import { Modal } from '@mui/material';

import { IConfirmModal } from './confirmModal.interface';
import './confirmModal.scss';

import CustomButton, { ButtonVariants } from '../buttons/CustomButton';
import Typography from '../typography/Typography';
import { closeIcon } from '../../../assets/images';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';

const ConfirmModal = ({
  open,
  setOpen,
  title,
  description,
  image,
  confirmButtonText,
  noCloseIcon = false,
  handleOkClick, //if any functionality is there on the OK click will be calling this
  handleClose,
  descriptionWidth,
  closeBtnText
}: IConfirmModal) => {
  const closeModal = () => {
    if (handleClose) {
      handleClose();
    }
    if (setOpen) setOpen(false);
  };

  const handleOk = () => {
    if (handleOkClick) {
      handleOkClick();
    } else {
      closeModal();
    }
  };

  return (
    <Modal open={open} data-testid="commonConfirmModal" id="common-confirm-modal">
      <PaperCustom variant="medium" className="common-confirm-modal">
        <div className="common-confirm-modal__heading">
          {!noCloseIcon && (
            <img
              src={closeIcon}
              alt="Close icon"
              data-testid="confirmModalCloseIcon"
              onClick={closeModal}
            />
          )}
        </div>
        <img
          className="common-confirm-modal__image"
          src={image}
          alt="icon with a checkmark"
          data-testid="confirmModalImage"
        />
        <Typography variant="body-1" size="semiBold" className="common-confirm-modal__title">
          {title}
        </Typography>
        <Typography
          variant="body-2"
          className="common-confirm-modal__description"
          data-testid="confirmModalDescription"
          style={{ width: descriptionWidth }}
        >
          {description}
        </Typography>
        <div className="d-flex justify-content-center gap-3">
          {closeBtnText && (
            <CustomButton
              text={closeBtnText}
              onClick={closeModal}
              buttonWidth="200px"
              variant={ButtonVariants.SECONDARY}
            />
          )}
          {confirmButtonText && (
            <CustomButton
              className="common-confirm-modal__confirm-button"
              text={confirmButtonText}
              onClick={handleOk}
              buttonWidth="200px"
            />
          )}
        </div>
      </PaperCustom>
    </Modal>
  );
};

export default ConfirmModal;
