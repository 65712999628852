import React from 'react';

import { cloudInSpotLogo, publicLayoutLogoSVG } from '../../assets/images';
import Footer from '../../components/common/footer/Footer';
import { Text, TextType } from '../../utils/Text';

import './publicLayout.css';

const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="guest-user">
        <div className="guest-user__container">
          <div className="guest-user__container__sub-container guest-user__container__logo-container">
            <img src={cloudInSpotLogo} alt="Company Logo" className="company-logo" />
            <Text type={TextType.H2} className="sub-heading">
              Maximize efficiency and minimize cost with cloud cost intelligence
            </Text>
            <img
              src={publicLayoutLogoSVG}
              alt="Logo if integration feature"
              className="integration-logo"
            />
          </div>
          <div className="guest-user__container__sub-container guest-user__container__form-container bg-light_blue">
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PublicLayout;
