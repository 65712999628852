import { getToken, isSupported } from 'firebase/messaging';

import { messaging } from '../../../firebase/firebaseConfig';
import { TRegisterDeviceRequest } from '../../../services/types/registerDevice.endpoints';
import getUserData from '../../../helpers/getUserData';
import { getOrgData } from '../../../helpers/getOrgData';
import useNotificationStore from '../../../store/notification/useNotificationStore';

const useGetToken = () => {
  // Hooks
  const { registerDevice } = useNotificationStore();

  // Constants

  const getTokenFunc = async () => {
    const supported = await isSupported();
    console.log(isSupported, 'isSupported');
    if (!supported) {
      return;
    }

    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      });
      console.log(token, 'fcm token');
      // Send token to server
      const { email } = getUserData();
      const { orgID } = getOrgData();

      const payload: TRegisterDeviceRequest = {
        email,
        organizationId: orgID,
        deviceId: token
      };
      registerDevice(payload);
    } catch (e) {
      console.log(e);
    }
  };

  return getTokenFunc;
};

export default useGetToken;
