import { Dispatch } from 'react';

import {
  UPDATE_SELECTED_USER,
  UPDATE_TEAM_MGMT_USER_PERMISSIONS,
  UPDATE_TEAM_MGMT_USER_PROFILE_DATA,
  UPDATE_TEAM_MGMT_USER_PROFILE_DATA_WITH_PERMISSIONS
} from './teamManagementUser.actionTypes';
import {
  ITeamMgmtUserProfile,
  IteamMgmtUserProfileWithPermissions,
  ITeamManagementGetUserWithPermissionsAction
} from './teamManagementUser.interface';

import { IUserPermissionsList } from '../teamManagement.interface';
import getWithAuth from '../../../services/getWithAuth';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { checkProfileImageValidity } from '../../actions';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';

export const updateSelectedUser = (state: string) => ({
  type: UPDATE_SELECTED_USER,
  payload: state
});

export const updateTeamMgmtUserProfileData = (state: ITeamMgmtUserProfile) => ({
  type: UPDATE_TEAM_MGMT_USER_PROFILE_DATA,
  payload: state
});

export const updateTeamMgmtUserProfileDataWithPermissions = (
  state: IteamMgmtUserProfileWithPermissions
) => ({
  type: UPDATE_TEAM_MGMT_USER_PROFILE_DATA_WITH_PERMISSIONS,
  payload: state
});

export const updateTeamMgmtUserPermissions = (state: IUserPermissionsList[]) => ({
  type: UPDATE_TEAM_MGMT_USER_PERMISSIONS,
  payload: state
});

export const fetchTeamMgmtUserRequest = ({ email }: { email: string }) => {
  return async (dispatch: Dispatch<ITeamManagementGetUserWithPermissionsAction>) => {
    try {
      const response = await getWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_GET_USER,
        params: { email }
      });

      const permissionsReponse = await getWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_GET_USER_ACCESS_CONTROL,
        params: { email }
      });

      const permissions = permissionsReponse?.data?.user?.permissions;
      const data = response?.data?.user as ITeamMgmtUserProfile;

      // to check whether the profile image is valid.
      const isProfileImageValid = await checkProfileImageValidity({ url: data?.url });

      data['isProfileImageValid'] = isProfileImageValid;

      if (data) {
        dispatch(
          updateTeamMgmtUserProfileDataWithPermissions({
            userProfileData: data,
            ...(permissions && { permissions })
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'getUserFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    }
  };
};
