import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export enum TextType {
  H1,
  H1_01,
  H1_012,
  H1_011,
  H2,
  H2_01,
  H3,
  H3_01,
  H3_02,
  H3_03,
  H4,
  H4_01,
  H4_011,
  H4_02,
  H5,
  H5_01
}

const styles: { [key in TextType]: string } = {
  [TextType.H1]: 'font-raleway font-bold text-2xl leading-normal', //form headings
  [TextType.H1_01]: 'font-raleway font-bold text-lg sm:text-[22px] lg:text-2xl leading-normal', //page headings
  [TextType.H1_012]: 'font-raleway font-semibold text-lg lg:text-xl leading-normal', //page headings new
  [TextType.H1_011]: 'font-raleway font-bold text-lg sm:text-2xl leading-normal', //delete Modal heading
  [TextType.H2]: 'font-noto font-semibold text-base sm:text-lg leading-[24.52px]',
  [TextType.H2_01]: 'font-noto font-semibold text-sm leading-[24.52px]',
  [TextType.H3]: 'font-noto font-bold text-base leading-[19.07px]',
  [TextType.H3_01]: 'font-noto font-normal text-base leading-[19.07px]',
  [TextType.H3_02]: 'font-raleway font-bold text-base leading-[16.44px]',
  [TextType.H3_03]: 'font-noto font-normal text-xs sm:text-base leading-[16.44px]',
  [TextType.H4]: 'font-noto font-normal text-sm leading-[19.07px]', //input labels
  [TextType.H4_01]: 'font-raleway font-bold text-sm leading-[16.44px]', //form button
  [TextType.H4_011]: 'font-noto font-bold text-sm leading-[16.44px]', //form button
  [TextType.H4_02]: 'font-noto font-normal text-xs sm:text-sm leading-[19.07px]',
  [TextType.H5]: 'font-noto font-normal text-xs leading-[16.34px]',
  [TextType.H5_01]: 'font-noto font-normal text-[11px] leading-[16.34px]'
  // ... add the rest of your styles here
};

export const Text: FC<{
  type: TextType;
  children: ReactNode | string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ type = TextType.H1, children, className, onClick, onChange }) => {
  const Element = `h${type + 1}`; // Convert TextType to corresponding heading level (h1, h2, ...)

  return React.createElement(
    Element,
    {
      className: twMerge(styles[type], className),
      onClick,
      onChange
    },
    children
  );
};
