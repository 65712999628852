import { IInsightsActions, IInsightsInitialValues } from './insights.interface';
import {
  SET_INSIGHTS_CURRENT_ACTIVE_TAB_FORM,
  UPDATE_INSIGHTS_TABLES_TYPE
} from './insights.actionTypes';

const InsightsInitialState: IInsightsInitialValues = {
  currentInsightsActiveTabForm: 0,
  tableType: 'provisioningAlerts'
};

const insightsReducer = (
  state: IInsightsInitialValues = InsightsInitialState,
  actions: IInsightsActions
) => {
  const { type, payload } = actions;
  switch (type) {
    case SET_INSIGHTS_CURRENT_ACTIVE_TAB_FORM:
      return {
        ...state,
        currentInsightsActiveTabForm: payload
      };
    case UPDATE_INSIGHTS_TABLES_TYPE:
      return {
        ...state,
        tableType: payload
      };
    default:
      return state;
  }
};
export default insightsReducer;
