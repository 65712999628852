export interface IGetUserDataOutput {
  email: string;
  firstName: string;
  lastName: string;
}

const getUserData = (): IGetUserDataOutput => {
  const userData: IGetUserDataOutput = JSON.parse(localStorage.getItem('userData') || '{}');
  const email = userData.email;
  const firstName = userData.firstName;
  const lastName = userData.lastName;
  if (!firstName || !lastName) {
    return { email, firstName: 'No', lastName: 'Name' };
  }
  return { email, firstName, lastName };
};

export default getUserData;
