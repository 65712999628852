import React from 'react';

import './cloudInSpotName.scss';

interface ICloudInSpotNameProb {
  className?: string;
}

const CloudInSpotName: React.FC<ICloudInSpotNameProb> = ({ className }) => {
  return <span className={`logoName ${className}`}>CloudInSpot</span>;
};

export default CloudInSpotName;
