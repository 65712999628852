import React from 'react';

// eslint-disable-next-lines

import IMAGES from '../../assets';
import { CLOUD_ACCOUNTS_TEXT } from '../../configs';

function RefreshAccountsButton({ loading = false, onclickHandler }: RefreshAccountsButtonProps) {
  return (
    <button type="button" className="refresh-table apply-loader" onClick={onclickHandler}>
      <img src={IMAGES.reloadIcon} className={`${loading ? 'rotate' : ''}`} alt="refresh table" />
      <span>{CLOUD_ACCOUNTS_TEXT.REFRESH}</span>
    </button>
  );
}

export default React.memo(RefreshAccountsButton);

export type RefreshAccountsButtonProps = {
  loading: boolean;
  onclickHandler: () => void;
};
