// eslint-disable-next-line import/named
import { Field, Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

import OTPValidationSchema from './OTP.validationSchema';
import { IOTPVerification, IResendOTP } from './OTPVerification.types';
import './otpVerification.scss';

import IMAGES from '../../assets';
import '../../assets/styles/index.scss';
import usePasswordVisibility from '../../utils/usePasswordVisibility';
import services from '../../services';
import API_ENDPOINTS from '../../constants/api_endpoints';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { successToastStyle } from '../../configs/styleConstants';
import CloseButton from '../../components/common/close-button/CloseButton';
import '../../components/user-authentication/UserAuth.css';
import { BUTTON_TEXT } from '../../configs/form';
import { routes } from '../../constants/routes';
import { useAppDispatch } from '../../hooks/redux/useRedux';
import { setSignUpValues } from '../../store/actions';
import { signUpInitialValues } from '../../store/userAuth/userAuth.reducer';
import AuthFormContainer from '../../components/common/formContainer/AuthFormContainer';
import CustomButton from '../../components/common/buttons/CustomButton';
import TextInput from '../../components/common/textInput/TextInput';
import Typography from '../../components/common/typography/Typography';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import { confirmSuccess } from '../../assets/images';
import { toastAutoCloseDuration } from '../../constants/constants';

const OTPVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues: IOTPVerification = {
    email: location?.state?.email,
    otp: ''
  };

  const { visible, toggleVisibility, inputType } = usePasswordVisibility(); //custom hook
  const [loading, setLoading] = useState<boolean>(false);
  const [isAccountCreatedSuccessModalOpen, setIsAccountCreatedSuccessModalOpen] =
    useState<boolean>(false);

  const handleSubmit = async (values: IOTPVerification) => {
    setLoading(true);
    //submit the form with email and OTP
    services
      .post({ url: API_ENDPOINTS.OWNER_CONFIRM_SIGN_UP, payload: { ...values } })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          dispatch(setSignUpValues(signUpInitialValues));
          setIsAccountCreatedSuccessModalOpen(true);
        }
      })
      .catch((error: unknown) => {
        useErrorHandler({
          error,
          toastId: 'OTPVerificationError',
          defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
        });
        setLoading(false);
      });
  };

  const [seconds, setSeconds] = useState<number>(15); //timer
  const [isRunning, setIsRunning] = useState<boolean>(false); //to start the timer

  const decreaseSeconds = () => {
    //decrease the time by 1 sec
    setSeconds((prevSeconds) => prevSeconds - 1);
  };

  const startTimer = () => {
    if (!isRunning) {
      //start the countdown if it's already not running
      setIsRunning(true);
      setSeconds(15); // restart the timer of 15 seconds
    }
  };

  const resendOTP = async ({ values, resetForm }: IResendOTP) => {
    // reset OTP value
    resetForm({ values: { otp: initialValues.otp, email: values.email } });

    //API call
    const payload = { email: location?.state?.email };
    services
      .get({ url: `${API_ENDPOINTS.OWNER_RESEND_SIGN_UP_OTP}?email=${payload.email}` })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const message = response?.data?.message || `OTP sent on your email !`;
          toast.success(message, {
            style: successToastStyle,
            toastId: 'AccountCreateSuccessfully',
            closeButton: <CloseButton color="#128C83" />,
            autoClose: toastAutoCloseDuration
          });
          startTimer(); //start the timer after getting the status code 200 OK
        }
      })
      .catch((error: unknown) => {
        useErrorHandler({
          error,
          toastId: 'ResentOTPError',
          defaultMessage: 'Unable to resend OTP due to unknown reasons! Please try again later'
        });
      });
  };

  useEffect(() => {
    if (isRunning && seconds > 0) {
      const countdownInterval = setInterval(decreaseSeconds, 1000);
      return () => clearInterval(countdownInterval); //cleanup function
    } else if (seconds === 0) {
      setIsRunning(false);
    }
  }, [isRunning, seconds]);

  const handleNavigateToSignIn = () => {
    setIsAccountCreatedSuccessModalOpen(false);
    navigate(routes.SIGN_IN);
  };

  useEffect(() => {
    // To disable to resend OTP button for the first 15 sec
    startTimer();
  }, []);
  return (
    <AuthFormContainer
      heading="Email Verification"
      handleBackToPrev={() => navigate(routes.SIGN_UP)}
      backToPrevScreenText="Create Account"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={OTPValidationSchema}
      >
        {({ values, resetForm, errors, touched }) => (
          <Form className="emailVerification">
            {/* email field auto populated */}
            <div className="emailVerification__field">
              <TextInput
                label="Email"
                type="text"
                name="email"
                placeholder="Enter an Email"
                disabled={true}
                errors={errors}
                touched={touched}
                required={true}
              />
            </div>
            {/* OTP field */}
            <div className="emailVerification__field">
              <Typography
                variant="body-3"
                size="regular"
                as="label"
                className="text-black-900 label_required"
              >
                Verification Code
              </Typography>
              <div className="emailVerification__field__password">
                <Field
                  type={inputType}
                  name="otp"
                  id="otp"
                  placeholder="Enter Verification Code"
                  disabled={loading}
                  className="input_fields"
                />

                <Tooltip
                  title={visible ? 'Hide Password' : 'Show Password'}
                  placement="bottom"
                  arrow={true}
                >
                  <img
                    src={visible ? IMAGES.VisibilityOnEye : IMAGES.VisibilityOffEye}
                    onClick={toggleVisibility}
                    alt={visible ? 'Show Password' : 'Hide Password'}
                    className="emailVerification__field__password__eyeIcon"
                  />
                </Tooltip>
              </div>
              {/* OTP Error */}
              <Typography
                variant="body-3"
                size="regular"
                as="div"
                className={`common-text-input__error  ${
                  errors.otp && touched.otp ? 'visible' : 'invisible'
                }`}
              >
                {errors.otp && touched.otp ? errors.otp : 'Please enter the organization ID'}
              </Typography>

              {/* resend OTP Error block */}
              <div className="emailVerification__field__resend">
                <Typography
                  variant="body-3"
                  size="semiBold"
                  onClick={() => {
                    if (!isRunning) {
                      // Only request new OTP, when the timer is not running
                      resendOTP({ values, resetForm });
                    }
                  }}
                  className={`emailVerification__field__resend__link  font-bold ${
                    isRunning
                      ? 'emailVerification__field__resend__disabledLink'
                      : 'emailVerification__field__resend__link'
                  }`}
                >
                  Resend Verification Code
                </Typography>
                {/* Count down timer */}
                {isRunning && ( //only visible if it is running
                  <Typography variant="body-3" size="semiBold" className="text-primary-800">
                    00:{seconds < 10 ? `0${seconds}` : seconds}
                  </Typography>
                )}
              </div>
            </div>
            {/* Submit button */}
            <CustomButton
              className="w-full"
              type="submit"
              text={BUTTON_TEXT.CONTINUE}
              isLoading={loading}
            />
          </Form>
        )}
      </Formik>
      {isAccountCreatedSuccessModalOpen && (
        <ConfirmModal
          open={true}
          title="Account Created!"
          description="Account created successfully! Organization ID sent to your email. Please sign in."
          confirmButtonText="OKAY"
          handleOkClick={handleNavigateToSignIn}
          handleClose={handleNavigateToSignIn}
          image={confirmSuccess}
          descriptionWidth="88%"
        />
      )}
    </AuthFormContainer>
  );
};

export default OTPVerification;
