import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { copyToClipboard } from '../../../assets/images';
import { successToastStyle } from '../../../configs/styleConstants';
import CloseButton from '../close-button/CloseButton';
import './CopyToClipboard.scss';

interface CopyToClipboardProps {
  textLabel?: string;
  textToCopy: string;
  toastMessage: string;
  toastId: string;
  textLabelClassName?: string;
  hyperlinkUrl?: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  textLabel = '',
  textToCopy,
  toastMessage,
  toastId,
  textLabelClassName,
  hyperlinkUrl
}) => {
  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(toastMessage, {
      style: successToastStyle,
      toastId,
      closeButton: <CloseButton color={successToastStyle?.color as string} />
    });
  }, [textToCopy, toastMessage, toastId]);

  const handleHyperlink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (hyperlinkUrl) {
        window.open(hyperlinkUrl, '_blank');
      }
    },
    [hyperlinkUrl]
  );

  const renderContent = () => {
    return hyperlinkUrl ? (
      <span onClick={handleHyperlink}>{hyperlinkUrl}</span>
    ) : (
      <span>{`${textLabel} ${textToCopy}`}</span>
    );
  };

  return (
    <span className="copy-to-clipboard">
      <span className={`text-copy ${textLabelClassName}`}>{renderContent()}</span>
      <button
        onClick={handleCopyToClipboard}
        aria-label="Copy to clipboard"
        className="copy-to-clipboard__icon"
      >
        <img src={copyToClipboard} alt="Copy icon" />
      </button>
    </span>
  );
};

export default CopyToClipboard;
