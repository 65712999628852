import { ITimezone, ITimezoneOptions } from '../../pages/userOnboarding/userOnboarding.types';

const timezoneConverter = (timezones: ITimezone[]): ITimezoneOptions[] => {
  const output: ITimezoneOptions[] = timezones.map((tz) => ({
    ...tz,
    name: `${tz.zoneName} (${tz.gmtOffsetName}) ${tz.tzName} ${tz.abbreviation}`,
    code: tz.gmtOffset
  }));

  return output;
};

export default timezoneConverter;
