import React from 'react';
import { useNavigate } from 'react-router-dom';

import './somethingWentWrong.scss';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';

const SomethingWentWrong = () => {
  //logic of this page will be decided further
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="somethingWentWrong">
      <div className="somethingWentWrong__container">
        <h1>Oops..</h1>
        <div className="somethingWentWrong__container__description">
          <h4>Something went wrong</h4>
          <div className="somethingWentWrong__container__description__buttons">
            <CustomButton text={'Go to Dashboard'} />
            <CustomButton
              text="Go Back"
              variant={ButtonVariants.SECONDARY}
              onClick={handleGoBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
