import { IOnSelectPermission, IPermissionsProps } from './permissions.interface';
import './permissions.scss';

import Accordion from '../../common/accordion/Accordion';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import { IPermissionsList } from '../../../store/teamManagement/teamManagement.interface';
import Typography from '../../common/typography/Typography';

const Permissions = ({
  currentStep,
  setCurrentStep,
  setPermissionHeadHeight,
  userPermissions,
  selectedPermissions,
  setSelectedPermissions,
  disableCheckboxes = false,
  hideCheckBox = false,
  includeDefaultPermissions = true,
  isLoading = false
}: IPermissionsProps) => {
  const accordionClickHandler = (permissionStep: number) => {
    setCurrentStep((prev) => (prev === permissionStep ? 0 : permissionStep));
  };

  const checkIfToBeSelected = ({
    item,
    module,
    permission
  }: {
    item: IPermissionsList;
    module: string;
    permission: string;
  }): boolean => {
    if (item.permissions[permission].default && includeDefaultPermissions) {
      return true;
    }
    if (selectedPermissions) {
      const selectedPermissionsObject = selectedPermissions.find((pItem) => pItem.module === module)
        ?.permissions;
      if (selectedPermissionsObject) {
        const selectedPermissionsList = Object.keys(selectedPermissionsObject);
        return selectedPermissionsList.includes(permission);
      }
      return false;
    }
    return false;
  };

  const collateUserSelectedPermissions = ({
    module,
    permission,
    apiKey,
    permissionApis
  }: IOnSelectPermission) => {
    // collate user selected permissions as per the payload requirements

    const addUserSelectedPermissionsTemp = JSON.parse(
      JSON.stringify(selectedPermissions)
    ) as IPermissionsList[];

    const moduleExistsIndex = addUserSelectedPermissionsTemp.findIndex(
      (sItem) => sItem.module === module
    );

    if (moduleExistsIndex < 0) {
      // if module doesn't exist in the selected permissions
      addUserSelectedPermissionsTemp.push({
        module,
        apiKey,
        permissions: { [permission]: permissionApis }
      });
    } else if (moduleExistsIndex >= 0) {
      // if module is already added.
      const module = addUserSelectedPermissionsTemp[moduleExistsIndex];
      const ispermissionAlreadyAdded = Object.keys(module.permissions).includes(permission);
      const addedPermissionsObject = addUserSelectedPermissionsTemp[moduleExistsIndex].permissions;
      if (!ispermissionAlreadyAdded) {
        addedPermissionsObject[permission] = permissionApis;
      } else {
        // remove the permissions becuase its unchecked.
        delete addedPermissionsObject[permission];
        if (Object.keys(addedPermissionsObject).length < 1) {
          // if there are no permissions for a module (ie. empty object), delete that module
          addUserSelectedPermissionsTemp.splice(moduleExistsIndex, 1);
        }
      }
    }
    if (setSelectedPermissions) {
      setSelectedPermissions(addUserSelectedPermissionsTemp);
    }
  };

  return (
    <>
      {userPermissions.map((item, index) => {
        return (
          <Accordion
            accordionTitle={
              <Typography variant="body-3" size="medium" as="p" className="label">
                {item.module}
              </Typography>
            }
            key={index}
            accordionKey={item.apiKey}
            isOpen={currentStep === index + 1}
            setPermissionHeadHeight={setPermissionHeadHeight}
            onClick={() => accordionClickHandler(index + 1)}
            isLoading={isLoading}
            containerClass="permissions-accordion"
          >
            <div className="permissions-module">
              <div className="permissions-module__head">
                <Typography
                  variant="body-3"
                  size="medium"
                  as="span"
                  className="permission apply-loader"
                >
                  {TEAM_MANAGEMENT_TEXT.PERMISSIONS}
                </Typography>
                <Typography
                  variant="body-3"
                  size="medium"
                  as="span"
                  className="access apply-loader"
                >
                  {!hideCheckBox && TEAM_MANAGEMENT_TEXT.ACCESS}
                </Typography>
              </div>

              {Object.keys(item.permissions).map((permission, index) => {
                const description = item?.permissions?.[permission]?.description;
                return (
                  <div key={index} className="permissions-module__item">
                    <Typography
                      variant="body-3"
                      size="medium"
                      as="span"
                      className="permission apply-loader"
                    >
                      {permission}

                      <Typography
                        variant="body-3"
                        size="regular"
                        as="span"
                        className="permission description apply-loader"
                      >
                        {description}
                      </Typography>
                    </Typography>

                    <div className="permissions-module__item__input">
                      <input
                        type="checkbox"
                        aria-description="checkbox for permission"
                        value={permission}
                        checked={checkIfToBeSelected({
                          item: item,
                          module: item.module,
                          permission
                        })}
                        onChange={() => {
                          const isDefaultPermission = item.permissions[permission].default;
                          // Should not be a default permission or includeDefaultPermissions should be false
                          if (
                            setSelectedPermissions &&
                            (!isDefaultPermission || !includeDefaultPermissions)
                          ) {
                            collateUserSelectedPermissions({
                              module: item.module,
                              permission: permission,
                              apiKey: item.apiKey,
                              permissionApis: item.permissions[permission]
                            });
                          }
                        }}
                        disabled={
                          disableCheckboxes ||
                          (item.permissions[permission].default && includeDefaultPermissions)
                        }
                        className={`${
                          hideCheckBox ? 'permissions-module__item__input--is-hidden' : ''
                        } apply-loader`}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Accordion>
        );
      })}
    </>
  );
};

export default Permissions;
