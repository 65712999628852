import { isSupported, onMessage } from 'firebase/messaging';
// eslint-disable-next-line
import { useEffect } from 'react';
import { FCM } from '../../../configs/common';
import { messaging } from '../../../firebase/firebaseConfig';
import updateTokens from '../../../services/updateTokens';
import { fetchMyProfile } from '../../../store/actions';
import useIsAuthenticated from '../../is-authenticated/useIsAuthenticated';
import useFetchMyProfile from '../../myProfile/fetchMyProfile/useFetchMyProfile';
import { useAppDispatch, useAppSelector } from '../../redux/useRedux';
import useGetToken from '../useGetToken/useGetToken';

const useGetNotifications = () => {
  // Hooks
  const dispatch = useAppDispatch();

  const getTokenFunc = useGetToken();

  const { refetch: refetchProfile } = useFetchMyProfile();

  const myProfileData = useAppSelector((store) => store.MyProfile);
  const { setAuthStatus } = useIsAuthenticated();

  const listenToNotifications = async () => {
    const supported = await isSupported();
    console.log(isSupported, 'isSupported');
    if (!supported) {
      return;
    }
    await getTokenFunc();
    onMessage(messaging, async (payload) => {
      // TODO: Actions based on the payload
      try {
        if (payload?.data?.type === FCM.PERMISSION_UPDATE) {
          refetchProfile();
          dispatch(fetchMyProfile({ ...myProfileData, permissionUpdated: true }));
          updateTokens();
        } else if (payload?.data?.type === FCM.DEACTIVATED_USER) {
          dispatch(fetchMyProfile({ ...myProfileData, deactivateUser: true }));
        } else if (payload?.data?.type === FCM.RESET_USER_PASSWORD) {
          dispatch(fetchMyProfile({ ...myProfileData, resetUserPassword: true }));
          updateTokens();

          setAuthStatus({ isUserChecked: false, isAuthenticated: false });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    listenToNotifications();
  }, []);

  return listenToNotifications;
};

export default useGetNotifications;
