import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './customTable.scss';

const columns = [1, 2, 3, 4];
const rows = [0, 1, 2, 3, 4];
// Alternative [...new Array(n).keys()] doesn't work here.

const CustomTableSkeletonLoader = () => {
  return (
    <TableContainer className="common-table-container common-table-container__loader">
      <Table aria-label="simple table" className="common-table-container__table" stickyHeader>
        <TableHead className="common-table-container__table__head">
          <TableRow className="common-table-container__table__head__row">
            {columns.map((column, index) => (
              <TableCell
                key={column}
                className={`common-table-container__table__head__row__cell ${
                  index === 0
                    ? 'no-left-padding'
                    : index === columns?.length - 1
                    ? 'no-right-padding'
                    : ''
                }`}
              >
                <div className="loader-animation"></div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="common-table-container__table__body">
          {rows.map((dataObject) => {
            return (
              <TableRow key={dataObject} className={`common-table-container__table__body__row `}>
                {columns.map((column, index) => {
                  return (
                    <TableCell
                      key={column}
                      className={`common-table-container__table__body__row__cell ${
                        index === 0
                          ? 'no-left-padding'
                          : index === columns?.length - 1
                          ? 'no-right-padding'
                          : ''
                      }`}
                    >
                      <div className="loader-animation"></div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTableSkeletonLoader;
