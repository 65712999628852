import React, { useState } from 'react';
import './InputWithBoldPlaceholder.scss';

interface InputWithBoldPlaceholderProps {
  placeholder: string;
  boldText: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const InputWithBoldPlaceholder: React.FC<InputWithBoldPlaceholderProps> = ({
  placeholder,
  boldText,
  value,
  onChange,
  className
}) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setInputValue(userInput);
    onChange(userInput);
  };

  const parts = placeholder.split(boldText);
  const hasBoldText = parts.length > 1;

  return (
    <div className={`custom-input-wrapper ${className || ''}`}>
      <input
        value={inputValue}
        onChange={handleInputChange}
        className="delete-group-modal__input"
      />
      {!inputValue && (
        <label className="placeholder-with-bold-text">
          {hasBoldText ? (
            <>
              {parts[0]}
              <span>{boldText}</span>
              {parts[1]}
            </>
          ) : (
            placeholder
          )}
        </label>
      )}
    </div>
  );
};

export default InputWithBoldPlaceholder;
