import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import IMAGES from '../../assets';
import { confirmError } from '../../assets/images';
import { ConnectAccountValidationSchema } from '../../components/cloudAccounts/CloudAccount.validationSchema';
import { TTableStatusText } from '../../components/cloudAccounts/cloudAccounts.interface';
import '../../components/cloudAccounts/connectAccount/connectAccount.scss';
import ConnectAccountSteps from '../../components/cloudAccounts/connectAccount/ConnectAccountSteps';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CustomButton from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import Loader from '../../components/common/loader/loader';
import LogoWithHeading from '../../components/common/logo-with-heading/logoWithHeading';
import InputGuidance from '../../components/common/textInput/InputGuidance';
import TextInput from '../../components/common/textInput/TextInput';
import '../../components/user-authentication/UserAuth.css';
import { CLOUD_ACCOUNTS_TEXT } from '../../configs';
import { AWS_CONNECT_INPUT_GUIDANCE } from '../../configs/cloudAccounts';
import { DECIMAL_NUMBER, WHOLE_NUMBER } from '../../constants/regex';
import { routes } from '../../constants/routes';
import { CloudAccountsTableStatus } from '../../enums/cloudAccounts';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import CloudAccountService from '../../services/cloudAccount.service';
import { TAccount, TGetAccountRequest } from '../../services/types/cloudAccount.endpoints';
import { setActiveTab, setConnectToAWSData, setHeading } from '../../store/actions';
import {
  ICloudAccountsReducerInitialState,
  IConnectToAwsData
} from '../../store/cloudAccounts/cloudAccounts.interface';
import { Text, TextType } from '../../utils/Text';
export interface IConnectAccountProps {
  setOpen: () => void;
}

type TConnectToAwsParams = {
  accountID?: string;
};

const ConnectAccount = () => {
  const [existingAccount, setExistingAccount] = useState<TAccount>(new TAccount());
  const [accountErrorModalOpen, setAccountErrorModalOpen] = useState<boolean>(false);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isStepsOpen, setIsStepsOpen] = useState<boolean>(false);
  const [isDisabled] = useState<boolean>(true);
  const isReconnectionRequest: boolean = location.pathname.includes('/re-connect');
  const params: TConnectToAwsParams = useParams<TConnectToAwsParams>();

  const { connectToAwsData } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;

  const handleSubmit = async ({ accountId, accountName, budget }: IConnectToAwsData) => {
    //open the connect to AWS steps
    const accountExist = await CloudAccountService.checkAccountExist({ accountId });
    if (
      accountExist &&
      !isReconnectionRequest &&
      (accountExist.account.status === 'Active' || accountExist.account.status === 'InProgress')
    ) {
      setExistingAccount(accountExist?.account as unknown as TAccount);
      setAccountErrorModalOpen(true);
    } else {
      handleSetIsStepsOpen();
      dispatch(setConnectToAWSData({ accountId, accountName, budget }));
    }
  };

  const handleSetIsStepsOpen = () => {
    setIsStepsOpen(!isStepsOpen);
  };

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading('Cloud Accounts'));
      dispatch(setActiveTab(2));
    },
    onUnMountFunc: () => {
      dispatch(
        setConnectToAWSData({
          accountId: '',
          accountName: '',
          budget: ''
        })
      );
    }
  });

  useEffect(() => {
    if (isReconnectionRequest) {
      const fetchAccountData = async () => {
        try {
          const request: TGetAccountRequest = {
            accountId: params.accountID || ''
          };
          const accountData = await CloudAccountService.getAccount(request);
          dispatch(
            setConnectToAWSData({
              accountName: accountData.account.accountName,
              accountId: accountData.account.accountId,
              budget: accountData.account.budget.toString()
            })
          );
        } catch (error) {
          console.error('Error fetching account data:', error);
        }
      };
      fetchAccountData();
    }
  }, [isReconnectionRequest, params.accountID]);

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
      {
        text: isReconnectionRequest
          ? CLOUD_ACCOUNTS_TEXT.RECONNECT_AWS
          : CLOUD_ACCOUNTS_TEXT.CONNECT_AWS,
        url: ''
      }
    ]
  });

  return isStepsOpen ? (
    <ConnectAccountSteps handleSetIsStepsOpen={handleSetIsStepsOpen} />
  ) : (
    <PaperCustom variant="medium" className="cardWrapper">
      <LogoWithHeading
        logoSrc={IMAGES.AWSLogo}
        headingText={
          isReconnectionRequest
            ? CLOUD_ACCOUNTS_TEXT.RECONNECT_AWS
            : CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS
        }
        className="mt-[-3px]"
      />
      {/*Connect Account Form */}
      <div className="cardWrapper__background-image" />
      <Formik
        initialValues={connectToAwsData}
        onSubmit={handleSubmit}
        validationSchema={ConnectAccountValidationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, touched }) => {
          return (
            <Form className="awsForm">
              <Text type={TextType.H4} className="awsForm__formWrapper">
                <div className="aws-input-container bg-[#F2F7FD] rounded-lg p-6">
                  <TextInput
                    label="Account Name"
                    type="text"
                    placeholder="Enter Account Name"
                    errors={errors}
                    touched={touched}
                    name="accountName"
                    data-testid="accountName"
                    disabled={isSubmitting}
                    required={true}
                    inputClassName="awsForm__formWrapper__input bg-white"
                    divClassName="input-inner-container"
                  />
                  <InputGuidance
                    content={
                      <>
                        The Account Name in <CloudInSpotName /> is a display name for the associated
                        AWS account within the platform. It doesn&apos;t change the actual AWS
                        account name. The Account Name must be 2-100 characters long and can only
                        include alphabets and underscores.
                      </>
                    }
                  />
                </div>
                <div className="aws-input-container p-6">
                  <TextInput
                    label="Account ID "
                    type="text"
                    placeholder="Enter Account ID"
                    errors={errors}
                    touched={touched}
                    name="accountId"
                    disabled={isSubmitting || (isReconnectionRequest ? true : false)}
                    required={true}
                    isFieldValid={(value) => WHOLE_NUMBER.test(value)}
                    inputClassName="awsForm__formWrapper__input"
                    divClassName="input-inner-container"
                  />
                  <InputGuidance content={AWS_CONNECT_INPUT_GUIDANCE.ACCOUNT_ID} />
                </div>
                <div className="aws-input-container  bg-[#F2F7FD] rounded-lg p-6 mb-5">
                  <TextInput
                    label="Monthly Budget"
                    type="text"
                    placeholder="Your Monthly Budget"
                    errors={errors}
                    touched={touched}
                    name="budget"
                    disabled={isSubmitting}
                    required={true}
                    isFieldValid={(value) => DECIMAL_NUMBER.test(value)}
                    currency="USD"
                    inputClassName="awsForm__formWrapper__input bg-white"
                    divClassName="input-inner-container"
                  />
                  <InputGuidance
                    content={
                      <>
                        The budget amount set here is specific to this AWS account and helps you
                        track expenses within <CloudInSpotName />.
                      </>
                    }
                  />
                </div>
              </Text>
              {/* <CopyPolicies divClassName="desktop:hidden w-full remove-scrollbar mb-4 hidden" /> */}
              <CustomButton
                type="submit"
                text={isSubmitting ? <Loader /> : `Continue`}
                role="button"
                buttonWidth="104px"
                className={isDisabled ? 'awsForm__formWrapper__disabledBtn' : 'aws_btn'}
              />
            </Form>
          );
        }}
      </Formik>
      {/* Copy text area */}
      {/* <CopyPolicies divClassName="hidden desktop:flex" /> */}
      {accountErrorModalOpen && (
        <ConfirmModal
          open={accountErrorModalOpen}
          title="Account Already Exists"
          description={
            <div className="flex flex-col gap-4">
              <p>
                The account with{' '}
                <span className="font-semibold accountId">
                  Account ID: {existingAccount.accountId}
                </span>{' '}
                already exists and is in{' '}
                {`"${CloudAccountsTableStatus[existingAccount.status as TTableStatusText]}"`} state.
              </p>
              <p>
                To View the account details, please click the &#34;View Account Details&#34; button
                below.
              </p>
            </div>
          }
          descriptionWidth="90%"
          confirmButtonText="View Account Details"
          handleOkClick={() => navigate(`/cloud-account/view/${existingAccount.accountId}`)}
          setOpen={setAccountErrorModalOpen}
          image={confirmError}
        />
      )}
    </PaperCustom>
  );
};

export default ConnectAccount;
