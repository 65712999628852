// eslint-disable-next-line
import React, { useState } from 'react';
// eslint-disable-next-line
import NeedHelpForm from './needHelp.form';
import './needHelpModal.css';
import closeIcon from '../../../assets/images/svg/closeIcon.svg';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import ScrollView from '../../common/scrollView/ScrollView';

interface NeedHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NeedHelpModal: React.FC<NeedHelpModalProps> = ({ isOpen, onClose }) => {
  const [formKey, setFormKey] = useState(0); // Key to force remount of the form
  const [isNeedHelpModalOpen, setIsNeedHelpModalOpen] = useState(false);
  const handleClose = () => {
    // Reset the form by changing its key
    setFormKey((prevKey) => prevKey + 1);
    onClose();
  };

  return (
    <>
      <div className={`need-help-overlay ${isOpen ? 'open' : ''}`} onClick={handleClose}></div>

      <div className={`need_help_modal ${isOpen ? 'open' : ''}`}>
        <ScrollView className="needHelpScroll">
          <div className="need_help_modal_modal_content">
            <div className="header_container">
              <div className="header">
                <span className="modal_heading">Need Help?</span>
                <button onClick={handleClose}>
                  <img src={closeIcon} alt="Close Icon" />
                </button>
              </div>
              <p className="modal_sub_heading">
                Share your query or issue with us related to <CloudInSpotName />.
              </p>
            </div>
            <NeedHelpForm
              key={formKey} // Change key to remount the form
              onClose={handleClose}
              resetForm={() => {}}
              isNeedHelpModalOpen={isNeedHelpModalOpen}
              setIsNeedHelpModalOpen={setIsNeedHelpModalOpen}
            />
          </div>
        </ScrollView>
      </div>
    </>
  );
};

export default NeedHelpModal;
