import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './archivedUsers.scss';

import IMAGES from '../../../assets';
import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { errorToastStyle } from '../../../configs/styleConstants';
import {
  TEAM_MANAGEMENT_TEXT,
  TEAM_MANAGEMENT_TOOLTIP_MESSAGES
} from '../../../configs/teamManagement';
import { ERRORS as ERRORS_COLORS, PRIMARY as PRIMARY_COLORS } from '../../../configs/v3.colors';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM, RowsPerPage } from '../../../constants/constants';
import { DeleteModal, Loader } from '../../../constants/pages';
import { routes } from '../../../constants/routes';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../hooks/loading/useLoading';
import { useAppSelector } from '../../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import postWithAuth from '../../../services/postWithAuth';
import { prefetchArchivedUsers } from '../../../services/prefechService/prefetchTeamsService';
import { fetchArchivedUsers } from '../../../services/teamManagement/teamManagement';
import { TGetArchivedUsersResponse } from '../../../services/types/teamManagement.endpoints';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import { selectDeslectCheckbox, sortObjectArray } from '../../../utils';
import {
  getFetchArchivedUsersQueryKey,
  getFetchUsersQueryKey
} from '../../../utils/pre-fetchers/prefetchTeamManagement';
import { TSortType } from '../../../utils/sortObjectArray/sortObjectArray.interface';
import TextTooltipWithEllipsis from '../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import CloseButton from '../../common/close-button/CloseButton';
import CustomTable from '../../common/commonTable/CustomTable';
import { ITableColumns } from '../../common/commonTable/customTable.interface';
import Conditional from '../../common/conditionalComponent/Conditional';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import RevertIcon from '../../common/icons/revertIcon/RevertIcon';
import TrashIcon from '../../common/icons/trashIcon/TrashIcon';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal from '../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import SearchForm from '../../common/searchForm/SearchForm';
import { TSearchFormValues } from '../../common/searchForm/searchForm.types';
import '../users/users.scss';

const ArchivedUsers = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_FIRST_ITEM);
  const [userSearchString, setuserSearchString] = useState({ value: '', isDirty: false });
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });
  const [isDeleteUserModalOpen, setDeleteUserModal] = useState<boolean>(false);
  const [isDeleteUserSuccessOpen, setDeleteUserSuccess] = useState<boolean>(false);
  const [isRestoreUserModalOpen, setRestoreUserModalOpen] = useState<boolean>(false);
  const [isRestoreSuccessModalOpen, setRestoreSuccessModal] = useState<boolean>(false);
  const [isRowSelectedForActivateOrDeactivation, setIsRowSelectedForActivateOrDeactivation] =
    useState<boolean>(false);

  const tableHeightRef = useRef<HTMLDivElement>(null);

  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [disableSelectAll, setDisableSelectAll] = useState<boolean>(false);

  const { isOwner: isloggedInUserOwner } = useAppSelector<IMyProfileState>(
    (store) => store.MyProfile
  );

  const { isLoading, data: archivedUsers } = useQuery<TGetArchivedUsersResponse>(
    getFetchArchivedUsersQueryKey({
      limit: rowsPerPage,
      offset: pageNumber,
      filterUser: userSearchString.value
    }),
    () => fetchArchivedUsers(rowsPerPage, pageNumber, userSearchString.value),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { loading, startLoading, stopLoading } = useLoading();

  // USER PERMISSIONS START
  const { canUserActivateAnotherUser, canUserDeleteAnotherUser } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = JSON.parse(JSON.stringify(archivedUsers?.users || []))
      .slice(0, rowsPerPage)
      .map((item: Record<string, string | string[] | number>[], index: number) => {
        return { ...item, itemNo: startIndex + index + 1 };
      });

    const newData = sortObjectArray({
      arr: totalItems,
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];
    return newData;
  }, [dataSort, archivedUsers?.users, pageNumber, rowsPerPage]);

  const onRowSelection = (value: string) => {
    if (setSelectedRows) {
      const newArray = selectDeslectCheckbox({ arr: selectedRows, value }) as string[];
      setSelectedRows(() => [...newArray]);
    }
  };

  const selectAndDeselectAllRows = () => {
    const allRowIds = sortedData.map((row) => row.email) as (string | number)[];

    if (setSelectedRows) {
      if (selectedRows.length === allRowIds.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(allRowIds);
      }
    }
  };

  const handleRestoreUsers = async () => {
    startLoading();
    //API call of archive users
    try {
      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_LIST_RESTORE_USERS,
        payload: { emails: selectedRows }
      });

      if (response) {
        setRestoreUserModalOpen(false);
        setRestoreSuccessModal(true);
        setSelectedRows([]);
        searchUsersByString({ query: userSearchString?.value });
        queryClient.resetQueries(
          getFetchUsersQueryKey({
            limit: ROWS_PER_PAGE_FIRST_ITEM,
            offset: offsetFirst,
            filterUser: ''
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementArchiveUsersFail',
        defaultMessage:
          'Your request to restore users has failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  const handleRestoreFunc = async () => {
    if (selectedRows.length === 0) {
      //if no user is selected then throw this toast
      toast.error('Please select users first !', {
        style: errorToastStyle,
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
      return;
    }

    toggleRestoreUserModal();
    //API call of archive users
  };

  const handleDeleteUsers = async () => {
    //to handle the delete user API
    try {
      startLoading();
      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_LIST_DELETE_USERS,
        payload: { emails: selectedRows }
      });

      if (response) {
        setDeleteUserSuccess(true);
        setSelectedRows([]);
        searchUsersByString({ query: userSearchString?.value });
        setDeleteUserModal(false);
        // resetQuery will remove cached api response for archived users list and will initialize loading
        queryClient.resetQueries(
          getFetchArchivedUsersQueryKey({
            limit: ROWS_PER_PAGE_FIRST_ITEM,
            offset: offsetFirst,
            filterUser: ''
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementDeleteArchiveUsersFail',
        defaultMessage:
          'Your request to delete archived users has failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  const toggleDeleteModal = () => {
    setDeleteUserModal((prev) => !prev);
    if (isRowSelectedForActivateOrDeactivation) {
      setIsRowSelectedForActivateOrDeactivation(false);
      setSelectedRows([]);
    }
  };
  const toggleRestoreUserModal = () => {
    setRestoreUserModalOpen((prev) => !prev);
    if (isRowSelectedForActivateOrDeactivation) {
      setIsRowSelectedForActivateOrDeactivation(false);
      setSelectedRows([]);
    }
  };

  const searchUsersByString = ({ query }: TSearchFormValues) => {
    setuserSearchString({ value: query, isDirty: true });
    setPageNumber(1);
    queryClient.invalidateQueries(
      getFetchArchivedUsersQueryKey({
        limit: ROWS_PER_PAGE_FIRST_ITEM,
        offset: offsetFirst,
        filterUser: query
      })
    );
  };

  useEffect(() => {
    if (archivedUsers?.count && pageNumber < archivedUsers?.count / rowsPerPage) {
      const nextPage = pageNumber + 1;
      prefetchArchivedUsers(queryClient, {
        filterUser: '',
        pageNumber: nextPage,
        rowsPerPage
      });
    }
  }, [pageNumber, rowsPerPage, queryClient, archivedUsers?.count]);

  useUpdateBreadcrumbs({
    breadcrumbs: [
      {
        text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT,
        url: routes.TEAM_MANAGEMENT_USERS_TABLE
      },
      {
        text: TEAM_MANAGEMENT_TEXT.DEACTIVATED_USER,
        url: ''
      }
    ]
  });

  const tableColumns: ITableColumns[] = [
    {
      key: 1,
      title: '',
      dataIndex: 'email',
      headElement: () => (
        <input
          type="checkbox"
          checked={selectedRows.length === sortedData.length && selectedRows?.length > 0}
          className="checkbox select-all-checkbox"
          onChange={selectAndDeselectAllRows}
          disabled={disableSelectAll}
        />
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;

        // disabling the checkbox if one of the following conditions are met
        // if the logged in user is not owner or they don't have permissions.
        const enableCheckbox =
          isloggedInUserOwner || canUserActivateAnotherUser || canUserDeleteAnotherUser;
        setDisableSelectAll(
          // Disabling select all checkbox, if any one of the row has to be disabled.
          // Default value is false, it can only be changed to true.
          (prev) => (!enableCheckbox ? true : prev)
        );

        return (
          <input
            type="checkbox"
            onChange={() => onRowSelection(email)}
            className="checkbox"
            checked={selectedRows.includes(email)}
            disabled={!enableCheckbox}
          />
        );
      }
    },
    {
      key: 3,
      title: 'First Name',
      dataIndex: 'firstName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>First Name</span>
        </div>
      ),
      bodyElement: (...args) => {
        const firstName = args[0] as string;
        return <TextTooltipWithEllipsis limit={10} text={firstName} />;
      }
    },
    {
      key: 4,
      title: 'Last Name',
      dataIndex: 'lastName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Last Name</span>
        </div>
      ),
      bodyElement: (...args) => {
        const lastName = args[0] as string;
        return <TextTooltipWithEllipsis limit={10} text={lastName} />;
      }
    },
    {
      key: 5,
      title: 'Email',
      dataIndex: 'email',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Email</span>
        </div>
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        return <TextTooltipWithEllipsis limit={30} text={email} />;
      }
    },
    {
      key: 6,
      title: 'Action',
      dataIndex: 'email',
      headElement: () => (
        <div className="cell-inner">
          <span>Action</span>
        </div>
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        const displayButtons =
          isloggedInUserOwner || canUserActivateAnotherUser || canUserDeleteAnotherUser;

        return (
          <div
            className="actions"
            onClick={() => {
              setSelectedRows([email]);
              setIsRowSelectedForActivateOrDeactivation(true);
            }}
          >
            <Conditional
              condition={displayButtons}
              whenTrue={
                <>
                  <div
                    className="actions__reactivate-user"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedRows([email]);
                      setRestoreUserModalOpen(true);
                    }}
                  >
                    <RevertIcon color="white" />
                  </div>
                  <div
                    className="actions__delete-user"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedRows([email]);
                      setDeleteUserModal(true);
                    }}
                  >
                    <TrashIcon color={ERRORS_COLORS[700]} />
                  </div>
                </>
              }
              whenFalse={<>--</>}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div
      className={`team-management-deactivated-table ${
        isLoading ? 'team-management-deactivated-table--is-loading' : ''
      }`}
    >
      {/* If no user count and no search query then the NoData screen will appear else table will appear */}

      <>
        <div className="team-management-deactivated-table__search">
          <SearchForm
            placeholder={TEAM_MANAGEMENT_TEXT.SEARCH_PLACEHOLDER}
            className="team-management-deactivated-table__search__input-field"
            onSearch={searchUsersByString}
          />
          <div className="team-management-deactivated-table__search__buttons">
            {selectedRows.length > 0 && (
              <>
                <TooltipCustom
                  text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_ACTIVATE_USER_ACCESS}
                  conditionToDisplay={!canUserActivateAnotherUser}
                >
                  <button
                    type="button"
                    className="activate-user"
                    onClick={handleRestoreFunc}
                    disabled={!canUserActivateAnotherUser}
                  >
                    <RevertIcon color="white" />
                    <span>Reactivate User</span>
                  </button>
                </TooltipCustom>
                <TooltipCustom
                  text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_DELETE_USER_ACCESS}
                  conditionToDisplay={!canUserDeleteAnotherUser}
                >
                  <button
                    onClick={toggleDeleteModal}
                    type="button"
                    className="delete-user"
                    disabled={!canUserDeleteAnotherUser}
                  >
                    <TrashIcon color={ERRORS_COLORS[700]} />
                    <span>{TEAM_MANAGEMENT_TEXT.DELETE_USER}</span>
                  </button>
                </TooltipCustom>
              </>
            )}
          </div>
        </div>
        <div className="team-management-deactivated-table__table" ref={tableHeightRef}>
          <CustomTable
            tableColumns={tableColumns}
            tableData={sortedData}
            totalItems={archivedUsers?.count || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            rowsPerPageOptions={RowsPerPage}
            isLoading={isLoading}
            sortState={dataSort}
            setSortState={setDataSort}
          />
        </div>
      </>
      {isDeleteUserModalOpen && (
        <DeleteModal
          open={isDeleteUserModalOpen}
          heading="Delete User"
          description="Are you sure you want to permanently delete the selected user(s)?"
          placeholder="Type DELETE to delete user"
          handleClose={toggleDeleteModal}
          handleDelete={handleDeleteUsers}
          loading={loading}
        />
      )}

      {/* Restore User Modal */}
      {isRestoreUserModalOpen && (
        <RestoreArchiveUserModal
          open={isRestoreUserModalOpen}
          heading={TEAM_MANAGEMENT_TEXT.REACTIVATE_USER}
          description={TEAM_MANAGEMENT_TEXT.CONFIRM_REACTIVATE_USER}
          handleClose={toggleRestoreUserModal}
          handlerFun={handleRestoreUsers}
          actionButtonText={loading ? <Loader /> : TEAM_MANAGEMENT_TEXT.YES_REACTIVATE}
          actionButtonColor={PRIMARY_COLORS[700]}
          icon={IMAGES.ErrorExclamation}
        />
      )}

      {/* Confirmation modal is outside the conditional block because there are chances that all users are deleted/restores for that case No Data screen will appear and it will wipe out the success modals too*/}
      {isRestoreSuccessModalOpen && (
        <ConfirmModal
          open={isRestoreSuccessModalOpen}
          setOpen={setRestoreSuccessModal}
          title={TEAM_MANAGEMENT_TEXT.USER_REACTIVATED}
          description={TEAM_MANAGEMENT_TEXT.USER_REACTIVATE_SUCCESS}
          image={confirmSuccess}
          confirmButtonText={BUTTON_TEXT.OKAY}
          handleOkClick={() => {
            navigate(routes.TEAM_MANAGEMENT_USERS_TABLE);
          }}
        />
      )}

      {isDeleteUserSuccessOpen && (
        <ConfirmModal
          open={isDeleteUserSuccessOpen}
          setOpen={setDeleteUserSuccess}
          title={TEAM_MANAGEMENT_TEXT.USER_DELETED}
          description={TEAM_MANAGEMENT_TEXT.USER_DELETED_PERMANENTLY}
          image={confirmSuccess}
          confirmButtonText={BUTTON_TEXT.OKAY}
        />
      )}
    </div>
  );
};

export default ArchivedUsers;
