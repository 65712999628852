// TODO: Make all constants in CAPS
export const RowsPerPage: number[] = [12, 25, 50];
export const rowsLimit: number = 100;
export const offsetFirst: number = 1;
export const ROWS_PER_PAGE_FIRST_ITEM: number = RowsPerPage[0];
export const toastAutoCloseDuration: number = 5000;
export const DEFAULT_OFFSET_INTERVAL: number = 1;
export const DEFAULT_PAGE_NUMBER: number = 1;
export const DEFAULT_ZOOM: number = 1;
export const BYTE_MULTIPLIER: number = 1024;
export const PROFILE_PIC_LIMIT: number = 512;
export const ORGANIZATION_REFRESH_INTERVAL: number = 30000;
