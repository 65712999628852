export const CUSTOM_TABLE = {
  SHOW: 'Show',
  ENTRIES: 'Entries',
  NO_DATA_FOUND: 'No Data Found',
  NO_RESULTS_FOUND: 'No results found',
  VIEW_ENTRIES: 'View Entries'
};

export const COMMON_TEXT = {
  CLOUD_ACCOUNT_NAME_LIMIT: 20,
  EXPLORER: 'Explorer',
  GO_TO_CLOUD_ACCOUNTS: 'Go to Cloud Accounts',
  GO_TO_DASHBOARD: 'Go to Dashboard',
  GO_TO_HOMEPAGE: 'Go to Home Page',
  SEARCH: 'Search',
  FEEDBACK_SUBMITTED_SUCCESSFULLY:
    'Your feedback is sent successfully to CloudInSpot team. Thank you!',
  POSITIVE: 'Positive',
  NEUTRAL: 'Neutral',
  NEGATIVE: 'Negative',
  NO_DATA_FOUND: 'No Data Found'
};
export const FCM = {
  //FCM
  PERMISSION_UPDATE: 'permission-update',
  DEACTIVATED_USER: 'deactivate-user',
  RESET_USER_PASSWORD: 'reset-user-password'
};
