import { useEffect, useRef, useState } from 'react';

import EmailInput from '../../components/teamManagement/AddUser/EmailInput';
import AddUserReview from '../../components/teamManagement/AddUser/AddUserReview';
import '../../components/teamManagement/AddUser/AddUser.scss';
import CustomStepsForm from '../../components/common/customStepsForm/CustomStepsForm';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  addUserStep1,
  addUserStep2,
  setActiveTab,
  setCurrentActiveTabForm,
  setHeading
} from '../../store/actions';
import { routes } from '../../constants/routes';
import { PersonalInfoInput, AddUserPermissions } from '../../constants/pages';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import useGetAddUserSteps from '../../hooks/customStepsForm/useGetAddUserStesp';
import { IUserPermissionsList } from '../../store/teamManagement/teamManagement.interface';
import getDefaultPermissions from '../../utils/teamManagement/defaultPermissions/getDefaultPermissions';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';

const defaultPermissions = getDefaultPermissions();

const AddUser = () => {
  const [addUserSelectedPermissions, setAddUserSelectedPermissions] =
    useState<IUserPermissionsList[]>(defaultPermissions);
  const addUserFlowtRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { currentActiveTabForm } = useAppSelector((state) => state.teamManagement);

  const onPageLoad = () => {
    dispatch(setHeading('Add User'));
    dispatch(setActiveTab(5));
    dispatch(setCurrentActiveTabForm(0));
  };

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: 'Team Management ', url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      { text: 'Users ', url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      { text: 'Add User', url: '' }
    ]
  });

  const AddUserForms: JSX.Element[] = [
    <PersonalInfoInput key={0} />,
    <EmailInput key={1} />,
    <AddUserPermissions
      key={2}
      addUserSelectedPermissions={addUserSelectedPermissions}
      setAddUserSelectedPermissions={setAddUserSelectedPermissions}
    />,
    <AddUserReview key={3} addUserSelectedPermissions={addUserSelectedPermissions} />
  ];

  const steps = useGetAddUserSteps();

  useEffect(() => {
    onPageLoad();

    return () => {
      dispatch(addUserStep1({ firstName: '', lastName: '' }));
      dispatch(addUserStep2({ email: '', jobTitle: '' }));
    };
  }, []);

  return (
    <PaperCustom className="addUser" id="teamMgmtAddUserContainer" ref={addUserFlowtRef}>
      <CustomStepsForm activeTab={currentActiveTabForm} steps={steps} />
      {AddUserForms[currentActiveTabForm]}
    </PaperCustom>
  );
};

export default AddUser;
