import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  webhookUrl: Yup.string()
    .required('Enter Webhook URL')
    .url('Please enter the correct webhook URL')
    .matches(/^https:\/\/.*webhook\.office\.com.*/, 'Please enter the correct webhook URL')
});

export default validationSchema;
