import { ICardProps } from './card.interface';

import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import TooltipCustom from '../molecules/tooltipCustom/TooltipCustom';
import './card.scss';

const Card = ({ img, heading, description, className, style, toolTipMsg }: ICardProps) => {
  return (
    <PaperCustom className={`card ${className}`} style={style} variant="medium">
      <TooltipCustom text={toolTipMsg}>
        <img src={img} className="card__img" />
      </TooltipCustom>
      <h2 className="heading">{heading}</h2>
      <p className="description">{description}</p>
    </PaperCustom>
  );
};

export default Card;
