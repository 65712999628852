import { create } from 'zustand';

import { connect } from '../../services/msTeams.service';
import type {
  TConnectMsTeamsRequest,
  TConnectMsTeamsResponse
} from '../../services/types/msTeams.endpoints';

interface MsTeamState {
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  connect: (request: TConnectMsTeamsRequest) => Promise<void>;
}

const useMsTeamStore = create<MsTeamState>((set) => ({
  isConnected: false,
  setIsConnected: (isConnected: boolean) => set(() => ({ isConnected: isConnected })),

  connect: async (request: TConnectMsTeamsRequest) => {
    const response: TConnectMsTeamsResponse = await connect(request);
    if (response) {
      set((state) => ({ ...state, isConnected: true }));
    }
  }
}));

export default useMsTeamStore;
