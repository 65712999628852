import { UPDATE_TEAM_MGMT_GROUP_PERMISSIONS } from './teamManagementGroup.actionTypes';
import {
  ITeamManagementGroupActions,
  ITeamManagementGroupInitialState
} from './teamManagementGroup.interface';

const teamManagementGroupInitialState: ITeamManagementGroupInitialState = {
  permissions: []
};

const teamManagementGroupReducer = (
  state: ITeamManagementGroupInitialState = teamManagementGroupInitialState,
  actions: ITeamManagementGroupActions
) => {
  const { type, payload } = actions;

  switch (type) {
    case UPDATE_TEAM_MGMT_GROUP_PERMISSIONS:
      return { ...state, permissions: payload };

    default:
      return state;
  }
};

export default teamManagementGroupReducer;
