export enum EActiveTabInsideAccountView {
  ACCOUNT_INFO = 'AccountInfo',
  SLACK = 'Slack',
  MSTEAMS = 'MS Teams',
  EMAIL = 'Email',
  EMPTY = ''
}

export const TabTexts = {
  ACCOUNT_VIEW: 'Account Info',
  SLACK: 'Slack',
  MSTEAMS: 'MS Teams',
  EMAIL: 'Email'
};

export const hoursAfterWhichShowReconnectButton = 6;
