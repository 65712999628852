import { permissions } from '@cloudinspot/cis-common-constants';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import disableUserRowCalc from './disableUserRowCalc';
import disableUserRowCheckboxCalc from './disableUserRowCheckboxCalc';
import { IViewMoreProps } from './users.interface';
import './users.scss';
import UsersSummary from './usersSummary/UsersSummary';

import IMAGES from '../../../assets';
import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { errorToastStyle } from '../../../configs/styleConstants';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import { ERRORS as ERRORS_COLORS, PRIMARY as PRIMARY_COLORS } from '../../../configs/v3.colors';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM, RowsPerPage } from '../../../constants/constants';
import { routes } from '../../../constants/routes';
import ENUMS from '../../../enums';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import { IElementPosition } from '../../../hooks/elementPosition/useElementPosition.interface';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import postWithAuth from '../../../services/postWithAuth';
import {
  prefetchTeamUser,
  prefetchTeamUsers
} from '../../../services/prefechService/prefetchTeamsService';
import { fetchUsers } from '../../../services/teamManagement/teamManagement';
import { updateSelectedUser } from '../../../store/actions';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import {
  ITeamMangUser,
  IUserPermissionsList,
  TUserSummary
} from '../../../store/teamManagement/teamManagement.interface';
import { selectDeslectCheckbox, sortObjectArray, textEllipsis } from '../../../utils';
import { calculateNumberOfPages, getNextOffset } from '../../../utils/pagination.utils';
import {
  getFetchArchivedUsersQueryKey,
  getFetchUsersQueryKey
} from '../../../utils/pre-fetchers/prefetchTeamManagement';
import { TSortType } from '../../../utils/sortObjectArray/sortObjectArray.interface';
import TextTooltipWithEllipsis from '../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import CloseButton from '../../common/close-button/CloseButton';
import CustomTable from '../../common/commonTable/CustomTable';
import { ITableColumns, TTableRowData } from '../../common/commonTable/customTable.interface';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import InfoIcon from '../../common/icons/infoIcon/InfoIcon';
import TrashIcon from '../../common/icons/trashIcon/TrashIcon';
import Loader from '../../common/loader/loader';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal from '../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import SearchForm from '../../common/searchForm/SearchForm';
import { TSearchFormValues } from '../../common/searchForm/searchForm.types';
import SimpleTooltip from '../../common/simpleTooltip/SimpleTooltip';
import Typography from '../../common/typography/Typography';
import ViewMoreModal from '../../common/viewMoreModal/ViewMoreModal';

const { userGroups } = ENUMS.teamManagement;

const firstNameEllipsisCharLimit = 10;

const Users = () => {
  // React state
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(RowsPerPage[0]);
  const [userSearchString, setuserSearchString] = useState({ value: '', isDirty: false });
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [viewMoreProps, setViewMoreProps] = useState<IViewMoreProps>({
    textColorClass: '',
    bgColorClass: '',
    data: [],
    parentClass: '',
    clientX: 0,
    clientY: 0,
    id: ''
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false);
  const [openConfirmArchiveModal, setOpenConfirmArchiveModal] = useState<boolean>(false);
  const [showUserSignedInTooltip, setShowUserSignedInTooltip] = useState<boolean>(false);
  const [mouseHoverRow, setMouseHoverRow] = useState<string>('');
  const [isRowSelectedForDeactivation, setIsRowSelectedForDeactivation] = useState<boolean>(false);
  const [isDeactivatedUser, setIsDeactivatedUser] = useState<boolean>(false);

  const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);

  // Refs
  const tableHeightRef = useRef<HTMLDivElement>(null);

  const { isOwner, groups } = useAppSelector<IMyProfileState>((store) => store.MyProfile);
  // User is considered owner, if they have isOwner flag
  const isloggedInUserOwner: boolean = isOwner;
  const isloggedInUserAdmin: boolean = (groups || []).indexOf(userGroups.Admin) > -1;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  // USER PERMISSIONS START
  const { canUserDeactivateAnotherUser, canUserViewAnotherUser } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const { isLoading, data: usersData } = useQuery<
    { users: ITeamMangUser[]; count: number } & TUserSummary
  >(
    getFetchUsersQueryKey({
      limit: rowsPerPage,
      offset: pageNumber,
      filterUser: userSearchString.value
    }),
    () => fetchUsers(rowsPerPage, pageNumber, userSearchString.value),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const prefetchUser = (email: string) => {
    prefetchTeamUser(queryClient, email);
  };

  const handleNavigateToDeactivatedUsers = () => {
    navigate('/team-management/deactivated-users');
  };

  const handleDeactivatedUserNavigation = isDeactivatedUser
    ? handleNavigateToDeactivatedUsers
    : () => closeArchiveModal;

  useEffect(() => {
    if (usersData?.count && calculateNumberOfPages(usersData?.count, rowsPerPage)) {
      prefetchTeamUsers(queryClient, {
        pageNumber: getNextOffset(pageNumber, 1),
        rowsPerPage: rowsPerPage,
        filterUser: userSearchString.value
      });
    }
  }, [pageNumber, rowsPerPage, queryClient, usersData?.count]);

  //Need to split this function for sorting and data manipulation
  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = JSON.parse(JSON.stringify(usersData?.users || []))
      .slice(0, rowsPerPage)
      .map((item: Record<string, string | string[] | number>[], index: number) => {
        const { disabled: disableCheckbox, tooltipText: disableCheckboxReason } =
          disableUserRowCheckboxCalc({
            userData: item as unknown as ITeamMangUser,
            isloggedInUserOwner,
            isloggedInUserAdmin,
            canUserDeactivateAnotherUser
          });

        return { ...item, itemNo: startIndex + index + 1, disableCheckbox, disableCheckboxReason };
      });

    const newData = sortObjectArray({
      arr: totalItems,
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];
    return newData;
  }, [
    dataSort,
    usersData?.users,
    usersData?.count,
    pageNumber,
    rowsPerPage,
    canUserDeactivateAnotherUser
  ]);

  const onRowSelection = (value: string) => {
    if (setSelectedRows) {
      const newArray = selectDeslectCheckbox({ arr: selectedRows, value }) as string[];
      setSelectedRows(() => [...newArray]);
    }
  };

  const selectAllRows = () => {
    // Only select rows which are not disabled
    const allRowIds = sortedData
      .filter((rowf) => !rowf.disableCheckbox)
      .map((row) => row.email) as string[];

    if (setSelectedRows) {
      if (selectedRows.length === allRowIds.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(allRowIds);
      }
    }
  };

  const handleRowClick = ({ rowData }: { rowData: TTableRowData }) => {
    const { disabled: disableRow } = disableUserRowCalc({
      userData: rowData as unknown as ITeamMangUser,
      isloggedInUserOwner,
      isloggedInUserAdmin,
      canUserViewAnotherUser
    });
    // only navigate the user when the row is not disabled.
    const user = rowData as unknown as ITeamMangUser;
    dispatch(updateSelectedUser(user.email));
    // Since backend didn't provide any api to query a user by any parameter other than email
    // email cannot be used for routing as it contains personal information
    // Cannot use the redux store, an bug is raised for this: https://cloudinspot.atlassian.net/browse/CIS2-1365
    if (!disableRow) {
      const user = rowData as unknown as ITeamMangUser;
      localStorage.setItem('teamMgmtUserProfile', user.email);
      navigate(routes.TEAM_MANAGEMENT_USER);
    }
  };

  const rowPropsFunc = ({ rowData }: { rowData: TTableRowData }) => {
    // Function that props to each such as click handlers, styles etc.
    const userData = rowData as unknown as ITeamMangUser;
    const { disabled: disableRow } = disableUserRowCalc({
      userData: rowData as unknown as ITeamMangUser,
      isloggedInUserOwner,
      isloggedInUserAdmin,
      canUserViewAnotherUser
    });
    return {
      onMouseEnter: () => {
        try {
          if (rowData) {
            setMouseHoverRow(userData.email);
          }
        } catch {
          setMouseHoverRow('');
        }
      },
      // passing a class for disabled row// passing a class for disabled row
      className: disableRow ? 'common-table-container__table__body__row--is-disabled' : ''
    };
  };

  const handleArchive = async () => {
    // Archive selected user
    // Currently api supports single user
    if (selectedRows.length > 0) {
      try {
        setIsLoadingStart(true);
        const response = await postWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_LIST_ARCHIVE_USERS,
          payload: { emails: selectedRows }
        });

        if (response) {
          setIsDeactivatedUser(true);
          setOpenArchiveModal(false);
          setOpenConfirmArchiveModal(true);
          searchUsersByString({ query: userSearchString?.value });
          setSelectedRows([]);
          queryClient.resetQueries(
            getFetchUsersQueryKey({
              limit: rowsPerPage,
              offset: pageNumber,
              filterUser: userSearchString.value
            })
          );
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'teamManagementArchiveUsersFail',
          defaultMessage:
            'Your request to archive users has failed due to unknown reasons! Please try again later'
        });
      } finally {
        setIsLoadingStart(false);
      }
    } else {
      toast.error(TEAM_MANAGEMENT_TEXT.PLEASE_SELECT_USER, {
        style: errorToastStyle,
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
    }
  };

  const closeArchiveModal = () => {
    setOpenArchiveModal(false);
    if (isRowSelectedForDeactivation) {
      setSelectedRows([]);
      setIsRowSelectedForDeactivation(false);
    }
  };

  const selectableUsers = sortedData.filter(
    (user) => !(user as unknown as ITeamMangUser)?.disableCheckbox
  );

  const searchUsersByString = ({ query }: TSearchFormValues) => {
    setuserSearchString({ value: query, isDirty: true });
    setPageNumber(1);
    queryClient.invalidateQueries(
      getFetchUsersQueryKey({
        limit: rowsPerPage,
        offset: offsetFirst,
        filterUser: query
      })
    );
  };

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      {
        text: TEAM_MANAGEMENT_TEXT.USERS,
        url: ''
      }
    ]
  });

  // EXTRACT THIS TABLE INTO A SEPERATE HOOK WHEN POSSIBLE DUE TO THE SIZE IT HAS GROWN INTO.
  const tableColumns: ITableColumns[] = [
    {
      key: 1,
      title: '',
      dataIndex: 'email',
      headElement: () => (
        <input
          type="checkbox"
          checked={selectedRows.length === selectableUsers.length && selectableUsers.length > 0}
          className="checkbox select-all-checkbox"
          onChange={selectAllRows}
        />
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        const userData = args[1] as unknown as ITeamMangUser;

        const { disableCheckbox = false, disableCheckboxReason = '' } = userData;

        return (
          <TooltipCustom
            text={disableCheckboxReason}
            conditionToDisplay={Boolean(disableCheckboxReason)}
          >
            <input
              type="checkbox"
              onChange={(e) => {
                e.stopPropagation();
                onRowSelection(email);
              }}
              onClick={(e) => {
                // To prevent on row click handler propagating.
                e.stopPropagation();
              }}
              className="checkbox"
              checked={selectedRows.includes(email)}
              disabled={disableCheckbox}
            />
          </TooltipCustom>
        );
      }
    },
    {
      key: 3,
      title: 'First Name',
      dataIndex: 'firstName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>First Name</span>
        </div>
      ),
      bodyElement: (...args) => {
        const firstName = args[0] as string;
        const { isVerified, email } = args[1] as unknown as ITeamMangUser;
        const rowData = args[1] as unknown as TTableRowData;

        const isRowOwner = (rowData as unknown as ITeamMangUser)?.isOwner;

        const { tooltipText } = disableUserRowCalc({
          userData: rowData as unknown as ITeamMangUser,
          isloggedInUserOwner,
          isloggedInUserAdmin,
          canUserViewAnotherUser
        });
        const firstNameToRender = `${firstName} ${isRowOwner ? '(Owner)' : ''}`;

        return (
          <div className="first-name" onMouseEnter={() => prefetchUser(rowData.email as string)}>
            {!isVerified && (
              <div className="first-name__no-signed-in">
                <SimpleTooltip
                  text={TEAM_MANAGEMENT_TEXT.USER_NOT_SIGNED_IN}
                  showTooltip={showUserSignedInTooltip && mouseHoverRow === email}
                  setShowTooltip={setShowUserSignedInTooltip}
                >
                  <InfoIcon />
                </SimpleTooltip>
              </div>
            )}
            <TooltipCustom
              text={
                <div>
                  {firstName?.length >= firstNameEllipsisCharLimit && <p>{firstNameToRender}</p>}
                  {tooltipText && <p>{tooltipText}</p>}
                </div>
              }
              conditionToDisplay={
                Boolean(tooltipText) || firstName?.length >= firstNameEllipsisCharLimit
              }
            >
              <div onClick={() => handleRowClick({ rowData })}>
                {textEllipsis({ text: firstName, charLimit: firstNameEllipsisCharLimit })}&nbsp;
                {isRowOwner ? '(Owner)' : ''}
              </div>
            </TooltipCustom>
          </div>
        );
      }
    },
    {
      key: 4,
      title: 'Last Name',
      dataIndex: 'lastName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Last Name</span>
        </div>
      ),
      bodyElement: (...args) => {
        const lastName = args[0] as string;
        const rowData = args[1] as unknown as TTableRowData;

        const { tooltipText } = disableUserRowCalc({
          userData: rowData as unknown as ITeamMangUser,
          isloggedInUserOwner,
          isloggedInUserAdmin,
          canUserViewAnotherUser
        });
        return (
          <TooltipCustom text={tooltipText} conditionToDisplay={Boolean(tooltipText)}>
            <div className="last-name" onClick={() => handleRowClick({ rowData })}>
              <TextTooltipWithEllipsis limit={10} text={lastName} />
            </div>
          </TooltipCustom>
        );
      }
    },
    {
      key: 5,
      title: 'Email',
      dataIndex: 'email',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Email</span>
        </div>
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        return <TextTooltipWithEllipsis limit={30} text={email} />;
      }
    },
    {
      key: 6,
      title: 'Teams',
      dataIndex: 'groups',
      headElement: () => (
        <div className="cell-inner">
          <span>Teams</span>
        </div>
      ),
      bodyElement: (...args) => {
        const groups = args[0] as string[];

        if (groups && groups?.length) {
          const rowData = args[1] as unknown as ITeamMangUser;
          const modalId = 'groups' + rowData.email;
          return (
            <div
              className="groups-row-cell user-groups"
              onMouseEnter={(e) => {
                e.stopPropagation();
                const elementPosition = (
                  e.target as HTMLElement
                ).getBoundingClientRect() as IElementPosition;
                // set the props of viewmore modal
                setViewMoreProps({
                  textColorClass: '',
                  bgColorClass: '',
                  data: groups,
                  parentClass: 'team-management-table',
                  clientX: elementPosition.left - 10,
                  clientY: elementPosition.bottom,
                  id: modalId
                });
                // opens the modal when mouse hovers
                setViewMore(true);
              }}
              onMouseLeave={() => setViewMore(false)}
            >
              {textEllipsis({ charLimit: 12, text: groups[0] })}{' '}
              <Typography as="span" variant="body-3" size="regular" className="view-more-modal">
                {groups.length > 1 ? `+ ${groups.length - 1} more` : ''}
              </Typography>
            </div>
          );
        }
        return <div className="groups-row-cell groups-row-cell--is-empty">&#8209;</div>;
      }
    },
    {
      key: 7,
      title: 'User Access',
      dataIndex: 'permissions',
      headElement: () => (
        <div className="cell-inner">
          <span>Access</span>
        </div>
      ),
      bodyElement: (...args) => {
        const rowData = args[1] as unknown as ITeamMangUser;
        const isOwner = rowData?.isOwner;

        const userAccesses = isOwner ? permissions : (args[0] as unknown as IUserPermissionsList[]);
        if (userAccesses && userAccesses?.length) {
          const rowData = args[1] as unknown as ITeamMangUser;
          const modalId = 'userAccess' + rowData.email;
          const allPermissions = userAccesses.map((item) => item.module);
          return (
            <div
              className="user-access-row-cell user-access"
              onMouseEnter={(e) => {
                e.stopPropagation();
                const elementPosition = (
                  e.target as HTMLElement
                ).getBoundingClientRect() as IElementPosition;
                setViewMoreProps({
                  textColorClass: '',
                  bgColorClass: '',
                  data: allPermissions,
                  parentClass: 'team-management-table',
                  clientX: elementPosition.left - 10,
                  clientY: elementPosition.top + elementPosition.height,
                  id: modalId
                });
                // opens the modal when mouse hovers
                setViewMore(true);
              }}
              onMouseLeave={() => setViewMore(false)}
            >
              {isOwner ? (
                'All'
              ) : (
                <>
                  {textEllipsis({ charLimit: 12, text: userAccesses[0].module })}
                  <Typography as="span" variant="body-3" size="regular" className="view-more-modal">
                    {userAccesses.length > 1 ? `+ ${userAccesses.length - 1} more` : ''}
                  </Typography>
                </>
              )}
            </div>
          );
        }
        return <div className="user-access-row-cell user-access-row-cell--is-empty">&#8209;</div>;
      }
    },
    {
      key: 8,
      title: 'Action',
      dataIndex: 'email',
      headElement: () => (
        <div className="cell-inner users-action-head">
          <span>Action</span>
        </div>
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        const userData = args[1] as unknown as ITeamMangUser;
        const { disabled: hideDeleteButton } = disableUserRowCheckboxCalc({
          userData,
          isloggedInUserOwner,
          isloggedInUserAdmin,
          canUserDeactivateAnotherUser
        });

        return (
          <div className="action">
            {hideDeleteButton ? (
              '-'
            ) : (
              <TrashIcon
                onClick={() => {
                  setSelectedRows([email]);
                  setIsRowSelectedForDeactivation(true);
                  setOpenArchiveModal(true);
                  queryClient.resetQueries(
                    getFetchArchivedUsersQueryKey({
                      limit: ROWS_PER_PAGE_FIRST_ITEM,
                      offset: offsetFirst,
                      filterUser: ''
                    })
                  );
                }}
                color={ERRORS_COLORS[700]}
              />
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div
      className={`team-management-table ${isLoading ? 'team-management-table--is-loading' : ''}`}
    >
      <UsersSummary
        selectedRows={selectedRows}
        setOpenArchiveModal={setOpenArchiveModal}
        userSummary={{
          totalActiveUsers: usersData?.totalActiveUsers || 0,
          totalDeactivatedUsers: usersData?.totalDeactivatedUsers || 0,
          usersAddedThisMonth: usersData?.usersAddedThisMonth || 0,
          usersDisconnectedThisMonth: usersData?.usersDisconnectedThisMonth || 0
        }}
      />{' '}
      <Typography className="team-management-table__label" as="p" size="medium" variant="body-2">
        Explorer
      </Typography>
      <SearchForm
        placeholder={TEAM_MANAGEMENT_TEXT.SEARCH_PLACEHOLDER}
        className="team-management-table__search"
        onSearch={searchUsersByString}
      />
      <div className="team-management-table__table" ref={tableHeightRef}>
        <CustomTable
          tableColumns={tableColumns}
          tableData={sortedData}
          totalItems={usersData?.count || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPageOptions={RowsPerPage}
          rowPropsFunc={rowPropsFunc}
          isLoading={isLoading}
          rowIdKey="email"
          selectedRows={selectedRows}
          sortState={dataSort}
          setSortState={setDataSort}
        />
      </div>
      <div className="team-management-table__view-more-modal">
        <ViewMoreModal
          {...viewMoreProps}
          viewMore={viewMore}
          setViewMore={setViewMore}
          textColor={PRIMARY_COLORS[700]}
        />
      </div>
      <RestoreArchiveUserModal
        heading={TEAM_MANAGEMENT_TEXT.DEACTIVATE_USERS}
        description={TEAM_MANAGEMENT_TEXT.CONFIRM_DEACTIVATE_USER}
        open={openArchiveModal}
        handleClose={closeArchiveModal}
        handlerFun={handleArchive}
        actionButtonText={isLoadingStart ? <Loader /> : BUTTON_TEXT.YES_DEACTIVATE}
        child="Deactivating this user will remove them from all active sessions."
        icon={IMAGES.ErrorExclamation}
      />
      <ConfirmModal
        title={TEAM_MANAGEMENT_TEXT.USER_DEACTIVATED}
        description={TEAM_MANAGEMENT_TEXT.USER_DEACTIVATE_SUCCESS}
        descriptionWidth="88%"
        confirmButtonText={BUTTON_TEXT.OKAY}
        open={openConfirmArchiveModal}
        setOpen={setOpenConfirmArchiveModal}
        image={confirmSuccess}
        handleOkClick={handleDeactivatedUserNavigation}
        handleClose={handleDeactivatedUserNavigation}
      />
    </div>
  );
};

export default Users;
