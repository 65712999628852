import { useEffect, useState } from 'react';

import { IUseCalculateElementHeight } from './useCalculateElementHeight.interface';

/*
This hook is useful when a height of particluar element is creating vertical scroll.
The primary output of this hook should be a number which restricts the height of the concerned element.
Can be use on only one element in a screen.

*/
const useCalculateElementHeight = ({
  ref,
  triggers = [],
  heightOffest = 0
}: IUseCalculateElementHeight) => {
  const [elementHeight, setElementHeight] = useState<number>(0);

  const calculateElementHeight = () => {
    if (ref.current) {
      // This step is to allow the table to take the normal height
      // ref.current.style.height = 'auto';
      const { y: heightofContentAboveElement, height: elementCurrentHeight } =
        ref.current.getBoundingClientRect();
      const { height: bodyHeight } = document.body.getBoundingClientRect();

      const heightofContentBelowElement =
        bodyHeight - heightofContentAboveElement - elementCurrentHeight;

      const considerHeightofContentBelowElement = window.innerWidth < 768;

      const elementHeightCalculated =
        window.innerHeight -
        heightofContentAboveElement -
        (considerHeightofContentBelowElement ? heightofContentBelowElement : heightOffest);

      setElementHeight(elementHeightCalculated);
    }
  };

  useEffect(() => {
    calculateElementHeight();
    // RecalculateToastMsgs if the screen size is changed.
    window.addEventListener('resize', calculateElementHeight);
    return () => window.removeEventListener('resize', calculateElementHeight);
  }, [ref, ...triggers]);

  return { elementHeight };
};

export default useCalculateElementHeight;
