import {
  addorPlusIcon,
  alertRedIcon,
  archiveIcon,
  arrowActiveIcon,
  arrowDisabledIcon,
  closeIcon,
  cloudInSpotLogo,
  cloudInSpotLogoDark,
  confirmGif,
  copyToClipboard,
  crossChevron,
  crossRedIcon,
  editPenIcon,
  emailColor,
  keyIcon,
  Loading,
  lockIcon,
  msTeamsColor,
  negativeFeedbackIcon,
  neutralFeedbackIcon,
  okIcon,
  plusBlueIcon,
  positiveFeedbackIcon,
  publicLayoutLogo,
  publicLayoutLogoSVG,
  reconnectIcon,
  reloadIcon,
  searchIcon,
  selectDownArrow,
  slackColor,
  sortAscIcon,
  sortDescIcon,
  sortIcon,
  tableCheckboxSelected,
  tooltipIcon,
  trashIcon,
  uploadPicture,
  warningIcon
} from './images';
import AWSActivateTrustedAccess from './images/Activate_Trusted_AWS.svg';
import Slack from './images/Slack.png';
import AWS_INDIVISUAL_ACCOUNT from './images/aws-indivisual-account.png';
import ErrorExclamation from './images/errorExclamation.svg';
import ClapsGIF from './images/gif/Claps.gif';
import AWS_OU_ID from './images/ou-id-aws.png';
import AzureCertificates from './images/png/AzureCertificates.png';
import AzureExpiration from './images/png/AzureExpiration.png';
import AzureAdDirectory from './images/png/Azure_AD _Directory.png';
import AzureAppID from './images/png/Azure_Application_ID.png';
import AureNewReg from './images/png/Azure_New_Registration.png';
import AzureRedirectURL from './images/png/Azure_Redirected_URL.png';
import ClientSecretValue from './images/png/Client-Secret-Value.png';
import ClientSecret from './images/png/Client-Secret.png';
import ConnectAWS from './images/png/Create Stack.png';
import GrantAdminConsent from './images/png/Grant-Admin-Consent.png';
import MicrosoftGraph from './images/png/Microsoft-Graph.png';
import UserRead from './images/png/User-Read.png';
import alertElasticIP from './images/png/alertElasticIP.png';
import alertGateway from './images/png/alertGateway.png';
import alertS3Bucket from './images/png/alertS3Bucket.png';
import ApiPermission from './images/png/api-permission.png';
import cardBGWave from './images/png/cardBGWave.png';
import AWSLogo from './images/svg/AWS-Logo.svg';
import CIS_Full_Logo from './images/svg/CIS_full_Logo.svg';
import CIS_Small_Logo from './images/svg/CIS_icon_small.svg';
import EmailBigLogo from './images/svg/EmailBigLogo1.svg';
import FAQ from './images/svg/FAQ.svg';
import MsTeamBigLogo from './images/svg/MSTeamBigLogo.svg';
import SlackBigLogo from './images/svg/SlackBigLogo.svg';
import activeCloudAccounts from './images/svg/activeCloudAccounts.svg';
import activeDashboard from './images/svg/activeDashboard.svg';
import activeFaqs from './images/svg/activeFaqs.svg';
import activeInsights from './images/svg/activeInsights.svg';
import activeNotifications from './images/svg/activeNotifications.svg';
import activeSettings from './images/svg/activeSettings.svg';
import activeTeamMgmt from './images/svg/activeTeamMgmt.svg';
import AnomalyDetection from './images/svg/anomaly.svg';
import disconnectAWS from './images/svg/aws_demo.svg';
import AzureLogo from './images/svg/azure_logo.svg';
import blueCross from './images/svg/blueCrossIcon.svg';
import check from './images/svg/check.svg';
import UnselectedCheckMark from './images/svg/checkMark_not_selected.svg';
import SelectedCheckMark from './images/svg/checkMark_selected.svg';
import ChevronIcon from './images/svg/chevron_icon.svg';
import Cloud from './images/svg/cloud.svg';
import cloudInSpotLogoSVG from './images/svg/cloudInSpotLogo.svg';
import copyOneThirdQuadrant from './images/svg/copy.svg';
import crossIcon from './images/svg/crossIcon.svg';
import Dashboard from './images/svg/dashboard.svg';
import DefaultServiceProviderLogo from './images/svg/defaultServiceProvider.svg';
import disabledDownArrow from './images/svg/disabledDownArrow.svg';
import disConnectBlue from './images/svg/disconnectBlue.svg';
import disconnectBlueLeft from './images/svg/disconnectBlueLeft.svg';
import DisconnectAccount from './images/svg/disconnect_account.svg';
import editBluePen from './images/svg/editBluePen.svg';
import backArrow from './images/svg/extendedBackArrow.svg';
import VisibilityOffEye from './images/svg/eye_hide.svg';
import VisibilityOnEye from './images/svg/eye_show.svg';
import Feedback from './images/svg/feedback.svg';
import inProgressTools from './images/svg/inProgressTools.svg';
import infoCircle from './images/svg/info-circle.svg';
import Insights from './images/svg/insights.svg';
import Integration from './images/svg/integration.svg';
import LeftArrow from './images/svg/leftArrow.svg';
import Logout from './images/svg/logout.svg';
import note from './images/svg/note.svg';
import Notification from './images/svg/notification.svg';
import pause from './images/svg/pause.svg';
import PlusWhite from './images/svg/plus-white.svg';
import Plus from './images/svg/plus.svg';
import Profile from './images/svg/profile.svg';
import ReconfigureIcon from './images/svg/reconfigureIcon.svg';
import refresh from './images/svg/refresh.svg';
import Restore from './images/svg/restoreIcon.svg';
import resume from './images/svg/resume.svg';
import resumeRound from './images/svg/resumeRound.svg';
import RightArrow from './images/svg/rightArrow.svg';
import roundedSelectedBlueCheckmark from './images/svg/roundedSelectedBlueCheckmark.svg';
import Settings from './images/svg/settings.svg';
import SidebarCollapseButton from './images/svg/sidebarCollapseButton.svg';
import Team from './images/svg/team.svg';
import users from './images/svg/users.svg';
import XCircle from './images/svg/x-circle.svg';
const IMAGES = {
  VisibilityOffEye,
  VisibilityOnEye,
  AnomalyDetection,
  CIS_Full_Logo,
  CIS_Small_Logo,
  Cloud,
  Dashboard,
  FAQ,
  Feedback,
  Insights,
  Integration,
  Settings,
  Team,
  UnselectedCheckMark,
  SelectedCheckMark,
  LeftArrow,
  RightArrow,
  Notification,
  Profile,
  Logout,
  cloudInSpotLogo,
  cloudInSpotLogoDark,
  closeIcon,
  selectDownArrow,
  publicLayoutLogo,
  publicLayoutLogoSVG,
  uploadPicture,
  copyToClipboard,
  editPenIcon,
  keyIcon,
  tooltipIcon,
  confirmGif,
  searchIcon,
  addorPlusIcon,
  sortIcon,
  sortAscIcon,
  sortDescIcon,
  okIcon,
  crossRedIcon,
  alertRedIcon,
  Plus,
  arrowActiveIcon,
  arrowDisabledIcon,
  warningIcon,
  reloadIcon,
  DisconnectAccount,
  disconnectAWS,
  ConnectAWS,
  AWSActivateTrustedAccess,
  ClapsGIF,
  ErrorExclamation,
  reconnectIcon,
  copyOneThirdQuadrant,
  tableCheckboxSelected,
  archiveIcon,
  trashIcon,
  lockIcon,
  Restore,
  plusBlueIcon,
  blueCross,
  crossChevron,
  users,
  SidebarCollapseButton,
  pause,
  slackColor,
  msTeamsColor,
  emailColor,
  SlackBigLogo,
  EmailBigLogo,
  ReconfigureIcon,
  disConnectBlue,
  Slack,
  refresh,
  resume,
  positiveFeedbackIcon,
  neutralFeedbackIcon,
  negativeFeedbackIcon,
  inProgressTools,
  note,
  editBluePen,
  disabledDownArrow,
  roundedSelectedBlueCheckmark,
  activeDashboard,
  activeCloudAccounts,
  activeInsights,
  activeNotifications,
  activeTeamMgmt,
  activeSettings,
  activeFaqs,
  alertElasticIP,
  alertGateway,
  alertS3Bucket,
  cardBGWave,
  cloudInSpotLogoSVG,
  crossIcon,
  backArrow,
  resumeRound,
  check,
  disconnectBlueLeft,
  MsTeamBigLogo,
  AWSLogo,
  AzureLogo,
  DefaultServiceProviderLogo,
  infoCircle,
  Loading,
  AureNewReg,
  AzureAdDirectory,
  AzureRedirectURL,
  AzureAppID,
  AzureCertificates,
  AzureExpiration,
  PlusWhite,
  XCircle,
  AWS_OU_ID,
  AWS_INDIVISUAL_ACCOUNT,
  ClientSecret,
  ClientSecretValue,
  MicrosoftGraph,
  ApiPermission,
  UserRead,
  GrantAdminConsent,
  ChevronIcon
};

export default IMAGES;
