const API_ENDPOINTS = {
  BASE_URL: '/',
  OWNER_CONFIRM_SIGN_UP: '/auth/confirm-sign-up',
  OWNER_RESEND_SIGN_UP_OTP: '/auth/resend-sign-up-otp',
  USER_FORGOT_PASSWORD: '/auth/forgot-password',
  USER_CONFIRM_FORGOT_PASSWORD: '/auth/confirm-forgot-password',
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  SIGN_OUT: '/auth/sign-out',
  CHANGE_PASSWORD: '/profile/change-password',
  GET_NEW_TOKEN: '/auth/get-new-tokens',
  SAVE_OWNER_DETAILS: '/organization/save-org-details',
  GET_USER: '/profile/get-profile',
  EDIT_USER: '/profile/edit-profile',
  GET_PRESIGNED_URL: '/profile/get-presigned-url',
  DELETE_PROFILE_PIC: '/profile/delete-profile-pic',
  FORCE_CHANGE_PASSWORD: '/auth/force-change-password',
  //CLOUD_ACCOUNTS
  CLOUD_DISCONNECT_ACCOUNT: '/accounts/disconnect-account',
  CLOUD_CONFIRM_DISCONNECT: '/accounts/confirm-disconnect-account',
  CLOUD_ACCOUNTS_LIST: '/accounts/list',
  CLOUD_GET_ACCOUNT: '/accounts/get-account',
  CLOUD_POLICY: '/accounts/policy',
  CLOUD_ADD_ACCOUNT: '/accounts/add-account',
  CLOUD_EDIT_ACCOUNT: '/accounts/edit-account',
  CLOUD_DELETE_ACCOUNT: '/accounts/delete-account',
  CLOUD_CONFIRM_CONNECT_ACCOUNT: '/accounts/confirm-account',
  CLOUD_ACCOUNT_POLICIES: '/accounts/get-policies',
  CLOUD_GET_SLACK_CONNECTION_DETAILS: '/accounts/slack-get-account-to-channel-mapping',
  CLOUD_SLACK_INSTALLATION: '/accounts/slack-list-channels',
  SLACK_CHANNELS_LIST: '/accounts/slack-list-channels',
  SLACK_ADD_TO_SLACK: '/accounts/slack-add-account-to-channel',
  SLACK_DISCONNECT: '/accounts/slack-disconnect-account-from-channel',
  CLOUD_ACCOUNTS_LIST_ACCOUNT_USER_EMAIL_NOTIFICATION:
    '/accounts/list-account-user-email-notification',
  CLOUD_ACCOUNTS_ADD_USER_EMAIL_NOTIFICATION: '/accounts/add-account-user-email-notification',
  CLOUD_ACCOUNTS_REMOVE_USER_EMAIL_NOTIFICATION: '/accounts/remove-account-user-email-notification',
  CLOUD_ACCOUNT_PAUSE_ACC: '/accounts/pause-account',
  CLOUD_ACCOUNT_RESUME_ACC: '/accounts/resume-account',
  CLOUD_SLACK_OAUTH_INSTALL: '/accounts/slack-oauth-install',
  CLOUD_TEAM_MGMT_LIST_USERS_EMAIL_NOTIFICATION: '/accounts/list-users-for-email-notification',

  // TEAM_MANAGEMENT
  TEAM_MGMT_ADD_USER: '/team-mgmt/add-user',
  TEAM_MGMT_ADD_USER_TO_GROUPS: '/team-mgmt/add-user-to-groups',
  TEAM_MGMT_ADD_USERS_TO_GROUP: '/team-mgmt/add-users-to-group',
  TEAM_MGMT_EDIT_USER: '/team-mgmt/edit-user',
  TEAM_MGMT_EDIT_GROUP: '/team-mgmt/edit-group',
  TEAM_MGMT_GET_GROUP_PERMISSIONS: '/team-mgmt/get-group-permissions',
  TEAM_MGMT_GET_USER: '/team-mgmt/get-user',
  TEAM_MGMT_GET_USER_ACCESS_CONTROL: '/team-mgmt/get-access-control',
  TEAM_MGMT_EDIT_USER_ACCESS_CONTROL: '/team-mgmt/edit-access-control',
  TEAM_MGMT_LIST_USERS: '/team-mgmt/list-users',
  TEAM_MGMT_LIST_ARCHIVED_USERS: '/team-mgmt/list-archived-users',
  TEAM_MGMT_LIST_ARCHIVE_USERS: '/team-mgmt/archive-user',
  TEAM_MGMT_LIST_RESTORE_USERS: '/team-mgmt/restore-user',
  TEAM_MGMT_LIST_DELETE_USERS: '/team-mgmt/delete-user',
  TEAM_MGMT_LIST_GROUPS: '/team-mgmt/list-groups',
  TEAM_MGMT_DELETE_GROUPS: '/team-mgmt/delete-group',
  TEAM_MGMT_REMOVE_USER_FROM_GROUPS: '/team-mgmt/remove-user-from-groups',
  TEAM_MGMT_RESET_PASSWORD: '/team-mgmt/reset-password',
  TEAM_MGMT_GET_GROUP_USERS: '/team-mgmt/get-group-users',
  REMOVE_USERS_FROM_GROUP: '/team-mgmt/remove-users-from-group',
  TEAM_MGMT_CREATE_GROUP: '/team-mgmt/create-group',
  ADD_USERS_TO_GROUP: '/team-mgmt/add-users-to-group',

  // FAQS
  FAQS: 'https://cis-public-assets.s3.ap-south-1.amazonaws.com/FAQ/faq.json',
  //submit feedback

  //  Need Help Modal
  NEED_HELP: '/need-help',
  NEED_HELP_GET_PRESIGNED_URL: '/need-help-get-presigned-url',
  SEND_SLACK_NEED_HELP_MESSAGE: '/send-slack-need-help-message',

  // MS TEAM
  MS_TEAM_CONNECT_WEBHOOK: '/accounts/teams-add-webhook',
  MS_TEAM_DISCONNECT_WEBHOOK: '/accounts/teams-delete-webhook',
  MS_TEAM_ACCOUNT_VIEW: '/accounts/teams-get-mapping',

  SUBMIT_FEEDBACK: '/submit-feedback',
  //settings
  SETTINGS_GET_NOTIFICATION_SETTINGS: '/organization/get-notification-settings',
  SETTINGS_EDIT_NOTIFICATION_SETTINGS: '/organization/edit-org-notification-settings',
  SETTINGS_GET_ORGANIZATION_DETAILS: '/organization/get-org-details',
  SETTINGS_EDIT_ORGANIZATION_DETAILS: '/organization/edit-org-details',
  DELETE_ORGANIZATION: '/organization/delete-org'
};

export default API_ENDPOINTS;
