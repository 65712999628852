// eslint-disable-next-line import/named
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IForgotPassword } from './ForgotPassword.types';
import ForgoPasswordValidationSchema from './ForgotPassword.validationSchema';
import './forgotPassword.scss';

import { confirmSuccess } from '../../assets/images';
import '../../assets/styles/index.scss';
import CustomButton from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import AuthFormContainer from '../../components/common/formContainer/AuthFormContainer';
import TextInput from '../../components/common/textInput/TextInput';
import '../../components/user-authentication/UserAuth.css';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { routes } from '../../constants/routes';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import services from '../../services';
import useForgotPasswordStore from '../../store/forgotPassword/useForgotPasswordStore';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [isCodeSentModalOpen, setIsCodeSentModalOpen] = useState<boolean>(false);

  const {
    forgotPasswordFormValues: { orgId, forgotEmail },
    setForgotPasswordFormValues: setForgotPasswordInitialState
  } = useForgotPasswordStore();
  const [routeData, setRouteData] = useState<IForgotPassword>({
    org_id: orgId,
    email: forgotEmail
  });
  const handleSubmit = async (forgotPasswordInitialValues: IForgotPassword) => {
    // Make a GET request
    await services
      .get({
        url: `${API_ENDPOINTS.USER_FORGOT_PASSWORD}?organizationId=${forgotPasswordInitialValues.org_id}&email=${forgotPasswordInitialValues.email}`
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Update route state & open success confirmation modal
          setRouteData(forgotPasswordInitialValues);
          setIsCodeSentModalOpen(true);
        }
      })
      .catch((error: unknown) => {
        useErrorHandler({
          error,
          toastId: 'OTPSentFail',
          defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
        });
      });
  };

  const clearOrgIdEmail = () => {
    setForgotPasswordInitialState('orgId', '');
    setForgotPasswordInitialState('forgotEmail', '');
  };

  const handleGoBack = () => {
    clearOrgIdEmail();
    navigate(routes.SIGN_IN);
  };

  // Handle navigation
  const handleNavigation = useCallback(() => {
    // navigate to set Password screen with params as organizationId and email with forgotPassword state
    navigate(`${routes.SET_PASSWORD}?organizationId=${routeData.org_id}&email=${routeData.email}`, {
      state: {
        isRedirectedFromForgotPassword: true
      }
    });
  }, [routeData]);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  });

  return (
    <AuthFormContainer
      heading="Forgot Password"
      backToPrevScreenText="Sign In"
      handleBackToPrev={handleGoBack}
    >
      <Formik
        initialValues={routeData}
        onSubmit={handleSubmit}
        validationSchema={ForgoPasswordValidationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, touched }) => {
          return (
            <Form className="forgotPassword" noValidate>
              <TextInput
                label="Organization ID"
                name="org_id"
                type="text"
                placeholder="Enter Organization ID"
                errors={errors}
                touched={touched}
                data-testid="email"
                required={true}
                onChangeInput={(value: string) => {
                  setForgotPasswordInitialState('orgId', value);
                }}
              />

              <TextInput
                label="Email"
                name="email"
                type="text"
                placeholder="Enter Email"
                errors={errors}
                touched={touched}
                data-testid="email"
                required={true}
                onChangeInput={(value: string) => {
                  setForgotPasswordInitialState('forgotEmail', value);
                }}
              />

              {/* submit button */}
              <CustomButton
                className="w-full"
                text="Send Verification Code"
                type="submit"
                isLoading={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
      {isCodeSentModalOpen && (
        <ConfirmModal
          open={true}
          title="Verification Code Sent!"
          description="The verification code has been successfully sent to your email address."
          descriptionWidth="88%"
          confirmButtonText="OKAY"
          image={confirmSuccess}
          setOpen={() => setIsCodeSentModalOpen(false)}
          handleClose={handleNavigation}
          handleOkClick={handleNavigation}
        />
      )}
    </AuthFormContainer>
  );
};

export default ForgotPassword;
