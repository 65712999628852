import {
  SET_INSIGHTS_CURRENT_ACTIVE_TAB_FORM,
  UPDATE_INSIGHTS_TABLES_TYPE
} from './insights.actionTypes';
import { TInsightsTablesType } from './insights.interface';

export const setInsightsCurrentActiveTabForm = (state: number) => ({
  type: SET_INSIGHTS_CURRENT_ACTIVE_TAB_FORM,
  payload: state
});

export const updateInsightsTable = (state: TInsightsTablesType) => ({
  type: UPDATE_INSIGHTS_TABLES_TYPE,
  payload: state
});
