import { useMemo, useState } from 'react';

import { IEditUserGroupsProps } from './editUser.interface';
import './editUserGroups.scss';

import IMAGES from '../../../assets';
import { MY_PROFILE_TEXT } from '../../../configs';
import { RowsPerPage } from '../../../constants/constants';
import { routes } from '../../../constants/routes';
import ENUMS from '../../../enums';
import useFetchTeamUserProfile from '../../../hooks/teamManagement/useFetchTeamUserProfile/useFetchTeamUserProfile';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { selectDeslectCheckbox, sortObjectArray } from '../../../utils';
import { TSortType } from '../../../utils/sortObjectArray/sortObjectArray.interface';
import TextTooltipWithEllipsis from '../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import CustomTable from '../../common/commonTable/CustomTable';
import { ITableColumns } from '../../common/commonTable/customTable.interface';

const rowsPerPageOptions = RowsPerPage;

const { userRoles } = ENUMS.teamManagement;

const EditUserGroups = ({
  editUserRemoveUserFromGroupsList,
  setEditUserRemoveUserFromGroupsList
}: IEditUserGroupsProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });

  const { teamUserProfile: teamUser, isLoading } = useFetchTeamUserProfile(true);

  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = teamUser?.userProfileData.groups.map((item: string, index: number) => {
      return { groupName: item, itemNo: startIndex + index + 1 };
    });

    const newData = sortObjectArray({
      arr: totalItems || [],
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];
    return newData;
  }, [
    dataSort,
    teamUser?.userProfileData.groups,
    pageNumber,
    rowsPerPage,
    editUserRemoveUserFromGroupsList,
    teamUser?.userProfileData
  ]);

  const onRowSelection = (value: string) => {
    const newArray = selectDeslectCheckbox({
      arr: editUserRemoveUserFromGroupsList,
      value
    }) as string[];
    setEditUserRemoveUserFromGroupsList(() => [...newArray]);
  };

  const tableColumns: ITableColumns[] = [
    {
      key: 2,
      title: 'Team Name',
      dataIndex: 'groupName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Team Names</span>
        </div>
      ),
      bodyElement: (...args) => {
        const groupName = args[0] as string;
        return (
          <span className="group-name">
            {groupName === userRoles.Admin && <img src={IMAGES.lockIcon} alt={userRoles.Admin} />}
            <TextTooltipWithEllipsis limit={30} text={groupName} />
          </span>
        );
      }
    },
    {
      key: 3,
      title: 'Action',
      dataIndex: 'groupName',
      headElement: () => (
        <div className="cell-inner text-center">
          <span>Action</span>
        </div>
      ),
      bodyElement: (...args) => {
        const groupName = args[0] as string;
        return (
          <div className="cell-inner text-left">
            <input
              type="checkbox"
              onChange={(e) => {
                e.stopPropagation();
                onRowSelection(groupName);
              }}
              onClick={(e) => {
                // To prevent on row click handler propagating.
                e.stopPropagation();
              }}
              className="checkbox"
              checked={!editUserRemoveUserFromGroupsList.includes(groupName)}
            />
          </div>
        );
      }
    }
  ];

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: MY_PROFILE_TEXT.USERS, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      {
        text: teamUser?.userProfileData.firstName + ' ' + teamUser?.userProfileData.lastName,
        url: routes.TEAM_MANAGEMENT_USER
      },
      { text: MY_PROFILE_TEXT.EDIT_ACCESS_CONTROL, url: '' }
    ]
  });

  return (
    <div className="team-management-table edit-user-group-table">
      <div className="team-management-table__table edit-user-group-table__table">
        <CustomTable
          tableColumns={tableColumns}
          tableData={sortedData}
          totalItems={teamUser?.userProfileData.groups.length || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPageOptions={rowsPerPageOptions}
          isLoading={isLoading}
          shouldShowPagination={false}
          sortState={dataSort}
          setSortState={setDataSort}
        />
      </div>
    </div>
  );
};

export default EditUserGroups;
