import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import IMAGES from '../../assets';
import { confirmProgress, pendingIcon, successIcon } from '../../assets/images';
import { IConnectAccountProps } from '../../components/cloudAccounts/cloudAccounts.interface';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import Loader from '../../components/common/loader/loader';
import LogoWithHeading from '../../components/common/logo-with-heading/logoWithHeading';
import Typography from '../../components/common/typography/Typography';
import { CLOUD_ACCOUNTS_TEXT } from '../../configs';
import { routes } from '../../constants/routes';
import ENUMS from '../../enums';
import getUserData from '../../helpers/getUserData';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import CloudAccountService from '../../services/cloudAccount.service';
import {
  TAddAccountRequest,
  TAddAccountResponse
} from '../../services/types/cloudAccount.endpoints';
import { setHeading } from '../../store/actions';
// eslint-disable-next-line import/order
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';

import './connectAzureSteps.scss';

import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../constants/constants';
import useAccountSummaryStore from '../../store/cloudAccounts/useAccountSummaryStore';
import { getFetchAccountQueryKey } from '../../utils/pre-fetchers/prefetchCloudAccounts';

const { CloudServiceProvider } = ENUMS.cloudAccounts;

const ConnectAccountSteps = ({ handleSetIsStepsOpen }: IConnectAccountProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isinProgressOpen, setIsinProgressOpen] = useState<boolean>(false);

  const connectAccountStepsRef = useRef<HTMLDivElement>(null);
  const { setHasAccounts } = useAccountSummaryStore();
  const { connectToAzureData } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isConfirmRequest: boolean = location.pathname.includes('/confirm');

  const handleConnectAzure = async () => {
    setLoading(true);
    try {
      const { email } = getUserData();

      const addAccountRequest: TAddAccountRequest = {
        createdBy: email,
        accountId: connectToAzureData.subscriptionKey,
        tenantId: connectToAzureData.tenantId,
        accountName: connectToAzureData.accountName,
        budget: connectToAzureData.budget,
        CSP: CloudServiceProvider.Azure,
        clientId: connectToAzureData.clientId,
        clientSecret: connectToAzureData.clientSecret
      };

      const addAccountResponse: TAddAccountResponse = await CloudAccountService.addAccount(
        addAccountRequest
      );
      // reset cloud account list cache after adding new account
      queryClient.resetQueries(
        getFetchAccountQueryKey({
          limit: ROWS_PER_PAGE_FIRST_ITEM,
          offset: offsetFirst
        })
      );
      setHasAccounts(true);
      window.open(addAccountResponse.redirectUrl, '_Top');
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'ConnectAzurefail'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToCloudAccounts = () => {
    queryClient.resetQueries(
      getFetchAccountQueryKey({
        limit: ROWS_PER_PAGE_FIRST_ITEM,
        offset: offsetFirst
      })
    );
    navigate(routes.CLOUD_ACCOUNTS, { replace: true });
  };

  const handleVisitAzure = () => {
    window.open('https://portal.azure/', '_blank');
  };

  dispatch(setHeading('Cloud Accounts'));
  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },

      {
        text: CLOUD_ACCOUNTS_TEXT.CONNECT_AZURE,
        url: ''
      }
    ]
  });

  return (
    <PaperCustom variant="medium">
      <div className="connect-account-steps" ref={connectAccountStepsRef}>
        {/* In Progress */}
        {isinProgressOpen && (
          <ConfirmModal
            open={isinProgressOpen}
            setOpen={setIsinProgressOpen}
            title={`Connection in progress !`}
            image={confirmProgress}
            confirmButtonText="OKAY"
            description={
              <>
                Connecting your azure account to <CloudInSpotName />. This may take a moment. Thank
                you for your patience!
              </>
            }
            descriptionWidth="88%"
            handleOkClick={handleNavigateToCloudAccounts}
          />
        )}
        {/* Steps */}
        <div className="flex flex-col gap-[24px]">
          <LogoWithHeading
            logoSrc={IMAGES.infoCircle}
            headingText={CLOUD_ACCOUNTS_TEXT.STEPS_TO_CONNECT_YOUR_AZURE_ACCOUNT}
            className="connect-azure-heading"
          />
          {/* Step 1 */}
          <div className="flex flex-col gap-[12px]">
            <div className="container-steps-status">
              <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
                Step 1 : Accept the permission
              </Typography>

              <div className={`container-status ${!isConfirmRequest ? ' pending' : 'success'}`}>
                <div className="i-button">
                  <img
                    src={!isConfirmRequest ? pendingIcon : successIcon}
                    alt="icon"
                    loading="lazy"
                    className="status_icon"
                  />
                </div>
                <div className={`status-text  ${!isConfirmRequest ? ' pending' : 'success'}`}>
                  {!isConfirmRequest ? 'Step pending' : ' Step completed'}
                </div>
              </div>
            </div>

            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="pl-8 border-l border-l-border_disabled ml-5 pr-6 lg:pr-20 stepDesc"
            >
              Click the &quot;Save and Connect to Azure&quot; button. If you&apos;re not signed in,
              you&apos;ll be prompted to log into your Azure account. After signing in, check the
              box labeled &quot;Consent on behalf of your organization,&quot; and click
              &quot;Accept&quot; to grant the permissions. Once the process is complete, the status
              will update from &quot;Step pending&quot; to &quot;Step complete.&quot;
            </Typography>
          </div>
          {/* Step 2 */}
          <div className="flex flex-col gap-[12px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 2 : Navigate to Subscriptions
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="pl-8 border-l border-l-border_disabled ml-5 pr-6 lg:pr-20 stepDesc"
            >
              After being redirected to this page, click the &quot;Visit Azure Portal&quot; button.
              Navigate to the Subscriptions section in the main menu, potentially under &quot;All
              services&quot;, to manage subscription settings.&quot; (you may need to find it under
              &quot;All services&quot;).
            </Typography>
          </div>

          {/* step-3 */}
          <div className="flex flex-col gap-[12px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 3 : Assign Role
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="pl-8 border-l border-l-border_disabled ml-5 pr-6 lg:pr-20 stepDesc"
            >
              Select the subscription and open its configuration pane. Click on &quot;Access Control
              (IAM)&quot; to proceed.
            </Typography>
          </div>

          {/* step-4 */}

          <div className="flex flex-col gap-[12px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 4 : Add Role
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="pl-8 border-l border-l-border_disabled ml-5 pr-6 lg:pr-20 stepDesc"
            >
              In the IAM, click &apos;Add&apos;, then select &apos;Add role assignment&apos;. Search
              and select the &apos;Billing Reader&apos; role, Click on &quot;Next&quot; button.
              Next, click &apos;Select Member&apos;, search for your app, and select it. Once
              selected, click &apos;Next,&apos; then click &apos;Review + Assign&apos;. Repeat these
              steps to assign the &apos;Reader&apos; role in the same way.
            </Typography>
          </div>

          {/* step-5 */}

          <div className="flex flex-col gap-[12px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 5 : Complete the above steps
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="pl-8 border-l border-l-border_disabled ml-5 pr-6 lg:pr-20 stepDesc"
            >
              Once you have completed the steps above, click the &quot;Finish&quot; button to
              complete the Azure account connection. After clicking &quot;Finish,&quot; the account
              status will initially be &quot;In Progress&quot; and will update to &quot;Active&quot;
              after some time.
            </Typography>
          </div>
        </div>
        {!isConfirmRequest ? (
          <div className="bottomCardWrapper">
            <PaperCustom className="bottomCardWrapper__bottomCard">
              <CustomButton
                text="Back"
                onClick={handleSetIsStepsOpen}
                variant={ButtonVariants.SECONDARY}
                className="back_btn"
              />
              <div className="bottomCardWrapper__bottomCard__btnContainer">
                <CustomButton
                  text={loading ? <Loader /> : CLOUD_ACCOUNTS_TEXT.CONNECT_AND_SAVE_TO_AZURE}
                  onClick={handleConnectAzure}
                  className="connect_and_save_btn px-[23px] whitespace-nowrap"
                />
              </div>
            </PaperCustom>
          </div>
        ) : (
          <div className="bottomCardWrapper">
            <PaperCustom className="bottomCardWrapper__bottomCard confirm">
              <div className="bottomCardWrapper__bottomCard__btnContainer">
                <CustomButton
                  text={loading ? <Loader /> : 'Visit Azure Portal'}
                  onClick={handleVisitAzure}
                  className="visit_azure_portal_btn px-[23px] whitespace-nowrap"
                />
              </div>
              <CustomButton
                text="Finish"
                onClick={() => setIsinProgressOpen(true)}
                className={`azure_finsh_btn h-[40px] px-28 sm:px-8 bottomCardWrapper__bottomCard__btnContainer__confirmBtn cursor-pointer`}
              />
            </PaperCustom>
          </div>
        )}
      </div>
    </PaperCustom>
  );
};

export default ConnectAccountSteps;
