import { useEffect, useState } from 'react';

import GroupNameInput from '../../components/teamManagement/Groups/addGroup/GroupNameInput';
import AddGroupPermissions from '../../components/teamManagement/Groups/addGroup/AddGroupPermissions';
import AddUsers from '../../components/teamManagement/Groups/addGroup/AddUsers';
import '../../components/teamManagement/Groups/addGroup/addGroup.scss';
import CustomStepsForm from '../../components/common/customStepsForm/CustomStepsForm';
import useGetAddGroupSteps from '../../hooks/customStepsForm/useGetAddGroupSteps';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  addGroupName,
  setActiveTab,
  setAddUsersToGroupList,
  setCurrentActiveTabForm,
  setHeading,
  updateAddUsersToGroupList
} from '../../store/actions';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { routes } from '../../constants/routes';
import { IPermissionsList } from '../../store/teamManagement/teamManagement.interface';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';

const AddGroup = () => {
  // While creating a group, there should not be any default permission
  const [addGroupSelectedPermissions, setAddGroupSelectedPermissions] = useState<
    IPermissionsList[]
  >([]);
  const { currentActiveTabForm } = useAppSelector((state) => state.teamManagement);
  const dispatch = useAppDispatch();

  const steps = useGetAddGroupSteps();

  const AddGroupForm = [
    <GroupNameInput key={0} />,
    <AddGroupPermissions
      key={1}
      addGroupSelectedPermissions={addGroupSelectedPermissions}
      setAddGroupSelectedPermissions={setAddGroupSelectedPermissions}
    />,
    <AddUsers key={2} addUserSelectedPermissions={addGroupSelectedPermissions} />
  ];

  const onPageLoad = () => {
    dispatch(setHeading('Add Team'));
    dispatch(setActiveTab(5));
    dispatch(setCurrentActiveTabForm(0));
  };

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      { text: TEAM_MANAGEMENT_TEXT.GROUPS, url: routes.TEAM_MANAGEMENT_GROUPS_TABLE },
      { text: 'Add Team', url: '' }
    ]
  });

  useEffect(() => {
    onPageLoad();
    return () => {
      dispatch(addGroupName(''));
      dispatch(updateAddUsersToGroupList([]));
      dispatch(setAddUsersToGroupList([]));
    };
  }, []);
  return (
    <PaperCustom variant="light" className="addGroupContainer">
      <CustomStepsForm activeTab={currentActiveTabForm} steps={steps} />
      {AddGroupForm[currentActiveTabForm]}
    </PaperCustom>
  );
};

export default AddGroup;
