export enum Timezone {
  PACIFIC_NIUE = 'Pacific/Niue, (GMT-11:00) Niue',
  PACIFIC_PAGO_PAGO = 'Pacific/Pago_Pago, (GMT-11:00) Pago Pago',
  PACIFIC_HONOLULU = 'Pacific/Honolulu, (GMT-10:00) Hawaii Time',
  PACIFIC_RAROTONGA = 'Pacific/Rarotonga, (GMT-10:00) Rarotonga',
  PACIFIC_TAHITI = 'Pacific/Tahiti, (GMT-10:00) Tahiti',
  PACIFIC_MARQUESAS = 'Pacific/Marquesas, (GMT-09:30) Marquesas',
  AMERICA_ANCHORAGE = 'America/Anchorage, (GMT-09:00) Alaska Time',
  PACIFIC_GAMBIER = 'Pacific/Gambier, (GMT-09:00) Gambier',
  AMERICA_LOS_ANGELES = 'America/Los_Angeles, (GMT-08:00) Pacific Time',
  PACIFIC_PITCAIRN = 'Pacific/Pitcairn, (GMT-08:00) Pitcairn',
  AMERICA_TIJUANA = 'America/Tijuana, (GMT-08:00) Pacific Time - Tijuana',
  AMERICA_VANCOUVER = 'America/Vancouver, (GMT-08:00) Pacific Time - Vancouver',
  AMERICA_WHITEHORSE = 'America/Whitehorse, (GMT-08:00) Pacific Time - Whitehorse',
  AMERICA_PHOENIX = 'America/Phoenix, (GMT-07:00) Mountain Time - Arizona',
  AMERICA_MAZATLAN = 'America/Mazatlan, (GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  AMERICA_DENVER = 'America/Denver, (GMT-07:00) Mountain Time',
  AMERICA_DAWSON_CREEK = 'America/Dawson_Creek, (GMT-07:00) Mountain Time - Dawson Creek',
  AMERICA_EDMONTON = 'America/Edmonton, (GMT-07:00) Mountain Time - Edmonton',
  AMERICA_HERMOSILLO = 'America/Hermosillo, (GMT-07:00) Mountain Time - Hermosillo',
  AMERICA_YELLOWKNIFE = 'America/Yellowknife, (GMT-07:00) Mountain Time - Yellowknife',
  AMERICA_BELIZE = 'America/Belize, (GMT-06:00) Belize',
  AMERICA_COSTA_RICA = 'America/Costa_Rica, (GMT-06:00) Costa Rica',
  AMERICA_CHICAGO = 'America/Chicago, (GMT-06:00) Central Time',
  AMERICA_EL_SALVADOR = 'America/El_Salvador, (GMT-06:00) El Salvador',
  PACIFIC_GALAPAGOS = 'Pacific/Galapagos, (GMT-06:00) Galapagos',
  AMERICA_GUATEMALA = 'America/Guatemala, (GMT-06:00) Guatemala',
  AMERICA_MANAGUA = 'America/Managua, (GMT-06:00) Managua',
  AMERICA_MEXICO_CITY = 'America/Mexico_City, (GMT-06:00) Central Time - Mexico City',
  AMERICA_REGINA = 'America/Regina, (GMT-06:00) Central Time - Regina',
  AMERICA_TEGUCIGALPA = 'America/Tegucigalpa, (GMT-06:00) Central Time - Tegucigalpa',
  AMERICA_WINNIPEG = 'America/Winnipeg, (GMT-06:00) Central Time - Winnipeg',
  AMERICA_CANCUN = 'America/Cancun, (GMT-05:00) America Cancun',
  AMERICA_BOGOTA = 'America/Bogota, (GMT-05:00) Bogota',
  AMERICA_CAYMAN = 'America/Cayman, (GMT-05:00) Cayman',
  PACIFIC_EASTER = 'Pacific/Easter, (GMT-05:00) Easter Island',
  AMERICA_NEW_YORK = 'America/New_York, (GMT-05:00) Eastern Time',
  AMERICA_GUAYAQUIL = 'America/Guayaquil, (GMT-05:00) Guayaquil',
  AMERICA_HAVANA = 'America/Havana, (GMT-05:00) Havana',
  AMERICA_IQALUIT = 'America/Iqaluit, (GMT-05:00) Eastern Time - Iqaluit',
  AMERICA_JAMAICA = 'America/Jamaica, (GMT-05:00) Jamaica',
  AMERICA_LIMA = 'America/Lima, (GMT-05:00) Lima',
  AMERICA_NASSAU = 'America/Nassau, (GMT-05:00) Nassau',
  AMERICA_PANAMA = 'America/Panama, (GMT-05:00) Panama',
  AMERICA_PORT_AU_PRINCE = 'America/Port-au-Prince, (GMT-05:00) Port-au-Prince',
  AMERICA_RIO_BRANCO = 'America/Rio_Branco, (GMT-05:00) Rio Branco',
  AMERICA_TORONTO = 'America/Toronto, (GMT-05:00) Eastern Time - Toronto',
  AMERICA_PORT_OF_SPAIN = 'America/Port_of_Spain, (GMT-04:00) Port of Spain',
  AMERICA_BARBADOS = 'America/Barbados, (GMT-04:00) Barbados',
  ATLANTIC_BERMUDA = 'Atlantic/Bermuda, (GMT-04:00) Bermuda',
  AMERICA_BOA_VISTA = 'America/Boa_Vista, (GMT-04:00) Boa Vista',
  AMERICA_CARACAS = 'America/Caracas, (GMT-04:00) Caracas',
  AMERICA_CURACAO = 'America/Curacao, (GMT-04:00) Curacao',
  AMERICA_GRAND_TURK = 'America/Grand_Turk, (GMT-04:00) Grand Turk',
  AMERICA_GUYANA = 'America/Guyana, (GMT-04:00) Guyana',
  AMERICA_HALIFAX = 'America/Halifax, (GMT-04:00) Atlantic Time - Halifax',
  AMERICA_LA_PAZ = 'America/La_Paz, (GMT-04:00) La Paz',
  AMERICA_MANAUS = 'America/Manaus, (GMT-04:00) Manaus',
  AMERICA_MARTINIQUE = 'America/Martinique, (GMT-04:00) Martinique',
  AMERICA_PORTO_VELHO = 'America/Porto_Velho, (GMT-04:00) Porto Velho',
  AMERICA_PUERTO_RICO = 'America/Puerto_Rico, (GMT-04:00) Puerto Rico',
  AMERICA_SANTO_DOMINGO = 'America/Santo_Domingo, (GMT-04:00) Santo Domingo',
  AMERICA_THULE = 'America/Thule, (GMT-04:00) Thule',
  AMERICA_ST_JOHNS = 'America/St_Johns, (GMT-03:30) Newfoundland Time - St. Johns',
  AMERICA_ARAGUAINA = 'America/Araguaina, (GMT-03:00) Araguaina',
  AMERICA_ASUNCION = 'America/Asuncion, (GMT-03:00) Asuncion',
  AMERICA_BELEM = 'America/Belem, (GMT-03:00) Belem',
  AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires, (GMT-03:00) Buenos Aires',
  AMERICA_CAMPO_GRANDE = 'America/Campo_Grande, (GMT-03:00) Campo Grande',
  AMERICA_CAYENNE = 'America/Cayenne, (GMT-03:00) Cayenne',
  AMERICA_CUIABA = 'America/Cuiaba, (GMT-03:00) Cuiaba',
  AMERICA_FORTALEZA = 'America/Fortaleza, (GMT-03:00) Fortaleza',
  AMERICA_GODTHAB = 'America/Godthab, (GMT-03:00) Godthab',
  AMERICA_MACEIO = 'America/Maceio, (GMT-03:00) Maceio',
  AMERICA_MIQUELON = 'America/Miquelon, (GMT-03:00) Miquelon',
  AMERICA_MONTEVIDEO = 'America/Montevideo, (GMT-03:00) Montevideo',
  ANTARCTICA_PALMER = 'Antarctica/Palmer, (GMT-03:00) Palmer',
  AMERICA_PARAMARIBO = 'America/Paramaribo, (GMT-03:00) Paramaribo',
  AMERICA_RECIFE = 'America/Recife, (GMT-03:00) Recife',
  ANTARCTICA_ROTHERA = 'Antarctica/Rothera, (GMT-03:00) Rothera',
  AMERICA_BAHIA = 'America/Bahia, (GMT-03:00) Salvador',
  AMERICA_SAO_PAULO = 'America/Sao_Paulo, (GMT-03:00) Sao Paulo',
  AMERICA_SANTIAGO = 'America/Santiago, (GMT-03:00) Santiago',
  ATLANTIC_STANLEY = 'Atlantic/Stanley, (GMT-03:00) Stanley',
  AMERICA_NORONHA = 'America/Noronha, (GMT-02:00) Noronha',
  ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia, (GMT-02:00) South Georgia',
  ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde, (GMT-01:00) Cape Verde',
  AMERICA_SCORESBYSUND = 'America/Scoresbysund, (GMT-01:00) Scoresbysund',
  AFRICA_ABIDJAN = 'Africa/Abidjan, (GMT+00:00) Abidjan',
  AFRICA_ACCRA = 'Africa/Accra, (GMT+00:00) Accra',
  AFRICA_BISSAU = 'Africa/Bissau, (GMT+00:00) Bissau',
  ATLANTIC_CANARY = 'Atlantic/Canary, (GMT+00:00) Canary Islands',
  AFRICA_CASABLANCA = 'Africa/Casablanca, (GMT+00:00) Casablanca',
  AMERICA_DANMARKSHAVN = 'America/Danmarkshavn, (GMT+00:00) Danmarkshavn',
  EUROPE_DUBLIN = 'Europe/Dublin, (GMT+00:00) Dublin',
  AFRICA_EL_AAIUN = 'Africa/El_Aaiun, (GMT+00:00) El Aaiun',
  ETC_GMT = 'Etc/GMT, (GMT+00:00) GMT (no daylight saving)',
  ATLANTIC_FAROE = 'Atlantic/Faroe, (GMT+00:00) Faeroe',
  AFRICA_MONROVIA = 'Africa/Monrovia, (GMT+00:00) Monrovia',
  EUROPE_LISBON = 'Europe/Lisbon, (GMT+00:00) Lisbon',
  EUROPE_LONDON = 'Europe/London, (GMT+00:00) London',
  ATLANTIC_REYKJAVIK = 'Atlantic/Reykjavik, (GMT+00:00) Reykjavik',
  AFRICA_ALGIERS = 'Africa/Algiers, (GMT+01:00) Algiers',
  EUROPE_AMSTERDAM = 'Europe/Amsterdam, (GMT+01:00) Amsterdam',
  EUROPE_ANDORRA = 'Europe/Andorra, (GMT+01:00) Andorra',
  ATLANTIC_AZORES = 'Atlantic/Azores, (GMT-01:00) Azores',
  EUROPE_BELGRADE = 'Europe/Belgrade, (GMT+01:00) Central European Time - Belgrade',
  EUROPE_BERLIN = 'Europe/Berlin, (GMT+01:00) Berlin',
  EUROPE_BRUSSELS = 'Europe/Brussels, (GMT+01:00) Brussels',
  EUROPE_BUDAPEST = 'Europe/Budapest, (GMT+01:00) Budapest',
  AFRICA_CEUTA = 'Africa/Ceuta, (GMT+01:00) Ceuta',
  EUROPE_COPENHAGEN = 'Europe/Copenhagen, (GMT+01:00) Copenhagen',
  EUROPE_GIBRALTAR = 'Europe/Gibraltar, (GMT+01:00) Gibraltar',
  AFRICA_LAGOS = 'Africa/Lagos, (GMT+01:00) Lagos',
  EUROPE_LUXEMBOURG = 'Europe/Luxembourg, (GMT+01:00) Luxembourg',
  EUROPE_MADRID = 'Europe/Madrid, (GMT+01:00) Madrid',
  EUROPE_MALTA = 'Europe/Malta, (GMT+01:00) Malta',
  EUROPE_MONACO = 'Europe/Monaco, (GMT+01:00) Monaco',
  AFRICA_NDJAMENA = 'Africa/Ndjamena, (GMT+01:00) Ndjamena',
  EUROPE_OSLO = 'Europe/Oslo, (GMT+01:00) Oslo',
  EUROPE_PARIS = 'Europe/Paris, (GMT+01:00) Paris',
  EUROPE_PRAGUE = 'Europe/Prague, (GMT+01:00) Central European Time - Prague',
  EUROPE_ROME = 'Europe/Rome, (GMT+01:00) Rome',
  EUROPE_STOCKHOLM = 'Europe/Stockholm, (GMT+01:00) Stockholm',
  EUROPE_TIRANE = 'Europe/Tirane, (GMT+01:00) Tirane',
  AFRICA_TUNIS = 'Africa/Tunis, (GMT+01:00) Tunis',
  EUROPE_VIENNA = 'Europe/Vienna, (GMT+01:00) Vienna',
  EUROPE_WARSAW = 'Europe/Warsaw, (GMT+01:00) Warsaw',
  EUROPE_ZURICH = 'Europe/Zurich, (GMT+01:00) Zurich',
  ASIA_AMMAN = 'Asia/Amman, (GMT+02:00) Amman',
  EUROPE_ATHENS = 'Europe/Athens, (GMT+02:00) Athens',
  EUROPE_BUCHAREST = 'Europe/Bucharest, (GMT+02:00) Bucharest',
  AFRICA_CAIRO = 'Africa/Cairo, (GMT+02:00) Cairo',
  EUROPE_CHISINAU = 'Europe/Chisinau, (GMT+02:00) Chisinau',
  ASIA_DAMASCUS = 'Asia/Damascus, (GMT+02:00) Damascus',
  ASIA_GAZA = 'Asia/Gaza, (GMT+02:00) Gaza',
  EUROPE_HELSINKI = 'Europe/Helsinki, (GMT+02:00) Helsinki',
  EUROPE_KALININGRAD = 'Europe/Kaliningrad, (GMT+02:00) Moscow-01 - Kaliningrad',
  EUROPE_KYIV = 'Europe/Kyiv, (GMT+02:00) Kyiv',
  AFRICA_TRIPOLI = 'Africa/Tripoli, (GMT+02:00) Tripoli',
  ASIA_BEIRUT = 'Asia/Beirut, (GMT+02:00) Beirut',
  AFRICA_MAPUTO = 'Africa/Maputo, (GMT+02:00) Maputo',
  ASIA_JERUSALEM = 'Asia/Jerusalem, (GMT+02:00) Jerusalem',
  AFRICA_JOHANNESBURG = 'Africa/Johannesburg, (GMT+02:00) Johannesburg',
  ASIA_NICOSIA = 'Asia/Nicosia, (GMT+02:00) Nicosia',
  EUROPE_RIGA = 'Europe/Riga, (GMT+02:00) Riga',
  EUROPE_SOFIA = 'Europe/Sofia, (GMT+02:00) Sofia',
  EUROPE_TALLINN = 'Europe/Tallinn, (GMT+02:00) Tallinn',
  EUROPE_VILNIUS = 'Europe/Vilnius, (GMT+02:00) Vilnius',
  AFRICA_WINDHOEK = 'Africa/Windhoek, (GMT+02:00) Windhoek',
  ASIA_BAGHDAD = 'Asia/Baghdad, (GMT+03:00) Baghdad',
  EUROPE_ISTANBUL = 'Europe/Istanbul, (GMT+03:00) Istanbul',
  AFRICA_KHARTOUM = 'Africa/Khartoum, (GMT+03:00) Khartoum',
  EUROPE_MINSK = 'Europe/Minsk, (GMT+03:00) Minsk',
  EUROPE_MOSCOW = 'Europe/Moscow, (GMT+03:00) Moscow+00 - Moscow',
  AFRICA_NAIROBI = 'Africa/Nairobi, (GMT+03:00) Nairobi',
  ASIA_QATAR = 'Asia/Qatar, (GMT+03:00) Qatar',
  ASIA_RIYADH = 'Asia/Riyadh, (GMT+03:00) Riyadh',
  ANTARCTICA_SYOWA = 'Antarctica/Syowa, (GMT+03:00) Syowa',
  ASIA_TEHRAN = 'Asia/Tehran, (GMT+03:30) Tehran',
  ASIA_BAKU = 'Asia/Baku, (GMT+04:00) Baku',
  ASIA_DUBAI = 'Asia/Dubai, (GMT+04:00) Dubai',
  INDIAN_MAHE = 'Indian/Mahe, (GMT+04:00) Mahe',
  INDIAN_MAURITIUS = 'Indian/Mauritius, (GMT+04:00) Mauritius',
  INDIAN_REUNION = 'Indian/Reunion, (GMT+04:00) Reunion',
  EUROPE_SAMARA = 'Europe/Samara, (GMT+04:00) Moscow+01 - Samara',
  ASIA_TBILISI = 'Asia/Tbilisi, (GMT+04:00) Tbilisi',
  ASIA_YEREVAN = 'Asia/Yerevan, (GMT+04:00) Yerevan',
  ASIA_KABUL = 'Asia/Kabul, (GMT+04:30) Kabul',
  ASIA_AQTAU = 'Asia/Aqtau, (GMT+05:00) Aqtau',
  ASIA_AQTOBE = 'Asia/Aqtobe, (GMT+05:00) Aqtobe',
  ASIA_ASHGABAT = 'Asia/Ashgabat, (GMT+05:00) Ashgabat',
  ASIA_DUSHANBE = 'Asia/Dushanbe, (GMT+05:00) Dushanbe',
  ASIA_KARACHI = 'Asia/Karachi, (GMT+05:00) Karachi',
  INDIAN_KERGUELEN = 'Indian/Kerguelen, (GMT+05:00) Kerguelen',
  INDIAN_MALDIVES = 'Indian/Maldives, (GMT+05:00) Maldives',
  ANTARCTICA_MAWSON = 'Antarctica/Mawson, (GMT+05:00) Mawson',
  ASIA_TASHKENT = 'Asia/Tashkent, (GMT+05:00) Tashkent',
  ASIA_YEKATERINBURG = 'Asia/Yekaterinburg, (GMT+05:00) Moscow+02 - Yekaterinburg',
  ASIA_COLOMBO = 'Asia/Colombo, (GMT+05:30) Colombo',
  ASIA_CALCUTTA = 'Asia/Calcutta, (GMT+05:30) India Standard Time',
  ASIA_KATMANDU = 'Asia/Katmandu, (GMT+05:45) Katmandu',
  ASIA_ALMATY = 'Asia/Almaty, (GMT+06:00) Almaty',
  INDIAN_CHAGOS = 'Indian/Chagos, (GMT+06:00) Chagos',
  ASIA_DHAKA = 'Asia/Dhaka, (GMT+06:00) Dhaka',
  ASIA_BISHKEK = 'Asia/Bishkek, (GMT+06:00) Bishkek',
  ASIA_OMSK = 'Asia/Omsk, (GMT+06:00) Moscow+03 - Omsk, Novosibirsk',
  ASIA_THIMPHU = 'Asia/Thimphu, (GMT+06:00) Thimphu',
  ANTARCTICA_VOSTOK = 'Antarctica/Vostok, (GMT+06:00) Vostok',
  ASIA_RANGOON = 'Asia/Rangoon, (GMT+06:30) Rangoon',
  INDIAN_COCOS = 'Indian/Cocos, (GMT+06:30) Cocos',
  ASIA_BANGKOK = 'Asia/Bangkok, (GMT+07:00) Bangkok',
  INDIAN_CHRISTMAS = 'Indian/Christmas, (GMT+07:00) Christmas',
  ANTARCTICA_DAVIS = 'Antarctica/Davis, (GMT+07:00) Davis',
  ASIA_SAIGON = 'Asia/Saigon, (GMT+07:00) Hanoi',
  ASIA_HO_CHI_MINH = 'Asia/Ho_Chi_Minh, (GMT+07:00) Ho Chi Minh',
  ASIA_HOVD = 'Asia/Hovd, (GMT+07:00) Hovd',
  ASIA_JAKARTA = 'Asia/Jakarta, (GMT+07:00) Jakarta',
  ASIA_KRASNOYARSK = 'Asia/Krasnoyarsk, (GMT+07:00) Moscow+04 - Krasnoyarsk',
  ASIA_SHANGHAI = 'Asia/Shanghai, (GMT+08:00) China Time - Beijing',
  ASIA_BRUNEI = 'Asia/Brunei, (GMT+08:00) Brunei',
  ANTARCTICA_CASEY = 'Antarctica/Casey, (GMT+08:00) Casey',
  ASIA_CHOIBALSAN = 'Asia/Choibalsan, (GMT+08:00) Choibalsan',
  ASIA_HONG_KONG = 'Asia/Hong_Kong, (GMT+08:00) Hong Kong',
  ASIA_MACAU = 'Asia/Macau, (GMT+08:00) Macau',
  ASIA_MAKASSAR = 'Asia/Makassar, (GMT+08:00) Makassar',
  ASIA_MANILA = 'Asia/Manila, (GMT+08:00) Manila',
  ASIA_IRKUTSK = 'Asia/Irkutsk, (GMT+08:00) Moscow+05 - Irkutsk',
  ASIA_KUALA_LUMPUR = 'Asia/Kuala_Lumpur, (GMT+08:00) Kuala Lumpur',
  AUSTRALIA_PERTH = 'Australia/Perth, (GMT+08:00) Western Time - Perth',
  ASIA_SINGAPORE = 'Asia/Singapore, (GMT+08:00) Singapore',
  ASIA_TAIPEI = 'Asia/Taipei, (GMT+08:00) Taipei',
  ASIA_ULAANBAATAR = 'Asia/Ulaanbaatar, (GMT+08:00) Ulaanbaatar',
  ASIA_PYONGYANG = 'Asia/Pyongyang, (GMT+08:30) Pyongyang',
  ASIA_DILI = 'Asia/Dili, (GMT+09:00) Dili',
  ASIA_JAYAPURA = 'Asia/Jayapura, (GMT+09:00) Jayapura',
  PACIFIC_PALAU = 'Pacific/Palau, (GMT+09:00) Palau',
  ASIA_SEOUL = 'Asia/Seoul, (GMT+09:00) Seoul',
  ASIA_TOKYO = 'Asia/Tokyo, (GMT+09:00) Tokyo',
  ASIA_YAKUTSK = 'Asia/Yakutsk, (GMT+09:00) Moscow+06 - Yakutsk',
  AUSTRALIA_DARWIN = 'Australia/Darwin, (GMT+09:30) Central Time - Darwin',
  AUSTRALIA_BRISBANE = 'Australia/Brisbane, (GMT+10:00) Eastern Time - Brisbane',
  ANTARCTICA_DUMONTDURVILLE = "Antarctica/DumontDUrville, (GMT+10:00) Dumont D'Urville",
  PACIFIC_GUAM = 'Pacific/Guam, (GMT+10:00) Guam',
  ASIA_MAGADAN = 'Asia/Magadan, (GMT+10:00) Moscow+07 - Magadan',
  PACIFIC_PORT_MORESBY = 'Pacific/Port_Moresby, (GMT+10:00) Port Moresby',
  ASIA_VLADIVOSTOK = 'Asia/Vladivostok, (GMT+10:00) Moscow+07 - Vladivostok',
  PACIFIC_CHUUK = 'Pacific/Chuuk, (GMT+10:00) Truk',
  AUSTRALIA_ADELAIDE = 'Australia/Adelaide, (GMT+10:30) Central Time - Adelaide',
  PACIFIC_EFATE = 'Pacific/Efate, (GMT+11:00) Efate',
  PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal, (GMT+11:00) Guadalcanal',
  AUSTRALIA_HOBART = 'Australia/Hobart, (GMT+11:00) Eastern Time - Hobart',
  PACIFIC_KOSRAE = 'Pacific/Kosrae, (GMT+11:00) Kosrae',
  PACIFIC_NORFOLK = 'Pacific/Norfolk, (GMT+11:00) Norfolk',
  PACIFIC_NOUMEA = 'Pacific/Noumea, (GMT+11:00) Noumea',
  PACIFIC_POHNPEI = 'Pacific/Pohnpei, (GMT+11:00) Ponape',
  AUSTRALIA_SYDNEY = 'Australia/Sydney, (GMT+11:00) Eastern Time - Melbourne, Sydney',
  ASIA_YUZHNO_SAKHALINSK = 'Asia/Yuzhno-Sakhalinsk, (GMT+11:00) Moscow+08 - Yuzhno-Sakhalinsk',
  PACIFIC_FUNAFUTI = 'Pacific/Funafuti, (GMT+12:00) Funafuti',
  PACIFIC_KWAJALEIN = 'Pacific/Kwajalein, (GMT+12:00) Kwajalein',
  PACIFIC_MAJURO = 'Pacific/Majuro, (GMT+12:00) Majuro',
  ASIA_KAMCHATKA = 'Asia/Kamchatka, (GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  PACIFIC_NAURU = 'Pacific/Nauru, (GMT+12:00) Nauru',
  PACIFIC_TARAWA = 'Pacific/Tarawa, (GMT+12:00) Tarawa',
  PACIFIC_WAKE = 'Pacific/Wake, (GMT+12:00) Wake',
  PACIFIC_WALLIS = 'Pacific/Wallis, (GMT+12:00) Wallis',
  PACIFIC_AUCKLAND = 'Pacific/Auckland, (GMT+13:00) Auckland',
  PACIFIC_ENDERBURY = 'Pacific/Enderbury, (GMT+13:00) Enderbury',
  PACIFIC_FAKAOFO = 'Pacific/Fakaofo, (GMT+13:00) Fakaofo',
  PACIFIC_FIJI = 'Pacific/Fiji, (GMT+13:00) Fiji',
  PACIFIC_TONGATAPU = 'Pacific/Tongatapu, (GMT+13:00) Tongatapu',
  PACIFIC_APIA = 'Pacific/Apia, (GMT+14:00) Apia',
  PACIFIC_KIRITIMATI = 'Pacific/Kiritimati, (GMT+14:00) Kiritimati'
}
