import { Field, Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IMyProfileProps, IUserProfileData } from './myProfile.interface';
import './myProfile.scss';
import { editProfileValidationSchema } from './myProfile.validationSchema';
import ProfileImage from './ProfileImage';

import IMAGES from '../../assets';
import { confirmSuccess } from '../../assets/images';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CloseButton from '../../components/common/close-button/CloseButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import CopyToClipboardIcon from '../../components/common/icons/copyToClipboardIcon/CopyToClipboardIcon';
import EditPenIcon from '../../components/common/icons/editPenIcon/EditPenIcon';
import TrashIcon from '../../components/common/icons/trashIcon/TrashIcon';
import TooltipCustom from '../../components/common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../components/common/restoreArchiveUserModal/RestoreArchiveUserModal';
import Typography from '../../components/common/typography/Typography';
import AccessControl from '../../components/myProfile/accessControl/AccessControl';
import Buttons from '../../components/myProfile/buttons/Buttons';
import ChangePassword from '../../components/myProfile/changePassword/ChangePassword';
import { BUTTON_TEXT, LABEL_TEXT, MY_PROFILE_TEXT } from '../../configs';
import { MY_PROFILE_TOOLTIP_MESSAGES } from '../../configs/myProfile';
import { successToastStyle } from '../../configs/styleConstants';
import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';
import { ERRORS as ERRORS_COLORS, PRIMARY } from '../../configs/v3.colors';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../constants/constants';
import { Loader } from '../../constants/pages';
import getUserData from '../../helpers/getUserData';
import useCheckUserPermissions from '../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import useLoading from '../../hooks/loading/useLoading';
import useFetchMyProfile from '../../hooks/myProfile/fetchMyProfile/useFetchMyProfile';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useFetchTeamUserProfile from '../../hooks/teamManagement/useFetchTeamUserProfile/useFetchTeamUserProfile';
import services from '../../services';
import postWithAuth from '../../services/postWithAuth';
import { fetchUserPermissionsRequest, setHeading } from '../../store/actions';
import { IMyProfileState } from '../../store/myProfile/myProfile.interface';
import useOrganizationStore from '../../store/orgnization/useOrgnizationStore';
import { getDateFromString } from '../../utils/dateAndHourFormatting/dateAndHourFormatting';
import { getFetchUsersQueryKey } from '../../utils/pre-fetchers/prefetchTeamManagement';
import { trimPayload } from '../../utils/trimString/trimString';

const MyProfile = ({
  teamUserprofile = false,
  onEditControlButtonClick,
  teamUserEmail = ''
}: IMyProfileProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openConfirmPasswordModal, setOpenConfirmPasswordModal] = useState<boolean>(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState<string>('');
  const [confirmModalText, setConfirmModalText] = useState<string>('');
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [openDeactivateUserModal, setOpenDeactivateUserModal] = useState<boolean>(false);
  const [isDeactivatedUser, setIsDeactivatedUser] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();

  const myProfile: IMyProfileState = useAppSelector<IMyProfileState>((state) => state.MyProfile);
  const {
    teamUserProfile: teamUserProfileData,
    isLoading: teamProfileLoading,
    refetchTeamUser
  } = useFetchTeamUserProfile(teamUserprofile);

  const { organization } = useOrganizationStore();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { refetch: refetchProfile, isLoading } = useFetchMyProfile();
  const navigate = useNavigate();
  // USER PERMISSIONS START
  const {
    canUserEditAnotherUser,
    canUserEditAnotherUserPermissions,
    canUserDeactivateAnotherUser
  } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const userProfileData: IUserProfileData = useMemo(() => {
    if (teamUserprofile) {
      return {
        ...teamUserProfileData?.userProfileData,
        permissions: teamUserProfileData?.permissions
      } as unknown as IUserProfileData;
    }
    return myProfile;
  }, [
    myProfile.firstName,
    myProfile.lastName,
    myProfile.jobTitle,
    myProfile.groups,
    teamUserProfileData?.userProfileData.firstName,
    teamUserProfileData?.userProfileData.lastName,
    teamUserProfileData?.userProfileData.jobTitle,
    JSON.stringify(teamUserProfileData?.userProfileData.groups),
    JSON.stringify(teamUserProfileData?.permissions),
    teamUserProfileData,
    myProfile
  ]);

  // Since groups and permissions are arrays or Javascript objects, converting them in to string to prevent unexpected behaviours.
  // such as resetting the profile form when not required.
  // There may be some performance overhead to meet these requirements.
  // useMemo may not work as expected when Javascript objects are used in dependency arrays.

  const userData = getUserData();
  const location = useLocation();

  const handleNavigateToDeactivatedUsers = () => {
    navigate('/team-management/deactivated-users');
  };

  const handleDeactivatedUserNavigation = isDeactivatedUser
    ? handleNavigateToDeactivatedUsers
    : () => setOpenConfirmModal(false);

  const handleSubmit = async (values: IUserProfileData) => {
    const { firstName, lastName, jobTitle } = values;

    if (!teamUserprofile) {
      // Function to handle form submission
      try {
        const response = await services.postWithAuth({
          url: API_ENDPOINTS.EDIT_USER,
          payload: trimPayload({ email: userData?.email, firstName, lastName, jobTitle })
        });

        if (response?.data) {
          const prevUserData = JSON.parse(localStorage.getItem('userData') || '');
          localStorage.setItem(
            'userData',
            JSON.stringify({ ...prevUserData, firstName, lastName })
          );
          refetchProfile();
          setOpenConfirmModal(true);
          setConfirmModalText(MY_PROFILE_TEXT.PROFILE_UPDATED_SUCCESSFULLY);
          setConfirmModalTitle(MY_PROFILE_TEXT.PROFILE_UPDATED);
          setIsEdit(false);
          queryClient.resetQueries(
            getFetchUsersQueryKey({
              limit: ROWS_PER_PAGE_FIRST_ITEM,
              offset: offsetFirst,
              filterUser: ''
            })
          );
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'updateUserFail',
          defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
        });
      }
    } else {
      try {
        const response = await services.postWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_EDIT_USER,
          payload: { email: teamUserEmail, firstName, lastName, jobTitle }
        });

        if (response?.data) {
          refetchTeamUser(); // call only for  logged in user
          setIsEdit(false);
          setOpenConfirmModal(true);
          setConfirmModalText(MY_PROFILE_TEXT.USER_PROFILE_UPDATED_SUCCESSFULLY);
          setConfirmModalTitle(MY_PROFILE_TEXT.USER_PROFILE_UPDATED);
          queryClient.resetQueries(
            getFetchUsersQueryKey({
              limit: ROWS_PER_PAGE_FIRST_ITEM,
              offset: offsetFirst,
              filterUser: ''
            })
          );
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'updateUserFail',
          defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
        });
      }
    }
  };

  const handleDeactivateUser = async () => {
    // Deactivate user
    // Currently api supports single user
    try {
      startLoading();
      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_LIST_ARCHIVE_USERS,
        payload: { emails: [teamUserProfileData?.userProfileData.email] }
      });

      if (response) {
        setOpenDeactivateUserModal(false);
        setConfirmModalTitle(TEAM_MANAGEMENT_TEXT.USER_DEACTIVATED);
        setConfirmModalText(TEAM_MANAGEMENT_TEXT.USER_DEACTIVATE_SUCCESS);
        setOpenConfirmModal(true);
        setIsDeactivatedUser(true);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementArchiveUsersFail',
        defaultMessage:
          'Your request to archive users has failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  const areActionsAvailable =
    teamUserprofile &&
    teamUserProfileData?.userProfileData?.email !== userData?.email &&
    teamUserProfileData?.userProfileData?.isVerified;

  useEffect(() => {
    if (!myProfile.isProfileLoaded && userData?.email && !teamUserprofile) {
      if (canUserEditAnotherUserPermissions) {
        dispatch(fetchUserPermissionsRequest());
      }
    }

    if (location.pathname === '/my-profile') {
      dispatch(setHeading('My Profile'));
    }
  }, [location.pathname]);

  return (
    <div
      className={`my-profile ${isLoading || teamProfileLoading ? 'my-profile--is-loading' : ''}`}
    >
      {/* Start of My profile image and email block */}
      <PaperCustom className="my-profile__main" variant="medium">
        <div className="my-profile__main__head">
          <ProfileImage userProfileData={userProfileData} isTeamUserProfile={teamUserprofile} />
          <p className="my-profile__main__head__name apply-loader">
            {userProfileData.firstName} {userProfileData.lastName}
          </p>
          <div className="my-profile__main__head__email apply-loader">
            <span>{teamUserprofile ? teamUserEmail : userData?.email}</span>
            <CopyToClipboardIcon
              data-testid="copyToClipboardButton"
              onClick={async () => {
                await navigator.clipboard.writeText(
                  teamUserprofile ? teamUserEmail : userData?.email
                );
                toast.success(MY_PROFILE_TEXT.CLIPBOARD_COPY, {
                  style: successToastStyle,
                  toastId: 'clipboardEmailCopySuccess',
                  closeButton: <CloseButton color="#1AAD95" />
                });
              }}
            />
          </div>
        </div>
        <div className="my-profile__main__user-data">
          <Formik
            onSubmit={handleSubmit}
            initialValues={userProfileData}
            validationSchema={editProfileValidationSchema}
            enableReinitialize={true}
          >
            {({ errors, resetForm, isSubmitting }) => {
              return (
                <Form>
                  {/* Start of personal information */}
                  <div
                    className={`my-profile__main__user-data__personal-information ${
                      isEdit ? 'my-profile__main__user-data__personal-information--is-edit' : ''
                    }`}
                  >
                    <div
                      className={`my-profile__main__user-data__personal-information__fields ${
                        isEdit
                          ? 'my-profile__main__user-data__personal-information__fields--is-edit'
                          : ''
                      }`}
                    >
                      <div className="my-profile__main__user-data__personal-information__fields__field">
                        <label
                          htmlFor="firstName"
                          className={`label ${isEdit ? 'label--is-required' : ''} apply-loader`}
                        >
                          {LABEL_TEXT.FIRST_NAME}
                        </label>
                        {isEdit ? (
                          <Field
                            type="text"
                            id="firstName"
                            name="firstName"
                            data-testid="firstName"
                            placeholder="First name"
                            className="input-field"
                            maxLength={25}
                          />
                        ) : (
                          <p className="value apply-loader" data-testid="firstNameView">
                            {userProfileData.firstName}
                          </p>
                        )}
                        <div
                          className={`error-message  ${
                            errors.firstName ? 'error-message--is-visible' : ''
                          }`}
                        >
                          {errors.firstName ? errors.firstName : 'Please enter the first name'}
                        </div>
                      </div>
                      <div className="my-profile__main__user-data__personal-information__fields__field">
                        <label
                          htmlFor="lastName"
                          className={`label ${isEdit ? 'label--is-required' : ''} apply-loader`}
                        >
                          {LABEL_TEXT.LAST_NAME}
                        </label>
                        {isEdit ? (
                          <Field
                            type="text"
                            id="lastName"
                            name="lastName"
                            data-testid="lastName"
                            placeholder="Last name"
                            className="input-field"
                            maxLength={25}
                          />
                        ) : (
                          <p className="value apply-loader" data-testid="lastNameView">
                            {userProfileData.lastName}
                          </p>
                        )}
                        <div
                          className={`error-message  ${
                            errors.lastName ? 'error-message--is-visible' : ''
                          }`}
                        >
                          {errors.lastName ? errors.lastName : 'Enter your Last Name'}
                        </div>
                      </div>
                      <div className="my-profile__main__user-data__professional-information__fields__field">
                        <label
                          htmlFor="jobTitle"
                          className={`label ${isEdit ? 'label--is-required' : ''} apply-loader`}
                        >
                          {LABEL_TEXT.JOB_TITLE}
                        </label>
                        {isEdit ? (
                          <Field
                            type="text"
                            id="jobTitle"
                            name="jobTitle"
                            data-testid="jobTitle"
                            placeholder="Job title"
                            className="input-field"
                          />
                        ) : (
                          <p className="value apply-loader" data-testid="jobTitleView">
                            {userProfileData.jobTitle}
                          </p>
                        )}
                        <div
                          className={`error-message  ${
                            errors.jobTitle ? 'error-message--is-visible' : ''
                          }`}
                        >
                          {errors.jobTitle ? errors.jobTitle : 'Enter your Job Title'}
                        </div>
                      </div>
                      <div className="my-profile__main__user-data__professional-information__fields__field">
                        <p className="label apply-loader">{MY_PROFILE_TEXT.ONBOARDED_ON}</p>
                        <p className="value onboardedDate apply-loader" data-testid="onboardedDate">
                          {getDateFromString(
                            userProfileData.onBoardedOn,
                            organization.organizationAbbreviation,
                            organization.organizationTimeZone
                          )}
                        </p>
                      </div>
                    </div>
                    {/* Start of Edit profile button */}
                    {!isEdit && (
                      <>
                        <TooltipCustom
                          text={MY_PROFILE_TOOLTIP_MESSAGES.NO_EDIT_OTHER_USER__PROFILE_ACCESS}
                          conditionToDisplay={teamUserprofile && !canUserEditAnotherUser}
                          containerProps={{ style: { width: 'initial' } }}
                        >
                          <button
                            className="my-profile__main__user-data__edit-button apply-loader"
                            data-testid="editButton"
                            onClick={() => {
                              setIsEdit(true);
                              dispatch(setHeading('Edit My Profile'));
                            }}
                            type="button"
                            disabled={teamUserprofile && !canUserEditAnotherUser}
                          >
                            <EditPenIcon data-testid="editButtonIcon" color={PRIMARY[600]} />
                            <Typography variant="body-3" size="medium" data-testid="editButtonText">
                              {MY_PROFILE_TEXT.EDIT}
                            </Typography>
                          </button>
                        </TooltipCustom>
                      </>
                    )}
                    {/* End of if Edit profile button */}
                    {/* Start of title and buttons */}

                    {isEdit && (
                      <Buttons
                        classNames="my-profile__main__user-data__buttons"
                        resetForm={resetForm}
                        setIsEdit={setIsEdit}
                        isSubmitting={isSubmitting}
                      />
                    )}
                    {areActionsAvailable && (
                      <TooltipCustom
                        text={MY_PROFILE_TOOLTIP_MESSAGES.NO_DEACTIVATE_OTHER_USER_ACCESS}
                        conditionToDisplay={teamUserprofile && !canUserDeactivateAnotherUser}
                        containerProps={{ style: { width: 'initial' } }}
                      >
                        <button
                          className="my-profile__main__user-data__deactivate-button apply-loader"
                          onClick={() => {
                            if (canUserDeactivateAnotherUser) {
                              setOpenDeactivateUserModal(true);
                            }
                          }}
                          type="button"
                          disabled={!canUserDeactivateAnotherUser}
                        >
                          <TrashIcon color={ERRORS_COLORS[700]} />
                          <Typography variant="body-3" size="medium" as="span">
                            {TEAM_MANAGEMENT_TEXT.DEACTIVATE_USER}
                          </Typography>
                        </button>
                      </TooltipCustom>
                    )}
                    {/* end of title and buttons */}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </PaperCustom>
      {/* End of My profile image and email block */}
      {/* Start of My profile view or edit form */}
      <div className="my-profile__details">
        {!isEdit && !teamUserprofile && (
          <PaperCustom className="my-profile__password" variant="medium">
            <div className="my-profile__password__fields">
              <div className="my-profile__password__fields__field">
                <Typography
                  variant="body-2"
                  size="regular"
                  className="label password apply-loader"
                  as="p"
                >
                  {LABEL_TEXT.PASSWORD}
                </Typography>
                <button
                  className={`change-password ${
                    isEdit ? 'change-password--is-disabled' : ''
                  } apply-loader`}
                  disabled={isEdit}
                  type="button"
                  data-testid="changePasswordButton"
                  onClick={() => setOpenChangePasswordModal(true)}
                >
                  <Typography variant="body-3" size="medium" as="span">
                    {MY_PROFILE_TEXT.CHANGE_PASSWORD}
                  </Typography>
                </button>
              </div>
            </div>
          </PaperCustom>
        )}
        {/* End of change password button */}
        {/* Start of access control */}
        <AccessControl
          loading={teamProfileLoading}
          title={MY_PROFILE_TEXT.GROUPS}
          gropus={userProfileData.groups}
          permissions={userProfileData.permissions || []}
          teamUserProfile={teamUserprofile}
          onEditControlButtonClick={onEditControlButtonClick}
        />
      </div>
      {/* End of My profile view or edit form */}
      <ChangePassword
        openChangePasswordModal={openChangePasswordModal}
        setOpenChangePasswordModal={setOpenChangePasswordModal}
        setOpenConfirmModal={setOpenConfirmPasswordModal}
        setConfirmModalText={setConfirmModalText}
      />
      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        title={confirmModalTitle}
        description={confirmModalText}
        image={confirmSuccess}
        confirmButtonText={BUTTON_TEXT.OKAY}
        descriptionWidth="88%"
        handleOkClick={handleDeactivatedUserNavigation}
        handleClose={handleDeactivatedUserNavigation}
      />
      <ConfirmModal
        open={openConfirmPasswordModal}
        setOpen={setOpenConfirmPasswordModal}
        title={MY_PROFILE_TEXT.PASSWORD_UPDATED}
        description={MY_PROFILE_TEXT.PASSWORD_UPDATED_SUCCESSFULLY}
        image={confirmSuccess}
        confirmButtonText={BUTTON_TEXT.OKAY}
      />
      <RestoreArchiveUserModal
        icon={IMAGES.alertRedIcon}
        type={TYPE_ALIGNMENT.CENTER}
        heading={TEAM_MANAGEMENT_TEXT.DEACTIVATE_USER}
        open={openDeactivateUserModal}
        handleClose={() => {
          setOpenDeactivateUserModal(false);
        }}
        handlerFun={handleDeactivateUser}
        actionButtonText={loading ? <Loader /> : BUTTON_TEXT.YES_DEACTIVATE}
        child={
          <>
            <p>Are you sure you want to deactivate this user?</p>
            <p className="mt-4">
              Teams and cloud accounts configured by this user(s) will be owned by the owner.
            </p>
          </>
        }
      />
    </div>
  );
};

export default MyProfile;
