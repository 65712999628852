import { useEffect } from 'react';
// eslint-disable-next-line

import { FCM } from '../../../configs/common';
import updateTokens from '../../../services/updateTokens';
import { fetchMyProfile } from '../../../store/actions';
import useFetchMyProfile from '../../myProfile/fetchMyProfile/useFetchMyProfile';
import { useAppDispatch, useAppSelector } from '../../redux/useRedux';

const useGetNotificationsOnBackground = () => {
  const dispatch = useAppDispatch();
  const { refetch: refetchProfile } = useFetchMyProfile();
  const myProfileData = useAppSelector((store) => store.MyProfile);

  const listenToNotificationsOnBackground = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(() => {
        navigator.serviceWorker.addEventListener('message', (event) => {
          const MessageEvent = event.data?.data.type;
          if (MessageEvent === FCM.PERMISSION_UPDATE) {
            refetchProfile();
            dispatch(fetchMyProfile({ ...myProfileData, permissionUpdated: true }));
            updateTokens();
          } else if (MessageEvent === FCM.DEACTIVATED_USER) {
            dispatch(
              fetchMyProfile({ ...myProfileData, permissionUpdated: true, deactivateUser: true })
            );
          } else if (MessageEvent === FCM.RESET_USER_PASSWORD) {
            dispatch(
              fetchMyProfile({ ...myProfileData, permissionUpdated: true, resetUserPassword: true })
            );
          }
        });
      });
    }
  };

  useEffect(() => {
    listenToNotificationsOnBackground();
  }, []);
  return listenToNotificationsOnBackground;
};
export default useGetNotificationsOnBackground;
