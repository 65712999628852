import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { IInputFieldTooltip, IInputFieldErrorCalc } from './inputFieldTooltip.interface';
import './inputFieldTooltip.scss';

const InputFieldTooltip = ({ formValues, field, schema, title, className }: IInputFieldTooltip) => {
  const [messages, setMessages] = useState<string[]>([]);

  const errors = async ({ formValues, field, schema }: IInputFieldErrorCalc) => {
    const result = await schema
      .validate(formValues, {
        abortEarly: false,
        context: { [field]: true }
      })
      .then((result) => result)
      .catch((e) => {
        return e;
      });
    setMessages(result.errors ? result.errors : []);
  };

  useEffect(() => {
    errors({ formValues, field, schema: schema });
  }, [formValues]);

  const classes = classNames('input-field-tooltip ', className);

  if (messages.length > 0) {
    return (
      <div data-testid="tooltipMessageBox" className={classes}>
        <p>{title}</p>
        <ul>
          {messages.map((message) => (
            <li key={message} data-testid="tooltipMessage">
              {message}
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

export default InputFieldTooltip;
