import { IAxiosInstance, IAxiosRequestConfig } from './interface/axios.interface';

export default (instance: IAxiosInstance): IAxiosInstance => ({
  get: (path: string, config?: IAxiosRequestConfig) => instance.get(path, config),
  post: (path: string, payload?: object, config?: IAxiosRequestConfig) =>
    instance.post(path, payload, config),
  put: (path: string, payload: object, config?: IAxiosRequestConfig) =>
    instance.put(path, payload, config),
  delete: (path: string, config?: IAxiosRequestConfig) => instance.delete(path, config),
  patch: (path: string, payload: object, config?: IAxiosRequestConfig) =>
    instance.patch(path, payload, config)
});
