export const errorsInitialState = {
  firstName: [],
  lastName: [],
  country: [],
  state: [],
  organizationName: [],
  jobTitle: [],
  currency: [],
  timezone: []
};

export const touchedInitialState = {
  firstName: false,
  lastName: false,
  country: false,
  state: false,
  organizationName: false,
  jobTitle: false,
  currency: false,
  timezone: false
};

export const formValuesInitialState = {
  firstName: '',
  lastName: '',
  country: '',
  state: '',
  organizationName: '',
  jobTitle: '',
  currency: '',
  timezone: ''
};
