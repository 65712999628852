import React from 'react';
import './logoWithHeading.scss';

interface IlogoWithHeadingProbs {
  logoSrc: string;
  headingText: string;
  className?: string;
}

const LogoWithHeading: React.FC<IlogoWithHeadingProbs> = ({ logoSrc, headingText, className }) => {
  return (
    <div className={`container-logo-with-heading ${className}`}>
      <img src={logoSrc} alt="Logo" loading="lazy" />
      <h1 className={`connect-heading ${className}`}>{headingText}</h1>
    </div>
  );
};

export default LogoWithHeading;
