import { permissions } from '@cloudinspot/cis-common-constants';

import { IPermissionsList } from '../../../store/teamManagement/teamManagement.interface';

const getDefaultPermissions = () => {
  const defaultPermissions: IPermissionsList[] = [];

  permissions.forEach((p: IPermissionsList) => {
    // Initiate key
    const module: IPermissionsList = { apiKey: p.apiKey, module: p.module, permissions: {} };

    // Extracting default permissions
    Object.keys(p.permissions)
      .filter((permission) => p.permissions[permission].default)
      .map((dP) => {
        module.permissions[dP] = p.permissions[dP];
      });

    if (Object.keys(module.permissions).length > 0) {
      defaultPermissions.push(module);
    }
  });

  return defaultPermissions;
};

export default getDefaultPermissions;
