import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import API_ENDPOINTS from '../../constants/api_endpoints';
import { routes } from '../../constants/routes';
import services from '../../services';
import { IUserInLocalStorage } from '../../services/service.interface';
import {
  clearCloudAccounts,
  fetchMyProfile,
  setChannelsList,
  setIsSlackChannelFound,
  setIsSlackInstalled,
  setSlackConnectionDetails,
  updateSelectedUser
} from '../../store/actions';
import { myProfileReducerState } from '../../store/myProfile/myProfile.reducer';
import useErrorHandler from '../error-handler/useErrorHandler';
import { useAppDispatch } from '../redux/useRedux';

const useSignOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClearUserData = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userExpiresIn');
    localStorage.removeItem('authInfo');
    localStorage.removeItem('organization');
    localStorage.removeItem('userData');
    localStorage.removeItem('teamMgmtGroupView'); //REMOVED FROM LOCAL
    localStorage.removeItem('retryCounter'); //REMOVED FROM LOCAL
    localStorage.removeItem('teamMgmtUserProfile');
    localStorage.removeItem('hasAccounts');
    //cloud accounts
    dispatch(clearCloudAccounts());
    dispatch(setIsSlackChannelFound(false));
    dispatch(setIsSlackInstalled(false));
    dispatch(
      setSlackConnectionDetails({
        accountId: '',
        awsAccountName: '',
        channelName: '',
        status: '',
        connectBy: ``,
        connectedOn: '',
        workspaceName: ''
      })
    );
    dispatch(setChannelsList([]));
    dispatch(updateSelectedUser('')); //removing user from redux
    dispatch(fetchMyProfile(myProfileReducerState));
    //redirect user to sign in and clear cached data for profile
    queryClient.resetQueries(['my-profile']);
    navigate(routes.SIGN_IN);
  };

  const handleSignOut = async () => {
    try {
      const user: IUserInLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
      //perform the sign out if user is there in the local storage
      if (user?.data) {
        const email = user?.data?.user?.email;
        const payload = {
          email
        };
        const response = await services.postWithAuth({ url: API_ENDPOINTS.SIGN_OUT, payload });
        if (response) {
          handleClearUserData();
          navigate(routes.SIGN_IN);
        }
      } else {
        //if no user found in local then navigate to Sign in page
        navigate(routes.SIGN_IN);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'signOutFail',
        defaultMessage: 'Sign out failed, due to some reasons'
      });
    }
  };

  return { handleSignOut, handleClearUserData };
};

export default useSignOut;
