import { BaseService } from './base.service';
import {
  TDeRegisterDeviceRequest,
  TDeRegisterDeviceResponse
} from './types/deRegisterDevice.endpoints';
import { TRegisterDeviceRequest, TRegisterDeviceResponse } from './types/registerDevice.endpoints';

interface INotificationsService {
  registerDevice(request: TRegisterDeviceRequest): Promise<TRegisterDeviceResponse>;
  deRegisterDevice(request: TDeRegisterDeviceRequest): Promise<TDeRegisterDeviceResponse>;
}

export class NotificationService extends BaseService implements INotificationsService {
  private REGISTER_DEVICE: string = '/notification/register-device';
  private DEREGISTER_DEVICE: string = '/notification/deregister-device';

  constructor() {
    super(process.env.REACT_APP_API_NOTIFICATION_BASE_URL || '');
  }

  async registerDevice(request: TRegisterDeviceRequest): Promise<TRegisterDeviceResponse> {
    const getAccountsResponse: TRegisterDeviceResponse = await this.post(
      this.REGISTER_DEVICE,
      request
    );
    return getAccountsResponse;
  }

  async deRegisterDevice(request: TDeRegisterDeviceRequest): Promise<TDeRegisterDeviceResponse> {
    const addAccountResponse: TDeRegisterDeviceResponse = await this.post(
      this.DEREGISTER_DEVICE,
      request
    );
    return addAccountResponse;
  }
}
