/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from '@mui/material';
import React, { useState } from 'react';

import { dailyTime } from './constants';
import './dailyModal.scss';

import { closeIcon } from '../../../assets/images';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import IMAGES from '../../../assets';

export interface IDailyModalProps {
  setScheduleTime: (value: any) => void;
  closeModal: () => void;
  selectedTime?: string;
}

const DailyModal = ({ closeModal, selectedTime, setScheduleTime }: IDailyModalProps) => {
  const [selectTime, setSelectTime] = useState<string | undefined>(selectedTime);

  const handleClose = () => {
    setScheduleTime(`${selectedTime}`);
    closeModal();
  };

  const handleSchedule = () => {
    setScheduleTime(`${selectTime}`);
    closeModal();
  };

  return (
    <Modal open={true} data-testid="common-daily-modal" id="common-daily-modal">
      <div className="common-daily-modal">
        <div className="common-daily-modal__heading">
          <h3>Schedule Time</h3>
          <img
            src={closeIcon}
            alt="Close icon"
            data-testid="confirmModalCloseIcon"
            onClick={handleClose}
          />
        </div>
        <div className="common-daily-modal__content">
          {/* Select time */}
          <div className="common-daily-modal__content__list">
            <h5 className="common-daily-modal__content__list__title">Select time</h5>
            {/* map days here */}
            <div className="common-daily-modal__content__list__items common-daily-modal__content__list__items__time">
              {dailyTime.map((time, idx) => (
                <div key={time + idx} onClick={() => setSelectTime(time)}>
                  <p>{time}</p>
                  {selectTime === time && (
                    <img src={IMAGES.roundedSelectedBlueCheckmark} alt="Selected option" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="common-daily-modal__buttons">
          <CustomButton text="Cancel" variant={ButtonVariants.SECONDARY} onClick={handleClose} />
          <CustomButton text="Schedule" variant={ButtonVariants.PRIMARY} onClick={handleSchedule} />
        </div>
      </div>
    </Modal>
  );
};

export default DailyModal;
