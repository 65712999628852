import moment from 'moment';

import { IHoursFormatting } from './dateAndHourFormatting.interface';

import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { TIMEZONE_REGEX } from '../../constants/regex';

export const hoursFormatting = ({ hours }: IHoursFormatting) => {
  if (hours < 10) {
    return `0${hours}`;
  } else if (hours > 9 && hours < 13) {
    return hours;
  } else if (hours > 12) {
    return hours - 12 > 9 ? hours - 12 : `0${hours - 12}`;
  } else {
    return hours;
  }
};

/**
 * Converts a date string into the format 'MMM DD, YYYY'.
 *
 * @param  options : str - takes the timestamp as input .
 * @returns {string} Returns the formatted date string in the 'MMM DD, YYYY' format.
 * @throws {Error} Throws an error if the input date is invalid or cannot be parsed.
 */
export const dateFormat = ({ date }: { date: string }): string => {
  //converts dateString format into this format May 30, 2023
  return moment(date).format('MMM DD, YYYY');
};

/**
 *
 * @param inputString
 * @returns date in this format +05:30
 * @example inputString: "Asia/Calcutta, (GMT+05:30) India Standard Time", output: "+05:30"
 * @default "+05:30"
 *
 */
export const extractTimeZoneOffset = (inputString: string): string => {
  const pattern: RegExp = TIMEZONE_REGEX;
  const match: RegExpExecArray | null = pattern.exec(inputString);

  if (match) {
    return match[1];
  }

  //if invalid input string passed, just to avoid unexpected behavior
  return '+05:30';
};

/**
 * Takes a timestamp from API call converts to user timezone
 * @param dateString
 * It adds the Abbreviation of Timezone to the time
 * @returns formatted date in this format "July 10, 2023 | 9:10 AM IST"
 * @example input "2023-12-18T04:23:25.665Z" --> output: "Dec 18, 2023 | 09:53 AM IST"
 */
// eslint-disable-next-line
export const getDateFromString = (
  dateString: string,
  abbreviation?: string,
  OrgTimezone?: string
) => {
  // eslint-disable-next-line
  try {
    const timeZone = extractTimeZoneOffset(OrgTimezone || '');
    const updatedDate = moment(dateString).utcOffset(timeZone).format('MMM DD, YYYY | hh:mm A');
    return `${updatedDate} ${abbreviation}`;
  } catch (error) {
    useErrorHandler({
      error,
      toastId: 'DateFail',
      defaultMessage: 'Plz send a valid date'
    });
  }
};

export const convertTime = (timeString: string, orgTimeZone: string) => {
  if (timeString) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [hours, minWithAbbreviation] = timeString.split(':');
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [min, amPM] = minWithAbbreviation.split(' ');
    try {
      let hoursInt = parseInt(hours);
      if (amPM === 'pm' && hoursInt !== 12) {
        hoursInt = hoursInt + 12;
      } else if (amPM === 'am' && hoursInt === 12) {
        hoursInt = 0; // Midnight
      }
      const timeZone = extractTimeZoneOffset(orgTimeZone);
      const updatedTime = moment()
        .utc()
        .utcOffset(timeZone)
        .set('hour', hoursInt)
        .set('minute', parseInt(min))
        .utc()
        .format('hh:mm a');
      return updatedTime;
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'DateFail',
        defaultMessage: 'Plz send a valid date'
      });
    }
  }
  return timeString;
};

// Below Function converted the time According to User
export const reverseconvertTime = (timeString: string, orgTimeZone: string) => {
  if (timeString) {
    const [hours, minWithAbbreviation] = timeString.split(':');
    const [min, amPM] = minWithAbbreviation?.split(' ') || ['', ''];
    try {
      let hoursInt = parseInt(hours);

      // Convert 12-hour format to 24-hour format
      if (amPM === 'pm') {
        hoursInt = hoursInt === 12 ? 12 : hoursInt + 12;
      } else if (amPM === 'am' && hoursInt === 12) {
        hoursInt = 0; // Midnight
      }

      const timeZone = extractTimeZoneOffset(orgTimeZone);
      const updatedTime = moment()
        .utc()
        .set('hour', hoursInt)
        .set('minute', parseInt(min))
        .utcOffset(timeZone)
        .format('hh:mm a');
      return updatedTime;
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'DateFail',
        defaultMessage: 'Please send a valid date'
      });
    }
  }
  return timeString;
};
