interface IRemoveDuplicatesFlatArray {
  arr: (string | number)[];
}

// Utility function to remove primitive duplicates in smaller 1D array.
// Cannot use Set, probably due to project configuration.
const removeDuplicatesFlatArray = ({ arr }: IRemoveDuplicatesFlatArray) => {
  try {
    const output: (string | number)[] = [];
    arr.forEach((item) => {
      if (output.indexOf(item) < 0) {
        output.push(item);
      }
    });

    return output;
  } catch {
    return arr;
  }
};

export default removeDuplicatesFlatArray;
