// Internal imports
import './selectedUser.scss';

import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';
import { textEllipsis } from '../../../../utils';
import CloseIconBorder from '../../../common/icons/closeIconBorder/CloseIconBorder';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import Typography from '../../../common/typography/Typography';

const tooltipCharLimitValue = 21;

export interface ISelectedUserProps {
  userName: string;
  email: string;
  deselectUser: (user: string) => void;
  tooltipCharLimit?: number;
}

const SelectedUser = ({
  userName,
  email,
  deselectUser,
  tooltipCharLimit = tooltipCharLimitValue
}: ISelectedUserProps) => {
  return (
    <TooltipCustom
      text={
        <div className="user-tooltip">
          <p style={{ textAlign: 'left' }}>{userName}</p>
          <p style={{ textAlign: 'left' }}>{email}</p>
        </div>
      }
      limitWidth={false}
      conditionToDisplay={userName?.length > tooltipCharLimit || email?.length > tooltipCharLimit}
      containerProps={{
        className: 'tm-add-group-selected-user apply-loader'
      }}
    >
      <div className="tm-add-group-selected-user__user">
        <Typography
          variant="body-3"
          size="medium"
          as="p"
          className="tm-add-group-selected-user__user__name"
        >
          {textEllipsis({ text: userName, charLimit: tooltipCharLimit })}
        </Typography>
        <Typography
          variant="body-3"
          size="regular"
          as="p"
          className="tm-add-group-selected-user__user__email"
        >
          {textEllipsis({ text: email, charLimit: tooltipCharLimit })}
        </Typography>
      </div>
      <CloseIconBorder
        color={BLACK_COLORS[700]}
        containerProps={{
          className: 'tm-add-group-selected-user__close-icon',
          onClick: () => deselectUser(email)
        }}
      />
    </TooltipCustom>
  );
};

export default SelectedUser;
