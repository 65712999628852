import { Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { IResetPassword, ISignInAPIResponse, IUserData } from './SignIn.types';
import SignInValidationSchema from './SignIn.validationSchema';
import './signIn.scss';

import IMAGES from '../../assets';
import { BUTTON_TEXT } from '../../configs/form';
import { errorToastStyle } from '../../configs/styleConstants';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { FormContainer } from '../../constants/pages';
import { routes } from '../../constants/routes';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { useAppDispatch } from '../../hooks/redux/useRedux';
import { IAuthInfo } from '../../interfaces/auth';
import services from '../../services';
import getWithAuth from '../../services/getWithAuth';
import { fetchMyProfile, setIfCloudAccountExist } from '../../store/actions';
// import {setSignUpValues } from '../../store/actions';
import { IMyProfileState } from '../../store/myProfile/myProfile.interface';
// import { signUpInitialValues } from '../../store/userAuth/userAuth.reducer';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import CloseButton from '../../components/common/close-button/CloseButton';
import TextInput from '../../components/common/textInput/TextInput';
import Typography from '../../components/common/typography/Typography';
import '../../components/user-authentication/UserAuth.css';
import { useGetNotifications } from '../../hooks/notification';
import useAccountSummaryStore from '../../store/cloudAccounts/useAccountSummaryStore';
import useForgotPasswordStore from '../../store/forgotPassword/useForgotPasswordStore';
import usePasswordVisibility from '../../utils/usePasswordVisibility';

const SignIn = () => {
  const listenToNotifications = useGetNotifications();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setHasAccounts } = useAccountSummaryStore();

  const { toggleVisibility, visible, inputType } = usePasswordVisibility();

  const userData: IUserData = {
    org_id: '',
    email: '',
    password: ''
  };
  const { resetForgotPasswordFormValues } = useForgotPasswordStore();

  const handleSubmit = async ({ org_id, email, password }: IUserData) => {
    try {
      const payload = {
        organizationId: org_id.trim(),
        email: email.trim(),
        password: password
      };

      const resp = await services.post({ url: API_ENDPOINTS.SIGN_IN, payload });
      const data = resp.data as ISignInAPIResponse;
      resetForgotPasswordFormValues();
      //If challenge Name is there that means User has to Force change the Password
      if (data?.data?.ChallengeName) {
        navigate(routes.FORCE_CHANGE_PASSWORD, {
          state: {
            organizationId: org_id,
            challengeName: data?.data?.ChallengeName,
            sessionToken: data?.data?.Session,
            email: email
          }
        });
        return;
      }

      localStorage.setItem('user', JSON.stringify(data));

      localStorage.setItem('organization', JSON.stringify(data.data.organization));

      // Calculates the time at which the token will expires.  todo Delete this
      const expiresIn = data.data.AuthenticationResult.ExpiresIn;
      // setting in redux if any cloud account is connected or not
      dispatch(setIfCloudAccountExist(data.data.accountConnected));
      setHasAccounts(data.data.accountConnected);
      const authInfo: IAuthInfo = {
        AccessToken: data.data.AuthenticationResult.AccessToken,
        ExpiresIn: data.data.AuthenticationResult.ExpiresIn.toString(),
        IdToken: data.data.AuthenticationResult.IdToken
      };

      // Storing the accessToken, expiresIn, IdToken in a separate key,
      // because refresh-access-token sends the response in a stricture difference from Sign In ('/user/sign-in').
      // after refreshing the access token, auth-info keys will be used and updated.
      localStorage.setItem('authInfo', JSON.stringify(authInfo));

      // sets the expires-in timestamp
      localStorage.setItem('userExpiresIn', `${new Date().getTime() + expiresIn * 1000}`);
      localStorage.setItem('userData', JSON.stringify(data.data.user));
      localStorage.setItem('retryCounter', '0');

      // Fetch user profile, for navigation and permissions after sign in
      const myProfileResponse = await getWithAuth({
        url: API_ENDPOINTS.GET_USER,
        params: { email }
      });
      listenToNotifications();
      const myProfileData = myProfileResponse?.data?.data as IMyProfileState;

      const { activeCloudAccount } = myProfileData;

      // Update redux state
      dispatch(fetchMyProfile(myProfileData));

      if (activeCloudAccount) {
        // If there are active cloud accouts, navigate to dashboard
        navigate(routes.CLOUD_ACCOUNTS);
      } else {
        // If there are no active cloud accouts, navigate to cloud accounts
        navigate(routes.CLOUD_ACCOUNTS);
      }
    } catch (error) {
      const errorcode = (error as IResetPassword).response.data.code;
      if (errorcode === 'PasswordResetRequiredException') {
        //exception handled
        toast.error(
          'Your password is reset by your admin. Enter the verification code sent on you email.',
          {
            style: errorToastStyle,
            closeButton: <CloseButton color={errorToastStyle?.color as string} />
          }
        );
        navigate(`${routes.SET_PASSWORD}?organizationId=${org_id}&email=${email}`);
      } else {
        useErrorHandler({
          error,
          toastId: 'SignInFail'
        });
      }
    }
  };

  const handleNavigateToForgotPassword = () => navigate(routes.FORGOT_PASSWORD);

  // const handleNavigateToSignUp = () => {
  //   dispatch(setSignUpValues(signUpInitialValues));
  //   navigate(routes.SIGN_UP);
  //   resetForgotPasswordFormValues();
  // };

  return (
    <FormContainer heading="Sign In">
      <div className="signIn">
        <Formik
          onSubmit={handleSubmit}
          initialValues={userData}
          validationSchema={SignInValidationSchema}
          validateOnBlur={false}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form className="signIn__form">
                {/* org_id field */}

                <div className="signIn__form__field">
                  <TextInput
                    label="Organization ID"
                    name="org_id"
                    placeholder="Enter Organization ID"
                    errors={errors}
                    touched={touched}
                    required={true}
                    type="text"
                  />
                </div>
                {/* email  field */}
                <div className="signIn__form__field">
                  <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="Enter an Email"
                    errors={errors}
                    touched={touched}
                    required={true}
                  />
                </div>

                {/* password field */}
                <div className="signIn__form__field">
                  <div className="relative">
                    <TextInput
                      placeholder="Enter Password"
                      label="Password"
                      name="password"
                      errors={errors}
                      touched={touched}
                      required={true}
                      type={inputType}
                      inputClassName="input_fields placeholder:text-placeholder_text text-normal_text"
                    />

                    <Tooltip
                      title={visible ? 'Hide Password' : 'Show Password'}
                      placement="bottom"
                      arrow={true}
                    >
                      <img
                        src={visible ? IMAGES.VisibilityOnEye : IMAGES.VisibilityOffEye}
                        onClick={toggleVisibility}
                        alt={visible ? 'Show Password' : 'Hide Password'}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      />
                    </Tooltip>
                  </div>
                  <div className="signIn__form__field__wrapper">
                    <Typography
                      variant="body-3"
                      size="regular"
                      className={`signIn__form__field__wrapper__error  ${
                        errors.password && touched.password ? 'visible' : 'invisible'
                      }`}
                    ></Typography>
                    <Typography
                      variant="body-3"
                      size="semiBold"
                      className="signIn__form__field__wrapper__link"
                      onClick={handleNavigateToForgotPassword}
                    >
                      Forgot Password?
                    </Typography>
                  </div>
                </div>

                {/* submit button */}
                <CustomButton
                  variant={ButtonVariants.PRIMARY}
                  isLoading={isSubmitting}
                  type="submit"
                  text={BUTTON_TEXT.SIGN_IN}
                  className="signIn__form__button"
                ></CustomButton>
              </Form>
            );
          }}
        </Formik>
        {/* <div className="signIn__footer">
          <Typography variant="body-3" size="regular" as="span" className="signIn__footer__text">
            Don’t have an account?
          </Typography>
          <Typography
            onClick={handleNavigateToSignUp}
            variant="body-3"
            size="semiBold"
            as="span"
            className="signIn__footer__link"
          >
            &nbsp; Create an account
          </Typography>
        </div> */}
      </div>
    </FormContainer>
  );
};

export default SignIn;
