import React, { useMemo, useRef, useState } from 'react';

import './tooltipCustom.scss';

import useElementPosition from '../../../../hooks/elementPosition/useElementPosition';
import { IElementPosition } from '../../../../hooks/elementPosition/useElementPosition.interface';

type TPlacement = 'top' | 'right' | 'bottom' | 'left';

interface ITooltipCustom {
  children: React.ReactNode;
  text: string | React.ReactNode;
  limitWidth?: boolean;
  offsetY?: number; // determines how far the tooltip is vertically
  offsetX?: number; // determines how far the tooltip is horizontally
  placement?: TPlacement;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  conditionToDisplay?: boolean; // additional condition to control whether to display tooltip, default should be true.
  // There may be some cases, where it is not necessary to display tooltip until some condition is met.
}

/*
This component is similar to Material UI tooltip, but gives more control over content and syle of tootip.
This also different from component "SimpleTooltip", which was created to enable hover and selection etc.
This component can be further modified based on the design requirements.
*/
const TooltipCustom = ({
  children,
  text,
  limitWidth = true,
  offsetY = 8,
  offsetX = 12,
  placement = 'top',
  containerProps,
  conditionToDisplay = true
}: ITooltipCustom) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const simpleTooltipRef = useRef<HTMLDivElement>(null);
  const tooltipMessageRef = useRef<HTMLDivElement>(null);

  const simpleTooltipPosition = useElementPosition({
    ref: simpleTooltipRef,
    triggers: [showTooltip, text]
  }) as IElementPosition;
  const tooltipMessagePosition = useElementPosition({
    ref: tooltipMessageRef,
    triggers: [showTooltip, text]
  }) as IElementPosition;

  const toolTiptop: Record<TPlacement, string> = useMemo(() => {
    return {
      top: `${simpleTooltipPosition.y - tooltipMessagePosition.height - offsetY}px`,
      right: `${simpleTooltipPosition.y - offsetY}px`,
      bottom: `${simpleTooltipPosition.y + simpleTooltipPosition.height + offsetY}px`,
      left: `${simpleTooltipPosition.y - offsetY}px`
    };
  }, [simpleTooltipPosition, tooltipMessagePosition]);

  const toolTipLeft: Record<TPlacement, string> = useMemo(() => {
    const top: number =
      simpleTooltipPosition.x - tooltipMessagePosition.width / 2 + simpleTooltipPosition.width / 2;
    return {
      top: `${top <= 0 ? 0 : `${top}px`}`,
      right: `${simpleTooltipPosition.x + simpleTooltipPosition.width + offsetX}px`,
      bottom: `${
        simpleTooltipPosition.x - tooltipMessagePosition.width / 2 + simpleTooltipPosition.width / 2
      }px`,
      left: `${simpleTooltipPosition.x - tooltipMessagePosition.width - offsetX}px`
    };
  }, [simpleTooltipPosition, tooltipMessagePosition]);

  return (
    <div
      className="tooltip-custom"
      onMouseEnter={() => {
        if (conditionToDisplay) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      ref={simpleTooltipRef}
      {...containerProps}
    >
      {children}
      {
        <>
          <p
            className={`tooltip-custom__tooltip tooltip-custom__tooltip--is-on-${placement} ${
              limitWidth ? 'tooltip-custom__tooltip--limit-width' : ''
            } `}
            ref={tooltipMessageRef}
            style={{
              top: toolTiptop[placement as keyof typeof toolTiptop],
              left: toolTipLeft[placement as keyof typeof toolTipLeft],
              visibility: showTooltip ? 'visible' : 'hidden',
              opacity: showTooltip ? 100 : 0
            }}
          >
            {text}
          </p>
        </>
      }
    </div>
  );
};

export default TooltipCustom;
