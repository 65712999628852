import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import './accessDenied.scss';

import { COMMON_TEXT } from '../../configs/common';
import { routes } from '../../constants/routes';
import useCalculateElementHeight from '../../hooks/common/calculateElementHeight/useCalculateElementHeight';
import { useAppSelector } from '../../hooks/redux/useRedux';
import { IMyProfileState } from '../../store/myProfile/myProfile.interface';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';

export interface IAccessDenied {
  previousLinkRoute?: string | number;
  text?: string;
}

const AccessDenied = () => {
  const accessDeniedRef = useRef<HTMLDivElement | null>(null);

  const activeCloudAccount = useAppSelector<IMyProfileState>((store) => store.MyProfile)
    ?.activeCloudAccount;

  const { elementHeight } = useCalculateElementHeight({ ref: accessDeniedRef, heightOffest: 40 });

  const navigate = useNavigate();

  return (
    <div
      className="access-denied-container"
      ref={accessDeniedRef}
      style={{ height: elementHeight ? elementHeight + 'px' : 'auto' }}
    >
      <div className="access-denied">
        <div className="access-denied__code">
          <p>
            Access<br></br>Denied
          </p>
        </div>
        <div className="access-denied__content">
          <p className="access-denied__content__heading">
            You&rsquo;ve stumbled on a restricted space.
          </p>
          <div className="access-denied__content__buttons">
            <CustomButton
              variant={ButtonVariants.PRIMARY}
              text={
                // will switch back to dashboard navigation once we start working on dashboard
                activeCloudAccount
                  ? COMMON_TEXT.GO_TO_CLOUD_ACCOUNTS
                  : COMMON_TEXT.GO_TO_CLOUD_ACCOUNTS
              }
              className="access-denied__content__buttons__button access-denied__content__buttons__dashboard"
              onClick={() => {
                if (activeCloudAccount) {
                  // will switch back to dashboard navigation once we start working on dashboard
                  navigate(routes.CLOUD_ACCOUNTS);
                } else {
                  navigate(routes.CLOUD_ACCOUNTS);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
