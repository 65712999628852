import { useState } from 'react';
import { permissions } from '@cloudinspot/cis-common-constants';

import { useAppSelector } from '../../../hooks/redux/useRedux';
import { IUserPermissionsList } from '../../../store/teamManagement/teamManagement.interface';
import Permissions from '../permissions/Permissions';
import { MY_PROFILE_TEXT } from '../../../configs';
import { routes } from '../../../constants/routes';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { ITeamManagementUserInitialState } from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';

const EditUserPermissions = ({
  editUserSelectedPermissions,
  setEditUserSelectedPermissions,
  isOwner = false
}: {
  editUserSelectedPermissions: IUserPermissionsList[];
  setEditUserSelectedPermissions: React.Dispatch<React.SetStateAction<IUserPermissionsList[]>>;
  isOwner: boolean;
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  const { userProfileData } = useAppSelector(
    (store) => store.teamManagementUser
  ) as ITeamManagementUserInitialState;

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: MY_PROFILE_TEXT.USERS, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      {
        text: userProfileData.firstName + ' ' + userProfileData.lastName,
        url: routes.TEAM_MANAGEMENT_USER
      },
      { text: MY_PROFILE_TEXT.EDIT_ACCESS_CONTROL, url: '' }
    ]
  });
  return (
    <Permissions
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      userPermissions={permissions as unknown as IUserPermissionsList[]}
      selectedPermissions={isOwner ? permissions : editUserSelectedPermissions}
      setSelectedPermissions={setEditUserSelectedPermissions}
      disableCheckboxes={isOwner}
    />
  );
};

export default EditUserPermissions;
