export const SettingsTabText = {
  ORGANIZATION: 'Organization',
  NOTIFICATIONS: 'Notification',
  ACCOUNT: 'Account'
};
export enum ESettingsActiveTab {
  ORGANIZATION = 'organization',
  NOTIFICATIONS = 'notifications',
  ACCOUNT = 'account'
}

export type TSettingsTab = 'organization' | 'notifications' | 'account';

export const deleteAccountSteps = [
  'All groups and user’s from Team Management will be automatically deleted.',
  'All Slack Channel connections and email participants will be deleted.',
  'All AWS account connections will be deleted. '
];
