import { Modal } from '@material-ui/core';
import classNames from 'classnames';

import { IRestoreArchiveUserModal } from './restoreArchieUserModal.interface';
import './restoreArchiveUserModal.scss';

import IMAGES from '../../../assets';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import Typography from '../typography/Typography';
import CustomButton, { ButtonVariants } from '../buttons/CustomButton';

export enum TYPE_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center'
}

const RestoreArchiveUserModal = ({
  heading,
  open,
  description,
  handleClose,
  handlerFun,
  loading,
  actionButtonText,
  cancelButtonWidth,
  child,
  actionButtonColor,
  icon,
  type = TYPE_ALIGNMENT.CENTER
}: IRestoreArchiveUserModal) => {
  const closeModal = () => {
    handleClose();
  };
  const handleFunction = () => {
    handlerFun();
  };
  return (
    <Modal
      open={open}
      data-testid="common-archive-restore-modal restoreArchive"
      id="common-archive-restore-modal restoreArchive"
      className="delete_modal"
    >
      <PaperCustom variant="medium" className="common-archive-restore-modal restoreArchive">
        <div className="restoreArchive__close">
          <img
            src={IMAGES.crossIcon}
            alt="Close icon"
            data-testid="confirmModalCloseIcon"
            onClick={closeModal}
            className=" cursor-pointer"
          />
        </div>
        <div
          className={classNames(
            'restoreArchive__content',
            type === TYPE_ALIGNMENT.CENTER ? 'alignCenter' : 'alignLeft'
          )}
        >
          {icon && <img src={icon} alt="exclamation" />}
          <Typography
            variant="body-1"
            size="semiBold"
            as="h2"
            className="restoreArchive__content__heading"
          >
            {heading}
          </Typography>
          {description && (
            <Typography
              variant="body-2"
              size="regular"
              as="h4"
              className="restoreArchive__content__description"
            >
              {description}
            </Typography>
          )}
          {child && (
            <Typography
              variant="body-2"
              size="regular"
              as="h4"
              className="restoreArchive__content__child"
            >
              {child}
            </Typography>
          )}

          <div className="restoreArchive__content__buttonsWrapper">
            <CustomButton
              style={{ width: cancelButtonWidth }}
              onClick={closeModal}
              text="Cancel"
              variant={ButtonVariants.SECONDARY}
            />
            <CustomButton
              onClick={handleFunction}
              text={actionButtonText}
              style={{ background: actionButtonColor }}
              isLoading={loading}
              className="restoreArchive__content__buttonsWrapper__delete"
            />
          </div>
        </div>
      </PaperCustom>
    </Modal>
  );
};

export default RestoreArchiveUserModal;
