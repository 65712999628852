import * as axios from 'axios';
import { useState } from 'react';

import { IuseGetRequestProps } from './userGetRequest.interface';

import getWithAuthCloud from '../../services/cloudAccounts/getWithCloudAuth';
import get from '../../services/get.service';
import getWithAuth from '../../services/getWithAuth';
import useDebounce from '../debounce/useDebounce';
import useErrorHandler from '../error-handler/useErrorHandler';

const useGetRequest = ({
  url,
  params,
  callback,
  trigger = true,
  delay = 0,
  withAuth = true,
  cloudAuth = false,
  redirectTo404 = false
}: IuseGetRequestProps) => {
  const [data, setData] = useState<axios.AxiosResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [useGetRequestCounter, setUseGetRequestCounter] = useState<number>(1);

  const getService = withAuth ? (cloudAuth ? getWithAuthCloud : getWithAuth) : get;
  const getRequest = async () => {
    setLoading(true);
    try {
      const response = await getService({ url, params });
      if (setData) {
        setData(response);
      }
      if (callback) {
        callback({ response });
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'useGetRequestFail',
        defaultMessage: 'Your request has failed due to unknown reasons! Please try again later',
        redirectTo404
      });
    } finally {
      setLoading(false);
    }
  };

  useDebounce({
    func: getRequest,
    delay,
    dependency: useGetRequestCounter,
    trigger
  });

  return { data, loading, setUseGetRequestCounter, getRequest };
};

export default useGetRequest;
