// External imports
import classNames from 'classnames';
import React, { useEffect } from 'react';

// Internal imports
import { ITeamMangUser } from '../../../../store/teamManagement/teamManagement.interface';
import { textEllipsis } from '../../../../utils';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import Typography from '../../../common/typography/Typography';

export interface IAddUserToGroupProps {
  user: ITeamMangUser;
  addToSelectedUsers: (user: ITeamMangUser) => void;
  index: number;
  selectedUsers: ITeamMangUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<ITeamMangUser[]>>;
  currentUsers: ITeamMangUser[];
  removeUsersFromGroupList: string[];
  setRemoveUsersFromGroupList: React.Dispatch<React.SetStateAction<string[]>>;
  checkedUsers: ITeamMangUser[];
  setCheckedUsers: React.Dispatch<React.SetStateAction<ITeamMangUser[]>>;
}

const tooltipCharLimitValue: number = 25;

// Note for future
// Considering the v3 changes, which makes this component similar to the one in Add Group flow
// Both components can potentially be combined.
export const AddUserToGroup = ({
  user,
  addToSelectedUsers,
  currentUsers,
  checkedUsers,
  setCheckedUsers,
  selectedUsers
}: IAddUserToGroupProps) => {
  const checkIfCheckboxSelected = (checkUser: ITeamMangUser) => {
    return checkedUsers.map((user) => user.email).includes(checkUser.email);
  };

  const handleSelectClick = (user: ITeamMangUser) => {
    const existingUser = currentUsers.find((extUser) => extUser.email === user.email);

    if (!existingUser) {
      addToSelectedUsers(user);
      const isChecked = checkIfCheckboxSelected(user);
      isChecked
        ? setCheckedUsers(checkedUsers.filter((checkedUser) => checkedUser.email !== user.email))
        : setCheckedUsers([...checkedUsers, user]);
    }
  };

  useEffect(() => {
    // checked users will always be summation of existing users and selected users
    setCheckedUsers([...currentUsers, ...selectedUsers]);
  }, []);

  const nameToDisplay: string = `${user.firstName} ${user.lastName}`;

  return (
    <div
      className={classNames('tm-add-group-add-users-to-group', {
        'tm-add-group-add-users-to-group--is-selected': checkIfCheckboxSelected(user)
      })}
      onClick={() => {
        handleSelectClick(user);
      }}
    >
      <TooltipCustom
        text={
          <div className="user-tooltip">
            <Typography variant="body-3" size="regular" as="p">
              {nameToDisplay}
            </Typography>
            <Typography variant="body-3" size="regular" as="p">
              {user.email}
            </Typography>
          </div>
        }
        limitWidth={false}
        conditionToDisplay={
          nameToDisplay?.length > tooltipCharLimitValue ||
          user.email?.length > tooltipCharLimitValue
        }
      >
        <div className="tm-add-group-add-users-to-group__user">
          <Typography variant="body-3" size="regular" as="p" className="name">
            {textEllipsis({ text: nameToDisplay, charLimit: tooltipCharLimitValue })}
          </Typography>
          <Typography variant="caption" size="regular" as="p" className="email">
            {textEllipsis({ text: user.email, charLimit: tooltipCharLimitValue })}
          </Typography>
        </div>
      </TooltipCustom>
      <input checked={checkIfCheckboxSelected(user)} type="checkbox" id={user.email} readOnly />
    </div>
  );
};
