// eslint-disable-next-line
import axios from 'axios';

import authInterceptor from '../authInterceptors';
import { getApiMSTeamsUrl } from '../getAuthTokensAndURL';

const authHttpMSTeams = axios.create({ baseURL: getApiMSTeamsUrl() });

// attaches the intercept here.
authInterceptor(authHttpMSTeams);
// TODO: @arman-scalex Lets remove this. We don't need this now.
export default authHttpMSTeams;
