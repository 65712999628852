// External imports
import * as Yup from 'yup';
import { Country } from 'country-state-city';

// import { Timezone } from '../../../enums';
import useTimezones from '../../hooks/userAuthentication/timezones/useTimezones';
import timezoneConverter from '../../utils/timezoneConverter/timezoneConverter';

const Countries = Country.getAllCountries().map((item) => item.name);
const Currency = Country.getAllCountries().map((item) => item.currency);
const Timezones = timezoneConverter(useTimezones()).map((tz) => tz.name);

export const useruserOnboardingStepOneValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed for the first name')
    .max(25, 'First Name should not exceed 25 characters')
    .required('Please enter the first name'),
  lastName: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed for the last name')
    .max(25, 'Last name should not be longer than 25 characters')
    .required('Please enter the last name'),
  organizationName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9\s]+$/, 'Organization name should only contain letters and numbers')
    .min(2, 'The organization name should be between 2 and 100 characters long')
    .max(100, 'The organization name should be between 2 and 100 characters long')
    .required('Please enter your organization name'),
  jobTitle: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9 ]+$/, 'Only alphabets and numbers are allowed for job title')
    .min(2, 'The job title must be between 2 and 100 characters long')
    .max(100, 'The job title must be between 2 and 100 characters long')
    .required('Please enter the job title')
});

const useruserOnboardingStepTwoValidationSchema = Yup.object().shape({
  country: Yup.mixed()
    .oneOf(Object.values(Countries), 'Please select your country from the available options')
    .required('Please select your country from the available options'),
  state: Yup.string().required('Select your State from the available options'),
  currency: Yup.mixed()
    .oneOf(Object.values(Currency), 'Please select your currency from the available options')
    .required('Please select your currency from the available options'),
  // city: Yup.string().required('Please select your City'), City excluded from form for now
  timezone: Yup.mixed()
    .oneOf(Object.values(Timezones), 'Please select your timezone from the available options')
    .required('Please select your timezone from the available options')
});

export default useruserOnboardingStepTwoValidationSchema;
