import React from 'react';

import { Text, TextType } from '../../utils/Text';

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div className="grid place-content-center">
        <Text type={TextType.H1} className="text-center my-12">
          Privacy Policy
        </Text>
        <Text type={TextType.H3_01} className="px-4 sm:px-40 space-y-6">
          <div data-testid="effective-date">Effective Date: 25 June 2023</div>
          <div data-testid="scalex-technology-solutions">
            At Scalex Technology Solutions LLP, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy Policy outlines how we
            collect, use, disclose, and protect the information you provide when using our
            CloudInSpot Cloud Cost Optimization Web App (&quot;CloudInSpot&quot;). Please read this
            Privacy Policy carefully to understand our practices regarding your personal
            information.
          </div>
          <div className="space-y-3" data-testid="information-we-collect">
            <b>Information We Collect</b>
            <p>
              <b>Personal Information</b> <br /> When you use CloudInSpot, we may collect personal
              information that you voluntarily provide to us. This information may include your
              name, email address, contact details, and any other information you choose to provide.
            </p>
            <p>
              <b> Usage Data</b> <br /> We also collect non-personal information about your use of
              CloudInSpot, which may include IP addresses, browser type, operating system, and other
              technical information. This data is collected automatically through cookies and
              similar technologies.
            </p>
          </div>
          <div className="space-y-3" data-testid="use-of-information">
            <b>Use of Information</b>
            <p>
              Provide and Improve CloudInSpot We use the information collected to provide you with
              access to CloudInSpot and its features, personalize your experience, and improve the
              functionality and performance of CloudInSpot.
            </p>
            <p>
              <b>Communication</b> <br /> We may use your personal information to communicate with
              you, respond to your inquiries, and provide customer support related to CloudInSpot.
              Analytics and Research We may aggregate and anonymize the information collected to
              analyze trends, monitor usage patterns, and conduct research to improve our services
              and develop new features.
            </p>
            <p>
              <b>Legal Compliance</b> <br /> We may use or disclose your information if required by
              law, court order, or government regulation, or if we believe that such action is
              necessary to protect our rights, property, or safety, or that of others.
            </p>
          </div>
          <div className="space-y-3" data-testid="sharing-of-information">
            <b>Sharing of Information</b>
            <p>
              <b>Service Providers</b> <br /> We may share your personal information with
              third-party service providers who assist us in operating, maintaining, and improving
              CloudInSpot. These service providers are obligated to protect the confidentiality and
              security of your information.
            </p>
            <p>
              <b>Business Transfers</b> <br /> In the event of a merger, acquisition, or sale of all
              or a portion of our assets, your information may be transferred to the acquiring
              organization as part of the transaction. We will notify you of any such transfer and
              provide you with the option to opt-out if your personal information will be used
              contrary to this Privacy Policy.
            </p>
            <p>
              <b>Legal Requirements</b> <br /> We may disclose your information if required to do so
              by law or in response to valid requests by public authorities (e.g., a court or
              government agency).
            </p>
          </div>
          <div className="space-y-3" data-testid="data-security">
            <b>Data Security</b>
            <p>
              We implement appropriate technical and organizational measures to safeguard the
              personal information we collect. However, no security measures are perfect, and we
              cannot guarantee the absolute security of your information. You are responsible for
              maintaining the confidentiality of any login credentials or other authentication
              mechanisms associated with your CloudInSpot account.
            </p>
          </div>
          <div className="space-y-3 grid" data-testid="choices-and-rights">
            <b>Your Choices and Rights </b>
            <b>Access and Update</b>
            <p>
              You have the right to access, update, and correct your personal information. You can
              do this by logging into your CloudInSpot account or by contacting us using the contact
              information provided below.
            </p>
          </div>
          <div className="space-y-3" data-testid="communications">
            <b> Communications </b>
            <p>
              You may choose to opt-out of receiving promotional communications from us by following
              the instructions provided in such communications. However, we may still send you
              non-promotional messages regarding your use of CloudInSpot.
            </p>
          </div>
          <div className="space-y-3" data-testid="california-consumer-privacy-act">
            <b>California Consumer Privacy Act (CCPA)</b>
            <p>
              The personal information that we may collect, or may have collected from consumers
              fall into the following categories established by the California Consumer Privacy Act,
              depending on how you engage with CloudInSpot’s Offerings:
            </p>
          </div>
          <div className="space-y-3" data-testid="identifiers">
            <ul className="list-disc">
              <li>Identifiers, such as your name, alias, address, phone numbers, or IP address;</li>
              <li>
                personal information as described in subdivision (e) of Section 1798.80 of the
                California Civil Code, such as a credit card number;
              </li>
              <li>
                characteristics of protected classifications under California or US federal law,
                such as age or gender, for example, if we conduct user surveys or analysis;
              </li>
              <li>commercial information, such as purchase activity;</li>
              <li>
                Internet or other electronic network activity information, including content
                interaction information, such as content downloads, streams, and playback details;
              </li>
              <li>
                audio, visual, electronic or other similar information, including when you
                communicate with us by phone or otherwise;
              </li>
              <li>
                professional or employment-related information, for example, data you may provide
                about your business;
              </li>
              <li>inference data, such as information about your preferences</li>
            </ul>
            <p>
              For more information about the personal information we collect, see the above section
              on information collected by CloudInSpot.
            </p>
            <p>
              Categories of personal information disclosed for a business purpose. The personal
              information that we may have disclosed about consumers for a business purpose in the
              preceding twelve months fall into the following categories established by the
              California Consumer Privacy Act, depending on how you engage with CloudInSpot&apos;s
              Offerings:
            </p>
            <ul className="list-disc">
              <li>
                Identifiers, such as your name, email, address, or phone numbers, for example, if we
                use a third party to facilitate customer contracts;
              </li>
              <li>
                personal information as described in subdivision (e) of Section 1798.80 of the
                California Civil Code, such as a credit card number, for example, if we use a
                third-party payment processor;
              </li>
              <li>
                your age, gender, or other protected classifications under California or US federal
                law, for example, if we conduct user surveys or analysis using a third-party service
                provider;
              </li>
              <li>
                commercial information, such as the details of a product or service you purchased if
                a third-party service provider is assisting to provide that product or service to
                you;
              </li>
              <li>
                Internet or other electronic network activity information, such as if we use a
                third-party service provider to help us gather reports for analyzing the health of
                our services;
              </li>
              <li>
                audio, visual, electronic or other similar information, for example, if a
                third-party service provider reviews recordings of customer support phone calls for
                quality assurance purposes;
              </li>
            </ul>
            <p>
              For more information about the personal information, we may disclose to third parties
              for a business purpose, please see the relevant sections in this document above.
            </p>
            <h4>
              <b>Your Rights</b>
            </h4>
            <p>
              You may have the right under the California Consumer Privacy Act to request
              information about the collection of your personal information by us, or access to or
              deletion of your personal information. If you wish to do any of these things, please
              contact us. Depending on your data choices, certain services may be limited or
              unavailable.
            </p>
          </div>
          <div className="space-y-3" data-testid="no-sale-of-personal-information">
            <b>No sale of personal information</b>
            <p>
              In the preceding twelve months, we have not sold any personal information of
              consumers, as those terms are defined under the California Consumer Privacy Act.
            </p>
          </div>
          <div className="space-y-3" data-testid="no-discrimination">
            <b>No Discrimination</b>
            <p>
              We will not discriminate against any consumer for exercising their rights under the
              California Consumer Privacy Act.
            </p>
          </div>

          <div className="space-y-3 grid" data-testid="gdpr-information-for-eu-consumers">
            <b>GDPR Information for EU Customers</b>
            <b> Personal information</b>
            <p>
              The personal information we collect may be considered Personal Data under the General
              Data Protection Regulation.
            </p>
          </div>
          <div className="space-y-3" data-testid="data-transfer">
            <b> Data Transfer </b>
            <p>
              {' '}
              We provide our Services from the US. That means that we may transfer your data from
              the EU to the US. Because the European Commission has not provided a decision of
              adequacy for the US, we protect your privacy and your rights through the use of the
              European Commission’s standard data protection clauses.
            </p>
            <p>
              We’ll take all steps reasonably necessary to ensure that your data is treated securely
              and in accordance with this Privacy Policy and no transfer of your personal
              information will take place to an organization or a country unless there are adequate
              controls in place including the security of your data and other personal information.
            </p>
          </div>
          <div className="space-y-3" data-testid="your-rights">
            <b>Your Rights</b>
            <p>
              If you’re located in the EU, you have the following rights relating to your personal
              information:
            </p>
            <ul className="list-disc">
              <li>The right to be informed about how your personal information is being used</li>
              <li>The right to access the personal information we hold about you</li>
              <li>
                The right to request the correction of inaccurate personal information we hold about
                you
              </li>
              <li>
                The right to request that we delete your data, or stop processing it or collecting
                it, in some circumstances
              </li>
              <li>
                The right to stop direct marketing messages, which you can do through My Account,
                and to withdraw consent for other consent-based processing at any time
              </li>
              <li>
                The right to request that we transfer or port elements of your data either to you or
                another service provider
              </li>
              <li>
                The right to complain to your data protection regulator — in the UK, the Information
                Commissioner’s Office
              </li>
            </ul>
          </div>

          <div className="space-y-3" data-testid="your-consent">
            <b>Your Consent</b>
            <p>
              By using CloudInSpot, you consent to the collection, use, and disclosure of your
              information as described in this Privacy Policy.
            </p>
          </div>
          <div className="space-y-3" data-testid="third-party-and-services">
            <b>Third-Party Links and Services</b>
            <p>
              CloudInSpot may contain links to third-party websites or services that are not
              operated or controlled by us. This Privacy Policy does not apply to such third-party
              websites or services. We encourage you to review the privacy policies of those third
              parties before providing any personal information.
            </p>
          </div>
          <div className="space-y-3" data-testid="location-of-personal-information">
            <b>Location of Personal Information</b>
            <p>
              At CloudInSpot, your privacy is our priority. We store and process your personal data
              in the secure Asia Pacific (Mumbai) region of AWS. With industry-leading security
              measures and strict data protection protocols, your information is in safe hands.
              Amazon Web Services, Inc. is located in the United States, and their affiliated
              companies are located throughout the world. We comply with applicable data protection
              laws and regulations, implementing access controls to prevent unauthorized access.
              Your privacy is important to us, and we value the confidentiality of your data.
            </p>
          </div>
          <div className="space-y-3" data-testid="data-retention-policy">
            <b>Data Retention Policy</b>
            <p>
              At CloudInSpot, we understand the importance of data privacy and the need for a clear
              data retention policy. We retain customer data related to the Cost and Usage reports
              generated by the CloudInSpot web app. We do not store any data sent to the slack app.
              This retention period allows us to provide our customers with access to historical
              data for analysis and reporting purposes. After the specified retention period, the
              data is securely deleted from our systems in accordance with our data archival/removal
              policy.
            </p>
          </div>
          <div className="space-y-3" data-testid="data-archival-removal-policy">
            <b>Data Archival/Removal Policy</b>
            <p>
              CloudInSpot follows a stringent data archival/removal policy to ensure the protection
              and privacy of customer data. Once the data retention period expires, we promptly
              remove all customer data related to the Cost and Usage reports from our systems. This
              removal process includes securely erasing the data from our databases, backups, and
              any other storage media to prevent any unauthorized access or use. Our data
              archival/removal policy ensures that customer data is handled responsibly and in
              compliance with applicable data protection laws and regulations.
            </p>
          </div>
          <div className="space-y-3" data-testid="data-storage-policy">
            <b>Data Storage Policy</b>
            <p>
              The security and integrity of customer data are of utmost importance to CloudInSpot.
              We employ industry-standard security measures to safeguard the data stored by our
              Slack app. All customer data, including the Cost and Usage reports, is securely stored
              in encrypted format. CloudInSpot ensures that appropriate technical and organizational
              measures are in place to protect the data against unauthorized access, disclosure,
              alteration, or destruction. Our data storage policy guarantees that customer data is
              stored in a secure environment, minimizing the risk of data breaches or unauthorized
              use.
            </p>
          </div>
          <div
            className="space-y-3"
            data-testid="procedure-for-handling-requests-for-data-deletion"
          >
            <b>Procedure for Handling Requests for Data Deletion</b>
            <p>
              CloudInSpot acknowledges individuals&apos; rights to have their personal data deleted,
              as per applicable data protection laws. If a user of the CloudInSpot Slack app
              requests the deletion of their data, including the Cost and Usage reports, we have
              established a clear procedure to handle such requests. Users can submit a data
              deletion request by contacting our support team through the designated channel or
              email address. Upon receiving a valid request, we will promptly review and verify the
              request and initiate the data deletion process in accordance with our data
              archival/removal policy. We aim to respond to data deletion requests within a
              reasonable timeframe and ensure that all customer data is securely and permanently
              deleted from our systems.
            </p>
            <p>
              Please note that while using the CloudInSpot Slack app, we only store cost and usage
              data related to the Cost and Usage reports generated from the customer&apos;s AWS
              account. Additionally, the data sent by default from Slack during the integration
              process is stored for the sole purpose of providing the functionality of the
              CloudInSpot app. We do not store any other personal or sensitive information beyond
              what is necessary for the proper functioning of the app. At CloudInSpot, we are
              committed to maintaining the privacy and security of our users&apos; data, and we
              continuously strive to comply with industry best practices and applicable data
              protection regulations.
            </p>
          </div>

          <div className="space-y-3" data-testid="children policy">
            <b>Children&apos;s Privacy</b>
            <p>
              CloudInSpot is not intended for use by individuals under the age of 13. We do not
              knowingly collect personal information from children. If you become aware that a child
              has provided us with personal information, please contact us using the contact
              information provided below, and we will take steps to delete the information.
            </p>
          </div>
          <div className="space-y-3" data-testid="changes-to-this-privacy-policy">
            <b>Changes to this Privacy Policy</b>
            <p>
              We may update this Privacy Policy from time to time to reflect changes in our
              practices and services. We will notify you of any material changes by posting the
              updated Privacy Policy on the CloudInSpot website or by other means of communication.
              Your continued use of CloudInSpot after the effective date of any changes constitutes
              your acceptance of the revised Privacy Policy.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="contact-us">
            <b>Contact Us</b>
            <p>
              If you have any questions or concerns about this Privacy Policy or our privacy
              practices, please contact us at:
              <h4>Scalex Technology Solutions LLP</h4>
              <h4>Office Address: 205-A, Suratwala Mark Plazzo, Hinjawadi, Pune 411057</h4>
              <h4>Email: sales@scalextechnologies.com</h4>
              <h4>Phone: +91 77560 26392</h4>
            </p>
          </div>
          <h4 data-testid="thankyou">Thank you for taking the time to read our Privacy Policy.</h4>
        </Text>
      </div>
    </React.Fragment>
  );
};
export default PrivacyPolicy;
