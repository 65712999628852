import { CloudAccountStatus } from '@cloudinspot/cis-common-constants';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './accountView.scss';

import AccountInfo from '../../components/cloudAccounts/accountView/accountInfo/AccountInfo';
import Email from '../../components/cloudAccounts/accountView/email/Email';
import MsTeam from '../../components/cloudAccounts/accountView/msteam/msTeam';
import shouldHideEmailSlackTab from '../../components/cloudAccounts/accountView/shouldHideEmailSlackTab';
import Slack from '../../components/cloudAccounts/accountView/slack/Slack';
import { IAccountViewProps, IParams } from '../../components/cloudAccounts/cloudAccounts.interface';
import { EActiveTabInsideAccountView, TabTexts } from '../../components/cloudAccounts/constants';
import Tabs from '../../components/common/customTabs/CustomTabs';
import { TTabItem } from '../../components/common/customTabs/CustomTabs.interface';
import useCheckUserPermissions from '../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  fetchCloudAccountDetailsRequest,
  setActiveTabInsideAccountView,
  updateSkeletonLoader
} from '../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';
import { ICommonReducerInitialState } from '../../store/common/common.interface';
// eslint-disable-next-line
import useLoading from '../../hooks/loading/useLoading';

const AccountView = ({ activeTabAccountView }: IAccountViewProps) => {
  const params: IParams = useParams() as unknown as IParams;
  const { activeTabInsideAccountView, accountDetails } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;
  const { showSkeletonLoader } = useAppSelector<ICommonReducerInitialState>(
    (store) => store.common
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    canUserViewAccountInfo,
    canUserViewSlackAccount,
    canUserViewMsTeamAccount,
    canUserViewEmailList
  } = useCheckUserPermissions();
  const { startLoading, stopLoading } = useLoading();

  const shouldHideEmailSlackTabs: boolean = shouldHideEmailSlackTab({
    accountStatus: accountDetails.status
  });

  const userTabItems: TTabItem[] = [
    {
      key: EActiveTabInsideAccountView.ACCOUNT_INFO,
      text: TabTexts.ACCOUNT_VIEW,
      disabled: !canUserViewAccountInfo
    }
  ];

  useEffect(() => {
    dispatch(
      fetchCloudAccountDetailsRequest({
        accountId: params.id,
        startLoading,
        stopLoading: () => {
          stopLoading();
          dispatch(updateSkeletonLoader({ isLoading: false }));
        }
      })
    );
  }, [params.id]);
  // as per BRD
  // If the AWS account status is Failed or In progress
  // then hide the slack and email word (which are subtabs) from account view screen
  if (!shouldHideEmailSlackTabs) {
    userTabItems.push(
      {
        key: EActiveTabInsideAccountView.SLACK,
        text: TabTexts.SLACK,
        disabled: !canUserViewSlackAccount
      },
      {
        key: EActiveTabInsideAccountView.MSTEAMS,
        text: TabTexts.MSTEAMS,
        disabled: !canUserViewMsTeamAccount
      },
      {
        key: EActiveTabInsideAccountView.EMAIL,
        text: TabTexts.EMAIL,
        disabled: !canUserViewEmailList
      }
    );
  }

  const AccountViewComponents = {
    [EActiveTabInsideAccountView.ACCOUNT_INFO]: <AccountInfo />,
    [EActiveTabInsideAccountView.SLACK]: <Slack />,
    [EActiveTabInsideAccountView.MSTEAMS]: <MsTeam />,
    [EActiveTabInsideAccountView.EMAIL]: <Email />,
    [EActiveTabInsideAccountView.EMPTY]: 'No Data'
  };

  const handleTabChange = (value: string) => {
    if (value === EActiveTabInsideAccountView.ACCOUNT_INFO)
      navigate(`/cloud-account/view/${params.id}`);
    else if (value === EActiveTabInsideAccountView.EMAIL)
      navigate(`/cloud-account/view/email/${params.id}`);
    else if (value === EActiveTabInsideAccountView.SLACK)
      navigate(`/cloud-account/view/slack/${params.id}`);
    else if (value === EActiveTabInsideAccountView.MSTEAMS)
      navigate(`/cloud-account/view/msteam/${params.id}`);
    else dispatch(setActiveTabInsideAccountView(value)); //just to avoid unnecessary behavior
  };

  useComponentMount({
    onMountFunc: () => {},
    onUnMountFunc: () => {
      dispatch(updateSkeletonLoader({ isLoading: true }));
    }
  });

  return (
    <div
      className={`cloud-accounts-account-view ${
        showSkeletonLoader ? 'cloud-accounts-account-view__loader' : ''
      }`}
    >
      <div className="cloud-accounts-account-view__tabs">
        {accountDetails.status !== CloudAccountStatus.Inactive && (
          <Tabs
            items={userTabItems}
            onChange={handleTabChange}
            activeItem={
              activeTabInsideAccountView ? activeTabInsideAccountView : activeTabAccountView
            }
            isLoading={showSkeletonLoader}
          />
        )}
      </div>

      <div className="cloud-accounts-account-view__content">
        {
          AccountViewComponents[
            activeTabInsideAccountView ? activeTabInsideAccountView : activeTabAccountView
          ]
        }
      </div>
    </div>
  );
};

export default AccountView;
