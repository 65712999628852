import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './connectAccountSteps.scss';

import IMAGES from '../../../assets';
import { confirmError, confirmProgress, confirmSuccess } from '../../../assets/images';
import { CLOUD_ACCOUNTS_TEXT } from '../../../configs';
import { successToastStyle } from '../../../configs/styleConstants';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../../constants/constants';
import { routes } from '../../../constants/routes';
import ENUMS from '../../../enums';
import getUserData from '../../../helpers/getUserData';
import useComponentMount from '../../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import { useAppSelector } from '../../../hooks/redux/useRedux';
import postWithCloudAuth from '../../../services/cloudAccounts/postWithCloudAuth';
import { ICloudAccountsReducerInitialState } from '../../../store/cloudAccounts/cloudAccounts.interface';
import useAccountSummaryStore from '../../../store/cloudAccounts/useAccountSummaryStore';
import type { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import { getFetchAccountQueryKey } from '../../../utils/pre-fetchers/prefetchCloudAccounts';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import CloseButton from '../../common/close-button/CloseButton';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import CopyToClipboard from '../../common/copy-to-clipboard/CopyToClipboard';
import Loader from '../../common/loader/loader';
import LogoWithHeading from '../../common/logo-with-heading/logoWithHeading';
import Typography from '../../common/typography/Typography';
import {
  IConfirmConnectAccount,
  IConnectAccountProps,
  IConnectAWSResponse
} from '../cloudAccounts.interface';

const { CloudAccountsStatuses } = ENUMS.cloudAccounts;

const ConnectAccountSteps = ({ handleSetIsStepsOpen }: IConnectAccountProps) => {
  const [isConnectClicked, seIsConnectClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isNotVerifiedModalOpen, setIsNotVerifiedModalOpen] = useState<boolean>(false);
  const [isinProgressOpen, setIsinProgressOpen] = useState<boolean>(false);

  const connectAccountStepsRef = useRef<HTMLDivElement>(null);
  const { setHasAccounts } = useAccountSummaryStore();
  const firstName: string = useAppSelector<IMyProfileState>((state) => state.MyProfile)?.firstName;

  const { connectToAwsData } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleConnectAWS = async () => {
    setLoading(true);
    try {
      const { email } = getUserData();
      const payload = {
        createdBy: email,
        accountId: connectToAwsData.accountId,
        accountName: connectToAwsData.accountName,
        budget: parseInt(connectToAwsData.budget)
      };
      const response = await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_ADD_ACCOUNT,
        payload: payload
      });
      const responseData: IConnectAWSResponse = response.data;
      seIsConnectClicked(true);
      // reset cloud account list cache after adding new account
      queryClient.resetQueries(
        getFetchAccountQueryKey({
          limit: ROWS_PER_PAGE_FIRST_ITEM,
          offset: offsetFirst
        })
      );
      setHasAccounts(true);
      window.open(responseData.redirectUrl, '_blank');
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'ConnectAWSFail'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmClick = async () => {
    try {
      const response = await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_CONFIRM_CONNECT_ACCOUNT,
        payload: { accountId: connectToAwsData.accountId.toString() }
      });

      const data = response.data as unknown as IConfirmConnectAccount;

      if (data.account.status === CloudAccountsStatuses.InProgress) {
        setIsinProgressOpen(true);
        return;
      } else if (data.account.status === CloudAccountsStatuses.Active) {
        setIsConfirmModalOpen(true);
        return;
      }
    } catch (error) {
      setIsNotVerifiedModalOpen(true);
    }
  };

  const handleNavigateToCloudAccounts = () => {
    navigate(routes.CLOUD_ACCOUNTS, { replace: true });
  };

  useComponentMount({
    onMountFunc: () => {
      toast.success(CLOUD_ACCOUNTS_TEXT.CONNECT_ACCOUNT_TOAST_MSG, {
        style: successToastStyle,
        toastId: 'ConnectAccountToastMSG',
        closeButton: <CloseButton color={successToastStyle?.color as string} />
      });
    }
  });

  return (
    <PaperCustom variant="medium">
      <div className="connect-account-steps" ref={connectAccountStepsRef}>
        {/* ConfirmErrorModal */}
        {isConfirmModalOpen && (
          <ConfirmModal
            open={isConfirmModalOpen}
            setOpen={setIsConfirmModalOpen}
            title={`Great Job, ${firstName}!`}
            image={confirmSuccess}
            confirmButtonText="OKAY"
            description={
              <>
                Your AWS Account connected with <CloudInSpotName /> successfully.
              </>
            }
            handleOkClick={handleNavigateToCloudAccounts}
          />
        )}

        {/* Not Verified Account */}
        {isNotVerifiedModalOpen && (
          <ConfirmModal
            open={isNotVerifiedModalOpen}
            setOpen={setIsNotVerifiedModalOpen}
            title={`Oops! Something went wrong!`}
            image={confirmError}
            confirmButtonText="OKAY"
            description="We couldn't connect to AWS. Please try again later. If the problem continues, reach out to our support team for help."
            descriptionWidth="88%"
            handleOkClick={handleNavigateToCloudAccounts}
          />
        )}
        {/* In Progress */}
        {isinProgressOpen && (
          <ConfirmModal
            open={isinProgressOpen}
            setOpen={setIsinProgressOpen}
            title={`Connection in progress !`}
            image={confirmProgress}
            confirmButtonText="OKAY"
            description={
              <>
                Connecting your aws account to <CloudInSpotName />. This may take a moment. Thank
                you for your patience!
              </>
            }
            descriptionWidth="88%"
            handleOkClick={handleNavigateToCloudAccounts}
          />
        )}
        {/* Steps */}
        <div className="connect-account-steps__steps">
          <LogoWithHeading
            headingText={CLOUD_ACCOUNTS_TEXT.STEPS_TO_CONNECT_YOUR_AWS_ACCOUNT}
            logoSrc={IMAGES.infoCircle}
          />
          {/* Step 1 */}
          <div className="connect-account-steps__steps__step">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 1 : Login to correct AWS account
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="connect-account-steps__steps__step__content stepDesc"
            >
              We require access to your AWS billing data to assist you with your billing. So please
              log in through AWS account ID you have entered in previous screen.
              {/* Copy to clipboard */}
              <CopyToClipboard
                textLabel="Your Account Id is :"
                textToCopy={connectToAwsData.accountId}
                toastMessage="Account ID copied to clipboard!"
                toastId="accountIdToast"
              />
            </Typography>
          </div>
          {/* Step 2 */}
          <div className="connect-account-steps__steps__step create-stack-step">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 2 : Connect Individual and Organization AWS Accounts
            </Typography>
            <div className="border-l border-l-border_disabled  ml-5 pl-8 pr-4 flex flex-col gap-[12px] lg:pb-4">
              {/* Note */}
              <Typography
                variant="body-3"
                size="regular"
                as="p"
                className="py-2 lg:py-4 text-normal-text block w-fit stepDesc"
              >
                If you&apos;re using an <span className="font-semibold">individual account,</span>
                follow ONLY step <span className="font-semibold">2b.</span> If you&apos;re using an{' '}
                <span className="font-semibold">organization account,</span> complete{' '}
                <span className="font-semibold">step 2a </span>
                first, then proceed to <span className="font-semibold">step 2b.</span>
              </Typography>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
                  a) Activate Trusted Access for AWS Organizations
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  Open the AWS CloudFormation console and, from the navigation pane, choose
                  “StackSets”.
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  If trusted access is deactivated, a banner will display prompting you to activate
                  trusted access. Click the Activate Trusted Access button to enable trusted access.
                </Typography>
              </div>

              {/* AWSActivateTrustedAccess image */}
              <img
                src={IMAGES.AWSActivateTrustedAccess}
                alt="AWS demo image"
                className="aws_activate_trusted hidden sm:block"
                loading="lazy"
              />
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
                  b) Create Stack
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  Click on “Connect to AWS” button to open the AWS CloudFormation template page.
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  We will run a cloud formation template that will enable us to view your AWS
                  billing data.
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  Scroll to the capabilities Section and check following TWO checkboxes
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  “I acknowledge that AWS Cloudformation might create IAM resources with custom
                  names”.
                </Typography>
              </div>
              <div className="flex  flex-col gap-[12px]">
                <Typography variant="body-3" size="regular" as="p" className="stepDesc">
                  “I acknowledge that AWS Cloudformation might require the following capability :
                  CAPABILITY_AUTO_EXPAND” then click “Create Stack” button
                </Typography>
              </div>
              <img
                src={IMAGES.ConnectAWS}
                alt="AWS demo image"
                className="aws_image inline-block -ml-1 hidden sm:block"
                loading="lazy"
              />
            </div>
          </div>

          {/* Step 3 */}
          <div className="connect-account-steps__steps__step create-stack-step">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 3 : Come back on this tab and click Confirm button.
            </Typography>
          </div>
        </div>

        {/* Button Card */}
        <div className="bottomCardWrapper">
          <PaperCustom className="bottomCardWrapper__bottomCard">
            <CustomButton
              text="Back"
              onClick={handleSetIsStepsOpen}
              variant={ButtonVariants.SECONDARY}
              className="back_btn"
            />
            <div className="bottomCardWrapper__bottomCard__btnContainer">
              <CustomButton
                text={loading ? <Loader /> : CLOUD_ACCOUNTS_TEXT.CONNECT_AWS}
                onClick={handleConnectAWS}
                className="connect_btn"
              />
              <CustomButton
                text="Confirm"
                disabled={!isConnectClicked}
                onClick={handleConfirmClick}
                className={`confirm_btn ${
                  isConnectClicked
                    ? 'bottomCardWrapper__bottomCard__btnContainer__confirmBtn'
                    : 'bottomCardWrapper__bottomCard__btnContainer__confirmBtnDisabled'
                }`}
              />
            </div>
          </PaperCustom>
        </div>
      </div>
    </PaperCustom>
  );
};

export default ConnectAccountSteps;
