import { Modal } from '@mui/material';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import './emailListActions.scss';

import { confirmSuccess } from '../../../../assets/images';
import { BUTTON_TEXT, CLOUD_ACCOUNTS_TEXT } from '../../../../configs';
import { CLOUD_ACCOUNTS_TOOLTIP_MESSAGES } from '../../../../configs/cloudAccounts';
import { ERRORS as ERRORS_COLORS } from '../../../../configs/v3.colors';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../../../constants/constants';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import { ConnectEmail } from '../../../../pages';
import postWithCloudAuth from '../../../../services/cloudAccounts/postWithCloudAuth';
import { updateCloudAccountsEmailsForRemoval } from '../../../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import { getFetchEmailRecipientsQueryKey } from '../../../../utils/pre-fetchers/prefetchCloudAccounts';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import PlusIcon from '../../../common/icons/plusIcon/PlusIcon';
import TrashIcon from '../../../common/icons/trashIcon/TrashIcon';
import Loader from '../../../common/loader/loader';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import Typography from '../../../common/typography/Typography';

interface IEmailListActions {
  fetchEmailRecipientsLoading: boolean;
  fetchEmailRecipients: () => void;
  emailListCount: number;
}

const EmailListActions = ({ fetchEmailRecipients, emailListCount }: IEmailListActions) => {
  const [openRemoveEmailModal, setOpenRemoveEmailModal] = useState<boolean>(false);
  const [openConfirmRemovalModal, setOpenConfirmRemovalModal] = useState<boolean>(false);
  const [openAddEmailModal, setOpenAddEmailModal] = useState<boolean>(false);

  const {
    accountViewEmailList: { emailsForRemoval }
  } = useAppSelector((store) => store.cloudAccounts) as ICloudAccountsReducerInitialState;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const params = useParams();
  const { loading, startLoading, stopLoading } = useLoading();
  // USER PERMISSIONS START
  const { canUserAddEmailRecipients, canUserRemoveEmailRecipients } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const handleConfirmModalClose = () => {
    fetchEmailRecipients();
    setOpenConfirmRemovalModal(false);
    dispatch(updateCloudAccountsEmailsForRemoval([]));
    queryClient.resetQueries(
      getFetchEmailRecipientsQueryKey({
        limit: ROWS_PER_PAGE_FIRST_ITEM,
        offset: offsetFirst,
        accountId: params.id || ''
      })
    );
  };

  const handleRemoveEmail = async () => {
    try {
      startLoading();
      const response = await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_ACCOUNTS_REMOVE_USER_EMAIL_NOTIFICATION,
        payload: { accountId: params.id, emails: emailsForRemoval }
      });

      if (response) {
        setOpenRemoveEmailModal(false);
        setOpenConfirmRemovalModal(true); //Delete after integration
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'removeEmailsFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  const emailsSelected = emailsForRemoval?.length > 0;
  const emailCount = emailsForRemoval.length;
  const formattedCount = emailCount < 10 ? `0${emailCount}` : emailCount;

  return (
    <div className="email-list-actions">
      <Typography className="email-integration" as="p" size="medium" variant="body-2">
        Email Integration
      </Typography>
      <div className="remove-add-recipient-container">
        {emailsSelected && (
          <TooltipCustom
            text={
              canUserRemoveEmailRecipients
                ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.REMOVE_EMAIL_RECIPIENTS
                : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_EMAIL_REMOVE_ACCESS
            }
          >
            <button
              className="remove apply-loader"
              type="button"
              onClick={() => {
                setOpenRemoveEmailModal(true);
              }}
              disabled={!canUserRemoveEmailRecipients}
            >
              <TrashIcon color={ERRORS_COLORS[700]} />
              <Typography variant="body-3" size="medium" as="span">
                {BUTTON_TEXT.REMOVE}
              </Typography>
            </button>
          </TooltipCustom>
        )}
        <TooltipCustom
          text={
            canUserAddEmailRecipients
              ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.ADD_EMAIL_RECIPIENTS
              : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_EMAIL_ADD_ACCESS
          }
        >
          <button
            type="button"
            onClick={() => setOpenAddEmailModal(true)}
            disabled={emailsSelected || !canUserAddEmailRecipients}
            className={`${emailListCount > 0 ? 'add-recipients' : 'add-recipients-d-none'}`}
          >
            <PlusIcon color="#fff" className="plusicon_inside_btn" />
            <Typography variant="body-3" size="medium" as="span">
              {CLOUD_ACCOUNTS_TEXT.ADD_RECIPIENTS}
            </Typography>
          </button>
        </TooltipCustom>
      </div>
      <RestoreArchiveUserModal
        type={TYPE_ALIGNMENT.LEFT}
        heading={CLOUD_ACCOUNTS_TEXT.CONFIRM_REMOVAL}
        description={CLOUD_ACCOUNTS_TEXT.CONFIRM_REMOVAL_TEXT}
        open={openRemoveEmailModal}
        handleClose={() => setOpenRemoveEmailModal(false)}
        handlerFun={handleRemoveEmail}
        actionButtonText={loading ? <Loader /> : BUTTON_TEXT.YES_REMOVE}
      />
      <ConfirmModal
        title="Removed!"
        description={`Removed ${formattedCount} Email Recipient(s) successfully.`}
        descriptionWidth="88%"
        confirmButtonText={BUTTON_TEXT.OKAY}
        open={openConfirmRemovalModal}
        setOpen={setOpenConfirmRemovalModal}
        image={confirmSuccess}
        handleOkClick={handleConfirmModalClose}
        handleClose={handleConfirmModalClose}
      />
      <Modal open={openAddEmailModal} className="email-list-actions__modal">
        <ConnectEmail
          onCancel={() => setOpenAddEmailModal(false)}
          isModal={true}
          fetchCloudAccountsEmailList={fetchEmailRecipients}
        />
      </Modal>
    </div>
  );
};

export default EmailListActions;
