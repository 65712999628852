import Typography from '../../components/common/typography/Typography';

interface IErrorMessage {
  error: string | string[];
  touched: boolean;
}

const ErrorMessage = ({ error, touched }: IErrorMessage) => {
  return (
    <div
      className={`text-Red text-sm user-onboarding-inner__form__row__field__error-message ${
        error && touched ? 'visible' : 'invisible'
      }`}
    >
      {error && touched ? (
        <Typography variant="body-3" size="regular">
          {error}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
};

export default ErrorMessage;
