import { QueryClient } from 'react-query';

import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../constants/constants';
import { ETeamManagementTabs } from '../../pages/teamManagement/TeamManagement';
import {
  getFetchTeamsQueryKey,
  getFetchUsersQueryKey
} from '../../utils/pre-fetchers/prefetchTeamManagement';
import {
  fetchArchivedUsers,
  fetchTeamPermissions,
  fetchTeams,
  fetchTeamUser,
  fetchTeamUsers,
  fetchUsers
} from '../teamManagement/teamManagement';

export interface IPrefetchTeamUserParams {
  filterUser?: string;
  pageNumber: number;
  rowsPerPage: number;
}

export interface IPrefetchGroupsParams {
  filterGroup?: string;
  pageNumber: number;
  rowsPerPage: number;
}

export interface IPrefetchArchivedUsers {
  pageNumber: number;
  rowsPerPage: number;
  filterUser?: string;
}

export interface IPrefetchTeamWiseUsers {
  groupName: string;
  page: number;
  rows: number;
}

export const prefetchTeamsModuleData = (tab: ETeamManagementTabs, queryClient: QueryClient) => {
  switch (tab) {
    case ETeamManagementTabs.USERS:
      prefetchTeamUsers(queryClient, {
        filterUser: '',
        pageNumber: offsetFirst,
        rowsPerPage: ROWS_PER_PAGE_FIRST_ITEM
      });
      break;
    case ETeamManagementTabs.GROUPS:
      prefetchGroups(queryClient, {
        filterGroup: '',
        pageNumber: offsetFirst,
        rowsPerPage: ROWS_PER_PAGE_FIRST_ITEM
      });
      break;
    case ETeamManagementTabs.DEACTIVATED_USERS:
      prefetchArchivedUsers(queryClient, {
        filterUser: '',
        pageNumber: offsetFirst,
        rowsPerPage: ROWS_PER_PAGE_FIRST_ITEM
      });
      break;
  }
};

// TODO : create generic function to genrate keys for prefetch query
export const prefetchTeamUsers = (queryClient: QueryClient, params: IPrefetchTeamUserParams) => {
  queryClient.prefetchQuery(
    getFetchUsersQueryKey({
      limit: params.rowsPerPage,
      offset: params.pageNumber,
      filterUser: params.filterUser || ''
    }),
    () => fetchUsers(params.rowsPerPage, params.pageNumber, params.filterUser)
  );
};

export const prefetchGroups = (queryClient: QueryClient, params: IPrefetchGroupsParams) => {
  queryClient.prefetchQuery(
    getFetchTeamsQueryKey({
      limit: params.rowsPerPage,
      offset: params.pageNumber,
      filterGroup: params.filterGroup || ''
    }),
    () => fetchTeams(params.rowsPerPage, params.pageNumber, params.filterGroup)
  );
};

export const prefetchArchivedUsers = (queryClient: QueryClient, params: IPrefetchArchivedUsers) => {
  queryClient.prefetchQuery(
    [
      'archivedUsers',
      {
        filterUser: params.filterUser,
        pageNumber: params.pageNumber,
        rowsPerPage: params.rowsPerPage
      }
    ],
    () => fetchArchivedUsers(params.rowsPerPage, params.pageNumber, params.filterUser)
  );
};

export const prefetchTeamwiseUsers = (queryClient: QueryClient, params: IPrefetchTeamWiseUsers) => {
  queryClient.prefetchQuery(
    ['viewTeamUsers', { groupname: params.groupName, page: params.page, rows: params.rows }],
    () => fetchTeamUsers(params.groupName, params.rows, params.page)
  );
};

export const prefetchTeamUser = (queryClient: QueryClient, email: string) => {
  queryClient.prefetchQuery(['team-user-profile', { email }], () => fetchTeamUser(email));
};

export const prefetchTeamPemissions = (queryClient: QueryClient, groupName: string) => {
  queryClient.prefetchQuery(['viewTeamPermissions', { groupname: groupName }], () =>
    fetchTeamPermissions(groupName)
  );
};
