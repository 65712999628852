export const BLACK = {
  100: '#f2f2f2',
  200: '#dadada',
  300: '#bfbfbf',
  400: '#a4a4a4',
  500: '#898989',
  600: '#6e6e6e',
  700: '#535353',
  800: '#383838',
  900: '#1d1d1d',
  1000: '#121212'
};

export const PRIMARY = {
  100: '#f2f7fd',
  200: '#e3ecfb',
  300: '#c1daf6',
  400: '#8bbbee',
  500: '#4c98e4',
  600: '#257ad2',
  700: '#165baa',
  800: '#144c90',
  900: '#144278',
  1000: '#163864',
  1100: '#0f2442',
  1200: '#E5E5EF',
  1300: '#4A3AFF'
};

export const ERRORS = {
  100: '#fef2f2',
  200: '#fee2e2',
  300: '#fecaca',
  400: '#fca5a5',
  500: '#f87171',
  600: '#ef4444',
  700: '#dc2626',
  800: '#b91c1c',
  900: '#991b1b',
  1000: '#7f1d1d'
};

export const SUCCESS = {
  100: '#ecfdf5',
  200: '#d1fae5',
  300: '#a7f3d0',
  400: '#6ee7b7',
  500: '#34d399',
  600: '#10b981',
  700: '#059669',
  800: '#047857',
  900: '#065f46',
  1000: '#064e3b'
};

export const WARNING = {
  100: '#fef4e8',
  200: '#fee2c3',
  300: '#fec78a',
  400: '#fda747',
  500: '#fa8e15',
  600: '#ea8008',
  700: '#ca6d04',
  800: '#a15907',
  900: '#854d0e',
  1000: '#714412'
};

export const WHITE = {
  white: '#ffffff'
};
