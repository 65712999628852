import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';

import './accountDeleteModal.scss';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import { getOrgData } from '../../../helpers/getOrgData';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import Typography from '../../common/typography/Typography';
import Loader from '../../common/loader/loader';

export interface IAccountDeleteModalProps {
  handleClose: () => void;
  handleDelete: () => void;
  isLoading: boolean;
}

const AccountDeleteModal = ({ handleClose, handleDelete, isLoading }: IAccountDeleteModalProps) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const { orgID } = getOrgData();
  const handleCancel = () => {
    setInput('');
    handleClose();
  };

  const handleFunc = () => {
    handleDelete();
  };

  const handlePaste = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (input === orgID) {
      setIsVerified(true);
      return;
    }
    setIsVerified(false);
  }, [input]);

  return (
    <Modal open={true} data-testid="commonConfirmModal" id="common-confirm-modal">
      <div className="account_delete_modal">
        <div className="account_delete_modal__red">
          <Typography as="h2" variant="body-2" size="medium">
            Are you sure you want to delete your <CloudInSpotName /> Account ?
          </Typography>
        </div>
        <div className="account_delete_modal__white">
          <p>
            Deleting your <CloudInSpotName /> account will also delete all the data in your account.
            This process can’t be undone. After deleting the account make sure to delete the stack
            created in your AWS accounts.
          </p>

          <h3>
            Please type your Organization ID <span className="org-id">{orgID}</span> below to delete
            your <CloudInSpotName className="!font-semibold" />
            &nbsp; Account
          </h3>

          <input
            placeholder={orgID}
            value={input}
            onChange={(e) => setInput(e.target.value.trim())}
            onPaste={handlePaste}
          />

          <div className="account_delete_modal__white__buttons">
            <CustomButton variant={ButtonVariants.SECONDARY} text="Cancel" onClick={handleCancel} />
            <CustomButton
              text={isLoading ? <Loader /> : 'Yes, Delete'}
              variant={ButtonVariants.DELETE}
              onClick={handleFunc}
              disabled={!isVerified}
              style={{
                opacity: !isVerified ? '50%' : '100%',
                cursor: !isVerified ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AccountDeleteModal;
