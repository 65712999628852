import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  subject: Yup.string()
    .trim()
    .required('Enter Subject')
    .min(1, 'Subject must be at least 1 character')
    .max(50, 'Subject must be 50 characters or less'),
  issueDescription: Yup.string()
    .trim()
    .required('Enter Description')
    .min(2, 'Description must be at least 2 characters')
    .max(500, 'Description must be 500 characters or less')
});

export default validationSchema;
