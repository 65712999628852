import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';
// Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let messaging: any;

isSupported().then((supported) => {
  if (supported) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Messaging service
    messaging = getMessaging(app);
  }
});

export { messaging };
