import React from 'react';

import { ITableColumnStatus } from './tableColumnStatus.interface';
import './tableColumnStatus.scss';

import IMAGES from '../../../assets';
import SimpleTooltip from '../simpleTooltip/SimpleTooltip';
import SubMenuModal from '../subMenuModal/SubMenuModal';
import Typography from '../typography/Typography';

// const icons = {
//   active: IMAGES.okIcon,
//   inactive: IMAGES.closeIcon,
//   failed: IMAGES.crossRedIcon,
//   unreachable: IMAGES.alertRedIcon,
//   inprogress: IMAGES.warningIcon,
//   connected: IMAGES.okIcon,
//   paused: IMAGES.closeIcon
// } as const;

const TableColumnStatus = ({
  type,
  text,
  tooltipText,
  showTooltip,
  closeTooltip,
  toggleTooltip
}: ITableColumnStatus) => {
  return (
    <div className="table-column-statuses">
      <SubMenuModal setOpenSubMenuModal={closeTooltip}>
        <SimpleTooltip text={tooltipText} showTooltip={showTooltip} setShowTooltip={toggleTooltip}>
          <div className={`table-column-statuses__status table-column-statuses__status--${type}`}>
            {/* <img src={icons[type]} /> */}
            <Typography variant="buttonText" size="medium" as="span" className="!text-[14px]">
              {text}
            </Typography>
          </div>
        </SimpleTooltip>
      </SubMenuModal>
      <img
        className="table-column-statuses__tooltip-icon"
        src={IMAGES.tooltipIcon}
        alt="click to open tooltip"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          toggleTooltip();
        }}
      />
    </div>
  );
};

export default TableColumnStatus;
