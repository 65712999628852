import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import { TUserSummary } from '../../../../store/teamManagement/teamManagement.interface';
import MinusIcon from '../../../common/icons/minusIcon/MinusIcon';
import PlusIcon from '../../../common/icons/plusIcon/PlusIcon';
import UserIcon from '../../../common/icons/userIcon/UserIcon';
import SummaryStats, {
  TSummaryStatItem
} from '../../../common/molecules/summaryStats/SummaryStats';
import Typography from '../../../common/typography/Typography';

const UserSummaryStats = ({ userSummary }: { userSummary: TUserSummary }) => {
  const data: TSummaryStatItem[] = [
    {
      label: 'Active Users',
      icon: <UserIcon color={PRIMARY_COLORS[600]} />,
      value: userSummary.totalActiveUsers
    },
    {
      label: 'Deactivated Users',
      icon: <UserIcon color={PRIMARY_COLORS[600]} />,
      value: userSummary.totalDeactivatedUsers
    },
    {
      label: (
        <div className="summary_card_container">
          Users Added{' '}
          <Typography variant="caption" size="regular" as="span">
            (This Month)
          </Typography>
        </div>
      ),
      icon: <PlusIcon color={PRIMARY_COLORS[600]} />,
      value: userSummary.usersAddedThisMonth
    },
    {
      label: (
        <div className="summary_card_container">
          Users Deactivated{' '}
          <Typography variant="caption" size="regular" as="span">
            (This Month)
          </Typography>
        </div>
      ),
      icon: <MinusIcon color={PRIMARY_COLORS[600]} />,
      value: userSummary.totalDeactivatedUsers
    }
  ];

  return <SummaryStats data={data} className="tmg-user-summary-stats" />;
};

export default UserSummaryStats;
