import { Modal } from '@mui/material';
import { FormEvent, useState } from 'react';

import IMAGES from '../../../assets';
import './deleteModal.scss';
import Typography from '../typography/Typography';
import CustomButton, { ButtonVariants } from '../buttons/CustomButton';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import InputWithBoldPlaceholder from '../InputWithBoldPlaceholder/InputWithBoldPlaceholder';
import { useAppSelector } from '../../../hooks/redux/useRedux';
import { ICommonReducerInitialState } from '../../../store/common/common.interface';
import Loader from '../loader/loader';

export interface IDeleteModalProps {
  heading?: string;
  description?: string | React.ReactNode;
  placeholder?: string;
  actionButtonText?: string;
  open: boolean;
  handleDelete: () => void;
  handleClose: () => void;
  loading?: boolean;
  inputRequiredToDelete?: string;
  child?: React.ReactNode | JSX.Element;
  actionButtonActiveColor?: string;
  actionButtonActiveTextColor?: string;
}

const DeleteModal = ({
  heading,
  description,
  placeholder,
  open,
  handleDelete,
  handleClose,
  inputRequiredToDelete = TEAM_MANAGEMENT_TEXT.DELETE_UPPER,
  actionButtonText,
  child,
  loading
}: IDeleteModalProps) => {
  const closeModal = () => {
    setInput(''); // Clear user text upon closing of delete modal.
    handleClose();
  };

  const [input, setInput] = useState<string>('');

  const { loaderId } = useAppSelector((store) => store.common) as ICommonReducerInitialState;

  const handleDeleteClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setInput('');
    handleDelete();
  };

  return (
    <Modal
      open={open}
      data-testid="commonConfirmModal"
      id="common-delete-modal"
      className="delete_modal"
    >
      <PaperCustom variant="medium" className="common-delete-modal deleteModal">
        <form onSubmit={(e) => handleDeleteClick(e)}>
          <div className="deleteModal__close">
            <img
              src={IMAGES.crossIcon}
              alt="Close icon"
              data-testid="confirmModalCloseIcon"
              onClick={closeModal}
            />
          </div>
          <div className="deleteModal__content">
            <Typography
              className="deleteModal__content__heading"
              variant="body-1"
              size="semiBold"
              as="h2"
            >
              {heading}
            </Typography>
            {description ? (
              <Typography
                variant="body-3"
                size="regular"
                as="h4"
                className="deleteModal__content__description"
              >
                {description}
              </Typography>
            ) : (
              <></>
            )}
            {child && (
              <Typography
                variant="body-2"
                size="regular"
                as="h4"
                className="deleteModal__content__child"
              >
                {child}
              </Typography>
            )}
            <InputWithBoldPlaceholder
              placeholder={placeholder || ''}
              boldText={inputRequiredToDelete}
              value={input}
              onChange={setInput}
              className="deleteModal__content__textInputField"
            />
            <div className="deleteModal__content__buttonsWrapper">
              <CustomButton
                type="reset"
                onClick={closeModal}
                text="Cancel"
                variant={ButtonVariants.SECONDARY}
              />
              <CustomButton
                type="submit"
                onClick={(e) => handleDeleteClick(e)}
                text={
                  loading || loaderId === 'deletedAccountLoader' ? (
                    <Loader />
                  ) : (
                    actionButtonText || 'Yes, Delete'
                  )
                }
                isLoading={loaderId === 'deletedAccountLoader'}
                className="deleteModal__content__buttonsWrapper__delete"
                disabled={input != inputRequiredToDelete}
              />
            </div>
          </div>
        </form>
      </PaperCustom>
    </Modal>
  );
};

export default DeleteModal;
