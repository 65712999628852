import { create } from 'zustand';

interface IForgotPasswordFormValues {
  orgId: string;
  forgotEmail: string;
}
interface ForgotPasswordState {
  forgotPasswordFormValues: IForgotPasswordFormValues;
  setForgotPasswordFormValues: (fieldName: string, value: string) => void;
  resetForgotPasswordFormValues: () => void;
}

const useForgotPasswordStore = create<ForgotPasswordState>()((set) => ({
  forgotPasswordFormValues: {
    orgId: '',
    forgotEmail: ''
  },
  setForgotPasswordFormValues: (fieldName: string, value: string) =>
    set((state) => ({
      forgotPasswordFormValues: { ...state.forgotPasswordFormValues, [fieldName]: value }
    })),
  resetForgotPasswordFormValues: () =>
    set(() => ({ forgotPasswordFormValues: { orgId: '', forgotEmail: '' } }))
}));

export default useForgotPasswordStore;
