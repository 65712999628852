import { useEffect, useState } from 'react';

import allAccessApiEndpointMapping from './allAccessApiEndpointMapping';
import permissionsState from './permissionsState';

import ENUMS, { PermissionModuleApiKeyEnums } from '../../../enums';
import { PermissionVariablesEnums } from '../../../enums/common';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import { IUserPermissionsList } from '../../../store/teamManagement/teamManagement.interface';
import { useAppSelector } from '../../redux/useRedux';

const { userGroups } = ENUMS.teamManagement;

interface ICheckPermissions {
  isOwner: boolean;
  groups: string[];
  permissions: IUserPermissionsList[];
  apiKey: PermissionModuleApiKeyEnums;
  permissionName: string;
  apiEndpoint?: string;
}

/**
 * This function returns all the api endpoints available in the permission array.
 * @param permissions
 * Permissions array
 * @returns an array of strings, each string represent an api endpoint.
 */
const getUserPermissionsEndpoints = ({
  permissions = []
}: {
  permissions: IUserPermissionsList[];
}): string[] => {
  const apisWithPermission: string[] = [];

  permissions.forEach((module) => {
    const modulePermissions = module.permissions;
    const modulePermissionsNames = Object.keys(modulePermissions);

    if (modulePermissionsNames?.length > 0) {
      modulePermissionsNames.forEach((key) => {
        apisWithPermission.push(...modulePermissions[key].apiEndpoints);
      });
    }
  });

  return apisWithPermission;
};

export const checkApiEndpointAccess = ({
  isOwner,
  isUserAdmin,
  availablePermissionsToUser,
  apiEndpoint
}: {
  isOwner: boolean;
  isUserAdmin: boolean;
  availablePermissionsToUser: string[];
  apiEndpoint: string;
}) => {
  if (isOwner) {
    // Owner has all the permissions
    return true;
  }

  if (isUserAdmin) {
    // Admin has all the permissions
    return true;
  }

  return availablePermissionsToUser.indexOf(apiEndpoint) > -1;
};

export const checkUserPermissions = ({
  isOwner,
  groups,
  permissions,
  apiKey,
  permissionName,
  apiEndpoint = ''
}: ICheckPermissions): boolean => {
  // Seperating the concerns intentionally using if block for ease of implementing business logic.

  if (isOwner) {
    // Owner has all the permissions
    return true;
  }

  if (groups.indexOf(userGroups.Admin) > -1) {
    // Admin has all the permissions
    return true;
  }

  const availableModulePermissions =
    permissions.find((permission) => permission.apiKey === apiKey)?.permissions || {};
  const permission = availableModulePermissions[permissionName];

  // Check endpoints access START

  const apisWithPermission: string[] = [];

  permissions.forEach((module) => {
    const modulePermissions = module.permissions;
    const modulePermissionsNames = Object.keys(modulePermissions);

    if (modulePermissionsNames?.length > 0) {
      modulePermissionsNames.forEach((key) => {
        apisWithPermission.push(...modulePermissions[key].apiEndpoints);
      });
    }
  });

  // Check endpoints access END

  if (apiEndpoint) {
    return Boolean(apisWithPermission.indexOf(apiEndpoint) > -1);
  }

  return Boolean(permission);
};

/**
 * This hook checks whether user has access at module and api endpoint level
 * @param apiKey
 * Unique key that represents a permission module
 * @param permissionName
 * Name of the permission module
 * @param apiEndpoint
 * Unique end point that represents a permission at api level,
 * existence of this key implies that user that access to that api
 * @returns a boolean, whether the user has access to api or not.
 */
const useCheckUserPermissions = (): Record<PermissionVariablesEnums, boolean> => {
  const [userPermissionsStatus, setUserPermissionsStatus] =
    useState<Record<PermissionVariablesEnums, boolean>>(permissionsState);

  const { isOwner, permissions, groups } = useAppSelector<IMyProfileState>(
    (store) => store.MyProfile
  );

  const isUserAdmin: boolean = groups.indexOf(userGroups.Admin) > -1;

  const updatePermissionKeys = () => {
    const availablePermissionsToUser = getUserPermissionsEndpoints({ permissions });

    allAccessApiEndpointMapping.forEach((item) => {
      const access = checkApiEndpointAccess({
        isOwner,
        isUserAdmin,
        availablePermissionsToUser,
        apiEndpoint: item.apiEndpoint
      });
      setUserPermissionsStatus((prev) => ({
        ...prev,
        [item.key as keyof typeof permissionsState]: access
      }));
    });
  };

  useEffect(() => {
    // updatePermission();
    updatePermissionKeys();
  }, []);

  return userPermissionsStatus;
};

export default useCheckUserPermissions;
