import { IButtons } from './buttons.interface';

import { useAppDispatch } from '../../../hooks/redux/useRedux';
import { setHeading } from '../../../store/actions';
import { BUTTON_TEXT } from '../../../configs';
import CustomButton from '../../common/buttons/CustomButton';
import Loader from '../../common/loader/loader';

const Buttons = ({ classNames, resetForm, setIsEdit, isSubmitting }: IButtons) => {
  const dispatch = useAppDispatch();

  return (
    <div className={classNames}>
      <button
        className="cancel"
        data-testid="cancelEdit"
        onClick={() => {
          resetForm();
          setIsEdit(false);
          dispatch(setHeading('My Profile'));
        }}
        type="button"
      >
        {BUTTON_TEXT.CANCEL}
      </button>
      <CustomButton
        className="save"
        type={isSubmitting ? 'button' : 'submit'}
        text={isSubmitting ? <Loader /> : BUTTON_TEXT.SAVE}
      />
    </div>
  );
};

export default Buttons;
