import {
  UPDATE_BREADCRUMBS,
  UPDATE_LOADERID,
  UPDATE_LOADING_STATUS,
  UPDATE_SKELETON_LOADING_STATUS
} from './common.actionTypes';
import { IBreadCrumb } from './common.interface';

export const updateLoadingStatusAction = (state: { isLoading: boolean }) => ({
  type: UPDATE_LOADING_STATUS,
  payload: state
});

export const updateSkeletonLoader = (state: { isLoading: boolean }) => ({
  type: UPDATE_SKELETON_LOADING_STATUS,
  payload: state
});

export const updateBreadcrumbs = (state: IBreadCrumb[]) => ({
  type: UPDATE_BREADCRUMBS,
  payload: state
});
export const updateLoaderId = (state: string) => ({
  type: UPDATE_LOADERID,
  payload: state
});
