import { lazy, Suspense } from 'react';
import { useQueryClient } from 'react-query';
//external imports
// eslint-disable-next-line
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Lazy loading the pages
const AccessDenied = lazy(() => import('./pages/accessDenied/AccessDenied'));
const AddUser = lazy(() => import('./pages/addUser/AddUser'));
const TeamManagementEditUserProfile = lazy(() => import('./pages/editUserProfile/EditUserProfile'));
const TeamManagementEditUserAccessControl = lazy(
  () => import('./pages/editUserAccessControl/EditUserAccessControl')
);
const TeamManagementViewGroup = lazy(() => import('./pages/viewGroup/ViewGroup'));
const TeamManagementEditGroup = lazy(() => import('./pages/editGroup/EditGroup'));
const AddGroup = lazy(() => import('./pages/addGroup/AddGroup'));
const ConfirmForgotPassword = lazy(
  () => import('./pages/confirmForgotPassword/ConfirmForgotPassword')
);
const FAQ = lazy(() => import('./pages/faq/FAQ'));
const ForceChangePassword = lazy(() => import('./pages/forceChangePassword/ForceChangePassword'));
const ForgotPassword = lazy(() => import('./pages/forgot-password/ForgotPassword'));
const Insights = lazy(() => import('./pages/insights/Insights'));
const MyProfile = lazy(() => import('./pages/myProfile/MyProfile'));
const NotFound = lazy(() => import('./pages/not-found/NotFound'));
const OTPVerification = lazy(() => import('./pages/otp-verification/OTPVerification'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const Settings = lazy(() => import('./pages/settings/Settings'));
const SignIn = lazy(() => import('./pages/sign-in/SignIn'));
// const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
const SomethingWentWrong = lazy(() => import('./pages/somethingWentWrong/SomethingWentWrong'));
const TeamManagement = lazy(() => import('./pages/teamManagement/TeamManagement'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse/TermsOfUse'));
const AccountView = lazy(() => import('./pages/accountView/AccountView'));
const CloudAccountsView = lazy(() => import('./pages/cloudAccountView/CloudAccountsView'));
const ConnectAccount = lazy(() => import('./pages/connectAccount/ConnectAccount'));
const ConnectSlack = lazy(() => import('./pages/connectSlack/ConnectSlack'));
const ConnectMsTeam = lazy(() => import('./pages/connectMsTeam/ConnectMsTeam'));
const ConnectEmail = lazy(() => import('./pages/connectEmail/ConnectEmail'));
const DisconnectAccount = lazy(() => import('./pages/disconnectAccount/DisconnectAccount'));
// const ConnectAzure = lazy(() => import('./pages/connectAzure/connectAzure'));
// const ConnectAzureSteps = lazy(() => import('./pages/connectAzureSteps/connectAzureSteps'));
const Feedback = lazy(() => import('./pages/feedback/Feedback'));
const Notification = lazy(() => import('./pages/notifications/Notification'));

// eslint-disable-next-line
import { AuthRoutes, ProtectedRoute, SignInSignUpRoute } from './routes';

// Hooks and other components (no need to lazy-load)
import { EActiveTabInsideAccountView } from './components/cloudAccounts/constants';
import LoadingScreen from './components/common/loadingScreen/LoadingScreen';
import PermissionUpdateModal from './components/permissionUpdateModal/PermissionUpdateModal';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from './constants/constants';
import { routes } from './constants/routes';
import useIsAuthenticated from './hooks/is-authenticated/useIsAuthenticated';
import useFetchMyProfile from './hooks/myProfile/fetchMyProfile/useFetchMyProfile';
import { useGetNotifications, useRegisterFirebaseServiceWorker } from './hooks/notification';
import useGetNotificationsOnBackground from './hooks/notification/useGetNotifications/useGetNotificationsOnBackground';
import useFetchOrganizationDetails from './hooks/orgDetails/useFetchOrganizationDetails';
import useGetUnauthorizedRoutes from './hooks/userAuthentication/getUnauthorizedRoutes/useGetUnauthorizedRoutes';
import ConnectSlackAdditionalSteps from './pages/connectSlack/ConnectSlackAdditionalSteps';
import {
  prefetchArchivedUsers,
  prefetchGroups,
  prefetchTeamUsers,
  prefetchTeamwiseUsers
} from './services/prefechService/prefetchTeamsService';

const App = () => {
  const unauthorizedRoutes = useGetUnauthorizedRoutes();

  // Register firebase service worker.
  useRegisterFirebaseServiceWorker();
  useFetchMyProfile();
  // Notifications
  useGetNotifications();
  useGetNotificationsOnBackground();
  useFetchOrganizationDetails();

  const queryClient = useQueryClient();

  const { isAuthenticated } = useIsAuthenticated();

  if (isAuthenticated) {
    prefetchGroups(queryClient, {
      pageNumber: offsetFirst,
      rowsPerPage: ROWS_PER_PAGE_FIRST_ITEM,
      filterGroup: ''
    });

    prefetchTeamUsers(queryClient, {
      pageNumber: offsetFirst,
      rowsPerPage: ROWS_PER_PAGE_FIRST_ITEM,
      filterUser: ''
    });

    prefetchArchivedUsers(queryClient, {
      pageNumber: offsetFirst,
      rowsPerPage: ROWS_PER_PAGE_FIRST_ITEM,
      filterUser: ''
    });

    prefetchTeamwiseUsers(queryClient, {
      groupName: '',
      page: offsetFirst,
      rows: ROWS_PER_PAGE_FIRST_ITEM
    });
  }

  return (
    <div>
      <BrowserRouter>
        <PermissionUpdateModal />
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route element={<SignInSignUpRoute />}>
              <Route path={routes.SIGN_IN} element={<SignIn />} />
              {/* <Route path={routes.SIGN_UP} element={<SignUp />} /> */}
            </Route>
            <Route element={<AuthRoutes />}>
              <Route path={routes.OTP} element={<OTPVerification />} />
              <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
              <Route path={routes.SET_PASSWORD} element={<ConfirmForgotPassword />} />
              <Route path={routes.FORCE_CHANGE_PASSWORD} element={<ForceChangePassword />} />
            </Route>
            <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={routes.TERMS_OF_USE} element={<TermsOfUse />} />

            <Route element={<ProtectedRoute />}>
              {unauthorizedRoutes.map((route) => (
                <Route
                  path={route.route}
                  element={<Navigate to={routes.ACCESS_DENIED} />}
                  key={route.permissionName + route.apiEndpoint}
                />
              ))}
              {/* will uncomment this once we start working on dashboard */}
              {/* <Route path={routes.DASHBOARD} element={<Dashboard />} /> */}
              <Route path={routes.MY_PROFILE} element={<MyProfile />} />
              {/* Cloud account */}
              <Route path={routes.CLOUD_ACCOUNTS} element={<CloudAccountsView />} />
              <Route path={routes.CONNECT_ACCOUNT} element={<ConnectAccount />} />
              <Route path={routes.RE_CONNECT_ACCOUNT} element={<ConnectAccount />} />
              {/* <Route path={routes.RE_CONNECT_TO_AZURE} element={<ConnectAzure />} /> */}
              {/* <Route path={routes.CONNECT_AZURE} element={<ConnectAzure />} /> */}
              {/* <Route path={routes.CONNECT_AZURE_CONFIRM} element={<ConnectAzureSteps />} /> */}
              <Route path={routes.DISCONNECT_ACCOUNT} element={<DisconnectAccount />} />
              <Route
                path={routes.CLOUD_ACCOUNTS_VIEW}
                element={
                  <AccountView activeTabAccountView={EActiveTabInsideAccountView.ACCOUNT_INFO} />
                }
              />
              <Route
                path={routes.CLOUD_ACCOUNTS_EDIT}
                element={
                  <AccountView activeTabAccountView={EActiveTabInsideAccountView.ACCOUNT_INFO} />
                }
              />
              <Route
                path={routes.CLOUD_ACCOUNTS_VIEW_MSTEAMS}
                element={<AccountView activeTabAccountView={EActiveTabInsideAccountView.MSTEAMS} />}
              />
              <Route
                path={routes.CLOUD_ACCOUNTS_VIEW_SLACK}
                element={<AccountView activeTabAccountView={EActiveTabInsideAccountView.SLACK} />}
              />
              <Route
                path={routes.CLOUD_ACCOUNTS_VIEW_EMAIL}
                element={<AccountView activeTabAccountView={EActiveTabInsideAccountView.EMAIL} />}
              />
              <Route path={routes.CONNECT_SLACK_ACCOUNT} element={<ConnectSlack />} />
              <Route
                path={routes.CONNECT_SLACK_ADDITIONAL_STEPS}
                element={<ConnectSlackAdditionalSteps />}
              />
              <Route path={routes.CONNECT_MSTEAM_ACCOUNT} element={<ConnectMsTeam />} />
              <Route path={routes.CONNECT_EMAIL_ACCOUNT} element={<ConnectEmail />} />
              <Route path={routes.NOTIFICATIONS} element={<Notification />} />
              {/* Team Management */}
              <Route path={routes.TEAM_MANAGEMENT_TABLE_TYPE} element={<TeamManagement />} />
              <Route path={routes.ADD_USER} element={<AddUser />} />
              <Route path={routes.ADD_GROUP} element={<AddGroup />} />
              <Route path={routes.FAQ} element={<FAQ />} />
              <Route path={routes.FEEDBACK} element={<Feedback />} />
              <Route path={routes.INSIGHTS} element={<Insights />} />
              <Route
                path={routes.TEAM_MANAGEMENT_USER}
                element={<TeamManagementEditUserProfile />}
              />
              <Route
                path={routes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_PARAMETRIC}
                element={<TeamManagementEditUserAccessControl />}
              />
              <Route
                path={routes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL}
                element={<TeamManagementEditUserAccessControl />}
              />
              <Route
                path={routes.TEAM_MANAGEMENT_VIEW_GROUP_BY_NAME}
                element={<TeamManagementViewGroup />}
              />
              <Route
                path={routes.TEAM_MANAGEMENT_EDIT_GROUP}
                element={<TeamManagementEditGroup />}
              />
              {/* Settings */}
              <Route path={routes.SETTINGS} element={<Settings />} />
              <Route path={routes.ACCESS_DENIED} element={<AccessDenied />} />
              {unauthorizedRoutes.map((route) => (
                <Route
                  path={route.route}
                  element={<Navigate to={routes.ACCESS_DENIED} />}
                  key={route.permissionName + route.apiEndpoint}
                />
              ))}
            </Route>
            {/* Not Found */}
            <Route path="/oops" element={<SomethingWentWrong />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
