import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#374151',
          color: '#ffffff'
        },
        arrow: {
          color: '#374151'
        }
      }
    }
  }
});

export default theme;
