import React from 'react';

import { Text, TextType } from '../../utils/Text';

const TermsOfUse = () => {
  return (
    <React.Fragment>
      <div className="grid place-content-center">
        <Text type={TextType.H1} className="text-center my-12">
          Terms of Use
        </Text>
        <Text type={TextType.H3_01} className="px-4 sm:px-40 space-y-6">
          <div className="space-y-3" data-testid="welcome">
            <p>
              Welcome to “Cloudinspot” a proprietary software developed, owned and operated by
              Scalex Technology Solutions LLP (“Scalex”, “we,” or “us” or “our”). Please read these
              Terms of Use (the “Terms”) carefully before using this Cloudinspot or accessing any
              information, content or services through our website (located at
              https://cloudinspot.com). These Terms create a binding legal agreement between Scalex
              and all visitors, users, and others who access this Website (“User(s)”, “you”,
              “your”).
            </p>
            <p>
              These Terms are intended to provide and govern the terms and conditions for your use
              of the software offered through this Website/App (referred as the “Services”).
            </p>
            <p>
              BY ACCEPTING THESE TERMS, BY CLICKING ‘I AGREE’ (OR SIMILLAR BUTTON) OR BY ACCESSING
              OR USING THIS WEBSITE/APP, YOU REPRESENT AND WARRANT THAT YOU HAVE READ, UNDERSTOOD,
              AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST NOT
              ACCEPT THESE TERMS OR USE OR ACCESS ALL OR ANY PORTION OF THE WEBSITE/APP.
            </p>
            <p>
              You may not access this Website/App, if you are Scalex’s competitor, except with a
              prior written consent of Scalex. In addition, you may not access this Website/App for
              purposes of monitoring its availability, performance or functionality, or for any
              other benchmarking or competitive purposes.
            </p>
            <p>
              We reserve the right to amend these Terms at any time with no notice to its Users.
              Your use of the Website/App following any such amendments will represent your
              agreement to be bound by these Terms as amended. We therefore recommend that each time
              you access or use the Website/App, you should read these Terms.
            </p>
          </div>

          <div className="space-y-3" data-testid="eligibility">
            <p className="flex">
              <b>ELIGIBILITY:</b>
              Intended for Users 18 and older
            </p>
            <p>
              This Website/App is intended for the use of Users who are of 18 years of age or older.
              This Website/App is not directed for use by any User under the age of 18. Users under
              the age of 18 should get the assistance of a parent or guardian to use this
              Website/App. The Services are not available to any Users previously removed from the
              Service by Us. If you are accessing an Account to use the Services on behalf of a
              User, then you represent and warrant that you:(i) are an authorized representative of
              such User(ii) agree to be bound by these Terms on behalf of such User. We shall not be
              under any obligation to verify your authority or eligibility. If you do not qualify to
              be an eligible User, you should not use these Services.
            </p>
          </div>
          <div className="space-y-3" data-testid="use-of-information">
            <b>Use of Information</b>
            <p>
              Provide and Improve CloudInSpot We use the information collected to provide you with
              access to CloudInSpot and its features, personalize your experience, and improve the
              functionality and performance of CloudInSpot. Communication We may use your personal
              information to communicate with you, respond to your inquiries, and provide customer
              support related to CloudInSpot. Analytics and Research We may aggregate and anonymize
              the information collected to analyze trends, monitor usage patterns, and conduct
              research to improve our services and develop new features. Legal Compliance We may use
              or disclose your information if required by law, court order, or government
              regulation, or if we believe that such action is necessary to protect our rights,
              property, or safety, or that of others.
            </p>
          </div>
          <div className="space-y-3" data-testid="registration-of-user-account">
            <b>REGISTRATION OF USER ACCOUNT</b>
            <p>
              As a condition for using Services, User must complete the registration on the ‘Sign
              Up’ page and create an account (“Account”) by selecting a password and entering
              his/her email address on the Website/App. The User agrees to provide Scalex with
              accurate, complete, and updated registration information and authorize the use and
              disclosure of your information to us in order to allow us to provide the Services and
              as otherwise disclosed in our Privacy Policy . Failure to do so shall constitute a
              breach of these Terms, which may result in immediate termination of the User&apos;s
              Account. Users may register on the Website through their Accounts with Third Party
              Platforms (defined below) for availing the Services. As part of the Services, you
              authorize us to import your login-in credentials, details and personal information
              dispersed over Third Party Platforms. &quot;Third Party Platforms&quot; would mean
              including but not limited to social networking platforms, such as Facebook, LinkedIn,
              Twitter, Google and other similar platforms.
            </p>
            <p>
              The User shall be solely responsible for maintaining the confidentiality of the
              Account and for all other activities that occur under his/her Account and you must
              keep your Account and password secure. Users are prohibited from transferring their
              Account to any third party. We encourage you to use “strong” passwords (passwords that
              use a combination of upper- and lower-case letters, numbers and symbols) with your
              Account. You must
            </p>
            <p>
              (i) notify Scalex immediately of any breach of security or unauthorized use of your
              Account
            </p>
            <p>
              (ii) report to Scalex immediately and use reasonable efforts to stop any unauthorized
              use of the Services that is known or suspected by the Users, and
            </p>
            <p>
              (iii) not provide false identity information to gain access to or use the Services.
              You shall be solely responsible for Your acts and omissions. Scalex shall not be
              liable for any losses caused by any unauthorized use of your Account, loss of data or
              functionality by the User. We also, at our sole discretion, reserve the right to
              refuse or cancel registration of any Account which we deem inappropriate.
            </p>
            <p>
              The Account gives access to the Users to the Services and functionalities that we may
              establish and maintain from time to time in our sole discretion.
            </p>
          </div>
          <div className="space-y-3" data-testid="use-of-service">
            <b>USE OF SERVICE</b>
            <p>
              Subject to these Terms, you are hereby granted a fixed-term, non-exclusive, limited,
              non-transferable, revocable, non-sub licensable, license to use and access the
              Services in India for your personal, non-commercial use only and as permitted by the
              features of the Services in accordance with these Terms. Scalex grants you this
              license as set forth in these Terms, provided that:
            </p>
            <p>
              (i) you will not copy, distribute, or disclose any part of the Services in any medium
            </p>
            <p>
              (ii) you will not alter or modify any part of the Services other than as may be
              reasonably necessary to use the Services for its intended purpose
            </p>
            <p>
              (iii) you agree not to sell, license, rent reproduce, transmit, publicly display,
              publicly perform, publish, adapt, reverse engineer, edit or create derivative works
              from any Services
            </p>
            <p>(iv) use the Services to create, promote or market any competing software</p>
            <p>
              (v) interfere with or disrupt the integrity and performance of the Website/App and
            </p>
            <p>
              (vi) you will otherwise comply with these Terms. Nothing herein contained shall be
              construed as granted to the User any intellectual property right, which includes
              copyrights, regarding the Website and/or Services except as expressly provided for
              hereunder. Scalex reserves all rights not expressly granted herein. Use of the Website
              for any purpose not expressly permitted by these Terms is strictly prohibited.
            </p>
          </div>
          <div className="space-y-3" data-testid="user-content">
            <b>USER CONTENT</b>
            <p>
              You understand and acknowledge that your use of the Services is solely based upon the
              User content, including but not limited to, all data and materials provided by you to
              the software for use in connection with the Services, including, without limitation,
              organizational details, personal data (such as name, email address, birth date,
              gender, city, country, password ), health information, sleeping patterns, stress
              levels, anger levels, behavioural patterns, anxiety levels, present mood and personal
              information, sensitive personal information, and similar other data provided by you on
              the Website/App (the “Content”). You agree that you shall be solely responsible for
              collecting, uploading and updating all the Content uploaded on the Website/App, and
              for ensuring that the Content does not
            </p>
            <p>
              (i) include anything that actually or potentially infringes or misappropriates the
              copyright, trade secret, trademark or other intellectual property right of any third
              party; or
            </p>
            <p>
              (ii) contain anything that is obscene, defamatory, harassing, offensive, malicious,
              harmful, abusive, invasive of privacy, defamatory, threatening, harassing,
              embarrassing, hateful or otherwise discriminatory, false or misleading, or incites an
              illegal act. You warrant that your Content is true and accurate at all times and you
              undertake to update your Content from time to time. If we become aware that the
              information provided by you is incorrect or false, we reserve the right to immediately
              cancel your registration and acceptance for registration in the future will be at our
              discretion.
            </p>
          </div>
          <div className="space-y-3" data-testid="our-proprietary-rights">
            <b>OUR PROPRIETARY RIGHTS</b>
            <p>
              Any and all rights to the Website, App, Services, graphics, images, layouts, logos,
              trademarks or text thereof including title, ownership rights and intellectual property
              rights such as copyrights, trademarks, service marks and patents therein is the sole
              and exclusive property of Scalex and/or its licensors. This Agreement does not grant
              the User any rights, title and interest in and to Website/App, its contents, and
              branding thereof including the Scalex intellectual property rights except where
              expressly and unequivocally licensed herein. Any rights not expressly and
              unequivocally granted to the User are reserved.
            </p>
            <p>
              User shall grant to Scalex a worldwide, non-exclusive and non-transferable
              limited-term license to host, copy, transmit, analyse, process, display, store,
              configure, and perform the Content solely as necessary to provide the Services to the
              User.
            </p>
            <p>
              You may choose to, or we may invite you to submit or you may submit comments or
              feedback, review, ratings about the Services including without limitation about how to
              improve the Services and the features of the Website/App. By submitting any comment,
              you agree that your disclosure is gratuitous, unsolicited and without restriction and
              will not place Scalex under any fiduciary or other obligation to maintain the secrecy
              of such feedback, comments or review and that we are free to use the feedback, review,
              ratings without any additional compensation to you, and/or to disclose the same on a
              non-confidential basis or otherwise to anyone. You further acknowledge that, by
              acceptance of your submission, Scalex does not waive any rights to use similar or
              related ideas previously known to Scalex or developed by its employees or obtained
              from sources other than you.
            </p>
          </div>
          <div className="space-y-3" data-testid="hyperlinks">
            <b>HYPERLINKS</b>
            <p>
              The Website/App may from time to time contain hyperlinks to other websites or mobile
              applications. Such links are provided for convenience only and we take no
              responsibility for the content and maintenance of or privacy compliance by any linked
              Website. Any hyperlink on our Website to another website does not imply our
              endorsement, support, or sponsorship of the operator of that website nor of the
              information and/or services which it provides. Scalex is not a party to any
              transaction between you and such website. [https://cloudinspot.com/]
            </p>
          </div>

          <div className="space-y-3" data-testid="privacy-and-security">
            <b>PRIVACY & SECURITY</b>

            <p>
              Scalex cares about the privacy of our Users, the integrity and security of the User’s
              personal information. The personal information collected by us is subject to and
              governed by our Privacy Policy located at
              [https://cloudinspot.com/privacy-policy.html] . You understand that by using the
              Services you consent to the collection, use and disclosure of your personally
              identifiable information and aggregate data as set forth in our Privacy Policy, and to
              have your personally identifiable information collected, used, transferred to and
              processed.
            </p>
          </div>

          <div className="space-y-3" data-testid="the-site-does-not-provide-professional-advice">
            <b>THE SITE DOES NOT PROVIDE PROFESSIONAL ADVICE</b>
            <p>
              All information offered on the Website/App, including but not limited to
              recommendations, analysis, health risk, symptoms, suggestions, notifications, status
              updates, comments and text messages, and advertising, comes from automated systems and
              software and is for information purposes only. Except for the Content shared by you on
              the Website/App, all materials including, without limitation, software, images, text,
              graphics, illustrations, logos, patents, trademarks, service marks, copyrights,
              photographs, information, audio, videos, music available on the Website obtained from
              Scalex’s licensors, partners, sponsors, advertisers, and other material contained on
              the Website/App are for informational purposes only. The results, provided by or using
              the Services or through any other communications from Scalex, or through this
              Website/App is not intended as a substitute for, nor does it replace, professional
              advice. In case of any medical information, the user shall not disregard, avoid or
              delay obtaining medical advice from a qualified healthcare provider or a doctor
              because of something he/she may have read on the Website/App. You acknowledge that the
              provision of Services does not create a medical professional/patient relationship or a
              doctor-patient relationship. In the event the user experiences a medical emergency,
              the user shall immediately call a doctor and shall not rely on the results or
              information on the Website. Nothing stated or posted on this Website or available
              through any Services is intended to be, and must not be taken to be, the practice of
              medicine, or other professional health care advice, or the provision of medical care.
              If you rely on any content provided through the Services, you do so solely at your own
              risk.
            </p>
            <p>
              Always seek the advice of your physician or other qualified health provider with any
              questions you may have regarding a medical condition, including your specific medical
              needs. None of the Services offered through this Website/App represents or warrants
              that it is safe, appropriate or effective for you. Never disregard professional
              medical advice or delay in seeking it because of something you have read on this
              Website/App. Scalex does not recommend or endorse any physicians, products,
              procedures, opinions, or other information that may be mentioned on the Website/App.
              Reliance on any information appearing on the Website is solely at your own risk.
            </p>
          </div>
          <div className="space-y-3" data-testid="user-warranty">
            <b>USER WARRANTY</b>
            <p>
              You hereby represent and warrant that you will access the Services solely within the
              territory of India and shall indemnify Scalex for any claim arising out of or related
              to the access of the Services beyond the territories of India.
            </p>
          </div>
          <div className="space-y-3" data-testid="no-warranty">
            <b>NO WARRANTY</b>
            <p>
              WE MAKE NO GUARANTEES, REPRESENTATIONS, ENDORSEMENTS OR WARRANTIES, WHETHER EXPRESSED
              OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, OR INFORMATION, OR
              OTHER CONTENT THROUGH THE SERVICES. IN NO EVENT, SHALL WE BE LIABLE TO YOU OR ANYONE
              ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON OUR SERVICES.
            </p>
            <p>
              THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS
              AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE
              PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PURPOSE,
              NON-INFRINGEMENT OR TIMELINESS OF THE CONTENT. WITHOUT LIMITING THE FOREGOING, Scalex,
              ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS
              ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE
              SERVICE(S) WILL BE AVAILABLE AT ANY SPECIFIC TIME OR LOCATION, UNINTERRUPTED OR
              SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED
              USING THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE
              FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS
              FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.
            </p>
            <p>
              Scalex DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY SERVICE
              ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE Scalex SERVICE OR ANY HYPERLINKED
              WEBSITE OR SERVICE.
            </p>
            <ul>
              Scalex SHALL NOT BE LIABLE FOR ANY
              <li>
                (i) WRONG INFORMATION, IMPROPER SUGGESTIONS, REPORTS, BEING GIVEN ON THE WEBSITE/APP
              </li>
              <li>
                (ii) ANY TYPE OF INCONVENIENCE, BODILY INJURY, OR DEATH OF THE USER DUE TO, USE OF
                THE WEBSITE/APP
              </li>
              <li>
                (iii), Scalex SHALL ALSO NOT BE LIABLE FOR ANY MEDICAL EVENTUALITIES THAT MIGHT
                OCCUR SUBSEQUENT TO USING THE SERVICES OR ON THE BASIS OF THE INFORMATION AVAILABLE
                ON THE WEBSITE/APP, NOR SHALL Scalex BE LIEBALE FOR ANY CLAIM ARISING OUT OF THE USE
                OF THESE SERVICES BEYOND THE TERRITORY OF INDIA.
              </li>
            </ul>
            <p>
              IN ADDITION, Scalex DOES NOT WARRANT THAT USE OF THE WEBSITE/APP WILL BE FREE FROM
              TECHNOLOGICAL DIFFICULTIES INCLUDING, BUT NOT LIMITED TO, UNAVAILABILITY OF
              INFORMATION, DOWNTIME, SERVICE DISRUPTIONS, VIRUSES OR WORMS, AND YOU UNDERSTAND THAT
              YOU ARE RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY
              YOUR PARTICULAR REQUIREMENTS FOR ACCURACY OF DATA INPUT AND OUTPUT.
            </p>
          </div>
          <div className="space-y-3" data-testid="termination-access-restriction">
            <b>TERMINATION/ ACCESS RESTRICTION</b>
            <p>
              Scalex reserves the right, in its sole discretion, to terminate your access to the
              Website/App and the related Services or any portion thereof at any time, without
              notice. Your right to access and use the Website terminates automatically upon your
              material breach of any of the of these Terms. Scalex reserves the right to change,
              suspend or discontinue all or any aspects of the Website/App at any time without prior
              notice.
            </p>
            <p>
              You agree that no joint venture, partnership, employment, or agency relationship
              exists between you and Scalex because of these Terms or use of this Website/App.
              Scalex&apos;s performance of these Terms are subject to applicable laws and legal
              process, and nothing contained in these Terms are in derogation of Scalex&apos;s right
              to comply with governmental, court and law enforcement requests or requirements
              relating to your use of the Website/App or information provided to or gathered by
              Scalex with respect to such use. If any part of these Terms is determined to be
              invalid or unenforceable pursuant to applicable law including, but not limited to, the
              warranty disclaimers and liability limitations set forth above, then the invalid or
              unenforceable provision will be deemed superseded by a valid, enforceable provision
              that most closely matches the intent of the original provision and the remainder of
              these Terms shall continue in effect.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="prohibited-conduct">
            <b>PROHIBITED CONDUCT</b>
            <p>You agree not to engage in any of the following activities:</p>
          </div>
          <div className="space-y-3 pb-10" data-testid="violating-laws-and-rights">
            <b>Violating laws and rights:</b>
            <p>You may not</p>
            <p>
              (a) use the Website/App for any illegal purpose or in violation of any local, state,
              national, or international laws
            </p>
            <p>
              (b) violate or encourage others to violate any right of or obligation to a third
              party, including but not limited to, by infringing, misappropriating, or violating
              intellectual property, confidentiality, or privacy rights.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="solicitation">
            <b>Solicitation:</b>
            <p>
              You may not use the Website/App, or any information provided through the Website for
              the transmission of advertising or promotional materials, including junk mail, spam,
              chain letters, or any other form of unsolicited or unwelcome solicitation.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="disruption">
            <b>Disruption:</b>
            <p>
              You may not use the Website/App in any manner that could disable, overburden, damage,
              or impair the Website/App, or interfere with any other party’s use and enjoyment of
              the Website; including by
            </p>
            <p>
              (a) uploading or otherwise disseminating any virus, adware, spyware, worm or other
              malicious code, or
            </p>
            <p>
              (b) interfering with or disrupting any network, equipment, or server connected to or
              used to provide any of the Services on the Website/App, or violating any regulation,
              policy, or procedure of any network, equipment, or server.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="impersonation-unauthorized-access">
            <b>Impersonation or unauthorized access:</b>
            <p>
              You may not impersonate another person or entity, or misrepresent your affiliation
              with a person or User when using the Website/App; You may not use or attempt to use
              another’s account or personal information; and, you may not attempt to gain
              unauthorized access to the Website/App, or the computer systems or networks connected
              to the Website/App, through hacking password mining or any other means. You may not
              attempt to disable, &quot;hack&quot; or otherwise interfere with the proper
              functioning of this Website/App.
            </p>
            <p>
              Scalex reserves the right (but is not obligated) to do any or all of the following: •
              Remove communications that fail to conform with these Terms; • Terminate a User&apos;s
              access to the entire Website/App or specific features of the Website/App; • Edit or
              delete any communications posted on the Website/App, regardless of whether such
              communications violate these standards.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="indemnity">
            <b>INDEMNITY</b>
            <p>
              You agree to defend, indemnify and hold harmless Scalex and its subsidiaries, agents,
              licensors, managers, and other affiliated companies, and their employees, contractors,
              agents, officers, and directors, from and against any and all claims, damages,
              obligations, losses, liabilities, costs or debt, and expenses (including but not
              limited to attorney’s fees) arising out of or related to:
            </p>
            <ul>
              <li>
                (i) your use of and access to the Services, including any data or Content
                transmitted or received by you
              </li>
              <li>(ii) your violation of these Terms</li>
              <li>
                (iii) your violation of any third-party right, including without limitation any
                right of privacy or Intellectual Property Rights
              </li>
              <li>(iv) your violation of any applicable law, rule or regulation</li>
              <li>
                (v) user content or any other information or content that is submitted through your
                Account including without limitation misleading, false, incomplete or inaccurate
                information; you’re your negligent or wilful misconduct.
              </li>
            </ul>
          </div>

          <div className="space-y-3 pb-10" data-testid="limitation-of-liability">
            <b>LIMITATION OF LIABILITY</b>
            <p>
              THE USE OF THE SITE IS ENTIRELY AT YOUR OWN RISK, AND IN NO EVENT, SHALL WE BE LIABLE
              TO YOU (OR TO ANY THIRD PARTY CLAIMING UNDER OR THROUGH YOU) FOR ANY DIRECT, INDIRECT,
              SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF, OR
              INABILITY TO USE, THE WEBSITE/APP OR THE SERVICES. THESE EXCLUSIONS APPLY TO ANY
              CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE
              OR MALFUNCTION, ANY OTHER COMMERCIAL DAMAGES OR LOSSES, OR MEDICAL MALPRACTICE OR
              NEGLIGENCE OF MEDIACL PRACTITIONERS UTILIZED THROUGH USE OF THE SERVICE, EVEN IF WE
              KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. YOU SPECIFICALLY
              ACKNOWLEDGE AND AGREE THAT WE SHALL NOT BE LIABLE FOR ANY CONTENT POSTED BY YOU OR BY
              ANY USER OR THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
              ENTIRELY WITH YOU. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICE
              IS TO STOP USING THE WEBSITE/APP OR THE SERVICE.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="general">
            <b>GENERAL</b>
            <b>Assignment:</b>
            <p>
              These Terms, and any rights and licenses granted hereunder, may not be transferred or
              assigned by you, but may be assigned by Scalex without restriction. Any attempted
              transfer or assignment by you in violation hereof shall be null and void.
            </p>
          </div>
          <div
            className="space-y-3 pb-10"
            data-testid="notification-procedures-and-changes-to-the-terms"
          >
            <b>Notification Procedures and Changes to the Terms:</b>
            <p>
              Scalex may provide notifications, whether such notifications are required by law or
              are for marketing or other business-related purposes, to you through an email or
              messages, or through posting of such notice on the Website/App, as determined by
              Scalex in its sole discretion. The Users may also choose to discontinue receiving such
              notification as described in these Terms. Scalex is not responsible for any automatic
              filtering you or your network provider may apply to email notifications we send to the
              email address you provide us.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="entire-terms-severability">
            <b>Entire Terms /Severability:</b>
            <p>
              These Terms, together with any amendments and any additional agreement you may enter
              with Scalex in connection with the Service, shall constitute the entire agreement
              between you and Scalex concerning the Service. If any provision of these Terms is
              deemed invalid by a court of competent jurisdiction, the invalidity of such provision
              shall not affect the validity of the remaining provisions of these Terms, which shall
              remain in full force and effect.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="no-waiver">
            <b>No Waiver:</b>
            <p>
              No waiver of any term of these Terms shall be deemed a further or continuing waiver of
              such term or any other term, and Scalex’s failure to assert any right or provision
              under these Terms shall not constitute a waiver of such right or provision.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="dispute-resolution">
            <b>Dispute Resolution:</b>
            <p>
              Any dispute or claim relating in any way to your use of any Scalex Service, by Scalex
              or through Scalex will be resolved by binding arbitration, to be conducted by an
              arbitrator appointed by Scalex. The arbitration proceedings shall be governed by the
              Arbitration and Conciliation Act, 1996 and the seat of the arbitration shall in Pune.
              The language of the proceedings shall be English, and the award of the proceedings
              shall be final and binding on the parties.
            </p>
          </div>
          <div className="space-y-3 pb-10" data-testid="governing-law-and-jurisdiction">
            <b>Governing Law and Jurisdiction:</b>
            <p>
              These Terms shall be governed by and interpreted and construed in accordance with the
              laws of India. The Courts/Tribunals in Pune shall have exclusive jurisdiction to try
              any disputes or suit arising out of these Terms. Thank you for your cooperation. We
              hope you find the Website/App helpful and convenient to use! Questions or comments
              regarding this Website, including any reports of non-functioning links, should be
              submitted using our Contact Us Form or via registered mail to: hello@cloudinspot.com.
              We try to answer every email in a timely manner but due to volume may not always be
              able to do so.
            </p>
          </div>
        </Text>
      </div>
    </React.Fragment>
  );
};
export default TermsOfUse;
