interface IGetOrgData {
  orgID: string;
  orgName: string;
  zone: string;
  organizationGmtOffsetName?: string;
  organizationAbbreviation?: string;
}

export const getOrgData = (): IGetOrgData => {
  const orgData: {
    organizationId: string;
    organizationName: string;
    organizationTimeZone: string;
    organizationGmtOffsetName?: string;
    organizationAbbreviation?: string;
  } = JSON.parse(localStorage.getItem('organization') || '{}');
  const orgID = orgData.organizationId;
  const orgName = orgData.organizationName;
  const zone = orgData.organizationTimeZone;
  const organizationGmtOffsetName = orgData.organizationGmtOffsetName;
  const organizationAbbreviation = orgData.organizationAbbreviation;
  return { orgID, orgName, zone, organizationGmtOffsetName, organizationAbbreviation };
};
