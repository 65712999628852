import { useNavigate } from 'react-router-dom';

import { IConfigureNotificationsProps } from './configureNotifications.interfaace';
import './configureNotifications.scss';

import IMAGES from '../../../assets';
import PlusIcon from '../../common/icons/plusIcon/PlusIcon';
import { colorConstants } from '../../../configs/styleConstants';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import { CLOUD_ACCOUNTS_TOOLTIP_MESSAGES } from '../../../configs/cloudAccounts';
import ENUMS from '../../../enums';

const {
  CloudAccountsStatuses,
  CloudAccountsEmailStatus,
  CloudAccountsSlackStatus,
  CloudAccountsMsTeamsStatus
} = ENUMS.cloudAccounts;

const classes = {
  Active: 'is-active',
  Connected: 'is-active',
  Failed: 'is-failed',
  Inactive: 'is-inactive',
  InProgress: 'is-inprogress',
  Unreachable: 'is-unreachable',
  Paused: 'is-inactive'
};

const configureNotifications = ({
  account,
  slackStatus = CloudAccountsSlackStatus.Inactive,
  emailStatus = CloudAccountsEmailStatus.Inactive,
  msTeamsStatus = CloudAccountsMsTeamsStatus.Inactive
}: IConfigureNotificationsProps) => {
  const naviagate = useNavigate();
  const isAccountStatusActive = account.status === CloudAccountsStatuses.Active;

  const emailIconVarientClass = () => {
    if (!isAccountStatusActive) return classes.Inactive;
    return emailStatus === CloudAccountsEmailStatus.Active ? classes.Active : classes.Inactive;
  };

  const slackIconVarientClass = () => {
    if (!isAccountStatusActive) return classes.Inactive;
    return slackStatus === CloudAccountsSlackStatus.Connected ||
      slackStatus === CloudAccountsSlackStatus.Active
      ? classes.Active
      : classes[slackStatus];
  };

  const msTeamsIconVarientClass = () => {
    if (!isAccountStatusActive) return classes.Inactive;
    return msTeamsStatus === CloudAccountsMsTeamsStatus.Connected ||
      msTeamsStatus === CloudAccountsMsTeamsStatus.Active
      ? classes.Active
      : classes[msTeamsStatus];
  };

  const borderSlackClass = () => {
    if (!isAccountStatusActive) return classes.Inactive;
    return slackStatus !== CloudAccountsSlackStatus.Connected
      ? classes.Inactive
      : classes[account.status];
  };

  const borderMsTeamsClass = () => {
    if (!isAccountStatusActive) return classes.Inactive;
    return msTeamsStatus !== CloudAccountsMsTeamsStatus.Connected
      ? classes.Inactive
      : classes[account.status];
  };

  const borderEmailClass = () => {
    if (!isAccountStatusActive) return classes.Inactive;
    return emailStatus !== CloudAccountsEmailStatus.Active
      ? classes.Inactive
      : classes[account.status];
  };

  const isSlackAddIconRequired =
    isAccountStatusActive && slackStatus !== CloudAccountsSlackStatus.Connected;
  const isMsTeamsAddIconRequired =
    isAccountStatusActive && msTeamsStatus !== CloudAccountsMsTeamsStatus.Connected;
  const isEmailAddIconRequired =
    isAccountStatusActive && emailStatus !== CloudAccountsEmailStatus.Active;

  const handleSlackClick = () => {
    naviagate(`/cloud-account/view/slack/${account.accountId}`);
  };
  const handleMsTeamsClick = () => {
    naviagate(`/cloud-account/view/msteam/${account.accountId}`);
  };
  const handleEmailClick = () => {
    naviagate(`/cloud-account/view/email/${account.accountId}`);
  };

  const tooltipTextSlack = (
    <p>
      {slackStatus === CloudAccountsSlackStatus.Connected
        ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.CONNECTED
        : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NOT_CONNECTED}
    </p>
  );
  const tooltipTextMSTeams = (
    <p>
      {msTeamsStatus === CloudAccountsMsTeamsStatus.Connected
        ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.CONNECTED
        : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NOT_CONNECTED}
    </p>
  );
  const tooltipTextEmail = (
    <p>
      {emailStatus === CloudAccountsEmailStatus.Active
        ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.CONNECTED
        : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NOT_CONNECTED}
    </p>
  );
  return (
    <div className="configure-notifications">
      <TooltipCustom text={tooltipTextSlack} offsetY={isSlackAddIconRequired ? 17 : 8}>
        <div
          className="slack-button"
          onClick={() => {
            if (isAccountStatusActive) {
              handleSlackClick();
            }
          }}
        >
          {isSlackAddIconRequired && (
            <div className="slack-button__add">
              <PlusIcon color={colorConstants.green3} />
            </div>
          )}
          <div className={`slack-button__icon  button-border--${borderSlackClass()}`}>
            <img
              src={IMAGES.slackColor}
              alt="status status"
              className={`slack-button__icon--${slackIconVarientClass()}`}
            />
          </div>
        </div>
      </TooltipCustom>

      {/* Ms Teams Start*/}
      <TooltipCustom text={tooltipTextMSTeams} offsetY={isMsTeamsAddIconRequired ? 17 : 8}>
        <div
          className="msteams-button"
          onClick={() => {
            if (isAccountStatusActive) {
              handleMsTeamsClick();
            }
          }}
        >
          {isMsTeamsAddIconRequired && (
            <div className="msteams-button__add">
              <PlusIcon color={colorConstants.green3} />
            </div>
          )}
          <div className={`msteams-button__icon  button-border--${borderMsTeamsClass()}`}>
            <img
              src={IMAGES.msTeamsColor}
              alt="status status"
              className={`msteams-button__icon--${msTeamsIconVarientClass()}`}
            />
          </div>
        </div>
      </TooltipCustom>
      {/* Ms Teams End */}

      {/* Email Start */}
      <TooltipCustom text={tooltipTextEmail} offsetY={isEmailAddIconRequired ? 17 : 8}>
        <div
          className="email-button"
          onClick={() => {
            if (isAccountStatusActive) {
              handleEmailClick();
            }
          }}
        >
          {isEmailAddIconRequired && (
            <div className="email-button__add">
              <PlusIcon color={colorConstants.green3} />
            </div>
          )}
          <div className={`email-button__icon button-border--${borderEmailClass()}`}>
            <img
              src={IMAGES.emailColor}
              alt="status status"
              className={`email-button__icon--${emailIconVarientClass()} `}
            />
          </div>
        </div>
      </TooltipCustom>
      {/* Email End */}
    </div>
  );
};

export default configureNotifications;
