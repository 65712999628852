import { IGet } from './service.interface';
import authHttp from './authHttp';

const getWithAuth = async ({ url, params }: IGet) => {
  //prev We used a axios directly from library
  //now used a *authHttp* a instance of axios so no need to send headers from here as this is taken care by authHttp

  return authHttp
    .get(url, { params })
    .then((response) => {
      localStorage.setItem('retryCounter', '0');
      return response;
    })
    .catch((error: unknown) => {
      throw error;
    });
};

export default getWithAuth;
