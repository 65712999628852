import { NotificationSettings } from '../../components/settings/notifications/notification.interface';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ISettingsReducerInitialState {
  organizationData: IOrganizationData;
  isSettingsEditModeOn: boolean;
  notificationSettings: NotificationSettings;
}

export class IOrganizationData {
  name: string;
  country: string;
  state: string;
  currency: string;
  timezone: string;
  abbreviation: string;
  gmtOffsetName: string;
  constructor() {
    this.name = '';
    this.country = '';
    this.state = '';
    this.currency = '';
    this.timezone = '';
    this.abbreviation = '';
    this.gmtOffsetName = '';
  }
}

export interface ISettingAction {
  type: string;
  payload: ISettingsReducerInitialState | any; //marking it as any for now
}

export interface IOrganizationAPIResponse {
  organization: IOrganizationData;
}
