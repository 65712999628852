// External imports
import { useState } from 'react';

import { IPermissionsReviewProps } from './permissions.interface';
import './permissions.scss';

import Accordion from '../../common/accordion/Accordion';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import Typography from '../../common/typography/Typography';

const PermissionsReview = ({
  userPermissions,
  selectedPermissions,
  disableCheckboxes = true
}: IPermissionsReviewProps) => {
  // React state
  const [currentStep, setCurrentStep] = useState<number>(1);

  const checkIfToBeSelected = ({
    module,
    permission
  }: {
    module: string;
    permission: string;
  }): boolean => {
    if (selectedPermissions) {
      const selectedPermissionsObject = selectedPermissions.find((pItem) => pItem.module === module)
        ?.permissions;
      if (selectedPermissionsObject) {
        const selectedPermissionsList = Object.keys(selectedPermissionsObject);
        return selectedPermissionsList.includes(permission);
      }
      return false;
    }
    return false;
  };

  const accordionClickHandler = (permissionStep: number) => {
    setCurrentStep((prev) => (prev === permissionStep ? 0 : permissionStep));
  };

  return (
    <>
      {userPermissions.map((item, index) => {
        return (
          <Accordion
            accordionTitle={
              <Typography variant="body-3" size="medium" as="span">
                {item.module}
              </Typography>
            }
            key={index}
            accordionKey={item.apiKey}
            isOpen={currentStep === index + 1}
            containerClass="permissions-accordion"
            onClick={() => accordionClickHandler(index + 1)}
          >
            <div className="permissions-module">
              <div className="permissions-module__head">
                <Typography variant="body-3" size="medium" as="span" className="permission">
                  {TEAM_MANAGEMENT_TEXT.PERMISSIONS}
                </Typography>
                <Typography variant="body-3" size="medium" as="span" className="access">
                  {TEAM_MANAGEMENT_TEXT.ACCESS}
                </Typography>
              </div>

              {Object.keys(item.permissions).map((permission, index) => {
                const description = item?.permissions?.[permission]?.description;
                return (
                  <div key={index} className="permissions-module__item">
                    <Typography variant="body-3" size="medium" as="span" className="permission">
                      {permission}
                      <Typography
                        variant="body-3"
                        size="regular"
                        as="span"
                        className="permission description apply-loader"
                      >
                        {description}
                      </Typography>
                    </Typography>

                    <div className="permissions-module__item__input">
                      <input
                        type="checkbox"
                        aria-description="checkbox for permission"
                        value={permission}
                        checked={checkIfToBeSelected({ module: item.module, permission })}
                        readOnly
                        disabled={disableCheckboxes}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Accordion>
        );
      })}
    </>
  );
};

export default PermissionsReview;
