import React from 'react';

import { colorConstants } from '../../../../configs/styleConstants';
import '../../icons/icon.scss';
import { TIcon } from '../icon.types';

const TrashIcon: React.FC<TIcon> = ({ color = colorConstants.primary, onClick }: TIcon) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={`cursor-pointer icon ${onClick ? 'icon-clickable' : ''}`}
    >
      <path
        d="M16.5 6.5V5.7C16.5 4.5799 16.5 4.01984 16.282 3.59202C16.0903 3.21569 15.7843 2.90973 15.408 2.71799C14.9802 2.5 14.4201 2.5 13.3 2.5H11.7C10.5799 2.5 10.0198 2.5 9.59202 2.71799C9.21569 2.90973 8.90973 3.21569 8.71799 3.59202C8.5 4.01984 8.5 4.5799 8.5 5.7V6.5M10.5 12V17M14.5 12V17M3.5 6.5H21.5M19.5 6.5V17.7C19.5 19.3802 19.5 20.2202 19.173 20.862C18.8854 21.4265 18.4265 21.8854 17.862 22.173C17.2202 22.5 16.3802 22.5 14.7 22.5H10.3C8.61984 22.5 7.77976 22.5 7.13803 22.173C6.57354 21.8854 6.1146 21.4265 5.82698 20.862C5.5 20.2202 5.5 19.3802 5.5 17.7V6.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
