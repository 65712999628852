import {
  SET_ACTIVE_TAB,
  SET_HEADING,
  TOGGLE_BOTTOM_NAVIGATION,
  TOGGLE_SIDEBAR
} from './Sidebar.actionTypes';
import { ISidebarActions, ISidebarReducer } from './Sidebar.interface';

const SidebarInitialValues: ISidebarReducer = {
  activeTab: 100,
  isSidebarCollapse: false,
  // title: 'Dashboard',
  isBottomNavigationOpen: false
};

const sidebarReducer = (state = SidebarInitialValues, action: ISidebarActions) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload
      };

    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapse: payload
      };

    case SET_HEADING:
      return {
        ...state,
        title: payload
      };

    case TOGGLE_BOTTOM_NAVIGATION:
      return {
        ...state,
        isBottomNavigationOpen: payload
      };

    default:
      return state;
  }
};

export default sidebarReducer;
