export const ADD_USER_STEP_1 = 'ADD_USER_STEP_1';
export const ADD_USER_STEP_2 = 'ADD_USER_STEP_2';
export const SET_CURRENT_ACTIVE_TAB_FORM = 'SET_CURRENT_ACTIVE_TAB_FORM';
export const UPDATE_TEAM_MANG_TABLES_TYPE = 'UPDATE_TEAM_MANG_TABLES_TYPE';
export const ADD_GROUP_NAME = 'ADD_GROUP_NAME';
export const UPDATE_VIEW_GROUP_TABLES_TYPE = 'UPDATE_VIEW_GROUP_TABLES_TYPE';
export const UPDATE_SELECTED_GROUP = 'UPDATE_SELECTED_GROUP';
export const EDIT_GROUP_NAME = 'EDIT_GROUP_NAME';

// USERS
export const UPDATE_TEAM_MGMT_TABLE_USERS = 'UPDATE_TEAM_MGMT_TABLE_USERS';
export const ARCHIVE_TEAM_MMGMT_USERS = 'ARCHIVE_TEAM_MMGMT_USERS';
export const CLEAR_TEAM_MGMT_TABLE_USERS = 'CLEAR_TEAM_MGMT_TABLE_USERS';

// GROUPS
export const UPDATE_TEAM_MGMT_TABLE_GROUPS = 'UPDATE_TEAM_MGMT_TABLE_GROUPS';
export const UPDATE_GROUP_SUMMARY = 'UPDATE_GROUP_SUMMARY';
export const CLEAR_TEAM_MGMT_TABLE_GROUPS = 'CLEAR_TEAM_MGMT_TABLE_GROUPS';
export const SET_ADD_USERS_TO_GROUP_LIST = 'SET_ADD_USERS_TO_GROUP_LIST';
export const UPDATE_ADD_USERS_TO_GROUP_LIST = 'UPDATE_ADD_USERS_TO_GROUP_LIST';

// ARCHIVED USERS
export const UPDATE_TEAM_MGMT_TABLE_ARCHIVED_USERS = 'UPDATE_TEAM_MGMT_TABLE_ARCHIVED_USERS';
export const CLEAR_TEAM_MGMT_TABLE_ARCHIVED_USERS = 'CLEAR_TEAM_MGMT_TABLE_ARCHIVED_USERS';

// ACCESS CONTROL
export const UPDATE_TEAM_MGMT_USER_ACCESS_CONTROL_TABTYPE =
  'UPDATE_TEAM_MGMT_USER_ACCESS_CONTROL_TABTYPE';

export const UPDATE_TABLES_LOADING_STATE = 'UPDATE_TABLES_LOADING_STATE';

export const UPDATE_USER_SUMMARY = 'UPDATE_USER_SUMMARY';
