import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import { TSvgIconProps } from '../../common.interface';

const ClockIcon = ({ color = PRIMARY_COLORS[800] }: TSvgIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.668 6V12L16.668 14M22.668 12C22.668 17.5228 18.1908 22 12.668 22C7.14512 22 2.66797 17.5228 2.66797 12C2.66797 6.47715 7.14512 2 12.668 2C18.1908 2 22.668 6.47715 22.668 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;
