import { useEffect, useMemo, useRef, useState } from 'react';

import './faq.scss';

import { confirmSuccess } from '../../assets/images';
import { BUTTON_TEXT, FAQ_TEXT } from '../../configs';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  fetchFaqsRequest,
  setActiveTab,
  setHeading,
  updateSkeletonLoader
} from '../../store/actions';
import { ICommonReducerInitialState } from '../../store/common/common.interface';
import { IFaqReducerInitialState } from '../../store/faq/faq.interface';
import Accordion from '../../components/common/accordion/Accordion';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import UserFeedback from '../../components/common/molecules/userFeedback/UserFeedback';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import CustomButton from '../../components/common/buttons/CustomButton';
import NeedHelpModal from '../../components/faq/needHelp/needHelpModal';
import SearchForm from '../../components/common/searchForm/SearchForm';
import { TSearchFormValues } from '../../components/common/searchForm/searchForm.types';

// to show loader items in loading state
const itemsForLoader = [
  { question: '', answer: '' },
  { question: '', answer: '' },
  { question: '', answer: '' }
];

const FAQ = () => {
  const [selectedFaq, setSelectedFaq] = useState<number>(-1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(true);
  const [openFeedbackConfirmModal, setOpenFeedbackConfirmModal] = useState<boolean>(false);

  const answerRef = useRef<HTMLDivElement[]>([]);

  const dispatch = useAppDispatch();
  const { faqs } = useAppSelector((store) => store.faq) as IFaqReducerInitialState;
  const { showSkeletonLoader } = useAppSelector(
    (store) => store.common
  ) as ICommonReducerInitialState;

  const faqsToLoad = useMemo(() => {
    if (showSkeletonLoader) {
      return itemsForLoader;
    }
    if (searchQuery !== '') {
      try {
        const newItems = faqs.filter(
          (item) =>
            (item.question as string)?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            (item.answer as string)?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        return newItems;
      } catch {
        return [];
      }
    }
    return faqs;
  }, [faqs, showSkeletonLoader, searchQuery]);

  const onChange = ({ query }: TSearchFormValues) => {
    setSearchQuery(query);
  };

  const handleFeedbackSubmit = async () => {
    setOpenFeedbackModal(false);
    setOpenFeedbackConfirmModal(true);
  };

  const fetchFaqs = () => {
    if (faqs.length <= 0) {
      dispatch(
        fetchFaqsRequest({
          callbackFunc: () => {
            dispatch(updateSkeletonLoader({ isLoading: false }));
          }
        })
      );
    } else {
      dispatch(updateSkeletonLoader({ isLoading: false }));
    }
  };

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading('Frequently Asked Questions'));
      dispatch(setActiveTab(7));
      fetchFaqs();
    },
    onUnMountFunc: () => {
      dispatch(updateSkeletonLoader({ isLoading: true }));
    }
  });

  // Add useEffect to set the initial FAQ open when data is loaded
  useEffect(() => {
    // Once FAQs are loaded, set the selectedFaq to the first item
    if (faqsToLoad.length > 0) {
      setSelectedFaq(0);
    }
  }, [faqsToLoad]);

  useEffect(() => {
    // This is to insert html content received from api
    faqsToLoad.forEach((item, index) => {
      const element = answerRef.current[index];
      if (element) {
        element.innerHTML = item?.answer;
      }
    });
  }, [answerRef.current, faqsToLoad]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="container_FAQ">
        <SearchForm
          placeholder={FAQ_TEXT.TYPE_QUERY}
          onSearch={onChange}
          className="faq_search_box"
          shouldShowSearchButton={false}
        />
        <CustomButton
          text="Need Help?"
          className="btn_faq_need_help"
          onClick={handleButtonClick}
          buttonWidth="180px"
        />
      </div>
      <NeedHelpModal isOpen={isModalOpen} onClose={handleModalClose} />

      <div className="faq__items">
        {faqsToLoad?.length > 0 ? (
          faqsToLoad.map((item, index) => (
            <div key={index} className="faq__items__item ">
              <Accordion
                accordionTitle={item.question}
                accordionKey={index}
                isOpen={selectedFaq === index}
                onClick={() => setSelectedFaq((prev) => (prev === index ? -1 : index))}
                containerClass="faq__items__item__accordion"
                isLoading={showSkeletonLoader}
                applyBoxShadow={false}
              >
                <div ref={(ele: HTMLDivElement) => (answerRef.current[index] = ele)}></div>
              </Accordion>
            </div>
          ))
        ) : (
          <p className="faq__items__not-found">No FAQs found</p>
        )}
      </div>
      <ConfirmModal
        open={openFeedbackConfirmModal}
        setOpen={setOpenFeedbackConfirmModal}
        title="Feedback Sent!"
        description={
          <>
            Your feedback is sent successfully to <CloudInSpotName /> team. Thank you!
          </>
        }
        descriptionWidth="88%"
        image={confirmSuccess}
        confirmButtonText={BUTTON_TEXT.OKAY}
        handleOkClick={() => setOpenFeedbackConfirmModal(false)}
      />
      <UserFeedback
        openModal={openFeedbackModal && !showSkeletonLoader}
        handleClose={() => setOpenFeedbackModal(false)}
        callbackFunc={handleFeedbackSubmit}
        containerClass="faq__user-feedback"
      />
    </>
  );
};

export default FAQ;
