import { useEffect } from 'react';

import { ISubMenuModal } from './subMenuModal.interface';

const SubMenuModal = ({ children, setOpenSubMenuModal }: ISubMenuModal) => {
  // Wrap this component where the behaviour similar to sub menu cards etc. is required
  // This component ONLY adds a event listener on the Window, where clicking on window triggers a state change
  // using "setOpenSubMenuModal".
  // Event stopPropagation needs to be handled in the parent component.
  // Styles needs be handled in the parent component.
  const closeSubMenuModal = () => {
    setOpenSubMenuModal(false);
  };

  useEffect(() => {
    window.addEventListener('click', closeSubMenuModal);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('click', closeSubMenuModal);
    };
  }, []);

  return <>{children}</>;
};

export default SubMenuModal;
