import { PermissionVariablesEnums } from '../../../enums/common';

const permissionsState: Record<PermissionVariablesEnums, boolean> = {
  canUserViewCloudAccount: false,
  canUserAddCloudAccount: false,
  canUserViewAccountInfo: false,
  canUserViewSlackAccount: false,
  canUserViewMsTeamAccount: false,
  canUserViewEmailList: false,
  canUserEditCloudAccount: false,
  canUserDisconnectCloudAccount: false,
  canUserResumeCloudAccount: false,
  canUserPauseCloudAccount: false,
  canUserReconnectCloudAccount: false,
  canUserAddEmailNotification: false,
  canUserDeleteEmail: false,
  canUserAddEmailRecipients: false,
  canUserRemoveEmailRecipients: false,
  canUserAddSlack: false,
  canUserDisconnectSlack: false,
  canUserDisconnectMsTeam: false,
  canUserReconfigureSlack: false,
  canUserDeleteCloudAccount: false,
  canUserEditAnotherUser: false,
  canUserEditAnotherUserPermissions: false,
  canUserEditAnotherUserGroups: false,
  canUserEditNotifications: false,
  canUserEditOrganization: false,
  canUserViewUsersTable: false,
  canUserViewGroupsTable: false,
  canUserViewDeactivatedUsersTable: false,
  canUserActivateAnotherUser: false,
  canUserDeleteAnotherUser: false,
  canUserAddGroup: false,
  canUserDeleteGroup: false,
  canUserViewGroup: false,
  canUserEditGroup: false,
  canUserRemoveUsersFromGroup: false,
  canUserAddAnotherUser: false,
  canUserDeactivateAnotherUser: false,
  canUserViewAnotherUser: false,
  canUserRemoveOtherUsersFromGroup: false,
  canUserResetOtherUsersPassword: false
};

export default permissionsState;
