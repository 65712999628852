import { ISortObjectArray, TSortObject } from './sortObjectArray.interface';

const sortObjectArray = ({ arr, key, sortType }: ISortObjectArray) => {
  try {
    if (sortType === 'reverse') {
      const newArray: TSortObject[] = [];
      arr.forEach((item) => {
        newArray.unshift(item);
      });
      return newArray;
    }
    if (key && sortType === 'asc') {
      return arr.sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (aValue.toLowerCase() < bValue.toLowerCase()) {
            return -1;
          }
          return 1;
        } else {
          if (a[key] < b[key]) {
            return -1;
          }
          return 1;
        }
      });
    }
    if (key && sortType === 'desc') {
      return arr.sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (aValue.toLowerCase() < bValue.toLowerCase()) {
            return 1;
          }
          return -1;
        } else {
          if (a[key] < b[key]) {
            return 1;
          }
          return -1;
        }
      });
    }
    if (key && sortType === 'timeDesc') {
      return arr.sort((a, b) => {
        const aValue = a[key] as string | number;
        const bValue = b[key] as string | number;
        if (isNaN(new Date(aValue).getTime()) || isNaN(new Date(bValue).getTime())) {
          throw new Error('Invalid date');
        }
        if (new Date(aValue).getTime() < new Date(bValue).getTime()) {
          return 1;
        }
        return -1;
      });
    }
    if (key && sortType === 'timeAsc') {
      return arr.sort((a, b) => {
        const aValue = a[key] as string | number;
        const bValue = b[key] as string | number;
        if (isNaN(new Date(aValue).getTime()) || isNaN(new Date(bValue).getTime())) {
          throw new Error('Invalid date');
        }
        if (new Date(aValue).getTime() < new Date(bValue).getTime()) {
          return -1;
        }
        return 1;
      });
    } else return arr;
  } catch {
    return arr;
  }
};

export default sortObjectArray;
