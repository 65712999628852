import { useMemo, useState } from 'react';

import './searchAndSelect.scss';
import { ISearchAndSelectInterface } from './searchAndSelect.interface';

import { selectDownArrow } from '../../../../assets/images';
import SubMenuModal from '../../subMenuModal/SubMenuModal';
import Typography from '../../typography/Typography';

const SearchAndSelect = ({
  id,
  name,
  testid,
  placeholder,
  searchPlaceholder,
  activeSearchModal,
  setActiveSearchModal,
  fieldItems,
  bottom = '0',
  value,
  onInputChangeFunc,
  onSelectChangeFunc,
  onBlur,
  disabled = false
}: ISearchAndSelectInterface) => {
  const [searchString, setSearchString] = useState<string>('');

  const searchList = useMemo(() => {
    if (searchString === '') {
      return fieldItems;
    }
    return fieldItems.filter(
      (item: { name: string }) => item?.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    );
  }, [searchString]);

  const modalActive = activeSearchModal === id && Boolean(searchList?.length);

  return (
    <>
      {/* Dummy div is to fill the height that is left by the Field 
      element while it is being positioned absolutely */}
      {modalActive && <div className="search-select-dummy-div h-[42px] mt-[2px]"></div>}
      <div
        style={{ bottom: bottom }}
        className={`${
          modalActive
            ? `absolute bg-white w-full border-solid border rounded-lg shadow-xl p-[10px] pb-0 z-10`
            : ''
        } ${modalActive && 'input-search-filter--active'} search-select`}
        onClick={(e) => {
          e.stopPropagation();
          if (searchList?.length) {
            setActiveSearchModal(id);
          }
        }}
      >
        <div className="search-select-inner">
          {modalActive && (
            <Typography
              variant="body-2"
              size="semiBold"
              className="search-select-inner__label"
              as="p"
            >{`Select ${name}`}</Typography>
          )}
          <input
            id={id}
            data-testid={testid}
            name={name}
            placeholder={modalActive ? searchPlaceholder : placeholder}
            className={`input_fields placeholder:text-placeholder_text text-normal_text input-search-filter ${id}`}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setActiveSearchModal(id);
              setSearchString(e.target.value);
              onInputChangeFunc({ field: name, value: e.target.value });
            }}
            onBlur={onBlur}
            disabled={disabled}
          />
          {modalActive && (
            <SubMenuModal setOpenSubMenuModal={setActiveSearchModal}>
              <ul
                data-testid="search-select-ul"
                className={`p-x-1 w-full bg-white left-0 border-solid rounded-lg mt-[6px] input-search-filter__results ${id}`}
              >
                {searchList.map((item, index) => (
                  <Typography
                    variant="body-3"
                    size="regular"
                    as="li"
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectChangeFunc({ field: name, selectedOption: item });
                      setActiveSearchModal('');
                    }}
                  >
                    {item.name}
                  </Typography>
                ))}
              </ul>
            </SubMenuModal>
          )}
        </div>
      </div>
      {!modalActive && Boolean(searchList?.length) && (
        <style>{`#${id} {background-image: url(${selectDownArrow})}`}</style>
      )}
    </>
  );
};

export default SearchAndSelect;
