import { useState } from 'react';

import { ITextTooltipWithEllipsis } from './textTooltipWithEllipsis.interface';

import SimpleTooltip from '../simpleTooltip/SimpleTooltip';
import { textEllipsis } from '../../../utils';

const TextTooltipWithEllipsis = ({ limit = 25, text }: ITextTooltipWithEllipsis) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  if (text?.length <= limit) {
    return text;
  }

  const ellipsisText = textEllipsis({ charLimit: limit, text: text });
  return (
    <SimpleTooltip
      text={text}
      showTooltip={showTooltip}
      setShowTooltip={setShowTooltip}
      limitWidth={true}
    >
      <span>{ellipsisText}</span>
    </SimpleTooltip>
  );
};

export default TextTooltipWithEllipsis;
