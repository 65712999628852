import { useState } from 'react';
import { permissions } from '@cloudinspot/cis-common-constants';

import PermissionsReview from '../../teamManagement/permissions/PermissionsReview';

const OwnerPermissionsView = () => {
  const [showPermissions, setShowPermissions] = useState<boolean>(false);

  if (showPermissions) {
    return (
      <PermissionsReview
        userPermissions={permissions}
        selectedPermissions={permissions}
        disableCheckboxes={true}
      />
    );
  }

  return (
    <p className="owner-permissions-view">
      You have all the permissions. Visit the list&nbsp;
      <span className="owner-permissions-view__link" onClick={() => setShowPermissions(true)}>
        here
      </span>
    </p>
  );
};

export default OwnerPermissionsView;
