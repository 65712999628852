import { isAxiosError, type AxiosResponse } from 'axios';

import authInterceptor from './authInterceptors';
import createInstance from './instance';
import methods from './methods';
import type { TGetOrganizationResponse } from './types/organization.endpoints';

import { IFetchNotificationSettingsResponse } from '../components/settings/notifications/notification.interface';
import API_ENDPOINTS from '../constants/api_endpoints';

const OrganizationServiceInstance = createInstance({
  baseURL: process.env.REACT_APP_API_BASE_URL || ''
});

authInterceptor(OrganizationServiceInstance);
methods(OrganizationServiceInstance);

export async function getOrganizationDetails(): Promise<TGetOrganizationResponse | undefined> {
  try {
    const axiosResponse: AxiosResponse<TGetOrganizationResponse> =
      await OrganizationServiceInstance.get('/organization/get-org-details');
    return axiosResponse.data;
  } catch (error) {
    if (isAxiosError(error)) Promise.reject(error?.response?.data?.errors);
  }
}

export async function getNotificationSettings(): Promise<
  IFetchNotificationSettingsResponse | undefined
> {
  try {
    const response: AxiosResponse<IFetchNotificationSettingsResponse> =
      await OrganizationServiceInstance.get(API_ENDPOINTS.SETTINGS_GET_NOTIFICATION_SETTINGS);
    return response.data as IFetchNotificationSettingsResponse;
  } catch (error) {
    if (isAxiosError(error)) Promise.reject(error?.response?.data?.errors);
  }
}
