import { useEffect, useState } from 'react';

import { IElementPosition, IUseElementPosition } from './useElementPosition.interface';

const useElementPosition = ({ ref, triggers = [] }: IUseElementPosition) => {
  // This hook calculates the position ref element,

  const [elementPosition, setElementPosition] = useState<IElementPosition>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  });

  const calculateElementPosition = () => {
    if (ref.current) {
      const currentPosition = ref.current.getBoundingClientRect();

      setElementPosition(currentPosition);
    }
  };

  useEffect(() => {
    calculateElementPosition();
    // Calculate element position if the screen size is changed.
    window.addEventListener('resize', calculateElementPosition);
    return () => window.removeEventListener('resize', calculateElementPosition);
  }, []);

  useEffect(() => {
    calculateElementPosition();
  }, [ref, ...triggers]);

  return elementPosition;
};

export default useElementPosition;
