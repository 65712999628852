import { ITextEllipsis } from './textEllipsis.interface';

const textEllipsis = ({ charLimit, text }: ITextEllipsis) => {
  try {
    if (charLimit >= text?.length) {
      return text;
    }
    return text.slice(0, charLimit) + '...';
  } catch {
    return text;
  }
};

export default textEllipsis;
