import post from './post.service';
import get from './get.service';
import postWithAuth from './postWithAuth';
import authHttp from './authHttp';
import getWithAuth from './getWithAuth';
import deleteWithAuth from './deleteWithAuth';
import getWithAuthMSTeams from './msTeams/getWithAuthMSTeams';
import postWithAuthMSTeams from './msTeams/postWithAuthMSTeams';

const services = {
  get,
  post,
  postWithAuth,
  authHttp,
  getWithAuth,
  deleteWithAuth,
  getWithAuthMSTeams,
  postWithAuthMSTeams
};
export default services;
