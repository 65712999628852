import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as axios from 'axios';

import GroupNameEdit from '../../components/teamManagement/Groups/editGroup/GroupNameEdit';
import EditGroupPermissions from '../../components/teamManagement/Groups/editGroup/EditGroupPermissions';
import AddUsers from '../../components/teamManagement/Groups/editGroup/AddUsers';
import CustomStepsForm from '../../components/common/customStepsForm/CustomStepsForm';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  editGroupName,
  setActiveTab,
  setCurrentActiveTabForm,
  setHeading,
  updateTeamMgmtGroupPermissions
} from '../../store/actions';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { routes } from '../../constants/routes';
import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';
import useEditGroupsSteps from '../../hooks/customStepsForm/useEditGroupsSteps';
import {
  IPermissionsList,
  ITeamManagementInitialValues,
  ITeamMangSelectedGroup,
  ITeamMangUser
} from '../../store/teamManagement/teamManagement.interface';
import NotFound from '../not-found/NotFound';
import { ITeamManagementGroupInitialState } from '../../store/teamManagement/teamManagementGroup/teamManagementGroup.interface';
import { getSelectedGroup } from '../../utils';
import useGetRequest from '../../hooks/useGetRequest/useGetRequest';
import API_ENDPOINTS from '../../constants/api_endpoints';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';

const EditGroup = () => {
  const editGroupRef = useRef<HTMLDivElement>(null);
  const selectedGroup = getSelectedGroup() as unknown as ITeamMangSelectedGroup;
  const { groupname } = useParams() as unknown as { groupname: string };

  const { currentActiveTabForm } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;
  const { permissions: groupPermissions } = useAppSelector(
    (store) => store.teamManagementGroup
  ) as ITeamManagementGroupInitialState;

  const [editGroupSelectedPermissions, setEditGroupSelectedPermissions] =
    useState<IPermissionsList[]>(groupPermissions);
  const [selectedUsers, setSelectedUsers] = useState<ITeamMangUser[]>([]);

  const dispatch = useAppDispatch();

  const steps = useEditGroupsSteps();

  const updatePermissions = ({ response }: { response: axios.AxiosResponse }) => {
    const currentPermissions = response?.data?.permissions || [];
    dispatch(updateTeamMgmtGroupPermissions(currentPermissions));
    setEditGroupSelectedPermissions(currentPermissions);
  };

  useGetRequest({
    url: API_ENDPOINTS.TEAM_MGMT_GET_GROUP_PERMISSIONS,
    params: {
      groupName: groupname
    },
    trigger: Boolean(groupname),
    callback: updatePermissions,
    redirectTo404: true
  });

  const AddGroupForm = [
    <GroupNameEdit key={0} />,
    <EditGroupPermissions
      key={1}
      editGroupSelectedPermissions={editGroupSelectedPermissions}
      setEditGroupSelectedPermissions={setEditGroupSelectedPermissions}
    />,
    <AddUsers
      key={2}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      editGroupSelectedPermissions={editGroupSelectedPermissions}
    />
  ];

  const onPageLoad = () => {
    dispatch(editGroupName(groupname));
    dispatch(setHeading(TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT));
    dispatch(setActiveTab(5));
    dispatch(setCurrentActiveTabForm(0));
  };

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      { text: TEAM_MANAGEMENT_TEXT.GROUPS, url: routes.TEAM_MANAGEMENT_GROUPS_TABLE },
      {
        text: groupname,
        url: `${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${groupname}`
      },
      { text: TEAM_MANAGEMENT_TEXT.EDIT_GROUP, url: '' }
    ]
  });

  useEffect(() => {
    onPageLoad();
  }, []);

  if (selectedGroup?.groupName) {
    return (
      <div ref={editGroupRef}>
        <PaperCustom
          variant="light"
          className="tm-edit-group w-full bg-white rounded-[10px]  desktop:pb-[55px] pt-[29px] sm:pt-[51px] editGroupContainer"
        >
          <CustomStepsForm activeTab={currentActiveTabForm} steps={steps} />
          {AddGroupForm[currentActiveTabForm]}
        </PaperCustom>
      </div>
    );
  }

  return <NotFound />;
};

export default EditGroup;
