import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';

interface IMinusIcon {
  color?: string;
}

const MinusIcon = ({ color = PRIMARY_COLORS[1000] }: IMinusIcon) => {
  return (
    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MinusIcon;
