import axios from 'axios';

interface IPost {
  url: string;
  payload: Record<string, string>;
}

const getAPIBaseUrl = (): string | undefined => {
  return process.env.REACT_APP_API_BASE_URL;
};

const generateURL = (url: string) => {
  const API_URL = getAPIBaseUrl();
  return API_URL + url;
};

const post = ({ url, payload }: IPost) => {
  // Created this file as on Dev it's getting undefined
  const URL = generateURL(url);
  return axios
    .post(URL, payload)
    .then((response) => response)
    .catch((error: unknown) => {
      throw error;
    });
};
export default post;
