import { ITeamMangUser } from '../../../store/teamManagement/teamManagement.interface';
import ENUMS from '../../../enums';
import getUserData from '../../../helpers/getUserData';

const { userGroups } = ENUMS.teamManagement;

interface IDisableUserRowCheckboxCalcProps {
  userData: ITeamMangUser;
  isloggedInUserOwner: boolean;
  isloggedInUserAdmin: boolean;
  canUserDeactivateAnotherUser: boolean;
}

interface IDisableUserRowCheckboxCalcReturn {
  disabled: boolean;
  tooltipText: string;
}

/**
 * Determines whether the checkbox in the team management users table is clickable by the logged-in user.
 * The checkbox enables the deactivation of user
 * @param  userData container the data of user in row
 * @param  isloggedInUserOwner Whether the logged in user is Owner
 * @param  isloggedInUserAdmin Whether the logged in user is Admin
 * @param  canUserViewAnotherUser Whether the logged in user has view other user profile screen
 * @returns {disabled, tooltipText} true -> disable checkbox, tooltipText -> additional feedback to the user in the form of text.
 */
const disableUserRowCheckboxCalc = ({
  userData,
  isloggedInUserOwner,
  isloggedInUserAdmin,
  canUserDeactivateAnotherUser
}: IDisableUserRowCheckboxCalcProps): IDisableUserRowCheckboxCalcReturn => {
  // User is considered owner, if they have isOwner flag
  const loggedInUserData = getUserData();

  const isRowUserOwner: boolean = userData?.isOwner;
  const isRowUserAdmin: boolean = (userData?.groups || []).indexOf(userGroups.Admin) > -1;

  if (!canUserDeactivateAnotherUser) {
    return { disabled: true, tooltipText: 'You do not have a permission to deactivate a user' };
  }
  if (!userData.isVerified) {
    // disable for user which is not logged in yet.
    return { disabled: true, tooltipText: 'Unverified user cannot be deactivated' };
  }
  if (userData.email === loggedInUserData.email) {
    // disable checkbox for self
    return { disabled: true, tooltipText: 'You cannot deactivate yourself' };
  }
  if (isloggedInUserOwner) {
    if (isRowUserOwner) {
      // Owner cannot deactivate another owner.
      return { disabled: true, tooltipText: 'You cannot deactivate an owner' };
    }
    // Allow for all the users
    return { disabled: false, tooltipText: '' };
  }
  if (isloggedInUserAdmin) {
    if (isRowUserOwner) {
      // Admin cannot deactivate owner.
      return { disabled: true, tooltipText: 'You cannot deactivate an owner' };
    }
    return { disabled: false, tooltipText: '' };
  }
  if (canUserDeactivateAnotherUser) {
    if (isRowUserOwner) {
      return { disabled: true, tooltipText: 'You cannot deactivate an owner' };
    }
    if (isRowUserAdmin) {
      return { disabled: false, tooltipText: '' };
    }
    return { disabled: false, tooltipText: '' };
  }
  return { disabled: true, tooltipText: '' };
};

export default disableUserRowCheckboxCalc;
