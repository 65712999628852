import React, { ReactNode, useRef } from 'react';

import './formContainer.scss';

// import useToastMessagePosition from '../../../hooks/toasts/useToastMessagePosition';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import Typography from '../typography/Typography';

export interface FormContainerProps {
  heading: string;
  children: ReactNode;
}

const FormContainer = ({ heading, children }: FormContainerProps) => {
  const formContainerRef = useRef<HTMLDivElement>(null);

  // --- keeping the commented code for now, once testing is done will remove them from the codebase ---
  // const { toastTop, toastLeft } = useToastMessagePosition({
  //   ref: formContainerRef,
  //   offsetBottom: 40
  // });

  return (
    <React.Fragment>
      <PaperCustom variant="medium" className=" formContainer">
        <div className=" formContainer__card" ref={formContainerRef}>
          <Typography
            variant="subHeading-1"
            size="medium"
            as="h1"
            className="formContainer__card__title"
          >
            {heading}
          </Typography>
          <div className="formContainer__card__children">{children}</div>
        </div>
      </PaperCustom>
      {/* <style>{`.Toastify .Toastify__toast-container{top: ${toastTop}px; left: ${toastLeft}px}`}</style> */}
    </React.Fragment>
  );
};

export default FormContainer;
