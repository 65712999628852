// External imports
import { useNavigate } from 'react-router-dom';

// Internal imports
import GroupSummaryStats, { IGroupSummaryStats } from './GroupSummaryStats';

import Typography from '../../../common/typography/Typography';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import {
  TEAM_MANAGEMENT_TEXT,
  TEAM_MANAGEMENT_TOOLTIP_MESSAGES
} from '../../../../configs/teamManagement';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import { routes } from '../../../../constants/routes';
import IMAGES from '../../../../assets';
import TrashIcon from '../../../common/icons/trashIcon/TrashIcon';
import { ERRORS as ERRORS_COLORS } from '../../../../configs/v3.colors';

interface IGroupsSummaryProps {
  selectedRows: (string | number)[];
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  summaryData: IGroupSummaryStats;
}

const GroupsSummary = ({ selectedRows, setOpenDeleteModal, summaryData }: IGroupsSummaryProps) => {
  const navigate = useNavigate();

  // USER PERMISSIONS START
  const { canUserDeleteGroup, canUserAddGroup } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const disableAddGroup: boolean = !canUserAddGroup || selectedRows?.length > 0;

  return (
    <div className="team-management-groups-table__groups-summary">
      <div className="team-management-groups-table__groups-summary__buttons-row">
        <Typography
          className="team-management-groups-table__groups-summary__buttons-row__label"
          as="p"
          size="medium"
          variant="body-2"
        >
          Summary
        </Typography>
        <div className="team-management-groups-table__groups-summary__buttons-row__buttons">
          {selectedRows.length > 0 && (
            <TooltipCustom
              text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_DELETE_GROUP_ACCESS}
              conditionToDisplay={!canUserDeleteGroup}
            >
              <button
                type="button"
                className="delete-group"
                onClick={() => setOpenDeleteModal(true)}
                disabled={!canUserDeleteGroup}
              >
                <TrashIcon color={ERRORS_COLORS[800]} />
                <Typography variant="body-3" size="medium" as="span">
                  {TEAM_MANAGEMENT_TEXT.DELETE_GROUP}
                </Typography>
              </button>
            </TooltipCustom>
          )}
          <TooltipCustom
            text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_ADD_GROUP_ACCESS}
            conditionToDisplay={!canUserAddGroup}
          >
            <button
              type="button"
              className="add-group apply-loader"
              onClick={() => navigate(routes.ADD_GROUP)}
              disabled={disableAddGroup}
            >
              <img src={IMAGES.addorPlusIcon} alt="add or plus button icon" />
              <Typography variant="body-3" size="medium" as="span">
                {TEAM_MANAGEMENT_TEXT.ADD_GROUP}
              </Typography>
            </button>
          </TooltipCustom>
        </div>
      </div>
      <div className="team-management-groups-table__groups-summary__stats">
        <GroupSummaryStats summary={summaryData} />
      </div>
    </div>
  );
};

export default GroupsSummary;
