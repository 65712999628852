const CloseButton = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ alignSelf: 'center' }}
    >
      <path
        d="M12 4L4 12"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-testid="close-button-path-1"
      />
      <path
        d="M4 4L12 12"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-testid="close-button-path-2"
      />
    </svg>
  );
};

export default CloseButton;
