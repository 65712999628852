import { colorConstants } from '../../../../configs/styleConstants';

interface ILockIconProps {
  strokeColor?: string;
}

const LockIcon = ({ strokeColor = colorConstants.primary }: ILockIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 9.49994C17 8.98812 16.8047 8.4763 16.4142 8.08579C16.0237 7.69526 15.5118 7.5 15 7.5M15 15.5C18.3137 15.5 21 12.8137 21 9.5C21 6.18629 18.3137 3.5 15 3.5C11.6863 3.5 9 6.18629 9 9.5C9 9.77368 9.01832 10.0431 9.05381 10.307C9.11218 10.7412 9.14136 10.9583 9.12172 11.0956C9.10125 11.2387 9.0752 11.3157 9.00469 11.4419C8.937 11.563 8.81771 11.6823 8.57913 11.9209L3.46863 17.0314C3.29568 17.2043 3.2092 17.2908 3.14736 17.3917C3.09253 17.4812 3.05213 17.5787 3.02763 17.6808C3 17.7959 3 17.9182 3 18.1627V19.9C3 20.4601 3 20.7401 3.10899 20.954C3.20487 21.1422 3.35785 21.2951 3.54601 21.391C3.75992 21.5 4.03995 21.5 4.6 21.5H7V19.5H9V17.5H11L12.5791 15.9209C12.8177 15.6823 12.937 15.563 13.0581 15.4953C13.1843 15.4248 13.2613 15.3987 13.4044 15.3783C13.5417 15.3586 13.7588 15.3878 14.193 15.4462C14.4569 15.4817 14.7263 15.5 15 15.5Z"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
