import { useEffect } from 'react';
import { useQuery } from 'react-query';

import API_ENDPOINTS from '../../../constants/api_endpoints';
import getUserData from '../../../helpers/getUserData';
import getWithAuth from '../../../services/getWithAuth';
import { fetchMyProfile, updateProfileLoading, updateSkeletonLoader } from '../../../store/actions';
import { useAppDispatch } from '../../redux/useRedux';

const useFetchMyProfile = () => {
  const dispatch = useAppDispatch();
  const userData = getUserData();

  const { isLoading, data, refetch } = useQuery(
    ['my-profile'],
    async () =>
      await getWithAuth({
        url: API_ENDPOINTS.GET_USER,
        params: { email: userData?.email }
      }),
    {
      staleTime: 0,
      keepPreviousData: true,
      enabled: !!userData?.email,
      refetchOnWindowFocus: false // Prevent refetching when tab/window is focused
    }
  );

  useEffect(() => {
    dispatch(updateSkeletonLoader({ isLoading }));
  }, [isLoading]);

  useEffect(() => {
    dispatch(fetchMyProfile(data?.data.data));
  }, [data]);

  const fetchMyProfileFunc = () => {
    dispatch(updateProfileLoading(true));
    refetch();
  };

  return { fetchMyProfileFunc, refetch, isLoading };
};

export default useFetchMyProfile;
