import moment from 'moment';

import useErrorHandler from '../hooks/error-handler/useErrorHandler';

/**
 * Calculates the difference in hours between the current moment and the given timestamp.
 *
 * @param {string} timestamp - The timestamp to calculate the difference from.
 * @returns {number} - The difference in hours.
 * @throws {Error} - Throws an error if the provided timestamp is not a valid string and return '0'
 * @example
 * const timestamp = '2024-01-16T12:00:00';
 * const hoursDifference = calculateHoursDifference(timestamp);
 * console.log(hoursDifference); // Output: 3 (for example)
 */

//todo create a separate util file date and time and keep all utils there @rushikeshm-scalex
const calculateHoursDifference = (timestamp: string) => {
  try {
    return moment().diff(timestamp, 'hours');
  } catch (error) {
    useErrorHandler({
      error,
      toastId: 'invalid string',
      defaultMessage: 'Please pass a valid timestamp'
    });
    return 0;
  }
};

export default calculateHoursDifference;
