import type { AxiosResponse } from 'axios';

import authInterceptor from './authInterceptors';
import createInstance from './instance';
import methods from './methods';
import type {
  TAddAccountRequest,
  TAddAccountResponse,
  TConfirmAccountRequest,
  TConfirmAccountResponse,
  TDisconnectAccountRequest,
  TDisconnectAccountResponse,
  TDisconnectConfirmAccountRequest,
  TDisconnectConfirmAccountResponse,
  TGetAccountRequest,
  TGetAccountResponse,
  TGetAccountsRequest,
  TGetAccountsResponse,
  TGetEmailReceipeintsRequest,
  TGetEmailReceipeintsResponse
} from './types/cloudAccount.endpoints';

import API_ENDPOINTS from '../constants/api_endpoints';

const CloudAccountServiceInstance = createInstance({
  baseURL: process.env.REACT_APP_CLOUD_BASE_URL || ''
});

authInterceptor(CloudAccountServiceInstance);
methods(CloudAccountServiceInstance);

async function checkAccountExist(request: TGetAccountRequest): Promise<TGetAccountResponse | null> {
  try {
    return await getAccount(request);
  } catch (error) {
    return null;
  }
}

async function addAccount(request: TAddAccountRequest): Promise<TAddAccountResponse> {
  const axiosResponse: AxiosResponse<TAddAccountResponse> = await CloudAccountServiceInstance.post(
    '/accounts/add-account',
    request
  );
  return axiosResponse.data;
}

async function getAccounts(request: TGetAccountsRequest): Promise<TGetAccountsResponse> {
  const axiosResponse: AxiosResponse<TGetAccountsResponse> = await CloudAccountServiceInstance.get(
    '/accounts/list',
    { params: request }
  );
  return axiosResponse.data;
}

async function getAccount(request: TGetAccountRequest): Promise<TGetAccountResponse> {
  const axiosResponse: AxiosResponse<TGetAccountResponse> = await CloudAccountServiceInstance.get(
    '/accounts/get-account',
    { params: request }
  );
  return axiosResponse.data;
}

async function confirmAccount(request: TConfirmAccountRequest): Promise<TConfirmAccountResponse> {
  const axiosResponse: AxiosResponse<TConfirmAccountResponse> =
    await CloudAccountServiceInstance.post('/accounts/confirm-account', request);
  return axiosResponse.data;
}

async function disconnectAccount(
  request: TDisconnectAccountRequest
): Promise<TDisconnectAccountResponse> {
  const disconnectAccountResponse: TDisconnectAccountResponse =
    await CloudAccountServiceInstance.post('/accounts/disconnect-account', request);
  return disconnectAccountResponse;
}

async function disconnectConfirmAccount(
  request: TDisconnectConfirmAccountRequest
): Promise<TDisconnectConfirmAccountResponse> {
  const disconnectAccountResponse: TDisconnectConfirmAccountResponse =
    await CloudAccountServiceInstance.post('/accounts/confirm-disconnect-account', request);
  return disconnectAccountResponse;
}

async function getEmailReceipients(
  request: TGetEmailReceipeintsRequest
): Promise<TGetEmailReceipeintsResponse> {
  const response: AxiosResponse<TGetEmailReceipeintsResponse> =
    await CloudAccountServiceInstance.get(
      API_ENDPOINTS.CLOUD_ACCOUNTS_LIST_ACCOUNT_USER_EMAIL_NOTIFICATION,
      { params: request }
    );
  return response?.data as TGetEmailReceipeintsResponse;
}

interface ICloudAccountService {
  addAccount: (request: TAddAccountRequest) => Promise<TAddAccountResponse>;
  getAccounts: (request: TGetAccountsRequest) => Promise<TGetAccountsResponse>;
  getAccount: (request: TGetAccountRequest) => Promise<TGetAccountResponse>;
  confirmAccount: (request: TConfirmAccountRequest) => Promise<TConfirmAccountResponse>;
  disconnectAccount: (request: TDisconnectAccountRequest) => Promise<TDisconnectAccountResponse>;
  disconnectConfirmAccount: (
    request: TDisconnectConfirmAccountRequest
  ) => Promise<TDisconnectConfirmAccountResponse>;
  getEmailReceipients: (
    request: TGetEmailReceipeintsRequest
  ) => Promise<TGetEmailReceipeintsResponse>;
  checkAccountExist: (request: TGetAccountRequest) => Promise<TGetAccountResponse | null>;
}

const CloudAccountService: ICloudAccountService = {
  addAccount: addAccount,
  getAccounts: getAccounts,
  getAccount: getAccount,
  confirmAccount: confirmAccount,
  disconnectAccount: disconnectAccount,
  disconnectConfirmAccount: disconnectConfirmAccount,
  getEmailReceipients: getEmailReceipients,
  checkAccountExist: checkAccountExist
};

export default CloudAccountService;
