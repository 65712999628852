import React, { ReactElement } from 'react';
import classNames from 'classnames';

import { TypographyProps } from './typography.types';

import './typography.scss';

const htmlElementMap: Record<string, string> = {
  'display-1': 'div',
  'display-2': 'div',
  'display-3': 'div',
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'subHeading-1': 'h4',
  'subHeading-2': 'h5',
  'body-1': 'div',
  'body-2': 'div',
  'body-3': 'div',
  buttonText: 'label',
  caption: 'caption'
};

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body-1',
  size = 'regular',
  className,
  as: Element,
  ...rest
}: TypographyProps) => {
  const elementNameByVariant: string = Element ? Element : htmlElementMap[variant];

  const classes: string = classNames(
    `typography__${variant} typography__${variant}__${size}`,
    className
  );

  const htmlElement: ReactElement = React.createElement(
    elementNameByVariant,
    {
      className: classes,
      ...rest
    },
    children
  );

  return htmlElement;
};

export default Typography;
