import { combineReducers } from 'redux';

import cloudAccountsReducer from './cloudAccounts/cloudAccounts.reducer';
import commonReducer from './common/common.reducer';
import faqReducer from './faq/faq.reducer';
import HeaderReducer from './header/Header.reducer';
import insightsReducer from './insights/insights.reducer';
import MyProfileReducer from './myProfile/myProfile.reducer';
import settingsReducer from './settings/settings.reducer';
import sidebarReducer from './sidebar/Sidebar.reducer';
import teamManagementReducer from './teamManagement/teamManagement.reducer';
import teamManagementGroupReducer from './teamManagement/teamManagementGroup/teamManagementGroup.reducer';
import teamManagementUserReducer from './teamManagement/teamManagementUser/teamManagementUser.reducer';
import userAuthReducer from './userAuth/userAuth.reducer';

const AppReducer = combineReducers({
  Sidebar: sidebarReducer,
  Header: HeaderReducer,
  MyProfile: MyProfileReducer,
  userAuth: userAuthReducer,
  cloudAccounts: cloudAccountsReducer,
  common: commonReducer,
  teamManagement: teamManagementReducer,
  teamManagementUser: teamManagementUserReducer,
  teamManagementGroup: teamManagementGroupReducer,
  insights: insightsReducer,
  faq: faqReducer,
  settings: settingsReducer
});

export type RootState = ReturnType<typeof AppReducer>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  return AppReducer(state, action);
};

export default rootReducer;
