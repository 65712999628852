export const FETCH_CLOUD_ACCOUNTS_DATA = 'FETCH_CLOUD_ACCOUNTS_DATA';
export const FETCH_CLOUD_ACCOUNTS_DETAILS_DATA = 'FETCH_CLOUD_ACCOUNTS_DETAILS__DATA';
export const FETCH_CLOUD_POLICIES = 'FETCH_CLOUD_POLICIES';
export const UPDATE_IF_CLOUD_ACCOUNTS_AVALIABLE = 'UPDATE_IF_CLOUD_ACCOUNTS_AVALIABLE';
export const SET_TAB_INSIDE_ACCOUNT_VIEW = 'SET_TAB_INSIDE_ACCOUNT_VIEW';
export const UPDATE_ACCOUNT_INFO_EDIT_STATE = 'UPDATE_ACCOUNT_INFO_EDIT_STATE';
export const SET_SLACK_CONNECTION_DETAILS = 'SET_SLACK_CONNECTION_DETAILS';
export const GET_SLACK_CHANNEL_LIST = 'GET_SLACK_CHANNEL_LIST';
export const SET_SLACK_REDIRECTION_URL = 'SET_SLACK_REDIRECTION_URL';
export const CLEAR_CLOUD_ACCOUNTS = 'CLEAR_CLOUD_ACCOUNTS';
export const SET_SLACK_CHANNEL_NAME = 'SET_SLACK_CHANNEL_NAME';
export const SET_IS_SLACK_FOUND = 'SET_IS_SLACK_FOUND';
export const CLEAR_SLACK_CONNECTION_DETAILS = 'CLEAR_SLACK_CONNECTION_DETAILS';
export const SET_IS_SLACK_INSTALLED = 'SET_IS_SLACK_INSTALLED';
export const SET_CONNECT_TO_AWS_DATA = 'SET_CONNECT_TO_AWS_DATA';
export const SET_CONNECT_TO_AZURE_DATA = 'SET_CONNECT_TO_AZURE_DATA';

// EMAIL CONFIGURATION
export const CLOUD_ACCOUNTS_UPDATE_EMAIL_LIST = 'CLOUD_ACCOUNTS_UPDATE_EMAIL_LIST';
export const CLOUD_ACCOUNTS_UPDATE_EMAILS_FOR_REMOVAL = 'CLOUD_ACCOUNTS_UPDATE_EMAILS_FOR_REMOVAL';
