import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';
import { routes } from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { setHeading } from '../../store/actions';
import { ITeamManagementUserInitialState } from '../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import MyProfile from '../myProfile/MyProfile';
import NotFound from '../not-found/NotFound';
import getUserData from '../../helpers/getUserData';

const EditUserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = getUserData();

  const { selectedUser, userProfileData } = useAppSelector(
    (store) => store.teamManagementUser
  ) as ITeamManagementUserInitialState;

  const onEditControlButtonClick = () => {
    navigate(routes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL);
  };

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      { text: TEAM_MANAGEMENT_TEXT.USERS, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      { text: userProfileData.firstName + ' ' + userProfileData.lastName, url: '' }
    ]
  });

  useEffect(() => {
    dispatch(setHeading(TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT));
  }, []);

  if (selectedUser) {
    return (
      <>
        <MyProfile
          teamUserprofile={selectedUser !== userData.email}
          onEditControlButtonClick={onEditControlButtonClick}
          teamUserEmail={selectedUser}
        />
      </>
    );
  }
  return <NotFound />;
};

export default EditUserProfile;
