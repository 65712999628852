import React from 'react';

import IMAGES from '../../../assets';
import './noData.scss';
import CustomButton from '../../common/buttons/CustomButton';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import { TEAM_MANAGEMENT_TOOLTIP_MESSAGES } from '../../../configs/teamManagement';
import Typography from '../../common/typography/Typography';

export interface INoDataProps {
  addInGroup: () => void;
}

const NoData = ({ addInGroup }: INoDataProps) => {
  // USER PERMISSIONS START
  const { canUserEditGroup } = useCheckUserPermissions();
  // USER PERMISSIONS END

  return (
    <div className="groupsNoData">
      <div className="wrapper">
        <img src={IMAGES.users} alt="Users" loading="lazy" />
        <Typography as="span" variant="body-3" size="regular" className="description">
          You have successfully created a team. Now you can add users to your team by clicking
          <span className="font-bold"> Add in Team</span> button.
        </Typography>
        <TooltipCustom
          text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_ADD_USER_IN_GROUP_ACCESS}
          conditionToDisplay={!canUserEditGroup}
        >
          <CustomButton
            disabled={!canUserEditGroup}
            text="Add in Team"
            onClick={addInGroup}
            buttonWidth="123px"
          />
        </TooltipCustom>
      </div>
    </div>
  );
};

export default NoData;
