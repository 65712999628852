import axios from 'axios';

import authInterceptor from './authInterceptors';
import { getAPIBaseUrl } from './getAuthTokensAndURL';

const authHttp = axios.create({ baseURL: getAPIBaseUrl() });

// attaches the intercept here.
authInterceptor(authHttp);

export default authHttp;
