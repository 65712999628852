/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { useField } from 'formik';
import { twMerge } from 'tailwind-merge';

import './textAreaInput.scss';

import Typography from '../typography/Typography';

export interface ITextAreaInputProps {
  placeholder: string;
  label: string;
  name: string;

  disabled?: boolean;
  divClassName?: string;
  inputClassName?: string;
  maxLength?: number;
  rows?: number;
  required?: boolean;
}

const TextAreaInput = (props: ITextAreaInputProps) => {
  const [field, meta] = useField(props);

  const {
    placeholder,
    maxLength,
    name,
    label,
    divClassName,
    disabled,
    inputClassName,
    required,
    rows
  } = props;

  const fieldTouchedAndHasError: string | boolean | undefined = meta.error && meta.touched;

  const labelClassName = classNames('common-text-area-input__label ', { label_required: required });

  return (
    <div className={twMerge('common-text-area-input', divClassName)}>
      {required && (
        <Typography variant="body-3" size="regular" as="label" className={labelClassName}>
          {label}
        </Typography>
      )}
      <div className="common-text-area-input__input-field">
        <textarea
          {...field}
          {...props}
          id={name}
          className={twMerge(
            `${disabled ? 'cursor-not-allowed' : ''} input_fields 
            common-text-area-input__input-field__input`,
            inputClassName
          )}
          placeholder={placeholder}
          disabled={disabled}
          data-testid={name}
          maxLength={maxLength}
          rows={rows}
        />
      </div>
      <Typography
        variant="body-3"
        size="regular"
        as="div"
        className={classNames('common-text-area-input__error', {
          'common-text-area-input__error--is-visible': fieldTouchedAndHasError
        })}
      >
        {fieldTouchedAndHasError && meta.error}
      </Typography>
    </div>
  );
};

export default TextAreaInput;
