import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';

type TEditPenIconProps = {
  color?: string;
} & React.HtmlHTMLAttributes<SVGElement>;

const EditPenIcon = ({ color = PRIMARY_COLORS[600], ...props }: TEditPenIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M11 4.50023H6.8C5.11984 4.50023 4.27976 4.50023 3.63803 4.82721C3.07354 5.11483 2.6146 5.57377 2.32698 6.13826C2 6.77999 2 7.62007 2 9.30023V17.7002C2 19.3804 2 20.2205 2.32698 20.8622C2.6146 21.4267 3.07354 21.8856 3.63803 22.1732C4.27976 22.5002 5.11984 22.5002 6.8 22.5002H15.2C16.8802 22.5002 17.7202 22.5002 18.362 22.1732C18.9265 21.8856 19.3854 21.4267 19.673 20.8622C20 20.2205 20 19.3804 20 17.7002V13.5002M7.99997 16.5002H9.67452C10.1637 16.5002 10.4083 16.5002 10.6385 16.445C10.8425 16.396 11.0376 16.3152 11.2166 16.2055C11.4184 16.0818 11.5914 15.9089 11.9373 15.563L21.5 6.00023C22.3284 5.1718 22.3284 3.82865 21.5 3.00023C20.6716 2.1718 19.3284 2.1718 18.5 3.00022L8.93723 12.563C8.59133 12.9089 8.41838 13.0818 8.29469 13.2837C8.18504 13.4626 8.10423 13.6577 8.05523 13.8618C7.99997 14.0919 7.99997 14.3365 7.99997 14.8257V16.5002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditPenIcon;
