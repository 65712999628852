// External imports
import React from 'react';

// Internal imports
import CloudAccountsSummaryStats from './CloudAccountsSummaryStats';
import './cloudAccountsSummary.scss';

const CloudAccountsSummary = () => {
  return (
    <>
      <h2 className="cloud-accounts-summary__title text-base font-medium">Summary</h2>
      <div className="cloud-accounts-summary cloud-accounts-summary__stats">
        <CloudAccountsSummaryStats />
      </div>
    </>
  );
};

export default React.memo(CloudAccountsSummary);
