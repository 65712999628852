import axios, { type AxiosResponse, isAxiosError } from 'axios';

import authInterceptor from './authInterceptors';
import createInstance from './instance';
import methods from './methods';
import { TDeleteProfileResponse, TSupportedFileTypes } from './types/profile.endpoint';

import API_ENDPOINTS from '../constants/api_endpoints';
import imageMimeTypes from '../helpers/json/imageMimeTypes.json';

const ProfileServiceInstance = createInstance({
  baseURL: process.env.REACT_APP_API_BASE_URL || ''
});

authInterceptor(ProfileServiceInstance);
methods(ProfileServiceInstance);

export async function deleteProfilePic(email: string): Promise<TDeleteProfileResponse | undefined> {
  try {
    const res: AxiosResponse<TDeleteProfileResponse> = await ProfileServiceInstance.delete(
      API_ENDPOINTS.DELETE_PROFILE_PIC,
      { params: { email } }
    );
    return res.data;
  } catch (error) {
    if (isAxiosError(error)) Promise.reject(error?.response?.data?.errors);
  }
}

export async function updloadProfilePic(file: File, email: string) {
  try {
    const { name } = file;
    const fileType: TSupportedFileTypes = name.split('.').pop() as TSupportedFileTypes;
    // Get Signed Url from S3 to upload the file
    const presignedUrl = await getPresignedUrl(email);
    // Upload the file to S3 using the signed URL
    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': imageMimeTypes[fileType] || 'image/png'
      }
    });
  } catch (error) {
    if (isAxiosError(error)) Promise.reject(error?.response?.data?.errors);
  }
}

export const getPresignedUrl = async (email: string): Promise<string> => {
  const preSignResponse = await ProfileServiceInstance.get(API_ENDPOINTS.GET_PRESIGNED_URL, {
    params: { email }
  });

  return preSignResponse?.data?.data;
};
