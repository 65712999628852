import { IStep } from '../../components/common/customStepsForm/CustomStepsForm.interface';
import { setCurrentActiveTabForm } from '../../store/actions';
import { useAppDispatch } from '../redux/useRedux';

const useGetAddGroupSteps = (): IStep[] => {
  const dispatch = useAppDispatch();

  const handleClick = (activeTab: number) => {
    dispatch(setCurrentActiveTabForm(activeTab));
  };

  const steps = [
    {
      label: 'Team Details',
      onClick: () => handleClick(0)
    },
    {
      label: 'Permissions',
      onClick: () => handleClick(1)
    },
    {
      label: 'Add Users',
      onClick: () => handleClick(2)
    }
  ];

  return steps;
};

export default useGetAddGroupSteps;
