export const commonToastStyle: React.CSSProperties | undefined = {
  borderRadius: '10px',
  position: 'static',
  textAlign: 'center'
};

export const errorToastStyle: React.CSSProperties | undefined = {
  ...commonToastStyle,
  background: '#FCEFF0',
  color: '#D1293D',
  border: '2px solid #D1293D'
};
export const successToastStyle: React.CSSProperties | undefined = {
  ...commonToastStyle,
  background: '#E1FCEF',
  color: '#128C83',
  border: '2px solid #1AAD95'
};

export const warnToastStyle: React.CSSProperties | undefined = {
  ...commonToastStyle,
  background: '#fffdf5',
  color: '#f1c40f',
  border: '2px solid #f1c40f'
};

export const colorConstants = {
  primary: '#33475B',
  normalText: '#374151',

  // black colors
  black2: '#1d1d1d',
  black3: '#121212',

  //reds
  red: '#f00',
  red1: '#d1293d',

  //greens
  lightGreen1: '#E1FCEF',
  green1: '#128C83',
  green3: '#1aad95',
  green4: '#3a8e03',

  //blues
  blue2: '#2264E5',
  blue3: '#165baa',
  blue4: '#144C90',
  blue5: '#144278',
  blue6: '#257ad2',

  //greys
  lightGrey1: '#c4c4c4',
  lightGrey2: '#e8e8e8',
  grey1: '#6E6E6E',
  grey2: '#E3ECFB',

  black: '#000',

  //white
  white: '#fff',
  white2: '#f2f2f2',

  //oranges
  orange1: '#EC7D17'
};
