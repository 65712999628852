import { ITeamMangSelectedGroup } from '../../../store/teamManagement/teamManagement.interface';

const getSelectedGroup = () => {
  try {
    const selectedGroup = JSON.parse(
      localStorage.getItem('teamMgmtGroupView') as unknown as string
    ) as unknown as ITeamMangSelectedGroup;
    if (selectedGroup) {
      return selectedGroup;
    }
    throw new Error('Group not found', {
      cause: 'teamMgmtGroupView key not found in localstorage'
    });
  } catch (e) {
    return {};
  }
};

export default getSelectedGroup;
