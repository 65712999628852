import { QueryClient } from 'react-query';

import CloudAccountService from '../../services/cloudAccount.service';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../constants/constants';
import {
  TGetAccountsRequest,
  TGetEmailReceipeintsRequest
} from '../../services/types/cloudAccount.endpoints';

export const getFetchAccountQueryKey = (getAccountsRequest: TGetAccountsRequest) => [
  'cloud-accounts',
  {
    ...getAccountsRequest
  }
];

export const getFetchEmailRecipientsQueryKey = (
  getEmailRecipientsRequest: TGetEmailReceipeintsRequest
) => ['email-recipients', { ...getEmailRecipientsRequest }];

export const prefetchEmailReceipeints = (
  queryClient: QueryClient,
  request: TGetEmailReceipeintsRequest
) => {
  queryClient.prefetchQuery(getFetchEmailRecipientsQueryKey(request), () =>
    CloudAccountService.getEmailReceipients(request)
  );
};

export const prefetchAccounts = async (
  queryClient: QueryClient,
  pageNumber = offsetFirst,
  rowsPerPage = ROWS_PER_PAGE_FIRST_ITEM,
  filterAccount = ''
) => {
  const getAccountsRequest: TGetAccountsRequest = {
    limit: rowsPerPage,
    offset: pageNumber,
    ...(filterAccount && { filterAccount: filterAccount })
  };

  queryClient.prefetchQuery(getFetchAccountQueryKey(getAccountsRequest), () =>
    CloudAccountService.getAccounts(getAccountsRequest)
  );

  return null;
};
