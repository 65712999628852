import { Modal, Slider, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { toast } from 'react-toastify';

import getCroppedImg from './cropImage';

import IMAGES from '../../../assets';
import { MY_PROFILE_TEXT } from '../../../configs';
import { errorToastStyle } from '../../../configs/styleConstants';
import { ERRORS as ERRORS_COLORS } from '../../../configs/v3.colors';
import { BYTE_MULTIPLIER, DEFAULT_ZOOM, PROFILE_PIC_LIMIT } from '../../../constants/constants';
import useFetchMyProfile from '../../../hooks/myProfile/fetchMyProfile/useFetchMyProfile';
import { useAppSelector } from '../../../hooks/redux/useRedux';
import { IUserProfileData } from '../../../pages/myProfile/myProfile.interface';
import { deleteProfilePic, updloadProfilePic } from '../../../services/profile.service';
import { TDeleteProfileResponse } from '../../../services/types/profile.endpoint';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import { profileHeadText } from '../../../utils';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import CloseButton from '../../common/close-button/CloseButton';
import TrashIcon from '../../common/icons/trashIcon/TrashIcon';
import './profileImageUpdateModal.scss';

const initialArea = {
  width: 0,
  height: 0,
  x: 0,
  y: 0
};

const initialPoint = { x: 0, y: 0 };

interface IProfileUpdateModalProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  url: string;
  email: string;
}

const ProfileImageUpdateModal = ({ open, setOpen, url, email }: IProfileUpdateModalProps) => {
  //states
  const [crop, setCrop] = useState<Point>(initialPoint);
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(initialArea);
  const [imgUrl, setImgUrl] = useState<string>(url);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  //Redux Store
  const myProfile: IMyProfileState = useAppSelector<IMyProfileState>((state) => state.MyProfile);

  //hooks
  const { refetch: refetchProfile } = useFetchMyProfile();

  const getCroppedImage = async (): Promise<File | null> => {
    return await getCroppedImg(imgUrl, croppedAreaPixels);
  };

  const saveProfilePic = async () => {
    setIsLoading(true);
    try {
      const cImg: File | null = await getCroppedImage();
      if (cImg) {
        await updloadProfilePic(cImg, email);
        refetchProfile();
        setOpen(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area): void => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const selectFile = (): void => {
    inputRef.current?.click();
  };

  const handleFileSelect = (files: FileList | null) => {
    let imgUrl: string = '';
    if (files && files[0]) {
      const { size } = files[0];
      if (size > PROFILE_PIC_LIMIT * BYTE_MULTIPLIER) {
        toast.error(MY_PROFILE_TEXT.PROFILE_PIC_LIMIT, {
          style: errorToastStyle,
          closeButton: <CloseButton color={errorToastStyle?.color as string} />
        });
        return;
      }
      imgUrl = URL.createObjectURL(files[0]);
    }
    setImgUrl(imgUrl);
  };

  const handleRemoveImage = async () => {
    const response: TDeleteProfileResponse | undefined = await deleteProfilePic(email);
    if (response?.message) refetchProfile();
  };

  useEffect(() => {
    setImgUrl(url);
  }, [url]);

  return (
    <Modal open={open} onClose={handleClose}>
      <PaperCustom
        variant="medium"
        className="upload_container p-4 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 w-[90%] lg:w-[450px] max-w-[450px]"
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <h3 className="font-bold text-xl">{imgUrl ? 'Edit Profile Photo' : 'Profile Photo'}</h3>
            <button onClick={handleClose}>
              <img src={IMAGES.XCircle} alt="close_icon" />
            </button>
          </div>
          <p className="text-xs upload_container-description">
            Profile photo size must not exceed 512 KB.
          </p>
          <p className="text-xs upload_container-description">
            Accepted file formats: JPG, JPEG, PNG.
          </p>
          <div className="relative h-[200px]">
            {imgUrl ? (
              <Cropper
                image={imgUrl}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape="round"
              />
            ) : (
              <p className="text-6xl  upload_container-profile-image-text h-48 flex justify-center items-center">
                {profileHeadText({ userProfileData: myProfile as IUserProfileData })}
              </p>
            )}
          </div>
          {imgUrl && (
            <div className="flex items-center">
              <Typography className="pe-4 text-xs">Zoom</Typography>
              <Slider
                className="flex-auto"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom as number)}
              />
            </div>
          )}
          <div className={`flex ${imgUrl ? 'justify-between' : 'justify-center'} items-center`}>
            {imgUrl ? (
              <>
                <div className="flex gap-4">
                  <CustomButton
                    variant={ButtonVariants.SECONDARY}
                    text="Change"
                    onClick={selectFile}
                  />
                  <CustomButton isLoading={isLoading} onClick={saveProfilePic} text="Save" />
                </div>
                <button
                  className="upload_container__icon upload_container__icon--delete"
                  onClick={handleRemoveImage}
                >
                  <TrashIcon color={ERRORS_COLORS[700]} />
                </button>
              </>
            ) : (
              <CustomButton
                text="Upload"
                variant={ButtonVariants.SECONDARY}
                onClick={selectFile}
              ></CustomButton>
            )}
            <input
              ref={inputRef}
              type="file"
              name="profileImg"
              id="profileImg"
              className="hidden"
              accept=".jpg,.jpeg,.png"
              multiple={false}
              onChange={(e) => handleFileSelect(e.target.files)}
            />
          </div>
        </div>
      </PaperCustom>
    </Modal>
  );
};

export default ProfileImageUpdateModal;
