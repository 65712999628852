import authHttpCloudService from './authHttpCloudAccount';

import { IGet } from '../service.interface';

const getWithAuthCloud = async ({ url, params }: IGet) => {
  return authHttpCloudService
    .get(url, { params })
    .then((response) => {
      localStorage.setItem('retryCounter', '0');
      return response;
    })
    .catch((error: unknown) => {
      throw error;
    });
};
export default getWithAuthCloud;
