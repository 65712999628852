import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './connectEmail.scss';

import IMAGES from '../../assets';
import { BUTTON_TEXT, CLOUD_ACCOUNTS_TEXT } from '../../configs';
import { errorToastStyle } from '../../configs/styleConstants';
import API_ENDPOINTS from '../../constants/api_endpoints';
import getUserData from '../../helpers/getUserData';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { useAppDispatch } from '../../hooks/redux/useRedux';
import useGetRequest from '../../hooks/useGetRequest/useGetRequest';
import {
  fetchCloudAccountDetailsRequest,
  setActiveTab,
  setHeading,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../store/actions';
// import Ruler from '../../../common/ruler/Ruler';
import { confirmSuccess } from '../../assets/images';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import CloseButton from '../../components/common/close-button/CloseButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import CloseIconBorder from '../../components/common/icons/closeIconBorder/CloseIconBorder';
import Loader from '../../components/common/loader/loader';
import TooltipCustom from '../../components/common/molecules/tooltipCustom/TooltipCustom';
import NoDataFound from '../../components/common/noDataFound/noDataFound';
import TextTooltipWithEllipsis from '../../components/common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import Typography from '../../components/common/typography/Typography';
import { COMMON_TEXT } from '../../configs/common';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../constants/constants';
import { routes } from '../../constants/routes';
import useLoading from '../../hooks/loading/useLoading';
import postWithCloudAuth from '../../services/cloudAccounts/postWithCloudAuth';
import useEmailListStore from '../../store/cloudAccounts/useEmailListStore';
import { ITeamMangUser } from '../../store/teamManagement/teamManagement.interface';
import { textEllipsis } from '../../utils';
import { getFetchEmailRecipientsQueryKey } from '../../utils/pre-fetchers/prefetchCloudAccounts';

const lazyLoadingIncrement = 10;

const tooltipCharLimitValue: number = 32;
const tooltipUsernameLimitValue: number = 15;
const tooltipSelectedUsersLimitValue: number = 20;

interface IConnectEmailProps {
  onCancel?: () => void;
  isModal?: boolean;
  fetchCloudAccountsEmailList?: () => void;
}

const ConnectEmail = ({
  onCancel,
  isModal = false,
  fetchCloudAccountsEmailList
}: IConnectEmailProps) => {
  const [selectedRecipients, setSelectedRecipients] = useState<ITeamMangUser[]>([]);

  // mail recipients

  const emailRecipientsRef = useRef<HTMLDivElement>(null);
  const [mailRecipients, setMailRecipients] = useState<ITeamMangUser[]>([]);
  const [mailRecipientsCount, setMailRecipientsCount] = useState<number>(0);
  const [mailRecipientsLimit, setMailRecipientsLimit] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>('');
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [openConfirmAddModal, setOpenConfirmAddModal] = useState<boolean>(false);
  const [shouldDisableAdd, setShouldDisableAdd] = useState(
    selectedRecipients.length > 0 ? true : false
  );
  const [noDataFound, setNoDataFound] = useState(false);

  const {
    emailList,
    accountDetails: { accountName }
  } = useEmailListStore();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const userData = getUserData();
  const { loading, startLoading, stopLoading } = useLoading();
  useComponentMount({
    onMountFunc: () => {
      if (!isModal) {
        dispatch(setHeading('Cloud Accounts'));
      }
      dispatch(setActiveTab(2));
    }
  });

  const handleBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        { text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW, url: `/cloud-account/view/${params.id}` },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountName}
            />
          ),
          url: `/cloud-account/view/${params.id}`
        },
        { text: `Email`, url: `/cloud-account/view/email/${params.id}` },
        { text: 'Add Email Recipients', url: '' }
      ])
    );
  };

  const updateEmailBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        { text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW, url: `/cloud-account/view/${params.id}` },
        { text: `Email | ${accountName}`, url: '' }
      ])
    );
  };

  useEffect(() => {
    handleBreadcrumbs();
  }, [accountName]);

  const handleConfirmModal = () => {
    // reset Email List  cache after deletion of email from integration list
    queryClient.resetQueries(
      getFetchEmailRecipientsQueryKey({
        limit: ROWS_PER_PAGE_FIRST_ITEM,
        offset: offsetFirst,
        accountId: params.id || ''
      })
    );
    if (fetchCloudAccountsEmailList) {
      fetchCloudAccountsEmailList();
      updateEmailBreadcrumbs();
    }
    if (onCancel) {
      onCancel();
    }
    setOpenConfirmAddModal(false);
    navigate(`/cloud-account/view/email/${params?.id}`);
  };

  const handleCancel = () => {
    if (onCancel) {
      updateEmailBreadcrumbs();
      onCancel();
      return;
    }

    navigate(`/cloud-account/view/email/${params.id}`);
  };

  const { setUseGetRequestCounter } = useGetRequest({
    url: API_ENDPOINTS.CLOUD_TEAM_MGMT_LIST_USERS_EMAIL_NOTIFICATION,
    cloudAuth: true,
    params: {
      limit: mailRecipientsLimit,
      offset: 1,
      includeUnverified: false,
      ...(searchString && { filterUser: searchString })
    },
    callback: ({ response }) => {
      const users =
        response.data.users?.map((user: ITeamMangUser) => ({ ...user, checked: false })) || [];
      const count = response.data.count || 0;
      setApiCalled(false);
      setMailRecipientsCount(count);
      setMailRecipients(users);
      setNoDataFound(users.length === 0);
    },
    delay: 500
  });

  const handleAddEmails = async () => {
    if (selectedRecipients.length <= 0) {
      toast.error(CLOUD_ACCOUNTS_TEXT.SELECT_USERS, {
        style: errorToastStyle,
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
    } else {
      const emails = selectedRecipients.map((recipient) => recipient.email);
      startLoading();
      try {
        const response = await postWithCloudAuth({
          url: API_ENDPOINTS.CLOUD_ACCOUNTS_ADD_USER_EMAIL_NOTIFICATION,
          payload: { accountId: params.id, emails, createdBy: userData.email }
        });

        if (response) {
          setOpenConfirmAddModal(true);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'addEmailsFail',
          defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
        });
      } finally {
        stopLoading();
      }
    }
  };

  const handelSelect = (user: ITeamMangUser) => {
    const selectedUsers = mailRecipients.map((recipient) => {
      recipient.email === user.email && !user.checked
        ? (recipient.checked = true)
        : (recipient.checked = false);
      return recipient;
    });
    setMailRecipients(selectedUsers);
    setSelectedRecipients((prev) => {
      const newList = JSON.parse(JSON.stringify(prev));
      const indexInSelectedUsers = selectedRecipients.findIndex(
        (selectedUser) => selectedUser.email === user.email
      );
      if (indexInSelectedUsers > -1) {
        newList.splice(indexInSelectedUsers, 1);
      } else {
        newList.push(user);
      }
      return newList;
    });
  };

  const handleDeselect = (user: ITeamMangUser) => {
    const selectedUsers = mailRecipients.map((recipient) => {
      if (recipient.email === user.email) {
        recipient.checked = false;
      }
      return recipient;
    });
    setMailRecipients(selectedUsers);
    setSelectedRecipients((prev) => {
      const newList = JSON.parse(JSON.stringify(prev));
      const indexInSelectedUsers = selectedRecipients.findIndex(
        (selectedUser) => selectedUser.email === user.email
      );
      if (indexInSelectedUsers > -1) {
        newList.splice(indexInSelectedUsers, 1);
        return newList;
      }
      return prev;
    });
  };

  useEffect(() => {
    // To refetch mail recipients
    setApiCalled(true);
    setUseGetRequestCounter((prev) => prev + 1);
  }, [searchString, mailRecipientsLimit]);

  // Side effect to keep the button enable/disabled
  useEffect(() => {
    if (selectedRecipients.length > 0) {
      setShouldDisableAdd(false);
    } else {
      setShouldDisableAdd(true);
    }
  }, [selectedRecipients.length]);

  const handleScroll = () => {
    // Implement lazy loading of users
    const element = emailRecipientsRef.current;
    if (element) {
      const height = element.getBoundingClientRect().height; // render height of element
      const scrollTop = element.scrollTop; // The amount of pixels that element has been scrolled
      const scrollHeight = element.scrollHeight; // free height of element, the height it would take when height is not constrained.
      const threshold = 0.7; // 70% threshold to have a seamless UX
      if (
        scrollTop + height >= threshold * scrollHeight &&
        mailRecipients.length <= mailRecipientsLimit &&
        mailRecipients.length >= mailRecipientsLimit - lazyLoadingIncrement &&
        mailRecipientsCount > mailRecipients.length &&
        !apiCalled
      ) {
        setMailRecipientsLimit((prev) => prev + lazyLoadingIncrement);
      }
    }
  };
  useEffect(() => {
    if (!accountName) {
      dispatch(
        fetchCloudAccountDetailsRequest({
          accountId: params.id || '',
          startLoading,
          stopLoading: () => {
            stopLoading();
            dispatch(updateSkeletonLoader({ isLoading: false }));
          }
        })
      );
    }
  }, [params.id]);

  const formattedCount =
    selectedRecipients.length < 10 ? `0${selectedRecipients.length}` : selectedRecipients.length;

  return (
    <PaperCustom className="cloud-accounts-connect-email">
      <div className="cloud-accounts-connect-email__header">
        <div className="cloud-accounts-connect-email__header__heading">
          <p className="cloud-accounts-connect-email__header__heading__title">
            <img src={IMAGES.emailColor} />
            <Typography variant="body-2" size="semiBold" as="span">
              Integrate Email Recipients
            </Typography>
          </p>
          <Typography
            variant="body-3"
            size="regular"
            className="cloud-accounts-connect-email__header__heading__sub-title"
          >
            Add Email notification recipient(s)
          </Typography>
        </div>
        {isModal && (
          <img
            className="cloud-accounts-connect-email__header__close"
            src={IMAGES.closeIcon}
            alt="close modal"
            onClick={handleCancel}
          />
        )}
      </div>
      <div className="cloud-accounts-connect-email__users">
        <div className="cloud-accounts-connect-email__users__select">
          <Typography
            variant="body-2"
            size="medium"
            className="cloud-accounts-connect-email__users__select__heading"
          >
            Select Mail Recipients
          </Typography>
          <div className="cloud-accounts-connect-email__users__select__heading__searchDiv">
            <img src={IMAGES.searchIcon} alt="search" />
            <input
              type="search"
              className="cloud-accounts-connect-email__users__select__heading__searchDiv__search-input"
              placeholder="Search Name"
              onChange={(e) => {
                setMailRecipientsLimit(10);
                setSearchString(e.target.value);
              }}
            />
          </div>
          <Typography
            variant="body-3"
            size="regular"
            className="cloud-accounts-connect-email__header__heading__sub-title"
          >
            The email addresses displayed below are of users who are registered in{' '}
            <CloudInSpotName />
          </Typography>

          <div
            className="cloud-accounts-connect-email__users__select__list"
            ref={emailRecipientsRef}
            onScroll={handleScroll}
          >
            {noDataFound ? (
              <NoDataFound />
            ) : (
              mailRecipients.map((user, index) => {
                const fullName: string = `${user.firstName} ${user.lastName}`;

                const showTooltip: boolean =
                  fullName?.length + user.jobTitle?.length > tooltipCharLimitValue ||
                  user.email?.length > tooltipCharLimitValue;
                return (
                  <div
                    key={index}
                    className={classNames(
                      'cloud-accounts-connect-email__users__select__list__user',
                      {
                        'cloud-accounts-connect-email__users__select__list__selectedUser':
                          user.checked
                      }
                    )}
                  >
                    <div className="cloud-accounts-connect-email__users__select__list__user__userView">
                      <TooltipCustom
                        text={
                          <div className="user-tooltip">
                            <p className="text-left">{fullName}</p>
                            <p className="text-left">{user.email}</p>
                          </div>
                        }
                        containerProps={{}}
                        conditionToDisplay={showTooltip}
                        limitWidth={false}
                      >
                        <div className="cloud-accounts-connect-email__users__select__list__user__userView__userDetails">
                          <Typography
                            variant="body-3"
                            size="regular"
                            className="cloud-accounts-connect-email__users__select__list__user__userView__userDetails__userDetailsText fullName"
                          >
                            {textEllipsis({ text: fullName, charLimit: tooltipUsernameLimitValue })}
                          </Typography>
                          <Typography
                            variant="body-3"
                            size="regular"
                            className="cloud-accounts-connect-email__users__select__list__user__userView__userDetails__userDetailsText"
                          ></Typography>
                        </div>
                      </TooltipCustom>

                      <Typography
                        variant="caption"
                        size="regular"
                        className="cloud-accounts-connect-email__users__select__list__user__userView__email user_email"
                      >
                        {textEllipsis({ text: user.email, charLimit: tooltipCharLimitValue })}
                      </Typography>
                    </div>
                    <div className="cloud-accounts-connect-email__users__select__list__user__userView__checkboxWrapper">
                      <input
                        className="cloud-accounts-connect-email__users__select__list__user__userView__checkboxWrapper__checkbox"
                        type="checkbox"
                        id={user.email}
                        value={user.email}
                        disabled={
                          emailList.findIndex((email) => email.notificationEmail === user.email) >
                          -1
                        }
                        checked={
                          // Also need to check whether the user is already added in the notification list
                          emailList.findIndex((email) => email.notificationEmail === user.email) >
                            -1 ||
                          selectedRecipients.findIndex(
                            (selectedUser) => selectedUser.email === user.email
                          ) > -1
                        }
                        onChange={() => handelSelect(user)}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="border border-[#E3ECFB]"></div>
        <div className="cloud-accounts-connect-email__users__selected-users">
          <div className="cloud-accounts-connect-email__users__selected-users__headerText">
            <Typography
              variant="body-2"
              size="medium"
              className="cloud-accounts-connect-email__users__select__heading"
            >
              Selected Recipients
            </Typography>
            <Typography variant="body-2" size="regular">
              ({selectedRecipients.length}
              {selectedRecipients.length ? ' user selected' : ' selected'})
            </Typography>
          </div>
          {/* Selected email list */}
          <div className="cloud-accounts-connect-email__users__selected-users__list">
            {selectedRecipients.map((user) => {
              const fullName: string = `${user.firstName} ${user.lastName}`;
              const showTooltip: boolean =
                fullName?.length + user.jobTitle?.length >= 20 ||
                user.email?.length >= tooltipCharLimitValue;
              return (
                <TooltipCustom
                  text={
                    <div className="user-tooltip">
                      <p className="text-left">{fullName}</p>
                      <p className="text-left">{user.email}</p>
                    </div>
                  }
                  key={user.email}
                  containerProps={{
                    className: 'cloud-accounts-connect-email__users__selected-users__list__user'
                  }}
                  limitWidth={false}
                  conditionToDisplay={showTooltip}
                >
                  <div className="cloud-accounts-connect-email__users__selected-users__list__user__userView">
                    <div className="cloud-accounts-connect-email__users__selected-users__list__user__userView__userDetails">
                      <Typography
                        variant="body-3"
                        size="regular"
                        className="cloud-accounts-connect-email__users__selected-users__list__user__userView__userDetails__userDetailsText cloud-accounts-connect-email__users__selected-users__list__user__userView__userDetails__name"
                      >
                        {textEllipsis({
                          text: fullName,
                          charLimit: 15
                        })}
                      </Typography>
                      <Typography
                        variant="body-3"
                        size="regular"
                        className="cloud-accounts-connect-email__users__selected-users__list__user__userView__userDetails__userDetailsText"
                      ></Typography>
                    </div>
                    <Typography
                      variant="caption"
                      size="regular"
                      className="cloud-accounts-connect-email__users__selected-users__list__user__userView__email"
                    >
                      {textEllipsis({
                        text: user.email,
                        charLimit: tooltipSelectedUsersLimitValue
                      })}
                    </Typography>
                  </div>
                  <div onClick={() => handleDeselect(user)}>
                    <CloseIconBorder color="#535353" height={24} width={24} />
                  </div>
                </TooltipCustom>
              );
            })}
          </div>
        </div>
      </div>
      <div className="cloud-accounts-connect-email__buttons">
        <CustomButton
          variant={ButtonVariants.SECONDARY}
          text={BUTTON_TEXT.CANCEL}
          type="button"
          onClick={handleCancel}
          buttonWidth="198px"
        />
        <CustomButton
          variant={ButtonVariants.PRIMARY}
          text={shouldDisableAdd ? BUTTON_TEXT.ADD : loading ? <Loader /> : BUTTON_TEXT.ADD}
          type="button"
          onClick={handleAddEmails}
          buttonWidth="198px"
          disabled={shouldDisableAdd}
          className={classNames({
            'cloud-accounts-connect-email__buttons__isDisabled': shouldDisableAdd
          })}
        />
      </div>
      <ConfirmModal
        title="Recipients Added!"
        description={`Added ${formattedCount} Email Recipient (s) successfully.`}
        descriptionWidth="88%"
        confirmButtonText="OKAY"
        open={openConfirmAddModal}
        setOpen={setOpenConfirmAddModal}
        image={confirmSuccess}
        handleOkClick={handleConfirmModal}
        handleClose={handleConfirmModal}
      />
    </PaperCustom>
  );
};

export default ConnectEmail;
