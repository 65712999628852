import ENUMS from '../../../../enums';
import { ITeamMangUser } from '../../../../store/teamManagement/teamManagement.interface';
import getUserData from '../../../../helpers/getUserData';

const { userGroups } = ENUMS.teamManagement;

interface IDisableGroupUserRowCheckboxCalcProps {
  userData: ITeamMangUser;
  groupname: string;
  isloggedInUserOwner: boolean;
  isloggedInUserAdmin: boolean;
  canUserRemoveOtherUsersFromGroup: boolean;
}

interface IDisableGroupUserRowCheckboxCalcReturn {
  disabled: boolean;
  tooltipText: string;
}

/**
 * Determines whether the checkbox in the team management group users table is clickable by the logged-in user.
 * The checkbox enables the deactivation of user
 * @param  userData container the data of user in row
 * @param  groupname Name of the group
 * @param  isloggedInUserOwner Whether the logged in user is Owner
 * @param  isloggedInUserAdmin Whether the logged in user is Admin
 * @param  canUserRemoveOtherUsersFromGroup Whether the logged in user can remove another user from the group
 * @returns {disabled, tooltipText} true -> disable checkbox, tooltipText -> additional feedback to the user in the form of text.
 */
const disableGroupUserRowCheckboxCalc = ({
  userData,
  groupname,
  isloggedInUserOwner,
  isloggedInUserAdmin,
  canUserRemoveOtherUsersFromGroup
}: IDisableGroupUserRowCheckboxCalcProps): IDisableGroupUserRowCheckboxCalcReturn => {
  // User is considered owner, if they have isOwner flag
  const loggedInUserData = getUserData();

  if (userData.email === loggedInUserData.email && groupname === userGroups.Admin) {
    // disable checkbox for self
    return { disabled: false, tooltipText: '' };
  }
  if (isloggedInUserOwner) {
    // Allow for all the users
    return { disabled: false, tooltipText: '' };
  }
  if (isloggedInUserAdmin) {
    return { disabled: false, tooltipText: '' };
  }
  if (canUserRemoveOtherUsersFromGroup) {
    return { disabled: false, tooltipText: '' };
  }
  return { disabled: true, tooltipText: '' };
};

export default disableGroupUserRowCheckboxCalc;
