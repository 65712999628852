import { ICloudAccountsEmailList } from '../../store/cloudAccounts/cloudAccounts.interface';

export type TAddAccountRequest = {
  createdBy: string;
  accountId: string;
  accountName: string;
  budget: string;
  CSP: string;
  tenantId: string | null;
  clientId: string | null;
  clientSecret: string | null;
};

export type TAddAccountResponse = {
  message: string;
  redirectUrl: string;
  account: {
    id: number;
    organizationId: number;
    accountId: string;
    accountName: string;
    budget: string;
    status: string;
    createdBy: number;
    parentAccountId: number | null;
    CSP: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
    externalId: string;
  };
};

export type TConfirmAccountRequest = {
  accountId: string;
};

export type TConfirmAccountResponse = {
  message: string;
  account: {
    id: number;
    organizationId: number;
    accountId: string;
    accountName: string;
    externalId: string;
    budget: string;
    stackId: null;
    status: string;
    createdBy: number;
    parentAccountId: number | null;
    CSP: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
  };
};

export type TGetAccountsRequest = { limit: number; offset: number; filterAccount?: string };

// TODO: @arman-scalex Add props for TGetAccountsResponse

export class TAccount {
  id: number;
  organizationId: number;
  accountId: string;
  accountName: string;
  budget: number;
  status: string;
  createdBy: number;
  parentAccountId: null;
  CSP: string;
  metadata: null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  slackAccountMapping: null;
  msTeamsInstallation: null;
  userAccountNotification: [];
  constructor() {
    this.id = 0;
    this.organizationId = 0;
    this.accountId = '';
    this.accountName = '';
    this.budget = 0;
    this.status = '';
    this.createdBy = 0;
    this.parentAccountId = null;
    this.CSP = '';
    this.metadata = null;
    this.createdAt = '';
    this.updatedAt = '';
    this.deletedAt = null;
    this.slackAccountMapping = null;
    this.msTeamsInstallation = null;
    this.userAccountNotification = [];
  }
}

export type TGetAccountsResponse = {
  message: string;
  count: number;
  accountConnectedThisMonth: number;
  accountDisconnectedThisMonth: number;
  totalActiveAccounts: number;
  totalInprogressAccounts: number;
  totalFailedUnreachableAccounts: number;
  accounts: TAccount[];
};

export type TGetAccountRequest = {
  accountId: string | number;
};

export type TGetAccountResponse = {
  message: string | number;
  account: {
    id: number;
    organizationId: number;
    accountId: string;
    accountName: string;
    budget: number;
    status: string;
    createdBy: number;
    parentAccountId: null;
    CSP: string;
    metadata: {
      tenantId: string;
      clientId: string;
      clientSecret: string;
    };
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
    accountConnectedBy: {
      id: number;
      organizationId: number;
      email: string;
      firstName: string;
      lastName: string;
      jobTitle: string;
      isVerified: boolean;
      permissions: null;
      isOwner: boolean;
      createdAt: string;
      updatedAt: string;
      deletedAt: null;
    };
  };
};

export type TDisconnectAccountRequest = {
  accountId: string;
};

export type TDisconnectAccountResponse = {
  redirectUrl: string;
  account: {
    id: number;
    organizationId: number;
    accountId: string;
    accountName: string;
    externalId: string;
    budget: number;
    stackId: string;
    status: string;
    createdBy: number;
    parentAccountId: null;
    CSP: string;
    metadata: null;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
  };
};

export type TDisconnectConfirmAccountRequest = {
  accountId: string;
};

export type TDisconnectConfirmAccountResponse = {
  message: string;
  account: {
    id: number;
    organizationId: number;
    accountId: string;
    accountName: string;
    budget: number;
    status: string;
    createdBy: number;
    parentAccountId: null;
    CSP: string;
    metadata: {
      tenantId: string;
    };
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
  };
};

export type TGetList = {
  offset: number;
  limit: number;
};

export type TGetEmailReceipeintsRequest = {
  accountId: string;
} & TGetList;

export type TEmailNotificationCreatedBy = {
  firstName: string;
  lastName: string;
};

export type TEMailNotification = {
  accountId: number;
  userId: number;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  notificationEmail: string;
  notificationEmailCreatedAt: string;
  emailNotificationCreatedBy: TEmailNotificationCreatedBy;
};

export type TGetEmailReceipeintsResponse = {
  account: TAccount;
  count: number;
  emailNotifications: ICloudAccountsEmailList[];
};
