// External imports
import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';

// Internal imports
import './arrowShaftIcon.scss';

// Interfaces
interface IArrowShaftIcon {
  colorOne?: string;
  colorTwo?: string;
  direction: 'top' | 'right' | 'down' | 'left';
}

const ArrowShaftIcon = ({
  colorOne = BLACK_COLORS[1000],
  colorTwo = 'black',
  direction = 'right'
}: IArrowShaftIcon) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`atom-arrow-shaft-rotate--${direction}`}
    >
      <path
        d="M15.0005 9H3.00049M3.00049 9L7.50049 13.5M3.00049 9L7.50049 4.5"
        stroke={colorOne}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0005 9H3.00049M3.00049 9L7.50049 13.5M3.00049 9L7.50049 4.5"
        stroke={colorTwo}
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0005 9H3.00049M3.00049 9L7.50049 13.5M3.00049 9L7.50049 4.5"
        stroke={colorTwo}
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0005 9H3.00049M3.00049 9L7.50049 13.5M3.00049 9L7.50049 4.5"
        stroke={colorTwo}
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowShaftIcon;
