import * as Yup from 'yup';

import { EMAIL_REGX } from '../../constants/regex';

const SignInValidationSchema = Yup.object().shape({
  org_id: Yup.string().trim().required('Please enter the organization ID'),
  email: Yup.string()
    .trim()
    .required('Please enter an email address')
    .matches(EMAIL_REGX, 'Please enter valid email address'),
  password: Yup.string().required('Enter the password')
});

export default SignInValidationSchema;
