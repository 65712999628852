import classNames from 'classnames';

import Typography from '../../../common/typography/Typography';

export interface IValue {
  text?: string | number;
  className?: string;
}
const Value = ({ text, className: valueClasses }: IValue) => {
  const classes = classNames('value', valueClasses);
  return (
    <Typography variant="body-3" size="regular" className={classes}>
      {text}
    </Typography>
  );
};

export default Value;
