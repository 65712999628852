import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { permissions } from '@cloudinspot/cis-common-constants';

import './addUserPermissions.scss';
import { IAddUserPermissions } from './addUser.interface';

import Permissions from '../permissions/Permissions';
import { BUTTON_TEXT } from '../../../configs';
import { useAppDispatch } from '../../../hooks/redux/useRedux';
import {
  addUserStep1,
  addUserStep2,
  setCurrentActiveTabForm,
  setHeading
} from '../../../store/actions';
import { routes } from '../../../constants/routes';
import { IUserPermissionsList } from '../../../store/teamManagement/teamManagement.interface';
import useComponentMount from '../../../hooks/componentMount/useComponentMount';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import Typography from '../../common/typography/Typography';
import ArrowShaftIcon from '../../common/icons/arrowShaftIcon/ArrowShaftIcon';
import CustomButton from '../../common/buttons/CustomButton';

const AddUserPermissions = ({
  addUserSelectedPermissions,
  setAddUserSelectedPermissions
}: IAddUserPermissions) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const permissionsRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(1));
  };

  const goToTeamManagement = () => {
    // Take the user back to team management screen
    dispatch(addUserStep1({ firstName: '', lastName: '' }));
    dispatch(addUserStep2({ email: '', jobTitle: '' }));
    navigate(routes.TEAM_MANAGEMENT_USERS_TABLE);
  };

  const handleNext = () => {
    dispatch(setCurrentActiveTabForm(3));
  };

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(TEAM_MANAGEMENT_TEXT.ADD_USER));
    }
  });

  return (
    <div className="permissions-container">
      <div className="add-user-review-container__go-back" onClick={handleGoBack}>
        <ArrowShaftIcon direction="left" />
        <Typography variant="caption" size="semiBold">
          {BUTTON_TEXT.BACK}
        </Typography>
      </div>
      <Typography variant="body-2" size="medium" as="p" className="permissions-container__heading">
        {TEAM_MANAGEMENT_TEXT.PERMISSIONS}
      </Typography>
      <div className="permissions-container__permissions" ref={permissionsRef}>
        <Permissions
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userPermissions={permissions as unknown as IUserPermissionsList[]}
          selectedPermissions={addUserSelectedPermissions}
          setSelectedPermissions={setAddUserSelectedPermissions}
        />
      </div>
      <div className="permissions-container__navigation">
        <div className="permissions-container__navigation__backward">
          <CustomButton className="cancel" onClick={goToTeamManagement} text={BUTTON_TEXT.CANCEL} />
        </div>
        <div className="permissions-container__navigation__forward">
          <CustomButton className="next" onClick={handleNext} text={BUTTON_TEXT.REVIEW} />
        </div>
      </div>
    </div>
  );
};

export default AddUserPermissions;
