import { IPost } from './service.interface';
import authHttp from './authHttp';

const postWithAuth = async ({ url, payload }: IPost) => {
  //prev We used a axios directly from library
  //now used a *authHttp* a instance of axios so no need to send headers from here as this is taken care by authHttp

  return authHttp
    .post(url, payload)
    .then((response) => {
      localStorage.setItem('retryCounter', '0');
      return response;
    })
    .catch((error: unknown) => {
      throw error;
    });
};

export default postWithAuth;
