import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import WelcomeMessageCloudAccounts from '../../components/cloudAccounts/WelcomeMessageCloudAccounts';
import Conditional from '../../components/common/conditionalComponent/Conditional';
import { ROWS_PER_PAGE_FIRST_ITEM } from '../../constants/constants';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch } from '../../hooks/redux/useRedux';
import CloudAccountService from '../../services/cloudAccount.service';
import { TGetAccountsResponse } from '../../services/types/cloudAccount.endpoints';
import { setActiveTab, setHeading, updateSkeletonLoader } from '../../store/actions';
import useAccountSummaryStore from '../../store/cloudAccounts/useAccountSummaryStore';
import { getFetchAccountQueryKey } from '../../utils/pre-fetchers/prefetchCloudAccounts';
import CloudAccounts from '../cloudAccounts/CloudAccounts';

const CloudAccountsView = () => {
  const [filterAccount, setFilterAccount] = useState('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_FIRST_ITEM);
  const dispatch = useAppDispatch();
  const CLOUD_ACCOUNTS_REFETCH_INTERVAL_IN_MS = 30000;

  const { hasAccounts, setAccountSummary, setHasAccounts } = useAccountSummaryStore();

  const { isLoading, data, refetch } = useQuery<TGetAccountsResponse>(
    getFetchAccountQueryKey({
      limit: rowsPerPage,
      offset: pageNumber,
      ...(filterAccount && { filterAccount })
    }),
    () =>
      CloudAccountService.getAccounts({
        limit: rowsPerPage,
        offset: pageNumber,
        ...(filterAccount && { filterAccount })
      }),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchInterval: CLOUD_ACCOUNTS_REFETCH_INTERVAL_IN_MS
    }
  );

  useEffect(() => {
    if (data) {
      !filterAccount && setHasAccounts(!!data.count);
      setAccountSummary({
        accountConnectedThisMonth: data.accountConnectedThisMonth,
        accountDisconnectedThisMonth: data.accountDisconnectedThisMonth,
        totalActiveAccounts: data.totalActiveAccounts,
        totalInprogressAccounts: data.totalInprogressAccounts,
        totalFailedUnreachableAccounts: data.totalFailedUnreachableAccounts
      });
    }
  }, [data, data?.accounts, setAccountSummary]);

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading('Cloud Accounts'));
      dispatch(setActiveTab(2));
      refetch();
    },
    onUnMountFunc: () => {
      dispatch(updateSkeletonLoader({ isLoading: true }));
      // This loader must be turned off network request in the respective components.
    }
  });

  return (
    <Conditional
      condition={hasAccounts || false}
      whenTrue={
        <CloudAccounts
          data={data}
          refetch={refetch}
          isLoading={isLoading}
          filterAccount={filterAccount}
          setFilterAccount={setFilterAccount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      }
      whenFalse={<WelcomeMessageCloudAccounts />}
    />
  );
};
export default CloudAccountsView;
