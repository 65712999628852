/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './accountInfo.scss';

import { getDateFromString } from './../../../../utils/dateAndHourFormatting/dateAndHourFormatting';

import IMAGES from '../../../../assets';
import { confirmSuccess } from '../../../../assets/images';
import { CLOUD_ACCOUNTS_TEXT } from '../../../../configs';
import {
  ACCOUNT_STATUS_MSG,
  AZURE_ACCOUNT_STATUS_MSG,
  CLOUD_ACCOUNTS_TOOLTIP_MESSAGES
} from '../../../../configs/cloudAccounts';
import { COMMON_TEXT } from '../../../../configs/common';
import { colorConstants } from '../../../../configs/styleConstants';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../../../constants/constants';
import { DECIMAL_NUMBER } from '../../../../constants/regex';
import { routes } from '../../../../constants/routes';
import ENUMS from '../../../../enums';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useComponentMount from '../../../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import postWithCloudAuth from '../../../../services/cloudAccounts/postWithCloudAuth';
import {
  fetchCloudAccountDetailsRequest,
  setActiveTab,
  setHeading,
  updateAccountInfoEditState,
  updateBreadcrumbs
} from '../../../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import useOrganizationStore from '../../../../store/orgnization/useOrgnizationStore';
import { Text, TextType } from '../../../../utils/Text';
import calculateHoursDifference from '../../../../utils/calculateHoursDifference';
import { getFetchAccountQueryKey } from '../../../../utils/pre-fetchers/prefetchCloudAccounts';
import CloudInSpotName from '../../../cloudInSpotName/CloudInSpotName';
import TextTooltipWithEllipsis from '../../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import AccountStatusMessage from '../../../common/accountStatus/AccountStatusMessage';
import { EAccountStatusVariants } from '../../../common/accountStatus/accountStatusMessage.interface';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import DeleteModal from '../../../common/deleteModal/DeleteModal';
import Loader from '../../../common/loader/loader';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import TableColumnStatus from '../../../common/tableColumnStatus/TableColumnStatus';
import {
  ITableColumnStatus,
  TTableStatus
} from '../../../common/tableColumnStatus/tableColumnStatus.interface';
import TextInput from '../../../common/textInput/TextInput';
import Typography from '../../../common/typography/Typography';
import { EditAccountInfoValidationSchema } from '../../CloudAccount.validationSchema';
import CloudProviderLogo from '../../CloudProviderLogo/CloudProviderLogo';
import DisconnectAzure from '../../azure/disconnectAzure';
import { IParams, TTableStatusText } from '../../cloudAccounts.interface';
import { hoursAfterWhichShowReconnectButton } from '../../constants';
import Key from '../slack/Key';
import Value from '../slack/Value';

const { CloudServiceProvider } = ENUMS.cloudAccounts;

const { TableStatusTooltipText, CloudAccountsTableStatus, CloudAccountsStatuses } =
  ENUMS.cloudAccounts;

export interface IAccountInfoInitialValues {
  accountName: string;
  budget: number;
}

const AccountInfo = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params: IParams = useParams() as unknown as IParams;

  const accountDetails = (
    useAppSelector((store) => store.cloudAccounts) as ICloudAccountsReducerInitialState
  ).accountDetails;

  const { organization } = useOrganizationStore();

  const {
    canUserEditCloudAccount,
    canUserDisconnectCloudAccount,
    canUserResumeCloudAccount,
    canUserPauseCloudAccount,
    canUserReconnectCloudAccount
  } = useCheckUserPermissions();

  const { isEditAccountInfoActive } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;
  let accountID = params.id;
  if (params && params.id) {
    accountID = params.id;
  }

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  //pause modal
  const [isPauseModalOpen, setIsPauseModalOpen] = useState<boolean>(false);
  const [isPauseSuccessModalOpen, setIsPauseSuccessModalOpen] = useState<boolean>(false);
  //resume modal
  const [isResumeModalOpen, setIsResumeModalOpen] = useState<boolean>(false);
  const [isResumeSuccessModalOpen, setIsResumeSuccessModalOpen] = useState<boolean>(false);
  //edit account info
  const [isAccountInfoSuccessModalOpen, setIsAccountInfoSuccessModalOpen] =
    useState<boolean>(false);

  const [openDisconnectAccountModal, setOpenDisconnectAccountModal] = useState<boolean>(false);

  //loading state
  const { loading, startLoading, stopLoading } = useLoading();
  const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const accountId: string = accountID;

  const isAccountInactive = useMemo(() => {
    return accountDetails.status === CloudAccountsStatuses.Inactive;
  }, [accountDetails.status]);

  const status = accountDetails.status as TTableStatusText;

  const editAccountInfoState = useMemo(() => {
    return {
      accountName: accountDetails.accountName,
      budget: accountDetails.budget
    };
  }, [accountDetails.accountName, accountDetails.budget]);

  const statusButtondata: ITableColumnStatus = {
    type: status.toLowerCase() as TTableStatus,
    text: CloudAccountsTableStatus[status],
    tooltipText: TableStatusTooltipText[status],
    showTooltip: showTooltip,
    openTooltip: () => setShowTooltip(true),
    closeTooltip: () => setShowTooltip(false),
    toggleTooltip: () => setShowTooltip((prev) => !prev)
  };

  const closeDisconnectAzureModal = () => {
    setOpenDisconnectAccountModal(false);
  };

  const handleNavigateToReconnect = () => {
    if (accountDetails.CSP === CloudServiceProvider.AWS) {
      navigate(`/cloud-account/aws/re-connect/${accountId}`);
      // } else if (accountDetails.CSP === CloudServiceProvider.Azure) {
      //   navigate(`/cloud-account/azure/re-connect/${accountId}`);
    }
  };

  const handleNavigateToDisconnectAccount = () => {
    if (accountDetails.CSP === CloudServiceProvider.AWS) {
      navigate(`/cloud-account/view/disconnect/${accountId}`);
      // } else if (accountDetails.CSP === CloudServiceProvider.Azure) {
      //   setOpenDisconnectAccountModal(true);
    }
  };

  const handleAccountEdit = () => {
    navigate(`/cloud-account/view/${accountId}/edit`);
  };

  const handleCancel = () => {
    navigate(`/cloud-account/view/${accountId}`);
  };

  const handlePause = async () => {
    if (accountID)
      try {
        const response = await postWithCloudAuth({
          url: API_ENDPOINTS.CLOUD_ACCOUNT_PAUSE_ACC,
          payload: {
            accountId: accountID
          }
        });

        if (response) {
          setIsPauseSuccessModalOpen(true);
          dispatch(
            fetchCloudAccountDetailsRequest({ accountId: accountId, startLoading, stopLoading })
          );
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'PauseFail',
          defaultMessage: 'AWS Pause failed'
        });
      }
  };

  const handleSave = async ({ accountName, budget }: IAccountInfoInitialValues) => {
    try {
      setIsLoadingStart(true);
      const response = await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_EDIT_ACCOUNT,
        payload: {
          accountId: accountDetails.accountId,
          accountName: accountName,
          budget: budget
        }
      });
      if (response) {
        handleCancel();
        dispatch(fetchCloudAccountDetailsRequest({ accountId, startLoading, stopLoading }));
        setIsAccountInfoSuccessModalOpen(true);
        queryClient.resetQueries(
          getFetchAccountQueryKey({
            limit: ROWS_PER_PAGE_FIRST_ITEM,
            offset: offsetFirst
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'EDIT_ACCOUNT_FAIL'
      });
    } finally {
      setIsLoadingStart(false);
    }
  };

  const handleResume = async () => {
    if (accountID)
      try {
        const response = await postWithCloudAuth({
          url: API_ENDPOINTS.CLOUD_ACCOUNT_RESUME_ACC,
          payload: {
            accountId: accountID
          }
        });

        if (response) {
          //open the resume success modal
          setIsResumeModalOpen(false);
          setIsResumeSuccessModalOpen(true);
          dispatch(
            fetchCloudAccountDetailsRequest({ accountId: accountId, startLoading, stopLoading })
          );
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'ResumeFail'
        });
      }
  };

  const handleSubmitAccountInfo = ({ accountName, budget }: IAccountInfoInitialValues) =>
    handleSave({ accountName, budget });

  useEffect(() => {
    if (isEditAccountInfoActive) {
      dispatch(
        updateBreadcrumbs([
          { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
          { text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW, url: `/cloud-account/view/${params.id}` },
          {
            text: (
              <TextTooltipWithEllipsis
                limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
                text={accountDetails.accountName}
              />
            ),
            url: `/cloud-account/view/${params.id}`
          },
          { text: `Edit Account Info`, url: '' }
        ])
      );
    } else {
      dispatch(
        updateBreadcrumbs([
          { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
          { text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW, url: `/cloud-account/view/${params.id}` },
          {
            text: (
              <TextTooltipWithEllipsis
                limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
                text={accountDetails.accountName}
              />
            ),
            url: `/cloud-account/view/${params.id}`
          },

          { text: `Account Info`, url: '' }
        ])
      );
    }
  }, [isEditAccountInfoActive, accountDetails.accountName]); // Include accountDetails.accountName as a dependency if it's used inside the effect
  const isEditPath = location.pathname.includes('/edit');
  useEffect(() => {
    dispatch(updateAccountInfoEditState(isEditPath));

    return () => {
      dispatch(updateAccountInfoEditState(false));
    };
  }, [dispatch, location.pathname]);

  const shouldShowReconnectButton: boolean =
    accountDetails.status.toLowerCase() === CloudAccountsStatuses.Inactive.toLowerCase() ||
    accountDetails.status.toLowerCase() === CloudAccountsStatuses.Failed.toLowerCase() ||
    accountDetails.status.toLowerCase() === CloudAccountsStatuses.Unreachable.toLowerCase() ||
    (accountDetails.status.toLowerCase() === CloudAccountsStatuses.InProgress.toLowerCase() &&
      calculateHoursDifference(accountDetails.createdAt) > hoursAfterWhichShowReconnectButton);

  const shouldShowDisconnectButton: boolean =
    accountDetails.status.toLowerCase() === CloudAccountsStatuses.Active.toLowerCase() ||
    accountDetails.status.toLowerCase() === CloudAccountsStatuses.Paused.toLowerCase();

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(`Cloud Accounts`));
      dispatch(setActiveTab(2));
    },
    onUnMountFunc: () => {}
  });

  return (
    <>
      <PaperCustom
        variant="medium"
        className={classNames('accountContainer', loading ? 'accountInfo' : '')}
      >
        <Formik
          onSubmit={handleSubmitAccountInfo}
          initialValues={editAccountInfoState}
          enableReinitialize={true}
          validationSchema={EditAccountInfoValidationSchema}
        >
          {({ errors, touched, resetForm }) => {
            return (
              <Form className="accountContainer__form">
                <div className="accountContainer__form__content">
                  {!isEditAccountInfoActive ? (
                    <div className="buttonContainer">
                      <Typography
                        variant="body-2"
                        size="medium"
                        className="accountContainer__form__content__header apply-loader"
                      >
                        Account Info
                      </Typography>
                      <div
                        className={`buttonView ${
                          accountDetails.status === CloudAccountsStatuses.Inactive &&
                          'top-[13.5rem]'
                        }`}
                      >
                        {/* for all types of status edit button should be there */}
                        <TooltipCustom
                          text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_EDIT_ACCESS}
                          conditionToDisplay={!canUserEditCloudAccount}
                        >
                          <CustomButton
                            text={<span>Edit</span>}
                            image={IMAGES.editBluePen}
                            variant={ButtonVariants.SECONDARY}
                            className="editButton apply-loader"
                            onClick={handleAccountEdit}
                            type="button"
                            disabled={!canUserEditCloudAccount}
                          />
                        </TooltipCustom>
                        {/* Resume only paused accounts  */}
                        {accountDetails.status.toLowerCase() ===
                          CloudAccountsStatuses.Paused.toLowerCase() && (
                          <TooltipCustom
                            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_RESUME_ACCESS}
                            conditionToDisplay={!canUserResumeCloudAccount}
                          >
                            <CustomButton
                              type="button"
                              text={<span>{CLOUD_ACCOUNTS_TEXT.RESUME}</span>}
                              image={IMAGES.resumeRound}
                              variant={ButtonVariants.SECONDARY}
                              className="pauseButton apply-loader"
                              onClick={() => {
                                setIsResumeModalOpen(true);
                              }}
                              disabled={!canUserResumeCloudAccount}
                            />
                          </TooltipCustom>
                        )}
                        {/* Pause only active accounts */}
                        {accountDetails.status.toLowerCase() ===
                          CloudAccountsStatuses.Active.toLowerCase() && (
                          <TooltipCustom
                            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_PAUSE_ACCESS}
                            conditionToDisplay={!canUserPauseCloudAccount}
                          >
                            <CustomButton
                              type="button"
                              text={<span>{CLOUD_ACCOUNTS_TEXT.PAUSE}</span>}
                              image={IMAGES.pause}
                              variant={ButtonVariants.SECONDARY}
                              className="pauseButton apply-loader"
                              onClick={() => {
                                setIsPauseModalOpen(true);
                              }}
                              disabled={!canUserPauseCloudAccount}
                            />
                          </TooltipCustom>
                        )}

                        {/* Reconnect only for inactive OR failed OR unreachable OR inProgress for more than desired hours  */}
                        {shouldShowReconnectButton && (
                          <TooltipCustom
                            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_RECONNECT_ACCESS}
                            conditionToDisplay={!canUserReconnectCloudAccount}
                          >
                            <CustomButton
                              className="pauseButton apply-loader"
                              type="button"
                              onClick={handleNavigateToReconnect}
                              text={CLOUD_ACCOUNTS_TEXT.RECONNECT_ACCOUNT}
                              image={IMAGES.reconnectIcon}
                              disabled={!canUserReconnectCloudAccount}
                              buttonWidth="145px"
                            />
                          </TooltipCustom>
                        )}
                        {/* Disconnect only active or paused accounts */}
                        {shouldShowDisconnectButton && (
                          <TooltipCustom
                            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_DISCONNECT_ACCESS}
                            conditionToDisplay={!canUserDisconnectCloudAccount}
                          >
                            <CustomButton
                              className="disconnectButton apply-loader"
                              type="button"
                              onClick={handleNavigateToDisconnectAccount}
                              text={CLOUD_ACCOUNTS_TEXT.DISCONNECT}
                              image={IMAGES.DisconnectAccount}
                              disabled={!canUserDisconnectCloudAccount}
                            />
                          </TooltipCustom>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="buttonContainer">
                      <Typography
                        variant="body-2"
                        size="medium"
                        className="accountContainer__form__content__header"
                      >
                        Edit Account Info
                      </Typography>
                      <div className="accountInfoButtons">
                        <CustomButton
                          onClick={() => {
                            resetForm();
                            handleCancel();
                          }}
                          text={CLOUD_ACCOUNTS_TEXT.CANCEL}
                          variant={ButtonVariants.SECONDARY}
                          type="button"
                          buttonWidth="87px"
                        />
                        <CustomButton
                          type="submit"
                          text={isLoadingStart ? <Loader /> : CLOUD_ACCOUNTS_TEXT.SAVE}
                          variant={ButtonVariants.PRIMARY}
                          buttonWidth="74px"
                        />
                      </div>
                    </div>
                  )}
                  {!isEditAccountInfoActive && accountDetails.CSP === CloudServiceProvider.AWS && (
                    <AccountStatusMessage
                      variant={accountDetails.status.toLowerCase() as EAccountStatusVariants}
                      msg={
                        ACCOUNT_STATUS_MSG[
                          accountDetails.status as keyof typeof CloudAccountsStatuses
                        ]
                      }
                      className={'apply-loader typography__body-3__mobile'}
                    />
                  )}

                  {!isEditAccountInfoActive &&
                    accountDetails.CSP === CloudServiceProvider.Azure && (
                      <AccountStatusMessage
                        variant={accountDetails.status.toLowerCase() as EAccountStatusVariants}
                        msg={
                          AZURE_ACCOUNT_STATUS_MSG[
                            accountDetails.status as unknown as keyof typeof CloudAccountsStatuses
                          ]
                        }
                        className={'apply-loader typography__body-3__mobile'}
                      />
                    )}
                  <div className="cloud-account-container">
                    <div className="inner-cloud-account-container">
                      <div className="cloud-account-container__list-item">
                        <Key
                          text="Cloud Provider"
                          className="apply-loader cloud-account-container__list-item__key"
                        />
                        <div className="apply-loader cloud-account-container__list-item__value">
                          <CloudProviderLogo cloudServiceProvider={accountDetails.CSP} />
                        </div>
                      </div>

                      <div className="cloud-account-container__list-item">
                        <Key
                          text={
                            accountDetails.CSP === CloudServiceProvider.AWS
                              ? 'Account ID'
                              : 'Subscription ID'
                          }
                          className="apply-loader cloud-account-container__list-item__key"
                        />
                        <Value
                          text={accountId}
                          className="apply-loader cloud-account-container__list-item__value"
                        />
                      </div>

                      {accountDetails.CSP === CloudServiceProvider.Azure && (
                        <div className="cloud-account-container__list-item">
                          <Key
                            text="Tenant ID"
                            className="apply-loader cloud-account-container__list-item__key"
                          />
                          <Value
                            text={accountDetails?.metadata?.tenantId}
                            className="apply-loader cloud-account-container__list-item__value"
                          />
                        </div>
                      )}
                      {accountDetails.CSP === CloudServiceProvider.Azure && (
                        <div className="cloud-account-container__list-item">
                          <Key
                            text="Application Client ID"
                            className="apply-loader cloud-account-container__list-item__key"
                          />
                          <Value
                            text={accountDetails?.metadata?.clientId}
                            className="apply-loader cloud-account-container__list-item__value"
                          />
                        </div>
                      )}

                      <div className="cloud-account-container__list-item">
                        <Key
                          text="Account Name"
                          className="apply-loader self-start cloud-account-container__list-item__key"
                        />
                        {isEditAccountInfoActive ? (
                          <TextInput
                            label=""
                            type="text"
                            placeholder="Your Account Name"
                            name="accountName"
                            disabled={false}
                            required={true}
                            errors={errors}
                            touched={touched}
                            labelRequired={false}
                            divClassName="textInput"
                          />
                        ) : (
                          <Value
                            text={accountDetails.accountName}
                            className="apply-loader cloud-account-container__list-item__value"
                          />
                        )}
                      </div>

                      <div className="cloud-account-container__list-item">
                        <Key
                          text="Monthly Budget"
                          className="apply-loader self-start cloud-account-container__list-item__key"
                        />
                        {isEditAccountInfoActive ? (
                          <TextInput
                            placeholder="Budget"
                            type="text"
                            name="budget"
                            isFieldValid={(value) => DECIMAL_NUMBER.test(value)}
                            currency="USD"
                            label=""
                            required={true}
                            errors={errors}
                            touched={touched}
                            labelRequired={false}
                            divClassName="textInput"
                            disabled={false}
                          />
                        ) : (
                          <Value
                            text={'$ ' + accountDetails.budget}
                            className="apply-loader cloud-account-container__list-item__value"
                          />
                        )}
                      </div>
                    </div>
                    <div className="inner-cloud-account-container">
                      <div className="cloud-account-container__list-item">
                        <Key
                          text="Cloud Connection Status"
                          className="apply-loader cloud-account-container__list-item__key"
                        />
                        <Text type={TextType.H4} className="apply-loader">
                          <TableColumnStatus {...statusButtondata} />
                        </Text>
                      </div>

                      <div className="cloud-account-container__list-item">
                        <Key
                          text="Connected On"
                          className="apply-loader cloud-account-container__list-item__key"
                        />
                        <Value
                          className="apply-loader cloud-account-container__list-item__value"
                          text={getDateFromString(
                            accountDetails.createdAt,
                            organization.organizationAbbreviation,
                            organization.organizationTimeZone
                          )}
                        />
                      </div>

                      <div className="cloud-account-container__list-item">
                        <Key
                          text="Connected By"
                          className="apply-loader cloud-account-container__list-item__key"
                        />
                        <Value
                          className=" whitespace-wrap apply-loader cloud-account-container__list-item__value"
                          text={`${accountDetails.accountConnectedBy.firstName} ${accountDetails.accountConnectedBy.lastName}`}
                        />
                      </div>
                    </div>
                    <div className="inner-cloud-account-container"></div>
                  </div>
                  {/* Below field is optional */}
                  {isAccountInactive && (
                    <div className="cloud-account-container__list-item">
                      <Key
                        text="Disconnected On"
                        className="apply-loader cloud-account-container__list-item__key"
                      />
                      <Value
                        text={getDateFromString(
                          accountDetails.updatedAt,
                          organization.organizationAbbreviation,
                          organization.organizationTimeZone
                        )}
                        className="apply-loader cloud-account-container__list-item__value"
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        {/* pause modal */}
        {isPauseModalOpen && (
          <DeleteModal
            open={isPauseModalOpen}
            handleClose={() => {
              setIsPauseModalOpen(false);
            }}
            handleDelete={() => {
              handlePause();
              setIsPauseModalOpen(false);
            }}
            placeholder="Type PAUSE to pause connection"
            heading="Pause Connection"
            child={
              <>
                <p>
                  Pausing this Connection means it won&apos;t process new cost data. Any data
                  collected during this pause will be added later when you Resume the connection.
                </p>
                <p style={{ marginTop: '1rem' }}>
                  However, no new data will show up in CloudInSpot until then. If you keep it paused
                  for a while, the backlog of data could get big and cause delays when you Resume.
                </p>
              </>
            }
            actionButtonText="Pause"
            inputRequiredToDelete="PAUSE"
            actionButtonActiveColor={colorConstants.red1}
            actionButtonActiveTextColor={colorConstants.white}
          />
        )}

        {/* Pause success message */}
        {isPauseSuccessModalOpen && (
          <ConfirmModal
            open={isPauseSuccessModalOpen}
            setOpen={setIsPauseSuccessModalOpen}
            title="Connection Paused!"
            description={`Your ${
              accountDetails.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'
            } account connection paused successfully.`}
            image={confirmSuccess}
            confirmButtonText="OKAY"
            descriptionWidth="88%"
          />
        )}

        {/* Resume Modals here */}
        {isResumeModalOpen && (
          <RestoreArchiveUserModal
            type={TYPE_ALIGNMENT.LEFT}
            open={isResumeModalOpen}
            handleClose={() => {
              setIsResumeModalOpen(false);
            }}
            handlerFun={() => handleResume()}
            heading="Resume Connection"
            child={
              <>
                <p>
                  Resuming this Connection means it will start processing new cost data and
                  you&apos;ll see new data in <CloudInSpotName />.
                </p>
                <p style={{ marginTop: '1rem' }}>
                  If the Connection was paused for a while, there might be delays as it processes
                  all the backed-up data
                </p>
              </>
            }
            actionButtonText="Resume"
            actionButtonColor="#165baa"
          />
        )}

        {/* Resume Success Modal */}
        {isResumeSuccessModalOpen && (
          <ConfirmModal
            open={isResumeSuccessModalOpen}
            setOpen={setIsResumeSuccessModalOpen}
            title="Connection Resumed!"
            description={`Your ${
              accountDetails.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'
            } account connection resumed successfully.`}
            descriptionWidth="88%"
            confirmButtonText="OKAY"
            image={confirmSuccess}
          />
        )}

        {/* Edit account Info Modals */}
        {isAccountInfoSuccessModalOpen && (
          <ConfirmModal
            open={isAccountInfoSuccessModalOpen}
            title="Updated!"
            description={`Updated ${
              accountDetails.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'
            } Account info successfully.`}
            confirmButtonText="OKAY"
            descriptionWidth="88%"
            image={confirmSuccess}
            setOpen={setIsAccountInfoSuccessModalOpen}
          />
        )}
      </PaperCustom>
      <DisconnectAzure
        closeDisconnectAzureModal={closeDisconnectAzureModal}
        isModalOpen={openDisconnectAccountModal}
        accountId={accountDetails?.accountId}
      />
    </>
  );
};

export default AccountInfo;
