import authHttpMSTeams from './authHttpMSTeams';

import { IGet } from '../service.interface';

const getWithAuthMSTeams = async ({ url, params }: IGet) => {
  //prev We used a axios directly from library
  //now used a *authHttp* a instance of axios so no need to send headers from here as this is taken care by authHttp

  return authHttpMSTeams
    .get(url, { params })
    .then((response) => {
      localStorage.setItem('retryCounter', '0');
      return response;
    })
    .catch((error: unknown) => {
      throw error;
    });
};
// TODO: @arman-scalex Lets remove this. We don't need this now.
export default getWithAuthMSTeams;
