// eslint-disable-next-line import/named
import { AxiosError, AxiosInstance } from 'axios';

import { getAuthTokens } from './getAuthTokensAndURL';
import updateTokens from './updateTokens';

const authInterceptor = (http: AxiosInstance) => {
  // This runs before the request
  // Here used for adding the headers

  // To restrict the number of retries
  // const retryCounter = +(localStorage.getItem('retryCounter') || 0);

  // const retryConfig: IAuthInterceptorRetryConfig = {
  //   retryNo: 1,
  //   maxRetries: 3
  // };

  http.interceptors.request.use(
    async (config) => {
      const tokenExpiresInEpoch = localStorage.getItem('userExpiresIn') as unknown as number;
      const milsecToMinsFactor = 60000;
      const tokenExpiresInMin = (tokenExpiresInEpoch - new Date().getTime()) / milsecToMinsFactor;

      // updating tokens within 10 mins of expiry
      if (tokenExpiresInMin < 10) {
        await updateTokens();
      }

      const { Authorization, idToken, organizationId } = getAuthTokens();
      config.headers.Authorization = Authorization;
      config.headers['id-token'] = idToken;
      config.headers['organization-id'] = organizationId;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    // This runs before the error is handled by then/catch handlers
    // In the code below, when 401 or 403 response is received, implying the token is valid.
    // A request is sent to fetch the new access token using the refresh token.
    (response) => response,
    async (error: AxiosError) => {
      // const status = error?.response?.status;
      // const originalRequest = error?.config;
      console.info(localStorage.getItem('retryCounter'));

      // if (status === 401 || !status) {
      //   // Token is not valid if error is 401 or 403
      //   retryConfig.retryNo++;
      //   localStorage.setItem('retryCounter', String(retryCounter + 1));
      //   const { organizationId, email, refreshToken } = getAuthTokens();
      //   const response: IGetTokenAPIResponse = await axios.post(
      //     generateURL(API_ENDPOINTS.GET_NEW_TOKEN),
      //     {
      //       organizationId,
      //       email,
      //       refreshToken
      //     }
      //   );

      //   const authenticationResult = response.data.data.AuthenticationResult;

      //   const expiresIn = authenticationResult.ExpiresIn;
      //   // Store new access token and idToken in the localStorage
      //   const authInfo: IAuthInfo = {
      //     AccessToken: authenticationResult.AccessToken,
      //     ExpiresIn: authenticationResult.ExpiresIn.toString(),
      //     IdToken: authenticationResult.IdToken
      //   };
      //   localStorage.setItem('authInfo', JSON.stringify(authInfo));

      //   // sets the expiresin timestamp
      //   localStorage.setItem('userExpiresIn', `${new Date().getTime() + expiresIn * 1000}`);

      //   // Update the headers from the refresh token resonse.
      //   http.defaults.headers.common.Authorization = authenticationResult.AccessToken;
      //   http.defaults.headers.common['id-token'] = authenticationResult.IdToken;

      //   if (originalRequest) {
      //     // Retry the request with the tokens and headers
      //     return http(originalRequest);
      //   }
      // }
      return Promise.reject(error);
    }
  );
};

export default authInterceptor;
