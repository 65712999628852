import { Fragment, useState } from 'react';
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom';
import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../hooks/loading/useLoading';
import CloudAccountService from '../../../services/cloudAccount.service';
import {
  TDisconnectAccountRequest,
  TDisconnectAccountResponse
} from '../../../services/types/cloudAccount.endpoints';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import Loader from '../../common/loader/loader';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../common/restoreArchiveUserModal/RestoreArchiveUserModal';

const DisconnectAzure = ({
  isModalOpen,
  closeDisconnectAzureModal,
  accountId,
  fetchAccounts
}: {
  isModalOpen: boolean;
  closeDisconnectAzureModal: () => void;
  accountId: string | number;
  fetchAccounts?: ({ showLoader }: { showLoader?: boolean }) => void;
}) => {
  const [openDisconnectAccountConfirmModal, setOpenDisconnectAccountConfirmModal] =
    useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();

  const navigate = useNavigate();

  const handleCloudAccount = () => {
    navigate(`/cloud-account/`);
  };

  const handleDisconnect = async () => {
    try {
      startLoading();
      if (accountId) {
        const request: TDisconnectAccountRequest = {
          accountId: accountId.toString()
        };

        const response = await CloudAccountService.disconnectAccount(request);
        const responseData: TDisconnectAccountResponse = response;
        if (responseData) {
          closeDisconnectAzureModal();
          setOpenDisconnectAccountConfirmModal(true);
        }
      }
    } catch (error) {
      useErrorHandler({ error, toastId: 'DisconnectRedirectionFail' });
    } finally {
      stopLoading();
    }
  };
  return (
    <Fragment>
      <RestoreArchiveUserModal
        type={TYPE_ALIGNMENT.LEFT}
        open={isModalOpen}
        heading="Disconnect Connection"
        actionButtonText={loading ? <Loader /> : 'Yes, Disconnect'}
        description="Are you sure you want to disconnect Azure cloud account?"
        handlerFun={() => {
          handleDisconnect();
        }}
        handleClose={() => {
          closeDisconnectAzureModal();
        }}
      />
      <ConfirmModal
        title="Disconnected!"
        description={
          <>
            Your Azure account disconnected from <CloudInSpotName /> successfully.
          </>
        }
        descriptionWidth="88%"
        confirmButtonText={BUTTON_TEXT.OKAY}
        open={openDisconnectAccountConfirmModal}
        handleOkClick={() => {
          handleCloudAccount();
          if (fetchAccounts) {
            fetchAccounts({});
            setOpenDisconnectAccountConfirmModal(false);
          }
        }}
        setOpen={setOpenDisconnectAccountConfirmModal}
        image={confirmSuccess}
      />
    </Fragment>
  );
};
export default DisconnectAzure;
