/* eslint-disable import/named */
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import * as React from 'react';

import { ICustomStepsFormProps } from './CustomStepsForm.interface';
import './customStepsForm.scss';

import IMAGES from '../../../assets';
import '../../../assets/styles/index.scss';
import { BLACK as BLACK_COLOR, PRIMARY as PRIMARY_COLORS } from '../../../configs/v3.colors';
import Typography from '../typography/Typography';

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: BLACK_COLOR[600],
      backgroundColor: BLACK_COLOR[600],
      width: '65%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#f00',
      backgroundColor: BLACK_COLOR[600],
      width: '65%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: '#f00',
    backgroundColor: BLACK_COLOR[600],
    borderRadius: 1,
    width: '65%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fffff',
  zIndex: 1,
  color: '#535353',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid ' + BLACK_COLOR[500],
  ...(ownerState.active && {
    borderColor: PRIMARY_COLORS[600],
    color: PRIMARY_COLORS[600]
  }),
  ...(ownerState.completed && {
    background: PRIMARY_COLORS[600],
    border: 'none'
  })
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <Typography variant="body-2" size="bold">
        1
      </Typography>
    ),
    2: (
      <Typography variant="body-2" size="bold">
        2
      </Typography>
    ),
    3: (
      <Typography variant="body-2" size="bold">
        3
      </Typography>
    )
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <img src={IMAGES.check} /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const CustomStepsForm = ({ activeTab, steps }: ICustomStepsFormProps) => {
  const handleClick = (index: number, onClickFunc: () => void) => {
    if (activeTab > index) {
      onClickFunc();
    }
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={3} className="custom-step-form">
      <Stepper
        alternativeLabel
        activeStep={activeTab}
        connector={<ColorlibConnector />}
        className="custom-step-form__stepper"
      >
        {steps.map((item, index) => (
          <Step key={item.label} className="custom-step-form__stepper__step">
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              className="custom-step-form__stepper__step__label"
            >
              <Typography
                variant="body-3"
                size="medium"
                onClick={() => handleClick(index, item.onClick)}
                // Active tab will be highlighted in blue as per the design
                className={classNames('custom-step-form__stepper__step__label__text', {
                  'custom-step-form__stepper__step__label__text--is-active': activeTab === index,
                  'custom-step-form__stepper__step__label__text--is-complete': activeTab > index
                })}
              >
                {item.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomStepsForm;
