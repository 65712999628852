import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import DeleteGroupModal from './DeleteGroupModal';
import { ITeamManagementGroupsProps } from './groups.interface';
import './groups.scss';
import GroupsSummary from './groupsSummary/GroupsSummary';

import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { COMMON_TEXT } from '../../../configs/common';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import { BLACK as BLACK_COLORS, ERRORS as ERRORS_COLORS } from '../../../configs/v3.colors';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM, RowsPerPage } from '../../../constants/constants';
import { routes } from '../../../constants/routes';
import ENUMS from '../../../enums';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import postWithAuth from '../../../services/postWithAuth';
import {
  prefetchGroups,
  prefetchTeamwiseUsers
} from '../../../services/prefechService/prefetchTeamsService';
import { fetchTeams } from '../../../services/teamManagement/teamManagement';
import { TGetGroupsResponse } from '../../../services/types/teamManagement.endpoints';
import { updateSelectedGroup } from '../../../store/actions';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import useOrganizationStore from '../../../store/orgnization/useOrgnizationStore';
import {
  ITeamMangGroup,
  ITeamMangGroupCreatedBy
} from '../../../store/teamManagement/teamManagement.interface';
import { ITeamManagementUserInitialState } from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import { selectDeslectCheckbox, sortObjectArray } from '../../../utils';
import { getDateFromString } from '../../../utils/dateAndHourFormatting/dateAndHourFormatting';
import { getFetchTeamsQueryKey } from '../../../utils/pre-fetchers/prefetchTeamManagement';
import { TSortType } from '../../../utils/sortObjectArray/sortObjectArray.interface';
import TextTooltipWithEllipsis from '../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import CustomTable from '../../common/commonTable/CustomTable';
import { ITableColumns, TTableRowData } from '../../common/commonTable/customTable.interface';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import LockPadIcon from '../../common/icons/lockPadIcon/LockPadIcon';
import TrashIcon from '../../common/icons/trashIcon/TrashIcon';
import SearchForm from '../../common/searchForm/SearchForm';
import { TSearchFormValues } from '../../common/searchForm/searchForm.types';
import Typography from '../../common/typography/Typography';
import HeadingText from '../common/headingText/HeadingText';
import '../users/users.scss';

const { userRoles, userGroups } = ENUMS.teamManagement;

const groupCreatedByFunc = (groupCreatedBy: ITeamMangGroupCreatedBy) => {
  return groupCreatedBy
    ? groupCreatedBy?.firstName + ' ' + groupCreatedBy?.lastName
    : userRoles.System;
};

const Groups = ({
  addUserToGroups,
  selectedRowsAddToGroup,
  setSelectedRowsAddToGroup
}: ITeamManagementGroupsProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_FIRST_ITEM);
  const [searchString, setSearchString] = useState({ value: '', isDirty: false });
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [isRowSelectedForDeletion, setIsRowSelectedForDeletion] = useState<boolean>(false);
  const tableHeightRef = useRef<HTMLDivElement>(null);

  const { userProfileData } = useAppSelector(
    (store) => store.teamManagementUser
  ) as ITeamManagementUserInitialState;
  const { isOwner: isloggedInUserOwner } = useAppSelector<IMyProfileState>(
    (store) => store.MyProfile
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  // USER PERMISSIONS START
  const { canUserDeleteGroup, canUserViewGroup, canUserRemoveUsersFromGroup } =
    useCheckUserPermissions();
  // USER PERMISSIONS END

  const { organization } = useOrganizationStore();

  const { isLoading, data: teamsData } = useQuery<TGetGroupsResponse>(
    getFetchTeamsQueryKey({
      limit: rowsPerPage,
      offset: pageNumber,
      filterGroup: searchString.value
    }),
    () => fetchTeams(rowsPerPage, pageNumber, searchString.value),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = JSON.parse(JSON.stringify(teamsData?.groups || []))
      .slice(0, rowsPerPage)
      .map((item: ITeamMangGroup, index: number) => {
        return {
          ...item,
          itemNo: startIndex + index + 1,
          groupCreatedBy: groupCreatedByFunc(item?.groupCreatedBy),
          disableCheckbox: item.groupName === userRoles.Admin || !canUserDeleteGroup
        };
      });
    const newData = sortObjectArray({
      arr: totalItems,
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];
    return newData;
  }, [dataSort, teamsData?.groups, pageNumber, rowsPerPage, canUserDeleteGroup]);

  const onselection = (value: string) => {
    if (setSelectedRows) {
      const newArray = selectDeslectCheckbox({ arr: selectedRows, value }) as string[];
      setSelectedRows(() => [...newArray]);
    }
  };

  const checkIfSelected = (groupName: string) => {
    if (userProfileData.groups.includes(groupName)) {
      return true;
    }
    if (selectedRowsAddToGroup) {
      return selectedRowsAddToGroup.includes(groupName);
    }
    return false;
  };

  const onSelectionAddToGroup = (value: string) => {
    if (selectedRowsAddToGroup) {
      const newArray = selectDeslectCheckbox({ arr: selectedRowsAddToGroup, value }) as string[];

      if (setSelectedRowsAddToGroup) {
        setSelectedRowsAddToGroup(() => [...newArray]);
      }
    }
  };

  const selectAllRows = () => {
    const allRowIds = sortedData
      .filter((rowf) => !rowf.disableCheckbox)
      .map((row) => row.groupName) as (string | number)[];

    if (setSelectedRows) {
      if (selectedRows.length === allRowIds.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(allRowIds);
      }
    }
  };

  const handleDeleteGroup = async () => {
    // api request to delete modal
    try {
      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_DELETE_GROUPS,
        payload: { groupNames: selectedRows }
      });

      if (response) {
        setOpenDeleteModal(false);
        setOpenConfirmDeleteModal(true);
        setSelectedRows([]);
        searchGroupsByString({ query: searchString?.value });
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementDeleteGroupsFail',
        defaultMessage:
          'Your request to delete groups has failed due to unknown reasons! Please try again later'
      });
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    if (isRowSelectedForDeletion) {
      setSelectedRows([]);
      setIsRowSelectedForDeletion(false);
    }
  };

  const navigateToGroup = ({ rowData }: { rowData: TTableRowData }) => {
    if (!addUserToGroups) {
      const groupData = rowData as unknown as ITeamMangGroup;
      dispatch(updateSelectedGroup(groupData));
      localStorage.setItem('teamMgmtGroupView', JSON.stringify(rowData));
      navigate(`${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${groupData.groupName}`);
    }
    return;
  };

  const handleRowClick = ({ rowData }: { rowData: TTableRowData }): undefined => {
    if (isloggedInUserOwner) {
      navigateToGroup({ rowData });
    } else if (!addUserToGroups && canUserViewGroup) {
      navigateToGroup({ rowData });
    }
  };

  const selectableGroups = sortedData.filter(
    (group) => !(group as unknown as ITeamMangGroup)?.disableCheckbox
  );

  const searchGroupsByString = ({ query }: TSearchFormValues) => {
    setSearchString({ value: query, isDirty: true });
    setPageNumber(1);
    queryClient.invalidateQueries(
      getFetchTeamsQueryKey({
        limit: ROWS_PER_PAGE_FIRST_ITEM,
        offset: offsetFirst,
        filterGroup: query
      })
    );
  };

  const prefetchTeamData = (team: string) => {
    prefetchTeamwiseUsers(queryClient, {
      groupName: team,
      page: offsetFirst,
      rows: ROWS_PER_PAGE_FIRST_ITEM
    });
  };

  useEffect(() => {
    if (teamsData?.count && pageNumber < teamsData?.count / rowsPerPage) {
      const nextPage = pageNumber + 1;
      prefetchGroups(queryClient, {
        pageNumber: nextPage,
        rowsPerPage,
        filterGroup: searchString.value
      });
    }
  }, [pageNumber, rowsPerPage, queryClient, teamsData?.count]);

  const tableColumns: ITableColumns[] = [
    {
      key: 3,
      title: TEAM_MANAGEMENT_TEXT.GROUP_NAME,
      dataIndex: 'groupName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.GROUPS}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const groupName = args[0] as string;
        const rowData = args[1] as unknown as TTableRowData;
        return (
          <span
            className="group-name"
            onMouseEnter={() => prefetchTeamData(groupName as string)}
            onClick={() => handleRowClick({ rowData })}
          >
            <TextTooltipWithEllipsis limit={12} text={groupName} />
            {groupName === userGroups.Admin && <LockPadIcon />}
          </span>
        );
      }
    },
    {
      key: 4,
      title: TEAM_MANAGEMENT_TEXT.CREATED_ON,
      dataIndex: 'createdAt',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.CREATED_ON}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const createOn = args[0] as string;
        return getDateFromString(
          createOn,
          organization.organizationAbbreviation,
          organization.organizationTimeZone
        );
      }
    },
    {
      key: 5,
      title: TEAM_MANAGEMENT_TEXT.CREATED_BY,
      dataIndex: 'groupCreatedBy',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.CREATED_BY}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const groupCreatedBy = args[0] as unknown as string;
        if (groupCreatedBy) {
          return <TextTooltipWithEllipsis limit={15} text={groupCreatedBy} />;
        }
        return userRoles.System;
      }
    },
    {
      key: 6,
      title: TEAM_MANAGEMENT_TEXT.USER_COUNT,
      dataIndex: 'userCount',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.USER_COUNT}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const userCount = args[0] as number;
        return (
          <div className={`user-count ${userCount === 0 ? 'user-count--is-empty' : ''}`}>
            {userCount}
          </div>
        );
      }
    }
  ];

  if (!addUserToGroups) {
    // Only add first column checkbox, if it's a regular Groups table
    tableColumns.unshift({
      key: 1,
      title: '',
      dataIndex: 'groupName',
      headElement: () => (
        <input
          type="checkbox"
          checked={selectedRows.length === selectableGroups.length && selectableGroups.length > 0}
          className="checkbox select-all-checkbox"
          onChange={selectAllRows}
        />
      ),
      bodyElement: (...args) => {
        const groupName = args[0] as string;
        const group = args[1] as unknown as ITeamMangGroup;

        return (
          <input
            type="checkbox"
            onChange={() => onselection(groupName)}
            className="checkbox"
            checked={selectedRows.includes(groupName)}
            disabled={group?.disableCheckbox}
            onClick={(e) => e.stopPropagation()}
          />
        );
      }
    });
    tableColumns.push({
      key: 8,
      title: 'Action',
      dataIndex: 'groupName',
      headElement: () => (
        <div className="cell-inner groups-action-row-head">
          <Typography variant="body-3" size="medium" as="span">
            Action
          </Typography>
        </div>
      ),
      bodyElement: (...args) => {
        const groupName = args[0] as string;
        const group = args[1] as unknown as ITeamMangGroup;
        const hideDeleteButton = group.groupName === userRoles.Admin || !canUserDeleteGroup;

        return (
          <div className="action delete-group">
            {hideDeleteButton ? (
              '-'
            ) : (
              <TrashIcon
                onClick={() => {
                  setSelectedRows([groupName]);
                  setIsRowSelectedForDeletion(true);
                  setOpenDeleteModal(true);
                }}
                color={ERRORS_COLORS[700]}
              />
            )}
          </div>
        );
      }
    });
  }

  if (addUserToGroups) {
    // Only add this column checkbox to the end, if it's a add group to user flow
    tableColumns.push({
      key: 7,
      title: 'Add to group',
      dataIndex: 'groupName',
      headElement: () => (
        <div className="cell-inner text-center">
          <span>{TEAM_MANAGEMENT_TEXT.ADD_TO_GROUP}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const groupName = args[0] as string;
        return (
          <div className="cell-inner text-left">
            <input
              type="checkbox"
              onChange={() => onSelectionAddToGroup(groupName)}
              className="checkbox"
              // rows are disabled if they are already added or group name is owner
              // or logged in user is not part of Owner group
              checked={checkIfSelected(groupName)}
              onClick={(e) => e.stopPropagation()}
              disabled={!canUserRemoveUsersFromGroup}
            />
          </div>
        );
      }
    });
  }

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      {
        text: TEAM_MANAGEMENT_TEXT.GROUPS,
        url: ''
      }
    ]
  });

  return (
    <div
      className={`team-management-groups-table ${
        addUserToGroups ? 'team-management-groups-table-add-user-to-group' : ''
      } ${isLoading ? 'team-management-groups-table--is-loading' : ''}`}
    >
      {!addUserToGroups && (
        <GroupsSummary
          summaryData={{
            totalGroups: teamsData?.totalGroups || 0,
            groupsAddedThisMonth: teamsData?.groupsAddedThisMonth || 0,
            groupsDeletedThisMonth: teamsData?.groupsDeletedThisMonth || 0
          }}
          selectedRows={selectedRows}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
      <div className="team-management-groups-table__explorer" ref={tableHeightRef}>
        {!addUserToGroups && (
          <>
            <HeadingText text={COMMON_TEXT.EXPLORER} color={BLACK_COLORS[800]} />
            <SearchForm
              placeholder={TEAM_MANAGEMENT_TEXT.GROUP_PLACEHOLDER}
              className="team-management-groups-table__explorer__search"
              onSearch={searchGroupsByString}
            />
          </>
        )}
        <div className="team-management-groups-table__explorer__table">
          <CustomTable
            tableColumns={tableColumns}
            tableData={sortedData}
            totalItems={teamsData?.totalGroups || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            rowsPerPageOptions={RowsPerPage}
            isLoading={isLoading}
            rowIdKey="groupName"
            selectedRows={selectedRows}
            sortState={dataSort}
            setSortState={setDataSort}
          />
        </div>
      </div>
      <DeleteGroupModal
        openModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDeleteGroup}
      />
      <ConfirmModal
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        title={TEAM_MANAGEMENT_TEXT.GROUPS_DELETED}
        description={TEAM_MANAGEMENT_TEXT.SELECTED_GROUP_DELETED}
        image={confirmSuccess}
        confirmButtonText={BUTTON_TEXT.OKAY}
      />
    </div>
  );
};

export default Groups;
