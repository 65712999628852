/* eslint-disable @typescript-eslint/no-explicit-any */
import { twMerge } from 'tailwind-merge';
import { ReactNode, ButtonHTMLAttributes } from 'react';

import './buttons.scss';
import Loader from '../loader/loader';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DISABLED = 'disabled',
  DELETE = 'delete',
  PRIMARY_DISABLED = 'primaryDisabled',
  RED = 'red',
  LIGHT_RED = 'lightRed'
}

export type IButtonProps = {
  variant?: ButtonVariants;
  text?: string | ReactNode;
  onClick?: (value: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  image?: string;
  imageStyles?: string;
  style?: React.CSSProperties;
  disableBgColor?: string;
  disableTextColor?: string;
  buttonWidth?: string;
  disabled?: boolean;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const CustomButton = ({
  variant = ButtonVariants.PRIMARY,
  text = 'Button',
  onClick,
  type,
  className,
  image,
  imageStyles,
  disableBgColor,
  disableTextColor,
  buttonWidth,
  disabled = false,
  isLoading,
  ...rest
}: IButtonProps) => {
  return (
    <button
      style={{
        backgroundColor: disableBgColor,
        color: disableTextColor,
        width: buttonWidth
      }}
      className={twMerge(
        variant,
        className,
        'custom-button',
        disabled ? 'custom-button--is-disabled' : ''
      )}
      onClick={!isLoading ? onClick : undefined}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {image && <img src={image} alt="Action" className={imageStyles} />}
      {isLoading ? <Loader /> : text}
    </button>
  );
};

export default CustomButton;
