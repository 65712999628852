import { QueryClient } from 'react-query';

import {
  getNotificationSettings,
  getOrganizationDetails
} from '../../services/organization.service';

export const prefetchNotificationSettings = (queryClient: QueryClient) => {
  queryClient.prefetchQuery(['notificationSettings'], () => getNotificationSettings());
};

export const prefetchOrganization = (queryClient: QueryClient) => {
  queryClient.prefetchQuery(['organization'], () => getOrganizationDetails());
};
