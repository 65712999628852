import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './settings.scss';

import Tabs from '../../components/common/customTabs/CustomTabs';
import { TTabItem } from '../../components/common/customTabs/CustomTabs.interface';
import Account from '../../components/settings/account/Account';
import Notifications from '../../components/settings/notifications/Notifications';
import Organization from '../../components/settings/organization/Organization';
import { ESettingsActiveTab, SettingsTabText, TSettingsTab } from '../../constants/tabsText';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import { setActiveTab, setHeading, updateSkeletonLoader } from '../../store/actions';
import { type IMyProfileState } from '../../store/myProfile/myProfile.interface';

const SettingComponents = {
  [ESettingsActiveTab.ORGANIZATION]: <Organization />,
  [ESettingsActiveTab.NOTIFICATIONS]: <Notifications />,
  [ESettingsActiveTab.ACCOUNT]: <Account />
};

const Settings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isOwner: boolean = useAppSelector<IMyProfileState>((store) => store.MyProfile).isOwner;

  const currentTab = (params?.type as unknown as TSettingsTab) || ESettingsActiveTab.ORGANIZATION;

  const settingTabItems: TTabItem[] = [
    { key: ESettingsActiveTab.ORGANIZATION, text: SettingsTabText.ORGANIZATION },
    { key: ESettingsActiveTab.NOTIFICATIONS, text: SettingsTabText.NOTIFICATIONS },
    { key: ESettingsActiveTab.ACCOUNT, text: SettingsTabText.ACCOUNT, disabled: !isOwner }
  ];

  //handle tab chang
  const handleTabChange = (value: string) => navigate(`/settings/${value}`);

  useEffect(() => {
    dispatch(setHeading('Settings'));
    dispatch(setActiveTab(6));
    return () => {
      dispatch(updateSkeletonLoader({ isLoading: true }));
    };
  }, []);

  return (
    <div className="settings">
      <Tabs items={settingTabItems} onChange={handleTabChange} activeItem={currentTab} />
      {SettingComponents[currentTab]}
    </div>
  );
};

export default Settings;
