import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

import CloseButton from '../../components/common/close-button/CloseButton';
import { errorToastStyle } from '../../configs/styleConstants';
import { routes } from '../../constants/routes';
import { toastAutoCloseDuration } from '../../constants/constants';

interface IErrorResponseData {
  response?: { data?: { message?: string; error?: string }; status: number };
}

const useErrorHandler = ({
  error,
  toastId,
  defaultMessage,
  autoCloseDuration = toastAutoCloseDuration,
  redirectTo404
}: {
  error: unknown;
  toastId: string;
  defaultMessage?: string;
  autoCloseDuration?: number | false;
  redirectTo404?: boolean;
}) => {
  // To trigger an toast message if an error occurs
  if (isAxiosError(error) && error.response?.status === 404 && redirectTo404) {
    //if error status is 404 and we want to redirect it to NOT_FOUND screen then we need to pass redirectTo404 as true from the API call.
    window.location.href = routes.NOT_FOUND;
  } else if (isAxiosError(error)) {
    // To handle error thrown by the axios
    if (error.response?.status === 403) {
      window.location.href = routes.ACCESS_DENIED;
    } else {
      toast.error(
        `${
          error?.response?.data?.message ||
          'Something went wrong on our end. Please refresh the page.'
        }`,
        {
          style: errorToastStyle,
          toastId: toastId,
          closeButton: <CloseButton color={errorToastStyle?.color as string} />,
          autoClose: autoCloseDuration
        }
      );
    }
  } else if ((error as IErrorResponseData)?.response?.data?.message) {
    // To handle error thrown by the backend
    toast.error((error as IErrorResponseData)?.response?.data?.message, {
      style: errorToastStyle,
      toastId: toastId,
      closeButton: <CloseButton color={errorToastStyle?.color as string} />,
      autoClose: autoCloseDuration
    });
  } else {
    // If the above conditions are not met
    toast.error(defaultMessage ? defaultMessage : `Please try again later!`, {
      style: errorToastStyle,
      toastId: toastId,
      closeButton: <CloseButton color={errorToastStyle?.color as string} />,
      autoClose: autoCloseDuration
    });
  }
};

export default useErrorHandler;
