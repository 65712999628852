import { IDelete } from './service.interface';
import authHttp from './authHttp';

const deleteWithAuth = async ({ url, params }: IDelete) => {
  return authHttp
    .delete(url, { params })
    .then((response) => response)
    .catch((error: unknown) => {
      throw error;
    });
};

export default deleteWithAuth;
