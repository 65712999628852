export enum TableStatusTooltipText {
  Active = 'Account Connected',
  Inactive = 'Account Disconnected',
  Failed = 'Seems connection is broken, Could you please follow instructions carefully and retry',
  Unreachable = 'Alert: Connection issue with your account!',
  InProgress = 'Connection Establishing...Please wait',
  Paused = 'Account is Paused',
  Connected = 'Account is Connected'
}

export enum CloudAccountsStatuses {
  Active = 'Active',
  Inactive = 'Inactive',
  Failed = 'Failed',
  Unreachable = 'Unreachable',
  InProgress = 'InProgress',
  Connected = 'Connected',
  Paused = 'paused'
}

export enum CloudAccountsTableStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Failed = 'Failed',
  Unreachable = 'Unreachable',
  InProgress = 'In-Progress',
  Connected = 'Connected',
  Paused = 'Paused'
}

export enum CloudAccountsSlackStatus {
  Active = 'Active',
  Connected = 'Connected',
  Inactive = 'Inactive'
}

export enum CloudAccountsMsTeamsStatus {
  Active = 'Active',
  Connected = 'Connected',
  Inactive = 'Inactive'
}

export enum CloudAccountsEmailStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum CloudAccountsTableActionTooltip {
  Edit = 'Edit',
  Disconnect = 'Disconnect',
  Delete = 'Delete',
  NotAllowed = 'NotAllowed'
}

export enum CloudServiceProvider {
  AWS = 'AWS',
  Azure = 'Azure'
}

const cloudAccounts = {
  TableStatusTooltipText,
  CloudAccountsEmailStatus,
  CloudAccountsTableStatus,
  CloudAccountsSlackStatus,
  CloudAccountsMsTeamsStatus,
  CloudAccountsStatuses,
  CloudAccountsTableActionTooltip,
  CloudServiceProvider
};

export default cloudAccounts;
