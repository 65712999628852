import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './editUserAccessControl.scss';

import EditUserPermissions from '../../components/teamManagement/editUser/EditUserPermissions';
import EditUserGroups from '../../components/teamManagement/editUser/EditUserGroups';
import AddUserToGroups from '../../components/teamManagement/editUser/AddUserToGroups';
import { BUTTON_TEXT, MY_PROFILE_TEXT } from '../../configs';
import { errorToastStyle } from '../../configs/styleConstants';
import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';
import { routes } from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  fetchTeamMgmtUserRequest,
  setHeading,
  updateAccessControlTabType
} from '../../store/actions';
import {
  ITeamManagementInitialValues,
  IUserPermissionsList,
  TTeamMangEditUserTabs
} from '../../store/teamManagement/teamManagement.interface';
import CloseButton from '../../components/common/close-button/CloseButton';
import Tabs from '../../components/common/customTabs/CustomTabs';
import { TTabItem } from '../../components/common/customTabs/CustomTabs.interface';
import { ITeamManagementUserInitialState } from '../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import postWithAuth from '../../services/postWithAuth';
import API_ENDPOINTS from '../../constants/api_endpoints';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import NotFound from '../not-found/NotFound';
import useUpdateTeamUserAccessControlState from '../../hooks/teamManagement/updateTeamUserAccessControlState/useUpdateTeamUserAccessControlState';
import useCheckUserPermissions from '../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import {
  userEditAccessControlTabTypes,
  userEditAccessControlModes
} from '../../enums/teamManagement';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import { confirmSuccess } from '../../assets/images';
import useLoading from '../../hooks/loading/useLoading';
import { Loader } from '../../constants/pages';

const EditUserAccessControl = () => {
  const { userAccessControlTabType } = useAppSelector(
    (store) => store.teamManagement
  ) as ITeamManagementInitialValues;
  const {
    selectedUser,
    permissions: teamMgmtUserPermissions,
    userProfileData
  } = useAppSelector((store) => store.teamManagementUser) as ITeamManagementUserInitialState;

  const isOwner: boolean = userProfileData.isOwner || false;

  const [editUserSelectedPermissions, setEditUserSelectedPermissions] =
    useState<IUserPermissionsList[]>(teamMgmtUserPermissions);
  const [editUserRemoveUserFromGroupsList, setEditUserRemoveUserFromGroupsList] = useState<
    string[]
  >([]);
  const [selectedRowsAddToGroup, setSelectedRowsAddToGroup] = useState<(string | number)[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState<string>('');
  const [confirmModalDescription, setConfirmModalDescription] = useState<string>('');
  const [confirmOKayClickMode, setConfirmOKayClickMode] = useState<number>(1);

  const { loading, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { actionType } = useParams() as { actionType: userEditAccessControlTabTypes };
  const [actionArray] = useState<string[]>([actionType]);

  // USER PERMISSIONS START
  const { canUserEditAnotherUserGroups, canUserEditAnotherUserPermissions } =
    useCheckUserPermissions();
  // USER PERMISSIONS END

  const changeUserTab = (value: string) => {
    if (
      actionArray[0] === userEditAccessControlTabTypes.AddToGroup &&
      value === userEditAccessControlTabTypes.Groups
    ) {
      value = userEditAccessControlTabTypes.AddToGroup;
    }
    navigate(`${routes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL}/${value}`);
    dispatch(updateAccessControlTabType(value as TTeamMangEditUserTabs));
  };

  const modalConfirmOkayClick = () => {
    switch (confirmOKayClickMode) {
      // User removed from group
      case 1: {
        setOpenConfirmModal(false);
        navigate(routes.TEAM_MANAGEMENT_USER);
        break;
      }
      // User added to group(s)
      case 2: {
        setOpenConfirmModal(false);
        handleCancel();
        break;
      }
      // User permissions updated
      case 3: {
        setOpenConfirmModal(false);
        navigate(routes.TEAM_MANAGEMENT_USER);
      }
    }
  };

  const userTabItems: TTabItem[] = [
    {
      key: userEditAccessControlTabTypes.Groups,
      text: TEAM_MANAGEMENT_TEXT.GROUPS,
      disabled: !canUserEditAnotherUserGroups
    },
    {
      key: userEditAccessControlTabTypes.Permissions,
      text: TEAM_MANAGEMENT_TEXT.PERMISSION,
      disabled: !canUserEditAnotherUserPermissions
    }
  ];

  const accessControlComponents = {
    [userEditAccessControlTabTypes.Permissions]: (
      <EditUserPermissions
        editUserSelectedPermissions={editUserSelectedPermissions}
        setEditUserSelectedPermissions={setEditUserSelectedPermissions}
        isOwner={isOwner}
      />
    ),
    [userEditAccessControlTabTypes.Groups]: (
      <EditUserGroups
        editUserRemoveUserFromGroupsList={editUserRemoveUserFromGroupsList}
        setEditUserRemoveUserFromGroupsList={setEditUserRemoveUserFromGroupsList}
      />
    )
  };

  const updateUserProfile = () => {
    dispatch(fetchTeamMgmtUserRequest({ email: selectedUser }));
  };

  const handleCancel = () => {
    if (actionType === userEditAccessControlTabTypes.AddToGroup) {
      updateUserProfile();
      navigate(routes.TEAM_MANAGEMENT_USER);
    } else {
      navigate(routes.TEAM_MANAGEMENT_USER);
    }
  };

  const handleRemoveUserFromGroups = async () => {
    if (editUserRemoveUserFromGroupsList.length > 0) {
      startLoading();
      try {
        const response = await postWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_REMOVE_USER_FROM_GROUPS,
          payload: { email: selectedUser, groupNames: editUserRemoveUserFromGroupsList }
        });
        if (response) {
          setConfirmModalTitle(MY_PROFILE_TEXT.USER_REMOVED);
          setConfirmModalDescription(MY_PROFILE_TEXT.USER_REMOVED_SUCCESSFULLY_FROM_GROUP);
          setOpenConfirmModal(true);
          setConfirmOKayClickMode(1);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'updateUserPermissionsFail',
          defaultMessage:
            'Your request to update user permissions failed due to unknown reasons! Please try again later'
        });
      } finally {
        stopLoading();
      }
    } else {
      toast.error(TEAM_MANAGEMENT_TEXT.REMOVE_GROUP_FROM_USER_NO_SELECTION, {
        style: errorToastStyle,
        toastId: 'removeGroupFromUserNoSelection',
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
    }
  };

  const handleEditPermissions = async () => {
    if (editUserSelectedPermissions.length > 0) {
      startLoading();
      try {
        const response = await postWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_EDIT_USER_ACCESS_CONTROL,
          payload: { email: selectedUser, permissions: editUserSelectedPermissions }
        });
        if (response) {
          setConfirmModalTitle(MY_PROFILE_TEXT.PERMISSION_UPDATED);
          setConfirmModalDescription(MY_PROFILE_TEXT.USER_PERMISSION_SUCCESSFULLY_UPDATED);
          setOpenConfirmModal(true);
          setConfirmOKayClickMode(3);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'updateUserPermissionsFail',
          defaultMessage:
            'Your request to update user permissions failed due to unknown reasons! Please try again later'
        });
      } finally {
        stopLoading();
      }
    } else {
      toast.error(MY_PROFILE_TEXT.USER_PERMISSIONS_UPDATE_NO_SELECTION, {
        style: errorToastStyle,
        toastId: 'updateAccessControlNoSelection',
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
    }
  };

  const handleAddUserToGroups = async () => {
    if (selectedRowsAddToGroup.length > 0) {
      startLoading();
      try {
        const response = await postWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_ADD_USER_TO_GROUPS,
          payload: { email: selectedUser, groupNames: selectedRowsAddToGroup }
        });
        if (response) {
          setConfirmModalTitle(MY_PROFILE_TEXT.USER_ADDED);
          setConfirmModalDescription(MY_PROFILE_TEXT.USER_SUCCESSFULLY_ADDED_TO_GROUPS);
          setOpenConfirmModal(true);
          setConfirmOKayClickMode(2);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'addUserToGroupsFail',
          defaultMessage:
            'Your request to add user to groups failed due to unknown reasons! Please try again later'
        });
      } finally {
        stopLoading();
      }
    } else {
      toast.error(TEAM_MANAGEMENT_TEXT.ADD_USER_TO_GROUPS_NO_SELECTION, {
        style: errorToastStyle,
        toastId: 'addUserToGrouplNoSelection',
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
    }
  };

  const handleSave = () => {
    const updateAction = userAccessControlTabType as unknown as userEditAccessControlModes;
    if (actionType === userEditAccessControlTabTypes.AddToGroup) {
      handleAddUserToGroups();
    } else if (
      updateAction === userEditAccessControlModes.Permissions ||
      updateAction === userEditAccessControlModes.editPermissions
    ) {
      handleEditPermissions();
    } else if (
      updateAction === userEditAccessControlModes.Groups ||
      updateAction === userEditAccessControlModes.editGroups
    ) {
      handleRemoveUserFromGroups();
    }
  };

  useEffect(() => {
    dispatch(setHeading(MY_PROFILE_TEXT.EDIT_ACCESS_CONTROL));
  }, []);

  useUpdateTeamUserAccessControlState({
    callbackFunc: (userPermissions) => {
      // Fetch user permissions on reload
      setEditUserSelectedPermissions(userPermissions);
    }
  });

  if (selectedUser) {
    return (
      <div className="edit-user-access-control">
        <div className="edit-user-access-control__head">
          <div className="edit-user-access-control__tabs">
            <Tabs
              items={userTabItems}
              onChange={changeUserTab}
              activeItem={
                actionType === userEditAccessControlTabTypes.AddToGroup
                  ? userEditAccessControlTabTypes.Groups
                  : actionType
              }
            />
          </div>
          <div className="edit-user-access-control__head__buttons">
            <div className="edit-user-access-control__head__buttons__right">
              <button className="cancel" onClick={handleCancel}>
                {BUTTON_TEXT.CANCEL}
              </button>
              <button className="save" onClick={handleSave}>
                {loading ? <Loader /> : BUTTON_TEXT.SAVE}
              </button>
            </div>
          </div>
        </div>
        {actionType === userEditAccessControlTabTypes.AddToGroup ? (
          <AddUserToGroups
            addUserToGroups={true}
            selectedRowsAddToGroup={selectedRowsAddToGroup}
            setSelectedRowsAddToGroup={setSelectedRowsAddToGroup}
          />
        ) : (
          <div className="edit-user-access-control__content">
            {accessControlComponents[actionType]}
          </div>
        )}
        <ConfirmModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          title={confirmModalTitle}
          description={confirmModalDescription}
          image={confirmSuccess}
          confirmButtonText={BUTTON_TEXT.OKAY}
          descriptionWidth="88%"
          handleOkClick={modalConfirmOkayClick}
        />
      </div>
    );
  }

  return <NotFound />;
};

export default EditUserAccessControl;
