import { PermissionApiEndpointsEnums } from '../../../enums/common';

export default [
  { key: 'canUserViewCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.GET_ACCOUNT },
  { key: 'canUserAddCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.ADD_ACCOUNT },
  { key: 'canUserViewAccountInfo', apiEndpoint: PermissionApiEndpointsEnums.GET_ACCOUNT },
  {
    key: 'canUserViewMsTeamAccount',
    apiEndpoint: PermissionApiEndpointsEnums.SLACK_GET_ACCOUNT_TO_CHANNEL_MAPPING
  },
  {
    key: 'canUserViewSlackAccount',
    apiEndpoint: PermissionApiEndpointsEnums.SLACK_GET_ACCOUNT_TO_CHANNEL_MAPPING
  },
  { key: 'canUserViewEmailList', apiEndpoint: PermissionApiEndpointsEnums.EMAIL_LIST },
  { key: 'canUserEditCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.EDIT_ACCOUNT },
  {
    key: 'canUserDisconnectCloudAccount',
    apiEndpoint: PermissionApiEndpointsEnums.DISCONNECT_ACCOUNT
  },
  { key: 'canUserResumeCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.RESUME_ACCOUNT },
  { key: 'canUserPauseCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.PAUSE_ACCOUNT },
  {
    key: 'canUserReconnectCloudAccount',
    apiEndpoint: PermissionApiEndpointsEnums.RECONNECT_ACCOUNT
  },
  {
    key: 'canUserAddEmailNotification',
    apiEndpoint: PermissionApiEndpointsEnums.ADD_EMAIL_ACCOUNT
  },
  { key: 'canUserDeleteEmail', apiEndpoint: PermissionApiEndpointsEnums.REMOVE_EMAIL_ACCOUNT },
  {
    key: 'canUserAddEmailRecipients',
    apiEndpoint: PermissionApiEndpointsEnums.ADD_EMAIL_ACCOUNT
  },
  {
    key: 'canUserRemoveEmailRecipients',
    apiEndpoint: PermissionApiEndpointsEnums.REMOVE_EMAIL_ACCOUNT
  },
  {
    key: 'canUserAddSlack',
    apiEndpoint: PermissionApiEndpointsEnums.SLACK_ADD_ACCOUNT_TO_CHANNEL
  },
  { key: 'canUserDisconnectMsTeam', apiEndpoint: PermissionApiEndpointsEnums.SLACK_DISCONNECT },
  { key: 'canUserDisconnectSlack', apiEndpoint: PermissionApiEndpointsEnums.SLACK_DISCONNECT },
  {
    key: 'canUserReconfigureSlack',
    apiEndpoint: PermissionApiEndpointsEnums.LIST_SLACK_CHANNELS
  },
  { key: 'canUserEditCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.EDIT_ACCOUNT },
  { key: 'canUserDeleteCloudAccount', apiEndpoint: PermissionApiEndpointsEnums.DELETE_ACCOUNT },
  {
    key: 'canUserDisconnectCloudAccount',
    apiEndpoint: PermissionApiEndpointsEnums.DISCONNECT_ACCOUNT
  },
  { key: 'canUserEditAnotherUser', apiEndpoint: PermissionApiEndpointsEnums.EDIT_OTHER_USERS },
  {
    key: 'canUserEditAnotherUserPermissions',
    apiEndpoint: PermissionApiEndpointsEnums.EDIT_OTHER_USERS_PERMISSION
  },
  {
    key: 'canUserEditAnotherUserGroups',
    apiEndpoint: PermissionApiEndpointsEnums.REMOVE_USER_FROM_GROUPS
  },
  {
    key: 'canUserEditAnotherUserPermissions',
    apiEndpoint: PermissionApiEndpointsEnums.EDIT_OTHER_USERS_PERMISSION
  },
  {
    key: 'canUserEditNotifications',
    apiEndpoint: PermissionApiEndpointsEnums.EDIT_NOTIFICATION_SETTINGS
  },
  { key: 'canUserEditOrganization', apiEndpoint: PermissionApiEndpointsEnums.EDIT_ORG_DETAILS },
  { key: 'canUserViewUsersTable', apiEndpoint: PermissionApiEndpointsEnums.LIST_USERS },
  { key: 'canUserViewGroupsTable', apiEndpoint: PermissionApiEndpointsEnums.LIST_GROUPS },
  {
    key: 'canUserViewDeactivatedUsersTable',
    apiEndpoint: PermissionApiEndpointsEnums.LIST_DEACTIVATED_USERS
  },
  { key: 'canUserActivateAnotherUser', apiEndpoint: PermissionApiEndpointsEnums.ACTIVATE_USER },
  { key: 'canUserDeleteAnotherUser', apiEndpoint: PermissionApiEndpointsEnums.DELETE_USER },
  {
    key: 'canUserEditAnotherUserGroups',
    apiEndpoint: PermissionApiEndpointsEnums.REMOVE_USER_FROM_GROUPS
  },
  {
    key: 'canUserEditAnotherUserPermissions',
    apiEndpoint: PermissionApiEndpointsEnums.EDIT_OTHER_USERS_PERMISSION
  },
  { key: 'canUserAddGroup', apiEndpoint: PermissionApiEndpointsEnums.ADD_GROUP },
  { key: 'canUserDeleteGroup', apiEndpoint: PermissionApiEndpointsEnums.DELETE_GROUP },
  { key: 'canUserViewGroup', apiEndpoint: PermissionApiEndpointsEnums.GET_GROUP_USERS },
  { key: 'canUserEditGroup', apiEndpoint: PermissionApiEndpointsEnums.EDIT_GROUP },
  {
    key: 'canUserRemoveUsersFromGroup',
    apiEndpoint: PermissionApiEndpointsEnums.REMOVE_USERS_FROM_GROUP
  },
  { key: 'canUserAddAnotherUser', apiEndpoint: PermissionApiEndpointsEnums.ADD_USER },
  {
    key: 'canUserDeactivateAnotherUser',
    apiEndpoint: PermissionApiEndpointsEnums.DEACTIVATE_USER
  },
  { key: 'canUserViewAnotherUser', apiEndpoint: PermissionApiEndpointsEnums.GET_USER },
  {
    key: 'canUserEditAnotherUserPermissions',
    apiEndpoint: PermissionApiEndpointsEnums.EDIT_OTHER_USERS_PERMISSION
  },
  {
    key: 'canUserRemoveOtherUsersFromGroup',
    apiEndpoint: PermissionApiEndpointsEnums.REMOVE_USERS_FROM_GROUP
  },
  {
    key: 'canUserResetOtherUsersPassword',
    apiEndpoint: PermissionApiEndpointsEnums.RESET_PASSWORD
  }
];
