import { useState } from 'react';

/**
 *
 * @returns loading as boolean
 * @methods startLoading : sets loading to true, stopLoading : sets loading to false
 */
const useLoading = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  return {
    loading,
    startLoading,
    stopLoading
  };
};

export default useLoading;
