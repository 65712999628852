import React, { useRef } from 'react';

import { FormContainerProps } from './formContainer';
import './authFormContainer.scss';

import '../../../assets/styles/index.scss';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import Typography from '../typography/Typography';
// import useToastMessagePosition from '../../../hooks/toasts/useToastMessagePosition';
import IMAGES from '../../../assets';

interface AuthFormContainerProps extends FormContainerProps {
  handleBackToPrev?: () => void;
  backToPrevScreenText?: string;
}

const AuthFormContainer = ({
  heading,
  children,
  handleBackToPrev,
  backToPrevScreenText
}: AuthFormContainerProps) => {
  const formContainerRef = useRef<HTMLDivElement>(null);

  // --- keeping the commented code for now, once testing is done will remove them from the codebase ---
  // const { toastTop, toastLeft } = useToastMessagePosition({
  //   ref: formContainerRef,
  //   offsetBottom: 40
  // });

  const handleNavigateToPrev = () => {
    if (handleBackToPrev) handleBackToPrev();
  };

  return (
    <React.Fragment>
      <PaperCustom variant="medium" className="authFormContainer">
        <div className="authFormContainer__card" ref={formContainerRef}>
          {backToPrevScreenText && (
            <div className="authFormContainer__card__back" onClick={handleNavigateToPrev}>
              <img src={IMAGES.backArrow} />
              <Typography variant="body-3" size="medium">
                &nbsp;&nbsp;Back to {backToPrevScreenText}
              </Typography>
            </div>
          )}
          <Typography
            variant="subHeading-1"
            size="medium"
            as="h1"
            className="authFormContainer__card__title"
          >
            {heading}
          </Typography>
          <div className="authFormContainer__card__component">{children}</div>
        </div>
      </PaperCustom>
      {/* <style>{`.Toastify .Toastify__toast-container{top: ${toastTop}px; left: ${toastLeft}px}`}</style> */}
    </React.Fragment>
  );
};

export default AuthFormContainer;
