const routes = {
  //authentication routes
  SIGN_IN: '/',
  SIGN_UP: '/sign-up',
  OTP: '/otp-verification',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SET_PASSWORD: '/set-password',
  FORCE_CHANGE_PASSWORD: '/force-change-password',

  //not found
  NOT_FOUND: '/not-found',

  // access denied
  ACCESS_DENIED: '/access-denied',

  //terms and policy
  PRIVACY_POLICY: 'https://cloudinspot.ai/privacy-policy/',
  TERMS_OF_USE: 'https://cloudinspot.ai/terms-of-use/',

  //dashboard
  // will uncomment this once we start working on dashboard
  // DASHBOARD: '/dashboard',

  //My Profile
  MY_PROFILE: '/my-profile',

  //Cloud Accounts
  CLOUD_ACCOUNTS: '/cloud-account',
  CONNECT_TO_AZURE: '/cloud-account/azure/connect-to-azure',
  // CONNECT_AZURE: '/cloud-accounts/connect/azure',
  // CONNECT_AZURE_CONFIRM: '/cloud-accounts/confirm-connection/azure',
  RE_CONNECT_TO_AZURE: '/cloud-account/azure/re-connect/:subscriptionKey',
  CLOUD_ACCOUNTS_VIEW: '/cloud-account/view/:id',
  CLOUD_ACCOUNTS_EDIT: '/cloud-account/view/:id/edit',
  CLOUD_ACCOUNTS_VIEW_MSTEAMS: '/cloud-account/view/msteam/:id',
  CLOUD_ACCOUNTS_VIEW_SLACK: '/cloud-account/view/slack/:id',
  CLOUD_ACCOUNTS_VIEW_EMAIL: '/cloud-account/view/email/:id',
  CONNECT_ACCOUNT: '/cloud-accounts/connect/aws',
  RE_CONNECT_ACCOUNT: '/cloud-account/aws/re-connect/:accountID',
  DISCONNECT_ACCOUNT: '/cloud-account/view/disconnect/:id',
  CONNECT_SLACK_ACCOUNT: '/cloud-account/view/slack/connect/:id',
  CONNECT_SLACK_ADDITIONAL_STEPS: '/cloud-account/view/slack/additional-steps/:id',
  CONNECT_MSTEAM_ACCOUNT: '/cloud-account/view/msteam/connect/:id',
  CONNECT_EMAIL_ACCOUNT: '/cloud-account/view/email/connect/:id',
  CLOUD_ACCOUNTS_VIEW_EMAIL_LIST: '/cloud-account/view/emaillist',

  //Anomaly
  NOTIFICATIONS: '/notifications',

  //Team Management
  TEAM_MANAGEMENT: '/team-management',
  TEAM_MANAGEMENT_TABLE_TYPE: '/team-management/:tabletype',
  TEAM_MANAGEMENT_USERS_TABLE: '/team-management/users',
  TEAM_MANAGEMENT_GROUPS_TABLE: '/team-management/groups',
  TEAM_MANAGEMENT_DEACTIVATED_USERS_TABLE: '/team-management/deactivated-users',
  ADD_USER: '/team-management/add-user',
  ADD_GROUP: '/team-management/add-group',
  TEAM_MANAGEMENT_USER: '/team-management/user',
  TEAM_MANAGEMENT_USER_ACCESS_CONTROL_PARAMETRIC:
    '/team-management/user/access-control/:actionType',
  TEAM_MANAGEMENT_USER_ACCESS_CONTROL: '/team-management/user/access-control',
  TEAM_MANAGEMENT_VIEW_GROUP_BY_NAME: '/team-management/group/:groupname',
  TEAM_MANAGEMENT_EDIT_GROUP: '/team-management/group/:groupname/edit',
  TEAM_MANAGEMENT_VIEW_GROUP: '/team-management/group',
  TEAM_MANAGEMENT_VIEW_GROUP_GROUP_NAME: '/team-management/group/:groupname',

  //Settings
  SETTINGS: '/settings/:type',
  SETTINGS_ORGANIZATION: '/settings/organization',

  //FAQ
  FAQ: '/faq',
  //Insights
  INSIGHTS: '/insights',

  FEEDBACK: '/feedback'
};

const parametricRoutes = {
  TEAM_MANAGEMENT_USER_ACCESS_CONTROL_ADD_TO_GROUP:
    '/team-management/user/access-control/add-to-groups',
  TEAM_MANAGEMENT_USER_ACCESS_CONTROL_EDIT_GROUP:
    '/team-management/user/access-control/edit-groups',
  TEAM_MANAGEMENT_USER_ACCESS_CONTROL_EDIT_PERMISSIONS:
    '/team-management/user/access-control/edit-permissions'
};

const authRoutes = [
  // If logged-in user visits these routes,
  // They will be redirected to dashboard
  routes.SIGN_IN,
  routes.SIGN_UP,
  routes.OTP,
  routes.FORGOT_PASSWORD,
  routes.MY_PROFILE
];

export { authRoutes, parametricRoutes, routes };
