import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';

import { AddUserToGroup } from './AddUserToGroup';
import './addUsersInGroupModal.scss';
import { IFetchUserData } from './AddUsers';

import IMAGES from '../../../../assets';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import {
  IAddUserToGroup,
  ITeamManagementInitialValues,
  ITeamMangUser
} from '../../../../store/teamManagement/teamManagement.interface';
import { setAddUsersToGroupList, updateAddUsersToGroupList } from '../../../../store/actions';
import getWithAuth from '../../../../services/getWithAuth';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { IFetchUsersAPIResponse } from '../groups.interface';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { TEAM_MANAGEMENT_TEXT } from '../../../../configs/teamManagement';
import Typography from '../../../common/typography/Typography';
import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';
import CloseIconBorder from '../../../common/icons/closeIconBorder/CloseIconBorder';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import { textEllipsis } from '../../../../utils';
import NoDataFound from '../../../common/noDataFound/noDataFound';
import { TSearchFormValues } from '../../../common/searchForm/searchForm.types';
import SearchForm from '../../../common/searchForm/SearchForm';
import Loader from '../../../common/loader/loader';

export interface IAddUsersInGroupModalProps {
  open: boolean;
  closeModal: () => void;
  handleAdd: () => void;
  currentUsers: ITeamMangUser[];
  isLoadingStart: boolean;
}

const tooltipCharLimitValue = 25;

const AddUsersInGroupModal = ({
  open,
  closeModal,
  handleAdd,
  currentUsers,
  isLoadingStart
}: IAddUsersInGroupModalProps) => {
  const [limit, setLimit] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const { loading, startLoading, stopLoading } = useLoading();
  const boxRef = React.createRef<HTMLDivElement>();

  const dispatch = useAppDispatch();
  const { addUsersToGroupAPIList, selectedUserToAddGroupsList } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const deselectUser = (user: string) => {
    const newList = selectedUserToAddGroupsList.filter((item) => item.email != user);
    dispatch(updateAddUsersToGroupList(newList));
  };

  const addToSelectedUsers = (user: IAddUserToGroup) => {
    const isUserSelected = selectedUserToAddGroupsList.some(
      (selectedUser) => selectedUser.email === user.email
    );

    if (selectedUserToAddGroupsList.includes(user)) {
      deselectUser(user.email);
      return;
    }
    if (isUserSelected) {
      const newList = selectedUserToAddGroupsList.filter((item) => item.email !== user.email);
      dispatch(updateAddUsersToGroupList(newList));
      return;
    }
    const addUsersList = [...selectedUserToAddGroupsList, user];
    dispatch(updateAddUsersToGroupList(addUsersList));
    return;
  };

  const handleAddUsers = async () => {
    handleAdd();
  };

  const handleClose = () => {
    closeModal();
    dispatch(updateAddUsersToGroupList([]));
  };

  const fetchUsers = async ({ query }: IFetchUserData) => {
    startLoading();
    setNoDataFound(false);
    try {
      let params;
      if (query) {
        params = {
          limit: limit,
          offset: 1,
          filterUser: query
        };
      } else {
        params = {
          limit: limit,
          offset: 1
        };
      }
      const response = (
        await getWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_LIST_USERS,
          params: params
        })
      )?.data as unknown as IFetchUsersAPIResponse;

      //convert response to IAddUserToGroup format
      const usersListFromAPI: IAddUserToGroup[] = response.users.map((user) => ({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        jobTitle: user.jobTitle
      }));

      if (response.users.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }

      setCount(response.count); //setting count
      dispatch(setAddUsersToGroupList(usersListFromAPI));
      setApiCalled(false);
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'FetchUsersFail'
      });
      setNoDataFound(true);
    } finally {
      stopLoading();
    }
  };

  const handleSearchQuery = async ({ query }: TSearchFormValues) => {
    fetchUsers({ query });
  };

  useEffect(() => {
    // Attach the scroll event listener to the box element
    const handleScroll = () => {
      const boxElement = boxRef.current;
      if (!boxElement || apiCalled) return; //return if api is called or don't have reference to the box element

      const boxHeight = boxElement.clientHeight; //height of the box
      const scrollPosition = boxElement.scrollTop; //position of the scroll
      const contentHeight = boxElement.scrollHeight; //height of the total content inside the box

      const threshold = 0.7; // 70% threshold to have a seamless UX

      if (
        boxHeight + scrollPosition > threshold * contentHeight && // scroll is crossed threshold
        count - addUsersToGroupAPIList.length > 0
      ) {
        // You have reached the bottom of the box
        //update the limit and call API with updated limit value
        setLimit((prev) => prev + 10);
        setApiCalled(true);
      }
    };

    const boxElement = boxRef.current;
    if (boxElement) {
      boxElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Detach the scroll event listener on cleanup
      if (boxElement) {
        boxElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [boxRef]);

  useEffect(() => {
    // on mount call to the API
    fetchUsers({});
  }, [limit]);

  return (
    <Modal open={open} data-testid="commonConfirmModal" id="common_add_users_in_group_modal">
      <div
        className={`common_add_users_in_group_modal ${loading ? 'add_users_in_group_modal' : ''}`}
      >
        <div className="modal_header">
          {/* Header of Modal */}
          <Typography variant="subHeading-1" size="semiBold">
            {TEAM_MANAGEMENT_TEXT.ADD_USERS}
          </Typography>
          {/* Cross icon */}
          <img onClick={handleClose} src={IMAGES.closeIcon} alt="Close Modal" />
        </div>
        <SearchForm
          placeholder={TEAM_MANAGEMENT_TEXT.SEARCH_USER}
          className="common_add_users_in_group_modal__search"
          onSearch={handleSearchQuery}
          shouldShowSearchButton={true}
        />
        <div className="add-users-in-group-container">
          <div className="search_and_result_container">
            {/* input box */}

            {/* Searched users List */}
            <div ref={boxRef} className="results_list">
              {addUsersToGroupAPIList.map((user, index) => (
                <AddUserToGroup
                  user={user}
                  key={index}
                  addToSelectedUsers={() => addToSelectedUsers(user)}
                  index={index}
                  selectedUsers={selectedUserToAddGroupsList}
                  currentUsers={currentUsers}
                />
              ))}
            </div>
            {/* if search user not found then render a component */}
            {noDataFound && <NoDataFound />}
          </div>
          {/* Horizontal Ruler for Mobile and Tablet View */}
          <div className="block border container__separator" />

          {/* Selected Users List */}
          <div className="selected_users_list_container">
            <div className="selected_users_list_container__heading">
              <Typography variant="subHeading-2" size="semiBold">
                {TEAM_MANAGEMENT_TEXT.SELECTED_USERS}
              </Typography>
              <Typography variant="body-2" size="regular">
                &#40;{selectedUserToAddGroupsList.length}&nbsp;{TEAM_MANAGEMENT_TEXT.USER_SELECTED}
                &#41;
              </Typography>
            </div>
            <div className="results_list">
              {selectedUserToAddGroupsList.map((user, index) => {
                return (
                  <TooltipCustom
                    text={
                      <div className="user-tooltip">
                        <Typography
                          variant="caption"
                          size="regular"
                          as="p"
                          style={{ textAlign: 'left' }}
                        >
                          {user.name}
                        </Typography>
                      </div>
                    }
                    limitWidth={false}
                    conditionToDisplay={user.name?.length >= tooltipCharLimitValue}
                    containerProps={{
                      className: 'results_list__item'
                    }}
                    key={index}
                  >
                    <Typography variant="body-3" size="regular" as="span">
                      {textEllipsis({ text: user.name, charLimit: tooltipCharLimitValue })}
                    </Typography>
                    <CloseIconBorder
                      color={BLACK_COLORS[700]}
                      containerProps={{
                        className: 'results_list__item__close-icon',
                        onClick: () => deselectUser(user.email)
                      }}
                    />
                  </TooltipCustom>
                );
              })}
            </div>
          </div>
        </div>
        {/* Buttons  */}
        <div className="cancel_add_buttons">
          <button onClick={handleClose} className="button cancel ">
            Cancel
          </button>
          <button onClick={handleAddUsers} className="button add">
            {isLoadingStart ? <Loader /> : 'Add'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUsersInGroupModal;
