import type { AxiosResponse } from 'axios';

import API_ENDPOINTS from '../../constants/api_endpoints';
import {
  ITeamMangUser,
  IUserPermissionsList,
  TUserSummary
} from '../../store/teamManagement/teamManagement.interface';
import { ITeamMgmtUserProfile } from '../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import getWithAuth from '../getWithAuth';
import {
  TGetArchivedUsersResponse,
  TGetGroupPermissionsResponse,
  TGetGroupsResponse,
  TGetTeamUsersResponse
} from '../types/teamManagement.endpoints';

//TODO : Create seprate file in services for api calls as Cloud Service
export const fetchTeams = async (rows: number, page: number, search: string = '') => {
  const params = {
    limit: rows,
    offset: page,
    ...(search && { filterGroup: search })
  };
  const response: AxiosResponse<TGetGroupsResponse> = await getWithAuth({
    url: API_ENDPOINTS.TEAM_MGMT_LIST_GROUPS,
    params
  });
  return response.data as TGetGroupsResponse;
};

export const fetchUsers = async (rows: number, page: number, search?: string) => {
  const params: Record<string, string | number | boolean> = {
    limit: rows,
    offset: page,
    includeUnverified: true,
    ...(search && { filterUser: search })
  };

  const response: AxiosResponse<{ users: ITeamMangUser[]; count: number } & TUserSummary> =
    await getWithAuth({
      url: API_ENDPOINTS.TEAM_MGMT_LIST_USERS,
      params
    });
  return response?.data as { users: ITeamMangUser[]; count: number } & TUserSummary;
};

export const fetchTeamUser = async (email: string) => {
  const response = await getWithAuth({
    url: API_ENDPOINTS.TEAM_MGMT_GET_USER,
    params: { email }
  });

  const permissionsReponse = await getWithAuth({
    url: API_ENDPOINTS.TEAM_MGMT_GET_USER_ACCESS_CONTROL,
    params: { email }
  });

  const permissions = permissionsReponse?.data?.user?.permissions as IUserPermissionsList[];
  const data = response?.data?.user as ITeamMgmtUserProfile;

  const teamUser = {
    userProfileData: data,
    permissions
  };

  return teamUser;
};

export const fetchArchivedUsers = async (rows: number, page: number, search?: string) => {
  const params: Record<string, string | number | boolean> = {
    limit: rows,
    offset: page,
    ...(search && { filterUser: search })
  };

  const response: AxiosResponse<TGetArchivedUsersResponse> = await getWithAuth({
    url: API_ENDPOINTS.TEAM_MGMT_LIST_ARCHIVED_USERS,
    params
  });
  return response?.data as TGetArchivedUsersResponse;
};

export const fetchTeamUsers = async (teamName: string, rows: number, page: number) => {
  const resp: AxiosResponse<TGetTeamUsersResponse> = await getWithAuth({
    url: API_ENDPOINTS.TEAM_MGMT_GET_GROUP_USERS,
    params: {
      groupName: teamName,
      limit: rows,
      offset: page
    }
  });
  return resp?.data as TGetTeamUsersResponse;
};

export const fetchTeamPermissions = async (groupName: string) => {
  const response: AxiosResponse<TGetGroupPermissionsResponse> = await getWithAuth({
    url: API_ENDPOINTS.TEAM_MGMT_GET_GROUP_PERMISSIONS,
    params: {
      groupName
    }
  });

  return response?.data as TGetGroupPermissionsResponse;
};
