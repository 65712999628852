// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { confirmSuccess } from '../../assets/images';
import confirmError from '../../assets/images/errorExclamation.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useSignOut from '../../hooks/sign-out/useSignOut';
import { IUserProfileData } from '../../pages/myProfile/myProfile.interface';
import { fetchMyProfile } from '../../store/actions';
import ConfirmModal from '../common/confirmModal/ConfirmModal';

const PermissionUpdateModal = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const myProfileData: IUserProfileData = useAppSelector((store) => store.MyProfile);
  const isUserDeactivated = myProfileData.deactivateUser;
  const isUserPasswordReset = myProfileData.resetUserPassword;

  const { handleClearUserData } = useSignOut();

  const handleOkClick = () => {
    handleClose();
    // Navigate to permissions page
    window.open('/my-profile', '_self');
  };

  const handleSignOutUser = async () => {
    await handleClearUserData();
    setVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
    dispatch(
      fetchMyProfile({
        ...myProfileData,
        permissionUpdated: false,
        deactivateUser: false,
        resetUserPassword: false
      })
    );
  };

  useEffect(() => {
    if (isUserDeactivated || isUserPasswordReset || myProfileData.permissionUpdated) {
      setVisible(true);
    }
  }, [isUserDeactivated, isUserDeactivated, myProfileData.permissionUpdated]);

  return (
    <div>
      <ConfirmModal
        open={visible}
        setOpen={setVisible}
        title="Permissions Updated!"
        description="Your permissions has been updated. Click 'View Permissions' to see the changes"
        image={confirmSuccess}
        handleOkClick={handleOkClick}
        handleClose={handleClose}
        confirmButtonText="View Permissions"
        closeBtnText="Close"
        descriptionWidth="88%"
      />
      {(isUserDeactivated || isUserPasswordReset) && (
        <ConfirmModal
          open={visible}
          setOpen={setVisible}
          title={isUserDeactivated ? 'Account Deactivated!' : 'Your password has been reset!'}
          description={
            isUserDeactivated
              ? 'Your account has been deactivated. Please contact owner for further assistance'
              : 'Please check your email for the credentials and sign in again.'
          }
          image={confirmError}
          handleClose={handleSignOutUser}
          descriptionWidth="88%"
          confirmButtonText="OKAY"
          handleOkClick={handleSignOutUser}
        />
      )}
    </div>
  );
};

export default PermissionUpdateModal;
