import {
  UPDATE_BREADCRUMBS,
  UPDATE_LOADERID,
  UPDATE_LOADING_STATUS,
  UPDATE_SKELETON_LOADING_STATUS
} from './common.actionTypes';
import { ICommonReducerInitialState, ICommonReduceraction } from './common.interface';

const commonReducerInitialState: ICommonReducerInitialState = {
  isLoading: false,
  showSkeletonLoader: true,
  breadcrumbs: [],
  loaderId: ''
};

const commonReducer = (state = commonReducerInitialState, action: ICommonReduceraction) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        isLoading: payload.isLoading
      };
    case UPDATE_SKELETON_LOADING_STATUS:
      return {
        ...state,
        showSkeletonLoader: payload.isLoading
      };
    case UPDATE_BREADCRUMBS:
      return { ...state, breadcrumbs: payload };
    case UPDATE_LOADERID:
      return { ...state, loaderId: payload };
    default:
      return state;
  }
};

export default commonReducer;
