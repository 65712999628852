import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { ORGANIZATION_REFRESH_INTERVAL } from '../../constants/constants';
import { getAuthTokens } from '../../services/getAuthTokensAndURL';
import { getOrganizationDetails } from '../../services/organization.service';
import useOrganizationStore from '../../store/orgnization/useOrgnizationStore';
import useIsAuthenticated from '../is-authenticated/useIsAuthenticated';

const useFetchOrganizationDetails = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const setOrganization = useOrganizationStore().setOrganization;

  const {
    isLoading,
    data: orgData,
    refetch: refetchOrganization
  } = useQuery(['organization'], () => getOrganizationDetails(), {
    staleTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: ORGANIZATION_REFRESH_INTERVAL,
    enabled: isAuthenticated
  });

  useEffect(() => {
    if (orgData) {
      setOrganization({
        organizationId: JSON.parse(localStorage.getItem('organization') || '')?.organizationId,
        organizationName: orgData.organization.name,
        organizationTimeZone: orgData.organization.timezone,
        organizationAbbreviation: orgData.organization.abbreviation,
        organizationGmtOffsetName: orgData.organization.gmtOffsetName
      });

      const { organizationId } = getAuthTokens();
      localStorage.setItem(
        'organization',
        JSON.stringify({
          organizationId: organizationId,
          organizationName: orgData.organization.name,
          organizationAbbreviation: orgData.organization.abbreviation,
          organizationGmtOffsetName: orgData.organization.gmtOffsetName,
          organizationTimeZone: orgData.organization.timezone
        })
      );
    }
  }, [orgData]);

  return { isLoading, orgData, refetchOrganization };
};

export default useFetchOrganizationDetails;
