import {
  UPDATE_SELECTED_USER,
  UPDATE_TEAM_MGMT_USER_PERMISSIONS,
  UPDATE_TEAM_MGMT_USER_PROFILE_DATA,
  UPDATE_TEAM_MGMT_USER_PROFILE_DATA_WITH_PERMISSIONS
} from './teamManagementUser.actionTypes';
import {
  ITeamManagementUserInitialState,
  ITeamManagementUserActions
} from './teamManagementUser.interface';

const teamManagementUserInitialState: ITeamManagementUserInitialState = {
  selectedUser: (localStorage.getItem('teamMgmtUserProfile') as unknown as string)
    ? (localStorage.getItem('teamMgmtUserProfile') as unknown as string)
    : '',
  userProfileData: {
    email: '',
    firstName: '',
    groups: [],
    jobTitle: '',
    lastName: '',
    onBoardedOn: '',
    organizationId: 1,
    url: '',
    isProfileImageValid: false,
    isOwner: false,
    isVerified: false
  },
  permissions: []
};

const teamManagementUserReducer = (
  state: ITeamManagementUserInitialState = teamManagementUserInitialState,
  actions: ITeamManagementUserActions
) => {
  const { type, payload } = actions;

  switch (type) {
    case UPDATE_SELECTED_USER:
      return { ...state, selectedUser: payload };

    case UPDATE_TEAM_MGMT_USER_PROFILE_DATA:
      return { ...state, userProfileData: payload };

    case UPDATE_TEAM_MGMT_USER_PERMISSIONS:
      return { ...state, permissions: payload };

    case UPDATE_TEAM_MGMT_USER_PROFILE_DATA_WITH_PERMISSIONS: {
      return {
        ...state,
        userProfileData: payload.userProfileData,
        permissions: payload.permissions
      };
    }

    default:
      return state;
  }
};

export default teamManagementUserReducer;
