import {
  CHECK_USER_DATA_IN_LOCAL_STORAGE,
  CLOSE_ALERT_MESSAGE,
  OPEN_ALERT_MESSAGE,
  TOGGLE_SUBMENU_CARD
} from './Header.actionTypes';
import { IHeaderAction, IHeaderReducerInitialState } from './Header.interface';

import { colorConstants } from '../../configs/styleConstants';

const HeaderReducerInitialState: IHeaderReducerInitialState = {
  isSubmenuCardOpen: false,
  isUserAndOrgDataLocalStorage: false,
  alertMessage: {
    showAlertMessage: false,
    message: '',
    textColor: colorConstants.green1,
    backgroundColor: colorConstants.lightGreen1
  }
};

const HeaderReducer = (state = HeaderReducerInitialState, action: IHeaderAction) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_SUBMENU_CARD:
      return {
        ...state,
        isSubmenuCardOpen: payload
      };

    case CHECK_USER_DATA_IN_LOCAL_STORAGE:
      return {
        ...state,
        isUserAndOrgDataLocalStorage: payload
      };
    case OPEN_ALERT_MESSAGE:
      return { ...state, alertMessage: payload };
    case CLOSE_ALERT_MESSAGE:
      return { ...state, alertMessage: HeaderReducerInitialState.alertMessage };
    default:
      return state;
  }
};

export default HeaderReducer;
