import * as Yup from 'yup';

import { AWS_ACC_ID } from '../../constants/regex';

export const ConnectAccountValidationSchema = Yup.object().shape({
  accountName: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z_ ]+$/,
      'Only underscores “_” are allowed as special characters in the account name'
    ) //TODO I will create constants for regex and appropriate constants for numeric values later
    .required('Please enter an account name')
    .min(2, 'The account name must be between 2 and 100 characters long')
    .max(100, 'The account name must be between 2 and 100 characters long'),
  accountId: Yup.string()
    .min(12, 'The account ID must be exactly 12 digits long')
    .max(12, 'The account ID must be exactly 12 digits long')
    .required('Please enter your account ID')
    .matches(AWS_ACC_ID, 'Short input. Add more text to meet the minimum requirement'),
  budget: Yup.number()
    .test('is-greater-than-zero', 'The budget should be a minimum of $1', (value) => value !== 0)
    .min(1, 'The budget should be a minimum of $1')
    .required('Please enter a budget value')
});
export const EditAccountInfoValidationSchema = Yup.object().shape({
  accountName: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z_ ]+$/,
      'Only underscores “_” are allowed as special characters in the account name'
    )
    .required('Please enter an account name')
    .min(2, 'The account name must be between 2 and 100 characters long')
    .max(100, 'The account name must be between 2 and 100 characters long'),
  budget: Yup.number()
    .test('is-greater-than-zero', 'The budget should be a minimum of $1', (value) => value !== 0)
    .min(1, 'The budget should be a minimum of $1')
    .required('Please enter a budget value')
});
