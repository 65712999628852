/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import DailyModal from './DailyModal';

import IMAGES from '../../../assets';
import { SETTINGS_TOOLTIP_MESSAGES } from '../../../configs/settings';
import { colorConstants } from '../../../configs/styleConstants';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import Ruler from '../../common/ruler/Ruler';
import CustomSwitch from '../../common/switch/Switch';
import Typography from '../../common/typography/Typography';

export interface INotificationRowProps {
  cardKey: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  initialState: string | number;
  setTime?: (setterFunction: string, value: string) => void;
  setterFunction?: React.Dispatch<React.SetStateAction<string>>;
  handleEdit?: () => Promise<void>;
  scheduleTime?: string;
  allowEdit?: boolean;
  enabled?: boolean;
  setSwitch?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  canUserEditNotifications: boolean;
  abbreviation: string;
}

const defaultScheduleTime = 'Schedule Time';

const NotificationRow = ({
  cardKey,
  initialState,
  title,
  description,
  scheduleTime,
  allowEdit,
  setterFunction,
  enabled,
  setSwitch,
  isLoading,
  canUserEditNotifications,
  abbreviation
}: INotificationRowProps) => {
  const [isDailyModalOpen, setDailyModalOpen] = useState<boolean>(false);

  //commenting as per suggestion from PM
  // const [isWeeklyModalOpen, setWeeklyModalOpen] = useState<boolean>(false);
  // const [isMonthlyModalOpen, setMonthlyModalOpen] = useState<boolean>(false);

  const handleSetTime = (value: string) => {
    if (setterFunction && canUserEditNotifications) {
      setterFunction(value);
    }
  };

  const toggleDropDownMenu = () => {
    if (canUserEditNotifications) {
      setDailyModalOpen(true);
    }
  };

  return (
    <div className={`notifications__wrapperContainer__container__box`} key={cardKey}>
      {/* wrapper */}
      <div className="notifications__wrapperContainer__container__box__wrapper">
        {/* title */}
        <div className="notifications_title">
          <Typography
            variant="body-3"
            size="semiBold"
            as="h4"
            className={`${isLoading ? 'apply-loader' : ''}`}
          >
            {title}
          </Typography>
          <Typography
            variant="body-3"
            size="regular"
            as="p"
            className={`notifications__wrapperContainer__container__box__wrapper__description ${
              isLoading ? 'apply-loader' : ''
            }`}
          >
            {description}
          </Typography>
        </div>
        {/* Dropdown */}
        <div
          className={`notifications__wrapperContainer__container__box__wrapper__dropdown notifications__wrapperContainer__container__box__wrapper__disabled ${
            isLoading ? 'apply-loader' : ''
          }`}
        >
          <button disabled={true} type="button">
            <Typography variant="caption" size="medium" as="span">
              {initialState}
            </Typography>
          </button>
        </div>
        {/* time/date */}
        <div className="notifications__wrapperContainer__container__box__wrapper__time">
          <Typography
            as="p"
            variant="body-3"
            size="regular"
            className={`notifications__wrapperContainer__container__box__wrapper__time__para ${
              scheduleTime === defaultScheduleTime
                ? 'notifications__wrapperContainer__container__box__wrapper__time__link'
                : ''
            } ${isLoading ? 'apply-loader' : ''}`}
            onClick={
              scheduleTime === defaultScheduleTime && allowEdit
                ? () => toggleDropDownMenu()
                : undefined
            }
          >
            {initialState !== 'Daily' ? (
              <Ruler color={colorConstants.blue6} className="notification-time-ruler" />
            ) : (
              ` ${scheduleTime} ${abbreviation}`
            )}
          </Typography>
          <TooltipCustom
            text={SETTINGS_TOOLTIP_MESSAGES.NO_NOTIFICATION_EDIT_ACCESS}
            conditionToDisplay={!canUserEditNotifications && initialState === 'Daily'}
          >
            {initialState === 'Daily' && (
              <span className={`${isLoading ? 'apply-loader' : ''}`}>
                <img
                  src={IMAGES.editBluePen}
                  alt="Edit"
                  onClick={() => toggleDropDownMenu()}
                  className={`${canUserEditNotifications ? '' : 'change-is-disabled'}`}
                />
              </span>
            )}
          </TooltipCustom>
        </div>
        {/* switch */}
        <div
          className={`notifications__wrapperContainer__container__box__wrapper__switch ${
            isLoading ? 'apply-loader' : ''
          }`}
        >
          {/* <Switch /> */}
          <TooltipCustom
            text={SETTINGS_TOOLTIP_MESSAGES.NO_NOTIFICATION_EDIT_ACCESS}
            conditionToDisplay={!canUserEditNotifications}
          >
            <CustomSwitch
              disabled={!allowEdit || !canUserEditNotifications}
              checked={enabled}
              onClick={() => {
                if (setSwitch) {
                  setSwitch((prev) => !prev);
                }
              }}
            />
          </TooltipCustom>
        </div>
      </div>

      {/* Commenting as per suggestions from PM */}
      {/* {isWeeklyModalOpen && (
        <WeekModal
          closeModal={() => {
            setWeeklyModalOpen(false);
          }}
          setScheduleTime={handleSetTime}
          selectedTime={scheduleTime}
        />
      )}
      {isMonthlyModalOpen && (
        <MonthModal
          closeModal={() => {
            setMonthlyModalOpen(false);
          }}
          setScheduleTime={handleSetTime}
          selectedTime={scheduleTime}
        />
      )} */}
      {isDailyModalOpen && (
        <DailyModal
          closeModal={() => {
            setDailyModalOpen(false);
          }}
          setScheduleTime={handleSetTime}
          selectedTime={scheduleTime}
        />
      )}
    </div>
  );
};

export default NotificationRow;
