import { Currency } from './currency';
import { Countries } from './countries';
import { Timezone } from './timezone';
import { ImageFileTypes } from './images';
import cloudAccounts from './cloudAccounts';
import teamManagement from './teamManagement';
import utils from './utils';
import { PermissionModuleApiKeyEnums } from './common';

const ENUMS = {
  Currency,
  Countries,
  Timezone,
  ImageFileTypes,
  cloudAccounts,
  teamManagement,
  utils
};

export { Currency, Countries, Timezone, ImageFileTypes, PermissionModuleApiKeyEnums };

export default ENUMS;
