import { IStep } from '../../components/common/customStepsForm/CustomStepsForm.interface';
import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';
import { setCurrentActiveTabForm } from '../../store/actions';
import { useAppDispatch } from '../redux/useRedux';

const useEditGroupsSteps = (): IStep[] => {
  const dispatch = useAppDispatch();

  const handleClick = (activeTab: number) => {
    dispatch(setCurrentActiveTabForm(activeTab));
  };

  const steps = [
    {
      label: TEAM_MANAGEMENT_TEXT.GROUP_DETAILS,
      onClick: () => handleClick(0)
    },
    {
      label: TEAM_MANAGEMENT_TEXT.PERMISSIONS,
      onClick: () => handleClick(1)
    },
    {
      label: TEAM_MANAGEMENT_TEXT.ADD_USERS,
      onClick: () => handleClick(2)
    }
  ];

  return steps;
};

export default useEditGroupsSteps;
