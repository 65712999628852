import classNames from 'classnames';

import Typography from '../../../common/typography/Typography';
import './slack.scss';

export interface IKey {
  text?: string;
  className?: string;
}

const Key = ({ text, className }: IKey) => {
  const classes = classNames('key ', className);
  return (
    <Typography variant="body-3" size="medium" as="h4" className={classes}>
      {text}
    </Typography>
  );
};

export default Key;
