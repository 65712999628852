import { ERRORS as ERRORS_COLORS } from '../../../../configs/v3.colors';
import { TSvgIconProps } from '../../common.interface';

const CrossChevronIcon = ({ color = ERRORS_COLORS[700] }: TSvgIconProps) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.25 6.75L8.75 11.25M8.75 6.75L13.25 11.25M2.54 9.72L5.78 14.04C6.044 14.392 6.176 14.568 6.34329 14.6949C6.49145 14.8074 6.65924 14.8913 6.83808 14.9423C7.04 15 7.26 15 7.7 15H13.4C14.6601 15 15.2902 15 15.7715 14.7548C16.1948 14.539 16.539 14.1948 16.7548 13.7715C17 13.2902 17 12.6601 17 11.4V6.6C17 5.33988 17 4.70982 16.7548 4.22852C16.539 3.80516 16.1948 3.46095 15.7715 3.24524C15.2902 3 14.6601 3 13.4 3H7.7C7.26 3 7.04 3 6.83808 3.05767C6.65924 3.10874 6.49145 3.19264 6.34329 3.30506C6.176 3.432 6.044 3.608 5.78 3.96L2.54 8.28C2.34635 8.53819 2.24953 8.66729 2.21221 8.80907C2.17926 8.93423 2.17926 9.06577 2.21221 9.19093C2.24953 9.33271 2.34635 9.46181 2.54 9.72Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossChevronIcon;
