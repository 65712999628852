import { MY_PROFILE_TEXT } from '../../../configs';
import { parametricRoutes, routes } from '../../../constants/routes';
import ENUMS from '../../../enums';
import useComponentMount from '../../../hooks/componentMount/useComponentMount';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { updateAccessControlTabType } from '../../../store/actions';
import { TTeamMangEditUserTabs } from '../../../store/teamManagement/teamManagement.interface';
import { ITeamManagementUserInitialState } from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import Groups from '../Groups/Groups';
import { ITeamManagementGroupsProps } from '../Groups/groups.interface';

const { userAccessControlTabTypes } = ENUMS.teamManagement;

const AddUserToGroups = ({
  addUserToGroups,
  selectedRowsAddToGroup,
  setSelectedRowsAddToGroup
}: ITeamManagementGroupsProps) => {
  const { userProfileData } = useAppSelector(
    (state) => state.teamManagementUser
  ) as ITeamManagementUserInitialState;

  const dispatch = useAppDispatch();

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: MY_PROFILE_TEXT.USERS, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      {
        text: userProfileData.firstName + ' ' + userProfileData.lastName,
        url: routes.TEAM_MANAGEMENT_USER
      },
      {
        text: MY_PROFILE_TEXT.EDIT_ACCESS_CONTROL,
        url: parametricRoutes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_EDIT_GROUP
      },
      {
        text: MY_PROFILE_TEXT.GROUPS,
        url: parametricRoutes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_EDIT_GROUP
      },
      { text: MY_PROFILE_TEXT.ADD_USER_TO_GROUPS, url: '' }
    ]
  });

  useComponentMount({
    onMountFunc: () => {
      // updating tabs upon reload
      dispatch(
        updateAccessControlTabType(userAccessControlTabTypes.Groups as TTeamMangEditUserTabs)
      );
    }
  });

  return (
    <Groups
      addUserToGroups={addUserToGroups}
      selectedRowsAddToGroup={selectedRowsAddToGroup}
      setSelectedRowsAddToGroup={setSelectedRowsAddToGroup}
    />
  );
};

export default AddUserToGroups;
