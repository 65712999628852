import { ReactNode } from 'react';

import {
  SET_ACTIVE_TAB,
  SET_HEADING,
  TOGGLE_BOTTOM_NAVIGATION,
  TOGGLE_SIDEBAR
} from './Sidebar.actionTypes';

export const setActiveTab = (index: number) => ({
  type: SET_ACTIVE_TAB,
  payload: index
});

export const toggleSidebar = (state: boolean) => ({
  type: TOGGLE_SIDEBAR,
  payload: state
});

export const setHeading = (heading: string | ReactNode) => ({
  type: SET_HEADING,
  payload: heading
});

export const toggleBottomNavigation = (state: boolean) => ({
  type: TOGGLE_BOTTOM_NAVIGATION,
  payload: state
});
