import React, { useState } from 'react';
// eslint-disable-next-line
import axios, { AxiosResponse } from 'axios';
// eslint-disable-next-line
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import validationSchema from './validation.schema';

// eslint-disable-next-line
import { confirmSuccess } from '../../../assets/images';
import deleteIcon from '../../../assets/images/svg/delete-icon.svg';
import fileIcon from '../../../assets/images/svg/file-icon.svg';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import getUserData from '../../../helpers/getUserData';
import imageMimeTypes from '../../../helpers/json/imageMimeTypes.json';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import services from '../../../services';
import { textEllipsis } from '../../../utils';
import CustomButton from '../../common/buttons/CustomButton';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import FileUploadInput from '../../common/fileUploadInput/FileUploadInput';
import TextAreaInput from '../../common/textAreaInput/TextAreaInput';
import TextInput from '../../common/textInput/TextInput';
import './needHelp.form.css';

export type TNeedHelpFormInitialValues = {
  fullName: string;
  subject: string;
  issueDescription: string;
  images: { file: File; name: string }[];
};

interface TNeedHelpApiResponse {
  data: {
    needHelpResponse: {
      id: string;
    };
  };
}

interface TPresignedUrlResponse {
  data: string;
}

interface NeedHelpFormProps {
  onClose: () => void;
  resetForm: () => void;
  isNeedHelpModalOpen: boolean;
  setIsNeedHelpModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NeedHelpForm: React.FC<NeedHelpFormProps> = ({
  onClose,
  isNeedHelpModalOpen,
  setIsNeedHelpModalOpen
}) => {
  const userData = getUserData();

  const [isMaxImagesError, setIsMaxImagesError] = useState(false);
  const [initialValues, setInitialValues] = useState<TNeedHelpFormInitialValues>({
    fullName: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
    subject: '',
    issueDescription: '',
    images: []
  });

  const handleSubmit = async (
    values: TNeedHelpFormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<TNeedHelpFormInitialValues>
  ) => {
    setSubmitting(true);

    try {
      const response: TNeedHelpApiResponse = await services.postWithAuth({
        url: API_ENDPOINTS.NEED_HELP, // TODO: I will create a separate service file for NeedHelp API's
        payload: {
          email: userData.email,
          subject: values.subject,
          description: values.issueDescription
        }
      });

      let atLeastOneFileUploaded = false;

      // Create an array of promises for getting presigned URLs and uploading images
      const uploadImages = values.images.map(async (image, index) => {
        const customImageName = `img${index + 1}`; // Custom ImageName like "img1", "img2".
        const presignedUrlResponse: string | null = await getDocumentPresignedUrl(
          response.data.needHelpResponse.id,
          customImageName
        );

        if (presignedUrlResponse) {
          const isFileUploaded = await uploadFiles(presignedUrlResponse, image.file);
          if (isFileUploaded) {
            atLeastOneFileUploaded = true;
          }
        }
      });

      // Wait for all uploadImages to complete
      await Promise.all(uploadImages);

      // Send Slack notification only after successful form submission and file uploads if applicable
      if (atLeastOneFileUploaded || !values.images.length) {
        await sendSlackNotification({
          email: userData.email,
          subject: values.subject,
          description: values.issueDescription,
          needHelpId: response.data.needHelpResponse.id.toString()
        });
      }

      setIsNeedHelpModalOpen(true);
      resetForm();
      setInitialValues({
        ...initialValues,
        subject: '',
        issueDescription: '',
        images: []
      });
      onClose();
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'needhelpFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getDocumentPresignedUrl = async (needHelpId: string, fileName: string) => {
    try {
      const response: AxiosResponse<TPresignedUrlResponse> = await services.getWithAuth({
        url: API_ENDPOINTS.NEED_HELP_GET_PRESIGNED_URL, // TODO: I will create a separate service file for Need Help Presigned URL API's
        params: {
          needHelpId,
          fileName
        }
      });

      if (response && response.data) {
        return response.data.data;
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'Need Help Get Presigned URL Failed.',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later.'
      });
    }
    return null;
  };
  // TODO: I will create a separate service file for below API
  const MaxFileSizeMb = 5; // Maximum file size in megabytes
  const MaxFileSizeBytes = MaxFileSizeMb * 1024 * 1024; // Convert MB to bytes

  const uploadFiles = async (presignedUrl: string, file: File): Promise<boolean> => {
    try {
      // Check file size
      if (file.size > MaxFileSizeBytes) {
        throw new Error(`File size exceeds the maximum limit of ${MaxFileSizeMb}MB.`);
      }

      const fileType = file.name.split('.').pop() as 'bmp' | 'jpg' | 'jpeg' | 'png';
      const binaryData = await file.arrayBuffer();
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: presignedUrl,
        headers: {
          'Content-Type': imageMimeTypes[fileType] || 'image/png'
        },
        data: binaryData
      };

      const response = await axios(config);
      return response.status === 200;
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'uploadFail',
        defaultMessage: 'Failed to upload file! Please try again later'
      });
      return false;
    }
  };

  const handleModalClose = () => {
    setIsNeedHelpModalOpen(false);
  };

  const sendSlackNotification = async (payload: {
    email: string;
    subject: string;
    description: string;
    needHelpId: string;
  }) => {
    try {
      await services.postWithAuth({
        url: API_ENDPOINTS.SEND_SLACK_NEED_HELP_MESSAGE,
        payload
      });
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'sendSlackMessageFail',
        defaultMessage: 'Failed to send Slack notification! Please try again later'
      });
    }
  };

  return (
    <div className="form-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, errors, touched, setFieldValue, values }) => (
          <Form noValidate className="forms">
            <TextInput
              label="Full Name"
              name="fullName"
              type="text"
              placeholder=""
              errors={errors}
              touched={touched}
              data-testid="fullName"
              required={false}
              disabled
            />
            <TextInput
              label="Subject"
              name="subject"
              type="text"
              placeholder="Enter Subject"
              errors={errors}
              touched={touched}
              required={true}
              inputClassName="needhelp-subject"
            />
            <TextAreaInput
              label="Issue Description"
              name="issueDescription"
              placeholder="Enter Description"
              data-testid="issueDescription"
              required={true}
              rows={6}
              inputClassName="need_help_textarea scrollView"
            />
            <div className="file_upload_container">
              <span className="typography__body-3">Upload Image </span>
              <FileUploadInput
                onChange={(files) => {
                  if (files) {
                    if (values.images.length + files.length > 5) {
                      setIsMaxImagesError(true);
                      setTimeout(() => setIsMaxImagesError(false), 3000);
                      return;
                    }

                    const updatedImages = Array.from(files).map((file) => ({
                      file,
                      name: file.name
                    }));

                    setFieldValue('images', [...values.images, ...updatedImages]);
                  }
                }}
              />
            </div>
            {isMaxImagesError && (
              <div className="max-images-error">You can upload a maximum of 5 images.</div>
            )}
            <FieldArray name="images">
              {({ remove }) => (
                <div className="container_uploaded_img">
                  {values.images.map((image, index) => (
                    <div key={index} className="uploaded-image">
                      <div className="image-info">
                        <img src={fileIcon} alt="Icon" className="icon-left" />
                        {textEllipsis({ text: image.name, charLimit: 25 })}
                      </div>
                      <button type="button" onClick={() => remove(index)} className="delete-icon">
                        <img src={deleteIcon} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <div className="need_help_btn_container">
              <CustomButton
                type="reset"
                text="Cancel"
                disabled={isSubmitting}
                buttonWidth="214px"
                className="secondary"
                onClick={onClose}
              />

              <CustomButton
                type="submit"
                text="Submit"
                disabled={isSubmitting}
                buttonWidth="214px"
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmModal
        open={isNeedHelpModalOpen}
        setOpen={setIsNeedHelpModalOpen}
        title="Submitted"
        description="Query submitted successfully."
        confirmButtonText="OKAY"
        handleOkClick={handleModalClose}
        image={confirmSuccess}
      />
    </div>
  );
};

export default NeedHelpForm;
