import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './scrollView.scss';

type TScrollView = HTMLAttributes<HTMLDivElement>;

const ScrollView = ({ children, className, ...rest }: TScrollView) => {
  const classes = classNames('scrollView ', className);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};
export default ScrollView;
