import classNames from 'classnames';
import { useState } from 'react';
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import { CloudAccountStatus } from '@cloudinspot/cis-common-constants';
// eslint-disable-next-line
import { ICloudAccountsActionsProps } from './cloudAccountsActions.interface';
import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { CLOUD_ACCOUNTS_TOOLTIP_MESSAGES } from '../../../configs/cloudAccounts';
import { colorConstants } from '../../../configs/styleConstants';
import { BLACK as BLACK_COLORS, ERRORS as ERRORS_COLORS } from '../../../configs/v3.colors';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import ENUMS from '../../../enums';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import { useAppDispatch } from '../../../hooks/redux/useRedux';
import postWithCloudAuth from '../../../services/cloudAccounts/postWithCloudAuth';
import { updateAccountInfoEditState, updateLoaderId } from '../../../store/actions';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import DeleteModal from '../../common/deleteModal/DeleteModal';
import DisconnectAccountIcon from '../../common/icons/disconnectAccountIcon/DisconnectAccountIcon';
import EditPenIcon from '../../common/icons/editPenIcon/EditPenIcon';
import TrashIcon from '../../common/icons/trashIcon/TrashIcon';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import SimpleTooltip from '../../common/simpleTooltip/SimpleTooltip';
import DisconnectAzure from '../azure/disconnectAzure';
import './cloudAccountsActions.scss';

const { CloudAccountsTableActionTooltip, CloudAccountsStatuses, CloudServiceProvider } =
  ENUMS.cloudAccounts;

const CloudAccountsActions = ({
  accountStatus,
  account,
  fetchAccounts
}: ICloudAccountsActionsProps) => {
  const [openRemoveAccountModal, setOpenRemoveAccountModal] = useState<boolean>(false);
  const [openRemoveAccountConfirmModal, setOpenRemoveAccountConfirmModal] =
    useState<boolean>(false);
  const [openDisconnectAccountModal, setOpenDisconnectAccountModal] = useState<boolean>(false);
  const [showActionsToolTip, setShowActionsTooltip] = useState<boolean>(false);
  const [tooltipText, settooltipText] = useState<keyof typeof CloudAccountsTableActionTooltip>(
    CloudAccountsTableActionTooltip.Edit
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { canUserEditCloudAccount, canUserDeleteCloudAccount, canUserDisconnectCloudAccount } =
    useCheckUserPermissions();

  const closeDisconnectAzureModal = () => {
    setOpenDisconnectAccountModal(false);
  };
  const goToEditAccount = () => {
    if (canUserEditCloudAccount) {
      dispatch(updateAccountInfoEditState(true));
      navigate(`/cloud-account/view/${account.accountId}/edit`);
    }
  };

  const goToDisconnectAccount = () => {
    if (canUserDisconnectCloudAccount && account.CSP === CloudServiceProvider.AWS) {
      navigate(`/cloud-account/view/disconnect/${account.accountId}`);
    } else if (canUserDisconnectCloudAccount && account.CSP === CloudServiceProvider.Azure) {
      setOpenDisconnectAccountModal(true);
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(updateLoaderId('deletedAccountLoader'));
      const response = await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_DELETE_ACCOUNT,
        payload: { accountId: account.accountId }
      });

      if (response) {
        setOpenRemoveAccountModal(false);
        setOpenRemoveAccountConfirmModal(true);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'removeAccountsFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      dispatch(updateLoaderId(''));
    }
  };

  return (
    <>
      <div className="cloud-account-actions">
        {/* Edit Cloud account Starts */}
        {/* Edit account is available for all the Cloud Accounts irrespective of status */}
        <TooltipCustom
          text={
            canUserEditCloudAccount
              ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.EDIT
              : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_EDIT_ACCESS
          }
        >
          <div
            className={classNames('cloud-account-actions__icon', {
              'cloud-account-actions__icon--is-disabled': !canUserEditCloudAccount
            })}
            onClick={goToEditAccount}
          >
            <EditPenIcon color={BLACK_COLORS[800]} />
          </div>
        </TooltipCustom>
        {/* Edit Cloud account ends */}

        {/* Delete or Disconnect Cloud account Starts */}
        {accountStatus === CloudAccountsStatuses.Inactive ||
        accountStatus === CloudAccountsStatuses.InProgress ||
        accountStatus === CloudAccountsStatuses.Failed ? (
          <TooltipCustom
            text={
              canUserDeleteCloudAccount
                ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.DELETE
                : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_DELETE_ACCESS
            }
          >
            <div
              className={classNames(
                'cloud-account-actions__icon cloud-account-actions__icon--delete',
                {
                  'cloud-account-actions__icon--is-disabled': !canUserDeleteCloudAccount
                }
              )}
              onClick={() => {
                if (canUserDeleteCloudAccount) {
                  setOpenRemoveAccountModal(true);
                }
              }}
            >
              {/* This has to modified during integration */}

              <TrashIcon color={ERRORS_COLORS[700]} />
            </div>
          </TooltipCustom>
        ) : accountStatus === CloudAccountStatus.Active ||
          accountStatus === CloudAccountStatus.Paused ? (
          // To show disconnect button when cloud account is either in Active or Paused state
          <TooltipCustom
            text={
              canUserDisconnectCloudAccount
                ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.DISCONNECT
                : CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_DISCONNECT_ACCESS
            }
          >
            <div className="cloud-account-actions__icon" onClick={goToDisconnectAccount}>
              {/* This has to modified during integration */}

              <DisconnectAccountIcon
                color={
                  canUserDisconnectCloudAccount ? colorConstants.primary : colorConstants.lightGrey1
                }
              />
            </div>
          </TooltipCustom>
        ) : (
          <SimpleTooltip
            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.ACTION_NOT_ALLOWED}
            showTooltip={
              tooltipText === CloudAccountsTableActionTooltip.NotAllowed && showActionsToolTip
            }
            setShowTooltip={setShowActionsTooltip}
            onMouseEnterFunc={() => {
              setShowActionsTooltip(true);
              settooltipText(CloudAccountsTableActionTooltip.NotAllowed);
            }}
          >
            <div className="cloud-account-actions__icon">
              {/* This has to modified during integration */}
              <DisconnectAccountIcon color={BLACK_COLORS[800]} />
            </div>
          </SimpleTooltip>
        )}
        {/* Delete or Disconnect Cloud account Ends */}

        <DeleteModal
          open={openRemoveAccountModal}
          heading={`Remove ${account.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'} Account`}
          description={
            <>
              Are you sure you want to remove this{' '}
              {account.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'} account from{' '}
              <CloudInSpotName />?
            </>
          }
          placeholder="Type REMOVE to delete account"
          handleClose={() => {
            setOpenRemoveAccountModal(false);
          }}
          handleDelete={handleDelete}
          inputRequiredToDelete="REMOVE"
        />
        <ConfirmModal
          title={`${account.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'} Account Removed!`}
          description={
            <>
              {account.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'} account removed from{' '}
              <CloudInSpotName /> successfully.
            </>
          }
          descriptionWidth="88%"
          confirmButtonText={BUTTON_TEXT.OKAY}
          open={openRemoveAccountConfirmModal}
          handleOkClick={() => {
            setOpenRemoveAccountConfirmModal(false);
            fetchAccounts({});
          }}
          setOpen={setOpenRemoveAccountConfirmModal}
          image={confirmSuccess}
        />
      </div>
      <DisconnectAzure
        closeDisconnectAzureModal={closeDisconnectAzureModal}
        isModalOpen={openDisconnectAccountModal}
        accountId={account?.accountId}
        fetchAccounts={fetchAccounts}
      />
    </>
  );
};

export default CloudAccountsActions;
