// External imports
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { IAccordion } from './accordion.interface';
import './accordion.scss';

import ChevronIcon from '../icons/chevronIcon/ChevronIcon';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';

/**
 * Accordion component.
 * @param  accordionTitle Title of the action, which would appear on the head
 * @param  accordionKey unique identifier of the accordion, to control height of body of each accordion etc.
 * @param  children Content of the accordion.
 * @param  isOpen Whether the accordion is open
 * @param  onClick Handler for the click on the accordion head.
 * @param  calculateContentHeight Whether to calculate the height of accordion body, useful for applying transitions to animations.
 * @param  setPermissionHeadHeight Sets the height of accordion content, useful for applying transitions to animations.
 * @param  showAccordionButtons Whether to show accordion button.
 * @param  containerClass External class to apply custom styles from outside this component.
 * @param  containerProps HTML DIV attributes if required, applied top level div of accordion.
 * @param  isLoading Represents the loading state in case of fetch call etc.. *
 * @param  applyBoxShadow Whether to apply box shadow
 */
const Accordion = ({
  accordionTitle,
  accordionKey,
  children,
  isOpen,
  onClick,
  setPermissionHeadHeight,
  showAccordionButtons = true,
  calculateContentHeight = true,
  containerClass = 'containerClass',
  paperClass,
  containerProps,
  isLoading = false,
  applyBoxShadow = true
}: IAccordion) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const permissionsHeadRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<string>('0px');
  const accordionClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      if (calculateContentHeight) {
        setContentHeight(`${contentRef.current.scrollHeight}px`);
      } else {
        setContentHeight('auto');
      }
    }
    if (permissionsHeadRef.current && setPermissionHeadHeight) {
      setPermissionHeadHeight(permissionsHeadRef.current.getBoundingClientRect().height);
    }
  }, [isOpen, contentRef, permissionsHeadRef]);

  return (
    <div
      {...containerProps}
      className={`common-accordion ${containerClass} ${
        isOpen ? `common-accordion--is-open ${containerClass}--is-open` : ''
      } ${isLoading ? 'common-accordion--is-loading' : ''}`}
    >
      <PaperCustom
        variant="light"
        className={classNames({
          'common-paper-custom--no-box-shadow': !applyBoxShadow,
          paperClass
        })}
      >
        <div
          className={`common-accordion__head ${containerClass}__head   ${
            isOpen ? `common-accordion__head--is-open ${containerClass}__head--is-open` : ''
          }`}
          onClick={accordionClickHandler}
          ref={permissionsHeadRef}
        >
          <div
            className={`common-accordion__head__title ${containerClass}__head__title title apply-loader`}
          >
            {accordionTitle}
          </div>
          {showAccordionButtons && (
            <div className={`common-accordion__head__handler ${containerClass}__head__handler`}>
              <ChevronIcon
                containerProps={{
                  'aria-label': 'button to open and close accordion',
                  className: `common-accordion__head__arrow-icon ${containerClass}__head__arrow-icon apply-loader`
                }}
              />
            </div>
          )}
        </div>
        <div
          className={`common-accordion__content ${containerClass}__content ${
            isOpen
              ? `common-accordion__content--is-open ${containerClass}__content--is-open content-${accordionKey}-height`
              : ''
          }`}
          ref={contentRef}
        >
          <style>{`.content-${accordionKey}-height {height: ${contentHeight}}`}</style>
          {children}
        </div>
      </PaperCustom>
    </div>
  );
};

export default Accordion;
