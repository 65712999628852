import * as Yup from 'yup';

export const GroupNameValidation = Yup.object().shape({
  groupName: Yup.string()
    .trim()
    .required('Please enter a team name')
    .matches(/^[a-zA-Z_ ]+$/, 'Only alphabets and underscores “_” are allowed in the team name')
    .min(2, 'The team name must be between 2 and 200 characters long')
    .max(200, 'The team name must be between 2 and 200 characters long')
});
