import { IFaqReducerInitialState, IFaqReduceraction } from './faq.interface';
import { UPDATE_FAQS } from './faq.actionTypes';

const faqReducerInitialState: IFaqReducerInitialState = {
  faqs: []
};

const faqReducer = (state = faqReducerInitialState, action: IFaqReduceraction) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_FAQS:
      return { ...state, faqs: payload.faqs };
    default:
      return state;
  }
};

export default faqReducer;
