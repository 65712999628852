import { useNavigate } from 'react-router-dom';

import './notFound.scss';

import { COMMON_TEXT } from '../../configs/common';
import { routes } from '../../constants/routes';
import useIsAuthenticated from '../../hooks/is-authenticated/useIsAuthenticated';
import { useAppSelector } from '../../hooks/redux/useRedux';
import { IMyProfileState } from '../../store/myProfile/myProfile.interface';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';

const NotFound = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const activeCloudAccount: boolean = useAppSelector<IMyProfileState>(
    (store) => store.MyProfile
  ).activeCloudAccount;

  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <div className="not-found">
        <div className="not-found__error-code">
          <p>404</p>
        </div>
        <div className="not-found__content">
          <p className="not-found__content__heading">404 Error - Page Not Found</p>
          <p className="not-found__content__description">
            The page you are looking for cannot be found. This could be due to an incorrect URL or
            the page is no longer available.
          </p>
          <div className="not-found__content__buttons">
            {isAuthenticated ? (
              <>
                <CustomButton
                  variant={ButtonVariants.PRIMARY}
                  text={
                    activeCloudAccount
                      ? // will switch back to dashboard navigation once we start working on dashboard
                        // ? COMMON_TEXT.GO_TO_DASHBOARD
                        COMMON_TEXT.GO_TO_CLOUD_ACCOUNTS
                      : COMMON_TEXT.GO_TO_CLOUD_ACCOUNTS
                  }
                  className="not-found__content__buttons__button not-found__content__buttons__dashboard"
                  onClick={() => {
                    if (activeCloudAccount) {
                      // will switch back to dashboard navigation once we start working on dashboard
                      navigate(routes.CLOUD_ACCOUNTS);
                    } else {
                      navigate(routes.CLOUD_ACCOUNTS);
                    }
                  }}
                />
              </>
            ) : (
              <CustomButton
                variant={ButtonVariants.PRIMARY}
                text={COMMON_TEXT.GO_TO_HOMEPAGE}
                className="not-found__content__buttons__button not-found__content__buttons__dashboard"
                onClick={() => navigate(routes.SIGN_IN)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
