import useVisibility from '../hooks/common/useVisibility/useVisibility';

const usePasswordVisibility = () => {
  const { visible, toggleVisibility } = useVisibility(false);

  return {
    visible,
    toggleVisibility,
    inputType: visible ? 'text' : 'password'
  };
};

export default usePasswordVisibility;
