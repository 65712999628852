import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import SummaryStats, {
  TSummaryStatItem
} from '../../../common/molecules/summaryStats/SummaryStats';
import Typography from '../../../common/typography/Typography';
import GroupIcon from '../../../common/icons/groupIcon/GroupIcon';

export interface IGroupSummaryStats {
  totalGroups: number;
  groupsAddedThisMonth: number;
  groupsDeletedThisMonth: number;
}

const GroupSummaryStats = ({ summary }: { summary: IGroupSummaryStats }) => {
  const data: TSummaryStatItem[] = [
    {
      label: 'Total Teams',
      icon: <GroupIcon color={PRIMARY_COLORS[600]} />,
      value: summary.totalGroups
    },
    {
      label: (
        <div className="summary_card_container">
          Teams Added{' '}
          <Typography variant="caption" size="regular" as="span">
            (This Month)
          </Typography>
        </div>
      ),
      icon: <GroupIcon color={PRIMARY_COLORS[600]} />,
      value: summary.groupsAddedThisMonth
    },
    {
      label: (
        <div className="summary_card_container">
          Teams Deleted{' '}
          <Typography variant="caption" size="regular" as="span">
            (This Month)
          </Typography>
        </div>
      ),
      icon: <GroupIcon color={PRIMARY_COLORS[600]} />,
      value: summary.groupsDeletedThisMonth
    }
  ];

  return <SummaryStats data={data} className="tmg-teams-summary-stats" />;
};

export default GroupSummaryStats;
