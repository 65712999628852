import { IAccountStatusMessageProps } from './accountStatusMessage.interface';

import Typography from '../typography/Typography';

import './accountStatusMessage.scss';

const AccountStatusMessage = ({ msg, variant, className }: IAccountStatusMessageProps) => (
  <div className={`accountStatusMessage ${variant} ${className ? className : ''}`}>
    <Typography variant="body-3" size="medium">
      {msg}
    </Typography>
  </div>
);

export default AccountStatusMessage;
