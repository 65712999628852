import { TableCell, TableRow } from '@mui/material';

import { ICustomTableNoResultsProps } from './customTable.interface';

import { CUSTOM_TABLE } from '../../../configs';
import { noDataIcon } from '../../../assets/images';
import Typography from '../typography/Typography';

const noDataDefault = (
  <div className="no-results__content">
    <img src={noDataIcon} />
    <Typography variant="body-2" size="medium" as="p">
      {CUSTOM_TABLE.NO_DATA_FOUND}
    </Typography>
  </div>
);

const CustomTableNoResults = ({ content = noDataDefault, colSpan }: ICustomTableNoResultsProps) => {
  return (
    <>
      <TableRow className="custom-table-no-results">
        <TableCell colSpan={colSpan} className="no-results">
          {content}
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomTableNoResults;
