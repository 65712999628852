import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import IMAGES from '../../assets';
import { confirmSuccess, copyToClipboard } from '../../assets/images';
import {
  IDisconnectResponseData,
  IParams
} from '../../components/cloudAccounts/cloudAccounts.interface';
import '../../components/cloudAccounts/cloudAccounts.scss';
import '../../components/cloudAccounts/connectAccount/connectAccountSteps.scss';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import CloseButton from '../../components/common/close-button/CloseButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import Loader from '../../components/common/loader/loader';
import { CLOUD_ACCOUNTS_TEXT } from '../../configs';
import { successToastStyle } from '../../configs/styleConstants';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { routes } from '../../constants/routes';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import {
  fetchCloudAccountDetailsRequest,
  setActiveTab,
  setHeading,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../store/actions';
// This is to be removed after confirmation from peers
// import useCalculateElementHeight from '../../../hooks/common/calculateElementHeight/useCalculateElementHeight';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CopyToClipboard from '../../components/common/copy-to-clipboard/CopyToClipboard';
import LogoWithHeading from '../../components/common/logo-with-heading/logoWithHeading';
import TextTooltipWithEllipsis from '../../components/common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import Typography from '../../components/common/typography/Typography';
import { DELETE_STACK_AWS } from '../../configs/cloudAccounts';
import { COMMON_TEXT } from '../../configs/common';
import useLoading from '../../hooks/loading/useLoading';
import postWithCloudAuth from '../../services/cloudAccounts/postWithCloudAuth';
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';

const DisconnectAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params: IParams = useParams() as unknown as IParams;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isNotVerifiedModalOpen, setIsNotVerifiedModalOpen] = useState<boolean>(false);
  const [isConnectClicked, seIsConnectClicked] = useState<boolean>(false);
  const disconnectRef = useRef<HTMLDivElement | null>(null);
  const { loading, startLoading, stopLoading } = useLoading();
  // This is to be removed after confirmation from peers
  // const { elementHeight } = useCalculateElementHeight({ ref: disconnectRef });
  const accountDetails = (
    useAppSelector((store) => store.cloudAccounts) as ICloudAccountsReducerInitialState
  ).accountDetails;
  console.log(accountDetails);
  let accountId: string;
  if (params && params.id) {
    accountId = params.id;
  }
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(accountId);

    toast.success('Account ID copied on the clipboard', {
      style: successToastStyle,
      toastId: 'AccountCreateSuccessfully',
      closeButton: <CloseButton color={successToastStyle?.color as string} />
    });
  };

  const handleDisconnect = async () => {
    try {
      startLoading();
      if (params && params.id) {
        const response = await postWithCloudAuth({
          url: API_ENDPOINTS.CLOUD_DISCONNECT_ACCOUNT,
          payload: { accountId: params.id.toString() }
        });
        const responseData: IDisconnectResponseData = response.data;
        window.open(responseData.redirectUrl, '_blanc');
        seIsConnectClicked(true);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'DisconnectRedirectionFail'
      });
    } finally {
      stopLoading();
    }
  };

  const handleConfirmClick = async () => {
    try {
      await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_CONFIRM_DISCONNECT,
        payload: { accountId: accountId.toString() }
      });
      setIsConfirmModalOpen(true);
    } catch (error) {
      setIsNotVerifiedModalOpen(true);
    }
  };

  const handleNavigateToCloudAccounts = () => {
    navigate(routes.CLOUD_ACCOUNTS);
  };

  useEffect(() => {
    dispatch(setHeading('Disconnect Account'));
    dispatch(setActiveTab(2));
  }, []);

  const updateMsTeamsBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        { text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW, url: `/cloud-account/view/${params.id}` },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountDetails.accountName}
            />
          ),
          url: `/cloud-account/view/${params.id}`
        },
        { text: `Disconnected Account`, url: '' }
      ])
    );
  };
  useEffect(() => {
    updateMsTeamsBreadcrumbs();
  }, [accountDetails?.accountName]);

  useEffect(() => {
    if (!accountDetails.accountName) {
      dispatch(
        fetchCloudAccountDetailsRequest({
          accountId: params?.id || '',
          startLoading,
          stopLoading: () => {
            stopLoading();
            dispatch(updateSkeletonLoader({ isLoading: false }));
          }
        })
      );
    }
  }, [params?.id]);
  return (
    <PaperCustom variant="medium">
      <div
        ref={disconnectRef}
        // style={{ height: elementHeight ? elementHeight + 'px' : 'auto' }}  // This is to be removed after confirmation from peers
        className="connect-account-steps"
      >
        {/* ConfirmErrorModal */}
        <ConfirmModal
          open={isConfirmModalOpen}
          setOpen={setIsConfirmModalOpen}
          title={`Disconnected!`}
          image={confirmSuccess}
          confirmButtonText="OKAY"
          description={
            <>
              Your AWS account disconnected from <CloudInSpotName /> successfully.
            </>
          }
          descriptionWidth="88%"
          handleOkClick={handleNavigateToCloudAccounts}
        />

        {/* Not Verified Account */}
        <ConfirmModal
          open={isNotVerifiedModalOpen}
          setOpen={setIsNotVerifiedModalOpen}
          title={`Oops! Something went wrong!`}
          image={IMAGES.ErrorExclamation}
          confirmButtonText="OKAY"
          description="We couldn't connect to AWS. Please try again later. If the problem continues, reach out to our support team for help."
          descriptionWidth="88%"
          handleOkClick={handleNavigateToCloudAccounts}
        />
        {/* Steps */}
        <div className="flex flex-col">
          <LogoWithHeading
            logoSrc={IMAGES.infoCircle}
            headingText={CLOUD_ACCOUNTS_TEXT.STEPS_TO_DISCONNECT_YOUR_AWS_ACCOUNT}
          />
          {/* Step 1 */}
          <div className="flex flex-col">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 1 : Login to correct AWS account
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="stepDesc pl-8 border-l border-l-border_disabled ml-5 w-[800px] mt-[20px]"
            >
              Click on the Disconnect button, and you will be redirected to AWS. Please ensure you
              are logged into the correct AWS account from which you intend to disconnect. Accurate
              authentication will facilitate a smooth disconnection process.
              {/* Copy to clipboard */}
              <span className="font-noto font-bold stepDesc__accountId">
                {' '}
                Your Account ID is {params.id}
                <img
                  onClick={handleCopyToClipboard}
                  src={copyToClipboard}
                  alt="copy to clipboard account ID"
                  className="inline-block ml-2 cursor-pointer"
                />
              </span>
            </Typography>
          </div>
          {/* Step 2 */}
          <div className="flex flex-col mt-[20px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 2 : Delete Bucket
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="stepDesc pl-8 border-l border-l-border_disabled ml-5 w-[800px] mt-[20px]"
            >
              Access the Amazon S3 console and empty the bucket associated with your AWS stack. Once
              emptied, delete the bucket.
            </Typography>
          </div>
          {/* Step 3 */}
          <div className="flex flex-col mt-[20px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 3 : Delete Stack
            </Typography>

            <Typography
              variant="body-3"
              size="regular"
              as="p"
              className="stepDesc pl-8 border-l border-l-border_disabled ml-5 w-[800px] mt-[20px]"
            >
              If you&apos;re using an <span className="font-semibold">individual account</span>,
              follow ONLY <span className="font-semibold">step 3b.</span> If you&apos;re using an{' '}
              <span className="font-semibold">organization account</span>, follow ONLY{' '}
              <span className="font-semibold">step 3a.</span>
              <p className="font-semibold	mt-8">a) Organization Account</p>
              <p className="mt-4">
                In the left-side menu, click on StackSet. Select the stackset (CIS-StackSet), then
                click on “Action” and choose “Delete stack” from StackSet.
              </p>
              <p className="mt-4">
                Enter your organization&apos;s OU ID (AWS OU ID). You can find the
                organization`&apos;s OU ID from{' '}
                <CopyToClipboard
                  textLabel=""
                  textToCopy={DELETE_STACK_AWS.AWS_OU_ID_URL}
                  toastMessage="URL copied on clipboard"
                  toastId="accountIdToast"
                />
              </p>
              <img
                src={IMAGES.AWS_OU_ID}
                alt="AWS OU ID"
                className="aws_activate_trusted hidden sm:block mt-4"
                loading="lazy"
              />
              <p className="mt-4">
                Specify the region (e.g., us-east-1), then click “Next”, and then “Submit”.
              </p>
              <p className="mt-4">
                The deletion process will begin, and you will see the stack delete in progress. Once
                completed, click “Action” again and select “Delete StackSet”.
              </p>
              <p className="font-semibold mt-8">b) Individual Account</p>
              <p className="mt-4">
                Go to Cloudformation. In Stacks section select the “CloudInSpot-connected-account”
                and click Delete button.
              </p>
              <img
                src={IMAGES.AWS_INDIVISUAL_ACCOUNT}
                alt="AWS OU ID"
                className="aws_activate_trusted hidden sm:block mt-4"
                loading="lazy"
              />
            </Typography>
          </div>

          {/* step-4 */}
          <div className="flex flex-col mt-[20px]">
            <Typography variant="body-3" size="semiBold" as="h4" className="stepTitle">
              Step 4 : Come back on this tab and click Confirm button.
            </Typography>
          </div>
        </div>

        {/* Button Card */}
        <div className="bottomCardWrapper">
          <PaperCustom className="bottomCardWrapper__bottomCard">
            <CustomButton
              text="Back"
              onClick={handleNavigateToCloudAccounts}
              variant={ButtonVariants.SECONDARY}
              className="back_btn"
            />
            <div className="bottomCardWrapper__bottomCard__btnContainer">
              <CustomButton
                text={loading ? <Loader /> : CLOUD_ACCOUNTS_TEXT.DISCONNECT}
                onClick={handleDisconnect}
                className="aws_disconnect_btn px-[23px] whitespace-nowrap"
              />
              <CustomButton
                text="Confirm"
                disabled={!isConnectClicked}
                onClick={handleConfirmClick}
                className={`confirm_btn h-[40px] px-28 sm:px-8 ${
                  isConnectClicked
                    ? 'bottomCardWrapper__bottomCard__btnContainer__confirmBtn cursor-pointer'
                    : 'bottomCardWrapper__bottomCard__btnContainer__confirmBtnDisabled cursor-not-allowed'
                } rounded-[6px] self-center`}
              />
            </div>
          </PaperCustom>
        </div>
      </div>
    </PaperCustom>
  );
};

export default DisconnectAccount;
