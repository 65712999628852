import { useQuery } from 'react-query';
import { useEffect } from 'react';

import { fetchTeamUser } from '../../../services/teamManagement/teamManagement';
import {
  ITeamManagementUserInitialState,
  IteamMgmtUserProfileWithPermissions
} from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import { useAppDispatch, useAppSelector } from '../../redux/useRedux';
import {
  updateTeamMgmtGroupPermissions,
  updateTeamMgmtUserProfileData
} from '../../../store/actions';

const useFetchTeamUserProfile = (isTeamUser: boolean) => {
  // This hook updates the profile redux state of selected user in team management
  const { selectedUser } = useAppSelector(
    (store) => store.teamManagementUser
  ) as ITeamManagementUserInitialState;

  const dispatch = useAppDispatch();

  const {
    isLoading,
    data: teamUserProfile,
    refetch: refetchTeamUser
  } = useQuery<IteamMgmtUserProfileWithPermissions>(
    ['team-user-profile', { email: selectedUser }],
    () => fetchTeamUser(selectedUser),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false, // Prevent refetching when tab/window is focused
      enabled: isTeamUser
    }
  );

  useEffect(() => {
    if (teamUserProfile) {
      dispatch(updateTeamMgmtUserProfileData(teamUserProfile?.userProfileData));
      dispatch(updateTeamMgmtGroupPermissions(teamUserProfile?.permissions));
    }
  }, [teamUserProfile]);

  return { teamUserProfile, isLoading, refetchTeamUser };
};

export default useFetchTeamUserProfile;
