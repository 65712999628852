import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './reconfigureSlack.scss';

import {
  IFetchSlackChannelsList,
  IListSlackChannelsAPI,
  IReconfigureSlack,
  type WorkspaceChannel
} from './reconfigureSlack.interface';

import IMAGES from '../../../../assets';
import { errorToastStyle } from '../../../../configs/styleConstants';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { getOrgData } from '../../../../helpers/getOrgData';
import getUserData from '../../../../helpers/getUserData';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import getWithAuthCloud from '../../../../services/cloudAccounts/getWithCloudAuth';
import postWithCloudAuth from '../../../../services/cloudAccounts/postWithCloudAuth';
import {
  fetchSlackConnectionDetails,
  setChannelsList,
  setSlackName,
  setSlackRedirectionURL
} from '../../../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import CloudInSpotName from '../../../cloudInSpotName/CloudInSpotName';
import Accordion from '../../../common/accordion/Accordion';
import CloseButtonIcon from '../../../common/buttons/CloseButtonIcon';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import CloseButton from '../../../common/close-button/CloseButton';
import Typography from '../../../common/typography/Typography';

const loaderList: IListSlackChannelsAPI[] = [
  {
    workspaceName: 'dev1',
    workspaceChannels: [
      {
        channelId: '12321',
        channelName: 'dev2'
      },
      {
        channelId: '121',
        channelName: 'dev3'
      },
      {
        channelId: '123',
        channelName: 'dev4'
      }
    ]
  },
  {
    workspaceName: 'dev10',
    workspaceChannels: [
      {
        channelId: '122321',
        channelName: 'dev11'
      },
      {
        channelId: '1221',
        channelName: 'dev13'
      },
      {
        channelId: '1232',
        channelName: 'dev14'
      }
    ]
  }
];

const ReconfigureSlack = ({
  open,
  handleClose,
  handleSuccess,
  accountId,
  setIsSlackSuccessfullyConnected,
  isRefreshTrue,
  workspace: installedWorkspace,
  selectedChannelName
}: IReconfigureSlack) => {
  const dispatch = useAppDispatch();

  const { slackChannelsList } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;
  const { email } = getUserData();

  const [selectedChannel, setSelectedChannel] = useState<WorkspaceChannel>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<string>('');
  const [workspaceOpen, setWorkspaceOpen] = useState<number>(-1);
  const { loading, startLoading, stopLoading } = useLoading();
  const connectLoading = useLoading();

  const handleOptionChange = (channel: WorkspaceChannel) => {
    if (selectedChannel?.channelId !== channel.channelId) {
      setSelectedChannel(channel);
    }
  };

  useEffect(() => {
    // We need to map channelId when we will start receiving it from API end.
    if (selectedChannelName) {
      handleOptionChange({ channelId: '', channelName: selectedChannelName });
    }
  }, []);

  const handleCloseClick = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const handleSlackConnection = async () => {
    if (!selectedChannel) {
      toast.error('Please select Channel first !', {
        style: errorToastStyle,
        closeButton: <CloseButton color={errorToastStyle?.color as string} />
      });
      return;
    }

    connectLoading.startLoading();
    if (selectedChannel.channelId === 'newWorkspace') {
      if (accountId)
        try {
          const { orgID } = getOrgData();
          window.open(
            `${process.env.REACT_APP_INSTALL_SLACK}?accountId=${accountId}&organizationId=${orgID}`,
            '_self'
          );
        } catch (error) {
          useErrorHandler({
            error,
            toastId: 'NewSlackWorkSpaceFail',
            defaultMessage: 'Slack New workspace installation fails'
          });
        } finally {
          connectLoading.stopLoading();
        }
      return;
    }

    if (accountId && email) {
      connectLoading.startLoading();
      try {
        const payload = {
          accountId: accountId,
          channelName: selectedChannel.channelName,
          createdBy: email,
          workspaceName: selectedWorkspace
        };

        const data = await postWithCloudAuth({ url: API_ENDPOINTS.SLACK_ADD_TO_SLACK, payload });
        if (data) {
          dispatch(setSlackName(selectedChannel.channelName));
          setIsSlackSuccessfullyConnected();
          handleCloseClick();
          handleSuccess && handleSuccess();
          dispatch(fetchSlackConnectionDetails({ accountId }));
          if (isRefreshTrue) {
            handleSuccess && handleSuccess();
          }
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'SlackConnectFail'
        });
      } finally {
        connectLoading.stopLoading();
      }
    }
  };

  const handleWorkspaceRefresh = async (value: string) => {
    fetchSlackChannelsList({ workspaceName: value, refresh: true });
  };

  const fetchSlackChannelsList = async ({ workspaceName, refresh }: IFetchSlackChannelsList) => {
    startLoading();
    if (accountId) {
      try {
        const response = await getWithAuthCloud({
          url: API_ENDPOINTS.SLACK_CHANNELS_LIST,
          params: {
            accountId: accountId,
            ...(workspaceName && { workspaceName }),
            ...(refresh && { refresh: true })
          }
        });

        const data = response?.data;
        if (data?.redirectUrl) {
          dispatch(setSlackRedirectionURL(data.redirectUrl));
          return;
        }

        if (data?.channels) {
          const channelsList: IListSlackChannelsAPI[] = data.channels.map(
            (item: IListSlackChannelsAPI) => {
              return {
                workspaceName: item.workspaceName,
                workspaceChannels: item.workspaceChannels.map((channel) => ({
                  channelId: channel.channelId,
                  channelName: channel.channelName
                }))
              };
            }
          );
          dispatch(setChannelsList(channelsList));
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'SlackListFail',
          defaultMessage: "Don't worry"
        });
      } finally {
        stopLoading();
      }
    }
  };

  useEffect(() => {
    fetchSlackChannelsList({ workspaceName: installedWorkspace, refresh: isRefreshTrue });
  }, []);

  return (
    <Modal
      open={open}
      data-testid="slack_reconfigure_modal"
      id="slack_reconfigure_modal"
      className=""
    >
      <div className={`slack_reconfigure_modal ${loading ? 'loaderClass' : ''}`}>
        <div className="content">
          <div className="header">
            <div className="logoNameContainer">
              <img src={IMAGES.Slack} alt="Slack " />
              <Typography variant="body-2" size="bold" as="h1">
                Configure Slack
              </Typography>
            </div>
            <CloseButtonIcon onClick={handleClose} />
          </div>
          <div className="container">
            <div>
              <div className=" existingChannelsContainer">
                <div className=""></div>
                {/* fixed height and scroll */}
                <div className="existingChannels">
                  <div>
                    <Typography
                      variant="body-3"
                      size="semiBold"
                      className="subHeading apply-loader"
                      as="h2"
                    >
                      Add to New Workspace
                    </Typography>
                    <Typography
                      variant="body-3"
                      size="regular"
                      className="inputField newSlackWorkspace apply-loader"
                    >
                      <input
                        type="radio"
                        name="slack_channel"
                        id="newSlack_newWorkspace"
                        value="newWorkspace"
                        onChange={() =>
                          handleOptionChange({
                            channelName: 'newWorkspace',
                            channelId: 'newWorkspace'
                          })
                        }
                        className="apply-loader"
                      />
                      <label htmlFor="newSlack_newWorkspace" className="channel apply-loader">
                        New Slack Workspace
                      </label>
                    </Typography>

                    {loading
                      ? loaderList.map((item, indx) => {
                          return (
                            <Accordion
                              key={indx + item.workspaceName}
                              accordionTitle={
                                <div className="title">
                                  <h2 className="apply-loader">{item.workspaceName}</h2>
                                  <img className="apply-loader" src={IMAGES.refresh} />
                                </div>
                              }
                              isOpen={indx === workspaceOpen}
                              accordionKey={indx}
                              containerClass="accordionClass"
                              paperClass="paperClass"
                              applyBoxShadow={false}
                            >
                              {item.workspaceChannels.map(({ channelId, channelName }) => {
                                return (
                                  <Typography
                                    variant="body-3"
                                    size="regular"
                                    key={channelId}
                                    className="inputField"
                                    as="div"
                                  >
                                    <input
                                      type="radio"
                                      name="slack_channel"
                                      id="newSlack_checked"
                                      checked={selectedChannel?.channelId === channelId}
                                      value={channelId}
                                      className="apply-loader"
                                    />
                                    <label
                                      htmlFor="newSlack_checked"
                                      className="channel apply-loader"
                                    >
                                      {channelName}
                                    </label>
                                  </Typography>
                                );
                              })}
                            </Accordion>
                          );
                        })
                      : slackChannelsList.map((item, indx) => {
                          return (
                            <Accordion
                              key={indx + item.workspaceName}
                              accordionTitle={
                                <div className="title">
                                  <Typography
                                    variant="body-3"
                                    size="semiBold"
                                    className="subHeading apply-loader"
                                    as="h2"
                                  >
                                    {item?.workspaceName}
                                  </Typography>

                                  <img
                                    src={IMAGES.refresh}
                                    onClick={(
                                      e: React.MouseEvent<HTMLImageElement, MouseEvent>
                                    ) => {
                                      e.stopPropagation();
                                      handleWorkspaceRefresh(item.workspaceName);
                                    }}
                                  />
                                </div>
                              }
                              isOpen={indx === workspaceOpen}
                              onClick={() => {
                                setWorkspaceOpen((prev) => (prev === indx ? -1 : indx));
                                setSelectedWorkspace(() => item.workspaceName);
                              }}
                              accordionKey={indx}
                              containerClass="accordionClass"
                              paperClass="paperClass"
                            >
                              {item?.workspaceChannels.map((channel: WorkspaceChannel) => {
                                return (
                                  <div key={channel.channelId} className="inputField">
                                    <input
                                      type="radio"
                                      name="slack_channel"
                                      id={channel.channelId}
                                      value={channel.channelId}
                                      onChange={() => handleOptionChange(channel)}
                                      // We are not receiving channelId from API that's why we are mapping with channel name. Later we need to ask BE to add channelId in API response
                                      checked={selectedChannel?.channelId == channel.channelId}
                                    />
                                    <label
                                      htmlFor={channel.channelId}
                                      className="channel"
                                      onClick={() => handleOptionChange(channel)}
                                    >
                                      {channel.channelName}
                                    </label>
                                  </div>
                                );
                              })}
                            </Accordion>
                          );
                        })}
                  </div>
                </div>
              </div>

              <div className="buttons">
                <CustomButton
                  text="Cancel"
                  variant={ButtonVariants.SECONDARY}
                  className="apply-loader"
                  buttonWidth="198px"
                  onClick={handleCloseClick}
                />
                <CustomButton
                  onClick={handleSlackConnection}
                  text="Connect"
                  variant={ButtonVariants.PRIMARY}
                  buttonWidth="198px"
                  className="apply-loader"
                  isLoading={connectLoading.loading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="note ">
          <img src={IMAGES.note} alt="Note " />
          <Typography as="p" variant="caption" size="regular">
            To see the private channels here, please go to your private channel and Type @
            <CloudInSpotName /> and then click on &quot;Invite&quot; and then revisit this section
            and refresh channel list to add it
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default ReconfigureSlack;
