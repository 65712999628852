import { Modal, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import { IChangePassword, IChangePasswordState } from './changePassword.interface';
import './changePassword.scss';
import {
  changePasswordValidationSchema,
  newPasswordTooltipValidationSchema
} from './changePassword.validation.Schema';

import { closeIcon, tooltipIcon, visibilityOffEye, visibilityOnEye } from '../../../assets/images';
import { BUTTON_TEXT, INPUT_TYPE, MY_PROFILE_TEXT } from '../../../configs';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import getUserData from '../../../helpers/getUserData';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import postWithAuth from '../../../services/postWithAuth';
import InputFieldTooltip from '../../common/inputFieldTooltip/InputFieldTooltip';
import Loader from '../../common/loader/loader';
import TextInput from '../../common/textInput/TextInput';
import Typography from '../../common/typography/Typography';

const ChangePassword = ({
  openChangePasswordModal,
  setOpenChangePasswordModal,
  setConfirmModalText,
  setOpenConfirmModal
}: IChangePassword) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [passwordVisibility, setPasswordVisibility] = useState<Record<string, boolean>>({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false
  });

  const userData = getUserData();

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };

  const handleSubmit = async (values: IChangePasswordState) => {
    // Function to handle form submission
    try {
      const response = await postWithAuth({
        url: API_ENDPOINTS.CHANGE_PASSWORD,
        payload: {
          email: userData?.email,
          newPassword: values.newPassword,
          oldPassword: values.currentPassword
        }
      });

      if (response?.data) {
        setConfirmModalText(MY_PROFILE_TEXT.PASSWORD_CHANGED);
        setOpenChangePasswordModal(false);
        setOpenConfirmModal(true);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'updateUserFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    }
  };

  const closeModal = () => {
    setOpenChangePasswordModal(false);
  };

  const toggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  return (
    <Modal
      open={openChangePasswordModal}
      data-testid="changePasswordModal"
      id="change-password-modal"
    >
      <div className="change-password-modal">
        <div className="change-password-icon-heading">
          <img
            src={closeIcon}
            alt="close icon"
            data-testid="changePasswordCloseButton"
            onClick={closeModal}
          />
          <Typography as="p" variant="body-1" size="semiBold" data-testid="changePasswordHeading">
            Change Password
          </Typography>
        </div>
        {/* Start of change password form */}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={changePasswordValidationSchema}
        >
          {({ values, errors, resetForm, touched, isSubmitting }) => {
            return (
              <Form className="change-password-modal__form !gap-1">
                {/* Start of current password */}
                <div className="change-password-modal__form__field">
                  <div className="change-password-modal__form__field__input">
                    <TextInput
                      placeholder="Current Password"
                      label="Current Password"
                      name="currentPassword"
                      type={
                        passwordVisibility.currentPassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD
                      }
                      errors={errors}
                      touched={touched}
                      required={true}
                    />

                    <Tooltip
                      placement="bottom"
                      title={passwordVisibility.currentPassword ? 'Hide Password' : 'Show Password'}
                      arrow
                    >
                      <img
                        src={
                          passwordVisibility.currentPassword ? visibilityOffEye : visibilityOnEye
                        }
                        alt="toggle password masking"
                        className="mask-icon"
                        data-testid="changePasswordCurrentPasswordMaskIcon"
                        onClick={() =>
                          setPasswordVisibility((prev) => ({
                            ...prev,
                            currentPassword: !prev.currentPassword
                          }))
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
                {/* End of current password */}
                {/* Start of new password */}
                <div className="change-password-modal__form__field change-password-modal__form__new-password">
                  <div className="change-password-modal__form__field__input">
                    <TextInput
                      placeholder="New Password"
                      label="New Password"
                      name="newPassword"
                      type={passwordVisibility.newPassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD}
                      errors={errors}
                      touched={touched}
                      required={true}
                      inputClassName="input-field"
                      onFocus={() => setShowTooltip(true)}
                      onBlur={() => {
                        setShowTooltip(false);
                      }}
                    />

                    <img
                      src={tooltipIcon}
                      alt="tooltip icon"
                      className="tooltip-icon"
                      onClick={toggleTooltip}
                      data-testid="changePasswordtooltipButton"
                    />
                    <Tooltip
                      placement="bottom"
                      title={passwordVisibility.newPassword ? 'Hide Password' : 'Show Password'}
                      arrow
                    >
                      <img
                        src={passwordVisibility.newPassword ? visibilityOffEye : visibilityOnEye}
                        alt="toggle password masking"
                        className="mask-icon"
                        data-testid="changePasswordNewPasswordMaskIcon"
                        onClick={() =>
                          setPasswordVisibility((prev) => ({
                            ...prev,
                            newPassword: !prev.newPassword
                          }))
                        }
                      />
                    </Tooltip>
                  </div>
                  {showTooltip && (
                    <InputFieldTooltip
                      formValues={values}
                      field="newPassword"
                      schema={newPasswordTooltipValidationSchema}
                      title="Password must contain"
                    />
                  )}
                </div>
                {/* End of new password */}
                {/* Start of confirm new password */}
                <div className="change-password-modal__form__field change-password-modal__form__confirm-password">
                  <div className="change-password-modal__form__field__input">
                    <TextInput
                      placeholder="Confirm Password"
                      label="Confirm Password"
                      name="confirmNewPassword"
                      type={
                        passwordVisibility.confirmNewPassword
                          ? INPUT_TYPE.TEXT
                          : INPUT_TYPE.PASSWORD
                      }
                      errors={errors}
                      touched={touched}
                      required={true}
                      inputClassName="input-field"
                    />
                    <Tooltip
                      placement="bottom"
                      title={
                        passwordVisibility.confirmNewPassword ? 'Hide Password' : 'Show Password'
                      }
                      arrow
                    >
                      <img
                        src={
                          passwordVisibility.confirmNewPassword ? visibilityOffEye : visibilityOnEye
                        }
                        alt="toggle password masking"
                        className="mask-icon"
                        data-testid="changePasswordConfirmPasswordMaskIcon"
                        onClick={() =>
                          setPasswordVisibility((prev) => ({
                            ...prev,
                            confirmNewPassword: !prev.confirmNewPassword
                          }))
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
                {/* End of confirm new password */}
                <div className="change-password-modal__form__buttons">
                  <button
                    type="reset"
                    onClick={() => {
                      resetForm();
                      closeModal();
                    }}
                    data-testid="changePasswordCancelButton"
                    className="cancel"
                  >
                    {BUTTON_TEXT.CANCEL}
                  </button>
                  <button
                    type={isSubmitting ? 'button' : 'submit'}
                    data-testid="changePasswordSaveButton"
                    className="save"
                  >
                    {isSubmitting ? <Loader /> : BUTTON_TEXT.UPDATE}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
        {/* End of change password form */}
      </div>
    </Modal>
  );
};

export default ChangePassword;
