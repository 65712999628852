import {
  ADD_GROUP_NAME,
  ADD_USER_STEP_1,
  ADD_USER_STEP_2,
  CLEAR_TEAM_MGMT_TABLE_ARCHIVED_USERS,
  CLEAR_TEAM_MGMT_TABLE_GROUPS,
  CLEAR_TEAM_MGMT_TABLE_USERS,
  EDIT_GROUP_NAME,
  SET_ADD_USERS_TO_GROUP_LIST,
  SET_CURRENT_ACTIVE_TAB_FORM,
  UPDATE_ADD_USERS_TO_GROUP_LIST,
  UPDATE_GROUP_SUMMARY,
  UPDATE_SELECTED_GROUP,
  UPDATE_TABLES_LOADING_STATE,
  UPDATE_TEAM_MANG_TABLES_TYPE,
  UPDATE_TEAM_MGMT_TABLE_ARCHIVED_USERS,
  UPDATE_TEAM_MGMT_TABLE_GROUPS,
  UPDATE_TEAM_MGMT_TABLE_USERS,
  UPDATE_TEAM_MGMT_USER_ACCESS_CONTROL_TABTYPE,
  UPDATE_USER_SUMMARY,
  UPDATE_VIEW_GROUP_TABLES_TYPE
} from './teamManagement.actionTypes';
import { ITeamManagementActions, ITeamManagementInitialValues } from './teamManagement.interface';

const TeamManagementInitialState: ITeamManagementInitialValues = {
  addUser1: {
    firstName: '',
    lastName: ''
  },
  addUser2: {
    email: '',
    jobTitle: ''
  },
  currentActiveTabForm: 0,
  tableType: 'users',
  userPermissions: [],
  selectedUserGroups: [],
  addGroup1: {
    groupName: ''
  },
  editGroup1: {
    groupName: ''
  },
  viewGrouptableType: 'groupusers',
  selectedGroup: {
    groupName: '',
    createdAt: '',
    createdBy: '',
    userCount: 0,
    permissions: [],
    updatedAt: '',
    orgnizationId: 1,
    groupCreatedBy: ''
  },

  // USERS
  userTableData: [],
  userTableDataCount: 0,
  hasUsers: false,
  userSummary: {
    totalActiveUsers: 0,
    totalDeactivatedUsers: 0,
    usersAddedThisMonth: 0,
    usersDisconnectedThisMonth: 0
  },
  // GROUPS
  groupTableData: [],
  groupSummary: {
    totalGroups: 0,
    groupsAddedThisMonth: 0,
    groupsDeletedThisMonth: 0
  },
  hasGroups: false,
  addUsersToGroupAPIList: [],
  selectedUserToAddGroupsList: [],

  // ARCHIVED USERS
  archiveUserTableData: [],
  archiveUserTableDataCount: 0,
  hasArchivedUsers: false,

  // ACCESS CONTROL
  userAccessControlTabType: 'permissions',

  isTableLoading: false
};

const teamManagementReducer = (
  state: ITeamManagementInitialValues = TeamManagementInitialState,
  actions: ITeamManagementActions
) => {
  const { type, payload } = actions;
  switch (type) {
    case ADD_USER_STEP_1:
      return {
        ...state,
        addUser1: payload
      };
    case ADD_USER_STEP_2:
      return {
        ...state,
        addUser2: payload
      };

    case SET_CURRENT_ACTIVE_TAB_FORM:
      return {
        ...state,
        currentActiveTabForm: payload
      };
    case UPDATE_TEAM_MANG_TABLES_TYPE:
      return {
        ...state,
        tableType: payload
      };
    case ADD_GROUP_NAME:
      return {
        ...state,
        addGroup1: {
          groupName: payload
        }
      };
    case EDIT_GROUP_NAME:
      return {
        ...state,
        editGroup1: {
          groupName: payload
        }
      };

    case UPDATE_VIEW_GROUP_TABLES_TYPE:
      return { ...state, viewGrouptableType: payload };
    case UPDATE_SELECTED_GROUP:
      return { ...state, selectedGroup: payload };
    case UPDATE_TEAM_MGMT_TABLE_USERS:
      return {
        ...state,
        userTableData: payload.userTableData,
        userTableDataCount: payload.userTableDataCount,
        ...(payload.hasUsers && { hasUsers: payload.hasUsers })
      };
    case UPDATE_TEAM_MGMT_TABLE_GROUPS:
      return {
        ...state,
        groupTableData: payload.groupTableData,
        ...(payload.hasGroups && { hasGroups: payload.hasGroups })
      };
    case UPDATE_TEAM_MGMT_TABLE_ARCHIVED_USERS:
      return {
        ...state,
        archiveUserTableData: payload.userTableData,
        archiveUserTableDataCount: payload.userTableDataCount,
        ...(payload.hasUsers && { hasArchivedUsers: payload.hasUsers })
      };

    case UPDATE_TEAM_MGMT_USER_ACCESS_CONTROL_TABTYPE:
      return { ...state, userAccessControlTabType: payload };

    case UPDATE_TABLES_LOADING_STATE:
      return { ...state, isTableLoading: payload };

    // CLEAR TABLES
    case CLEAR_TEAM_MGMT_TABLE_USERS:
      return {
        ...state,
        userTableData: [],
        userTableDataCount: 0,
        hasUsers: false
      };
    case CLEAR_TEAM_MGMT_TABLE_GROUPS:
      return {
        ...state,
        groupTableData: [],
        groupSummary: {
          totalGroups: 0,
          groupsAddedThisMonth: 0,
          groupsDeletedThisMonth: 0
        },
        hasGroups: false
      };
    case CLEAR_TEAM_MGMT_TABLE_ARCHIVED_USERS:
      return {
        ...state,
        archiveUserTableData: [],
        archiveUserTableDataCount: 0,
        hasArchivedUsers: false
      };

    //Add users to Group
    case SET_ADD_USERS_TO_GROUP_LIST:
      return {
        ...state,
        addUsersToGroupAPIList: payload
      };

    case UPDATE_ADD_USERS_TO_GROUP_LIST:
      return {
        ...state,
        selectedUserToAddGroupsList: payload
      };
    case UPDATE_GROUP_SUMMARY:
      return {
        ...state,
        groupSummary: payload
      };

    case UPDATE_USER_SUMMARY:
      return {
        ...state,
        userSummary: payload
      };
    default:
      return state;
  }
};

export default teamManagementReducer;
