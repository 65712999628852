import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import AppReducer from './reducer';

export const store = configureStore({
  reducer: AppReducer,
  middleware: [thunk]
});

export type AppDispatch = typeof store.dispatch;
export default store;
