// Internal imports
import React from 'react';
import './paperCustom.scss';

// Interfaces
type IPaperCustomProps = {
  children: React.ReactNode;
  className?: string;
  variant?: 'light' | 'medium' | 'high'; // add new variants accordingly.
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * PaperCustom applies the styles as per the v3 design
 * @param children ReactNode
 */
const PaperCustom = ({
  children,
  variant = 'light',
  className,
  ref,
  ...props
}: IPaperCustomProps) => {
  return (
    <div
      ref={ref}
      {...props}
      className={`common-paper-custom common-paper-custom--${variant} ${className}`}
    >
      {children}
    </div>
  );
};

export default PaperCustom;
