export const TEAM_MANAGEMENT_TEXT = {
  ACTION: 'Action',
  ACTIVATE: 'Activate',
  ACTIVATE_USER: 'Activate User',
  REACTIVATE_USER: 'Reactivate User(s)',
  SEARCH_PLACEHOLDER: 'Search by Name, Email',
  GROUP_PLACEHOLDER: 'Search by Name',
  ADD_USER: 'Add User',
  ADD_USERS: 'Add Users',
  ADD_USER_TO_GROUPS_NO_SELECTION: 'You have not selected any team!',
  ADD_IN_GROUP: 'Add in Team',
  ADD_GROUP: 'Add Team',
  CONFIRM_DEACTIVATE_USER: 'Are you sure you want to deactivate selected user(s)?',
  CONFIRM_ACTIVATE_USER: 'Are you sure you want to activate selected user(s)?',
  CONFIRM_REACTIVATE_USER: 'Are you sure you want to reactivate selected User(s) ?',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  DEACTIVATE: 'Deactivate',
  DEACTIVATE_USER: 'Deactivate User',
  DEACTIVATE_USERS: 'Deactivate User(s)',
  DEACTIVATED_USER: 'Deactivated User',
  DEACTIVATED_USERS: 'Deactivated Users',
  DELETE_USER: 'Delete User',
  EMAIL: 'Email',
  EDIT_GROUP: 'Edit Team',
  EDIT_GROUPS: 'Edit Team',
  EDIT_GROUP_DETAILS: 'Edit Team Details',
  EDIT_GROUP_PERMISSIONS: 'Edit Team Permissions',
  EDIT_PERMISSIONS: 'Edit Permissions',
  EDIT_USERS: 'Edit Users',
  EXISTING_USERS: 'Existing Users',
  GROUP_CREATE_SUCCESS: 'The team is successfully created.',
  GROUP_CREATED: 'Team Created',
  FIRST_NAME: 'First Name',
  GROUP_DELETED: 'Team Deleted!',
  GROUPS_DELETED: 'Team(s) Deleted!',
  GROUP_DETAILS: 'Team Details',
  GROUP_NAME: 'Team Name',
  GROUPS_NAME: 'Team Names',
  GROUP_NAMES: 'Teams Name',
  GROUP_VIEW: 'Team View',
  GROUP_UPDATE_SUCCESS: 'The team’s details are successfully updated.',
  GROUP_UPDATED: 'Team Updated!',
  ITEM_NO: 'Item No',
  JOB_TITLE: 'Job Title',
  USER_COUNT: 'User Count',
  DELETE_GROUP: 'Delete Team',
  PERSONAL_INFO: 'Personal Info',
  PERSONAL_INFORMATION: 'Personal Information',
  PERMISSION_NAME: 'Permission Name',
  PERMISSION: 'Permission',
  PERMISSIONS: 'Permissions',
  ACCESS: 'Access',
  PLEASE_SELECT_USER: 'Please select atleast one row',
  PLEASE_SELECT_USERS: 'Please select atleast one user',
  REACTIVE_USER: 'Reactivate User',
  REMOVE_GROUP_FROM_USER_NO_SELECTION:
    'Please uncheck at least one checkbox to remove the user from the team and click save.',
  REMOVE_USER: 'Remove User',
  REMOVE_USERS: 'Remove User(s)',
  REMOVE_USER_DESCRIPTION: 'Are you sure you want to remove selected user(s) from this team?',
  RESET_PASSWORD: 'Reset Password',
  REVIEW_ADD_USER: 'Review Add User',
  SEARCH_USER: 'Search User',
  SELECTED_USERS: 'Selected Users',
  SELECTED_GROUP_DELETED: 'Selected Team(s) successfully deleted.',
  TEAM_MANAGEMENT: 'Team Management',
  TEMPORARY_PASSWORD_SENT_TO_EMAIL: 'A temporary password has been sent to user’s email.',
  DELETE: 'Delete',
  DELETE_UPPER: 'DELETE',
  GROUP: 'Team',
  GROUPS: 'Teams',
  ADD_TO_GROUP: 'Add to Team',
  ARCHIVE_USER: 'Archive User',
  CONFIRM_ARCHIVE_USER: 'Are you sure you want to archive selected user(s)?',
  MODULE: 'Module',
  TEMPORARY_PASSWORD_SENT: 'Temporary Password Sent!',
  USER: 'User',
  USERS: 'Users',
  USER_ADDED: 'User(s) Added!',
  USER_ADDED_TO_GROUPS: 'User added in the team Successfully.',
  USER_ARCHIVE_SUCCESS: 'User(s) archived successfully.',
  USER_ACTIVATE_SUCCESS:
    'User(s) activated successfully. A temporary password has been sent to the user’s email.',
  USER_DEACTIVATE_SUCCESS: 'User(s) deactivated successfully.',
  USER_DEACTIVATED: 'User Deactivated!',
  USER_DELETED: 'User(s) Deleted!',
  USER_DELETED_PERMANENTLY: 'User(s) are permanently deleted.',
  USER_NOT_SIGNED_IN: 'User has not signed in yet.',
  USER_REACTIVATED: 'User(s) Reactivated',
  USER_REACTIVATE_SUCCESS:
    'User(s) reactivated successfully. A temporary password has been sent to user’s email.',
  USER_REMOVED: 'User(s) Removed!',
  USER_REMOVE_SUCCESS: 'User(s) removed from the team successfully.',
  USER_SELECTED: 'User selected',
  YES_ACTIVATE: 'Yes, activate',
  YES_REACTIVATE: 'Yes, Reactivate',
  YES_DELETE: 'Yes, Delete',
  GROUP_DELETE_SUCCESS: 'Team deleted successfully',
  LAST_NAME: 'Last Name',
  ADD_USERS_IN_GROUP_SUCCESS: 'User(s) added to the team successfully.',
  REMOVE_FROM_GROUP: 'Remove from Team'
};

export const TEAM_MANAGEMENT_TOOLTIP_MESSAGES = {
  NO_ACTIVATE_USER_ACCESS: 'You do not have access to activate a user',
  NO_ADD_USER_ACCESS: 'You do not have access to add a user',
  NO_ADD_USER_IN_GROUP_ACCESS: 'You do not have access to add a user in the group',
  NO_ADD_GROUP_ACCESS: 'You do not have access to add a team',
  NO_DEACTIVATE_USER_ACCESS: 'You do not have access to deactivate another user',
  NO_DELETE_USER_ACCESS: 'You do not have access to delete another user',
  NO_DELETE_GROUP_ACCESS: 'You do not have access to delete a team',
  NO_EDIT_GROUP_ACCESS: 'You do not have access to edit a team',
  NO_VIEW_GROUP_ACCESS: 'You do not have access to view a team'
};
