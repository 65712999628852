import * as Yup from 'yup';

import { PASSWORD_SPECIAL_CHARACTERS } from '../../constants/regex';

const ConfirmForgotPasswordValidationSchema = Yup.object().shape({
  verification_code: Yup.string().required('Please enter the verification code'),
  password: Yup.string()
    .min(8, 'Password must be between 8 to 20 characters long')
    .max(20, 'Password must be between 8 to 20 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(PASSWORD_SPECIAL_CHARACTERS, 'Password must contain at least one special character')
    .required('Please enter a new password'),

  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Password did not match. Please try again')
    .required('Please confirm your password')
});

export default ConfirmForgotPasswordValidationSchema;
