import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import { TSvgIconProps } from '../../common.interface';

const GroupIcon = ({ color = PRIMARY_COLORS[800] }: TSvgIconProps) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0003 13.8369C18.4562 14.5683 19.7045 15.742 20.6156 17.2096C20.796 17.5003 20.8862 17.6456 20.9174 17.8468C20.9808 18.2558 20.7011 18.7585 20.3203 18.9204C20.1328 19 19.922 19 19.5003 19M15.0003 9.53224C16.482 8.79589 17.5003 7.26686 17.5003 5.5C17.5003 3.73314 16.482 2.20411 15.0003 1.46776M13.0003 5.5C13.0003 7.98528 10.9856 10 8.50032 10C6.01504 10 4.00032 7.98528 4.00032 5.5C4.00032 3.01472 6.01504 1 8.50032 1C10.9856 1 13.0003 3.01472 13.0003 5.5ZM1.55956 16.9383C3.15386 14.5446 5.6697 13 8.50032 13C11.331 13 13.8468 14.5446 15.4411 16.9383C15.7904 17.4628 15.965 17.725 15.9449 18.0599C15.9292 18.3207 15.7583 18.64 15.5499 18.7976C15.2822 19 14.9142 19 14.178 19H2.82268C2.0865 19 1.7184 19 1.45076 18.7976C1.24237 18.64 1.07141 18.3207 1.05576 18.0599C1.03565 17.725 1.21029 17.4628 1.55956 16.9383Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GroupIcon;
