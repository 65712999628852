import { useEffect } from 'react';

import { IBreadCrumbProps } from '../../store/common/common.interface';
import { useAppDispatch } from '../redux/useRedux';
import { updateBreadcrumbs } from '../../store/actions';

const useUpdateBreadcrumbs = ({ breadcrumbs, dependency = 0 }: IBreadCrumbProps) => {
  const dispatch = useAppDispatch();

  const clearBreadcrumbs = () => {
    // Reset bread crumbs while unmounting
    dispatch(updateBreadcrumbs([]));
  };

  useEffect(() => {
    dispatch(updateBreadcrumbs(breadcrumbs));

    return clearBreadcrumbs;
  }, [JSON.stringify(breadcrumbs), dependency]);
};

export default useUpdateBreadcrumbs;
