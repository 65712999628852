import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { TOrganization, TOrganizationAction, TOrganizationState } from './types';

type TOrganizationStore = TOrganizationState & TOrganizationAction;

const useOrganizationStore = create<TOrganizationStore>()(
  persist(
    (set) => ({
      organization: {
        organizationId: '',
        organizationName: '',
        organizationTimeZone: '',
        organizationAbbreviation: '',
        organizationGmtOffsetName: ''
      },
      setOrganization: (organization: TOrganization) => {
        set(() => ({ organization: organization }));
      }
    }),
    { name: 'OrganizationStore' }
  )
);

export default useOrganizationStore;
