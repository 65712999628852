import { colorConstants } from '../../../../configs/styleConstants';

const DisconnectAccountIcon = ({ color = colorConstants.primary }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99992 4.6665H3.99992C3.56218 4.6665 3.12873 4.75272 2.72431 4.92024C2.31989 5.08775 1.95243 5.33329 1.6429 5.64281C1.33337 5.95234 1.08784 6.31981 0.920323 6.72423C0.752807 7.12865 0.666587 7.5621 0.666587 7.99984C0.666587 8.43758 0.752807 8.87103 0.920323 9.27545C1.08784 9.67987 1.33337 10.0473 1.6429 10.3569C1.95243 10.6664 2.31989 10.9119 2.72431 11.0794C3.12873 11.247 3.56218 11.3332 3.99992 11.3332H5.99992M9.99992 11.3332H11.9999C12.4377 11.3332 12.8711 11.247 13.2755 11.0794C13.6799 10.9119 14.0474 10.6664 14.3569 10.3569C14.9821 9.73174 15.3333 8.88389 15.3333 7.99984C15.3333 7.11578 14.9821 6.26794 14.3569 5.64281C13.7318 5.01769 12.884 4.6665 11.9999 4.6665H9.99992"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 8H5.33342"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13L3 3"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DisconnectAccountIcon;
