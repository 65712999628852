import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { IAuthStatus } from './useIsAuthenticated.interface';

import API_ENDPOINTS from '../../constants/api_endpoints';
import getUserData from '../../helpers/getUserData';
import { IUserLocalStorage } from '../../interfaces/auth';
import authHttp from '../../services/authHttp';
import getWithAuth from '../../services/getWithAuth';
import { fetchMyProfile } from '../../store/actions';
import { IMyProfileState } from '../../store/myProfile/myProfile.interface';
import { useAppDispatch } from '../redux/useRedux';

const useIsAuthenticated = () => {
  const [authStatus, setAuthStatus] = useState<IAuthStatus>({
    isUserChecked: false, // Whether the user is checked for authentication.
    isAuthenticated: false // Whether the user has passed authentication checks.
  });

  const dispatch = useAppDispatch();

  const user: IUserLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');

  const { email } = getUserData();

  const updatePermissions = async () => {
    const myProfileResponse = await getWithAuth({
      url: API_ENDPOINTS.GET_USER,
      params: { email }
    });

    const myProfileData = myProfileResponse?.data?.data as IMyProfileState;

    // Update redux state
    dispatch(fetchMyProfile(myProfileData));

    setAuthStatus({ isUserChecked: true, isAuthenticated: true });
  };

  const checkAuthentication = async () => {
    if (!user.data) {
      // user is checked for authentication, but there is no key on the client side
      setAuthStatus({ isUserChecked: true, isAuthenticated: false });
    } else if (user && email) {
      authHttp
        .post(API_ENDPOINTS.CHANGE_PASSWORD)
        .then((response) => {
          if (response) {
            updatePermissions();
          }
        })
        .catch((error: unknown) => {
          if (isAxiosError(error)) {
            const status = error?.response?.status;
            if (!status) {
              setAuthStatus({ isUserChecked: true, isAuthenticated: false });
            } else if (status === 401 || status === 403) {
              setAuthStatus({ isUserChecked: true, isAuthenticated: false });
            } else {
              updatePermissions();
            }
          } else {
            setAuthStatus({ isUserChecked: true, isAuthenticated: false });
          }
        });
    } else {
      setAuthStatus({ isUserChecked: true, isAuthenticated: false });
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return {
    isUserChecked: authStatus.isUserChecked,
    isAuthenticated: authStatus.isAuthenticated,
    setAuthStatus
  };
};

export default useIsAuthenticated;
