import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line
import { Form, Formik, FormikHelpers } from 'formik';

import '../../components/cloudAccounts/accountView/msteam/msteam.scss';
// eslint-disable-next-line
import validationSchema from '../../components/cloudAccounts/accountView/msteam/validation.schema';
// eslint-disable-next-line
import { IConnectToMsTeamSteps } from '../../components/cloudAccounts/accountView/msteam/reconfigureMsTeam.interface';
import { confirmSuccess } from '../../assets/images';
import { CLOUD_ACCOUNTS_TEXT, MS_TEAMS } from '../../configs/cloudAccounts';
import { routes } from '../../constants/routes';
import getUserData from '../../helpers/getUserData';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import { TConnectMsTeamsRequest } from '../../services/types/msTeams.endpoints';
import useMsTeamStore from '../../store/msTeams/useMsTeamStore';
import {
  fetchCloudAccountDetailsRequest,
  setHeading,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import ConnectStep from '../../components/common/connectStep/ConnectStep';
import Loader from '../../components/common/loader/loader';
import TextInput from '../../components/common/textInput/TextInput';
import Typography from '../../components/common/typography/Typography';
import TextTooltipWithEllipsis from '../../components/common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import useLoading from '../../hooks/loading/useLoading';
import { COMMON_TEXT } from '../../configs/common';
import LogoWithHeading from '../../components/common/logo-with-heading/logoWithHeading';
import IMAGES from '../../assets';

export type TMsTeamFormValues = {
  webhookUrl: string;
};

const initialValues: TMsTeamFormValues = {
  webhookUrl: ''
};

const ConnectMsTeam = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const param = useParams();
  const { connect } = useMsTeamStore();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { startLoading, stopLoading } = useLoading();
  const { accountDetails } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;

  const handleGoBack = () => navigate(`/cloud-account/view/msteam/${param?.id}`);

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(`Cloud Accounts`));
    }
  });

  const updateMsTeamsBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        {
          text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW,
          url: `/cloud-account/view/${param?.id}`
        },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountDetails.accountName}
            />
          ),
          url: `/cloud-account/view/${param?.id}`
        },
        {
          text: `Connect to MS Teams`,
          url: ``
        }
      ])
    );
  };
  useEffect(() => {
    updateMsTeamsBreadcrumbs();
  }, [accountDetails.accountName]);

  const userData = getUserData();

  const handleSubmit = async (
    { webhookUrl }: TMsTeamFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TMsTeamFormValues>
  ) => {
    setSubmitting(true);

    try {
      const connectMsTeamsRequest: TConnectMsTeamsRequest = {
        accountId: param?.id || '',
        createdBy: userData.email,
        webhookUrl: webhookUrl
      };

      await connect(connectMsTeamsRequest);

      setShowConfirmationModal(true);
      resetForm();
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'MS Team Fail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (!accountDetails.accountName) {
      dispatch(
        fetchCloudAccountDetailsRequest({
          accountId: param?.id || '',
          startLoading,
          stopLoading: () => {
            stopLoading();
            dispatch(updateSkeletonLoader({ isLoading: false }));
          }
        })
      );
    }
  }, [param.id]);

  const CONNECT_TO_MS_TEAM_STEPS: IConnectToMsTeamSteps[] = [
    {
      stepName: 'Step 1 : Access Settings',
      description: (
        <p>
          Access the channel settings by clicking the three dots, then selecting &quot;Manage
          Channel.&quot; Navigate to the &quot;Settings&quot; tab to proceed.
        </p>
      )
    },
    {
      stepName: 'Step 2 : Set Up Webhook',
      description: (
        <p>
          Within the settings, locate the &quot;Connectors &quot; section. Click &quot;Edit &quot;
          and search for &quot;Webhook. &quot; Click &quot;Add &quot; and <strong>provide</strong> a
          suitable name for the webhook.
        </p>
      )
    },
    {
      stepName: 'Step 3 : Copy Link',
      description: (
        <p>
          After naming the webhook, confirm by clicking &quot;Create.&quot; Once created,{' '}
          <strong>copy the provided link to the clipboard.</strong>
        </p>
      )
    },
    {
      stepName: 'Step 4 : Enter the webhook URL and click on the “Confirm” button to proceed.',
      description: ''
    }
  ];

  return (
    <Fragment>
      <PaperCustom className="msteam connectSteps" variant="medium">
        <div>
          <LogoWithHeading
            logoSrc={IMAGES.infoCircle}
            headingText={MS_TEAMS.STEPS_TO_CONNECT_TO_MS_TEAMS}
          />
          <Typography variant="body-3" size="semiBold" className="connectSteps__stepTitle">
            Prerequisite
          </Typography>
          <div className="connectSteps__descDiv">
            <Typography variant="body-3" size="regular" as="p">
              Your cloud account should be connected with <CloudInSpotName />.
            </Typography>
            <Typography variant="body-3" size="regular" as="p" className="lg:w-[705px]">
              You should have any MS Teams business account, as these plans provide access to
              &apos;Teams&apos; and &apos;Channels&apos; which is required to integrate your cloud
              account with MS Teams.
            </Typography>
            <Typography variant="body-3" size="regular" as="p">
              Create a channel in Microsoft Teams.
            </Typography>
          </div>
        </div>
        {/*Connect To Ms Team Steps */}
        {CONNECT_TO_MS_TEAM_STEPS.map((item, index) => {
          const { stepName, description } = item;
          return (
            <ConnectStep
              key={item.stepName + index}
              stepName={stepName}
              description={description}
            />
          );
        })}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, errors, touched }) => {
            return (
              <Form className="container_webhookUrl">
                <div className="webhookUrl">
                  <TextInput
                    label="Paste the copied webhook URL into the input field below."
                    name="webhookUrl"
                    type="text"
                    placeholder="Enter webhook URL"
                    errors={errors}
                    touched={touched}
                    disabled={isSubmitting}
                    data-testid="webhookUrl"
                    required={true}
                    inputClassName="webhookUrl_input"
                  />
                </div>

                <PaperCustom variant="medium" className="buttonWrapper">
                  <CustomButton
                    text="Back"
                    variant={ButtonVariants.SECONDARY}
                    onClick={handleGoBack}
                    buttonWidth="74px"
                  />
                  <div className="buttons">
                    <CustomButton
                      type="submit"
                      text={isSubmitting ? <Loader /> : 'Confirm'}
                      variant={ButtonVariants.PRIMARY}
                      buttonWidth="97px"
                      className="buttons__confirmBtn"
                    />
                  </div>
                </PaperCustom>
              </Form>
            );
          }}
        </Formik>
      </PaperCustom>

      {showConfirmationModal && (
        <ConfirmModal
          title={
            location.state?.reconfiguration === true
              ? 'Reconfigured!'
              : `Great Job, ${userData.firstName}!`
          }
          description="Your Cloud Account connected to MS Teams successfully."
          confirmButtonText="OKAY"
          image={confirmSuccess}
          open={showConfirmationModal}
          handleClose={handleGoBack}
          descriptionWidth="88%"
          handleOkClick={handleGoBack}
        />
      )}
    </Fragment>
  );
};

export default ConnectMsTeam;
