import * as Yup from 'yup';

import { PASSWORD_SPECIAL_CHARACTERS } from '../../../constants/regex';

export const newPasswordTooltipValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .matches(/[A-Z]/, 'One uppercase letter')
    .matches(/[a-z]/, 'One lowercase letter')
    .matches(/\d/, 'One numeral')
    .matches(PASSWORD_SPECIAL_CHARACTERS, 'One special character')
});

export const changePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Please enter your current password'),
  newPassword: Yup.string()
    .min(8, 'Password must be between 8 to 20 characters long')
    .max(20, 'Password must be between 8 to 20 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(PASSWORD_SPECIAL_CHARACTERS, 'Password must contain at least one special character')
    .required('Please enter a new password')
    .notOneOf(
      [Yup.ref('currentPassword'), null],
      'The new password must be different from your current password'
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Password did not match. Please try again')
    .required('Please confirm your password')
});
