import { SetStateAction, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import { useQueryClient } from 'react-query';
import CloudProviderLogo from '../../components/cloudAccounts/CloudProviderLogo/CloudProviderLogo';
import RefreshAccountsButton from '../../components/cloudAccounts/RefreshAccountsButton';
import {
  ICloudAccountsTable,
  TTableStatusText
} from '../../components/cloudAccounts/cloudAccounts.interface';
import '../../components/cloudAccounts/cloudAccounts.scss';
import CloudAccountsActions from '../../components/cloudAccounts/cloudAccountsActions/CloudAccountsActions';
import CloudAccountsSummary from '../../components/cloudAccounts/cloudAccountsSummary/CloudAccountsSummary';
import ConfigureNotifications from '../../components/cloudAccounts/configureNotifications/ConfigureNotifications';
import ConnectToCloudAccountButton from '../../components/cloudAccounts/connectToCloudAccountButton/connectToCloudAccountButton';
import TextTooltipWithEllipsis from '../../components/common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import CustomTable from '../../components/common/commonTable/CustomTable';
import { ITableColumns } from '../../components/common/commonTable/customTable.interface';
import TooltipCustom from '../../components/common/molecules/tooltipCustom/TooltipCustom';
import SearchForm from '../../components/common/searchForm/SearchForm';
import { TSearchFormValues } from '../../components/common/searchForm/searchForm.types';
import TableColumnStatus from '../../components/common/tableColumnStatus/TableColumnStatus';
import {
  ITableColumnStatus,
  TTableStatus
} from '../../components/common/tableColumnStatus/tableColumnStatus.interface';
import { CLOUD_ACCOUNTS_TEXT } from '../../configs';
import { CLOUD_ACCOUNTS_TOOLTIP_MESSAGES } from '../../configs/cloudAccounts';
import { RowsPerPage } from '../../constants/constants';
import { routes } from '../../constants/routes';
import ENUMS from '../../enums';
import useCheckUserPermissions from '../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import type { TGetAccountsResponse } from '../../services/types/cloudAccount.endpoints';
import { updateAccountInfoEditState } from '../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';
import { sortObjectArray } from '../../utils';
import { calculateNumberOfPages, getNextOffset } from '../../utils/pagination.utils';
import {
  getFetchAccountQueryKey,
  prefetchAccounts
} from '../../utils/pre-fetchers/prefetchCloudAccounts';
import { TSortType } from '../../utils/sortObjectArray/sortObjectArray.interface';

const {
  TableStatusTooltipText,
  CloudAccountsTableStatus,
  CloudAccountsEmailStatus,
  CloudAccountsSlackStatus,
  CloudAccountsMsTeamsStatus
} = ENUMS.cloudAccounts;

const CloudAccounts = ({
  data,
  isLoading,
  refetch,
  filterAccount,
  setFilterAccount,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage
}: {
  data: TGetAccountsResponse | undefined;
  isLoading: boolean;
  refetch: () => void;
  filterAccount: string;
  setFilterAccount: React.Dispatch<string>;
  pageNumber: number;
  setPageNumber: React.Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<SetStateAction<number>>;
}) => {
  const [searchByStringCounter, setSearchByStringCounter] = useState<number>(1);
  const [activeStatusTooltip, setActiveStatusTooltip] = useState<string>('');
  // This loader is specifically for refresh button, which turns into loading state every 30 sec where there is inProgress item present.
  // Considering this is silent reload of items, user should not see the loader in the whole table (only in the button)
  // So a new loader state is being used.
  const [autoRefreshLoader, setAutoRefreshLoader] = useState<boolean>(false);
  const { refetchItems } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;

  const queryClient = useQueryClient();

  /**
   * The queryClient will be pre-fetching the next data of our page. So we improved the user experience of our application
   */
  useEffect(() => {
    if (data?.count && calculateNumberOfPages(data?.count, rowsPerPage)) {
      prefetchAccounts(queryClient, getNextOffset(pageNumber), rowsPerPage, filterAccount);
    }
  }, [pageNumber, rowsPerPage, queryClient, filterAccount, data?.count]);

  const { canUserViewCloudAccount, canUserAddCloudAccount } = useCheckUserPermissions();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });

  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = JSON.parse(JSON.stringify(data?.accounts || [])).map(
      (item: Record<string, string | string[] | number>[], index: number) => {
        return { ...item, item_no: startIndex + index + 1 };
      }
    );
    const newData = sortObjectArray({
      arr: totalItems,
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];
    return newData;
  }, [dataSort, data?.accounts, pageNumber, rowsPerPage, searchByStringCounter]);

  const handleRedirectToAccountView = (
    args: [string | number | string[], Record<string, string | number | string[]>, number]
  ) => {
    dispatch(updateAccountInfoEditState(false));
    navigate(`/cloud-account/view/${args[1].accountId}`, { state: args });
  };

  const tableColumns: ITableColumns[] = [
    {
      key: 0,
      title: 'Cloud Provider',
      dataIndex: 'CSP',
      headElement: () => (
        <div className="cell-inner">
          <span>Cloud Provider</span>
        </div>
      ),
      bodyElement: (...args) => {
        const cloudServiceProvider = args[0] as string;

        return (
          <div className="cursor-context-menu">
            <CloudProviderLogo cloudServiceProvider={cloudServiceProvider} />
          </div>
        );
      }
    },
    {
      key: 1,
      title: 'Account Name',
      dataIndex: 'accountName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Cloud Account</span>
        </div>
      ),
      bodyElement: (...args) => {
        const text = args[0] as string;
        if (canUserViewCloudAccount) {
          return (
            <div onClick={() => handleRedirectToAccountView(args)} className="account-name">
              <TextTooltipWithEllipsis text={text} />
            </div>
          );
        }
        return (
          <TooltipCustom
            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_VIEW_ACCESS}
            conditionToDisplay={!canUserViewCloudAccount}
          >
            <div className="account-name account-name--is-disabled">
              <TextTooltipWithEllipsis text={text} />
            </div>
          </TooltipCustom>
        );
      }
    },
    {
      key: 2,
      title: 'Account/Tenant ID',
      dataIndex: 'accountId',
      sortable: true,
      headElement: () => (
        <div className="cell-inner center">
          <span>Account ID</span>
        </div>
      ),
      bodyElement: (...args) => {
        const account_tenantid = args[0] as string;
        return (
          <div className="account_tenantid">
            <TextTooltipWithEllipsis limit={29} text={account_tenantid} />
          </div>
        );
      }
    },
    {
      key: 3,
      title: 'Status',
      dataIndex: 'status',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Account Status</span>
        </div>
      ),
      bodyElement: (...args) => {
        const value = args[0] as TTableStatusText;
        const accountId = args[1].accountId as string;
        const status: ITableColumnStatus = {
          type: value.toLowerCase() as TTableStatus,
          text: CloudAccountsTableStatus[value],
          tooltipText: TableStatusTooltipText[value],
          showTooltip: activeStatusTooltip === accountId,
          closeTooltip: () => setActiveStatusTooltip(''),
          openTooltip: () => setActiveStatusTooltip(accountId),
          toggleTooltip: () => {
            setActiveStatusTooltip((prev) => (prev === accountId ? '' : accountId));
          }
        };
        return <TableColumnStatus {...status} />;
      }
    },
    {
      key: 4,
      title: 'configureNotifications',
      dataIndex: 'status',
      headElement: () => (
        <div className="cell-inner configure-notifications-head">
          <span>Configure Notifications</span>
        </div>
      ),
      bodyElement: (...args) => {
        const account = args[1] as unknown as ICloudAccountsTable;
        const slackStatus =
          (args[1] as unknown as ICloudAccountsTable)?.slackAccountMapping?.status ||
          CloudAccountsSlackStatus.Inactive;
        const msTeamsStatus =
          (args[1] as unknown as ICloudAccountsTable)?.msTeamsInstallation?.status ||
          CloudAccountsMsTeamsStatus.Inactive;
        const emailStatus =
          (args[1] as unknown as ICloudAccountsTable)?.userAccountNotification?.length > 0
            ? CloudAccountsEmailStatus.Active
            : CloudAccountsEmailStatus.Inactive;
        // This will be modified during integration
        return (
          <div className="configure-notifications-container">
            <ConfigureNotifications
              account={account}
              slackStatus={slackStatus}
              msTeamsStatus={msTeamsStatus}
              emailStatus={emailStatus}
            />
          </div>
        );
      }
    },
    {
      key: 5,
      title: 'Actions',
      dataIndex: 'status',
      headElement: () => (
        <div className="cell-inner actions-head">
          <span>Action</span>
        </div>
      ),
      bodyElement: (...args) => {
        const status = args[0] as unknown as TTableStatusText;
        const account = args[1] as unknown as ICloudAccountsTable;
        return (
          <div className="actions-container">
            <CloudAccountsActions
              accountStatus={status}
              account={account}
              fetchAccounts={() => {
                queryClient.resetQueries(
                  getFetchAccountQueryKey({
                    limit: rowsPerPage,
                    offset: pageNumber,
                    ...(filterAccount && { filterAccount: filterAccount })
                  })
                );
              }}
            />
          </div>
        );
      }
    }
  ];

  const searchAccountsByString = ({ query }: TSearchFormValues) => {
    setFilterAccount(query);
    setPageNumber(1);
    setSearchByStringCounter((prev) => prev + 1);

    queryClient.invalidateQueries(
      getFetchAccountQueryKey({
        limit: rowsPerPage,
        offset: pageNumber,
        filterAccount: query
      })
    );
  };

  useEffect(() => {
    setPageNumber(1);
  }, [rowsPerPage]);

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: 'Cloud Accounts', url: routes.CLOUD_ACCOUNTS },
      {
        text: 'Cloud Accounts',
        url: ''
      }
    ]
  });

  const handleRefreshTableClick = () => {
    setAutoRefreshLoader(true);
    refetch();
    setAutoRefreshLoader(false);
  };

  return (
    <div className={`cloud-accounts ${isLoading ? 'cloud-accounts--is-loading' : ''}`}>
      <CloudAccountsSummary />
      <div className="cloud-accounts__search">
        <SearchForm
          placeholder={CLOUD_ACCOUNTS_TEXT.SEARCH_PLACEHOLDER}
          className="cloud-accounts__search__search-users"
          onSearch={searchAccountsByString}
          shouldShowSearchButton={true}
        />
        <div className="cloud-accounts__search__buttons">
          {refetchItems && (
            <RefreshAccountsButton
              loading={autoRefreshLoader}
              onclickHandler={handleRefreshTableClick}
            />
          )}

          <TooltipCustom
            text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_ADD_ACCESS}
            conditionToDisplay={!canUserAddCloudAccount}
          >
            <ConnectToCloudAccountButton disabled={!canUserAddCloudAccount} />
          </TooltipCustom>
        </div>
      </div>

      <div className="cloud-accounts__table">
        <CustomTable
          tableColumns={tableColumns}
          tableData={sortedData}
          totalItems={data?.count || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPageOptions={RowsPerPage}
          isLoading={isLoading}
          sortState={dataSort}
          setSortState={setDataSort}
          shouldUpdatePageNumberOnItemChange={false}
        />
      </div>
    </div>
  );
};
export default CloudAccounts;

//TODO: Feature Need to check 1.Delete Account 2.Account Summary
