import { useEffect } from 'react';
import { isSupported } from 'firebase/messaging';

const useRegisterFirebaseServiceWorker = () => {
  const register = () => {
    isSupported()
      .then((supported) => {
        if (supported) {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker
              .register('/firebase-messaging-sw.js')
              .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
              })
              .catch((error) => {
                console.error('Service Worker registration failed:', error);
              });
          }
        } else {
          console.log(
            'This browser does not support the APIs required for Firebase Cloud Messaging.'
          );
        }
      })
      .catch((error) => {
        console.error('Error checking API support: ', error);
      });
  };

  useEffect(() => {
    register();
  }, []);
};

export default useRegisterFirebaseServiceWorker;
