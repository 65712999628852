import { Formik, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { GroupNameValidation } from './editGroup.validations';

import TextInput from '../../../common/textInput/TextInput';
import { routes } from '../../../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import {
  IAddGroupName,
  ITeamManagementInitialValues
} from '../../../../store/teamManagement/teamManagement.interface';
import { editGroupName, setCurrentActiveTabForm } from '../../../../store/actions';
import { BUTTON_TEXT } from '../../../../configs';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import Typography from '../../../common/typography/Typography';
import { TEAM_MANAGEMENT_TEXT } from '../../../../configs/teamManagement';

const GroupNameEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { editGroup1 } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const { groupname } = useParams() as unknown as { groupname: string };

  const addGroupNameInitialValues: IAddGroupName = {
    groupName: editGroup1.groupName
  };

  const handleCancel = () => {
    navigate(`${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${groupname}`);
    dispatch(editGroupName(''));
  };

  const handleNextClick = ({ groupName }: IAddGroupName) => {
    dispatch(editGroupName(groupName));
    dispatch(setCurrentActiveTabForm(1));
  };

  return (
    <>
      <Formik
        onSubmit={handleNextClick}
        initialValues={addGroupNameInitialValues}
        validationSchema={GroupNameValidation}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, touched }) => {
          return (
            <Form className="tm-edit-group-group-name-form">
              <PaperCustom variant="light" className="tm-edit-group-group-name-form__inner">
                <Typography
                  variant="body-3"
                  size="medium"
                  className="tm-edit-group-group-name-form__inner__heading"
                >
                  {TEAM_MANAGEMENT_TEXT.GROUP_DETAILS}
                </Typography>
                <TextInput
                  disabled={isSubmitting}
                  placeholder="Enter Team Name"
                  label="Team Name"
                  required={true}
                  errors={errors}
                  touched={touched}
                  name="groupName"
                  type="text"
                />
                <div className="tm-edit-group-group-name-form__inner__buttons add_user flex justify-between">
                  <CustomButton
                    variant={ButtonVariants.SECONDARY}
                    type="button"
                    text={BUTTON_TEXT.CANCEL}
                    className="cancel_button"
                    onClick={handleCancel}
                  />
                  <CustomButton
                    variant={ButtonVariants.PRIMARY}
                    type="submit"
                    text={BUTTON_TEXT.NEXT}
                    className="next_button"
                  />
                </div>
              </PaperCustom>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default GroupNameEdit;
