// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './confirmForgotPassword.scss';
import {
  IConfirmForgotPassword,
  IConfirmForgotPasswordApiResponse,
  IResendOTP
} from './ConfirmForgotPassword.types';
import ConfirmForgotPasswordValidationSchema from './ConfirmForgotPassword.validationSchema';

import { routes } from '../../constants/routes';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { BUTTON_TEXT } from '../../configs/form';
import usePasswordVisibility from '../../utils/usePasswordVisibility';
import useConfirmPasswordVisibility from '../../utils/useConfirmPasswordVisibility';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import services from '../../services';
import IMAGES from '../../assets';
import '../../components/user-authentication/UserAuth.css';
import { confirmSuccess, tooltipIcon } from '../../assets/images';
import InputFieldTooltip from '../../components/common/inputFieldTooltip/InputFieldTooltip';
import { passwordTooltipValidationSchema } from '../forceChangePassword/forceChangePassword.validation';
import useLoading from '../../hooks/loading/useLoading';
import AuthFormContainer from '../../components/common/formContainer/AuthFormContainer';
import TextInput from '../../components/common/textInput/TextInput';
import Typography from '../../components/common/typography/Typography';
import CustomButton from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import useForgotPasswordStore from '../../store/forgotPassword/useForgotPasswordStore';

const initialTimerValue = 15;
const smallestTwoDigitValue = 10;

const ConfirmForgotPassword = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Try to get organizationId and email from URL parameters
  const organizationId: string = searchParams.get('organizationId') as unknown as string;
  const email: string = searchParams.get('email') as unknown as string;

  // Check if state from navigation contains forgotPassword flag
  const isRedirectedFromForgotPassword: boolean =
    location.state && location.state.isRedirectedFromForgotPassword;

  const navigate = useNavigate();

  const { visible, toggleVisibility, inputType } = usePasswordVisibility(); //toggles Password visibility
  const { confirmPasswordVisible, confirmPasswordInputType, toggleConfirmPasswordVisibility } =
    useConfirmPasswordVisibility();

  const [seconds, setSeconds] = useState<number>(initialTimerValue); //timer
  const [isTimerRunning, setTimerIsRunning] = useState<boolean>(false); //to start the timer
  const [passwordTooltipInfoIcon, setPasswordTooltipInfoIcon] = useState<boolean>(false);
  const [showPasswordReqsTooltip, setShowPasswordReqsTooltip] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading(); //reusable loading
  const [isSetPasswordSuccessOpen, setIsSetPasswordSuccessOpen] = useState<boolean>(false);
  const [isCodeSentModalOpen, setIsCodeSentModalOpen] = useState<boolean>(false);
  const { resetForgotPasswordFormValues } = useForgotPasswordStore();

  const confirmForgotPasswordInitialValues: IConfirmForgotPassword = {
    org_id: organizationId, //org_id pre-filled from prev screen
    email: email, //email pre-filled from prev screen
    verification_code: '',
    password: '',
    confirm_password: ''
  };
  const decreaseSeconds = () => {
    //decrease the time by 1 sec
    setSeconds((prevSeconds) => prevSeconds - 1);
  };

  const startTimer = () => {
    if (!isTimerRunning) {
      //start the countdown if it's already not running
      setTimerIsRunning(true);
      setSeconds(15); // restart the timer of 15 seconds
    }
  };

  const resendOTP = async ({ values, resetForm }: IResendOTP) => {
    // reset input fields
    resetForm({
      values: {
        org_id: values.org_id,
        email: values.email,
        verification_code: confirmForgotPasswordInitialValues.verification_code,
        password: confirmForgotPasswordInitialValues.password,
        confirm_password: confirmForgotPasswordInitialValues.confirm_password
      }
    });

    const url = `${API_ENDPOINTS.USER_FORGOT_PASSWORD}?organizationId=${organizationId}&email=${email}`;
    // Make a GET request
    services
      .get({ url })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setIsCodeSentModalOpen(true);
          startTimer(); //start the timer after getting the status code 200 OK
        }
      })
      .catch((error: unknown) => {
        useErrorHandler({
          error,
          toastId: 'OTPSentFail',
          defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
        });
      });
  };

  const handleSubmit = async (confirmForgotPasswordInitialValues: IConfirmForgotPassword) => {
    startLoading();
    // Make a POST request

    try {
      const response: AxiosResponse<IConfirmForgotPasswordApiResponse> = await services.post({
        url: API_ENDPOINTS.USER_CONFIRM_FORGOT_PASSWORD,
        payload: {
          organizationId: confirmForgotPasswordInitialValues.org_id,
          email: confirmForgotPasswordInitialValues.email,
          otp: confirmForgotPasswordInitialValues.verification_code,
          password: confirmForgotPasswordInitialValues.password
        }
      });

      if (response.status >= 200 && response.status < 300) {
        setIsSetPasswordSuccessOpen(true);
        resetForgotPasswordFormValues();
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'ConfirmForgotPasswordFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (isTimerRunning && seconds > 0) {
      const countdownInterval = setInterval(decreaseSeconds, 1000);
      return () => clearInterval(countdownInterval); //cleanup function
    } else if (seconds === 0) {
      setTimerIsRunning(false);
    }
  }, [isTimerRunning, seconds]);

  const backToPrev = () => navigate(routes.FORGOT_PASSWORD);

  const handleSuccessModalClick = () => {
    setIsSetPasswordSuccessOpen(false);
    navigate(routes.SIGN_IN);
  };

  useEffect(() => {
    //initially start timer
    startTimer();
  }, []);

  return (
    <AuthFormContainer
      heading="Set New Password"
      backToPrevScreenText={isRedirectedFromForgotPassword ? 'Forgot Password' : ''}
      handleBackToPrev={backToPrev}
    >
      <Formik
        initialValues={confirmForgotPasswordInitialValues}
        onSubmit={handleSubmit}
        validationSchema={ConfirmForgotPasswordValidationSchema}
      >
        {({ values, resetForm, errors, touched, setTouched }) => (
          <Form className="confirmForgotPassword">
            <div className="confirmForgotPassword__field">
              <TextInput
                label="Verification Code"
                name="verification_code"
                placeholder="Enter Verification Code"
                errors={errors}
                touched={touched}
                required={true}
                type="text"
              />
              {isRedirectedFromForgotPassword && (
                <div className="confirmForgotPassword__field__timer">
                  <Typography
                    variant="body-3"
                    size="semiBold"
                    onClick={() => {
                      if (!isTimerRunning) {
                        // Only request new OTP, when the timer is not running
                        resendOTP({ values, resetForm });
                      }
                    }}
                    className={` ${
                      isTimerRunning
                        ? 'confirmForgotPassword__field__timer__disabledLink'
                        : 'confirmForgotPassword__field__timer__link'
                    }`}
                  >
                    Resend Verification Code
                  </Typography>
                  {/* Count down timer */}
                  {isTimerRunning && ( //only visible if it is running
                    <Typography variant="body-3" size="semiBold" className="text-primary-800">
                      00:{seconds < smallestTwoDigitValue ? `0${seconds}` : seconds}
                    </Typography>
                  )}
                </div>
              )}
            </div>

            <div className="confirmForgotPassword__field">
              <Typography
                variant="body-3"
                size="regular"
                as="label"
                className="text-black-900 label_required"
              >
                New Password
              </Typography>

              {showPasswordReqsTooltip && (
                <InputFieldTooltip
                  formValues={{ password: values.password }}
                  field="password"
                  schema={passwordTooltipValidationSchema}
                  title="Password must contain"
                  className="confirmForgotPassword__field__passwordTooltip"
                />
              )}
              <Field
                type={inputType}
                name="password"
                id="password"
                placeholder="Enter New Password"
                className="input_fields "
                autoComplete="password"
                onFocus={() => setShowPasswordReqsTooltip(true)}
                onBlur={() => {
                  setShowPasswordReqsTooltip(false);
                  setTouched({ ...touched, password: true });
                }}
                onMouseEnter={() => setShowPasswordReqsTooltip(true)}
                onMouseLeave={() => setShowPasswordReqsTooltip(false)}
              />
              <Tooltip
                title={visible ? 'Hide Password' : 'Show Password'}
                placement="bottom"
                arrow={true}
              >
                <img
                  src={visible ? IMAGES.VisibilityOnEye : IMAGES.VisibilityOffEye}
                  onClick={toggleVisibility}
                  data-testid="eye_icon"
                  alt={visible ? 'Show Password' : 'Hide Password'}
                  className="confirmForgotPassword__field__eyeIcon"
                />
              </Tooltip>

              <Typography
                variant="body-3"
                size="regular"
                as="div"
                className={`common-text-input__error  ${
                  errors.password && touched.password ? 'visible' : 'invisible'
                }`}
              >
                {errors.password && touched.password ? errors.password : 'Enter the new password'}
              </Typography>

              <div
                className="absolute top-[2rem] right-[-1.5rem] flex sm:hidden cursor-pointer"
                onClick={() => {
                  setShowPasswordReqsTooltip((prev) => !prev);
                  setPasswordTooltipInfoIcon(!passwordTooltipInfoIcon);
                }}
              >
                <div className="font-bold">
                  <img src={tooltipIcon} alt="info icon" className="text-primary info_icon" />
                </div>
              </div>
            </div>
            <div className="confirmForgotPassword__field">
              <Typography
                variant="body-3"
                size="regular"
                as="label"
                className="text-black-900 label_required"
              >
                Confirm Password
              </Typography>

              <Field
                type={confirmPasswordInputType}
                name="confirm_password"
                id="confirm_password"
                placeholder="Confirm Password"
                className="input_fields placeholder:text-placeholder_text text-normal_text"
                autoComplete="confirm-password"
              />
              <Tooltip
                title={confirmPasswordVisible ? 'Hide Confirm Password' : 'Show Confirm Password'}
                placement="bottom"
                arrow={true}
              >
                <img
                  src={confirmPasswordVisible ? IMAGES.VisibilityOnEye : IMAGES.VisibilityOffEye}
                  onClick={toggleConfirmPasswordVisibility}
                  data-testid="confirm_eye_icon"
                  alt={visible ? 'Show Confirm Password' : 'Hide Confirm Password'}
                  className="confirmForgotPassword__field__eyeIcon"
                />
              </Tooltip>

              <Typography
                variant="body-3"
                size="regular"
                as="div"
                className={`common-text-input__error  ${
                  errors.confirm_password && touched.confirm_password ? 'visible' : 'invisible'
                }`}
              >
                {errors.confirm_password && touched.confirm_password
                  ? errors.confirm_password
                  : 'Confirm your new password'}
              </Typography>
            </div>

            {/* Submit button */}
            <CustomButton
              text={BUTTON_TEXT.CONTINUE}
              className="w-full"
              type="submit"
              isLoading={loading}
            />
          </Form>
        )}
      </Formik>
      {isSetPasswordSuccessOpen && (
        <ConfirmModal
          title="Password Updated!"
          description="Your new password has been set successfully! Please sign in"
          descriptionWidth="88%"
          open={true}
          image={confirmSuccess}
          handleClose={handleSuccessModalClick}
          handleOkClick={handleSuccessModalClick}
          confirmButtonText="Sign In"
        />
      )}
      {isCodeSentModalOpen && (
        <ConfirmModal
          open={true}
          title="Verification Code Sent!"
          description="The verification code has been successfully sent to your email address."
          descriptionWidth="88%"
          confirmButtonText="OKAY"
          image={confirmSuccess}
          setOpen={() => setIsCodeSentModalOpen(false)}
        />
      )}
    </AuthFormContainer>
  );
};

export default ConfirmForgotPassword;
