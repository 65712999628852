/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { successToastStyle } from '../../../../configs/styleConstants';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { toastAutoCloseDuration } from '../../../../constants/constants';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import postWithAuth from '../../../../services/postWithAuth';
import {
  convertTime,
  reverseconvertTime
} from '../../../../utils/dateAndHourFormatting/dateAndHourFormatting';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import CloseButton from '../../../common/close-button/CloseButton';
import Typography from '../../../common/typography/Typography';
import NotificationRow from '../NotificationRow';
import { Anomaly } from '../notification.interface';

export interface INotificationCardProps {
  data: Anomaly;
  cardKey: string;
  nameOfCard?: string;
  descriptionOfCard?: string;
  isLoading?: boolean;
  canUserEditNotifications: boolean;
  orgTimeZone: string;
  abbreviation: string;
  refetchNotificationSetting: () => void;
}

const NotificationCard = ({
  cardKey,
  nameOfCard,
  descriptionOfCard,
  data,
  isLoading: loading,
  canUserEditNotifications,
  orgTimeZone,
  refetchNotificationSetting,
  abbreviation
}: INotificationCardProps) => {
  const [slackNotificationTime, setSlackNotificationTime] = useState<string>(
    reverseconvertTime(data.notification.slackNotification.scheduledTime || '', orgTimeZone)
  );
  const [teamsNotificationTime, setTeamsNotificationTime] = useState<string>(
    reverseconvertTime(data.notification.teamsNotification.scheduledTime || '', orgTimeZone)
  );
  const [emailNotificationTime, setEmailNotificationTime] = useState<string>(
    reverseconvertTime(data.notification.mailNotification.scheduledTime || '', orgTimeZone)
  );
  const [slackReportsTime, setSlackReportsTime] = useState<string>(
    reverseconvertTime(data.reports.slackNotification.scheduledTime || '', orgTimeZone)
  );
  const [teamsReportsTime, setTeamsReportsTime] = useState<string>(
    reverseconvertTime(data.reports.teamsNotification.scheduledTime || '', orgTimeZone)
  );
  const [emailReportsTime, setEmailReportsTime] = useState<string>(
    reverseconvertTime(data.reports.mailNotification.scheduledTime || '', orgTimeZone)
  );

  const [mailNotificationSwitch, setMailNotificationSwitch] = useState<boolean>(
    data.notification.mailNotification.enabled as boolean
  );
  const [mailReportsSwitch, setMailReportsSwitch] = useState<boolean>(
    data.reports.mailNotification.enabled as boolean
  );

  const handleEditSettings = async () => {
    try {
      const response = await postWithAuth({
        url: API_ENDPOINTS.SETTINGS_EDIT_NOTIFICATION_SETTINGS,
        payload: {
          [cardKey]: {
            notification: {
              slackNotification: {
                frequency: data.notification.slackNotification.frequency,
                scheduledTime: convertTime(slackNotificationTime, orgTimeZone),
                enabled: true,
                allowEdit: false
              },
              teamsNotification: {
                frequency: data.notification.teamsNotification.frequency,
                scheduledTime: convertTime(teamsNotificationTime, orgTimeZone),
                enabled: true,
                allowEdit: false
              },
              mailNotification: {
                frequency: data.notification.mailNotification.frequency,
                scheduledTime: convertTime(emailNotificationTime, orgTimeZone),
                enabled: mailNotificationSwitch,
                allowEdit: true
              },
              inAppNotification: {
                frequency: data.notification.inAppNotification.frequency,
                scheduledTime: data.notification.inAppNotification.scheduledTime,
                enabled: true,
                allowEdit: false
              }
            },
            reports: {
              slackNotification: {
                frequency: data.reports.slackNotification.frequency,
                scheduledTime: convertTime(slackReportsTime, orgTimeZone),
                enabled: true,
                allowEdit: false
              },
              teamsNotification: {
                frequency: data.reports.teamsNotification.frequency,
                scheduledTime: convertTime(teamsReportsTime, orgTimeZone),
                enabled: true,
                allowEdit: false
              },
              mailNotification: {
                frequency: data.reports.mailNotification.frequency,
                scheduledTime: convertTime(emailReportsTime, orgTimeZone),
                enabled: mailReportsSwitch,
                allowEdit: true
              }
            }
          }
        }
      });
      if (response) {
        refetchNotificationSetting();
        toast.success('Settings updated successfully', {
          style: successToastStyle,
          toastId: 'AccountCreateSuccessfully',
          closeButton: <CloseButton color={successToastStyle?.color as string} />,
          autoClose: toastAutoCloseDuration
        });
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'EditNotificationsFail',
        defaultMessage: 'Due to some unknown reasons settings has not been updated',
        autoCloseDuration: toastAutoCloseDuration
      });
    }
  };
  const handleUpdate = async () => {
    // Define conditions to check if there are any actual changes
    const hasSlackNotificationTimeChanged =
      slackNotificationTime !==
      reverseconvertTime(data.notification.slackNotification.scheduledTime || '', orgTimeZone);
    const hasTeamsNotificationTimeChanged =
      teamsNotificationTime !==
      reverseconvertTime(data.notification.teamsNotification.scheduledTime || '', orgTimeZone);
    const hasEmailNotificationTimeChanged =
      emailNotificationTime !==
      reverseconvertTime(data.notification.mailNotification.scheduledTime || '', orgTimeZone);
    const hasSlackReportsTimeChanged =
      slackReportsTime !==
      reverseconvertTime(data.reports.slackNotification.scheduledTime || '', orgTimeZone);
    const hasTeamsReportsTimeChanged =
      teamsReportsTime !==
      reverseconvertTime(data.reports.teamsNotification.scheduledTime || '', orgTimeZone);
    const hasEmailReportsTimeChanged =
      emailReportsTime !==
      reverseconvertTime(data.reports.mailNotification.scheduledTime || '', orgTimeZone);
    const hasMailNotificationSwitchChanged =
      mailNotificationSwitch !== (data.notification.mailNotification.enabled as boolean);
    const hasMailReportsSwitchChanged =
      mailReportsSwitch !== (data.reports.mailNotification.enabled as boolean);

    // Check if any condition is met
    const shouldUpdate =
      hasSlackNotificationTimeChanged ||
      hasTeamsNotificationTimeChanged ||
      hasEmailNotificationTimeChanged ||
      hasSlackReportsTimeChanged ||
      hasTeamsReportsTimeChanged ||
      hasEmailReportsTimeChanged ||
      hasMailNotificationSwitchChanged ||
      hasMailReportsSwitchChanged;

    // Only call handleEditSettings if there are actual changes
    if (shouldUpdate) {
      await handleEditSettings();
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [
    slackNotificationTime,
    teamsNotificationTime,
    emailNotificationTime,
    slackReportsTime,
    teamsReportsTime,
    emailReportsTime,
    mailNotificationSwitch,
    mailReportsSwitch,
    slackNotificationTime,
    teamsNotificationTime
  ]);

  // updated the local state when redux updates
  useEffect(() => {
    setMailNotificationSwitch(data.notification.mailNotification.enabled);
    setMailReportsSwitch(data.reports.mailNotification.enabled);
    setSlackNotificationTime(
      reverseconvertTime(data.notification.slackNotification.scheduledTime || '', orgTimeZone)
    );
    setTeamsNotificationTime(
      reverseconvertTime(data.notification.teamsNotification.scheduledTime || '', orgTimeZone)
    );
    setEmailNotificationTime(
      reverseconvertTime(data.notification.mailNotification.scheduledTime || '', orgTimeZone)
    );
    setEmailReportsTime(
      reverseconvertTime(data.reports.mailNotification.scheduledTime || '', orgTimeZone)
    );
    setSlackReportsTime(
      reverseconvertTime(data.reports.slackNotification.scheduledTime || '', orgTimeZone)
    );
    setTeamsReportsTime(
      reverseconvertTime(data.reports.teamsNotification.scheduledTime || '', orgTimeZone)
    );
  }, [data, orgTimeZone]);

  return (
    <PaperCustom variant="medium" className="notifications__wrapperContainer">
      {/* header */}
      <div className="notifications__wrapperContainer__header">
        {/* Title */}
        <Typography
          variant="body-2"
          size="medium"
          as="h2"
          className="notifications__wrapperContainer__header__title apply-loader"
        >
          {nameOfCard}
        </Typography>
        {/* Description */}
        <Typography
          variant="body-3"
          size="regular"
          as="p"
          className="notifications__wrapperContainer__header__description apply-loader"
        >
          {descriptionOfCard}
        </Typography>
      </div>
      {/* description */}
      <div className="notifications__wrapperContainer__container">
        {/* box */}
        <NotificationRow
          abbreviation={abbreviation}
          title="Slack Notifications"
          description="Receive spends alert updates on slack channels connected to your cloud accounts "
          initialState={data.notification.slackNotification.frequency as string}
          scheduleTime={
            slackNotificationTime
              ? slackNotificationTime
              : (data.notification.slackNotification.scheduledTime as string)
          }
          setterFunction={setSlackNotificationTime}
          allowEdit={data.notification.slackNotification.allowEdit}
          enabled={data.notification.slackNotification.enabled}
          isLoading={loading}
          cardKey={cardKey}
          key={cardKey + ' Slack notification' + data.notification.slackNotification.scheduledTime}
          canUserEditNotifications={canUserEditNotifications}
        />

        <NotificationRow
          abbreviation={abbreviation}
          title="MS Teams Notifications"
          description="Receive savings alert updates on MS Teams connected to your cloud accounts "
          initialState={data.notification.teamsNotification.frequency as string}
          scheduleTime={
            teamsNotificationTime
              ? teamsNotificationTime
              : (data.notification.teamsNotification.scheduledTime as string)
          }
          setterFunction={setTeamsNotificationTime}
          allowEdit={data.notification.teamsNotification.allowEdit}
          enabled={data.notification.teamsNotification.enabled}
          isLoading={loading}
          cardKey={cardKey}
          key={cardKey + ' Teams notification' + data.notification.teamsNotification.scheduledTime}
          canUserEditNotifications={canUserEditNotifications}
        />
        <NotificationRow
          abbreviation={abbreviation}
          title="Email Notifications"
          description="Receive savings alert updates on selected email recipients to your cloud accounts "
          initialState={data.notification.mailNotification.frequency}
          scheduleTime={
            emailNotificationTime
              ? emailNotificationTime
              : data.notification.mailNotification.scheduledTime
          }
          setterFunction={setEmailNotificationTime}
          allowEdit={data.notification.mailNotification.allowEdit}
          enabled={
            mailNotificationSwitch
              ? mailNotificationSwitch
              : data.notification.mailNotification.enabled
          }
          setSwitch={setMailNotificationSwitch}
          isLoading={loading}
          cardKey={cardKey}
          key={
            data.notification.mailNotification.enabled +
            cardKey +
            data.notification.mailNotification.allowEdit
          }
          canUserEditNotifications={canUserEditNotifications}
        />
        {/* <NotificationRow  //Todo : We are not using In-App Notifications in this version, so we are commenting it out. Once required, we will uncomment it and implement.
          abbreviation={abbreviation}
          title="In-App Notifications"
          description="Receive savings alert updates on in-app notifications"
          initialState={data.notification.inAppNotification.frequency}
          scheduleTime={data.notification.inAppNotification.scheduledTime}
          allowEdit={data.notification.inAppNotification.allowEdit}
          enabled={data.notification.inAppNotification.enabled}
          isLoading={loading}
          cardKey={cardKey}
          key={
            data.notification.inAppNotification.scheduledTime +
            cardKey +
            data.notification.inAppNotification.frequency
          }
          canUserEditNotifications={canUserEditNotifications}
        /> */}
      </div>
      {/* Ruler */}
      {/* <Ruler className="notifications__ruler" /> */}
      {/* description */}
      {/* <div className="notifications__container"> */}
      {/* <Typography
          variant="body-2"
          size="medium"
          as="h3"
          className="notifications__wrapperContainer__container__subtitle apply-loader"
        >
          Reports   //Todo : We are not using Reports in this version, so we are commenting it out. Once required, we will uncomment it and implement.
        </Typography> */}
      {/* box */}
      {/* <NotificationRow
          abbreviation={abbreviation}
          title="Slack Notifications"
          description="Receive PDF cost report on your slack channels"
          initialState={data.reports.slackNotification.frequency}
          scheduleTime={
            slackReportsTime ? slackReportsTime : data.reports.slackNotification.scheduledTime
          }
          setterFunction={setSlackReportsTime}
          allowEdit={data.reports.slackNotification.allowEdit}
          enabled={data.reports.slackNotification.enabled}
          isLoading={loading}
          cardKey={cardKey}
          key={data.reports.slackNotification.frequency + data.reports.slackNotification.enabled}
          canUserEditNotifications={canUserEditNotifications}
        /> */}
      {/* <NotificationRow
          abbreviation={abbreviation}
          title="MS Teams Notifications"
          description="Receive PDF savings report on your MS Teams"
          initialState={data.reports.teamsNotification.frequency}
          scheduleTime={
            teamsReportsTime ? teamsReportsTime : data.reports.teamsNotification.scheduledTime
          }
          setterFunction={setTeamsReportsTime}
          allowEdit={data.reports.teamsNotification.allowEdit}
          enabled={data.reports.teamsNotification.enabled}
          isLoading={loading}
          cardKey={cardKey}
          key={
            data.reports.teamsNotification.frequency +
            data.reports.teamsNotification.enabled +
            'Ms Teams'
          }
          canUserEditNotifications={canUserEditNotifications}
        /> */}
      {/* <NotificationRow
          abbreviation={abbreviation}
          title="Email Notifications"
          description="Receive PDF cost report to your mail recipients"
          initialState={data.reports.mailNotification.frequency}
          scheduleTime={
            emailReportsTime ? emailReportsTime : data.reports.mailNotification.scheduledTime
          }
          setterFunction={setEmailReportsTime}
          allowEdit={data.reports.mailNotification.allowEdit}
          enabled={mailReportsSwitch ? mailReportsSwitch : data.reports.mailNotification.enabled}
          setSwitch={setMailReportsSwitch}
          isLoading={loading}
          cardKey={cardKey}
          key={data.reports.mailNotification.frequency + cardKey}
          canUserEditNotifications={canUserEditNotifications}
        /> */}
      {/* </div> */}
    </PaperCustom>
  );
};

export default NotificationCard;
