import './welcome.scss';

import '../user-authentication/UserAuth.css';

import ConnectToCloudAccountButton from './connectToCloudAccountButton/connectToCloudAccountButton';
import type { WelcomeCloudAccountServiceCardProps } from './welcomeCloudAccountServiceCard/welcomeCloudAccountServiceCard';
import WelcomeCloudAccountServiceCard from './welcomeCloudAccountServiceCard/welcomeCloudAccountServiceCard';

import IMAGES from '../../assets';
import useCheckUserPermissions from '../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { closeAlertMessage, openAlertMessage } from '../../store/actions';
import type { IMyProfileState } from '../../store/myProfile/myProfile.interface';
import { Text, TextType } from '../../utils/Text';
import CloudInSpotName from '../cloudInSpotName/CloudInSpotName';
import PaperCustom from '../common/atoms/PaperCustom/PaperCustom';
import TooltipCustom from '../common/molecules/tooltipCustom/TooltipCustom';
import Typography from '../common/typography/Typography';
import './cloudAccounts.scss';

const cloudAccountServices: WelcomeCloudAccountServiceCardProps[] = [
  {
    heading: 'Integrate with Slack',
    description:
      'Integrate with Slack to receive notifications such as Saving Opportunities and Daily Spends on your Slack channels.',
    image: IMAGES.SlackBigLogo,
    toolTipMsg: 'To enable Slack integration, please connect a cloud account first.'
  },
  {
    heading: 'Integrate with MS Teams',
    description:
      'Integrate with MS Teams to receive notifications such as Saving Opportunities and Daily Spends on your MS Teams channels.',
    image: IMAGES.MsTeamBigLogo,
    toolTipMsg: 'To enable MS Teams integration, please connect a cloud account first. '
  },
  {
    heading: 'Integrate with Email',
    description:
      'Integrate with Email to receive notifications such as Saving Opportunities and Daily Spends on your Email.',
    image: IMAGES.EmailBigLogo,
    toolTipMsg: 'To enable Email integration, please connect a cloud account first.'
  }
];

const WelcomeMessageCloudAccounts = () => {
  const firstName: string = useAppSelector<IMyProfileState>((state) => state.MyProfile)?.firstName;

  const dispatch = useAppDispatch();

  const { canUserAddCloudAccount } = useCheckUserPermissions();

  useUpdateBreadcrumbs({
    breadcrumbs: []
  });

  useComponentMount({
    onMountFunc: () => {
      dispatch(
        openAlertMessage({
          showAlertMessage: true,
          message: 'Link your cloud account to monitor daily expenses',
          textColor: '#047857',
          backgroundColor: '#ECFDF5'
        })
      );
    },
    onUnMountFunc: () => {
      dispatch(closeAlertMessage());
    }
  });

  return (
    <div className="welcomeContainer">
      <PaperCustom variant="medium" className="welcomeContainer__gradientCard">
        <img
          src={IMAGES.Integration}
          alt="integration Logo"
          className="welcomeContainer__gradientCard__integration"
        />
        <Text type={TextType.H1} className="welcomeContainer__gradientCard__welcomeHeader">
          Welcome to <CloudInSpotName className="!font-semibold" />, {firstName} !
        </Text>
        <div>
          <Typography
            variant="body-3"
            size="medium"
            as="p"
            className="welcomeContainer__gradientCard__welcomeText"
          >
            We recommend to click on connect to Cloud button below.
          </Typography>
          <Typography
            variant="body-3"
            size="medium"
            as="p"
            className="welcomeContainer__gradientCard__welcomeText"
          >
            It will help you connect your cloud account to <CloudInSpotName /> and view daily
            spends.
          </Typography>
        </div>
        <TooltipCustom
          text={'User does not have access to connect account'}
          conditionToDisplay={!canUserAddCloudAccount}
        >
          <ConnectToCloudAccountButton disabled={!canUserAddCloudAccount} />
        </TooltipCustom>
      </PaperCustom>
      <div className="welcomeContainer__cardWrapper">
        {cloudAccountServices.map((accountService) => (
          <WelcomeCloudAccountServiceCard
            heading={accountService.heading}
            description={accountService.description}
            image={accountService.image}
            key={accountService.heading}
            toolTipMsg={accountService.toolTipMsg}
          />
        ))}
      </div>
    </div>
  );
};

export default WelcomeMessageCloudAccounts;
