import { useState } from 'react';

import { TUseVisibility } from './useVisibility.types';

/**
 * This is a custom React hook called `useVisibility` that takes an initial visibility value as an argument and returns an object with two properties: visible and toggleVisibility.

 * The visible property is a boolean value that represents the current visibility state of the component. It is initialized with the initialVisibility argument passed to the hook.

 * The toggleVisibility function is a callback that toggles the visibility state of the component. It uses the useState hook to update the visible state by inverting the current value.

 * The hook is useful for creating reusable components that can be toggled between visible and hidden states. By using this hook, you can avoid duplicating code for managing the visibility state of your components.
 */
const useVisibility = (initialVisibility: boolean): TUseVisibility => {
  const [visible, setVisible] = useState<boolean>(initialVisibility);

  const toggleVisibility = (): void => {
    setVisible((prevVisible: boolean) => !prevVisible);
  };

  return {
    visible,
    toggleVisibility
  };
};

export default useVisibility;
