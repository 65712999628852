import type { AnyAction, Reducer } from '@reduxjs/toolkit';

import {
  FETCH_MY_PROFILE_DATA,
  FETCH_USER_PERMISSIONS,
  UPDATE_PROFILE_LOADING_STATE
} from './myProfile.actionTypes';
import { IMyProfileState } from './myProfile.interface';

export const myProfileReducerState: IMyProfileState = {
  email: '',
  organizationId: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  onBoardedOn: '',
  groups: [],
  permissions: [],
  url: '',
  isProfileLoaded: false,
  isProfileImageValid: false,
  isOwner: false,
  isProfileLoading: true,
  activeCloudAccount: false,
  deactivateUser: false,
  permissionUpdated: false,
  resetUserPassword: false
};

const MyProfileReducer: Reducer<IMyProfileState> = (
  state = myProfileReducerState,
  action: AnyAction
) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MY_PROFILE_DATA:
      return {
        ...state,
        ...payload,
        loaded: true,
        isProfileLoading: false
      };

    case FETCH_USER_PERMISSIONS: {
      return { ...state, permissions: payload };
    }
    case UPDATE_PROFILE_LOADING_STATE: {
      return { ...state, isProfileLoading: payload };
    }
    default:
      return state;
  }
};

export default MyProfileReducer;
