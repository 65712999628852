import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { PersonalInfoValidation } from './AddUser.validation';

import { BUTTON_TEXT } from '../../../configs';
import { routes } from '../../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import { addUserStep1, setCurrentActiveTabForm } from '../../../store/actions';
import {
  IAddUserStep1,
  ITeamManagementInitialValues
} from '../../../store/teamManagement/teamManagement.interface';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import TextInput from '../../common/textInput/TextInput';
import Typography from '../../common/typography/Typography';
import './AddUser.scss';

const PersonalInfoInput = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addUser1 } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const personalInfoInitialValues: IAddUserStep1 = {
    firstName: addUser1.firstName,
    lastName: addUser1.lastName
  };

  const handleNextClick = ({ firstName, lastName }: IAddUserStep1) => {
    dispatch(addUserStep1({ firstName, lastName })); // add user values in the redux and go to the next page
    dispatch(setCurrentActiveTabForm(1));
  };

  const handleCancel = () => {
    navigate(routes.TEAM_MANAGEMENT_USERS_TABLE);
    dispatch(addUserStep1({ firstName: '', lastName: '' })); //after user cancels the flow values should be resets
  };

  return (
    <Formik
      onSubmit={handleNextClick}
      initialValues={personalInfoInitialValues}
      validationSchema={PersonalInfoValidation}
    >
      {({ isSubmitting, errors, touched }) => {
        return (
          <Form>
            <PaperCustom variant="medium" className="personalInfoInput">
              <div className="personalInfoInput__header">
                <Typography variant="body-2" size="medium" className="text-black-1000">
                  Personal Information
                </Typography>
                <Typography variant="body-3" size="regular" className="text-black-800">
                  Add a new user to your <CloudInSpotName /> account starting from name.
                </Typography>
              </div>
              <TextInput
                disabled={isSubmitting}
                placeholder="User's First Name"
                label="First Name"
                required={true}
                errors={errors}
                touched={touched}
                name="firstName"
                type="text"
                maxLength={25}
              />
              <TextInput
                disabled={isSubmitting}
                placeholder="User's Last Name"
                label="Last Name"
                required={true}
                errors={errors}
                touched={touched}
                name="lastName"
                type="text"
                maxLength={25}
              />
              <div className="personalInfoInput__buttons">
                <CustomButton
                  variant={ButtonVariants.SECONDARY}
                  type="button"
                  text={BUTTON_TEXT.CANCEL}
                  onClick={handleCancel}
                  buttonWidth="200px"
                />

                <CustomButton
                  variant={ButtonVariants.PRIMARY}
                  type="submit"
                  text={BUTTON_TEXT.NEXT}
                  buttonWidth="200px"
                />
              </div>
            </PaperCustom>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalInfoInput;
