// External imports
import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import { TSvgIconProps } from '../../common.interface';

const LockPadIcon = ({ color = PRIMARY_COLORS[700] }: TSvgIconProps) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7503 6.5V5C10.7503 2.92893 9.07137 1.25 7.00031 1.25C4.92924 1.25 3.25031 2.92893 3.25031 5V6.5M7.00031 9.875V11.375M4.60031 14.75H9.40031C10.6604 14.75 11.2905 14.75 11.7718 14.5048C12.1951 14.289 12.5394 13.9448 12.7551 13.5215C13.0003 13.0402 13.0003 12.4101 13.0003 11.15V10.1C13.0003 8.83988 13.0003 8.20982 12.7551 7.72852C12.5394 7.30516 12.1951 6.96095 11.7718 6.74524C11.2905 6.5 10.6604 6.5 9.40031 6.5H4.60031C3.34019 6.5 2.71013 6.5 2.22883 6.74524C1.80546 6.96095 1.46126 7.30516 1.24554 7.72852C1.00031 8.20982 1.00031 8.83988 1.00031 10.1V11.15C1.00031 12.4101 1.00031 13.0402 1.24554 13.5215C1.46126 13.9448 1.80546 14.289 2.22883 14.5048C2.71013 14.75 3.34019 14.75 4.60031 14.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockPadIcon;
