import { create } from 'zustand';

import { ICloudAccountsEmailList } from './cloudAccounts.interface';

import { TAccount } from '../../services/types/cloudAccount.endpoints';

type TEmailStore = {
  emailList: Array<ICloudAccountsEmailList>;
  accountDetails: TAccount;
};

type TAddEmailActions = {
  setEmailList: (list: ICloudAccountsEmailList[]) => void;
  setAccountDetails: (account: TAccount) => void;
};

const useEmailListStore = create<TEmailStore & TAddEmailActions>((set) => ({
  emailList: [],
  accountDetails: new TAccount(),
  setAccountDetails: (account: TAccount) => {
    set(() => ({
      accountDetails: account
    }));
  },
  setEmailList: (list: ICloudAccountsEmailList[]) =>
    set(() => ({
      emailList: list
    }))
}));

export default useEmailListStore;
