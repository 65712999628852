import React from 'react';

import { colorConstants } from '../../../../configs/styleConstants';

interface ICheckboxIcon {
  strokeColor?: string;
  height?: number;
  width?: number;
  containerProps?: React.HTMLAttributes<SVGElement>;
}

const CheckboxIcon = ({
  strokeColor = colorConstants.white2,
  height = 19,
  width = 18,
  containerProps = {}
}: ICheckboxIcon) => {
  return (
    <svg
      {...containerProps}
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 4.5L7.25 12.75L3.5 9"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckboxIcon;
