import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './msteam.scss';
// eslint-disable-next-line
import ConnectedMsTeamsView from './ConnectedMsTeamsView';
import IMAGES from '../../../../assets';
import { CLOUD_ACCOUNTS_TEXT } from '../../../../configs';
import { COMMON_TEXT } from '../../../../configs/common';
import { colorConstants } from '../../../../configs/styleConstants';
import { routes } from '../../../../constants/routes';
import useComponentMount from '../../../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import { viewMsTeams } from '../../../../services/msTeams.service';
import {
  TMSTeamsMapping,
  TViewMsTeamsRequest,
  TViewMsTeamsResponse
} from '../../../../services/types/msTeams.endpoints';
import {
  closeAlertMessage,
  fetchCloudAccountDetailsRequest,
  openAlertMessage,
  setActiveTab,
  setHeading,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import useMsTeamStore from '../../../../store/msTeams/useMsTeamStore';
import NoDataScreen from '../../../common/noData/NoDataScreen';
import TextTooltipWithEllipsis from '../../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';

function MsTeam() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { startLoading, stopLoading, loading } = useLoading();
  const { isConnected, setIsConnected } = useMsTeamStore();

  const accountDetails = (
    useAppSelector((store) => store.cloudAccounts) as ICloudAccountsReducerInitialState
  ).accountDetails;

  const [msTeamsMapping, setMsTeamsMapping] = useState<TMSTeamsMapping | null>(null);

  const handleConnectToMsTeam = () => navigate(`/cloud-account/view/msteam/connect/${id}`);

  const handleNoDataScreen = async () => {
    handleConnectToMsTeam();
  };

  useEffect(() => {
    dispatch(
      openAlertMessage({
        showAlertMessage: !isConnected,
        message: 'Integrate your MS Teams to receive daily notifications',
        textColor: colorConstants.green1,
        backgroundColor: colorConstants.lightGreen1
      })
    );
  }, [isConnected]);

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(`Cloud Accounts`));
      dispatch(setActiveTab(2));

      if (!accountDetails.status && id) {
        dispatch(
          fetchCloudAccountDetailsRequest({
            accountId: id,
            startLoading,
            stopLoading: () => {
              dispatch(updateSkeletonLoader({ isLoading: false }));
            }
          })
        );
      }
    },
    onUnMountFunc: () => {
      dispatch(closeAlertMessage());
    }
  });

  const viewMSTeamsDetails = async () => {
    if (id) {
      startLoading();
      try {
        const viewMsTeamsRequest: TViewMsTeamsRequest = { accountId: id };
        const viewMSTeamResponse: TViewMsTeamsResponse = await viewMsTeams(viewMsTeamsRequest);
        setMsTeamsMapping(viewMSTeamResponse.msTeamsMapping);
        if (viewMSTeamResponse.msTeamsMapping) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'MSTeamsViewFail',
          defaultMessage: 'Error in Ms Teams disconnection'
        });
      } finally {
        stopLoading();
      }
    }
  };

  useEffect(() => {
    viewMSTeamsDetails();
  }, []);

  const updateMsTeamsBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        {
          text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW,
          url: `/cloud-account/view/${id}`
        },

        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountDetails?.accountName}
            />
          ),
          url: `/cloud-account/view/${id}`
        },
        {
          text: `MS Teams`,
          url: ``
        }
      ])
    );
  };
  useEffect(() => {
    updateMsTeamsBreadcrumbs();
  }, [accountDetails?.accountName]);

  if (loading || isConnected) {
    return (
      <ConnectedMsTeamsView
        msTeamsMapping={msTeamsMapping}
        setMsTeamsMapping={setMsTeamsMapping}
        viewMSTeamsDetails={viewMSTeamsDetails}
        loading={loading}
      />
    );
  }

  return (
    <div className="noDataWrapper apply-loader">
      <NoDataScreen
        img={IMAGES.MsTeamBigLogo}
        heading="Integrate with MS Teams"
        line1="We recommend to click on connect to MS Teams button below."
        line2="It will help you connect your cloud account to your MS Teams and view daily spends."
        buttonText="Connect to MS Teams"
        buttonWidth="218px"
        onClick={handleNoDataScreen}
      />
    </div>
  );
}

export default MsTeam;
