import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { IUserProfileData } from './myProfile.interface';

import ProfileImageUpdateModal from '../../components/myProfile/ProfileImageUpdateModal/ProfileImageUpdateModal';
import getUserData from '../../helpers/getUserData';
import { checkProfileImageValidity } from '../../store/actions';
import { profileHeadText } from '../../utils';
import './profileImage.scss';

const ProfileImage = ({
  userProfileData,
  isTeamUserProfile
}: {
  userProfileData: IUserProfileData;
  isTeamUserProfile: boolean;
}) => {
  const [profileImageModalOpen, setProfileImageModalOpen] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>('');
  const userData = getUserData();

  const checkImageUrlValid = async (url: string) => {
    const isValid: boolean = await checkProfileImageValidity({ url });
    isValid ? setImgUrl(userProfileData.url) : setImgUrl('');
  };

  const openProfileImageModal = (): void => {
    if (!isTeamUserProfile) setProfileImageModalOpen(true);
  };

  useEffect(() => {
    if (userProfileData.url) checkImageUrlValid(userProfileData.url);
  }, [userProfileData.url]);

  return (
    <div className={classNames(!isTeamUserProfile ? 'cursor-pointer' : '')}>
      {imgUrl ? (
        <div
          className={classNames('relative', {
            img_circle: !isTeamUserProfile
          })}
          onClick={openProfileImageModal}
        >
          <img
            src={imgUrl}
            alt="profile image"
            className={classNames('h-[126px] w-[126px] rounded-full')}
          />
        </div>
      ) : (
        <p
          className={classNames(
            'text-[2rem] relative p-7 h-[126px] w-[126px] font-semibold  upload_container-profile-image-text flex justify-center items-center',
            { img_circle: !isTeamUserProfile }
          )}
          onClick={openProfileImageModal}
        >
          {profileHeadText({ userProfileData })}
        </p>
      )}
      {profileImageModalOpen && (
        <ProfileImageUpdateModal
          open={profileImageModalOpen}
          setOpen={setProfileImageModalOpen}
          url={imgUrl}
          email={userData.email}
        />
      )}
    </div>
  );
};

export default ProfileImage;
