import { useRef } from 'react';

import './simpleTooltip.scss';
import { ISimpleTooltip } from './simpleTooltip.interface';

import useElementPosition from '../../../hooks/elementPosition/useElementPosition';
import { IElementPosition } from '../../../hooks/elementPosition/useElementPosition.interface';

const SimpleTooltip = ({
  children,
  text,
  showTooltip,
  setShowTooltip,
  limitWidth = true,
  onMouseEnterFunc,
  onMouseLeaveFunc
}: ISimpleTooltip) => {
  const simpleTooltipRef = useRef<HTMLDivElement>(null);
  const tooltipMessageRef = useRef<HTMLDivElement>(null);

  const simpleTooltipPosition = useElementPosition({
    ref: simpleTooltipRef,
    triggers: [showTooltip]
  }) as IElementPosition;
  const tooltipMessagePosition = useElementPosition({
    ref: tooltipMessageRef,
    triggers: [showTooltip]
  }) as IElementPosition;

  const toolTiptop = `${simpleTooltipPosition.y - tooltipMessagePosition.height - 8}px`;
  const toolTipLeft = `${
    simpleTooltipPosition.x - tooltipMessagePosition.width / 2 + simpleTooltipPosition.width / 2
  }px`;

  return (
    <div
      className="simple-tooltip"
      onMouseEnter={() => {
        if (onMouseEnterFunc) {
          onMouseEnterFunc();
        } else {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => {
        if (onMouseLeaveFunc) {
          onMouseLeaveFunc();
        } else {
          setShowTooltip(false);
        }
      }}
      ref={simpleTooltipRef}
    >
      {children}
      {
        <>
          <p
            className={`simple-tooltip__tooltip ${
              limitWidth ? 'simple-tooltip__tooltip--limit-width' : ''
            } `}
            ref={tooltipMessageRef}
            style={{
              top: toolTiptop,
              left: toolTipLeft,
              visibility: showTooltip ? 'visible' : 'hidden',
              opacity: showTooltip ? 100 : 0
            }}
          >
            {text}
          </p>
        </>
      }
    </div>
  );
};

export default SimpleTooltip;
