import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import { TIcon } from '../icon.types';

const ArrowIcon = ({ color = PRIMARY_COLORS[600] }: TIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M6 9.5L12 15.5L18 9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
