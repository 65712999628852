import React from 'react';

import IMAGES from './../../../assets';

import ENUMS from '../../../enums';

const { CloudServiceProvider } = ENUMS.cloudAccounts;

interface ICloudProviderLogoProps {
  cloudServiceProvider: string;
}

function getCloudServiceProviderImage(cloudServiceProvider: string): string {
  if (cloudServiceProvider === CloudServiceProvider.AWS) {
    return IMAGES.AWSLogo;
  } else if (cloudServiceProvider === CloudServiceProvider.Azure) {
    return IMAGES.AzureLogo;
  }
  return IMAGES.DefaultServiceProviderLogo;
}

const CloudProviderLogo: React.FC<ICloudProviderLogoProps> = ({ cloudServiceProvider }) => {
  const logoSrc: string = getCloudServiceProviderImage(cloudServiceProvider);

  return (
    <div className="cloud-provider-logo apply-loader">
      {logoSrc && (
        <img
          src={logoSrc}
          alt={`${cloudServiceProvider} Logo`}
          className="apply-loader grid-container__grid-item__value"
          loading="lazy"
        />
      )}
    </div>
  );
};

export default CloudProviderLogo;
