export enum PermissionModuleApiKeyEnums {
  CLOUD_ACCOUNTS = 'accounts',
  TEAM_MANAGEMENT = 'team-mgmt',
  ORGANIZATION = 'organization'
}

export enum PermissionNameEnums {
  // Cloud accounts
  VIEW_CLOUD_ACCOUNTS = 'View Cloud Accounts',
  EDIT_CLOUD_ACCOUNTS = 'Edit Cloud Accounts(info-Edit, pause,resume,disconnect,reconnect,delete)',

  // Team management

  VIEW_TEAM_MANAGEMENT = 'View Users, teams, archived users.',
  EDIT_TEAM_MANAGEMENT = 'Edit \n(Add users, Edit other users, delete users, deactivate users, reactivate user)\n(Add teams, edit teams, delete teams, add users to teams, remove users from group)',

  // Settings
  VIEW_SETTINGS = 'View Settings',
  EDIT_SETTINGS = 'Edit Settings'
}

export enum PermissionApiEndpointsEnums {
  // Cloud Accounts
  ADD_ACCOUNT = 'add-account',
  GET_ACCOUNT = 'get-account',
  CONFIRM_ACCOUNT = 'confirm-account',
  DELETE_ACCOUNT = 'delete-account',
  DISCONNECT_ACCOUNT = 'disconnect-account',
  EDIT_ACCOUNT = 'edit-account',
  PAUSE_ACCOUNT = 'pause-account',
  RECONNECT_ACCOUNT = 'reconnect-account',
  RESUME_ACCOUNT = 'resume-account',

  // Slack
  LIST_SLACK_CHANNELS = 'slack-list-channels',
  SLACK_GET_ACCOUNT_TO_CHANNEL_MAPPING = 'slack-get-account-to-channel-mapping',
  SLACK_ADD_ACCOUNT_TO_CHANNEL = 'slack-add-account-to-channel',
  SLACK_DISCONNECT = 'slack-disconnect-account-from-channel',

  // Email
  EMAIL_LIST = 'list-account-user-email-notification',
  ADD_EMAIL_ACCOUNT = 'add-account-user-email-notification',
  REMOVE_EMAIL_ACCOUNT = 'remove-account-user-email-notification',

  // Team Management
  ACTIVATE_USER = 'restore-user',
  ADD_USER = 'add-user',
  ADD_USER_TO_GROUPS = 'add-user-to-groups',
  ADD_USERS_TO_GROUP = 'add-users-to-group',
  ADD_GROUP = 'create-group',
  DEACTIVATE_USER = 'archive-user',
  EDIT_GROUP = 'edit-group',
  EDIT_OTHER_USERS = 'edit-user',
  EDIT_OTHER_USERS_PERMISSION = 'edit-access-control',
  DELETE_GROUP = 'delete-group',
  DELETE_USER = 'delete-user',
  GET_USER = 'get-user',
  GET_GROUP_PERMISSIONS = 'get-group-permissions',
  GET_GROUP_USERS = 'get-group-users',
  REMOVE_USER_FROM_GROUPS = 'remove-user-from-groups',
  REMOVE_USERS_FROM_GROUP = 'remove-users-from-group',

  LIST = 'list',
  LIST_USERS = 'list-users',
  LIST_GROUPS = 'list-groups',
  LIST_DEACTIVATED_USERS = 'list-archived-users',

  // Settings
  GET_ORG_DETAILS = 'get-org-details',
  GET_NOTIFICATION_SETTINGS = 'get-notification-settings',
  EDIT_ORG_DETAILS = 'edit-org-details',
  EDIT_NOTIFICATION_SETTINGS = 'edit-org-notification-settings',

  // My Profile
  RESET_PASSWORD = 'reset-password'
}

export enum PermissionVariablesEnums {
  canUserViewCloudAccount = 'canUserViewCloudAccount',
  canUserAddCloudAccount = 'canUserAddCloudAccount',
  canUserViewAccountInfo = 'canUserViewAccountInfo',
  canUserViewSlackAccount = 'canUserViewSlackAccount',
  canUserViewMsTeamAccount = 'canUserViewMsTeamAccount',
  canUserViewEmailList = 'canUserViewEmailList',
  canUserEditCloudAccount = 'canUserEditCloudAccount',
  canUserResumeCloudAccount = 'canUserResumeCloudAccount',
  canUserPauseCloudAccount = 'canUserPauseCloudAccount',
  canUserReconnectCloudAccount = 'canUserReconnectCloudAccount',
  canUserAddEmailNotification = 'canUserAddEmailNotification',
  canUserDeleteEmail = 'canUserDeleteEmail',
  canUserAddEmailRecipients = 'canUserAddEmailRecipients',
  canUserRemoveEmailRecipients = 'canUserRemoveEmailRecipients',
  canUserAddSlack = 'canUserAddSlack',
  canUserDisconnectSlack = 'canUserDisconnectSlack',
  canUserDisconnectMsTeam = 'canUserDisconnectMsTeam',
  canUserReconfigureSlack = 'canUserReconfigureSlack',
  canUserDeleteCloudAccount = 'canUserDeleteCloudAccount',
  canUserDisconnectCloudAccount = 'canUserDisconnectCloudAccount',
  canUserEditAnotherUser = 'canUserEditAnotherUser',
  canUserEditNotifications = 'canUserEditNotifications',
  canUserEditOrganization = 'canUserEditOrganization',
  canUserViewUsersTable = 'canUserViewUsersTable',
  canUserViewGroupsTable = 'canUserViewGroupsTable',
  canUserViewDeactivatedUsersTable = 'canUserViewDeactivatedUsersTable',
  canUserActivateAnotherUser = 'canUserActivateAnotherUser',
  canUserDeleteAnotherUser = 'canUserDeleteAnotherUser',
  canUserEditAnotherUserGroups = 'canUserEditAnotherUserGroups',
  canUserAddGroup = 'canUserAddGroup',
  canUserDeleteGroup = 'canUserDeleteGroup',
  canUserViewGroup = 'canUserViewGroup',
  canUserEditGroup = 'canUserEditGroup',
  canUserRemoveUsersFromGroup = 'canUserRemoveUsersFromGroup',
  canUserAddAnotherUser = 'canUserAddAnotherUser',
  canUserDeactivateAnotherUser = 'canUserDeactivateAnotherUser',
  canUserViewAnotherUser = 'canUserViewAnotherUser',
  canUserEditAnotherUserPermissions = 'canUserEditAnotherUserPermissions',
  canUserRemoveOtherUsersFromGroup = 'canUserRemoveOtherUsersFromGroup',
  canUserResetOtherUsersPassword = 'canUserResetOtherUsersPassword'
}
