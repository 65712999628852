// External imports
import React from 'react';

// Internal exports
import Typography from '../../../common/typography/Typography';
import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';

// Interfaces
type THeadingTextProps = {
  text: string;
  color?: string;
  className?: string;
} & React.HtmlHTMLAttributes<HTMLParagraphElement>;

const HeadingText = ({ text, color = BLACK_COLORS[800], ...rest }: THeadingTextProps) => {
  return (
    <Typography variant="body-2" size="medium" as="p" style={{ color }} {...rest}>
      {text}
    </Typography>
  );
};

export default HeadingText;
