import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import Conditional from '../../components/common/conditionalComponent/Conditional';
import Tabs from '../../components/common/customTabs/CustomTabs';
import { TTabItem } from '../../components/common/customTabs/CustomTabs.interface';
import TooltipCustom from '../../components/common/molecules/tooltipCustom/TooltipCustom';
import Typography from '../../components/common/typography/Typography';
import ViewGroupPermissions from '../../components/teamManagement/Groups/viewGroup/ViewGroupPermissions';
import ViewGroupUsers from '../../components/teamManagement/Groups/viewGroup/ViewGroupUsers';
import '../../components/teamManagement/Groups/viewGroup/viewGroup.scss';
import { TEAM_MANAGEMENT_TEXT } from '../../configs/teamManagement';
import ENUMS from '../../enums';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import { prefetchTeamPemissions } from '../../services/prefechService/prefetchTeamsService';
import {
  setActiveTab,
  setHeading,
  updateSkeletonLoader,
  updateViewGrouptableType
} from '../../store/actions';
import { ICommonReducerInitialState } from '../../store/common/common.interface';
import useOrganizationStore from '../../store/orgnization/useOrgnizationStore';
import {
  ITeamManagementInitialValues,
  ITeamMangSelectedGroup,
  TTeamMangTablesType
} from '../../store/teamManagement/teamManagement.interface';
import { getSelectedGroup, textEllipsis } from '../../utils';
import convertToString from '../../utils/convertToString';
import { getDateFromString } from '../../utils/dateAndHourFormatting/dateAndHourFormatting';
import NotFound from '../not-found/NotFound';

const { userRoles } = ENUMS.teamManagement;

// Interfaces
interface IGroupDetails {
  label: string;
  value?: string | number;
}

const ViewGroup = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { groupname } = useParams() as unknown as { groupname: string };
  const { viewGrouptableType } = useAppSelector(
    (store) => store.teamManagement
  ) as ITeamManagementInitialValues;
  const { showSkeletonLoader } = useAppSelector<ICommonReducerInitialState>(
    (state) => state.common
  );

  const { organization: organizationData } = useOrganizationStore();

  const changeUserTab = (value: string) => {
    dispatch(updateViewGrouptableType(value as TTeamMangTablesType));
  };

  const userTabItems: TTabItem[] = [
    { key: 'groupusers', text: 'Users' },
    { key: 'permissions', text: 'Permissions' }
  ];

  const tableComponents = {
    groupusers: <ViewGroupUsers />,
    permissions: <ViewGroupPermissions />
  };

  // Get the group details
  const selectedGroup = getSelectedGroup() as unknown as ITeamMangSelectedGroup;

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT));
      dispatch(setActiveTab(5));
      prefetchTeamPemissions(queryClient, groupname);
    },
    onUnMountFunc: () => {
      changeUserTab('groupusers');
      dispatch(updateSkeletonLoader({ isLoading: true }));
    }
  });

  const groupDetails: IGroupDetails[] = [
    { label: TEAM_MANAGEMENT_TEXT.GROUPS, value: groupname },
    {
      label: TEAM_MANAGEMENT_TEXT.CREATED_BY,
      value: selectedGroup?.groupCreatedBy || userRoles.System
    },
    {
      label: TEAM_MANAGEMENT_TEXT.CREATED_ON,
      value: getDateFromString(
        selectedGroup.createdAt,
        organizationData.organizationAbbreviation,
        organizationData.organizationTimeZone
      )
    }
  ];

  return (
    <Conditional
      condition={groupname ? true : false}
      whenTrue={
        <>
          <div className="team-management">
            <div className="team-management__group-details">
              {groupDetails.map((item, index) => {
                const value: string = convertToString({ num: item.value, fallbackValue: 'NA' });

                return (
                  <PaperCustom
                    key={index}
                    variant="light"
                    className="team-management__group-details__item"
                  >
                    <Typography variant="body-3" size="medium" as="p" className="label">
                      {item.label}
                    </Typography>
                    <TooltipCustom text={value} conditionToDisplay={value?.length > 30}>
                      <Typography variant="body-3" size="medium" as="p" className="value">
                        {textEllipsis({ text: value, charLimit: 30 })}
                      </Typography>
                    </TooltipCustom>
                  </PaperCustom>
                );
              })}
            </div>
            <div className="team-management__tabs">
              <Tabs
                items={userTabItems}
                onChange={changeUserTab}
                activeItem={viewGrouptableType}
                isLoading={showSkeletonLoader}
              />
            </div>
            <div className="team-management__content">{tableComponents[viewGrouptableType]}</div>
          </div>
        </>
      }
      whenFalse={<NotFound />}
    />
  );
};

export default ViewGroup;
