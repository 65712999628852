import { IUserProfileData } from '../../pages/myProfile/myProfile.interface';

const profileHeadText = ({ userProfileData }: { userProfileData: IUserProfileData }) => {
  try {
    if (!userProfileData.firstName && !userProfileData.lastName) {
      throw new Error('first and last name not available');
    } else {
      return `${userProfileData.firstName.slice(0, 1).toUpperCase()}${userProfileData.lastName
        .slice(0, 1)
        .toUpperCase()}`;
    }
  } catch {
    return '';
  }
};

export default profileHeadText;
