import { colorConstants } from '../../../../configs/styleConstants';

interface CloseIconBorderProps {
  color?: string;
  height?: number;
  width?: number;
  strokeWidth?: number;
  containerProps?: React.HTMLAttributes<SVGElement>;
}

const CloseIconBorder = ({
  color = colorConstants.black,
  height = 13,
  width = 12,
  strokeWidth = 0.8,
  containerProps = {}
}: CloseIconBorderProps) => {
  return (
    <svg
      {...containerProps}
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M7.5 5L4.5 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M4.5 5L7.5 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default CloseIconBorder;
