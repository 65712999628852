import React from 'react';

import { ILoader } from './loader.interface';
import './loader.scss';

const Loader = ({ classes }: ILoader) => {
  return (
    <React.Fragment>
      <div className={`common-loader ${classes}`} role="status">
        <span>Loading...</span>
      </div>
    </React.Fragment>
  );
};

export default Loader;
