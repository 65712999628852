import type { AxiosResponse } from 'axios';

import authInterceptor from './authInterceptors';
import createInstance from './instance';
import methods from './methods';
import type {
  TConnectMsTeamsRequest,
  TConnectMsTeamsResponse,
  TDisconnectMsTeamsRequest,
  TDisconnectMsTeamsResponse,
  TViewMsTeamsRequest,
  TViewMsTeamsResponse
} from './types/msTeams.endpoints';

const MsTeamServiceInstance = createInstance({
  baseURL: process.env.REACT_APP_API_MSTEAMS_BASE_URL || ''
});

authInterceptor(MsTeamServiceInstance);
methods(MsTeamServiceInstance);

export async function connect(request: TConnectMsTeamsRequest): Promise<TConnectMsTeamsResponse> {
  const axiosResponse: AxiosResponse<TConnectMsTeamsResponse> = await MsTeamServiceInstance.post(
    '/accounts/teams-add-webhook',
    request
  );
  return axiosResponse?.data;
}

export async function disconnectMsTeams(
  disconnectMsTeamsRequest: TDisconnectMsTeamsRequest
): Promise<TDisconnectMsTeamsResponse> {
  const axiosResponse: AxiosResponse<TDisconnectMsTeamsResponse> = await MsTeamServiceInstance.post(
    '/accounts/teams-delete-webhook',
    disconnectMsTeamsRequest
  );
  return axiosResponse?.data;
}

export async function viewMsTeams(
  viewMsTeamsRequest: TViewMsTeamsRequest
): Promise<TViewMsTeamsResponse> {
  const axiosResponse: AxiosResponse<TViewMsTeamsResponse> = await MsTeamServiceInstance.get(
    '/accounts/teams-get-mapping',
    { params: viewMsTeamsRequest }
  );
  return axiosResponse?.data;
}
