import { ISelectDeslectCheckbox } from './selectDeslectCheckbox.interface';

const selectDeslectCheckbox = ({ arr, value }: ISelectDeslectCheckbox) => {
  try {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(value);
    }
    return arr;
  } catch {
    return arr;
  }
};

export default selectDeslectCheckbox;
