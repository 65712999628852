import * as Yup from 'yup';

import { ImageFileTypes } from '../../enums';

export const editProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed for the first name')
    .max(25, 'First Name should not exceed 25 characters')
    .required('Please enter the first name'),
  lastName: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed for the last name')
    .max(25, 'Last name should not be longer than 25 characters')
    .required('Please enter the last name'),
  jobTitle: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9 ]+$/, 'Only alphabets and numbers are allowed for job title')
    .min(2, 'The job title must be between 2 and 100 characters long')
    .max(100, 'The job title must be between 2 and 100 characters long')
    .required('Please enter the job title')
});

export const profileImageValidationSchema = Yup.object().shape({
  size: Yup.number().max(5120, 'Image size must be less than 5 MB'),
  width: Yup.number()
    .min(512, 'Image width must be greater than 512px')
    .max(1024, 'Image width must be less than 1024px'),
  height: Yup.number()
    .min(512, 'Image height must be greater than 512px')
    .max(1024, 'Image height must be less than 1024px'),
  type: Yup.mixed<ImageFileTypes>().oneOf(
    Object.values(ImageFileTypes),
    'Accepted image types: BMP, JPG, JPEG, PNG'
  )
});
