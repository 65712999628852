import React from 'react';
import { Modal } from '@mui/material';
// eslint-disable-next-line import/named
import { Form, Formik, FormikHelpers } from 'formik';

import './deleteGroupModal.scss';
import { closeIcon } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import { IDeleteGroupModal } from '../../../pages/teamManagement/teamManagement.types';
import Typography from '../../common/typography/Typography';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import InputWithBoldPlaceholder from '../../common/InputWithBoldPlaceholder/InputWithBoldPlaceholder';

type TInitialValues = { deleteText: string };

const DeleteGroupModal = ({ openModal, closeDeleteModal, handleDelete }: IDeleteGroupModal) => {
  const initialValues: TInitialValues = {
    deleteText: ''
  };

  const handleSubmit = async (_: TInitialValues, { resetForm }: FormikHelpers<TInitialValues>) => {
    resetForm();
    handleDelete();
  };

  const handleCancel = () => {
    closeDeleteModal();
  };

  const permissions = [
    'View Dashboard',
    'View Cloud Accounts',
    'View Insights',
    'View Notifications',
    'View FAQ',
    'Submit Feedback'
  ];

  return (
    <Modal open={openModal} data-testid="changePasswordModal" id="delete-group-modal">
      <div className="delete-group-modal">
        <div className="delete-group-modal__heading">
          <Typography variant="body-1" size="semiBold">
            {TEAM_MANAGEMENT_TEXT.DELETE_GROUP}
          </Typography>
          <img
            src={closeIcon}
            alt="close icon"
            data-testid="changePasswordCloseButton"
            onClick={handleCancel}
          />
        </div>
        <div className="delete-group-modal__content">
          <div className="delete-group-modal__content__description">
            <Typography variant="body-2" size="regular">
              Are you sure you want to delete this team? All the permissions assigned to the team
              will also be removed.
            </Typography>
            <Typography variant="body-2" size="regular">
              If a user is not in any team then default permissions for a user are
            </Typography>
          </div>
          <ol type="1">
            {permissions.map((permission, index) => (
              <li key={index} data-type="1">
                {permission}
              </li>
            ))}
          </ol>
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => {
            const readyToDelete: boolean = values?.deleteText === TEAM_MANAGEMENT_TEXT.DELETE_UPPER;

            const updateDeleteText = (value: string) => {
              setFieldValue('deleteText', value);
            };

            return (
              <Form className="delete-group-modal__form">
                <InputWithBoldPlaceholder
                  placeholder="Type DELETE to delete team"
                  boldText="DELETE"
                  value={values?.deleteText}
                  onChange={updateDeleteText}
                  className="delete-group-modal__input custom-input"
                />
                <div className="delete-group-modal__form__buttons">
                  <CustomButton
                    type="button"
                    onClick={handleCancel}
                    className="cancel"
                    text={BUTTON_TEXT.CANCEL}
                    variant={ButtonVariants.SECONDARY}
                  />
                  <CustomButton
                    type="submit"
                    className={`delete ${readyToDelete ? 'delete--is-active' : ''}`}
                    variant={ButtonVariants.RED}
                    disabled={!readyToDelete}
                    text={TEAM_MANAGEMENT_TEXT.YES_DELETE}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
