import { Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './forceChangePassword.scss';
import {
  IForceChangePasswordAPIResponse,
  IForceChangePasswordInitialValues
} from './forceChangePassword.types';
import {
  ForceChangePasswordValidationSchema,
  passwordTooltipValidationSchema
} from './forceChangePassword.validation';

import IMAGES from '../../assets';
import { confirmSuccess } from '../../assets/images';
import '../../assets/styles/index.scss';
import CustomButton from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import AuthFormContainer from '../../components/common/formContainer/AuthFormContainer';
import InputFieldTooltip from '../../components/common/inputFieldTooltip/InputFieldTooltip';
import TextInput from '../../components/common/textInput/TextInput';
import Typography from '../../components/common/typography/Typography';
import { BUTTON_TEXT, LABEL_TEXT } from '../../configs';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { routes } from '../../constants/routes';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import post from '../../services/post.service';
import usePasswordVisibility from '../../utils/usePasswordVisibility';

const ForceChangePassword = () => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const passwordTooltipRef = useRef<HTMLDivElement | null>(null);
  const [isPasswordSetSuccessModalOpen, setIsPasswordSetSuccessModalOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;

  const { toggleVisibility, visible, inputType } = usePasswordVisibility();
  const confirmPassword = usePasswordVisibility();

  const handlePasswordTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  const forceChangePasswordInitialValues: IForceChangePasswordInitialValues = {
    email: state?.email.trim(),
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = async ({ email, password }: IForceChangePasswordInitialValues) => {
    try {
      const payload = {
        organizationId: state?.organizationId,
        email: email,
        newPassword: password,
        sessionToken: state?.sessionToken,
        challengeName: state?.challengeName
      };
      const response = await post({ url: API_ENDPOINTS.FORCE_CHANGE_PASSWORD, payload });
      const data = response?.data as IForceChangePasswordAPIResponse;
      if (data) {
        setIsPasswordSetSuccessModalOpen(true);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'ForceChangePasswordFail'
      });
    }
  };

  const handleGoBack = () => navigate(routes.SIGN_IN);

  const handleModalClick = () => {
    setIsPasswordSetSuccessModalOpen(false);
    navigate(routes.SIGN_IN);
  };

  return (
    <AuthFormContainer
      heading="Set New Password"
      backToPrevScreenText="Sign In"
      handleBackToPrev={handleGoBack}
    >
      <Formik
        initialValues={forceChangePasswordInitialValues}
        validationSchema={ForceChangePasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, values }) => {
          return (
            <Form className="forceChangePassword">
              {/* email field  */}
              <TextInput
                label={LABEL_TEXT.EMAIL}
                name="email"
                type="text"
                placeholder="Enter an Email"
                errors={errors}
                touched={touched}
                disabled={isSubmitting || state?.email}
                data-testid="email"
                required={true}
              />

              {/* password field */}
              <div className="forceChangePassword__field ">
                {showTooltip && (
                  <InputFieldTooltip
                    formValues={{ password: values.password }}
                    field="password"
                    schema={passwordTooltipValidationSchema}
                    title="Password must contain"
                  />
                )}
                <TextInput
                  placeholder="Enter New Password"
                  label={LABEL_TEXT.NEW_PASSWORD}
                  name="password"
                  type={inputType}
                  errors={errors}
                  touched={touched}
                  required={true}
                  disabled={isSubmitting}
                  inputClassName={`${isSubmitting ? 'cursor-not-allowed' : ''} input_fields `}
                  divClassName="mt-1"
                  onFocus={() => setShowTooltip(true)}
                  onBlur={() => {
                    setShowTooltip(false);
                  }}
                />
                {/* pasword eye icon  */}
                <Tooltip
                  placement="bottom"
                  title={visible ? 'Hide Password' : 'Show Password'}
                  arrow
                >
                  <div className="forceChangePassword__field__eyeIcon ">
                    <img
                      src={visible ? IMAGES.VisibilityOnEye : IMAGES.VisibilityOffEye}
                      onClick={toggleVisibility}
                      alt={visible ? 'Show Password' : 'Hide Password'}
                      data-testid="password-eye-icon"
                      className=""
                    />
                  </div>
                </Tooltip>

                {/* password requirement tooltip for mobile only */}
                <div
                  className="info_icon flex sm:hidden"
                  onClick={handlePasswordTooltip}
                  ref={passwordTooltipRef}
                >
                  <div className="info_circle border-primary text-primary">i</div>
                </div>
                {/* password error msg  */}
                <Typography
                  variant="body-3"
                  size="regular"
                  as="div"
                  className={`common-text-input__error  ${
                    errors.password && touched.password ? 'visible' : 'invisible'
                  }`}
                ></Typography>
              </div>

              {/* confirm password field  */}
              <div className="forceChangePassword__field">
                <TextInput
                  placeholder="Confirm Password"
                  label={LABEL_TEXT.CONFIRM_PASSWORD}
                  name="confirmPassword"
                  inputClassName={`${isSubmitting ? 'cursor-not-allowed' : ''} input_fields `}
                  divClassName="mt-1"
                  type={confirmPassword.inputType}
                  errors={errors}
                  touched={touched}
                  required={true}
                />

                {/* confirm pasword eye icon  */}

                <Tooltip
                  placement="bottom"
                  title={confirmPassword.visible ? 'Hide Password' : 'Show Password'}
                  arrow
                >
                  <div className="forceChangePassword__field__eyeIcon ">
                    <img
                      src={
                        confirmPassword.visible ? IMAGES.VisibilityOnEye : IMAGES.VisibilityOffEye
                      }
                      onClick={confirmPassword.toggleVisibility}
                      alt={confirmPassword.visible ? 'Show Password' : 'Hide Password'}
                      data-testid="confirm-password-eye-icon"
                    />
                  </div>
                </Tooltip>

                {/* confirm password error msg  */}
                <Typography
                  variant="body-3"
                  size="regular"
                  as="div"
                  className={`common-text-input__error  ${
                    errors.confirmPassword && touched.confirmPassword ? 'visible' : 'invisible'
                  }`}
                ></Typography>
              </div>

              {/* form data submit button  */}
              <CustomButton
                text={BUTTON_TEXT.CONTINUE}
                type="submit"
                isLoading={isSubmitting}
                className="w-full"
              />
            </Form>
          );
        }}
      </Formik>
      {isPasswordSetSuccessModalOpen && (
        <ConfirmModal
          open={true}
          title="Password Set!"
          description="Your new password has been set successfully! Please sign in."
          descriptionWidth="88%"
          image={confirmSuccess}
          handleClose={handleModalClick}
          handleOkClick={handleModalClick}
          confirmButtonText="OKAY"
        />
      )}
    </AuthFormContainer>
  );
};

export default ForceChangePassword;
