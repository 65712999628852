// External imports
import { HTMLAttributes } from 'react';
import classNames from 'classnames';

// Internal imports
import './goBack.scss';

import { BUTTON_TEXT } from '../../../../configs';
import ArrowShaftIcon from '../../../common/icons/arrowShaftIcon/ArrowShaftIcon';
import Typography from '../../../common/typography/Typography';

// Types
type TGoBack = {
  handleGoBack: () => void;
} & HTMLAttributes<HTMLDivElement>;

const GoBack = ({ handleGoBack, className, ...rest }: TGoBack) => {
  return (
    <div className={classNames('tm-common-go-back', className)} onClick={handleGoBack} {...rest}>
      <ArrowShaftIcon direction="left" />
      <Typography variant="caption" size="semiBold">
        {BUTTON_TEXT.BACK}
      </Typography>
    </div>
  );
};

export default GoBack;
