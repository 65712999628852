// External imports
import classNames from 'classnames';

// Internal imports
import './addUserToGroup.scss';

import {
  IAddUserToGroup,
  ITeamMangUser
} from '../../../../store/teamManagement/teamManagement.interface';
import { textEllipsis } from '../../../../utils';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import Typography from '../../../common/typography/Typography';

export interface IAddUserToGroupProps {
  user: IAddUserToGroup;
  addToSelectedUsers: (user: string) => void;
  index: number;
  selectedUsers: IAddUserToGroup[];
  currentUsers?: ITeamMangUser[];
  tooltipCharLimit?: number;
}

export const AddUserToGroup = ({
  user,
  addToSelectedUsers,
  selectedUsers,
  currentUsers,
  tooltipCharLimit = 35
}: IAddUserToGroupProps) => {
  const handleSelectClick = (user: string) => {
    addToSelectedUsers(user);
  };

  const checkIfSelected = selectedUsers.map((user) => user.email).includes(user.email);

  const currentUsersEmails: string[] = currentUsers ? currentUsers.map((item) => item.email) : [];

  const isUserAlreadyInTheGroup: boolean = currentUsersEmails.indexOf(user.email) > -1;

  return (
    <TooltipCustom
      text={
        <div className="user-tooltip">
          <p style={{ textAlign: 'left' }}>{user.name}</p>
          <p style={{ textAlign: 'left' }}>{user.email}</p>
        </div>
      }
      limitWidth={false}
      conditionToDisplay={
        user.name?.length > tooltipCharLimit || user.email?.length > tooltipCharLimit
      }
      containerProps={{
        className: classNames('tm-add-group-add-users-to-group user_list apply-loader', {
          'tm-add-group-add-users-to-group--is-selected': checkIfSelected
        })
      }}
    >
      <div className="tm-add-group-add-users-to-group__user">
        <Typography variant="body-3" size="regular" as="p" className="name">
          {textEllipsis({ text: user.name, charLimit: tooltipCharLimit })}
        </Typography>
        <Typography variant="caption" size="regular" as="p" className="email">
          {textEllipsis({ text: user.email, charLimit: tooltipCharLimit })}
        </Typography>
      </div>
      <input
        checked={checkIfSelected || isUserAlreadyInTheGroup}
        type="checkbox"
        id={user.email}
        readOnly
        onClick={() => handleSelectClick(user.name)}
        disabled={isUserAlreadyInTheGroup}
      />
    </TooltipCustom>
  );
};
