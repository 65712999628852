import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ITabs, TTabItem } from './CustomTabs.interface';
import './CustomTabs.scss';

const CustomTabs: React.FC<ITabs> = ({
  items,
  onChange,
  activeItem,
  isLoading,
  containerProps,
  onMouseEnter
}) => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const activeIndex: number = items.findIndex((item: TTabItem) => item.key === activeItem);
    setValue(activeIndex);
  }, [activeItem, items]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const newActiveItem: string = items[newValue].key;
    setValue(newValue);
    onChange(newActiveItem);
  };

  return (
    <Box {...containerProps} sx={{ width: '100%' }}>
      <Box className={`tabs-container ${isLoading ? 'tabs-container--is-loading' : ''}`}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: { display: isLoading ? 'none' : 'block' }
          }}
        >
          {items.map((item: TTabItem, index) => (
            <Tab
              key={item.key}
              label={item.text}
              {...a11yProps(index)}
              disabled={item.disabled || isLoading}
              onMouseEnter={() => onMouseEnter && onMouseEnter(item.key)}
              className={`tab_items
              ${activeItem === item.key ? 'tab_items_active' : ''}
              ${item.disabled ? 'tab_items_disabled' : ''}
             ${isLoading ? 'apply-loader' : ''}`}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default CustomTabs;
