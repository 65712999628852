import { Link } from 'react-router-dom';

import './footer.scss';
import { routes } from '../../../constants/routes';
import Typography from '../typography/Typography';

const Footer = () => {
  return (
    <div className="footer">
      <Typography variant="caption" size="medium" className="footer__item">
        <Link to={routes.TERMS_OF_USE} className="footer__item__link" target="_blank">
          Terms of Use
        </Link>
      </Typography>
      <Typography variant="caption" size="medium" className="text-black-700">
        &nbsp;&nbsp;&#124;&nbsp;&nbsp;
      </Typography>
      <Typography variant="caption" size="medium" className="footer__item">
        <Link to={routes.PRIVACY_POLICY} className="footer__item__link" target="_blank">
          Privacy Policy
        </Link>
      </Typography>
    </div>
  );
};

export default Footer;
