/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { Field, useField } from 'formik';
import { twMerge } from 'tailwind-merge';

import './textInput.scss';

import TooltipCustom from '../molecules/tooltipCustom/TooltipCustom';
import Typography from '../typography/Typography';

export interface ITextInputProps {
  placeholder: string;
  label: string;
  name: string;
  type: string;
  errors: any;
  touched: any;
  required: boolean;
  disabled?: boolean;
  divClassName?: string;
  inputClassName?: string;
  isFieldValid?: (value: string) => boolean;
  currency?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  labelRequired?: boolean;
  maxLength?: number;
  tooltipText?: string;
  additionalLabel?: string;
  onChangeInput?: (value: string) => void;
}

const TextInput = (props: ITextInputProps) => {
  const [field, , helpers] = useField(props);
  const {
    placeholder,
    maxLength,
    name,
    label,
    touched,
    errors,
    divClassName,
    disabled,
    required,
    type,
    inputClassName,
    isFieldValid,
    currency,
    onFocus,
    onBlur,
    labelRequired = true,
    tooltipText,
    onChangeInput,
    additionalLabel
  } = props;

  const handleChange = (e: any) => {
    //this will set touched to false so no error msg will appear when a user is typing
    helpers.setTouched(false);
    if ((isFieldValid && isFieldValid(e.target.value)) || !isFieldValid) {
      helpers.setValue(e.target.value);
      onChangeInput && onChangeInput(e.target.value);
    }
  };
  const labelClassName = classNames('common-text-input__label ', { label_required: required });

  return (
    <div className={twMerge('common-text-input', divClassName)}>
      <div className="container_input_tooltip">
        {labelRequired && (
          <Typography variant="body-3" size="medium" as="label" className={labelClassName}>
            {label}
            {additionalLabel && <span className="additional-label">{additionalLabel}</span>}
          </Typography>
        )}
        {/* Render tooltip icon if tooltipText is provided */}
        {tooltipText && (
          <div className="tooltip-icon">
            <TooltipCustom text={tooltipText}>
              <div className="info_circle border-primary text-primary cursor-pointer ">i</div>
            </TooltipCustom>
          </div>
        )}
      </div>
      <div className="common-text-input__input-field">
        <Field
          {...field}
          id={name}
          onChange={handleChange}
          className={twMerge(
            `${disabled ? 'cursor-not-allowed' : ''} input_fields
            common-text-input__input-field__input ${
              currency ? 'common-text-input__input-field__input--is-currency' : ''
            }`,
            inputClassName
          )}
          placeholder={placeholder}
          disabled={disabled}
          data-testid={name}
          type={type}
          data-currency={currency}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
        />
        {currency ? (
          <div className="common-text-input__input-field__currency">
            <p>{currency}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Typography
        variant="body-3"
        size="regular"
        as="div"
        className={`common-text-input__error  ${
          errors[name] && touched[name] ? 'visible' : 'invisible'
        }`}
      >
        {errors[name] && touched[name] ? errors[name] : 'Enter your email'}
      </Typography>
    </div>
  );
};

export default TextInput;
