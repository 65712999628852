import axios from 'axios';

interface IGet {
  url: string;
}

const getAPIBaseUrl = (): string | undefined => {
  return process.env.REACT_APP_API_BASE_URL;
};

const generateURL = (url: string) => {
  const API_URL = getAPIBaseUrl();
  return API_URL + url;
};

const get = ({ url }: IGet) => {
  // This is created as a seperate file because it will contain headers, pass tokens etc.
  const URL = generateURL(url);
  return axios
    .get(URL)
    .then((response) => response)
    .catch((error: unknown) => {
      throw error;
    });
};

export default get;
