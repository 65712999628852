import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  accountName: Yup.string()
    .trim()
    .required('Please enter an account name')
    .matches(
      /^[a-zA-Z_ ]+$/,
      'Only underscores “_” are allowed as special characters in the account name'
    )
    .min(2, 'The account name must be between 2 and 100 characters long')
    .max(100, 'The account name must be between 2 and 100 characters long'),
  tenantId: Yup.string()
    .trim()
    .required('Please enter your tenant ID')
    .matches(
      /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/,
      'The format of the tenant ID is incorrect. Please use the format: "00000000-0000-0000-0000-000000000000"'
    )
    .max(36, 'The tenant ID must be 36 characters long'),
  subscriptionKey: Yup.string()
    .trim()
    .required('Please enter your subscription ID')
    .matches(
      /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/,
      'The format of the subscription ID is incorrect. Please use the format: "00000000-0000-0000-0000-000000000000"'
    )
    .max(36, 'The subscription ID must be 36 characters long'),
  clientId: Yup.string()
    .trim()
    .required('Please enter your application ID')
    .matches(
      /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/,
      'The format of the application ID is incorrect. Please use the format: "00000000-0000-0000-0000-000000000000"'
    )
    .max(36, 'The application ID must be 36 characters long'),
  clientSecret: Yup.string().trim().required('Please enter your client secret value'),

  budget: Yup.number()
    .test('is-greater-than-zero', 'The budget should be a minimum of $1', (value) => value !== 0)
    .min(1, 'The budget should be a minimum of $1')
    .required('Please enter a budget value')
});

export default validationSchema;
