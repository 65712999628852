import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import disableGroupUserRowCheckboxCalc from './disableGroupUserRowCheckboxCalc';
import './viewGroup.scss';

import IMAGES from '../../../../assets';
import { confirmSuccess } from '../../../../assets/images';
import { BUTTON_TEXT } from '../../../../configs';
import { TEAM_ADD_USER } from '../../../../configs/cloudAccounts';
import { errorToastStyle } from '../../../../configs/styleConstants';
import {
  TEAM_MANAGEMENT_TEXT,
  TEAM_MANAGEMENT_TOOLTIP_MESSAGES
} from '../../../../configs/teamManagement';
import { ERRORS as ERRORS_COLORS, PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { RowsPerPage } from '../../../../constants/constants';
import { routes } from '../../../../constants/routes';
import ENUMS from '../../../../enums';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useCalculateElementHeight from '../../../../hooks/common/calculateElementHeight/useCalculateElementHeight';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useFetchMyProfile from '../../../../hooks/myProfile/fetchMyProfile/useFetchMyProfile';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import postWithAuth from '../../../../services/postWithAuth';
import { prefetchTeamwiseUsers } from '../../../../services/prefechService/prefetchTeamsService';
import { fetchTeamUsers } from '../../../../services/teamManagement/teamManagement';
import { TGetTeamUsersResponse } from '../../../../services/types/teamManagement.endpoints';
import { setAddUsersToGroupList, updateAddUsersToGroupList } from '../../../../store/actions';
import { IMyProfileState } from '../../../../store/myProfile/myProfile.interface';
import {
  ITeamManagementInitialValues,
  ITeamMangSelectedGroup,
  ITeamMangUser
} from '../../../../store/teamManagement/teamManagement.interface';
import { getSelectedGroup, selectDeslectCheckbox, sortObjectArray } from '../../../../utils';
import { TSortType } from '../../../../utils/sortObjectArray/sortObjectArray.interface';
import TextTooltipWithEllipsis from '../../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import CloseButton from '../../../common/close-button/CloseButton';
import CustomTable from '../../../common/commonTable/CustomTable';
import { ITableColumns } from '../../../common/commonTable/customTable.interface';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import CrossChevronIcon from '../../../common/icons/crossChevronIcon/CrossChevronIcon';
import EditPenIcon from '../../../common/icons/editPenIcon/EditPenIcon';
import Loader from '../../../common/loader/loader';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal from '../../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import Typography from '../../../common/typography/Typography';
import NoData from '../NoData';
import AddUsersInGroupModal from '../addGroup/AddUsersInGroupModal';
import '../groups.scss';

const rowsPerPageOptions = RowsPerPage;
const { userRoles, userGroups } = ENUMS.teamManagement;

const ViewGroupUsers = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [isAddUsersInGroupModalOpen, setIsAddUsersInGroupModalOpen] = useState<boolean>(false);
  const [isUsersAddSuccessModalOpen, setIsUsersAddSuccessModalOpen] = useState<boolean>(false);
  const [disableSelectAll, setDisableSelectAll] = useState<boolean>(false);
  const [isRowSelectedForDeletion, setIsRowSelectedForDeletion] = useState<boolean>(false);
  const tableHeightRef = useRef<HTMLDivElement>(null);

  const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);

  const selectedGroup = getSelectedGroup() as unknown as ITeamMangSelectedGroup;

  const { selectedUserToAddGroupsList } = useAppSelector(
    (store) => store.teamManagement
  ) as ITeamManagementInitialValues;

  const { isOwner: isloggedInUserOwner, groups } = useAppSelector<IMyProfileState>(
    (store) => store.MyProfile
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { refetch: refetchProfile } = useFetchMyProfile();
  const { groupname } = useParams() as unknown as { groupname: string };

  // User is considered owner, if they have isOwner flag
  const isloggedInUserAdmin: boolean = (groups || []).indexOf(userGroups.Admin) > -1;

  // USER PERMISSIONS START
  const { canUserEditGroup, canUserRemoveOtherUsersFromGroup } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const {
    isLoading,
    data: teamUsers,
    refetch: refetchTeamUsers
  } = useQuery<TGetTeamUsersResponse>(
    ['viewTeamUsers', { groupname, rows: rowsPerPage, page: pageNumber }],
    () => fetchTeamUsers(groupname, rowsPerPage, pageNumber),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = JSON.parse(JSON.stringify(teamUsers?.users || []))
      .slice(0, rowsPerPage)
      .map((item: Record<string, string | string[] | number>[], index: number) => {
        return { ...item, itemNo: startIndex + index + 1 };
      });

    const newData = sortObjectArray({
      arr: totalItems,
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];
    return newData;
  }, [dataSort, teamUsers?.users, pageNumber, rowsPerPage]);

  const onselection = (value: string) => {
    if (setSelectedRows) {
      const newArray = selectDeslectCheckbox({ arr: selectedRows, value }) as string[];
      setSelectedRows(() => [...newArray]);
    }
  };

  const selectAllRows = () => {
    const allRowIds = sortedData
      .filter((rowf) => rowf.groupName !== userRoles.Admin)
      .map((row) => row.email) as (string | number)[];

    if (setSelectedRows) {
      if (selectedRows.length === allRowIds.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(allRowIds);
      }
    }
  };

  const selectableUsers = sortedData.filter((user) => user.groupName !== userRoles.Admin);

  const goToEditGroup = () => {
    navigate(`${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${groupname}/edit`);
  };

  const toggleAddUsersInGroupModal = () => setIsAddUsersInGroupModalOpen((prev) => !prev);

  const handleAddUsersInGroup = async () => {
    try {
      setIsLoadingStart(true);
      const usersEmailsList = selectedUserToAddGroupsList.map((user) => user.email);

      if (!usersEmailsList.length) {
        toast.error(TEAM_ADD_USER.PLEASE_SELECT_AT_LEAST_ONE_USER_TO_ADD_TO_THE_TEAM, {
          style: errorToastStyle,
          closeButton: <CloseButton color={errorToastStyle?.color as string} />
        });
        return;
      }

      const payload = {
        emails: usersEmailsList,
        groupName: groupname
      };
      const response = await postWithAuth({ url: API_ENDPOINTS.ADD_USERS_TO_GROUP, payload });
      if (response) {
        setPageNumber(1);
        toggleAddUsersInGroupModal();
        setIsUsersAddSuccessModalOpen(true);
        refetchTeamUsers();
        dispatch(updateAddUsersToGroupList([]));
        dispatch(setAddUsersToGroupList([]));
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'AddUsersInGroupFail'
      });
    } finally {
      setIsLoadingStart(false);
    }
  };

  const { elementHeight } = useCalculateElementHeight({
    ref: tableHeightRef,
    triggers: [sortedData.length],
    // gap between bottom of table and bottom of screen as per figma
    heightOffest: 50
  });

  const closeUserDeletionModal = () => {
    setOpenRemoveModal(false);
    if (isRowSelectedForDeletion) {
      setSelectedRows([]);
      setIsRowSelectedForDeletion(false);
    }
  };

  const tableColumns: ITableColumns[] = [
    {
      key: 1,
      title: '',
      dataIndex: 'email',
      headElement: () => (
        <input
          type="checkbox"
          checked={selectedRows.length === selectableUsers.length && selectedRows.length > 0}
          className="checkbox select-all-checkbox"
          onChange={selectAllRows}
          disabled={disableSelectAll}
        />
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        const user = args[1] as unknown as ITeamMangUser;

        const { disabled, tooltipText } = disableGroupUserRowCheckboxCalc({
          userData: user,
          groupname,
          isloggedInUserOwner,
          isloggedInUserAdmin,
          canUserRemoveOtherUsersFromGroup
        });

        setDisableSelectAll(
          // Disabling select all checkbox, if any one of the row has to be disabled.
          // Default value is false, it can only be changed to true.
          (prev) => (disabled ? true : prev)
        );
        return (
          <TooltipCustom text={tooltipText} conditionToDisplay={disabled}>
            <input
              type="checkbox"
              onChange={() => onselection(email)}
              className="checkbox"
              checked={selectedRows.includes(email)}
              disabled={disabled}
            />
          </TooltipCustom>
        );
      }
    },
    {
      key: 3,
      title: TEAM_MANAGEMENT_TEXT.GROUP_NAME,
      dataIndex: 'firstName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.FIRST_NAME}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const firstName = args[0] as string;
        return (
          <span className="first-name">
            <TextTooltipWithEllipsis limit={12} text={firstName} />
          </span>
        );
      }
    },
    {
      key: 4,
      title: TEAM_MANAGEMENT_TEXT.LAST_NAME,
      dataIndex: 'lastName',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.LAST_NAME}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const lastName = args[0] as string;
        return (
          <span className="last-name">
            <TextTooltipWithEllipsis limit={12} text={lastName} />
          </span>
        );
      }
    },
    {
      key: 5,
      title: TEAM_MANAGEMENT_TEXT.EMAIL,
      dataIndex: 'email',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>{TEAM_MANAGEMENT_TEXT.EMAIL}</span>
        </div>
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        return <TextTooltipWithEllipsis limit={30} text={email} />;
      }
    },
    {
      key: 6,
      title: 'Action',
      dataIndex: 'email',
      headElement: () => (
        <div className="cell-inner view-group-users-action-head">
          <Typography variant="body-3" size="medium" as="span">
            Action
          </Typography>
        </div>
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        const userData = args[1] as unknown as ITeamMangUser;
        const { disabled: hideDeleteButton } = disableGroupUserRowCheckboxCalc({
          userData,
          groupname,
          isloggedInUserOwner,
          isloggedInUserAdmin,
          canUserRemoveOtherUsersFromGroup
        });

        return (
          <div
            className="action delete-group"
            onClick={() => {
              setSelectedRows([email]);
              setIsRowSelectedForDeletion(true);
              setOpenRemoveModal(true);
            }}
          >
            {hideDeleteButton ? '-' : <CrossChevronIcon color={ERRORS_COLORS[700]} />}
          </div>
        );
      }
    }
  ];

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.TEAM_MANAGEMENT, url: routes.TEAM_MANAGEMENT_USERS_TABLE },
      {
        text: TEAM_MANAGEMENT_TEXT.GROUPS,
        url: routes.TEAM_MANAGEMENT_GROUPS_TABLE
      },
      {
        text: selectedGroup?.groupName,
        url: ''
      }
    ]
  });

  const handleRemoveSelectedUsers = async () => {
    try {
      setIsLoadingStart(true);
      const payload = {
        emails: selectedRows,
        groupName: groupname
      };

      await postWithAuth({
        url: API_ENDPOINTS.REMOVE_USERS_FROM_GROUP,
        payload: payload
      });
      setPageNumber(1);
      setOpenRemoveModal(false);
      setOpenConfirmDeleteModal(true);
      setSelectedRows([]);
      refetchTeamUsers();
      refetchProfile(); // to refetch the user groups, useful when user removes themselves from the group, they need to be navigated outside.
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'RemoveUserFromGroupFail'
      });
    } finally {
      setIsLoadingStart(false);
    }
  };

  const showEditGroupButton = groupname !== userGroups.Admin && selectedRows?.length === 0;

  useEffect(() => {
    if (teamUsers?.count && pageNumber < teamUsers?.count / rowsPerPage) {
      const nextPage = pageNumber + 1;
      prefetchTeamwiseUsers(queryClient, {
        groupName: '',
        page: nextPage,
        rows: rowsPerPage
      });
    }
  }, [pageNumber, rowsPerPage, queryClient, teamUsers?.count]);

  return (
    <div className="team-management-table view-group-users">
      <>
        <div className="view-group-users__buttons-row">
          {/* Placeholder */}
          <div className="view-group-users__buttons-row__search"></div>
          <div className="view-group-users__buttons-row__buttons">
            <div className="view-group-users__buttons-row__buttons__button-row">
              {showEditGroupButton && (
                <TooltipCustom
                  text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_EDIT_GROUP_ACCESS}
                  conditionToDisplay={!canUserEditGroup}
                >
                  <button
                    type="button"
                    className="edit-group"
                    onClick={goToEditGroup}
                    disabled={!canUserEditGroup}
                  >
                    <EditPenIcon color={PRIMARY_COLORS[600]} />
                    <Typography variant="body-3" size="medium" as="span">
                      {TEAM_MANAGEMENT_TEXT.EDIT_GROUP_DETAILS}
                    </Typography>
                  </button>
                </TooltipCustom>
              )}
              <TooltipCustom
                text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_EDIT_GROUP_ACCESS}
                conditionToDisplay={!canUserEditGroup}
              >
                <button
                  onClick={toggleAddUsersInGroupModal}
                  type="button"
                  className="add-in-group"
                  disabled={!canUserEditGroup || selectedRows?.length > 0}
                >
                  <img src={IMAGES.addorPlusIcon} alt="add or plus button icon" />
                  <Typography variant="body-3" size="medium">
                    {TEAM_MANAGEMENT_TEXT.ADD_USER}
                  </Typography>
                </button>
              </TooltipCustom>
            </div>
            {selectedRows.length > 0 && (
              <button
                type="button"
                className="delete-group"
                onClick={() => setOpenRemoveModal(true)}
              >
                <CrossChevronIcon color={ERRORS_COLORS[800]} />
                <Typography variant="body-3" size="medium" as="span">
                  {TEAM_MANAGEMENT_TEXT.REMOVE_FROM_GROUP}
                </Typography>
              </button>
            )}
          </div>
        </div>
        <div
          ref={tableHeightRef}
          className={`team-management-table__table view-group-users__table ${
            selectedRows.length > 0 ? 'view-group-users__table--is-selected' : ''
          }`}
          style={{ height: elementHeight ? `${elementHeight}px` : 'auto' }}
        >
          <CustomTable
            tableColumns={tableColumns}
            tableData={sortedData}
            totalItems={teamUsers?.count || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            rowsPerPageOptions={rowsPerPageOptions}
            isLoading={isLoading}
            rowIdKey="email"
            selectedRows={selectedRows}
            sortState={dataSort}
            setSortState={setDataSort}
            noData={<NoData addInGroup={toggleAddUsersInGroupModal} />}
          />
        </div>
        <ConfirmModal
          open={openConfirmDeleteModal}
          setOpen={setOpenConfirmDeleteModal}
          title={TEAM_MANAGEMENT_TEXT.USER_REMOVED}
          description={TEAM_MANAGEMENT_TEXT.USER_REMOVE_SUCCESS}
          descriptionWidth="88%"
          image={confirmSuccess}
          confirmButtonText={BUTTON_TEXT.OKAY}
        />
        <RestoreArchiveUserModal
          icon={IMAGES.ErrorExclamation}
          heading={TEAM_MANAGEMENT_TEXT.REMOVE_USERS}
          open={openRemoveModal}
          description={TEAM_MANAGEMENT_TEXT.REMOVE_USER_DESCRIPTION}
          handleClose={closeUserDeletionModal}
          handlerFun={handleRemoveSelectedUsers}
          actionButtonText={isLoadingStart ? <Loader /> : BUTTON_TEXT.YES_REMOVE}
        />

        {/* Add in Group Modal */}
        {isAddUsersInGroupModalOpen && (
          <AddUsersInGroupModal
            open={isAddUsersInGroupModalOpen}
            closeModal={toggleAddUsersInGroupModal}
            handleAdd={handleAddUsersInGroup}
            currentUsers={sortedData as unknown as ITeamMangUser[]}
            isLoadingStart={isLoadingStart}
          />
        )}

        {/* Add in Group Success Message */}
        <ConfirmModal
          open={isUsersAddSuccessModalOpen}
          setOpen={setIsUsersAddSuccessModalOpen}
          title={TEAM_MANAGEMENT_TEXT.USER_ADDED}
          description={TEAM_MANAGEMENT_TEXT.ADD_USERS_IN_GROUP_SUCCESS}
          image={confirmSuccess}
          confirmButtonText={BUTTON_TEXT.OKAY}
        />
      </>
    </div>
  );
};

export default ViewGroupUsers;
