import { create } from 'zustand';

import {
  TRegisterDeviceRequest,
  TRegisterDeviceResponse
} from '../../services/types/registerDevice.endpoints';
import { NotificationService } from '../../services/notification.service';
import {
  TDeRegisterDeviceRequest,
  TDeRegisterDeviceResponse
} from '../../services/types/deRegisterDevice.endpoints';

interface INotificationStore {
  deviceId: string;
  registerDevice: (request: TRegisterDeviceRequest) => void;
  deRegisterDevice: (request: TDeRegisterDeviceRequest) => void;
  resetDeviceId: () => void;
}

const useNotificationStore = create<INotificationStore>()((set) => ({
  deviceId: '',
  registerDevice: async (request: TRegisterDeviceRequest) => {
    try {
      const response: TRegisterDeviceResponse = await new NotificationService().registerDevice(
        request
      );

      if (response) {
        console.log(22, response);
      }

      // set(() => ({
      //   deviceId
      // }))
    } catch (err) {
      console.log(err);
    }
  },
  deRegisterDevice: async (request: TDeRegisterDeviceRequest) => {
    try {
      const response: TDeRegisterDeviceResponse = await new NotificationService().deRegisterDevice(
        request
      );

      if (response) {
        console.log(22, response);
      }

      // set(() => ({
      //   deviceId
      // }))
    } catch (err) {
      console.log(err);
    }
  },
  resetDeviceId: () => set(() => ({ deviceId: '' }))
}));

export default useNotificationStore;
