import React, { useCallback } from 'react';
import classNames from 'classnames';

import './fileUploadInput.css';
import cloudIcon from '../../../assets/images/svg/drag_drop_icon.svg';

interface FileInputProps {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  accept?: string;
}

interface FileUploadInputProps {
  onChange: (files: FileList | null) => void;
  divClassName?: string;
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({ onChange, divClassName }) => {
  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.files);
      event.target.value = '';
    },
    [onChange]
  );

  const fileInputProps: FileInputProps = {
    id: 'file-upload',
    onChange: handleFileInputChange,
    multiple: true,
    accept: '.png, .jpg, .jpeg'
  };

  return (
    <div className={classNames('file-upload-input', divClassName)}>
      <input {...fileInputProps} type="file" style={{ display: 'none' }} />
      <label
        htmlFor="file-upload"
        className="file-input-label"
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          onChange(e.dataTransfer.files);
        }}
      >
        <div className="file-input-icon">
          <img src={cloudIcon} alt="File Icon" className="cloud-icon-left" />
        </div>
        <span className="file-input-text">
          Drag and drop the file &nbsp; or &nbsp;
          <span>Browse on your device</span>
        </span>
        <span className="file-input-text-mobile">
          <span>Tap to choose a file from your device</span>
        </span>
      </label>
    </div>
  );
};

export default FileUploadInput;
