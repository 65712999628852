import { useState } from 'react';
import { useNavigate } from 'react-router';

import './account.scss';
import AccountDeleteModal from './AccountDeleteModal';

import IMAGES from '../../../assets';
import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { PRIMARY as PRIMARY_COLORS } from '../../../configs/v3.colors';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { routes } from '../../../constants/routes';
import { deleteAccountSteps } from '../../../constants/tabsText';
import { getOrgData } from '../../../helpers/getOrgData';
import useComponentMount from '../../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import useSignOut from '../../../hooks/sign-out/useSignOut';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import postWithAuth from '../../../services/postWithAuth';
import { updateSkeletonLoader } from '../../../store/actions';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import CloudInSpotName from '../../cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import UserFeedback from '../../common/molecules/userFeedback/UserFeedback';
import Ruler from '../../common/ruler/Ruler';
import Typography from '../../common/typography/Typography';

const Account = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteAccountSuccessModalOpen, setIsDeleteAccountSuccessModalOpen] =
    useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [feedBackModalOpen, setFeedBackModalOpen] = useState<boolean>(true);
  const [feedbackSuccessModal, setFeedbackSuccessModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleDeleteState = () => setIsDeleteModalOpen((prev) => !prev);

  const isOwner: boolean = useAppSelector<IMyProfileState>((store) => store.MyProfile).isOwner;

  const { orgID, orgName } = getOrgData();

  const { handleClearUserData } = useSignOut();

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await postWithAuth({ url: API_ENDPOINTS.DELETE_ORGANIZATION, payload: {} });
      if (response) {
        setIsDeleteAccountSuccessModalOpen(true);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setWarningModalOpen(true);
        return;
      }
      useErrorHandler({
        error,
        toastId: 'AccountDeleteFail'
      });
    } finally {
      setIsDeleteModalOpen(false);
      setIsLoading(false);
    }
  };

  const handleFeedbackSubmit = () => {
    setFeedBackModalOpen(false);
    setFeedbackSuccessModal(true);
  };

  useComponentMount({
    onMountFunc: () => {
      dispatch(updateSkeletonLoader({ isLoading: false }));
    }
  });

  useUpdateBreadcrumbs({
    breadcrumbs: [
      {
        text: 'Settings',
        url: routes.SETTINGS_ORGANIZATION
      },
      {
        text: 'Account',
        url: ''
      }
    ]
  });

  if (!isOwner) {
    navigate(routes.ACCESS_DENIED);
  }

  return (
    <PaperCustom variant="medium" className="account ">
      <div className="account_heading ">
        <Typography variant="body-2" size="medium" as="h2" className="apply-loader">
          Delete your <CloudInSpotName className="!font-semibold" /> account
        </Typography>
        <Typography
          variant="body-2"
          size="regular"
          as="span"
          className="apply-loader  account_heading__span"
        >
          (Organization ID:{' '}
          <Typography variant="body-2" size="semiBold" as="span">
            {orgID}
          </Typography>
          )
        </Typography>
      </div>

      <Ruler color={PRIMARY_COLORS[200]} />

      <div className="account_content ">
        <Typography variant="body-3" size="regular" as="h4" className="apply-loader">
          If you wish to permanently delete your <CloudInSpotName /> account “
          <span className="org-name">{orgName}</span>
          ”, this will also delete following -
        </Typography>
        <div className="steps">
          <ol>
            {deleteAccountSteps.map((item, idx) => (
              <li className="apply-loader" key={idx + item}>
                <Typography variant="body-3" size="regular" as="h4">{`${
                  idx + 1
                }. ${item}`}</Typography>
              </li>
            ))}
          </ol>
        </div>

        <Typography variant="body-3" size="regular" as="h4" className="apply-loader">
          Once deleted, this action can not be undone.
        </Typography>
        <CustomButton
          text="Delete Account"
          variant={ButtonVariants.DELETE}
          onClick={handleDeleteState}
          className="apply-loader "
        />
      </div>

      {isDeleteModalOpen && (
        <AccountDeleteModal
          handleClose={() => handleDeleteState()}
          handleDelete={handleDelete}
          isLoading={isLoading}
        />
      )}

      {isDeleteAccountSuccessModalOpen && (
        <ConfirmModal
          open={true}
          description={
            <>
              Your <CloudInSpotName /> account deleted successfully. Signing you out...
            </>
          }
          image={IMAGES.confirmGif}
          setOpen={() => {
            setIsDeleteAccountSuccessModalOpen(false);
          }}
          handleOkClick={() => {
            handleClearUserData();
          }}
          confirmButtonText="OKAY"
        />
      )}

      {feedBackModalOpen && (
        <UserFeedback
          openModal={true}
          handleClose={() => setFeedBackModalOpen(false)}
          callbackFunc={handleFeedbackSubmit}
          containerClass="account__faq"
        />
      )}

      {feedbackSuccessModal && (
        <ConfirmModal
          open={true}
          setOpen={setFeedbackSuccessModal}
          title="Feedback Sent!"
          description={
            <>
              Your feedback is sent successfully to <CloudInSpotName /> team. Thank you!
            </>
          }
          image={confirmSuccess}
          descriptionWidth="88%"
          confirmButtonText={BUTTON_TEXT.OKAY}
          handleOkClick={() => {
            setFeedbackSuccessModal(false);
          }}
        />
      )}
      {warningModalOpen && (
        <ConfirmModal
          open={true}
          setOpen={setWarningModalOpen}
          title="Warning"
          description={
            <span>
              Your Cloud Account(s) are still connected to <CloudInSpotName />. Please, disconnect
              them first and then delete your account.
            </span>
          }
          image={IMAGES.ErrorExclamation}
          confirmButtonText={'Go to Cloud Accounts'}
          handleOkClick={() => navigate(routes.CLOUD_ACCOUNTS)}
          descriptionWidth="88%"
        />
      )}
    </PaperCustom>
  );
};

export default Account;
