import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import IMAGES from '../../../assets';
import { BUTTON_TEXT } from '../../../configs';
import { CREATE_TEAMS_MESSAGES, MY_PROFILE_TOOLTIP_MESSAGES } from '../../../configs/myProfile';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import { PRIMARY } from '../../../configs/v3.colors';
import { parametricRoutes, routes } from '../../../constants/routes';
import ENUMS from '../../../enums';
import { userEditAccessControlTabTypes } from '../../../enums/teamManagement';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import { updateAccessControlTabType } from '../../../store/actions';
import { ITeamManagementUserInitialState } from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import EditPenIcon from '../../common/icons/editPenIcon/EditPenIcon';
import PlusIcon from '../../common/icons/plusIcon/PlusIcon';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import NoDataFound from '../../common/noDataFound/noDataFound';
import Typography from '../../common/typography/Typography';

const { userGroups } = ENUMS.teamManagement;

const Groups = ({
  data,
  isLoading,
  teamUserProfile
}: {
  data: string[];
  teamUserProfile: boolean;
  isLoading: boolean;
  onEditControlButtonClick?: () => void;
}) => {
  const userGroupsScrollRef = useRef<HTMLDivElement>(null);

  const { userProfileData } = useAppSelector(
    (state) => state.teamManagementUser
  ) as ITeamManagementUserInitialState;

  const isOwner: boolean = userProfileData.isOwner;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(updateAccessControlTabType('groups'));
    navigate(
      `${routes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL}/${userEditAccessControlTabTypes.Groups}`
    );
  };

  const navigateToAddTeam = () => {
    navigate('/team-management/add-group');
  };

  // USER PERMISSIONS START
  const { canUserEditAnotherUserGroups } = useCheckUserPermissions();
  // USER PERMISSIONS END

  return (
    <PaperCustom className="access-control__groups access-control__tab-item" variant="medium">
      <div className="access-control__groups__heading">
        <Typography
          variant="body-2"
          size="medium"
          className="access-control__groups__heading__title apply-loader"
        >
          {data?.length > 0 ? TEAM_MANAGEMENT_TEXT.GROUPS : ''}
        </Typography>
        {teamUserProfile && (
          <div className="access-control__groups__heading__buttons">
            {data?.length > 0 && (
              <TooltipCustom
                text={MY_PROFILE_TOOLTIP_MESSAGES.NO_EDIT_OTHER_USER_PROFILE_GROUPS}
                conditionToDisplay={!canUserEditAnotherUserGroups}
              >
                <button
                  className="access-control__groups__heading__edit apply-loader"
                  onClick={() => {
                    if (canUserEditAnotherUserGroups) {
                      handleEdit();
                    }
                  }}
                  disabled={!canUserEditAnotherUserGroups}
                >
                  <EditPenIcon color={PRIMARY[600]} />
                  <Typography variant="body-3" size="medium" as="span">
                    {TEAM_MANAGEMENT_TEXT.EDIT_GROUP}
                  </Typography>
                </button>
              </TooltipCustom>
            )}

            <TooltipCustom
              text={MY_PROFILE_TOOLTIP_MESSAGES.NO_EDIT_OTHER_USER_PROFILE_GROUPS}
              conditionToDisplay={!canUserEditAnotherUserGroups}
            >
              <button
                className="add-user-to-group  apply-loader"
                onClick={() => {
                  navigate(parametricRoutes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_ADD_TO_GROUP);
                }}
                disabled={!canUserEditAnotherUserGroups}
              >
                <PlusIcon color="white" />
                <Typography variant="body-3" size="medium" as="span">
                  {BUTTON_TEXT.ADD_USER_TO_GROUPS}
                </Typography>
              </button>
            </TooltipCustom>
          </div>
        )}
      </div>
      <div
        className="access-control__groups__items access-control__tab-item__items"
        ref={userGroupsScrollRef}
      >
        {data?.length > 0 ? (
          data.map((item, index) => (
            <p className="access-control__tab-item__items__item apply-loader" key={index}>
              {item === userGroups.Admin && <img src={IMAGES.lockIcon} />}
              <Typography variant="body-3" size="regular" className="group-item" as="span">
                {item}
              </Typography>
            </p>
          ))
        ) : (
          <div className="no-data-container">
            <NoDataFound
              isLoading={isLoading}
              description={
                isOwner && teamUserProfile
                  ? CREATE_TEAMS_MESSAGES.YOU_HAVE_NOT_BEEN_ADDED_TO_ANY_TEAM_YET
                  : teamUserProfile
                  ? CREATE_TEAMS_MESSAGES.THIS_USER_HAS_NOT_BEEN_ADDED_TO_ANY_TEAM
                  : CREATE_TEAMS_MESSAGES.YOU_HAVE_NOT_BEEN_ADDED_TO_ANY_TEAM_YET
              }
            />

            {!teamUserProfile && (
              <TooltipCustom
                text={MY_PROFILE_TOOLTIP_MESSAGES.NO_PERMISSION_CREATE_TEAM}
                conditionToDisplay={!canUserEditAnotherUserGroups}
              >
                <CustomButton
                  text={<span>Create Team</span>}
                  image={IMAGES.PlusWhite}
                  variant={ButtonVariants.PRIMARY}
                  className="create-team-btn apply-loader"
                  type="button"
                  buttonWidth="152px"
                  onClick={navigateToAddTeam}
                  disabled={!canUserEditAnotherUserGroups}
                />
              </TooltipCustom>
            )}
          </div>
        )}
      </div>
    </PaperCustom>
  );
};

export default Groups;
