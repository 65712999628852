import React, { useEffect } from 'react';

import './insights.scss';

import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import { setHeading, setActiveTab, updateInsightsTable } from '../../store/actions';
import { TTabItem } from '../../components/common/customTabs/CustomTabs.interface';
import {
  IInsightsInitialValues,
  TInsightsTablesType
} from '../../store/insights/insights.interface';
import Tabs from '../../components/common/customTabs/CustomTabs';
import useNoCloudAccountsAccessDenied from '../../hooks/userAuthentication/NoCloudAccountsAccessDenied/useNoCloudAccountsAccessDenied';
import ProvisioningAlerts from '../../components/insights/provisioningAlerts/ProvisioningAlerts';
import Anomaly from '../../components/insights/anomaly/Anomaly';

const Insights = () => {
  const dispatch = useAppDispatch();

  const { tableType } = useAppSelector((store) => store.insights) as IInsightsInitialValues;

  const changeInsightsTab = (value: string) => {
    dispatch(updateInsightsTable(value as TInsightsTablesType));
  };

  const insightsTabItems: TTabItem[] = [
    { key: 'provisioningAlerts', text: 'Provisioning alerts' },
    { key: 'anomaly', text: 'Anomaly ' }
  ];

  const tableComponents = {
    provisioningAlerts: <ProvisioningAlerts />,
    anomaly: <Anomaly />
  };

  useNoCloudAccountsAccessDenied();

  useEffect(() => {
    dispatch(setHeading('Insights'));
    dispatch(setActiveTab(3));
  }, []);
  return (
    <>
      <div className="insights">
        <div className="insights__tabs">
          <Tabs items={insightsTabItems} onChange={changeInsightsTab} activeItem={tableType} />
        </div>
        <div className="insights__content">{tableComponents[tableType]}</div>
      </div>
    </>
  );
};
export default Insights;
