import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import './teamManagement.scss';

import { TTeamMgmtTableType } from './teamManagement.types';

import Tabs from '../../components/common/customTabs/CustomTabs';
import { TTabItem } from '../../components/common/customTabs/CustomTabs.interface';
import Groups from '../../components/teamManagement/Groups/Groups';
import ArchivedUsers from '../../components/teamManagement/archivedUsers/ArchivedUsers';
import Users from '../../components/teamManagement/users/Users';
import { routes } from '../../constants/routes';
import teamManagement from '../../enums/teamManagement';
import useCheckUserPermissions from '../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import { useAppDispatch } from '../../hooks/redux/useRedux';
import { prefetchTeamsModuleData } from '../../services/prefechService/prefetchTeamsService';
import { setActiveTab, setHeading, updateSkeletonLoader } from '../../store/actions';
import NotFound from '../not-found/NotFound';

const { tableTypes } = teamManagement;

export enum ETeamManagementTabs {
  USERS = 'users',
  GROUPS = 'groups',
  DEACTIVATED_USERS = 'deactivated-users'
}

const TeamManagement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();

  const tableType = (params.tabletype as unknown as TTeamMgmtTableType) || tableTypes.Users;

  const changeUserTab = (value: string) => {
    navigate(`${routes.TEAM_MANAGEMENT}/${value}`);
  };

  // USER PERMISSIONS START
  const { canUserViewUsersTable, canUserViewGroupsTable, canUserViewDeactivatedUsersTable } =
    useCheckUserPermissions();
  // USER PERMISSIONS END

  const userTabItems: TTabItem[] = [
    { key: ETeamManagementTabs.USERS, text: 'Users', disabled: !canUserViewUsersTable },
    { key: ETeamManagementTabs.GROUPS, text: 'Teams', disabled: !canUserViewGroupsTable },
    {
      key: ETeamManagementTabs.DEACTIVATED_USERS,
      text: 'Deactivated Users',
      disabled: !canUserViewDeactivatedUsersTable
    }
  ];

  const tableComponents = {
    users: <Users />,
    groups: <Groups />,
    ['deactivated-users']: <ArchivedUsers />
  };

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading('Team Management'));
      dispatch(setActiveTab(5));
    },
    onUnMountFunc: () => {
      dispatch(updateSkeletonLoader({ isLoading: true }));
    }
  });

  const componentToRender = tableComponents[tableType];

  if (componentToRender) {
    return (
      <>
        <div className="team-management">
          <div className="team-management__tabs">
            <Tabs
              items={userTabItems}
              onChange={changeUserTab}
              onMouseEnter={(tab: string) =>
                prefetchTeamsModuleData(tab as ETeamManagementTabs, queryClient)
              }
              activeItem={tableType}
            />
          </div>
          <div className="team-management__content">{componentToRender}</div>
        </div>
      </>
    );
  }

  return <NotFound />;
};

export default TeamManagement;
