import axios from 'axios';
import { errorResponse } from '@cloudinspot/cis-common-constants';

import { generateURL, getAuthTokens } from './getAuthTokensAndURL';
import { IErrorResponseData } from './service.interface';

import { IAuthInfo, IGetTokenAPIResponse } from '../interfaces/auth';
import API_ENDPOINTS from '../constants/api_endpoints';

const updateTokens = async () => {
  try {
    const { organizationId, email, refreshToken } = getAuthTokens();

    // A request  sent to fetch the new access token using the refresh token.
    const response: IGetTokenAPIResponse = await axios.post(
      generateURL(API_ENDPOINTS.GET_NEW_TOKEN),
      {
        organizationId,
        email: email,
        refreshToken
      }
    );

    const authenticationResult = response.data.data.AuthenticationResult;

    const expiresIn = authenticationResult.ExpiresIn;
    // Store new access token and idToken in the localStorage
    const authInfo: IAuthInfo = {
      AccessToken: authenticationResult.AccessToken,
      ExpiresIn: authenticationResult.ExpiresIn.toString(),
      IdToken: authenticationResult.IdToken
    };
    localStorage.setItem('authInfo', JSON.stringify(authInfo));

    // sets the expiresin timestamp
    localStorage.setItem('userExpiresIn', `${new Date().getTime() + expiresIn * 1000}`);
  } catch (error: unknown) {
    if (error as IErrorResponseData) {
      const response = (error as IErrorResponseData)?.response;
      const areCredentialsInvalid =
        response?.data?.code === errorResponse.CognitoExceptions.INVALID_CREDENTIALS.code &&
        response?.status === 400;

      if (areCredentialsInvalid) {
        localStorage.removeItem('user');
        localStorage.removeItem('userExpiresIn');
        localStorage.removeItem('authInfo');
        localStorage.removeItem('organization');
        localStorage.removeItem('userData');
        localStorage.removeItem('retryCounter');
        (window as Window).location = '/';
      }
    }
  }
};

export default updateTokens;
