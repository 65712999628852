// External imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Internal imports
import UserSummaryStats from './UserSummaryStats';

import { ERRORS as ERRORS_COLORS } from '../../../../configs/v3.colors';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import {
  TEAM_MANAGEMENT_TOOLTIP_MESSAGES,
  TEAM_MANAGEMENT_TEXT
} from '../../../../configs/teamManagement';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import IMAGES from '../../../../assets';
import { routes } from '../../../../constants/routes';
import Typography from '../../../common/typography/Typography';
import TrashIcon from '../../../common/icons/trashIcon/TrashIcon';
import { TUserSummary } from '../../../../store/teamManagement/teamManagement.interface';

interface IUsersSummaryProps {
  selectedRows: string[];
  setOpenArchiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  userSummary: TUserSummary;
}

const UsersSummary = ({ selectedRows, setOpenArchiveModal, userSummary }: IUsersSummaryProps) => {
  const navigate = useNavigate();

  // USER PERMISSIONS START
  const { canUserAddAnotherUser, canUserDeactivateAnotherUser } = useCheckUserPermissions();
  // USER PERMISSIONS END

  return (
    <div className="team-management-table__user-summary">
      <div className="team-management-table__user-summary__buttons-row">
        <Typography
          className="team-management-table__user-summary__buttons-row__label"
          as="p"
          size="medium"
          variant="body-2"
        >
          Summary
        </Typography>
        <div className="team-management-table__search__buttons team-management-table__user-summary__buttons-row__buttons">
          {selectedRows.length > 0 && (
            <TooltipCustom
              text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_DEACTIVATE_USER_ACCESS}
              conditionToDisplay={!canUserDeactivateAnotherUser}
            >
              <button
                type="button"
                className="archive-user apply-loader"
                onClick={() => setOpenArchiveModal(true)}
                disabled={!canUserDeactivateAnotherUser}
              >
                <TrashIcon color={ERRORS_COLORS[700]} />
                <span>{TEAM_MANAGEMENT_TEXT.DEACTIVATE}</span>
              </button>
            </TooltipCustom>
          )}
          <TooltipCustom
            text={TEAM_MANAGEMENT_TOOLTIP_MESSAGES.NO_ADD_USER_ACCESS}
            conditionToDisplay={!canUserAddAnotherUser}
          >
            <button
              type="button"
              className="add-user apply-loader"
              onClick={() => navigate(routes.ADD_USER)}
              disabled={!canUserAddAnotherUser || selectedRows?.length > 0}
            >
              <img src={IMAGES.addorPlusIcon} alt="add or plus button icon" />
              <span>{TEAM_MANAGEMENT_TEXT.ADD_USER}</span>
            </button>
          </TooltipCustom>
        </div>
      </div>
      <div className="team-management-table__user-summary__stats">
        <UserSummaryStats userSummary={userSummary} />
      </div>
    </div>
  );
};

export default UsersSummary;
