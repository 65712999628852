import { useRef } from 'react';

import { IViewMoreModal } from './viewMoreModal.interface';
import './viewMoreModal.scss';

import SubMenuModal from '../subMenuModal/SubMenuModal';
import useElementPosition from '../../../hooks/elementPosition/useElementPosition';
import Typography from '../typography/Typography';

const ViewMoreModal = ({
  textColorClass,
  bgColorClass,
  data,
  parentClass,
  viewMore,
  setViewMore,
  clientX,
  clientY,
  textColor = 'black'
}: IViewMoreModal) => {
  const viewMoreModalRef = useRef<HTMLDivElement>(null);

  const viewMoreModalRefPosition = useElementPosition({
    ref: viewMoreModalRef,
    triggers: [viewMore, clientX, clientY]
  });

  const leftPosition = clientX;
  const topPosition = clientY;

  const isModalOutsideWindowBottom =
    topPosition + viewMoreModalRefPosition.height > window.innerHeight;

  return (
    <SubMenuModal setOpenSubMenuModal={setViewMore}>
      <div
        className={`view-more-list-modal ${parentClass}__view-more-list-modal`}
        style={{
          visibility: viewMore ? 'visible' : 'hidden',
          left: `${leftPosition}px`,
          ...(!isModalOutsideWindowBottom && { top: `${topPosition}px` }),
          ...(isModalOutsideWindowBottom && { bottom: '2px' }),
          ...(leftPosition < 0 && { width: `${clientX}px`, left: '2px' })
        }}
        ref={viewMoreModalRef}
        onMouseEnter={() => setViewMore(true)}
        onMouseLeave={() => setViewMore(false)}
      >
        <div className="view-more-list-modal__inner">
          {data.map((group, index) => (
            <Typography
              key={index}
              className={`${parentClass}__view-more-list-modal__item view-more-list-modal__item ${textColorClass} ${bgColorClass}`}
              style={{ color: textColor }}
              as="p"
              size="regular"
            >
              {group}
            </Typography>
          ))}
        </div>
      </div>
    </SubMenuModal>
  );
};

export default ViewMoreModal;
