import { IConnectStepProps } from './connectStep.interface';

import './connectStep.scss';
import Typography from '../typography/Typography';

const ConnectStep = ({ stepName, description, descriptionWidth = '705px' }: IConnectStepProps) => {
  return (
    <div className="connectStep">
      <Typography variant="body-3" size="semiBold" className="heading">
        {stepName}
      </Typography>
      <Typography
        variant="body-3"
        size="regular"
        className="description"
        style={{ width: descriptionWidth }}
      >
        {description}
      </Typography>
    </div>
  );
};

export default ConnectStep;
