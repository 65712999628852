// Helper function to trim all string values in a payload
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trimPayload = (payload: Record<string, any>): Record<string, any> => {
  // Create a new object to store the trimmed values
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trimmedPayload: Record<string, any> = {};

  // Iterate over each key-value pair in the payload
  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const value = payload[key];

      // If the value is a string, trim it; otherwise, keep the original value
      trimmedPayload[key] = typeof value === 'string' ? value.trim() : value;
    }
  }

  // Return the new object with trimmed values
  return trimmedPayload;
};
