import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import './addGroup.scss';

import { AddUserToGroup } from './AddUserToGroup';
import SelectedUser from './SelectedUser';

import IMAGES from '../../../../assets';
import { confirmSuccess } from '../../../../assets/images';
import { BUTTON_TEXT } from '../../../../configs';
import { COMMON_TEXT } from '../../../../configs/common';
import { TEAM_MANAGEMENT_TEXT } from '../../../../configs/teamManagement';
import { BLACK as BLACK_COLORS, PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../../../constants/constants';
import { routes } from '../../../../constants/routes';
import getUserData from '../../../../helpers/getUserData';
import useDebounce from '../../../../hooks/debounce/useDebounce';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import getWithAuth from '../../../../services/getWithAuth';
import postWithAuth from '../../../../services/postWithAuth';
import {
  addGroupName,
  setAddUsersToGroupList,
  setCurrentActiveTabForm,
  updateAddUsersToGroupList
} from '../../../../store/actions';
import {
  IAddUserToGroup,
  ITeamManagementInitialValues
} from '../../../../store/teamManagement/teamManagement.interface';
import { getFetchTeamsQueryKey } from '../../../../utils/pre-fetchers/prefetchTeamManagement';
import { trimPayload } from '../../../../utils/trimString/trimString';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import NoDataFound from '../../../common/noDataFound/noDataFound';
import Ruler from '../../../common/ruler/Ruler';
import Typography from '../../../common/typography/Typography';
import GoBack from '../../common/goBack/GoBack';
import HeadingText from '../../common/headingText/HeadingText';
import { IAddUsersProps, IFetchUsersAPIResponse } from '../groups.interface';

export interface IFetchUserData {
  query?: string;
}

const AddUsers = ({ addUserSelectedPermissions }: IAddUsersProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();
  const createGroupLoading = useLoading();
  const boxRef = React.createRef<HTMLDivElement>();
  const [noDataFound, setNoDataFound] = useState(false);

  const { addGroup1, selectedUserToAddGroupsList, addUsersToGroupAPIList } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const deselectUser = (user: string) => {
    const newList = selectedUserToAddGroupsList.filter((item) => item.email != user);
    dispatch(updateAddUsersToGroupList(newList));
  };

  const addToSelectedUsers = (user: IAddUserToGroup) => {
    if (selectedUserToAddGroupsList.includes(user)) {
      deselectUser(user.email);
      return;
    }
    const addUsersList = [...selectedUserToAddGroupsList, user];
    dispatch(updateAddUsersToGroupList(addUsersList));
    return;
  };

  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(1));
  };

  const handleCancel = () => {
    dispatch(addGroupName(''));
    navigate(routes.TEAM_MANAGEMENT_GROUPS_TABLE);
  };

  const handleCreateGroup = async () => {
    createGroupLoading.startLoading();
    try {
      const userData = getUserData();
      const usersEmailsList = selectedUserToAddGroupsList.map((user) => user.email); //collects the email from selected users
      const payload = {
        groupName: addGroup1.groupName,
        emails: usersEmailsList,
        createdBy: userData.email,
        permissions: addUserSelectedPermissions //permissions from second step
      };

      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_CREATE_GROUP,
        payload: trimPayload(payload)
      });

      if (response) {
        setIsSuccessModalOpen(true);
        queryClient.resetQueries(
          getFetchTeamsQueryKey({
            limit: ROWS_PER_PAGE_FIRST_ITEM,
            offset: offsetFirst,
            filterGroup: ''
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'AddGroupFail'
      });
    } finally {
      createGroupLoading.stopLoading();
    }
  };

  const handleOkForModal = () => {
    dispatch(addGroupName(''));
    navigate(routes.TEAM_MANAGEMENT_GROUPS_TABLE);
  };

  const fetchUsers = async ({ query }: IFetchUserData) => {
    startLoading();
    try {
      let params;
      if (query) {
        params = {
          limit: limit,
          offset: 1,
          filterUser: query
        };
      } else {
        params = {
          limit: limit,
          offset: 1
        };
      }
      const response = (
        await getWithAuth({
          url: API_ENDPOINTS.TEAM_MGMT_LIST_USERS,
          params: params
        })
      )?.data as unknown as IFetchUsersAPIResponse;

      //convert response to IAddUserToGroup format
      const usersListFromAPI: IAddUserToGroup[] = response.users.map((user) => ({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        jobTitle: user.jobTitle
      }));

      setCount(response.count); //setting count
      dispatch(setAddUsersToGroupList(usersListFromAPI));
      setApiCalled(false);
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'FetchUsersFail'
      });
    } finally {
      stopLoading();
    }
  };

  const handleSearchQuery = async () => {
    await fetchUsers({ query });
    setNoDataFound(true);
  };

  useDebounce({
    func: handleSearchQuery,
    delay: 500,
    dependency: query
  });

  // Attach the scroll event listener
  useEffect(() => {
    // Attach the scroll event listener to the box element
    const handleScroll = () => {
      const boxElement = boxRef.current;
      if (!boxElement || apiCalled) return; //return if api is called or don't have reference to the box element

      const boxHeight = boxElement.clientHeight; //height of the box
      const scrollPosition = boxElement.scrollTop; //position of the scroll
      const contentHeight = boxElement.scrollHeight; //height of the total content inside the box

      const threshold = 0.7; // 70% threshold to have a seamless UX

      if (
        boxHeight + scrollPosition > threshold * contentHeight && // scroll is crossed threshold
        count - addUsersToGroupAPIList.length > 0
      ) {
        // You have reached the bottom of the box
        //update the limit and call API with updated limit value
        setLimit((prev) => prev + 10);
        setApiCalled(true);
      }
    };

    const boxElement = boxRef.current;
    if (boxElement) {
      boxElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Detach the scroll event listener on cleanup
      if (boxElement) {
        boxElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [boxRef]);

  useEffect(() => {
    // on mount call to the API
    fetchUsers({});
  }, [limit]);

  return (
    <div className={`add-group-add-user ${loading ? 'add_user_loading' : ''}`}>
      <GoBack handleGoBack={handleGoBack} className="add-group-add-user__go-back" />
      <div className="search-selected">
        <PaperCustom variant="light" className="add_users">
          <HeadingText text={TEAM_MANAGEMENT_TEXT.ADD_USERS} color={BLACK_COLORS[900]} />
          <div className="add_users__search add_users__input-field">
            <img src={IMAGES.searchIcon} alt="Search Icon" />
            <input
              type="search"
              className="search_input"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search User"
            />
            <CustomButton
              variant={ButtonVariants.PRIMARY}
              text={COMMON_TEXT.SEARCH}
              onClick={handleSearchQuery}
            />
          </div>
          <div ref={boxRef} className="list-container">
            {addUsersToGroupAPIList.length > 0 ? (
              addUsersToGroupAPIList.map((user, index) => (
                <AddUserToGroup
                  user={user}
                  key={index}
                  addToSelectedUsers={() => addToSelectedUsers(user)}
                  index={index}
                  selectedUsers={selectedUserToAddGroupsList}
                  tooltipCharLimit={50}
                />
              ))
            ) : noDataFound ? (
              <NoDataFound />
            ) : null}
          </div>
        </PaperCustom>

        {/* divider line */}
        <Ruler className="search-selected__divider" color={PRIMARY_COLORS[200]} />

        <PaperCustom variant="light" className="selected-users-container ">
          <div className="selected-users-container__heading">
            <HeadingText text={TEAM_MANAGEMENT_TEXT.SELECTED_USERS} color={BLACK_COLORS[900]} />
            <Typography
              variant="body-3"
              size="regular"
              color={BLACK_COLORS[900]}
              className="selected-users-container__heading__count"
            >
              &#40;{selectedUserToAddGroupsList.length}&nbsp;{TEAM_MANAGEMENT_TEXT.USER_SELECTED}
              &#41;
            </Typography>
          </div>
          <div className="selected-users-container_list ">
            {selectedUserToAddGroupsList.map((user, index) => {
              return (
                <SelectedUser
                  key={index}
                  userName={user.name}
                  email={user.email}
                  deselectUser={deselectUser}
                />
              );
            })}
          </div>
        </PaperCustom>
      </div>
      <div className="add_user_buttons">
        <CustomButton
          text={BUTTON_TEXT.CANCEL}
          onClick={handleCancel}
          type="button"
          variant={ButtonVariants.SECONDARY}
          className="cancel"
        />
        <CustomButton
          text={BUTTON_TEXT.CREATE_GROUP}
          onClick={handleCreateGroup}
          type="submit"
          isLoading={createGroupLoading.loading}
          variant={ButtonVariants.PRIMARY}
          className="create-group"
        />
      </div>
      <ConfirmModal
        title={TEAM_MANAGEMENT_TEXT.GROUP_CREATED}
        description={TEAM_MANAGEMENT_TEXT.GROUP_CREATE_SUCCESS}
        image={confirmSuccess}
        setOpen={setIsSuccessModalOpen}
        open={isSuccessModalOpen}
        confirmButtonText={BUTTON_TEXT.OKAY}
        handleOkClick={handleOkForModal}
      />
    </div>
  );
};

export default AddUsers;
