import { twMerge } from 'tailwind-merge';

const RulerDefaultValues: IRulerProps = {
  borderWidth: 1,
  color: '#c4c4c4'
};

export interface IRulerProps {
  borderWidth?: number;
  color?: string;
  className?: string;
}

const Ruler = ({
  borderWidth = RulerDefaultValues.borderWidth,
  color = RulerDefaultValues.color,
  className
}: IRulerProps) => {
  return <div style={{ borderWidth, borderColor: color }} className={twMerge(className)} />;
};

export default Ruler;
