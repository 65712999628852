export const MY_PROFILE_TEXT = {
  EDIT: 'Edit',
  EDIT_PROFILE: 'Edit Profile',
  PERSONAL_INFORMATION: 'Personal Information',
  PROFESSIONAL_INFORMATION: 'Professional Information',
  ONBOARDED_ON: 'Onboarded On',
  GROUPS: 'Teams',
  Role: 'Role',
  CHANGE_PASSWORD: 'Change Password',
  CLIPBOARD_COPY: 'Email ID copied to clipboard',
  PROFILE_UPDATED: 'Profile updated!',
  PROFILE_UPDATED_SUCCESSFULLY: 'Profile has been successfully updated.',
  PASSWORD_CHANGED: 'Password changed successfully',
  PASSWORD_UPDATED: 'Password Updated!',
  PASSWORD_UPDATED_SUCCESSFULLY: 'Your password changed successfully.',
  PERMISSION_UPDATED: 'Permission Updated!',
  USER_PERMISSION_SUCCESSFULLY_UPDATED: `This user's permission has been successfully updated.`,
  UPLOAD_PHOTO: 'Upload Photo',
  REMOVE_PHOTO: 'Remove Photo',
  IMAGE_UPLOAD_INVALID_FILE_TYPE: 'Image should be of type BMP, JPG, JPEG, or PNG',
  INFORMATION: 'Information',
  ACCESS_CONTROL: 'Access Control',
  VIEW_MORE: 'View More',
  PERMISSIONS: 'Permissions',
  EDIT_ACCESS_CONTROL: 'Edit Access Control',
  USER_INFO_UPDATE_SUCCESS: 'User Information updated successfully',
  USER_PERMISSIONS_UPDATE_SUCCESS: 'User permissions updated successfully',
  USER_PERMISSIONS_UPDATE_NO_SELECTION: 'You have not selected any permissions!',
  USER_PROFILE_UPDATED: 'User Profile Updated!',
  USER_PROFILE_UPDATED_SUCCESSFULLY: 'This user’s profile has been successfully updated.',
  USER_REMOVED: 'User Removed',
  USER_REMOVED_SUCCESSFULLY_FROM_GROUP: 'This user has been successfully removed from the team(s)',
  USER_ADDED: 'User added',
  USER_SUCCESSFULLY_ADDED_TO_GROUPS:
    'This user has been successfully added to the selected team(s).',
  USERS: 'Users',
  ADD_USER_TO_GROUPS: 'Add User to Teams',
  REMOVED_FROM_GROUP: 'User successfully removed from team(s)',
  PROFILE_PIC_LIMIT: 'File size must not exceed 512 KB.'
};

export const MY_PROFILE_TOOLTIP_MESSAGES = {
  NO_DEACTIVATE_OTHER_USER_ACCESS: 'You do not have access to deactivate another profile',
  NO_EDIT_OTHER_USER__PROFILE_ACCESS: 'You do not have access to edit other users profile',
  NO_EDIT_OTHER_USER_PROFILE_GROUPS: 'You do not have access to edit other users teams',
  NO_EDIT_OTHER_USER_PROFILE_PERMISSIONS: 'You do not have access to edit other users permissions',
  NO_PERMISSION_CREATE_TEAM: 'You do not have access to create team ',

  NO_RESET_OTHER_USER_PASSWORD_ACCESS: 'You do not have access to reset another users password'
};

export const CREATE_TEAMS_MESSAGES = {
  YOU_HAVE_NOT_BEEN_ADDED_TO_ANY_TEAM_YET: 'You have not been added to any team yet.',
  THIS_USER_HAS_NOT_BEEN_ADDED_TO_ANY_TEAM: 'This user has not been added to any team.'
};
