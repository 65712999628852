import { Country } from 'country-state-city';

import { ITimezoneOptions } from '../../../pages/userOnboarding/userOnboarding.types';
import timezoneConverter from '../../../utils/timezoneConverter/timezoneConverter';

const useTimezones = () => {
  let timezones: ITimezoneOptions[] = [];

  Country.getAllCountries().forEach((country) => {
    const coutryTimezones = country.timezones;
    if (coutryTimezones) {
      timezoneConverter(coutryTimezones).forEach((tz) => timezones.push(tz));
    }
  });

  // Sorting timezones in the increasing order of GMT.
  timezones = timezones.sort((a, b) => (a?.gmtOffset < b?.gmtOffset ? -1 : +1));

  return timezones;
};

export default useTimezones;
