import React from 'react';
import './InputGuidance.scss';

interface IInputGuidanceProps {
  content: string | React.ReactNode;
  className?: string;
}

const InputGuidance = ({ content, className = 'aws-info' }: IInputGuidanceProps) => {
  return <p className={className}>{content}</p>;
};

export default InputGuidance;
