import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import '../slack/slack.scss';

import EmailList from './EmailList';

import IMAGES from '../../../../assets';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import {
  closeAlertMessage,
  openAlertMessage,
  setActiveTab,
  setHeading,
  updateBreadcrumbs
} from '../../../../store/actions';
// eslint-disable-next-line

import {
  CLOUD_ACCOUNTS_TEXT,
  CLOUD_ACCOUNTS_TOOLTIP_MESSAGES
} from '../../../../configs/cloudAccounts';
import { COMMON_TEXT } from '../../../../configs/common';
import { colorConstants } from '../../../../configs/styleConstants';
import { ROWS_PER_PAGE_FIRST_ITEM } from '../../../../constants/constants';
import { routes } from '../../../../constants/routes';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import CloudAccountService from '../../../../services/cloudAccount.service';
import {
  TAccount,
  TGetEmailReceipeintsResponse
} from '../../../../services/types/cloudAccount.endpoints';
import { ICloudAccountsReducerInitialState } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import useEmailListStore from '../../../../store/cloudAccounts/useEmailListStore';
import { calculateNumberOfPages, getNextOffset } from '../../../../utils/pagination.utils';
import {
  getFetchEmailRecipientsQueryKey,
  prefetchEmailReceipeints
} from '../../../../utils/pre-fetchers/prefetchCloudAccounts';
import NoDataScreen from '../../../common/noData/NoDataScreen';
import TextTooltipWithEllipsis from '../../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import shouldHideEmailSlackTab from '../shouldHideEmailSlackTab';

const Email = () => {
  //Redux
  const {
    accountDetails: { accountName, status: cloudAccountStatus }
  } = useAppSelector((state) => state.cloudAccounts) as ICloudAccountsReducerInitialState;
  //states
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_FIRST_ITEM);
  //hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const queryClient = useQueryClient();
  const { setEmailList, setAccountDetails } = useEmailListStore();
  // USER PERMISSIONS START
  const { canUserAddEmailNotification } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const handleConnectToEmailsClick = () => {
    navigate(`/cloud-account/view/email/connect/${param?.id}`);
  };

  const {
    isLoading,
    data: emailReceipients,
    refetch: refetchEmailReceipients
  } = useQuery<TGetEmailReceipeintsResponse>(
    getFetchEmailRecipientsQueryKey({
      limit: rowsPerPage,
      offset: pageNumber,
      accountId: param?.id || ''
    }),
    () =>
      CloudAccountService.getEmailReceipients({
        limit: rowsPerPage,
        offset: pageNumber,
        accountId: param?.id || ''
      }),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const updateMsTeamsBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        { text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW, url: `/cloud-account/view/${param.id}` },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountName}
            />
          ),
          url: `/cloud-account/view/${param.id}`
        },
        { text: `Email`, url: '' }
      ])
    );
  };
  useEffect(() => {
    updateMsTeamsBreadcrumbs();
  }, [accountName]);

  useEffect(() => {
    const onMountFunc = () => {
      dispatch(setHeading('Cloud Accounts'));
      dispatch(setActiveTab(2));

      // This is temporary, dispatch should be based on whether any email is connected
      if (emailReceipients) {
        const emailReceipientsCount =
          emailReceipients.count === undefined || emailReceipients.count === 0;

        if (emailReceipientsCount) {
          dispatch(
            openAlertMessage({
              showAlertMessage: true,
              message: 'Add Email recipients to receive daily notifications',
              textColor: colorConstants.green1,
              backgroundColor: colorConstants.lightGreen1
            })
          );
        }
      }
    };

    const onUnMountFunc = () => {
      dispatch(closeAlertMessage());
    };

    onMountFunc();
    return onUnMountFunc;
  }, [emailReceipients?.count]);

  useEffect(() => {
    if (emailReceipients?.count && calculateNumberOfPages(emailReceipients?.count, rowsPerPage)) {
      prefetchEmailReceipeints(queryClient, {
        limit: rowsPerPage,
        offset: getNextOffset(pageNumber),
        accountId: param.id || ''
      });
    }
  }, [pageNumber, rowsPerPage, queryClient, emailReceipients?.count]);

  useEffect(() => {
    // reset rowsPerPage size to 12 when left the page
    return () => {
      setRowsPerPage(ROWS_PER_PAGE_FIRST_ITEM);
    };
  }, []);

  useEffect(() => {
    setEmailList(emailReceipients?.emailNotifications || []);
    setAccountDetails(emailReceipients?.account || new TAccount());
  }, [emailReceipients]);

  const shouldHideEmailSlackTabs: boolean = shouldHideEmailSlackTab({
    accountStatus: cloudAccountStatus
  });

  if (isLoading || (emailReceipients && emailReceipients?.count > 0)) {
    return (
      <EmailList
        emailList={emailReceipients?.emailNotifications || []}
        emailListCount={emailReceipients?.count || 0}
        isLoading={isLoading}
        fetchEmailRecipients={refetchEmailReceipients}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    );
  }

  const tooltipText = !canUserAddEmailNotification
    ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_EMAIL_ADD_ACCESS
    : 'Email connection is not allowed when the Cloud account is in progress or has failed!';

  return (
    <NoDataScreen
      img={IMAGES.EmailBigLogo}
      heading="Integrate with Email"
      line1="We recommend to click on Add Email Recipients button below."
      line2="It will help you add emails to receive daily spends notification."
      disabled={!canUserAddEmailNotification || shouldHideEmailSlackTabs}
      tooltiptext={tooltipText}
      buttonText="Add Email Recipients"
      buttonWidth="214px"
      onClick={handleConnectToEmailsClick}
    />
  );
};

export default Email;
