import { useEffect } from 'react';
import * as axios from 'axios';

import { IUseUpdateTeamUserAccessControlState } from './useUpdateTeamUserAccessControlState.interface';

import { updateTeamMgmtUserPermissions } from '../../../store/actions';
import useGetRequest from '../../useGetRequest/useGetRequest';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { useAppDispatch, useAppSelector } from '../../redux/useRedux';
import { ITeamManagementUserInitialState } from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import useCheckUserPermissions from '../../cloudAccounts/checkUserPermissions/useCheckUserPermissions';

// Deprecated
const useUpdateTeamUserAccessControlState = ({
  callbackFunc
}: IUseUpdateTeamUserAccessControlState) => {
  // This hook updates the profile redux state of selected user permissions in team management
  const { selectedUser } = useAppSelector(
    (store) => store.teamManagementUser
  ) as ITeamManagementUserInitialState;

  const dispatch = useAppDispatch();

  // USER PERMISSIONS START
  const { canUserViewAnotherUser } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const getAccessControlCallback = async ({ response }: { response: axios.AxiosResponse }) => {
    if (response?.data?.user) {
      const { permissions } = response?.data?.user as ITeamManagementUserInitialState;
      if (permissions) {
        dispatch(updateTeamMgmtUserPermissions(permissions));
        if (callbackFunc) {
          callbackFunc(permissions);
        }
      } else {
        dispatch(updateTeamMgmtUserPermissions([]));
      }
    }
  };

  // Fetch user permissions only when user has permissions to view other user
  const { loading, getRequest } = useGetRequest({
    url: API_ENDPOINTS.TEAM_MGMT_GET_USER_ACCESS_CONTROL,
    params: { email: selectedUser },
    trigger: Boolean(selectedUser) && canUserViewAnotherUser,
    callback: getAccessControlCallback
  });

  useEffect(() => {
    if (canUserViewAnotherUser) {
      getRequest();
    }
  }, [canUserViewAnotherUser]);

  return { isLoading: loading };
};

export default useUpdateTeamUserAccessControlState;
