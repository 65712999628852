import { SET_SIGN_UP_VALUES, UPDATE_SIGN_UP_EMAIL } from './userAuth.actionTypes';

import { ISignUp } from '../../pages/SignUp/SignUp.types';

export const updateSignUpEmail = (state: string) => ({
  type: UPDATE_SIGN_UP_EMAIL,
  payload: state
});

export const setSignUpValues = (state: ISignUp) => ({
  type: SET_SIGN_UP_VALUES,
  payload: state
});
