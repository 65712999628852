import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';

type TCopyToClipboardIconProps = {
  color?: string;
} & React.HtmlHTMLAttributes<SVGElement>;

const CopyToClipboardIcon = ({
  color = BLACK_COLORS[800],
  ...props
}: TCopyToClipboardIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M12.5 6.5V4.4C12.5 3.55992 12.5 3.13988 12.3365 2.81901C12.1927 2.53677 11.9632 2.3073 11.681 2.16349C11.3601 2 10.9401 2 10.1 2H4.4C3.55992 2 3.13988 2 2.81901 2.16349C2.53677 2.3073 2.3073 2.53677 2.16349 2.81901C2 3.13988 2 3.55992 2 4.4V10.1C2 10.9401 2 11.3601 2.16349 11.681C2.3073 11.9632 2.53677 12.1927 2.81901 12.3365C3.13988 12.5 3.55992 12.5 4.4 12.5H6.5M8.9 17H14.6C15.4401 17 15.8601 17 16.181 16.8365C16.4632 16.6927 16.6927 16.4632 16.8365 16.181C17 15.8601 17 15.4401 17 14.6V8.9C17 8.05992 17 7.63988 16.8365 7.31901C16.6927 7.03677 16.4632 6.8073 16.181 6.66349C15.8601 6.5 15.4401 6.5 14.6 6.5H8.9C8.05992 6.5 7.63988 6.5 7.31901 6.66349C7.03677 6.8073 6.8073 7.03677 6.66349 7.31901C6.5 7.63988 6.5 8.05992 6.5 8.9V14.6C6.5 15.4401 6.5 15.8601 6.66349 16.181C6.8073 16.4632 7.03677 16.6927 7.31901 16.8365C7.63988 17 8.05992 17 8.9 17Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyToClipboardIcon;
