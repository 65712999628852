import * as Yup from 'yup';

import { EMAIL_REGX, PASSWORD_SPECIAL_CHARACTERS } from '../../constants/regex';

export const ForceChangePasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter an email address')
    .matches(EMAIL_REGX, 'Please enter valid email address'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .max(20, 'Maximum password length is 20 characters')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/\d/, 'Must contain at least one digit')
    .matches(PASSWORD_SPECIAL_CHARACTERS, 'Must contain at least one special character')
    .required('Enter the password'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Password did not match. Please try again.')
    .required('Confirm your new password')
});

export const passwordTooltipValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .matches(/[A-Z]/, 'One uppercase letter')
    .matches(/[a-z]/, 'One lowercase letter')
    .matches(/\d/, 'One numeral')
    .matches(PASSWORD_SPECIAL_CHARACTERS, 'One special character'),
  confirmPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .matches(/[A-Z]/, 'One uppercase letter')
    .matches(/[a-z]/, 'One lowercase letter')
    .matches(/\d/, 'One numeral')
    .matches(PASSWORD_SPECIAL_CHARACTERS, 'One special character')
});
