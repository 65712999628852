import { create } from 'zustand';

export type TAccountSummary = {
  hasAccounts?: boolean;
  accountConnectedThisMonth: number;
  accountDisconnectedThisMonth: number;
  totalActiveAccounts: number;
  totalInprogressAccounts: number;
  totalFailedUnreachableAccounts: number;
};

export type TAccountSummaryActions = {
  setAccountSummary: (summary: TAccountSummary) => void;
  setHasAccounts: (hasAccounts: boolean) => void; // New action
};

const useAccountSummaryStore = create<TAccountSummary & TAccountSummaryActions>((set) => ({
  hasAccounts: true,
  accountConnectedThisMonth: 0,
  accountDisconnectedThisMonth: 0,
  totalActiveAccounts: 0,
  totalInprogressAccounts: 0,
  totalFailedUnreachableAccounts: 0,

  setAccountSummary: (summary: TAccountSummary) =>
    set(() => ({
      accountConnectedThisMonth: summary.accountConnectedThisMonth,
      accountDisconnectedThisMonth: summary.accountDisconnectedThisMonth,
      totalActiveAccounts: summary.totalActiveAccounts,
      totalInprogressAccounts: summary.totalInprogressAccounts,
      totalFailedUnreachableAccounts: summary.totalFailedUnreachableAccounts
    })),
  setHasAccounts: (hasAccounts: boolean) => set(() => ({ hasAccounts }))
}));

export default useAccountSummaryStore;
