import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { ToastContainer, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import './index.css'; // import tailwind style

import App from './App';
import theme from './assets/styles/theme';
import { toastAutoCloseDuration } from './constants/constants';
import reportWebVitals from './reportWebVitals';
import store from './store';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const toastAnimation = cssTransition({
  enter: 'global_toast_message_enter_animation',
  exit: 'global_toast_message_exit_animation'
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <App />
          {/* Change this to true to see cached data of react-query  */}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        <ToastContainer
          hideProgressBar={true}
          position="top-center"
          autoClose={toastAutoCloseDuration}
          icon={false}
          draggable={false}
          transition={toastAnimation}
        />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
