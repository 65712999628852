import * as Yup from 'yup';

import { EMAIL_REGX } from '../../../constants/regex';

export const PersonalInfoValidation = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed for the first name')
    .required('Please enter the first name')
    .max(25, 'First Name should not exceed 25 characters'),
  lastName: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed for the last name')
    .required('Please enter the last name')
    .max(25, 'Last Name should not exceed 25 characters')
});

export const EmailJobTitleValidation = Yup.object().shape({
  jobTitle: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9 ]+$/, 'Only alphabets and numbers are allowed for job title')
    .min(2, 'The job title must be between 2 and 100 characters long')
    .max(100, 'The job title must be between 2 and 100 characters long')
    .required('Please enter the job title'),
  email: Yup.string()
    .required('Please enter an email address')
    .matches(EMAIL_REGX, 'Please enter valid email address')
    .trim()
});
