export interface INotificationSettingsAPIResponse {
  notificationSettings: NotificationSettings;
}

export interface NotificationSettings {
  cost: Anomaly;
  saving: Anomaly;
  anomaly: Anomaly;
  abbreviation?: string;
}
export interface IFetchNotificationSettingsResponse {
  notificationSettings: NotificationSettings;
  abbreviation?: string;
  timezone: string;
}

export interface Anomaly {
  notification: Notification;
  reports: Reports;
}

export interface Notification {
  slackNotification: InAppNotificationClass;
  teamsNotification: InAppNotificationClass;
  mailNotification: InAppNotificationClass;
  inAppNotification: InAppNotificationClass;
}

export interface InAppNotificationClass {
  frequency: Frequency;
  enabled: boolean;
  allowEdit: boolean;
  scheduledTime?: string;
}

export enum Frequency {
  Always = 'Always',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Empty = ''
}

export interface Reports {
  slackNotification: InAppNotificationClass;
  teamsNotification: InAppNotificationClass;
  mailNotification: InAppNotificationClass;
}
