import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Groups from './Groups';
import OwnerPermissionsView from './OwnerPermissionsView';
import Permissions from './Permissions';
import { IAccessControl } from './accessControl.interface';
import './accessControl.scss';

import IMAGES from '../../../assets';
import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { MY_PROFILE_TOOLTIP_MESSAGES } from '../../../configs/myProfile';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { routes } from '../../../constants/routes';
import getUserData from '../../../helpers/getUserData';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import { useAppSelector } from '../../../hooks/redux/useRedux';
import postWithAuth from '../../../services/postWithAuth';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import { TTeamMangEditUserTabs } from '../../../store/teamManagement/teamManagement.interface';
import { ITeamManagementUserInitialState } from '../../../store/teamManagement/teamManagementUser/teamManagementUser.interface';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import Tabs from '../../common/customTabs/CustomTabs';
import { TTabItem } from '../../common/customTabs/CustomTabs.interface';
import LockIcon from '../../common/icons/lockIcon/LockIcon';
import Loader from '../../common/loader/loader';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal from '../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import Typography from '../../common/typography/Typography';

const AccessControl = ({
  gropus,
  permissions,
  teamUserProfile,
  onEditControlButtonClick,
  loading
}: IAccessControl) => {
  const [tabType, setTabType] = useState<TTeamMangEditUserTabs>('groups');
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState<boolean>(false);
  const [openSelectModuleModal, setOpenSelectModuleModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { selectedUser, userProfileData } = useAppSelector(
    (store) => store.teamManagementUser
  ) as ITeamManagementUserInitialState;
  const myProfile: IMyProfileState = useAppSelector<IMyProfileState>((store) => store.MyProfile);
  // USER PERMISSIONS START
  const { canUserResetOtherUsersPassword } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const changeUserTab = (value: string) => {
    setTabType(value as TTeamMangEditUserTabs);
  };

  const accessControlItems: TTabItem[] = [
    { key: 'groups', text: 'Teams' },
    { key: 'permissions', text: 'Permissions' }
  ];

  const navigate = useNavigate();
  const { email } = getUserData();

  // Determining whether the user should have all the permissions
  const doesUserHasAllPermissions: boolean = useMemo(() => {
    if (teamUserProfile) {
      return Boolean(userProfileData.isOwner);
    } else {
      return Boolean(myProfile.isOwner);
    }
  }, [userProfileData.isOwner, myProfile.isOwner]);

  const tableComponents = {
    groups: (
      <Groups
        isLoading={loading}
        data={gropus}
        teamUserProfile={teamUserProfile}
        onEditControlButtonClick={onEditControlButtonClick}
      />
    ),
    permissions: doesUserHasAllPermissions ? (
      <OwnerPermissionsView /> // If the user is owner, show them a screen that lists all the permissions
    ) : (
      <Permissions
        data={permissions.length ? permissions : []} // This is protection against invalid data {} being data from backend apis
        teamUserProfile={teamUserProfile}
        onEditControlButtonClick={onEditControlButtonClick}
        openSelectModuleModal={openSelectModuleModal}
        setOpenSelectModuleModal={setOpenSelectModuleModal}
      />
    )
  };

  const handleResetPassword = async () => {
    // Reset password of user
    try {
      setIsLoading(true);
      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_RESET_PASSWORD,
        payload: { email: selectedUser }
      });

      if (response) {
        setIsResetPasswordModalOpen(false);
        setOpenConfirmModal(true);
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'resetPasswordFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => {
    navigate(routes.TEAM_MANAGEMENT_USERS_TABLE);
  };

  const isActionsAvailable =
    teamUserProfile && selectedUser !== email && userProfileData.isVerified;

  return (
    <div className="my-profile__access-control access-control">
      <div className="access-control__tabs">
        <Tabs items={accessControlItems} onChange={changeUserTab} activeItem={tabType} />
        {/* Logged in  user doesn't have the permission to have a action button */}
        {isActionsAvailable && (
          <TooltipCustom
            text={MY_PROFILE_TOOLTIP_MESSAGES.NO_RESET_OTHER_USER_PASSWORD_ACCESS}
            conditionToDisplay={teamUserProfile && !canUserResetOtherUsersPassword}
            containerProps={{ style: { width: 'initial' } }}
          >
            <div className="reset-pass-container">
              <button
                className="reset-password w-[185px]"
                onClick={() => setIsResetPasswordModalOpen(true)}
                disabled={!canUserResetOtherUsersPassword}
              >
                <LockIcon strokeColor="white" />
                <Typography variant="body-3" size="medium" as="span">
                  {TEAM_MANAGEMENT_TEXT.RESET_PASSWORD}
                </Typography>
              </button>
            </div>
          </TooltipCustom>
        )}
      </div>
      {tableComponents[tabType]}

      <RestoreArchiveUserModal
        heading="Reset Password?"
        open={isResetPasswordModalOpen}
        actionButtonText={isLoading ? <Loader /> : 'Confirm'}
        child="Do you wish to reset this user’s password? A temporary password will be sent to this user's email."
        icon={IMAGES.ErrorExclamation}
        handlerFun={handleResetPassword}
        handleClose={() => setIsResetPasswordModalOpen(false)}
      />
      <ConfirmModal
        title={TEAM_MANAGEMENT_TEXT.TEMPORARY_PASSWORD_SENT}
        description={TEAM_MANAGEMENT_TEXT.TEMPORARY_PASSWORD_SENT_TO_EMAIL}
        confirmButtonText={BUTTON_TEXT.OKAY}
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        image={confirmSuccess}
        handleOkClick={handleConfirm}
      />
    </div>
  );
};

export default AccessControl;
