import { CloudAccountStatus } from '@cloudinspot/cis-common-constants';

const shouldHideEmailSlackTab = ({ accountStatus }: { accountStatus: string }) => {
  // Cloud account is not active? -> hide both slack and email tabs
  const shouldHideEmailSlackTabResult: boolean = accountStatus !== CloudAccountStatus.Active;

  return shouldHideEmailSlackTabResult;
};

export default shouldHideEmailSlackTab;
