import { useMemo } from 'react';
import { useQuery } from 'react-query';

import NotificationCard from './notificationCard/NotificationCard';
import './notifications.scss';

import { routes } from '../../../constants/routes';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { getNotificationSettings } from '../../../services/organization.service';
import { notificationInitialState } from '../../../store/settings/settings.reducer';

const Notifications = () => {
  // USER PERMISSIONS START
  const { canUserEditNotifications } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const {
    isLoading,
    data: notificationSettings,
    refetch: refetchNotificationSetting
  } = useQuery(['notificationSettings'], () => getNotificationSettings(), {
    staleTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });

  const { cost, saving } = useMemo(() => {
    const { cost, saving, anomaly } =
      notificationSettings?.notificationSettings || notificationInitialState;
    return { cost, saving, anomaly };
  }, [
    notificationSettings?.notificationSettings.anomaly,
    notificationSettings?.notificationSettings.cost,
    notificationSettings?.notificationSettings.saving
  ]);
  useUpdateBreadcrumbs({
    breadcrumbs: [
      {
        text: 'Settings',
        url: routes.SETTINGS_ORGANIZATION
      },
      {
        text: 'Notification',
        url: ''
      }
    ]
  });

  return (
    <>
      <NotificationCard
        refetchNotificationSetting={refetchNotificationSetting}
        abbreviation={notificationSettings?.abbreviation || ''}
        orgTimeZone={notificationSettings?.timezone || ''}
        data={saving}
        nameOfCard="Savings Notifications"
        descriptionOfCard="Elevate your savings game! Get instant notifications via Slack, MS Teams, email, and in-app alerts – your personalized roadmap to financial success, one update at a time."
        isLoading={isLoading}
        cardKey={'saving'}
        key={'saving'}
        canUserEditNotifications={canUserEditNotifications}
      />
      <NotificationCard
        refetchNotificationSetting={refetchNotificationSetting}
        abbreviation={notificationSettings?.abbreviation || ''}
        orgTimeZone={notificationSettings?.timezone || ''}
        data={cost}
        nameOfCard="Spends Notifications"
        descriptionOfCard="Maximize your spending strategy! Receive instant notifications through Slack, MS Teams, email, and in-app alerts – your key to staying savvy with every transaction and making the most of your cloud cost journey."
        isLoading={isLoading}
        cardKey={'cost'}
        key={'cost'}
        canUserEditNotifications={canUserEditNotifications}
      />

      {/* <NotificationCard     //Todo : We are not using Anomaly in this version, so we are commenting it out. Once required, we will uncomment it and implement.
        refetchNotificationSetting={refetchNotificationSetting}
        abbreviation={notificationSettings?.abbreviation || ''}
        orgTimeZone={notificationSettings?.timezone || ''}
        data={anomaly}
        nameOfCard="Anomaly"
        descriptionOfCard="Stay ahead of the unexpected! Receive anomaly alerts effortlessly through Slack, MS Teams, email, and in-app notifications – ensuring your cloud cost is secure and seamless, one vigilant update at a time."
        isLoading={isLoading}
        cardKey={'anomaly'}
        key={'anomaly'}
        canUserEditNotifications={canUserEditNotifications}
      /> */}
    </>
  );
};

export default Notifications;
