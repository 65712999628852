import { IGetAuthTokens, IUserInLocalStorage } from './service.interface';

export const getAuthTokens = (): IGetAuthTokens => {
  const user: IUserInLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
  const authInfo = JSON.parse(localStorage.getItem('authInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');

  // Using auth-info for access token and id-token, they will be updated post sign-in and access token refresh.
  const Authorization = authInfo?.AccessToken;
  const idToken = authInfo?.IdToken;
  const organizationId = organization?.organizationId;
  const refreshToken = user?.data?.AuthenticationResult?.RefreshToken;
  const email = user?.data?.user?.email;

  return {
    Authorization,
    idToken,
    organizationId,
    refreshToken,
    email
  };
};

export const getAPIBaseUrl = (): string | undefined => {
  return process.env.REACT_APP_API_BASE_URL;
};

export const getCloudBaseUrl = (): string | undefined => {
  return process.env.REACT_APP_CLOUD_BASE_URL;
};

export const getApiMSTeamsUrl = (): string | undefined => {
  return process.env.REACT_APP_API_MSTEAMS_BASE_URL;
};

export const generateURL = (url: string) => {
  const BASE_URL = getAPIBaseUrl();
  return BASE_URL + url;
};
