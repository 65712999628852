import axios from 'axios';
import { Dispatch } from 'react';

import {
  FETCH_MY_PROFILE_DATA,
  FETCH_USER_PERMISSIONS,
  UPDATE_PROFILE_LOADING_STATE
} from './myProfile.actionTypes';
import { IMyProfileAction, IMyProfileState } from './myProfile.interface';

import { IUserProfileData } from '../../pages/myProfile/myProfile.interface';
import API_ENDPOINTS from '../../constants/api_endpoints';
import getUserData from '../../helpers/getUserData';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import getWithAuth from '../../services/getWithAuth';

export const fetchMyProfile = (state: IUserProfileData) => ({
  type: FETCH_MY_PROFILE_DATA,
  payload: state
});

export const fetchuserPermissions = (state: IUserProfileData) => ({
  type: FETCH_USER_PERMISSIONS,
  payload: state
});

export const updateProfileLoading = (state: boolean) => ({
  type: UPDATE_PROFILE_LOADING_STATE,
  payload: state
});

export const checkProfileImageValidity = async ({ url }: { url: string }) => {
  let response = false;
  try {
    await axios.get(url);
    response = true;
  } catch {
    response = false;
  }
  return response;
};

export const fetchMyProfileRequest = ({ callbackFunc }: { callbackFunc?: () => void }) => {
  const userData = getUserData();
  return async (dispatch: Dispatch<IMyProfileAction>) => {
    try {
      const response = await getWithAuth({
        url: API_ENDPOINTS.GET_USER,
        params: { email: userData?.email }
      });

      const data = response?.data?.data as IMyProfileState;

      // to check whether the profile image is valid.
      const isProfileImageValid = await checkProfileImageValidity({ url: data.url });

      data['isProfileImageValid'] = isProfileImageValid;

      if (data) {
        dispatch(fetchMyProfile(data));
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'getUserFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      if (callbackFunc) {
        callbackFunc();
      }
    }
  };
};

export const fetchUserPermissionsRequest = () => {
  const userData = getUserData();
  return async (dispatch: Dispatch<IMyProfileAction>) => {
    try {
      const permissionsReponse = await getWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_GET_USER_ACCESS_CONTROL,
        params: { email: userData?.email }
      });

      const permissions = permissionsReponse?.data?.user?.permissions;

      if (permissions) {
        dispatch(fetchuserPermissions(permissions));
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'getUserFail',
        defaultMessage:
          'Your request to fetch permissions failed due to unknown reasons! Please try again later'
      });
    }
  };
};
