import { Dispatch } from 'react';

import {
  CLEAR_CLOUD_ACCOUNTS,
  CLEAR_SLACK_CONNECTION_DETAILS,
  CLOUD_ACCOUNTS_UPDATE_EMAILS_FOR_REMOVAL,
  CLOUD_ACCOUNTS_UPDATE_EMAIL_LIST,
  FETCH_CLOUD_ACCOUNTS_DATA,
  FETCH_CLOUD_ACCOUNTS_DETAILS_DATA,
  FETCH_CLOUD_POLICIES,
  GET_SLACK_CHANNEL_LIST,
  SET_CONNECT_TO_AWS_DATA,
  SET_CONNECT_TO_AZURE_DATA,
  SET_IS_SLACK_FOUND,
  SET_IS_SLACK_INSTALLED,
  SET_SLACK_CHANNEL_NAME,
  SET_SLACK_CONNECTION_DETAILS,
  SET_SLACK_REDIRECTION_URL,
  SET_TAB_INSIDE_ACCOUNT_VIEW,
  UPDATE_ACCOUNT_INFO_EDIT_STATE,
  UPDATE_IF_CLOUD_ACCOUNTS_AVALIABLE
} from './cloudAccounts.actionTypes';
import {
  ICloudAccountDetails,
  ICloudAccountDetailsAction,
  ICloudAccountsAction,
  ICloudAccountsEmailList,
  ICloudPolicies,
  ICloudPoliciesAction,
  IConnectToAwsData,
  IConnectToAzureData,
  IFetchCloudAccountDetailsRequest,
  IFetchCloudAccountsRequest,
  IFetchCloudAccountsResponse,
  IFetchSlackAccountDetailsParams,
  ISlackConnectionDetails
} from './cloudAccounts.interface';

import { IListSlackChannelsAPI } from '../../components/cloudAccounts/accountView/slack/reconfigureSlack.interface';
import { ICloudAccountsTableResponse } from '../../components/cloudAccounts/cloudAccounts.interface';
import API_ENDPOINTS from '../../constants/api_endpoints';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import getWithAuthCloud from '../../services/cloudAccounts/getWithCloudAuth';
import { sortObjectArray } from '../../utils';
import { TSortObject } from '../../utils/sortObjectArray/sortObjectArray.interface';
import { updateLoadingStatusAction } from '../actions';
import { IUpdateLoadingStatusaction } from '../common/common.interface';

export const fetchCloudAccountsData = (state: IFetchCloudAccountsResponse) => ({
  type: FETCH_CLOUD_ACCOUNTS_DATA,
  payload: state
});

export const fetchCloudAccountDetailsData = (state: ICloudAccountDetails) => ({
  type: FETCH_CLOUD_ACCOUNTS_DETAILS_DATA,
  payload: state
});

export const fetchCloudPolicies = (state: ICloudPolicies) => ({
  type: FETCH_CLOUD_POLICIES,
  payload: state
});

export const fetchCloudAccountsRequest = ({ params, callbackFunc }: IFetchCloudAccountsRequest) => {
  return async (dispatch: Dispatch<ICloudAccountsAction | IUpdateLoadingStatusaction>) => {
    try {
      const response = await getWithAuthCloud({
        url: API_ENDPOINTS.CLOUD_ACCOUNTS_LIST,
        params
      });

      const data: IFetchCloudAccountsResponse = response?.data;

      if (data) {
        const { count, accounts } = data;

        // Sorting based on update time, latest to oldest
        const accountsSortedByTime = sortObjectArray({
          arr: accounts as unknown as TSortObject[],
          key: 'updatedAt',
          sortType: 'timeDesc'
        });

        const hasAccounts = accounts.length > 0;
        localStorage.setItem('hasAccounts', hasAccounts.toString());
        const refetchItems = Boolean(accounts.find((item) => item.status === 'InProgress'));
        const hasStringFilter = Boolean(params.filterAccount);
        dispatch(
          fetchCloudAccountsData({
            count,
            accounts: accountsSortedByTime as unknown as ICloudAccountsTableResponse[],
            refetchItems,
            // hasAccounts represent whether there are any users in the whole team management at all.
            ...(hasAccounts && { hasAccounts: true }),
            ...(params.offset === 1 && !hasStringFilter && { hasAccounts: Boolean(hasAccounts) }), // when fetching for 1st page and and search string is empty, this line can set hasAccounts to false. useful when user deletes all the cloud accounts.
            accountConnectedThisMonth: data.accountConnectedThisMonth,
            accountDisconnectedThisMonth: data.accountDisconnectedThisMonth,
            totalActiveAccounts: data.totalActiveAccounts,
            totalInprogressAccounts: data.totalInprogressAccounts,
            totalFailedUnreachableAccounts: data.totalFailedUnreachableAccounts
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'getCloudAccountsFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      dispatch(updateLoadingStatusAction({ isLoading: false }));
      if (callbackFunc) {
        callbackFunc();
      }
    }
  };
};

export const fetchCloudAccountDetailsRequest = ({
  accountId,
  startLoading,
  stopLoading
}: IFetchCloudAccountDetailsRequest) => {
  return async (dispatch: Dispatch<ICloudAccountDetailsAction>) => {
    if (startLoading) {
      startLoading();
    }
    try {
      const response = await getWithAuthCloud({
        url: API_ENDPOINTS.CLOUD_GET_ACCOUNT,
        params: {
          accountId
        }
      });

      const data: ICloudAccountDetails = response?.data?.account;

      if (data) {
        dispatch(fetchCloudAccountDetailsData(data));
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'getCloudAccountDataFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      if (stopLoading) {
        stopLoading();
      }
    }
  };
};

export const fetchCloudPoliciesRequest = () => {
  return async (dispatch: Dispatch<ICloudPoliciesAction>) => {
    try {
      const response = await getWithAuthCloud({
        url: API_ENDPOINTS.CLOUD_ACCOUNT_POLICIES
      });

      const data: ICloudPolicies = response?.data;

      if (data) {
        dispatch(fetchCloudPolicies(data));
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'getCloudAccountDataFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    }
  };
};

export const setActiveTabInsideAccountView = (state: string) => ({
  type: SET_TAB_INSIDE_ACCOUNT_VIEW,
  payload: state
});

export const updateAccountInfoEditState = (state: boolean) => ({
  type: UPDATE_ACCOUNT_INFO_EDIT_STATE,
  payload: state
});

export const setSlackConnectionDetails = (data: ISlackConnectionDetails) => ({
  type: SET_SLACK_CONNECTION_DETAILS,
  payload: data
});

export const fetchSlackConnectionDetails = ({
  accountId,
  startLoading,
  stopLoading
}: IFetchSlackAccountDetailsParams) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  return async (dispatch: Dispatch<ICloudAccountsAction | any>) => {
    if (startLoading) startLoading();
    try {
      const response = await getWithAuthCloud({
        url: API_ENDPOINTS.CLOUD_GET_SLACK_CONNECTION_DETAILS,
        params: {
          accountId
        }
      });

      const data = response?.data;

      if (!data?.slackAccountMapping) {
        //set slack no channelFound and return
        dispatch(setIsSlackChannelFound(false));
        dispatch(setIsSlackInstalled(true));
        return;
      }

      if (data) {
        dispatch(setIsSlackChannelFound(true));
        dispatch(setIsSlackInstalled(true));
        const slackData: ISlackConnectionDetails = {
          accountId: data?.account.accountId,
          awsAccountName: data?.account.accountName,
          channelName: data?.slackAccountMapping?.slackChannelMapping?.channelName,
          status: data?.slackAccountMapping?.status,
          connectBy:
            `${data?.slackAccountMapping?.slackMappingCreatedBy?.firstName} ` +
            `${data?.slackAccountMapping?.slackMappingCreatedBy?.lastName}`,
          connectedOn: data?.slackAccountMapping?.createdAt,
          workspaceName: data?.slackAccountMapping?.workspaceName
        };
        dispatch(setSlackConnectionDetails(slackData));
      }
    } catch (error) {
      dispatch(setIsSlackChannelFound(false));
      dispatch(setIsSlackInstalled(false));
    } finally {
      if (stopLoading) stopLoading();
    }
  };
};

export const setChannelsList = (state: IListSlackChannelsAPI[]) => ({
  type: GET_SLACK_CHANNEL_LIST,
  payload: state
});

export const setSlackRedirectionURL = (state: string) => ({
  type: SET_SLACK_REDIRECTION_URL,
  payload: state
});

export const clearCloudAccounts = () => ({
  type: CLEAR_CLOUD_ACCOUNTS
});

export const setSlackName = (state: string) => ({
  type: SET_SLACK_CHANNEL_NAME,
  payload: state
});

export const setIsSlackChannelFound = (state: boolean) => ({
  type: SET_IS_SLACK_FOUND,
  payload: state
});

export const clearSlackConnectionDetails = () => ({
  type: CLEAR_SLACK_CONNECTION_DETAILS
});
export const updateCloudAccountsEmailsForRemoval = (state: string[]) => ({
  type: CLOUD_ACCOUNTS_UPDATE_EMAILS_FOR_REMOVAL,
  payload: state
});

export const updateCloudAccountsEmailList = (state: {
  emailList: ICloudAccountsEmailList[];
  emailListCount: number;
}) => ({
  type: CLOUD_ACCOUNTS_UPDATE_EMAIL_LIST,
  payload: state
});

export const setIsSlackInstalled = (state: boolean) => ({
  type: SET_IS_SLACK_INSTALLED,
  payload: state
});

export const setConnectToAWSData = (state: IConnectToAwsData) => ({
  type: SET_CONNECT_TO_AWS_DATA,
  payload: state
});

export const setConnectToAzureData = (state: IConnectToAzureData) => ({
  type: SET_CONNECT_TO_AZURE_DATA,
  payload: state
});

export const setIfCloudAccountExist = (payload: boolean) => ({
  type: UPDATE_IF_CLOUD_ACCOUNTS_AVALIABLE,
  payload
});
