import { Modal } from '@mui/material';
import { Country, State } from 'country-state-city';
import React, { useEffect, useMemo, useState } from 'react';
import { ValidationError } from 'yup';

import ErrorMessage from './ErrorMessage';
import { errorsInitialState, formValuesInitialState, touchedInitialState } from './initialStates';
import './userOnboarding.scss';
import {
  ICountry,
  ITimezoneOptions,
  IUserOnboardingInterface,
  IUserOnboardingProps
} from './userOnboarding.types';
import useruserOnboardingStepTwoValidationSchema, {
  useruserOnboardingStepOneValidationSchema
} from './userOnboarding.validationSchema';

import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import Footer from '../../components/common/footer/Footer';
import CheckboxIcon from '../../components/common/icons/checkboxIcon/checkboxIcon';
import CisLogoIcon from '../../components/common/icons/cisLogoIcon/CisLogoIcon';
import Loader from '../../components/common/loader/loader';
import SearchAndSelect from '../../components/common/molecules/searchAndSelect/SearchAndSelect';
import { TFieldItem } from '../../components/common/molecules/searchAndSelect/searchAndSelect.interface';
import Typography from '../../components/common/typography/Typography';
import '../../components/user-authentication/UserAuth.css';
import { BUTTON_TEXT, LABEL_TEXT } from '../../configs/form';
import { USERAUTH_HEADING } from '../../configs/heading';
import { colorConstants } from '../../configs/styleConstants';
import API_ENDPOINTS from '../../constants/api_endpoints';
import useUserData from '../../helpers/getUserData';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import useFetchMyProfile from '../../hooks/myProfile/fetchMyProfile/useFetchMyProfile';
import useFetchOrganizationDetails from '../../hooks/orgDetails/useFetchOrganizationDetails';
import { useAppDispatch } from '../../hooks/redux/useRedux';
import useTimezones from '../../hooks/userAuthentication/timezones/useTimezones';
import postWithAuth from '../../services/postWithAuth';
import { checkUserDataInLocalStorage } from '../../store/actions';
import { removeDuplicatesFlatArray } from '../../utils';
import timezoneConverter from '../../utils/timezoneConverter/timezoneConverter';
import { trimPayload } from '../../utils/trimString/trimString';

type TFormSteps = 1 | 2;

const NOT_APPLICABLE = 'Not Applicable';

const UserOnboarding = ({ open }: IUserOnboardingProps) => {
  // React state
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [initialValues, setInitialValues] =
    useState<IUserOnboardingInterface>(formValuesInitialState);
  const [errors, setErrors] =
    useState<Record<keyof IUserOnboardingInterface, string[]>>(errorsInitialState);
  const [touched, setTouched] =
    useState<Record<keyof IUserOnboardingInterface, boolean>>(touchedInitialState);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [activeSearchModal, setActiveSearchModal] = useState<string | boolean>('');
  const [currentFormStep, setCurrentFormStep] = useState<TFormSteps>(1);

  const dispatch = useAppDispatch();
  const defaultTimezones = useTimezones();
  const { refetchOrganization } = useFetchOrganizationDetails();
  const { refetch: refetchProfile } = useFetchMyProfile();

  const validateErrors = ({
    updateTouched = false,
    validationSchema
  }: {
    updateTouched?: boolean;
    validationSchema:
      | typeof useruserOnboardingStepTwoValidationSchema
      | typeof useruserOnboardingStepOneValidationSchema;
  }) => {
    const errors = validationSchema
      .validate(initialValues, { abortEarly: false })
      .then(() => {
        // then block runs when there are no errors
        // So the errors are reset to initial state here
        setErrors(errorsInitialState);
        return [];
      })
      .catch((validationError) => {
        const errors: Record<string, string[]> = {};

        validationError.inner.forEach((error: ValidationError) => {
          const field = error.path;
          if (field) {
            errors[field as string] = [error.message];
            if (updateTouched) {
              setTouched((prev) => ({ ...prev, [field]: true }));
            }
          }
        });

        setErrors(errors);
        return validationError.errors;
      });

    return errors;
  };

  const updatedTouchedFields = ({ field }: { field: keyof IUserOnboardingInterface }) => {
    validateErrors({
      validationSchema: useruserOnboardingStepTwoValidationSchema.concat(
        useruserOnboardingStepOneValidationSchema
      )
    });
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const updateInitialValues = ({
    field,
    value
  }: {
    field: keyof IUserOnboardingInterface;
    value: string | number;
  }) => {
    setInitialValues((prev) => ({ ...prev, [field]: value }));
  };

  const updateGeographyFields = ({
    field,
    selectedOption
  }: {
    field: keyof IUserOnboardingInterface;
    selectedOption: TFieldItem;
  }) => {
    updateInitialValues({ field, value: selectedOption.name });

    switch (field) {
      case 'country': {
        setSelectedCountry(selectedOption as ICountry);
        break;
      }
      default: {
        break;
      }
    }
  };

  const { currencies, countries, states, timezones } = useMemo(() => {
    const currencies = removeDuplicatesFlatArray({
      arr: Country.getAllCountries().map((item) => item.currency)
    }) as string[];

    const country = Country.getAllCountries().find((item) => item.name === initialValues.country);

    const states = State.getStatesOfCountry(selectedCountry?.isoCode) || [];

    let timezones: ITimezoneOptions[] = defaultTimezones;
    if (country) {
      // If a country is selected from the existing list,
      // Only show the timezones of the country.
      if (country?.timezones) {
        const countryTimezones = timezoneConverter(country?.timezones);
        updateInitialValues({ field: 'timezone', value: countryTimezones[0]?.name || '' });
        // Assigning country timezones and sorting timezones in the increasing order of GMT.
        timezones = countryTimezones.sort((a, b) => (a?.gmtOffset < b?.gmtOffset ? -1 : +1));
      }
      if (country?.currency) {
        updateInitialValues({ field: 'currency', value: country.currency });
      }
    }

    if (country && states?.length < 1) {
      // When the selected country has no States, changes the State value to 'Not Applicable'
      updateInitialValues({ field: 'state', value: 'Not Applicable' });
    }

    if (states?.length > 1) {
      // When the selected country has at-least one State.
      updateInitialValues({ field: 'state', value: '' });
    }

    return {
      currencies: currencies.map((item) => ({ name: item, code: item })),
      countries: Country.getAllCountries(),
      states,
      timezones
    };
  }, [initialValues.country, selectedCountry?.name]);

  const continueToStepTwo = async () => {
    const validationErrors = await validateErrors({
      updateTouched: true,
      validationSchema: useruserOnboardingStepOneValidationSchema
    });

    if (!validationErrors.length) {
      setCurrentFormStep(2);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      country,
      state,
      organizationName,
      jobTitle,
      currency,
      timezone
    }: IUserOnboardingInterface = initialValues;

    const validationErrors = await validateErrors({
      updateTouched: true,
      validationSchema: useruserOnboardingStepTwoValidationSchema.concat(
        useruserOnboardingStepOneValidationSchema
      )
    });

    if (!validationErrors.length) {
      try {
        // function to handle user submission
        const userData = useUserData();

        setIsSubmitting(true);

        const selectedTimezone = timezones.find((tz) => tz.name === timezone) || null;

        if (userData?.email) {
          const payload = {
            email: userData.email,
            firstName: firstName,
            lastName: lastName,
            organizationName: organizationName,
            jobTitle: jobTitle,
            currency: currency,
            country: country,
            state,
            timezone: timezone,
            ...(selectedTimezone && {
              abbreviation: selectedTimezone?.abbreviation,
              gmtOffsetName: selectedTimezone?.gmtOffsetName
            })
          };
          const trimmedPayload = trimPayload(payload);

          const response = await postWithAuth({
            url: API_ENDPOINTS.SAVE_OWNER_DETAILS,
            payload: trimmedPayload
          });

          if (response.status === 200) {
            localStorage.setItem(
              'userData',
              JSON.stringify({ firstName, lastName, email: userData.email })
            );
            refetchOrganization();
            refetchProfile();
            dispatch(checkUserDataInLocalStorage(true));
          }
        }
      } catch (error) {
        useErrorHandler({ error, toastId: 'OwnerDataAPIFail' });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    validateErrors({
      validationSchema: useruserOnboardingStepTwoValidationSchema.concat(
        useruserOnboardingStepOneValidationSchema
      )
    });
  }, [initialValues]);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex user-onboarding-modal-container"
      >
        <div className="user-onboarding">
          <div className="user-onboarding__logo">
            <CisLogoIcon
              primaryColor={colorConstants.white}
              secondaryColor={colorConstants.white}
            />
          </div>
          <div className="bg-white rounded-[5px] outline-none user-onboarding-inner">
            <Typography
              variant="subHeading-1"
              size="medium"
              data-testid="heading"
              className="text-normal_text user-onboarding-inner__heading"
            >
              {USERAUTH_HEADING.USER_ONBOARDING}
            </Typography>
            <div className="user-onboarding-inner__steps">
              <div
                className={`user-onboarding-inner__steps__step ${
                  currentFormStep === 1 ? 'user-onboarding-inner__steps__step--is-active' : ''
                } ${
                  currentFormStep === 2 ? 'user-onboarding-inner__steps__step--is-complete' : ''
                }`}
                data-label={LABEL_TEXT.INFORMATION}
              >
                {currentFormStep === 1 ? <p>1</p> : <CheckboxIcon />}
              </div>
              {/* hidden as per suggestion from Shalabh */}
              <hr className="invisible"></hr>
              <div
                className={`user-onboarding-inner__steps__step ${
                  currentFormStep === 2 ? 'user-onboarding-inner__steps__step--is-active' : ''
                }`}
                data-label={LABEL_TEXT.ADDITIONAL_INFORMATION}
              >
                2
              </div>
            </div>
            <form
              className="form gap-[10px] space-y-[5px] !ps-0 !pe-0 user-onboarding-inner__form"
              onSubmit={handleSubmit}
            >
              {currentFormStep === 1 && (
                <>
                  {/* Name block starts */}
                  <div className="flex flex-row justify-between user-onboarding-inner__form__row">
                    {/* First name */}
                    <div className="user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="first-name"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                        as="label"
                      >
                        {LABEL_TEXT.FIRST_NAME}
                      </Typography>
                      <input
                        type="text"
                        id="first-name"
                        data-testid="first-name"
                        name="firstName"
                        placeholder="First Name"
                        className="input_fields placeholder:text-placeholder_text text-normal_text user-onboarding-inner__form__row__field__input"
                        value={initialValues.firstName}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (inputValue.length <= 25) {
                            updateInitialValues({ field: 'firstName', value: inputValue });
                          }
                        }}
                      />
                      <ErrorMessage error={errors.firstName} touched={touched.firstName} />
                    </div>
                    {/* Last name */}
                    <div className="user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="last-name"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.LAST_NAME}
                      </Typography>
                      <input
                        type="text"
                        id="last-name"
                        data-testid="last-name"
                        name="lastName"
                        placeholder="Last Name"
                        className="input_fields placeholder:text-placeholder_text text-normal_text user-onboarding-inner__form__row__field__input"
                        value={initialValues.lastName}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (inputValue.length <= 25) {
                            updateInitialValues({ field: 'lastName', value: inputValue });
                          }
                        }}
                      />
                      <ErrorMessage error={errors.lastName} touched={touched.lastName} />
                    </div>
                  </div>
                  {/* Name block ends */}

                  {/* Organization block starts */}
                  <div className="flex flex-row justify-between user-onboarding-inner__form__row">
                    {/* Organisation name */}
                    <div className="user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="organization-name"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.ORGANIZATION_NAME}
                      </Typography>
                      <input
                        type="text"
                        id="organization-name"
                        data-testid="organization-name"
                        name="organizationName"
                        placeholder="Organization Name"
                        className="input_fields placeholder:text-placeholder_text text-normal_text user-onboarding-inner__form__row__field__input"
                        value={initialValues.organizationName}
                        onChange={(e) =>
                          updateInitialValues({
                            field: 'organizationName',
                            value: e.target.value
                          })
                        }
                      />
                      <ErrorMessage
                        error={errors.organizationName}
                        touched={touched.organizationName}
                      />
                    </div>

                    {/* Job Title */}
                    <div className="user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="job-title"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.JOB_TITLE}
                      </Typography>
                      <input
                        type="text"
                        id="job-title"
                        data-testid="job-title"
                        name="jobTitle"
                        placeholder="Job Title"
                        className="input_fields placeholder:text-placeholder_text text-normal_text user-onboarding-inner__form__row__field__input"
                        value={initialValues.jobTitle}
                        onChange={(e) =>
                          updateInitialValues({ field: 'jobTitle', value: e.target.value })
                        }
                      />
                      <ErrorMessage error={errors.jobTitle} touched={touched.jobTitle} />
                    </div>
                  </div>
                  {/* Organization block ends */}
                  <CustomButton
                    variant={ButtonVariants.PRIMARY}
                    type="button"
                    text={
                      <Typography variant="body-3" size="medium" as="p">
                        {BUTTON_TEXT.CONTINUE}
                      </Typography>
                    }
                    className="user-onboarding-inner__form__button user-onboarding-inner__form__step-one-continue"
                    onClick={continueToStepTwo}
                  />
                </>
              )}

              {currentFormStep === 2 && (
                <>
                  {/* Geography block starts */}
                  <div className="flex flex-row justify-between user-onboarding-inner__form__row">
                    {/* Country */}
                    <div className="lg:mr-[20px] relative user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="country"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.COUNTRY}
                      </Typography>
                      <SearchAndSelect
                        activeSearchModal={activeSearchModal}
                        setActiveSearchModal={setActiveSearchModal}
                        id="country"
                        name="country"
                        testid="country"
                        placeholder="Select Country"
                        searchPlaceholder="Search Your Country"
                        fieldItems={countries}
                        value={initialValues.country}
                        onInputChangeFunc={updateInitialValues}
                        onSelectChangeFunc={updateGeographyFields}
                        onBlur={() => updatedTouchedFields({ field: 'country' })}
                        bottom={errors.country?.length ? '30px' : '10px'}
                      />
                      <ErrorMessage error={errors.country} touched={touched.country} />
                    </div>

                    {/* State */}
                    <div className="relative user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="country"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.STATE}
                      </Typography>
                      <SearchAndSelect
                        activeSearchModal={activeSearchModal}
                        setActiveSearchModal={setActiveSearchModal}
                        id="state"
                        name="state"
                        testid="state"
                        placeholder="Select State"
                        searchPlaceholder="Search Your State"
                        fieldItems={states}
                        value={initialValues.state}
                        onInputChangeFunc={updateInitialValues}
                        onSelectChangeFunc={updateGeographyFields}
                        key={selectedCountry?.name}
                        onBlur={() => updatedTouchedFields({ field: 'state' })}
                        bottom={errors.state?.length ? '30px' : '10px'}
                        disabled={initialValues.state === NOT_APPLICABLE}
                      />
                      <ErrorMessage error={errors.state} touched={touched.state} />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between user-onboarding-inner__form__row">
                    {/* Currency */}
                    <div className="lg:mr-[20px] relative user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="currency"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.CURRENCY}
                      </Typography>
                      <SearchAndSelect
                        activeSearchModal={activeSearchModal}
                        setActiveSearchModal={setActiveSearchModal}
                        id="currency"
                        name="currency"
                        testid="currency"
                        placeholder="Select Currency"
                        searchPlaceholder="Search Your Currency"
                        fieldItems={currencies}
                        value={initialValues.currency}
                        onInputChangeFunc={updateInitialValues}
                        onSelectChangeFunc={updateGeographyFields}
                        onBlur={() => updatedTouchedFields({ field: 'currency' })}
                        bottom={errors.currency?.length ? '30px' : '10px'}
                      />
                      <ErrorMessage error={errors.currency} touched={touched.currency} />
                    </div>

                    {/* Timezone */}
                    <div className="relative user-onboarding-inner__form__row__field">
                      <Typography
                        variant="body-3"
                        size="regular"
                        htmlFor="timezone"
                        className="label_required text-normal_text user-onboarding-inner__form__row__field__label"
                      >
                        {LABEL_TEXT.TIMEZONE}
                      </Typography>
                      <SearchAndSelect
                        activeSearchModal={activeSearchModal}
                        setActiveSearchModal={setActiveSearchModal}
                        id="timezone"
                        name="timezone"
                        testid="timezone"
                        placeholder="Select Timezone"
                        searchPlaceholder="Search Your Timezone"
                        fieldItems={timezones}
                        value={initialValues.timezone}
                        onInputChangeFunc={updateInitialValues}
                        onSelectChangeFunc={updateGeographyFields}
                        key={`${timezones.length}${selectedCountry?.name}`}
                        onBlur={() => updatedTouchedFields({ field: 'timezone' })}
                        bottom={errors.timezone?.length ? '30px' : '10px'}
                      />
                      <ErrorMessage error={errors.timezone} touched={touched.timezone} />
                    </div>
                  </div>
                  <div className="user-onboarding-inner__form__buttons">
                    <CustomButton
                      variant={ButtonVariants.SECONDARY}
                      type="button"
                      text={BUTTON_TEXT.BACK}
                      className="user-onboarding-inner__form__button user-onboarding-inner__form__step-two-back"
                      onClick={() => setCurrentFormStep(1)}
                    />
                    <CustomButton
                      variant={ButtonVariants.PRIMARY}
                      disabled={!touched.country}
                      type="submit"
                      data-testid="submit"
                      text={isSubmitting ? <Loader classes="m-auto" /> : BUTTON_TEXT.SUBMIT}
                      className="user-onboarding-inner__form__button user-onboarding-inner__form__submit"
                    />
                  </div>
                </>
              )}
              {/* Geography block ends */}
            </form>
          </div>
          <div className="user-onboarding__footer">
            <Footer />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserOnboarding;
