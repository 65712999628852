import React, { useEffect } from 'react';

import { useAppDispatch } from '../../hooks/redux/useRedux';
import { setHeading, setActiveTab } from '../../store/actions';

const Feedback = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setHeading('Submit Feedback'));
    dispatch(setActiveTab(9));
  }, []);
  return <></>;
};

export default Feedback;
