import { permissions } from '@cloudinspot/cis-common-constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { IAddUserReview, ITeamMgmtAddUser } from './addUser.interface';
import './addUserReview.scss';

import { confirmSuccess } from '../../../assets/images';
import { BUTTON_TEXT } from '../../../configs';
import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { routes } from '../../../constants/routes';
import useComponentMount from '../../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import postWithAuth from '../../../services/postWithAuth';
import { TPostPayload } from '../../../services/service.interface';
import {
  addUserStep1,
  addUserStep2,
  setCurrentActiveTabForm,
  setHeading
} from '../../../store/actions';
import {
  ITeamManagementInitialValues,
  IUserPermissionsList
} from '../../../store/teamManagement/teamManagement.interface';
import { trimPayload } from '../../../utils/trimString/trimString';
import Accordion from '../../common/accordion/Accordion';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import ArrowShaftIcon from '../../common/icons/arrowShaftIcon/ArrowShaftIcon';
import Typography from '../../common/typography/Typography';
import PermissionsReview from '../permissions/PermissionsReview';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../../constants/constants';
import { getFetchUsersQueryKey } from '../../../utils/pre-fetchers/prefetchTeamManagement';

const AddUserReview = ({ addUserSelectedPermissions }: IAddUserReview) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();

  const { addUser1, addUser2 } = useAppSelector(
    (store) => store.teamManagement
  ) as ITeamManagementInitialValues;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const goToTeamManagement = () => {
    // Take the user back to team management screen
    dispatch(addUserStep1({ firstName: '', lastName: '' }));
    dispatch(addUserStep2({ email: '', jobTitle: '' }));
    navigate(routes.TEAM_MANAGEMENT_USERS_TABLE);
  };

  const handleSendInvite = async () => {
    // There should be network call here.
    // clear the fields
    startLoading();
    const payload: ITeamMgmtAddUser = {
      ...addUser1,
      ...addUser2,
      permissions: addUserSelectedPermissions
    };

    const trimmedPayload = trimPayload(payload) as ITeamMgmtAddUser;

    try {
      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_ADD_USER,
        payload: trimmedPayload as unknown as TPostPayload
      });

      if (response) {
        dispatch(addUserStep1({ firstName: '', lastName: '' }));
        dispatch(addUserStep2({ email: '', jobTitle: '' }));
        // Open the success modal
        setOpenConfirmModal(true);
        queryClient.resetQueries(
          getFetchUsersQueryKey({
            limit: ROWS_PER_PAGE_FIRST_ITEM,
            offset: offsetFirst,
            filterUser: ''
          }),
          { exact: true }
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementAddUserFail',
        defaultMessage:
          'Your request to add user has failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(2));
  };

  const removeTopPadding = () => {
    const teamMgmtAddUserContainer = document.getElementById('teamMgmtAddUserContainer');
    if (teamMgmtAddUserContainer) {
      teamMgmtAddUserContainer.style.paddingTop = '16px';
      teamMgmtAddUserContainer.style.paddingBottom = '16px';
    }
  };

  const addTopPadding = () => {
    const teamMgmtAddUserContainer = document.getElementById('teamMgmtAddUserContainer');
    if (teamMgmtAddUserContainer) {
      teamMgmtAddUserContainer.style.paddingTop = '51px';
      teamMgmtAddUserContainer.style.paddingBottom = '51px';
    }
  };

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(TEAM_MANAGEMENT_TEXT.ADD_USER));
    }
  });

  useComponentMount({ onMountFunc: removeTopPadding, onUnMountFunc: addTopPadding });

  const personalInformationData = [
    { label: TEAM_MANAGEMENT_TEXT.FIRST_NAME, value: addUser1.firstName },
    { label: TEAM_MANAGEMENT_TEXT.LAST_NAME, value: addUser1.lastName },
    { label: TEAM_MANAGEMENT_TEXT.JOB_TITLE, value: addUser2.jobTitle },
    { label: TEAM_MANAGEMENT_TEXT.EMAIL, value: addUser2.email }
  ];

  return (
    <div className="add-user-review-container">
      <div className="add-user-review-container__go-back" onClick={handleGoBack}>
        <ArrowShaftIcon direction="left" />
        <Typography variant="caption" size="semiBold">
          {BUTTON_TEXT.BACK}
        </Typography>
      </div>
      <PaperCustom variant="light" className="add-user-review-container__heading">
        <Typography variant="subHeading-2" size="medium">
          Review
        </Typography>
        <Typography variant="body-3" size="regular" as="p">
          Review and confirm the user data you entered
        </Typography>
      </PaperCustom>
      <div className="add-user-review-container__personal-info">
        <Accordion
          accordionTitle={
            <Typography variant="body-3" size="medium" as="p" className="label">
              {TEAM_MANAGEMENT_TEXT.PERSONAL_INFORMATION}
            </Typography>
          }
          isOpen={true}
          accordionKey="personal-info"
          showAccordionButtons={false}
          calculateContentHeight={false}
          containerClass="permissions-accordion"
        >
          <div className="add-user-review-container__personal-info__content">
            {personalInformationData.map((item, index) => (
              <div key={index} className="add-user-review-container__personal-info__content__item">
                <Typography variant="body-3" size="medium" as="p" className="label">
                  {item.label}:
                </Typography>
                <Typography variant="body-3" size="regular" as="p" className="value">
                  {item.value}
                </Typography>
              </div>
            ))}
          </div>
        </Accordion>
      </div>
      <div className="add-user-review-container__permissions">
        <Typography
          variant="body-3"
          size="medium"
          as="p"
          className="add-user-review-container__permissions__heading"
        >
          {TEAM_MANAGEMENT_TEXT.PERMISSIONS}
        </Typography>
        <PermissionsReview
          userPermissions={permissions as unknown as IUserPermissionsList[]}
          selectedPermissions={addUserSelectedPermissions}
        />
      </div>
      <div className="add-user-review-container__navigation">
        <div className="add-user-review-container__navigation__backward">
          <CustomButton
            className="cancel"
            onClick={goToTeamManagement}
            text={BUTTON_TEXT.CANCEL}
            variant={ButtonVariants.SECONDARY}
          />
        </div>
        <div className="add-user-review-container__navigation__forward">
          <CustomButton
            text="Send Invite"
            onClick={handleSendInvite}
            className="next"
            isLoading={loading}
          />
        </div>
      </div>{' '}
      <ConfirmModal
        title="Email sent to the user!"
        description="A temporary password has been sent to the user’s email successfully."
        descriptionWidth="88%"
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        image={confirmSuccess}
        confirmButtonText={BUTTON_TEXT.OKAY}
        handleOkClick={goToTeamManagement}
      />
    </div>
  );
};

export default AddUserReview;
