/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { EmailJobTitleValidation } from './AddUser.validation';
import './AddUser.scss';

import TextInput from '../../common/textInput/TextInput';
import { routes } from '../../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/useRedux';
import {
  IAddUserStep2,
  ITeamManagementInitialValues
} from '../../../store/teamManagement/teamManagement.interface';
import { addUserStep1, addUserStep2, setCurrentActiveTabForm } from '../../../store/actions';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import { BUTTON_TEXT } from '../../../configs';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import Typography from '../../common/typography/Typography';
import IMAGES from '../../../assets';

const EmailInput = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addUser2 } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const emailInputInitialValues: IAddUserStep2 = {
    email: addUser2.email,
    jobTitle: addUser2.jobTitle
  };
  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(0));
  };

  const handleNextClick = ({ email, jobTitle }: IAddUserStep2) => {
    dispatch(addUserStep2({ email, jobTitle }));
    dispatch(setCurrentActiveTabForm(2));
  };

  const handleCancel = () => {
    navigate(routes.TEAM_MANAGEMENT_USERS_TABLE);

    dispatch(addUserStep1({ firstName: '', lastName: '' }));
    dispatch(addUserStep2({ email: '', jobTitle: '' }));
  };

  return (
    <Formik
      onSubmit={handleNextClick}
      initialValues={emailInputInitialValues}
      validationSchema={EmailJobTitleValidation}
    >
      {({ isSubmitting, errors, touched }) => {
        return (
          <Form>
            <PaperCustom variant="medium" className=" emailInput">
              <div className="emailInput__back" onClick={handleGoBack}>
                <img src={IMAGES.backArrow} />
                <Typography variant="caption" size="semiBold">
                  &nbsp;&nbsp; Back
                </Typography>
              </div>
              <div className="emailInput__header">
                <Typography variant="body-2" size="medium" className="text-black-1000">
                  Email Address
                </Typography>
                <Typography variant="body-3" size="regular" className="text-black-800">
                  Add Email address of the intended user
                </Typography>
              </div>
              <TextInput
                disabled={isSubmitting}
                placeholder="User’s Email Address"
                label="Email Address"
                required={true}
                errors={errors}
                touched={touched}
                name="email"
                type="text"
              />
              <TextInput
                disabled={isSubmitting}
                placeholder="User's Job Title"
                label="Job Title"
                required={true}
                errors={errors}
                touched={touched}
                name="jobTitle"
                type="text"
              />
              <div className="emailInput__buttons">
                <CustomButton
                  variant={ButtonVariants.SECONDARY}
                  type="button"
                  text={BUTTON_TEXT.CANCEL}
                  onClick={handleCancel}
                  buttonWidth="200px"
                />

                <CustomButton
                  variant={ButtonVariants.PRIMARY}
                  type="submit"
                  text={BUTTON_TEXT.NEXT}
                  buttonWidth="200px"
                />
              </div>
            </PaperCustom>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailInput;
