import {
  CLEAR_CLOUD_ACCOUNTS,
  CLEAR_SLACK_CONNECTION_DETAILS,
  CLOUD_ACCOUNTS_UPDATE_EMAILS_FOR_REMOVAL,
  CLOUD_ACCOUNTS_UPDATE_EMAIL_LIST,
  FETCH_CLOUD_ACCOUNTS_DATA,
  FETCH_CLOUD_ACCOUNTS_DETAILS_DATA,
  FETCH_CLOUD_POLICIES,
  GET_SLACK_CHANNEL_LIST,
  SET_CONNECT_TO_AWS_DATA,
  SET_CONNECT_TO_AZURE_DATA,
  SET_IS_SLACK_FOUND,
  SET_IS_SLACK_INSTALLED,
  SET_SLACK_CHANNEL_NAME,
  SET_SLACK_CONNECTION_DETAILS,
  SET_SLACK_REDIRECTION_URL,
  SET_TAB_INSIDE_ACCOUNT_VIEW,
  UPDATE_ACCOUNT_INFO_EDIT_STATE,
  UPDATE_IF_CLOUD_ACCOUNTS_AVALIABLE
} from './cloudAccounts.actionTypes';
import { ICloudAccountsAction, ICloudAccountsReducerInitialState } from './cloudAccounts.interface';

import { EActiveTabInsideAccountView } from '../../components/cloudAccounts/constants';

const initialState: ICloudAccountsReducerInitialState = {
  accounts: [],
  count: 0,
  accountConnectedThisMonth: 0,
  accountDisconnectedThisMonth: 0,
  totalActiveAccounts: 0,
  totalInprogressAccounts: 0,
  totalFailedUnreachableAccounts: 0,
  searchString: '',
  rowsPerPage: 10,
  pageNumber: 1,
  accountDetails: {
    accountId: '',
    accountName: '',
    budget: 0,
    accountConnectedBy: {
      organizationId: '',
      email: '',
      firstName: '',
      isVerified: false,
      jobTitle: '',
      lastName: ''
    },
    CSP: '',
    metadata: {
      tenantId: '',
      clientId: '',
      clientSecret: ''
    },
    createdAt: '',
    createdBy: '',
    created_by: '',
    deletedAt: '',
    organizationId: '',
    status: '',
    updatedAt: ''
  },
  policies: { policyToCreateStack: {}, policyToUseCloudFormation: {} },
  refetchItems: false,
  hasAccounts: JSON.parse(localStorage.getItem('hasAccounts') || 'false') || false,
  activeTabInsideAccountView: EActiveTabInsideAccountView.EMPTY,
  isEditAccountInfoActive: false,
  accountViewEmailList: {
    emailListCount: 0,
    emailList: [],
    emailsForRemoval: []
  },
  slackConnectionDetails: {
    accountId: '',
    awsAccountName: '',
    channelName: '',
    status: '',
    connectBy: '',
    connectedOn: '',
    workspaceName: ''
  },
  isSlackConnected: false,
  slackChannelsList: [],
  slackRedirectionURL: '',
  slackChannelName: '',
  isSlackInstalled: false,
  connectToAwsData: {
    accountId: '',
    accountName: '',
    budget: ''
  },
  connectToAzureData: {
    accountName: '',
    tenantId: '',
    subscriptionKey: '',
    budget: '',
    clientId: '',
    clientSecret: ''
  }
};

const myProfileReducer = (state = initialState, action: ICloudAccountsAction) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLOUD_ACCOUNTS_DATA:
      return {
        ...state,
        ...payload
      };
    case FETCH_CLOUD_ACCOUNTS_DETAILS_DATA: {
      return {
        ...state,
        accountDetails: payload
      };
    }
    case FETCH_CLOUD_POLICIES: {
      return {
        ...state,
        policies: payload
      };
    }
    case UPDATE_IF_CLOUD_ACCOUNTS_AVALIABLE: {
      return { ...state, hasAccounts: payload };
    }

    case SET_TAB_INSIDE_ACCOUNT_VIEW:
      return {
        ...state,
        activeTabInsideAccountView: payload
      };

    case UPDATE_ACCOUNT_INFO_EDIT_STATE:
      return {
        ...state,
        isEditAccountInfoActive: payload
      };

    case SET_SLACK_CONNECTION_DETAILS:
      return {
        ...state,
        slackConnectionDetails: payload
      };

    case GET_SLACK_CHANNEL_LIST:
      return {
        ...state,
        slackChannelsList: payload
      };

    case SET_SLACK_REDIRECTION_URL:
      return {
        ...state,
        slackRedirectionURL: payload
      };
    case CLEAR_CLOUD_ACCOUNTS:
      return initialState;

    case SET_SLACK_CHANNEL_NAME:
      return {
        ...state,
        slackChannelName: payload
      };

    case SET_IS_SLACK_FOUND:
      return {
        ...state,
        isSlackConnected: payload
      };

    case CLEAR_SLACK_CONNECTION_DETAILS:
      return {
        ...state,
        slackConnectionDetails: {
          accountId: '',
          awsAccountName: '',
          channelName: '',
          status: '',
          connectBy: '',
          connectedOn: ''
        }
      };

    case CLOUD_ACCOUNTS_UPDATE_EMAIL_LIST:
      return { ...state, accountViewEmailList: { ...state.accountViewEmailList, ...payload } };
    case CLOUD_ACCOUNTS_UPDATE_EMAILS_FOR_REMOVAL:
      return {
        ...state,
        accountViewEmailList: { ...state.accountViewEmailList, emailsForRemoval: payload }
      };

    case SET_IS_SLACK_INSTALLED:
      return {
        ...state,
        isSlackInstalled: payload
      };

    case SET_CONNECT_TO_AWS_DATA:
      return {
        ...state,
        connectToAwsData: payload
      };

    case SET_CONNECT_TO_AZURE_DATA:
      return {
        ...state,
        connectToAzureData: payload
      };
    default:
      return state;
  }
};

export default myProfileReducer;
