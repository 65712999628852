import React from 'react';
import { useNavigate } from 'react-router-dom';

import IMAGES from '../../../assets';
import { CLOUD_ACCOUNTS_TEXT } from '../../../configs';
import { routes } from '../../../constants/routes';
import CustomButton from '../../common/buttons/CustomButton';
// import SubMenuModal from '../../common/subMenuModal/SubMenuModal';
// import ConnectToCloudDropDown, {
//   type IConnectToCloudDropDownOption
// } from '../ConnectToCloudDropDown/ConnectToCloudDropDown';

export type ConnectToCloudAccountButtonProps = {
  disabled: boolean;
};

// const cloudOptions: IConnectToCloudDropDownOption[] = [
//   { label: 'Connect to AWS', route: routes.CONNECT_ACCOUNT },
//   { label: 'Connect to Azure', route: routes.CONNECT_AZURE }
// ];

function ConnectToCloudAccountButton({ disabled }: ConnectToCloudAccountButtonProps) {
  // const [showCloudDropDown, setShowCloudDropDown] = useState(false);

  const navigate = useNavigate();
  const handleNavigateToAWS = () => {
    navigate(routes.CONNECT_ACCOUNT); // Navigate to AWS route directly
  };

  // const handleDropDown = (e: { stopPropagation: () => void }) => {
  //   e.stopPropagation();
  //   setShowCloudDropDown((prev) => !prev);
  // };

  return (
    <>
      {/* {showCloudDropDown && (
        <SubMenuModal setOpenSubMenuModal={setShowCloudDropDown}>
          <ConnectToCloudDropDown options={cloudOptions} />
        </SubMenuModal>
      )} */}

      <CustomButton
        text={CLOUD_ACCOUNTS_TEXT.CONNECT_AWS}
        onClick={handleNavigateToAWS}
        image={IMAGES.Plus}
        className="welcomeContainer__gradientCard__button apply-loader"
        disabled={disabled}
      />
    </>
  );
}

export default React.memo(ConnectToCloudAccountButton);
