/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import IMAGES from '../../../assets';

export interface ICloseButtonProps {
  onClick?: (value?: any) => void;
}

const CloseButtonIcon = ({ onClick }: ICloseButtonProps) => {
  return (
    <button onClick={onClick} className="cursor-pointer">
      <img src={IMAGES.closeIcon} alt="Close" />
    </button>
  );
};

export default CloseButtonIcon;
