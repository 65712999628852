// eslint-disable-next-line import/named
import classNames from 'classnames';
import { Country, State } from 'country-state-city';
// eslint-disable-next-line
import { Form, Formik } from 'formik';
// eslint-disable-next-line
import { useEffect, useMemo, useState } from 'react';

import './organization.scss';

import { useQueryClient } from 'react-query';

import organizationValidationSchema from './organization.validationSchema';

import IMAGES from '../../../assets';
import { confirmSuccess } from '../../../assets/images';
import { SETTINGS_TOOLTIP_MESSAGES } from '../../../configs/settings';
import API_ENDPOINTS from '../../../constants/api_endpoints';
import { ALPHABETS_AND_NUMBERS } from '../../../constants/regex';
import { routes } from '../../../constants/routes';
import { getOrgData } from '../../../helpers/getOrgData';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../hooks/loading/useLoading';
import useFetchOrganizationDetails from '../../../hooks/orgDetails/useFetchOrganizationDetails';
import useUpdateBreadcrumbs from '../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import useTimezones from '../../../hooks/userAuthentication/timezones/useTimezones';
import { ICountry, ITimezoneOptions } from '../../../pages/userOnboarding/userOnboarding.types';
import postWithAuth from '../../../services/postWithAuth';
import { IOrganizationData } from '../../../store/settings/settings.interface';
import { removeDuplicatesFlatArray } from '../../../utils';
import { prefetchNotificationSettings } from '../../../utils/pre-fetchers/prefetchSettings';
import timezoneConverter from '../../../utils/timezoneConverter/timezoneConverter';
import { trimPayload } from '../../../utils/trimString/trimString';
import Key from '../../cloudAccounts/accountView/slack/Key';
import Value from '../../cloudAccounts/accountView/slack/Value';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../common/buttons/CustomButton';
import ConfirmModal from '../../common/confirmModal/ConfirmModal';
import SearchAndSelect from '../../common/molecules/searchAndSelect/SearchAndSelect';
import { TFieldItem } from '../../common/molecules/searchAndSelect/searchAndSelect.interface';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import Typography from '../../common/typography/Typography';

const NOT_APPLICABLE = 'Not Applicable';

const Organization = () => {
  const defaultTimezones = useTimezones();
  const { orgID } = getOrgData();
  const [isSettingsEditModeOn, setIsSettingsEditModeOn] = useState<boolean>(false);
  const [activeSearchModal, setActiveSearchModal] = useState<string | boolean>('');
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [isSettingsSuccessModalOpen, setIsSettingsSuccessModalOpen] = useState<boolean>(false);
  const submitLoading = useLoading();
  const [orgName, setOrgName] = useState<string>('');
  const [orgError, setOrgError] = useState<string>('');
  const [countryError, setCountryError] = useState<string>('');
  const [stateError, setStateError] = useState<string>('');
  const [currencyError, setCurrencyError] = useState<string>('');
  const [timeZoneError, setTimeZoneError] = useState<string>('');

  const organizationInitialValues: IOrganizationData = new IOrganizationData();
  const [initialValues, setInitialValues] = useState<IOrganizationData>(organizationInitialValues);
  // USER PERMISSIONS START
  const { canUserEditOrganization } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const queryClient = useQueryClient();
  const { refetchOrganization, orgData, isLoading } = useFetchOrganizationDetails();
  const updateFieldsonCountryChange = ({ changeValue }: { changeValue: string }) => {
    const country = Country.getAllCountries().find((item) => item.name === changeValue);

    if (country?.timezones) {
      const countryTimezones = timezoneConverter(country?.timezones);

      updateInitialValues({ field: 'state', value: '' });
      updateInitialValues({ field: 'timezone', value: countryTimezones[0]?.name });
      updateInitialValues({ field: 'currency', value: country.currency });
    }
  };

  const updateInitialValues = ({
    field,
    value
  }: {
    field: keyof IOrganizationData;
    value: string | number;
  }) => {
    setInitialValues((prev) => ({ ...prev, [field]: value }));

    if (field === 'country') {
      updateFieldsonCountryChange({ changeValue: value as string });
    }
  };

  const updateGeographyFields = ({
    field,
    selectedOption
  }: {
    field: keyof IOrganizationData;
    selectedOption: TFieldItem;
  }) => {
    updateInitialValues({ field, value: selectedOption.name });

    switch (field) {
      case 'country': {
        updateFieldsonCountryChange({ changeValue: selectedOption.name as string });

        setSelectedCountry(selectedOption as ICountry);
        break;
      }
      default: {
        break;
      }
    }
  };

  const { currencies, countries, states, timezones } = useMemo(() => {
    const currencies = removeDuplicatesFlatArray({
      arr: Country.getAllCountries().map((item) => item.currency)
    }) as string[];

    const country = Country.getAllCountries().find((item) => item.name === initialValues.country);
    setSelectedCountry(country as ICountry);

    const states = State.getStatesOfCountry(country?.isoCode) || [];

    let timezones: ITimezoneOptions[] = defaultTimezones;

    if (country) {
      // If a country is selected from the existing list,
      // Only show the timezones of the country.
      if (country?.timezones) {
        const countryTimezones = timezoneConverter(country?.timezones);
        // Assigning country timezones and sorting timezones in the increasing order of GMT.
        timezones = countryTimezones.sort((a, b) => (a?.gmtOffset < b?.gmtOffset ? -1 : +1));
      }
    }

    if (country && states?.length < 1) {
      // When the selected country has no States, changes the State value to 'Not Applicable'
      updateInitialValues({ field: 'state', value: 'Not Applicable' });
    }

    return {
      currencies: currencies.map((item) => ({ name: item, code: item })),
      countries: Country.getAllCountries(),
      states,
      timezones
    };
  }, [initialValues.country, selectedCountry?.name, isSettingsEditModeOn]);

  const handleEditModeToggle = () => {
    setIsSettingsEditModeOn((prev) => !prev);
  };

  const isValidOrganizationName = () => {
    if (orgName.trim().length === 0) {
      setOrgError('Please enter your organization name');
      return false;
    }
    if (orgName.length < 2) {
      setOrgError('The organization name should be between 2 and 100 characters long');
      return false;
    } else if (orgName.length > 100) {
      setOrgError('The organization name should be between 2 and 100 characters long');
      return false;
    } else if (!ALPHABETS_AND_NUMBERS.test(orgName)) {
      setOrgError('Only alphabets and numbers are allowed in the organization name');
      return false;
    }

    setOrgError('');
    return true;
  };

  const isValidCountry = () => {
    if (!initialValues.country || initialValues.country.trim().length === 0) {
      setCountryError('Please select your country from the available options');
      return false;
    }
    setCountryError('');
    return true;
  };

  const isValidState = () => {
    if (!initialValues.state || initialValues.state.trim().length === 0) {
      setStateError('Please select your state from the available options');
      return false;
    }
    setStateError('');
    return true;
  };

  const isValidCurrency = () => {
    if (!initialValues.currency || initialValues.currency.trim().length === 0) {
      setCurrencyError('Please select your currency from the available options');
      return false;
    }
    setCurrencyError('');
    return true;
  };

  const isValidTimeZone = () => {
    if (!initialValues.timezone || initialValues.timezone.trim().length === 0) {
      setTimeZoneError('Please select your timezone from the available options');
      return false;
    }
    setTimeZoneError('');
    return true;
  };

  const handleSubmit = async () => {
    if (
      isValidOrganizationName() &&
      isValidCountry() &&
      isValidState() &&
      isValidCurrency() &&
      isValidTimeZone()
    ) {
      submitLoading.startLoading();
      const { orgID } = getOrgData();
      try {
        const { currency, country, state, timezone } = initialValues;
        const selectedTimezone = timezones.find((tz) => tz.name === timezone) || null;
        const payload = {
          organizationName: orgName,
          currency: currency,
          country: country,
          state: state,
          timezone: timezone,
          ...(selectedTimezone && {
            abbreviation: selectedTimezone?.abbreviation,
            gmtOffsetName: selectedTimezone?.gmtOffsetName
          })
        };

        const trimmedPayload = trimPayload(payload);

        const response = await postWithAuth({
          url: API_ENDPOINTS.SETTINGS_EDIT_ORGANIZATION_DETAILS,
          payload: trimmedPayload
        });

        if (response) {
          localStorage.setItem(
            'organization',
            JSON.stringify({
              organizationId: orgID,
              organizationName: orgName,
              organizationAbbreviation: payload.abbreviation,
              organizationGmtOffsetName: payload.gmtOffsetName,
              organizationTimeZone: timezone
            })
          );
          refetchOrganization();
          handleEditModeToggle();
          setIsSettingsSuccessModalOpen(true);
          queryClient.resetQueries(['organization']);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'EditOrganizationDetailsFail',
          defaultMessage: 'organization details update failed due to some unknown error'
        });
      } finally {
        submitLoading.stopLoading();
      }
    }
  };

  const keyClassNames = classNames('apply-loader', ' field__key', {
    'field__key-edit': isSettingsEditModeOn
  });

  useEffect(() => {
    prefetchNotificationSettings(queryClient);
    if (orgData) {
      setInitialValues(orgData.organization);
      setOrgName(orgData.organization.name);
    }
  }, [orgData]);

  useUpdateBreadcrumbs({
    breadcrumbs: [
      {
        text: 'Settings',
        url: routes.SETTINGS_ORGANIZATION
      },
      {
        text: 'Organization',
        url: ''
      }
    ]
  });

  return (
    <Formik
      initialValues={organizationInitialValues}
      validationSchema={organizationValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <PaperCustom
              variant="medium"
              className={`organization ${isLoading ? 'organization_loading' : ''}`}
            >
              <div className="organization_heading">
                <Typography variant="body-2" size="medium" className="apply-loader" as="h1">
                  My Organization
                </Typography>
                {isSettingsEditModeOn ? (
                  <>
                    <div className="organization_heading__buttons">
                      <CustomButton
                        onClick={() => {
                          resetForm();
                          handleEditModeToggle();
                          setIsSettingsEditModeOn(false);
                          setInitialValues(orgData?.organization || new IOrganizationData());
                          setOrgError('');
                          setOrgName(orgData?.organization.name || '');
                        }}
                        type="button"
                        text="Cancel"
                        variant={ButtonVariants.SECONDARY}
                        buttonWidth="150px"
                      />
                      <CustomButton
                        type="submit"
                        text="Save"
                        variant={ButtonVariants.PRIMARY}
                        buttonWidth="150px"
                        onClick={handleSubmit}
                        isLoading={submitLoading.loading}
                      />
                    </div>
                  </>
                ) : (
                  <TooltipCustom
                    text={SETTINGS_TOOLTIP_MESSAGES.NO_ORGANIZATION_EDIT_ACCESS}
                    conditionToDisplay={!canUserEditOrganization}
                  >
                    <CustomButton
                      onClick={handleEditModeToggle}
                      type="button"
                      text="Edit"
                      image={IMAGES.editPenIcon}
                      variant={ButtonVariants.SECONDARY}
                      buttonWidth="120px"
                      className="apply-loader"
                      disabled={!canUserEditOrganization}
                    />
                  </TooltipCustom>
                )}
              </div>
              {/* <Ruler color={colorConstants.lightGrey2} /> */}
              <div className="organization_content">
                <div className="organization_content_row">
                  <div className="field">
                    <Key text="Organization ID" className="apply-loader" />
                    <>
                      <Value text={orgID} className="apply-loader field__value org_value" />
                    </>
                  </div>
                  <div className="field">
                    <Key text="Organization Name" className={keyClassNames} />
                    {isSettingsEditModeOn ? (
                      <div className="inputClass orgInputClass">
                        <input
                          value={orgName}
                          onChange={(e) => {
                            setOrgName(e.target.value);
                            setOrgError('');
                          }}
                          className="orgField"
                          placeholder="Enter Organization Name"
                        />
                        <p
                          className={`${
                            orgError ? 'orgInputClass__error' : 'orgInputClass__error--is-hidden'
                          }`}
                        >
                          {orgError ? orgError : 'Please enter your organization name'}
                        </p>
                      </div>
                    ) : (
                      <>
                        <Value
                          text={orgData?.organization.name.trim()}
                          className="apply-loader field__value org_value org_name"
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="organization_content_row">
                  <div className="field">
                    <Key text="Country" className={keyClassNames} />
                    {isSettingsEditModeOn ? (
                      <div className="inputClass field__country field__select">
                        <SearchAndSelect
                          activeSearchModal={activeSearchModal}
                          setActiveSearchModal={setActiveSearchModal}
                          id="country"
                          name="country"
                          testid="country"
                          placeholder="Select Your Country"
                          searchPlaceholder="Search Your Country"
                          fieldItems={countries}
                          value={initialValues.country}
                          onInputChangeFunc={(value) => {
                            updateInitialValues({ field: 'country', value: value.value });
                            setCountryError('');
                          }}
                          onSelectChangeFunc={updateGeographyFields}
                          onBlur={() => {
                            setCountryError('');
                          }}
                        />
                        <p
                          className={`${
                            countryError
                              ? 'orgInputClass__error'
                              : 'orgInputClass__error--is-hidden'
                          }`}
                        >
                          {countryError ? countryError : 'Please select a country'}
                        </p>
                      </div>
                    ) : (
                      <Value
                        text={orgData?.organization.country}
                        className="apply-loader field__value org_value"
                      />
                    )}
                  </div>
                  <div className="field">
                    <Key text="State" className={keyClassNames} />
                    {isSettingsEditModeOn ? (
                      <div className="inputClass field__state field__select">
                        <SearchAndSelect
                          activeSearchModal={activeSearchModal}
                          setActiveSearchModal={setActiveSearchModal}
                          id="state"
                          name="state"
                          testid="state"
                          placeholder="Select Your State"
                          searchPlaceholder="Search Your State"
                          fieldItems={states}
                          value={initialValues.state}
                          onInputChangeFunc={(value) => {
                            updateInitialValues({ field: 'state', value: value.value });
                            setStateError('');
                          }}
                          onSelectChangeFunc={updateGeographyFields}
                          key={selectedCountry?.name}
                          disabled={initialValues.state === NOT_APPLICABLE}
                          onBlur={() => {
                            setStateError('');
                          }}
                        />
                        <p
                          className={`${
                            stateError ? 'orgInputClass__error' : 'orgInputClass__error--is-hidden'
                          }`}
                        >
                          {stateError
                            ? stateError
                            : 'Please select your state from the available options'}
                        </p>
                      </div>
                    ) : (
                      <Value
                        text={orgData?.organization.state}
                        className="apply-loader field__value org_value"
                      />
                    )}
                  </div>
                </div>
                <div className="organization_content_row">
                  <div className="field">
                    <Key text="Currency" className={keyClassNames} />
                    {isSettingsEditModeOn ? (
                      <div className="inputClass field__currency field__select">
                        <SearchAndSelect
                          id="currency"
                          name="currency"
                          testid="currency"
                          placeholder="Select Your Currency"
                          searchPlaceholder="Search Your Currency"
                          activeSearchModal={activeSearchModal}
                          setActiveSearchModal={setActiveSearchModal}
                          fieldItems={currencies}
                          value={initialValues.currency}
                          onInputChangeFunc={(value) => {
                            updateInitialValues({ field: 'currency', value: value.value });
                            setCurrencyError('');
                          }}
                          onSelectChangeFunc={updateGeographyFields}
                          onBlur={() => {
                            setCurrencyError('');
                          }}
                        />
                        <p
                          className={`${
                            currencyError
                              ? 'orgInputClass__error'
                              : 'orgInputClass__error--is-hidden'
                          }`}
                        >
                          {currencyError
                            ? currencyError
                            : 'Please select your currency from the available options'}
                        </p>
                      </div>
                    ) : (
                      <Value
                        text={orgData?.organization.currency}
                        className="apply-loader field__value org_value"
                      />
                    )}
                  </div>
                  <div className="field">
                    <Key text="Timezone" className={keyClassNames} />
                    {isSettingsEditModeOn ? (
                      <div className="inputClass field__timezone field__select">
                        <SearchAndSelect
                          id="timezone"
                          name="timezone"
                          testid="timezone"
                          placeholder="Select Your Timezone"
                          searchPlaceholder="Search Your Timezone"
                          activeSearchModal={activeSearchModal}
                          setActiveSearchModal={setActiveSearchModal}
                          fieldItems={timezones}
                          value={initialValues.timezone}
                          key={selectedCountry?.name}
                          onInputChangeFunc={(value) => {
                            updateInitialValues({ field: 'timezone', value: value.value });
                            setTimeZoneError('');
                          }}
                          onSelectChangeFunc={updateGeographyFields}
                          onBlur={() => {
                            setTimeZoneError('');
                          }}
                        />
                        <p
                          className={`${
                            timeZoneError
                              ? 'orgInputClass__error'
                              : 'orgInputClass__error--is-hidden'
                          }`}
                        >
                          {timeZoneError ? timeZoneError : 'Please select a country'}
                        </p>
                      </div>
                    ) : (
                      <Value
                        text={orgData?.organization.timezone}
                        className="apply-loader field__value org_value"
                      />
                    )}
                  </div>
                </div>
              </div>
            </PaperCustom>
            {isSettingsSuccessModalOpen && (
              <ConfirmModal
                open={isSettingsSuccessModalOpen}
                setOpen={setIsSettingsSuccessModalOpen}
                image={confirmSuccess}
                title="Details Updated"
                description="Organization's details updated successfully."
                confirmButtonText="OKAY"
                descriptionWidth="88%"
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default Organization;
