import React, { useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import EmailListActions from './EmailListActions';

import { confirmSuccess } from '../../../../assets/images';
import { BUTTON_TEXT, CLOUD_ACCOUNTS_TEXT } from '../../../../configs';
import { CLOUD_ACCOUNTS_TOOLTIP_MESSAGES } from '../../../../configs/cloudAccounts';
import { colorConstants } from '../../../../configs/styleConstants';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import {
  offsetFirst,
  ROWS_PER_PAGE_FIRST_ITEM,
  RowsPerPage
} from '../../../../constants/constants';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useCalculateElementHeight from '../../../../hooks/common/calculateElementHeight/useCalculateElementHeight';
import useComponentMount from '../../../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch } from '../../../../hooks/redux/useRedux';
import postWithCloudAuth from '../../../../services/cloudAccounts/postWithCloudAuth';
import { closeAlertMessage, updateCloudAccountsEmailsForRemoval } from '../../../../store/actions';
import { ICloudAccountsEmailList } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import useOrganizationStore from '../../../../store/orgnization/useOrgnizationStore';
import { selectDeslectCheckbox, sortObjectArray } from '../../../../utils';
import { getDateFromString } from '../../../../utils/dateAndHourFormatting/dateAndHourFormatting';
import { getFetchEmailRecipientsQueryKey } from '../../../../utils/pre-fetchers/prefetchCloudAccounts';
import { TSortType } from '../../../../utils/sortObjectArray/sortObjectArray.interface';
import CustomTable from '../../../common/commonTable/CustomTable';
import { ITableColumns } from '../../../common/commonTable/customTable.interface';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import TrashIcon from '../../../common/icons/trashIcon/TrashIcon';
import Loader from '../../../common/loader/loader';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import TextTooltipWithEllipsis from '../../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import Typography from '../../../common/typography/Typography';
import './emailList.scss';

interface IEmailListProps {
  isLoading: boolean;
  fetchEmailRecipients: () => void;
  emailList: ICloudAccountsEmailList[];
  emailListCount: number;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

const EmailList = ({
  isLoading,
  fetchEmailRecipients,
  emailList,
  emailListCount,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage
}: IEmailListProps) => {
  const [dataSort, setDataSort] = useState<{ sortType: TSortType; sortColumn: string }>({
    sortType: 'order',
    sortColumn: ''
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // REMOVE EMAIL
  const [emailSelectedForRemoval, setEmailSelectedForRemoval] = useState<string>('');
  const [openRemoveEmailModal, setOpenRemoveEmailModal] = useState<boolean>(false);
  const [openConfirmRemovalModal, setOpenConfirmRemovalModal] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();

  const tableHeightRef = useRef<HTMLDivElement>(null);

  const params = useParams();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  // USER PERMISSIONS START
  const { canUserDeleteEmail } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const { organization } = useOrganizationStore();

  const sortedData = useMemo(() => {
    const startIndex = (pageNumber - 1) * rowsPerPage;
    const totalItems = JSON.parse(JSON.stringify(emailList)).map(
      (item: ICloudAccountsEmailList, index: number) => {
        return {
          item_no: startIndex + index + 1,
          email: item.notificationEmail,
          addedOn: item.createdAt,
          addedBy:
            item.emailNotificationCreatedBy.firstName +
            ' ' +
            item.emailNotificationCreatedBy.lastName
        };
      }
    );

    const newData = sortObjectArray({
      arr: totalItems,
      key: dataSort.sortColumn,
      sortType: dataSort.sortType
    }) as unknown as Record<string, string | string[] | number>[];

    setSelectedRows([]);
    return newData;
  }, [dataSort, emailList, pageNumber, rowsPerPage]);

  const onRowSelection = (value: string) => {
    if (setSelectedRows) {
      const newArray = selectDeslectCheckbox({ arr: selectedRows, value }) as string[];
      setSelectedRows(() => [...newArray]);
      dispatch(updateCloudAccountsEmailsForRemoval([...newArray]));
    }
  };

  const selectAllRows = () => {
    const allRowIds = sortedData.map((row) => row.email) as string[];

    if (setSelectedRows) {
      if (selectedRows.length === allRowIds.length) {
        setSelectedRows([]);
        dispatch(updateCloudAccountsEmailsForRemoval([]));
      } else {
        setSelectedRows(allRowIds);
        dispatch(updateCloudAccountsEmailsForRemoval(allRowIds));
      }
    }
  };

  const handleRemoveEmail = async () => {
    try {
      startLoading();
      const response = await postWithCloudAuth({
        url: API_ENDPOINTS.CLOUD_ACCOUNTS_REMOVE_USER_EMAIL_NOTIFICATION,
        payload: { accountId: params.id, emails: [emailSelectedForRemoval] }
      });
      if (response) {
        setOpenRemoveEmailModal(false);
        setOpenConfirmRemovalModal(true);
        setEmailSelectedForRemoval('');
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'removeEmailsFail',
        defaultMessage: 'Your request failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  const tableColumns: ITableColumns[] = [
    {
      key: 1,
      title: '',
      dataIndex: 'email',
      headElement: () => (
        <input
          type="checkbox"
          checked={selectedRows.length === sortedData.length && sortedData.length > 0}
          className="checkbox select-all-checkbox"
          onChange={selectAllRows}
        />
      ),
      bodyElement: (...args) => {
        const email = args[0] as string;
        return (
          <input
            type="checkbox"
            onChange={(e) => {
              e.stopPropagation();
              onRowSelection(email);
            }}
            onClick={(e) => {
              // To prevent on row click handler propagating.
              e.stopPropagation();
            }}
            className="checkbox"
            checked={selectedRows.includes(email)}
          />
        );
      }
    },
    {
      key: 2,
      title: 'Email',
      dataIndex: 'email',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Email ID</span>
        </div>
      ),
      bodyElement: (...args) => {
        const text = args[0] as string;
        return (
          <div className="email">
            <TextTooltipWithEllipsis text={text} limit={30} />
          </div>
        );
      }
    },
    {
      key: 3,
      title: 'Added On',
      dataIndex: 'addedOn',
      sortable: true,
      headElement: () => (
        <div className="cell-inner email-list-added-on-head">
          <span>Added On</span>
        </div>
      ),
      bodyElement: (...args) => {
        const dateString = args[0] as unknown as string;
        return (
          <div className="added-on">
            {getDateFromString(
              dateString,
              organization.organizationAbbreviation,
              organization.organizationTimeZone
            )}
          </div>
        );
      }
    },
    {
      key: 4,
      title: 'Added By',
      dataIndex: 'addedBy',
      sortable: true,
      headElement: () => (
        <div className="cell-inner">
          <span>Added By</span>
        </div>
      ),
      bodyElement: (...args) => {
        const value = args[0] as unknown as string;
        return value;
      }
    },
    {
      key: 5,
      title: 'Action',
      dataIndex: 'email',
      headElement: () => (
        <div className="cell-inner">
          <span className="email-list-action-head">Action</span>
        </div>
      ),
      rowCellProps: { className: 'email-list-action-td' },
      bodyElement: (...args) => {
        const email = args[0] as unknown as string;

        return (
          <TooltipCustom
            text={
              !canUserDeleteEmail
                ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_EMAIL_REMOVE_ACCESS
                : 'Remove Email Recipients'
            }
          >
            <div
              className="remove-email"
              onClick={() => {
                if (canUserDeleteEmail) {
                  setEmailSelectedForRemoval(email);
                  setOpenRemoveEmailModal(true);
                }
              }}
            >
              <TrashIcon
                color={canUserDeleteEmail ? colorConstants.red1 : colorConstants.lightGrey1}
              />
            </div>
          </TooltipCustom>
        );
      }
    }
  ];

  const { elementHeight } = useCalculateElementHeight({
    ref: tableHeightRef,
    triggers: [sortedData.length],
    // gap between bottom of table and bottom of screen as per figma
    heightOffest: 40
  });

  // This component need a different height, hence handling it manually
  // Reverting the previous upon unmounting
  useComponentMount({
    onMountFunc: () => {
      if (tableHeightRef.current) {
        (tableHeightRef.current?.parentElement as unknown as HTMLDivElement).style.height = 'auto';
      }
      if (emailListCount > 0) {
        dispatch(closeAlertMessage());
      }
    },
    onUnMountFunc: () => {
      const parentClass = document.querySelector(
        '.cloud-accounts-account-view__content'
      ) as unknown as HTMLDivElement;
      if (parentClass) {
        parentClass.style.height = 'calc(100vh - 180px)';
      }
      dispatch(updateCloudAccountsEmailsForRemoval([]));
    }
  });

  return (
    <div className="cloud-accounts-email-list">
      <div className="cloud-accounts-email-list__buttons-row">
        <Typography
          variant="body-2"
          size="medium"
          className="cloud-accounts-email-list__buttons-row__heading apply-loader"
        ></Typography>
        <EmailListActions
          fetchEmailRecipientsLoading={isLoading}
          fetchEmailRecipients={fetchEmailRecipients}
          emailListCount={emailListCount}
        />
      </div>

      <div
        className="cloud-accounts-email-list__table"
        ref={tableHeightRef}
        style={{ height: elementHeight ? `${elementHeight}px` : 'auto' }}
      >
        <CustomTable
          tableColumns={tableColumns}
          tableData={sortedData}
          totalItems={emailListCount}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPageOptions={RowsPerPage}
          isLoading={isLoading}
          selectedRows={selectedRows}
          rowIdKey="email"
          sortState={dataSort}
          setSortState={setDataSort}
        />
      </div>
      <RestoreArchiveUserModal
        type={TYPE_ALIGNMENT.LEFT}
        heading={CLOUD_ACCOUNTS_TEXT.CONFIRM_REMOVAL}
        description={CLOUD_ACCOUNTS_TEXT.CONFIRM_REMOVAL_TEXT}
        open={openRemoveEmailModal}
        handleClose={() => setOpenRemoveEmailModal(false)}
        handlerFun={handleRemoveEmail}
        actionButtonText={loading ? <Loader /> : BUTTON_TEXT.YES_REMOVE}
      />
      <ConfirmModal
        title="Removed!"
        description="Removed 01 Email Recipient(s) successfully."
        descriptionWidth="88%"
        confirmButtonText={BUTTON_TEXT.OKAY}
        open={openConfirmRemovalModal}
        setOpen={setOpenConfirmRemovalModal}
        image={confirmSuccess}
        handleOkClick={() => {
          setOpenConfirmRemovalModal(false);
          queryClient.resetQueries(
            getFetchEmailRecipientsQueryKey({
              limit: ROWS_PER_PAGE_FIRST_ITEM,
              offset: offsetFirst,
              accountId: params.id || ''
            })
          );
        }}
      />
    </div>
  );
};

export default EmailList;
