enum userRoles {
  Admin = 'Admin',
  System = 'System'
}

enum userGroups {
  Admin = 'Admin',
  Owner = 'Owner'
}

enum userAccessControlTabTypes {
  Permissions = 'permissions',
  Groups = 'groups'
}

export enum userEditAccessControlTabTypes {
  Permissions = 'edit-permissions',
  Groups = 'edit-groups',
  AddToGroup = 'add-to-groups'
}

export enum userEditAccessControlModes {
  Permissions = 'permissions',
  Groups = 'groups',
  editGroups = 'edit-groups',
  editPermissions = 'edit-permissions'
}

enum tableTypes {
  Users = 'users',
  Groups = 'groups',
  ArchivedUsers = 'archivedUsers'
}

const teamManagement = { userRoles, userGroups, userAccessControlTabTypes, tableTypes };

export default teamManagement;
