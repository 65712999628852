export const LABEL_TEXT = {
  ADDITIONAL_INFORMATION: 'Additional Information',
  INFORMATION: 'Information',
  ORGANIZATION_ID: 'Organization ID',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COUNTRY: 'Country',
  ORGANIZATION_NAME: 'Organization Name',
  JOB_TITLE: 'Job Title',
  NAME: 'Name',
  ORGANIZATION: 'Organization',
  GEOGRAPHY: 'Geography',
  CURRENCY: 'Currency',
  CITY: 'City',
  STATE: 'State',
  TIMEZONE: 'Timezone'
};

export const BUTTON_TEXT = {
  ADD: 'Add',
  ACTIVATE: 'Activate',
  BACK: 'Back',
  CONTINUE: 'Continue',
  SIGN_IN: 'Sign In',
  SUBMIT: 'Submit',
  SKIP: 'Skip',
  OK: 'OK',
  OKAY: 'OKAY',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  BACK_TO_CREATE_ACCOUNT: 'Back to Create Account',
  BACK_TO_FORGOT_PASSWORD: 'Back to Forgot Password',
  NEXT: 'Next',
  GO_BACK: 'Go Back',
  REMOVE: 'Remove',
  REVIEW: 'Review',
  SAVE_CHANGES: 'Save Changes',
  SEND_INVITE: 'Send Invite',
  ADD_USER_TO_GROUPS: 'Add to Team',
  YES_DEACTIVATE: 'Yes, Deactivate',
  YES_REMOVE: 'Yes, Remove',
  SET_PASSWORD: 'Set Password',
  CREATE_GROUP: 'Create Team',
  UPDATE: 'Update'
};
export const INPUT_TYPE = {
  TEXT: 'text',
  EMAIL: 'email',
  PASSWORD: 'password',
  NUMBER: 'number',
  TEXT_AREA: 'text-area',
  CHECKBOX: 'checkbox'
};
