import classNames from 'classnames';
import React from 'react';

import { noDataIcon } from '../../../assets/images';
import { COMMON_TEXT } from '../../../configs/common';
import Typography from '../typography/Typography';
import './noDataFound.scss';

interface NoDataFoundProps {
  description?: string;
  isLoading?: boolean;
}

const NoDataFound: React.FC<NoDataFoundProps> = ({ description, isLoading }) => {
  return (
    <div className="no-data-found-container">
      <img src={noDataIcon} alt="No data found" />
      <Typography
        variant="body-2"
        size="medium"
        as="p"
        className={classNames({ 'no-data-found-container-description-loader': isLoading })}
      >
        {description || COMMON_TEXT.NO_DATA_FOUND}
      </Typography>
    </div>
  );
};

export default NoDataFound;
