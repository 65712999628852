import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { confirmSuccess } from '../../assets/images';
import ReconfigureSlack from '../../components/cloudAccounts/accountView/slack/ReconfigureSlack';
import '../../components/cloudAccounts/accountView/slack/slack.scss';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../components/common/buttons/CustomButton';
import ConfirmModal from '../../components/common/confirmModal/ConfirmModal';
import ConnectStep from '../../components/common/connectStep/ConnectStep';
import TextTooltipWithEllipsis from '../../components/common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import Typography from '../../components/common/typography/Typography';
import { BUTTON_TEXT } from '../../configs';
import {
  CONNECT_TO_SLACK_STEPS,
  RECONFIGURE_TO_SLACK_STEPS,
  SLACK_CONNECT_STEPS
} from '../../configs/cloudAccounts';
import { COMMON_TEXT } from '../../configs/common';
import API_ENDPOINTS from '../../constants/api_endpoints';
import { routes } from '../../constants/routes';
import getUserData from '../../helpers/getUserData';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
import useLoading from '../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import getWithAuthCloud from '../../services/cloudAccounts/getWithCloudAuth';
import {
  fetchCloudAccountDetailsRequest,
  fetchSlackConnectionDetails,
  // setChannelsList,
  setHeading,
  setSlackRedirectionURL,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';
import CloudInSpotName from '../../components/cloudInSpotName/CloudInSpotName';
import LogoWithHeading from '../../components/common/logo-with-heading/logoWithHeading';
import IMAGES from '../../assets';
const ConnectSlack = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const { slackRedirectionURL, accountDetails } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;
  const { startLoading, stopLoading } = useLoading();
  const [isSlackReconfigureOpen, setIsSlackReconfigureOpen] = useState<boolean>(false);
  const [isSlackSuccessfullyConnected, setIsSlackSuccessfullyConnected] = useState<boolean>(false);
  const [isSlackSuccessfullyReconnected, setIsSlackSuccessfullyReconnected] =
    useState<boolean>(false);
  const [isSlackInstallationSuccess, setIsSlackInstallationSuccess] = useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [, setIsSlackInstallationError] = useState<boolean>(false);
  const [slackAlreadyConnected, setSlackAlreadyConnected] = useState<boolean>(true);

  const { firstName } = getUserData();

  const { slackConnectionDetails, isSlackInstalled, slackChannelName, isSlackConnected } =
    useAppSelector((state) => state.cloudAccounts) as ICloudAccountsReducerInitialState;

  const handleGoBack = () => navigate(`/cloud-account/view/slack/${param?.id}`);

  const checkForSlackListChannels = async () => {
    if (param && param?.id)
      try {
        const response = await getWithAuthCloud({
          url: API_ENDPOINTS.SLACK_CHANNELS_LIST,
          params: { accountId: param.id }
        });

        const data = response?.data;

        if (data?.redirectUrl) {
          dispatch(setSlackRedirectionURL(data.redirectUrl));
          return;
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'SlackListFail'
        });
      }
  };

  const handleConfirmSuccess = () => {
    navigate(`/cloud-account/view/slack/${param.id}`);
  };

  const checkHasRedirectionRefresh = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isSlackConnectionSuccess = urlParams.get('refresh') === 'true';
    const installedWorkSpace = urlParams.get('workspaceName');

    const isSlackConnectionError = urlParams.get('success') === 'false';

    if (isSlackConnectionSuccess) {
      // Perform actions or state updates based on the presence of success=true
      setIsSlackInstallationSuccess(true);
      if (installedWorkSpace) setWorkspaceName(installedWorkSpace);
    } else if (isSlackConnectionError) {
      setIsSlackInstallationError(true);
    }

    if (!installedWorkSpace) {
      setWorkspaceName('');
    }
  };

  //update page heading too
  const handleConnectToSlack = async () => {
    window.open(slackRedirectionURL, '_self'); // Open Slack redirection URL after reload
  };

  const fetchSlackAccountDetails = async () => {
    if (param && param?.id) {
      dispatch(fetchSlackConnectionDetails({ accountId: param.id, startLoading, stopLoading }));
    }
  };

  const openCloseSlackSuccessModal = () => setIsSlackSuccessfullyConnected((prev) => !prev);
  const openSlackReconfigureModal = () => setIsSlackReconfigureOpen(true);

  const handleSlackAdditionalSteps = () => {
    window.open(`/cloud-account/view/slack/additional-steps/${param?.id}`, '_blank');
  };

  const openCloseSlackReconnectSuccessModal = () =>
    setIsSlackSuccessfullyReconnected((prev) => !prev);

  useComponentMount({
    onMountFunc: () => {
      checkHasRedirectionRefresh();
      fetchSlackAccountDetails();
      dispatch(setHeading(`Cloud Accounts`));
      checkForSlackListChannels();
    }
  });

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { url: routes.CLOUD_ACCOUNTS, text: 'Cloud Accounts' },
      { url: `/cloud-account/view/${param?.id}`, text: `Account View` },
      { text: `${accountDetails.accountName}`, url: `/cloud-account/view/${param.id}` }, //add account  Account View (Engineering 1 Cost)(format)
      {
        text: isSlackInstalled && isSlackConnected ? 'Reconfigure to Slack' : 'Connect to Slack',
        url: ''
      }
    ]
  });

  useEffect(() => {
    if (!accountDetails.accountName) {
      dispatch(
        fetchCloudAccountDetailsRequest({
          accountId: param?.id || '',
          startLoading,
          stopLoading: () => {
            stopLoading();
            dispatch(updateSkeletonLoader({ isLoading: false }));
          }
        })
      );
    }
  }, [param?.id]);

  const updateSlackBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { url: routes.CLOUD_ACCOUNTS, text: 'Cloud Accounts' },
        { url: `/cloud-account/view/${param?.id}`, text: `Account View` },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountDetails.accountName}
            />
          ),
          url: `/cloud-account/view/${param.id}`
        }, //add account  Account View (Engineering 1 Cost)(format)
        {
          text: isSlackInstalled && isSlackConnected ? 'Reconfigure to Slack' : 'Connect to Slack',
          url: ''
        }
      ])
    );
  };

  useEffect(() => {
    !isSlackConnected && setSlackAlreadyConnected(isSlackConnected);
  }, [isSlackConnected]);

  useEffect(() => {
    updateSlackBreadcrumbs();
  }, [accountDetails.accountName]);

  return (
    <PaperCustom className="slack connectSteps" variant="medium">
      <LogoWithHeading
        logoSrc={IMAGES.infoCircle}
        headingText={
          isSlackInstalled && isSlackConnected
            ? SLACK_CONNECT_STEPS.STEPS_TO_RE_CONNECT_TO_SLACK
            : SLACK_CONNECT_STEPS.STEPS_TO_CONNECT_TO_SLACK
        }
        className="slack-steps-heading"
      />
      <div>
        <Typography variant="body-3" size="semiBold" className="connectSteps__stepTitle">
          Prerequisite
        </Typography>
        <div className="connectSteps__descDiv">
          <Typography variant="body-3" size="regular" as="p">
            Your cloud account should be connected with <CloudInSpotName />.
          </Typography>
          <Typography variant="body-3" size="regular" as="p">
            Sign in to your slack workplace and create a channel to receive updates.
          </Typography>
          <Typography variant="body-3" size="regular" as="p">
            You can view the steps for slack{' '}
            {isSlackInstalled && isSlackConnected ? 'reconfigure' : 'integration'} in a new tab.{' '}
            <button className="slack-click-here" onClick={handleSlackAdditionalSteps}>
              Click here
            </button>
          </Typography>
        </div>
      </div>
      {/*Connect and Reconfigure To Slack Steps */}
      {isSlackInstalled && isSlackConnected
        ? RECONFIGURE_TO_SLACK_STEPS.map((item, index) => {
            const { stepName, description } = item;
            return (
              <ConnectStep
                key={item.stepName + index}
                stepName={stepName}
                description={description}
              />
            );
          })
        : CONNECT_TO_SLACK_STEPS.map((item, index) => {
            const { stepName, description } = item;
            return (
              <ConnectStep
                key={item.stepName + index}
                stepName={stepName}
                description={description}
              />
            );
          })}
      <PaperCustom variant="medium" className="buttonWrapper">
        <CustomButton
          text="Back"
          variant={ButtonVariants.SECONDARY}
          onClick={handleGoBack}
          className="back-button"
          buttonWidth="74px"
        />
        <div className="buttons">
          {!isSlackInstalled ? (
            <CustomButton
              text="Visit Slack Workspace"
              onClick={handleConnectToSlack}
              className="visit-button"
              buttonWidth="186px"
            />
          ) : (
            <CustomButton
              text="Visit Slack Workspace"
              onClick={openSlackReconfigureModal}
              className="visit-button"
              buttonWidth="186px"
            />
          )}
        </div>
      </PaperCustom>

      {(isSlackInstallationSuccess || isSlackReconfigureOpen) && !slackAlreadyConnected && (
        <ReconfigureSlack
          setIsSlackSuccessfullyConnected={openCloseSlackSuccessModal}
          accountId={param?.id ?? param?.id}
          open={isSlackInstallationSuccess || isSlackReconfigureOpen}
          isRefreshTrue={isSlackInstallationSuccess ? true : false}
          workspace={isSlackInstallationSuccess ? workspaceName : ''}
          handleClose={() => {
            setIsSlackReconfigureOpen(false);
            setIsSlackInstallationSuccess(false);
          }}
          selectedChannelName={slackConnectionDetails?.channelName}
        />
      )}
      {/* Slack connected successfully and user needs to select the slack channel */}
      {/* Slack re configuration */}
      {(isSlackInstallationSuccess || isSlackReconfigureOpen) && slackAlreadyConnected && (
        <ReconfigureSlack
          setIsSlackSuccessfullyConnected={openCloseSlackReconnectSuccessModal}
          accountId={param?.id ?? param?.id}
          open={isSlackInstallationSuccess || isSlackReconfigureOpen}
          isRefreshTrue={isSlackInstallationSuccess ? true : false}
          workspace={isSlackInstallationSuccess ? workspaceName : ''}
          handleClose={() => {
            setIsSlackReconfigureOpen(false);
            setIsSlackInstallationSuccess(false);
          }}
          handleSuccess={() => setIsSlackSuccessfullyReconnected(true)}
          selectedChannelName={slackConnectionDetails.channelName}
        />
      )}
      {/* Slack Connected Successfully */}
      {slackAlreadyConnected && isSlackSuccessfullyReconnected && (
        <ConfirmModal
          open={true}
          setOpen={setIsSlackSuccessfullyReconnected}
          title="Reconfigured!"
          description={`Your AWS Account(s) connected to slack channel “${slackChannelName}” successfully.`}
          descriptionWidth="88%"
          image={confirmSuccess}
          handleOkClick={() => handleConfirmSuccess()}
          confirmButtonText={BUTTON_TEXT.OKAY}
        />
      )}
      {!slackAlreadyConnected && isSlackSuccessfullyConnected && (
        <ConfirmModal
          open={true}
          setOpen={setIsSlackSuccessfullyConnected}
          title={`Great Job, ${firstName}!`}
          description={`Your AWS Account(s) connected to slack channel “${slackChannelName}” successfully.`}
          descriptionWidth="88%"
          handleOkClick={() => handleConfirmSuccess()}
          image={confirmSuccess}
          confirmButtonText={BUTTON_TEXT.OKAY}
        />
      )}
    </PaperCustom>
  );
};

export default ConnectSlack;
