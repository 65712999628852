/* eslint-disable @typescript-eslint/no-unused-vars */
import { CloudAccountStatus } from '@cloudinspot/cis-common-constants';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Key from './Key';
import './slack.scss';
import Value from './Value';

import IMAGES from '../../../../assets';
import { BUTTON_TEXT, CLOUD_ACCOUNTS_TEXT } from '../../../../configs';
import { routes } from '../../../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
// eslint-disable-next-line
import useComponentMount from '../../../../hooks/componentMount/useComponentMount';

import { confirmSuccess } from '../../../../assets/images';
import { CLOUD_ACCOUNTS_TOOLTIP_MESSAGES } from '../../../../configs/cloudAccounts';
import { COMMON_TEXT } from '../../../../configs/common';
import { colorConstants } from '../../../../configs/styleConstants';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import ENUMS from '../../../../enums';
import { CloudServiceProvider } from '../../../../enums/cloudAccounts';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import postWithCloudAuth from '../../../../services/cloudAccounts/postWithCloudAuth';
import {
  clearSlackConnectionDetails,
  closeAlertMessage,
  fetchCloudAccountDetailsRequest,
  fetchSlackConnectionDetails,
  openAlertMessage,
  setActiveTab,
  setHeading,
  setIsSlackChannelFound,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../../../store/cloudAccounts/cloudAccounts.interface';
import useOrganizationStore from '../../../../store/orgnization/useOrgnizationStore';
import { getDateFromString } from '../../../../utils/dateAndHourFormatting/dateAndHourFormatting';
import CloudInSpotName from '../../../cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import Loader from '../../../common/loader/loader';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import NoDataScreen from '../../../common/noData/NoDataScreen';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import TableColumnStatus from '../../../common/tableColumnStatus/TableColumnStatus';
import {
  ITableColumnStatus,
  TTableStatus
} from '../../../common/tableColumnStatus/tableColumnStatus.interface';
import TextTooltipWithEllipsis from '../../../common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import Typography from '../../../common/typography/Typography';
import { TTableStatusText } from '../../cloudAccounts.interface';
import shouldHideEmailSlackTab from '../shouldHideEmailSlackTab';

export interface IChannelNoSlackInstallationResponse {
  redirectUrl: string;
}

const { TableStatusTooltipText, CloudAccountsTableStatus } = ENUMS.cloudAccounts;

const Slack = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const [isDisconnectSlackOpen, setIsDisconnectSlackOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [slackDisconnectSuccess, setSlackDisconnectSuccess] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();

  const { slackConnectionDetails, isSlackInstalled, isSlackConnected, accountDetails } =
    useAppSelector((state) => state.cloudAccounts) as ICloudAccountsReducerInitialState;

  // const { abbreviation, timezone: OrgTimezone } = useAppSelector(
  //   (store) => store.settings.organizationData
  // );

  const { organization } = useOrganizationStore();

  // USER PERMISSIONS START
  const { canUserAddSlack, canUserDisconnectSlack, canUserReconfigureSlack } =
    useCheckUserPermissions();
  // USER PERMISSIONS END

  const isCloudAccountActive: boolean = accountDetails.status === CloudAccountStatus.Active;

  const handleConnectToSlackClick = () =>
    navigate(`/cloud-account/view/slack/connect/${param?.id}`);

  const status = slackConnectionDetails.status as TTableStatusText;

  const slackConnectionStatus: ITableColumnStatus = {
    type: status.toLowerCase() as TTableStatus,
    text: CloudAccountsTableStatus[status],
    tooltipText: TableStatusTooltipText[status],
    showTooltip: showTooltip,
    openTooltip: () => setShowTooltip(true),
    closeTooltip: () => setShowTooltip(false),
    toggleTooltip: () => setShowTooltip((prev) => !prev)
  };

  const fetchSlackAccountDetails = async () => {
    if (param && param?.id) {
      dispatch(fetchSlackConnectionDetails({ accountId: param.id, startLoading, stopLoading }));
    }
  };

  const handleDisconnect = async () => {
    if (param && param?.id)
      try {
        startLoading();
        const response = await postWithCloudAuth({
          url: API_ENDPOINTS.SLACK_DISCONNECT,
          payload: {
            accountId: param.id
          }
        });

        if (response) {
          setSlackDisconnectSuccess(true);
          setIsDisconnectSlackOpen(false);
          // moved data updation logic to updateDetailsPostDisconnection callback
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'SlackDisconnectFail',
          defaultMessage: 'Error in slack disconnection'
        });
      } finally {
        stopLoading();
      }
  };

  // Updated data post confirm modal action
  const updateDetailsPostDisconnection = () => {
    dispatch(setIsSlackChannelFound(false));
    fetchSlackAccountDetails();
    dispatch(clearSlackConnectionDetails());
  };

  const updateEmailBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { text: CLOUD_ACCOUNTS_TEXT.CLOUD_ACCOUNTS, url: routes.CLOUD_ACCOUNTS },
        {
          text: CLOUD_ACCOUNTS_TEXT.ACCOUNT_VIEW,
          url: `/cloud-account/view/${param.id}`
        },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountDetails.accountName}
            />
          ),
          url: `/cloud-account/view/${param.id}`
        },
        {
          text: `Slack`,
          url: ``
        }
      ])
    );
  };

  useEffect(() => {
    updateEmailBreadcrumbs();
  }, [accountDetails.accountName]);

  useEffect(() => {
    dispatch(
      openAlertMessage({
        showAlertMessage: !isSlackConnected || !isSlackInstalled,
        message: 'Integrate your slack channel to receive daily notifications',
        textColor: colorConstants.green1,
        backgroundColor: colorConstants.lightGreen1
      })
    );
  }, [isSlackConnected, isSlackInstalled]);

  useComponentMount({
    onMountFunc: () => {
      fetchSlackAccountDetails();
      dispatch(setHeading(`Cloud Accounts`));
      dispatch(setActiveTab(2));

      // only where is no account details status
      // this api will be called the page is refreshed, visited from a bookmark or similar
      // Also turns off skeleton loader
      if (!accountDetails.status && param?.id) {
        dispatch(
          fetchCloudAccountDetailsRequest({
            accountId: param.id,
            startLoading,
            stopLoading: () => {
              dispatch(updateSkeletonLoader({ isLoading: false }));
            }
          })
        );
      }
    },
    onUnMountFunc: () => {
      dispatch(closeAlertMessage());
    }
  });

  const shouldHideEmailSlackTabs: boolean = shouldHideEmailSlackTab({
    accountStatus: accountDetails.status
  });

  const tooltipText = !isCloudAccountActive
    ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.SLACK_CONNECTION_NOT_ALLOWED_NOT_ACTIVE_ACCOUNT
    : !canUserAddSlack
    ? CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_SLACK_ADD_ACCESS
    : 'Slack connection is not allowed when the Cloud account is in progress or has failed!';

  if (!loading && (!isSlackConnected || !isSlackInstalled)) {
    return (
      <div className="noDataWrapper">
        <NoDataScreen
          img={IMAGES.SlackBigLogo}
          heading="Integrate with Slack"
          line1="We recommend to click on connect to Slack button below."
          line2="It will help you connect your AWS account to your Slack channel and view daily spends."
          disabled={!isCloudAccountActive || !canUserAddSlack || shouldHideEmailSlackTabs}
          tooltiptext={tooltipText}
          buttonText="Connect to Slack"
          buttonWidth="185px"
          onClick={handleConnectToSlackClick}
        />
      </div>
    );
  }

  return (
    <>
      <PaperCustom className={`slack ${loading ? 'slack_loading' : ''}`}>
        <>
          <>
            <div className="slackContainer">
              <div className="slackContainer__slackHeaderView">
                <Typography
                  variant="body-2"
                  size="medium"
                  className="slackContainer__slackHeaderView__header apply-loader"
                >
                  Slack Integration
                </Typography>
                <div className="slackButtons">
                  <TooltipCustom
                    text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_SLACK_DISCONNECT_ACCESS}
                    conditionToDisplay={!canUserDisconnectSlack}
                  >
                    <CustomButton
                      text={CLOUD_ACCOUNTS_TEXT.DISCONNECT}
                      variant={ButtonVariants.SECONDARY}
                      image={IMAGES.disconnectBlueLeft}
                      onClick={() => setIsDisconnectSlackOpen(true)}
                      className="apply-loader disconnect-button"
                      disabled={!canUserDisconnectSlack}
                    />
                  </TooltipCustom>
                  <TooltipCustom
                    text={CLOUD_ACCOUNTS_TOOLTIP_MESSAGES.NO_SLACK_RECONFIGURE_ACCESS}
                    conditionToDisplay={!canUserReconfigureSlack}
                  >
                    <CustomButton
                      onClick={handleConnectToSlackClick}
                      text={CLOUD_ACCOUNTS_TEXT.RECONFIGURE}
                      image={IMAGES.reconnectIcon}
                      className="apply-loader reconfig-button"
                      disabled={!canUserReconfigureSlack}
                    />
                  </TooltipCustom>
                </div>
              </div>

              <div className="grid-container">
                <div className="grid-container__grid-item">
                  <Key
                    text="Channel Name"
                    className="apply-loader grid-container__grid-item__key"
                  />
                  <Value
                    text={slackConnectionDetails.channelName}
                    className="apply-loader grid-container__grid-item__value"
                  />
                </div>
                <div className="grid-container__grid-item">
                  <Key
                    text="Slack Connection Status"
                    className="apply-loader grid-container__grid-item__key"
                  />
                  <span className="apply-loader">
                    <TableColumnStatus {...slackConnectionStatus} />
                  </span>
                </div>
                <div className="grid-container__grid-item">
                  <Key
                    text="Workspace Name"
                    className="apply-loader grid-container__grid-item__key"
                  />
                  <Value
                    text={slackConnectionDetails.workspaceName}
                    className="apply-loader grid-container__grid-item__value"
                  />
                </div>
                <div className="grid-container__grid-item">
                  <Key
                    text="Connected On"
                    className="apply-loader grid-container__grid-item__key"
                  />
                  <Value
                    text={getDateFromString(
                      slackConnectionDetails.connectedOn,
                      organization.organizationAbbreviation,
                      organization.organizationTimeZone
                    )}
                    className="apply-loader grid-container__grid-item__value"
                  />
                </div>
                <div className="grid-container__grid-item">
                  <Key
                    text="Connected By"
                    className="apply-loader grid-container__grid-item__key"
                  />
                  <Value
                    text={slackConnectionDetails.connectBy}
                    className="apply-loader grid-container__grid-item__value"
                  />
                </div>
              </div>
            </div>
          </>
          {isDisconnectSlackOpen && (
            <RestoreArchiveUserModal
              type={TYPE_ALIGNMENT.LEFT}
              open={isDisconnectSlackOpen}
              heading="Confirm Disconnection"
              actionButtonText={loading ? <Loader /> : 'Yes, Disconnect'}
              description={`Are you sure you want to disconnect your slack connection from ${
                accountDetails.CSP === CloudServiceProvider.AWS ? 'AWS' : 'Azure'
              } account connection paused successfully.`}
              handlerFun={() => {
                handleDisconnect();
              }}
              handleClose={() => setIsDisconnectSlackOpen(false)}
            />
          )}

          {/* Slack Disconnected Successfully */}
          {slackDisconnectSuccess && (
            <ConfirmModal
              open={slackDisconnectSuccess}
              setOpen={setSlackDisconnectSuccess}
              title="Disconnected!"
              description={
                <>
                  Disconnected Slack channel from <CloudInSpotName /> successfully.
                </>
              }
              descriptionWidth="88%"
              image={confirmSuccess}
              confirmButtonText={BUTTON_TEXT.OKAY}
              handleClose={updateDetailsPostDisconnection}
            />
          )}
        </>
      </PaperCustom>
    </>
  );
};

export default Slack;
