import React, { useState } from 'react';

import './userFeedback.scss';

import IMAGES from '../../../../assets';
import { BUTTON_TEXT } from '../../../../configs';
import { COMMON_TEXT } from '../../../../configs/common';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { SANITIZE_INPUT } from '../../../../constants/regex';
import getUserData from '../../../../helpers/getUserData';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import postWithAuth from '../../../../services/postWithAuth';
import CustomButton, { ButtonVariants } from '../../buttons/CustomButton';
import CloseIconBorder from '../../icons/closeIconBorder/CloseIconBorder';
import Loader from '../../loader/loader';
import Typography from '../../typography/Typography';

const maxCharacters = 500;

interface IUserFeedback {
  openModal: boolean;
  handleClose: () => void;
  callbackFunc: () => void;
  containerClass?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onChangeCallback?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

type feedbackType = 'positive' | 'neutral' | 'negative' | '';

const UserFeedback = ({
  openModal,
  handleClose,
  containerClass = '',
  containerProps = {},
  onChangeCallback,
  callbackFunc
}: IUserFeedback) => {
  const [feedback, setFeedback] = useState<string>('');
  const [feedbackType, setFeedbackType] = useState<feedbackType>('');
  const { loading, startLoading, stopLoading } = useLoading();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;

    if (onChangeCallback) {
      onChangeCallback(e);
    }
    if (text?.length <= 500) {
      setFeedback(text);
    }
  };

  const sendFeedback = () => {
    const sanitizedInput = sanitizeInput(feedback);
    handleFeedbackSubmit({ feedback: sanitizedInput, feedbackType });
  };
  const handleFeedbackSubmit = async ({
    feedback,
    feedbackType
  }: {
    feedback: string;
    feedbackType: string;
  }) => {
    const { email } = getUserData();
    try {
      startLoading();
      const response = await postWithAuth({
        url: API_ENDPOINTS.SUBMIT_FEEDBACK,
        payload: {
          email: email,
          experience: feedbackType,
          ...(feedback && { feedback })
        }
      });
      if (response) {
        callbackFunc();
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'FeedbackSubmitFail'
      });
    } finally {
      stopLoading;
    }
  };

  const sanitizeInput = (input: string) => {
    // Remove script tags and their content
    return input.replace(SANITIZE_INPUT, '');
  };

  const feedbacks: { img: string; type: feedbackType; description: string }[] = [
    {
      img: IMAGES.positiveFeedbackIcon,
      type: 'positive',
      description: COMMON_TEXT.POSITIVE
    },
    {
      img: IMAGES.neutralFeedbackIcon,
      type: 'neutral',
      description: COMMON_TEXT.NEUTRAL
    },
    {
      img: IMAGES.negativeFeedbackIcon,
      type: 'negative',
      description: COMMON_TEXT.NEGATIVE
    }
  ];

  return (
    <>
      <div
        {...containerProps}
        className={`user-feedback ${containerClass} ${
          openModal ? `user-feedback--is-open ${containerClass}--is-open` : ''
        }`}
      >
        <CloseIconBorder
          color="#fff"
          height={20}
          width={20}
          containerProps={{
            onClick: handleClose,
            className: `user-feedback__close-icon ${containerClass}__close-icon`
          }}
        />
        <div className="user-feedback__header header">
          <Typography
            as="p"
            variant="body-3"
            size="medium"
            className="user-feedback__header__heading"
          >
            How happy are you with product experience?
          </Typography>
        </div>
        <div className="user-feedback__feedback-container">
          <div className="user-feedback__options options">
            {feedbacks.map((item, index) => (
              <div
                key={index}
                aria-label={item.type}
                className={`user-feedback__options__option user-feedback__options__${item.type} ${
                  item.type
                } ${
                  feedbackType === item.type
                    ? `user-feedback__options__${item.type}--is-active ${item.type}--is-active`
                    : ''
                }`}
              >
                <img
                  src={item.img}
                  onClick={() => {
                    setFeedbackType((prev) => (prev === item.type ? '' : item.type));
                  }}
                />
                <Typography variant="body-3" size="regular" className="text-black-800">
                  {item.description}
                </Typography>
              </div>
            ))}
          </div>
          {feedbackType !== '' && (
            <>
              <div className="user-feedback__feedback feedback">
                <div className="text-area-container">
                  <textarea
                    className="user-feedback__feedback__text-area text-area"
                    placeholder="Tell us about your experience"
                    value={feedback}
                    onChange={onChange}
                  ></textarea>
                </div>
                <Typography
                  as="p"
                  variant="caption"
                  size="regular"
                  className="user-feedback__feedback__feedback-length feedback-length"
                >
                  {feedback?.length}/{maxCharacters}
                </Typography>
              </div>
              <CustomButton
                variant={ButtonVariants.SECONDARY}
                text={loading ? <Loader /> : BUTTON_TEXT.SUBMIT}
                onClick={sendFeedback}
                className="user-feedback__submit-feedback submit-feedback"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserFeedback;
