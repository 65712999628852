import { EActiveTabInsideAccountView } from '../components/cloudAccounts/constants';
import { parametricRoutes, routes } from '../constants/routes';
import { PermissionModuleApiKeyEnums } from '../enums';
import { PermissionApiEndpointsEnums, PermissionNameEnums } from '../enums/common';
import {
  AccountView,
  AddGroup,
  AddUser,
  ConnectAccount,
  // ConnectAzure,
  // ConnectAzureSteps,
  ConnectEmail,
  ConnectMsTeam,
  ConnectSlack,
  Settings,
  TeamManagement,
  TeamManagementEditUserAccessControl,
  TeamManagementEditUserProfile,
  TeamManagementViewGroup
} from '../pages';
import ConnectSlackAdditionalSteps from '../pages/connectSlack/ConnectSlackAdditionalSteps';

export const useAllRoutes = () => {
  const allRoutes = [
    // Cloud Accounts

    {
      route: routes.CLOUD_ACCOUNTS_VIEW_SLACK,
      component: <AccountView activeTabAccountView={EActiveTabInsideAccountView.SLACK} />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.VIEW_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.SLACK_GET_ACCOUNT_TO_CHANNEL_MAPPING
    },
    {
      route: routes.CLOUD_ACCOUNTS_EDIT,
      component: <AccountView activeTabAccountView={EActiveTabInsideAccountView.ACCOUNT_INFO} />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.ADD_ACCOUNT
    },
    {
      route: routes.CLOUD_ACCOUNTS_VIEW_EMAIL,
      component: <AccountView activeTabAccountView={EActiveTabInsideAccountView.EMAIL} />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.VIEW_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.EMAIL_LIST
    },

    // Connect account CONNECT_ACCOUNT
    {
      route: routes.CONNECT_ACCOUNT,
      component: <ConnectAccount />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.ADD_ACCOUNT
    },
    // Connect Azure account
    // {
    //   route: routes.CONNECT_AZURE,
    //   component: <ConnectAzure />,
    //   apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
    //   permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
    //   apiEndpoint: PermissionApiEndpointsEnums.ADD_ACCOUNT
    // },
    // {
    //   route: routes.CONNECT_AZURE_CONFIRM,
    //   component: <ConnectAzureSteps />,
    //   apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
    //   permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
    //   apiEndpoint: PermissionApiEndpointsEnums.ADD_ACCOUNT
    // },
    // connect slack
    {
      route: routes.CONNECT_SLACK_ACCOUNT,
      component: <ConnectSlack />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.SLACK_ADD_ACCOUNT_TO_CHANNEL
    },
    // connect slack
    {
      route: routes.CONNECT_SLACK_ADDITIONAL_STEPS,
      component: <ConnectSlackAdditionalSteps />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.SLACK_ADD_ACCOUNT_TO_CHANNEL
    },
    // connect msteam
    {
      route: routes.CONNECT_MSTEAM_ACCOUNT,
      component: <ConnectMsTeam />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.SLACK_ADD_ACCOUNT_TO_CHANNEL
    },

    // Add email notification
    {
      route: routes.CONNECT_EMAIL_ACCOUNT,
      component: <ConnectEmail />,
      apiKey: PermissionModuleApiKeyEnums.CLOUD_ACCOUNTS,
      permissionName: PermissionNameEnums.EDIT_CLOUD_ACCOUNTS,
      apiEndpoint: PermissionApiEndpointsEnums.ADD_EMAIL_ACCOUNT
    },

    // Team management
    {
      route: routes.TEAM_MANAGEMENT_USERS_TABLE,
      component: <TeamManagement />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.VIEW_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.LIST_USERS
    },
    {
      route: routes.TEAM_MANAGEMENT_GROUPS_TABLE,
      component: <TeamManagement />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.VIEW_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.LIST_GROUPS
    },
    {
      route: routes.TEAM_MANAGEMENT + '/deactivated-users',
      component: <TeamManagement />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.VIEW_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.LIST_DEACTIVATED_USERS
    },
    // Add user
    {
      route: routes.ADD_USER,
      component: <AddUser />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.ADD_USER
    },
    // Add group
    {
      route: routes.ADD_GROUP,
      component: <AddGroup />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.ADD_GROUP
    },
    // View other user
    {
      route: routes.TEAM_MANAGEMENT_USER,
      component: <TeamManagementEditUserProfile />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.GET_USER
    },
    // Edit other user permissions
    {
      route: parametricRoutes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_EDIT_PERMISSIONS,
      component: <TeamManagementEditUserAccessControl />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.EDIT_OTHER_USERS_PERMISSION
    },
    // Edit other user groups
    {
      route: parametricRoutes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_EDIT_GROUP,
      component: <TeamManagementEditUserAccessControl />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.REMOVE_USER_FROM_GROUPS
    },
    // Add user to groups
    {
      route: parametricRoutes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL_ADD_TO_GROUP,
      component: <TeamManagementEditUserProfile />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.ADD_USER_TO_GROUPS
    },
    // View Group
    {
      route: routes.TEAM_MANAGEMENT_VIEW_GROUP_BY_NAME,
      component: <TeamManagementViewGroup />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.GET_GROUP_USERS
    },
    // View Group
    {
      route: routes.TEAM_MANAGEMENT_EDIT_GROUP,
      component: <TeamManagementViewGroup />,
      apiKey: PermissionModuleApiKeyEnums.TEAM_MANAGEMENT,
      permissionName: PermissionNameEnums.EDIT_TEAM_MANAGEMENT,
      apiEndpoint: PermissionApiEndpointsEnums.EDIT_GROUP
    },

    // Settings
    {
      route: routes.SETTINGS,
      component: <Settings />,
      apiKey: PermissionModuleApiKeyEnums.ORGANIZATION,
      permissionName: PermissionNameEnums.VIEW_SETTINGS,
      apiEndpoint: PermissionApiEndpointsEnums.GET_ORG_DETAILS
    }
  ];

  return allRoutes;
};

export default useAllRoutes;
