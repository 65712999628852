import React from 'react';

import Card from '../../common/card/Card';

export type WelcomeCloudAccountServiceCardProps = {
  heading: string;
  description: string;
  image: string;
  toolTipMsg: string;
};

function WelcomeCloudAccountServiceCard({
  heading,
  description,
  image,
  toolTipMsg
}: WelcomeCloudAccountServiceCardProps) {
  return (
    <Card
      className="welcomeContainer__cardWrapper__card"
      heading={heading}
      description={description}
      img={image}
      toolTipMsg={toolTipMsg}
    />
  );
}

export default React.memo(WelcomeCloudAccountServiceCard);
