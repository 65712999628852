import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { permissions } from '@cloudinspot/cis-common-constants';

import { IEditGroupPermissionsProps } from './editGroup.interface';
import './editGroupPermissions.scss';

import Permissions from '../../permissions/Permissions';
import { BUTTON_TEXT } from '../../../../configs';
import { useAppDispatch } from '../../../../hooks/redux/useRedux';
import { editGroupName, setCurrentActiveTabForm } from '../../../../store/actions';
import { routes } from '../../../../constants/routes';
import { IPermissionsList } from '../../../../store/teamManagement/teamManagement.interface';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import GoBack from '../../common/goBack/GoBack';
import useCalculateElementHeight from '../../../../hooks/common/calculateElementHeight/useCalculateElementHeight';

const EditGroupPermissions = ({
  editGroupSelectedPermissions,
  setEditGroupSelectedPermissions
}: IEditGroupPermissionsProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const permissionsRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { groupname } = useParams() as unknown as { groupname: string };

  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(0));
  };

  const goToTeamManagement = () => {
    // Take the user back to team management screen
    dispatch(editGroupName(''));
    navigate(`${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${groupname}`);
  };

  const handleNext = () => {
    dispatch(setCurrentActiveTabForm(2));
  };

  const { elementHeight } = useCalculateElementHeight({
    ref: permissionsRef,
    triggers: [],
    // gap between bottom of table and bottom of screen as per figma
    heightOffest: 70
  });

  return (
    <div
      className="edit-group-permissions-container"
      ref={permissionsRef}
      style={{ height: elementHeight ? `${elementHeight}px` : 'auto' }}
    >
      <GoBack handleGoBack={handleGoBack} />
      <div className="edit-group-permissions-container__permissions">
        <Permissions
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userPermissions={permissions as unknown as IPermissionsList[]}
          selectedPermissions={editGroupSelectedPermissions}
          setSelectedPermissions={setEditGroupSelectedPermissions}
          includeDefaultPermissions={false}
        />
      </div>
      <div className="edit-group-permissions-container__navigation">
        <div className="edit-group-permissions-container__navigation__backward">
          <CustomButton
            variant={ButtonVariants.SECONDARY}
            type="button"
            text={BUTTON_TEXT.CANCEL}
            className="cancel"
            onClick={goToTeamManagement}
          />
        </div>
        <div className="edit-group-permissions-container__navigation__forward">
          <CustomButton
            variant={ButtonVariants.PRIMARY}
            text={BUTTON_TEXT.NEXT}
            className="next"
            onClick={handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default EditGroupPermissions;
