import { IConnectToSlackSteps } from '../components/cloudAccounts/accountView/slack/reconfigureSlack.interface';
import ENUMS from '../enums';
const { CloudAccountsStatuses } = ENUMS.cloudAccounts;

export const CLOUD_ACCOUNTS_TEXT = {
  ADD_RECIPIENTS: 'Add Recipients',
  ACCOUNT_REMOVED_SUCCESSFULLY: 'Your AWS account disconnected from CloudInSpot successfully.',
  SEARCH_PLACEHOLDER: 'Search by Account Id, Account Name',
  CONFIRM_REMOVAL: 'Confirm Removal',
  CONFIRM_REMOVAL_TEXT: 'Confirm removal of selected email(s) from notification recipients?',
  CONNECT_AWS: 'Connect to AWS',
  CONNECT_AZURE: 'Connect to Azure',
  CONNECT_AND_SAVE_TO_AZURE: 'Save and Connect to Azure',
  RECONNECT_AZURE: 'Reconnect to Azure',
  RECONNECT_AWS: 'Reconnect to AWS',
  CONNECT_TO_CLOUD: 'Connect to Cloud',
  EMAIL_INTEGRATION: 'Email Integration',
  ITEM_NO: 'Item No',
  ACCOUNT_ID: 'Account Id',
  STATUS: 'Status',
  REFRESH: 'Refresh',
  RECONNECT_ACCOUNT: 'Reconnect',
  CLOUD_ACCOUNTS: 'Cloud Accounts',
  ACCOUNT_VIEW: 'Account View',
  DISCONNECT_ACCOUNT: 'Disconnect Account',
  DISCONNECT: 'Disconnect',
  PAUSE: 'Pause',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  SELECT_USERS: 'Please select users',
  RECONFIGURE: 'Reconfigure',
  CONNECT_TO_SLACK: 'Connect to Slack',
  RESUME: 'Resume',
  CONNECT_ACCOUNT_TOAST_MSG: `We assume you have the necessary permissions to execute the CloudFormation template.`,
  STEPS_TO_CONNECT_YOUR_AWS_ACCOUNT: 'Steps to Connect Your AWS Account',
  STEPS_TO_CONNECT_YOUR_AZURE_ACCOUNT: 'Steps to Connect Your Azure Account',
  CONNECT_TO_AZURE_PREREQUISITES_STEPS: 'Connect to Azure: Prerequisites Steps',
  STEPS_TO_DISCONNECT_YOUR_AWS_ACCOUNT: 'Steps to Disconnect Your AWS Account'
};

export const ACCOUNT_STATUS_MSG: Record<keyof typeof CloudAccountsStatuses, string> = {
  Active: 'All systems are operating as anticipated.',
  Failed: 'Connection appears disrupted. Follow instructions carefully and retry..',
  Inactive:
    'Your AWS account has been disconnected. When you are ready to resume reporting, please reconnect.',
  Paused: 'Resume connection to continue data reception',
  Unreachable: 'Alert: Connection issue with your account!',
  InProgress: 'Connecting to AWS... Please wait.',
  Connected: 'Account successfully connected with AWS'
};
export const AZURE_ACCOUNT_STATUS_MSG: Record<keyof typeof CloudAccountsStatuses, string> = {
  Active: 'All systems are operating as anticipated.',
  Failed: 'Connection appears disrupted. Follow instructions carefully and retry..',
  Inactive:
    'Your Azure account has been disconnected. When you are ready to resume reporting, please reconnect.',
  Paused: 'Resume connection to continue data reception',
  Unreachable: 'Alert: Connection issue with your account!',
  InProgress: 'Connecting to Azure... Please wait.',
  Connected: 'Account successfully connected with Azure'
};
export const CLOUD_ACCOUNTS_TOOLTIP_MESSAGES = {
  CONNECTED: 'Connected',
  NOT_CONNECTED: 'Not Connected',
  ADD_EMAIL_RECIPIENTS: 'Add Email Recipients',
  DELETE: 'Delete',
  DISCONNECT: 'Disconnect',
  EDIT: 'Edit',
  EMAIL_CONNECTED: 'Email Connected',
  EMAIL_CONNECTION_ISSUE: 'Email Connection Issue',
  EMAIL_NOT_CONNECTED: 'Email Not Connected',
  NO_ADD_ACCESS: 'You do not have access to add a cloud account',
  NO_DELETE_ACCESS: 'You do not have access to delete a cloud account',
  NO_DISCONNECT_ACCESS: 'You do not have access to disconnect a cloud account',
  NO_EDIT_ACCESS: 'You do not have access to edit a cloud account',
  NO_EMAIL_ADD_ACCESS: 'You do not have access to add a user email notification',
  NO_EMAIL_REMOVE_ACCESS: 'You do not have access to remove a user email notification',
  NO_PAUSE_ACCESS: 'You do not have access to pause a cloud account',
  NO_RECONNECT_ACCESS: 'You do not have access to reconnect a cloud account',
  NO_RESUME_ACCESS: 'You do not have access to resume a cloud account',
  NO_SLACK_ADD_ACCESS: 'You do not have access to add slack channel',
  NO_SLACK_DISCONNECT_ACCESS: 'You do not have access to disconnect slack channel',
  NO_SLACK_RECONFIGURE_ACCESS: 'You do not have access to reconfigure slack channel',
  NO_VIEW_ACCESS: 'You do not have access to view a cloud account',
  REMOVE_EMAIL_RECIPIENTS: 'Remove Email Recipients',
  SLACK_CONNECTED: 'Slack Connected',
  SLACK_CONNECTION_ISSUE: 'Slack Connection Issue',
  SLACK_CONNECTION_NOT_ALLOWED_NOT_ACTIVE_ACCOUNT:
    'Slack Connection is not allowed as cloud account is not active',
  SLACK_NOT_CONNECTED: 'Slack Not Connected',
  MS_TEAMS_CONNECTED: 'MS Teams Connected',
  MS_TEAMS__NOT_CONNECTED: 'MS Teams Not Connected',
  ACTION_NOT_ALLOWED: 'Unauthorized action',
  AZURE_SUBSRIPTIONS_MSG:
    'Navigate to Subscriptions in the main menu, click on subscription to access its configuration page, and then copy the Subscription ID.',
  AZURE_TENANT_MSG:
    'Sign in to your Azure account, go to Azure Active Directory > Properties, and simply copy the Tenant ID from there (e.g. 00000000-0000-0000-0000-000000000000).'
};

export const TEAM_ADD_USER = {
  PLEASE_SELECT_AT_LEAST_ONE_USER_TO_ADD_TO_THE_TEAM:
    'Please select at least one user to add to the team'
};
export const CONNECT_TO_SLACK_STEPS: IConnectToSlackSteps[] = [
  {
    stepName: 'Step 1 :',
    description: 'Click on “Visit Slack Workspace” button'
  },
  {
    stepName: 'Step 2 :',
    description:
      'If you are already logged in to your Slack workspace, please follow step 3, otherwise please log in to your Slack workspace first.'
  },
  {
    stepName: 'Step 3 :',
    description:
      'Choose your desired workspace and click the "Allow" button. Then, pick the channel you want to integrate CloudInSpot with and click "Connect" button to finish the integration.'
  }
];
export const CONNECT_TO_SLACK_ADDITIONAL_STEPS: IConnectToSlackSteps[] = [
  {
    stepName: 'Step 1 :',
    description: 'Click on “Visit Slack Workspace” button'
  },
  {
    stepName: 'Step 2 :',
    description:
      'If you are already logged in to your Slack workspace, please follow step 3, otherwise please log in to your Slack workspace first.'
  },
  {
    stepName: 'Step 3 :',
    description:
      'Choose your desired workspace and click the "Allow" button. Then, pick the channel you want to integrate CloudInSpot with and click "Connect" button to finish the integration.'
  }
];
export const RECONFIGURE_TO_SLACK_STEPS: IConnectToSlackSteps[] = [
  {
    stepName: 'Step 1 :',
    description:
      ' Click on “Visit Slack Workspace” button. Select “New Slack Workspace” and click on “connect” button.'
  },
  {
    stepName: 'Step 2 :',
    description:
      'If you are already logged in to your Slack workspace, please follow step 3, otherwise please log in to your Slack workspace first.'
  },
  {
    stepName: 'Step 3 :',
    description:
      'Click "Add Workspace" in the upper right corner, select your desired workspace, and click "Allow." Then, choose the channel you want to integrate CloudInSpot and click "Connect" to complete the process.'
  }
];
export const RECONFIGURE_TO_SLACK_ADDITIONAL_STEPS: IConnectToSlackSteps[] = [
  {
    stepName: 'Step 1 :',
    description:
      'Click on “Visit Slack Workspace” button. Select “New Slack Workspace” and click on “connect” button.'
  },
  {
    stepName: 'Step 2 :',
    description:
      'If you are already logged in to your Slack workspace, please follow step 3, otherwise please log in to your Slack workspace first.'
  },
  {
    stepName: 'Step 3 :',
    description:
      'Click "Add Workspace" in the upper right corner, select your desired workspace, and click "Allow." Then, choose the channel you want to integrate CloudInSpot and click "Connect" to complete the process.'
  }
];

export const AWS_CONNECT_INPUT_GUIDANCE = {
  ACCOUNT_NAME: `The Account Name in CloudInSpot is a display name for the associated AWS account within the platform. It doesn't change the actual AWS account name. The Account Name must be 2-100 characters long and can only include alphabets and underscores.`,
  ACCOUNT_ID: `To find your AWS Account ID, sign in to AWS and check the top right corner of the screen. Your Account ID will be displayed there. Copy it—note that it has a maximum of 12 digits.`,
  MONTHLY_BUDGET: `The budget amount set here is specific to this AWS account and helps you track expenses within CloudInSpot.`
};

export const AZURE_CONNECT_INPUT_GUIDANCE = {
  ACCOUNT_NAME: `The Account Name in CloudInSpot is a display name for the associated Azure account within the platform. It doesn't change the actual Azure account name. The Account Name must be 2-100 characters long and can only include alphabets and underscores.`,
  TENANT_ID: `To obtain the Tenant ID, sign in to your Azure account and search for 'App registrations.' Select this option, then navigate to the 'All applications' tab to view your registered applications. Find and click on the application you want to connect. Within the application details, locate and copy the Tenant ID (e.g., 00000000-0000-0000-0000-000000000000).`,
  SUBSCRIPTION_KEY: `To obtain the Subscription ID, first sign in to your Azure account. Then, navigate to Subscriptions in the main menu, click on the relevant subscription to access its configuration page, and copy the Subscription ID.`,
  APPLICATION_CLIENT_ID: `To obtain the Application Client ID, first sign in to your Azure account and open Azure Active Directory.
  In the menu, select App Registrations and
  find your application. Once selected, go to the Overview section,
  where you’ll see the Application (client) ID displayed—copy it for your use.`,
  CLIENT_SECRET_VALUE: `To obtain the Client Secret Value, first sign in to your Azure account and open
  Azure Active Directory. Navigate to App Registrations,
  select your application, and go to the Certificates & Secrets section. Under Client Secrets, click New client secret, add a description,
  set an expiration period, and then click Add.
  The Client Secret Value will be displayed—copy it immediately, as it won't be shown again.`,
  MONTHLY_BUDGET: `The budget amount set here is specific to this Azure account and helps you track expenses within CloudInSpot.`
};

export const MS_TEAMS = {
  STEPS_TO_CONNECT_TO_MS_TEAMS: 'Steps to Connect to MS Teams'
};
export const SLACK_CONNECT_STEPS = {
  STEPS_TO_CONNECT_TO_SLACK: 'Steps to Connect to Slack',
  STEPS_TO_RE_CONNECT_TO_SLACK: 'Steps to Reconfigure to Slack'
};

export const DELETE_STACK_AWS = {
  AWS_OU_ID_URL: 'https://us-east-1.console.aws.amazon.com/organizations/v2/home/accounts'
};
