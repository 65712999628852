import { Dispatch } from 'react';
// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';

import {
  ADD_GROUP_NAME,
  ADD_USER_STEP_1,
  ADD_USER_STEP_2,
  CLEAR_TEAM_MGMT_TABLE_ARCHIVED_USERS,
  CLEAR_TEAM_MGMT_TABLE_GROUPS,
  CLEAR_TEAM_MGMT_TABLE_USERS,
  EDIT_GROUP_NAME,
  SET_ADD_USERS_TO_GROUP_LIST,
  SET_CURRENT_ACTIVE_TAB_FORM,
  UPDATE_ADD_USERS_TO_GROUP_LIST,
  UPDATE_GROUP_SUMMARY,
  UPDATE_SELECTED_GROUP,
  UPDATE_TABLES_LOADING_STATE,
  UPDATE_TEAM_MANG_TABLES_TYPE,
  UPDATE_TEAM_MGMT_TABLE_ARCHIVED_USERS,
  UPDATE_TEAM_MGMT_TABLE_GROUPS,
  UPDATE_TEAM_MGMT_TABLE_USERS,
  UPDATE_TEAM_MGMT_USER_ACCESS_CONTROL_TABTYPE,
  UPDATE_USER_SUMMARY,
  UPDATE_VIEW_GROUP_TABLES_TYPE
} from './teamManagement.actionTypes';
import {
  IAddUserStep1,
  IAddUserStep2,
  IAddUserToGroup,
  IFetchTeamManagementArchiveUsersRequestAction,
  IFetchTeamManagementGroupsRequestAction,
  IFetchTeamManagementUsersRequestAction,
  IGroupSummary,
  ITeamMangGroup,
  ITeamMangUser,
  TTeamMangEditUserTabs,
  TTeamMangTablesType,
  type TUserSummary
} from './teamManagement.interface';

import API_ENDPOINTS from '../../constants/api_endpoints';
import getWithAuth from '../../services/getWithAuth';
// eslint-disable-next-line import/named
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';
// eslint-disable-next-line
import { TGetGroupsResponse } from '../../services/types/teamManagement.endpoints';

export const addUserStep1 = (state: IAddUserStep1) => ({
  type: ADD_USER_STEP_1,
  payload: state
});

export const addUserStep2 = (state: IAddUserStep2) => ({
  type: ADD_USER_STEP_2,
  payload: state
});

export const setCurrentActiveTabForm = (state: number) => ({
  type: SET_CURRENT_ACTIVE_TAB_FORM,
  payload: state
});

export const updateTeamMangTable = (state: TTeamMangTablesType) => ({
  type: UPDATE_TEAM_MANG_TABLES_TYPE,
  payload: state
});

export const addGroupName = (state: string) => ({
  type: ADD_GROUP_NAME,
  payload: state
});

export const updateViewGrouptableType = (state: string) => ({
  type: UPDATE_VIEW_GROUP_TABLES_TYPE,
  payload: state
});

export const updateSelectedGroup = (state: ITeamMangGroup) => ({
  type: UPDATE_SELECTED_GROUP,
  payload: state
});

export const editGroupName = (state: string) => ({
  type: EDIT_GROUP_NAME,
  payload: state
});

export const updateTeamMgmtTableUsers = (state: {
  userTableData: ITeamMangUser[];
  userTableDataCount: number;
}) => ({
  type: UPDATE_TEAM_MGMT_TABLE_USERS,
  payload: state
});

export const updateTeamMgmtTableGroups = (state: {
  groupTableData: ITeamMangGroup[];
  groupTableDataCount: number;
}) => ({
  type: UPDATE_TEAM_MGMT_TABLE_GROUPS,
  payload: state
});
export const updateGroupSummary = (state: IGroupSummary) => ({
  type: UPDATE_GROUP_SUMMARY,
  payload: state
});
export const updateTeamMgmtTableArchivedUsers = (state: {
  userTableData: ITeamMangUser[];
  userTableDataCount: number;
}) => ({
  type: UPDATE_TEAM_MGMT_TABLE_ARCHIVED_USERS,
  payload: state
});

export const clearTeamMgmtTableUsers = () => ({
  type: CLEAR_TEAM_MGMT_TABLE_USERS
});

export const clearTeamMgmtTableArchivedUsers = () => ({
  type: CLEAR_TEAM_MGMT_TABLE_ARCHIVED_USERS
});

export const clearTeamMgmtTableGroups = () => ({
  type: CLEAR_TEAM_MGMT_TABLE_GROUPS
});

export const updateAccessControlTabType = (state: TTeamMangEditUserTabs) => ({
  type: UPDATE_TEAM_MGMT_USER_ACCESS_CONTROL_TABTYPE,
  payload: state
});

export const updateTeamMgmtTableLoadingState = (state: boolean) => ({
  type: UPDATE_TABLES_LOADING_STATE,
  payload: state
});

export const fetchTeamManagementUsersRequest = ({
  action,
  updateLoadingStatus,
  url,
  params,
  callbackFunc
}: {
  action: (state: {
    userTableData: ITeamMangUser[];
    userTableDataCount: number;
  }) => IFetchTeamManagementUsersRequestAction | IFetchTeamManagementArchiveUsersRequestAction;
  url: string;
  updateLoadingStatus: (state: boolean) => void;
  params: { filterUser?: string; limit: number; offset: number; includeUnverified?: boolean };
  callbackFunc?: () => void;
}) => {
  return async (
    dispatch: Dispatch<
      IFetchTeamManagementUsersRequestAction | IFetchTeamManagementArchiveUsersRequestAction
    >
  ) => {
    updateLoadingStatus(true);
    try {
      const response = await getWithAuth({ url, params });
      const {
        users,
        count,
        totalActiveUsers,
        totalDeactivatedUsers,
        usersAddedThisMonth,
        usersDisconnectedThisMonth
      } = response?.data as { users: ITeamMangUser[]; count: number } & TUserSummary;

      dispatch(
        action({
          userTableData: users,
          userTableDataCount: count,
          ...(count && { hasUsers: true })
        })
      );

      dispatch(
        updateUserSummary({
          totalActiveUsers: totalActiveUsers,
          totalDeactivatedUsers: totalDeactivatedUsers,
          usersAddedThisMonth: usersAddedThisMonth,
          usersDisconnectedThisMonth: usersDisconnectedThisMonth
        })
      );
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementFetchUsersFail',
        defaultMessage:
          'Your request to fetch users has failed due to unknown reasons! Please try again later'
      });
    } finally {
      updateLoadingStatus(false);
      if (callbackFunc) {
        callbackFunc();
      }
    }
  };
};

// TO FETCH GROUPS
export const fetchTeamManagementGroupsRequest = ({
  params,
  updateLoadingStatus,
  callbackFunc
}: {
  params: { filterGroup?: string; limit: number; offset: number };
  updateLoadingStatus: (state: boolean) => void;
  callbackFunc?: () => void;
}) => {
  return async (dispatch: Dispatch<IFetchTeamManagementGroupsRequestAction | IGroupSummary>) => {
    updateLoadingStatus(true);
    try {
      const response: AxiosResponse<TGetGroupsResponse> = await getWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_LIST_GROUPS,
        params
      });
      const { count, groups, groupsAddedThisMonth, groupsDeletedThisMonth }: TGetGroupsResponse =
        response.data;

      dispatch(
        updateTeamMgmtTableGroups({
          groupTableData: groups,
          groupTableDataCount: count,
          ...(count && { hasGroups: true })
        })
      );

      dispatch(
        updateGroupSummary({
          totalGroups: count,
          groupsAddedThisMonth: groupsAddedThisMonth,
          groupsDeletedThisMonth: groupsDeletedThisMonth
        })
      );
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementFetchUsersFail',
        defaultMessage:
          'Your request to fetch groups has failed due to unknown reasons! Please try again later'
      });
    } finally {
      updateLoadingStatus(false);
      if (callbackFunc) {
        callbackFunc();
      }
    }
  };
};

export const setAddUsersToGroupList = (state: IAddUserToGroup[]) => ({
  type: SET_ADD_USERS_TO_GROUP_LIST,
  payload: state
});

export const updateAddUsersToGroupList = (state: IAddUserToGroup[]) => ({
  type: UPDATE_ADD_USERS_TO_GROUP_LIST,
  payload: state
});

export const updateUserSummary = (state: TUserSummary) => ({
  type: UPDATE_USER_SUMMARY,
  payload: state
});
