import React, { useEffect } from 'react';

import { useAppDispatch } from '../../hooks/redux/useRedux';
import { setHeading, setActiveTab } from '../../store/actions';
import useNoCloudAccountsAccessDenied from '../../hooks/userAuthentication/NoCloudAccountsAccessDenied/useNoCloudAccountsAccessDenied';

const Notification = () => {
  const dispatch = useAppDispatch();

  const onPageLoad = () => {
    dispatch(setHeading('Notification'));
    dispatch(setActiveTab(4));
  };

  useNoCloudAccountsAccessDenied();

  useEffect(() => {
    onPageLoad();
  }, []);

  return <div></div>;
};

export default Notification;
