const EMAIL_REGX = /^[a-z0-9A-Z._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
const ONBOARDING_DATE_FORMAT = /^\d{1,2}\/\d{1,2}\/\d{4} \| \d{2}:\d{2} (?:AM|PM) UTC$/;
const WHOLE_NUMBER = /^[0-9]*$/;
const DECIMAL_NUMBER = /^[0-9]*\.?[0-9]*$/;
const PASSWORD_SPECIAL_CHARACTERS = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/"'`/\\-\s|=]/; // from https://owasp.org/www-community/password-special-characters
const TIMEZONE_REGEX = /\(([^)]+)\)/;
const AWS_ACC_ID = /^[0-9]{12}$/;
const SANITIZE_INPUT = /<[^>]*>.*?<\/[^>]*>/g; //remove the angle brackets and all the content bet them
const ALPHABETS_AND_NUMBERS = /^[a-zA-Z0-9\s]+$/;
export {
  EMAIL_REGX,
  ONBOARDING_DATE_FORMAT,
  AWS_ACC_ID,
  WHOLE_NUMBER,
  DECIMAL_NUMBER,
  PASSWORD_SPECIAL_CHARACTERS,
  TIMEZONE_REGEX,
  SANITIZE_INPUT,
  ALPHABETS_AND_NUMBERS
};
