import React from 'react';

import { colorConstants } from '../../../../configs/styleConstants';

interface ICisLogoIcon {
  primaryColor?: string;
  secondaryColor?: string;
  containerProps?: React.HTMLAttributes<SVGElement>;
}

const CisLogoIcon = ({
  primaryColor = colorConstants.black,
  secondaryColor = colorConstants.green4,
  containerProps = {}
}: ICisLogoIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...containerProps}
      width="170"
      height="50"
      viewBox="0 0 170 50"
      fill="none"
    >
      <path
        d="m39.2422 24.98C39.2422 24.0359 39.4042 23.0918 39.7515 22.1938C40.0988 21.2728 40.6081 20.4438 41.2564 19.707C41.9278 18.9471 42.715 18.3484 43.6642 17.9109C44.6134 17.4734 45.7015 17.2432 46.9286 17.2432C48.364 17.2432 49.6142 17.5655 50.656 18.1872C51.6978 18.809 52.485 19.6379 52.9943 20.628L50.656 22.2168C50.4013 21.6412 50.0541 21.1807 49.6142 20.8353C49.1974 20.4899 48.7344 20.2596 48.2482 20.1215C47.762 19.9833 47.299 19.9142 46.8128 19.9142C46.0488 19.9142 45.3774 20.0754 44.8218 20.3747C44.2661 20.6741 43.7799 21.0886 43.4095 21.5721C43.0391 22.0787 42.7381 22.6313 42.5529 23.23C42.3677 23.8517 42.2751 24.4504 42.2751 25.0721C42.2751 25.7629 42.3908 26.4076 42.5992 27.0524C42.8307 27.6971 43.1317 28.2497 43.5253 28.7333C43.9188 29.2169 44.405 29.6083 44.9838 29.8846C45.5395 30.1609 46.1646 30.3221 46.836 30.3221C47.3221 30.3221 47.8083 30.253 48.3177 30.0918C48.827 29.9307 49.2669 29.6774 49.7068 29.332C50.1235 28.9866 50.4708 28.5261 50.7023 27.9734L53.1795 29.4011C52.8786 30.184 52.3692 30.8287 51.6747 31.3583C50.9801 31.8879 50.193 32.3024 49.3364 32.5787C48.4797 32.855 47.6231 32.9932 46.7665 32.9932C45.6552 32.9932 44.6365 32.7629 43.7105 32.3024C42.7844 31.8419 41.9972 31.2201 41.3258 30.4603C40.6544 29.7004 40.1451 28.8254 39.7747 27.8813C39.4274 26.9142 39.2422 25.9471 39.2422 24.98Z"
        fill={primaryColor}
      />
      <path
        d="m54.9623 16.8979H57.9026V29.24C57.9026 29.7697 57.9952 30.1151 58.1573 30.2993C58.3425 30.4835 58.574 30.5756 58.875 30.5756C59.1065 30.5756 59.3612 30.5526 59.5927 30.4835C59.8473 30.4144 60.0557 30.3453 60.2641 30.2762L60.6577 32.5098C60.2641 32.694 59.801 32.8552 59.2685 32.9473C58.7361 33.0394 58.273 33.1085 57.8331 33.1085C56.9071 33.1085 56.1894 32.8552 55.68 32.3716C55.1707 31.8881 54.916 31.1743 54.916 30.2762V16.8979H54.9623Z"
        fill={primaryColor}
      />
      <path
        d="m67.2789 33.0853C66.3297 33.0853 65.4962 32.9241 64.7554 32.6248C64.0145 32.3254 63.3663 31.8879 62.857 31.3353C62.3476 30.7827 61.9309 30.1609 61.6531 29.4471C61.3752 28.7333 61.2363 27.9965 61.2363 27.2136C61.2363 26.4307 61.3752 25.6708 61.6531 24.957C61.9309 24.2432 62.3245 23.6215 62.857 23.0688C63.3663 22.5162 64.0145 22.1017 64.7554 21.7794C65.4962 21.48 66.3529 21.3188 67.2789 21.3188C68.2282 21.3188 69.0616 21.48 69.8025 21.7794C70.5433 22.0787 71.1684 22.5162 71.7009 23.0688C72.2334 23.6215 72.627 24.2432 72.9048 24.957C73.1826 25.6708 73.3215 26.4077 73.3215 27.2136C73.3215 27.9965 73.1826 28.7333 72.9048 29.4471C72.627 30.1609 72.2334 30.7827 71.7241 31.3353C71.2147 31.8879 70.5665 32.3024 69.8256 32.6248C69.0848 32.9471 68.2282 33.0853 67.2789 33.0853ZM64.2692 27.2136C64.2692 27.8813 64.4081 28.457 64.6628 28.9636C64.9175 29.4702 65.2879 29.8616 65.7509 30.1609C66.214 30.4373 66.7233 30.5984 67.3021 30.5984C67.8577 30.5984 68.3671 30.4603 68.8301 30.1609C69.2931 29.8616 69.6404 29.4702 69.9182 28.9636C70.1961 28.457 70.335 27.8813 70.335 27.2136C70.335 26.5688 70.1961 25.9932 69.9182 25.4866C69.6404 24.98 69.2931 24.5656 68.8301 24.2892C68.3671 24.0129 67.8809 23.8517 67.3021 23.8517C66.7233 23.8517 66.214 23.9899 65.7509 24.2892C65.2879 24.5886 64.9406 24.98 64.6628 25.4866C64.385 25.9932 64.2692 26.5688 64.2692 27.2136Z"
        fill={primaryColor}
      />
      <path
        d="m75.1736 28.6186V21.5034H78.1139V27.9738C78.1139 28.8488 78.2759 29.4935 78.6 29.931C78.9242 30.3685 79.4104 30.5988 80.0355 30.5988C80.4059 30.5988 80.7995 30.5297 81.1699 30.3685C81.5403 30.2074 81.8876 30.0001 82.2117 29.7008C82.5358 29.4014 82.7905 29.033 83.022 28.5725V21.5264H85.9623V29.6087C85.9623 29.908 86.0086 30.1383 86.1244 30.2534C86.2401 30.3685 86.4022 30.4607 86.6569 30.4837V32.8784C86.3559 32.9245 86.1244 32.9475 85.916 32.9705C85.7076 32.9935 85.5456 32.9936 85.3835 32.9936C84.851 32.9936 84.4343 32.8784 84.087 32.6251C83.7629 32.3949 83.5545 32.0495 83.5082 31.635L83.4388 30.737C82.9294 31.4968 82.258 32.0955 81.4709 32.487C80.6606 32.8784 79.7808 33.0857 78.8084 33.0857C77.6277 33.0857 76.7248 32.7172 76.0997 31.9574C75.4746 31.1975 75.1736 30.0922 75.1736 28.6186Z"
        fill={primaryColor}
      />
      <path
        d="m88.1851 27.1907C88.1851 26.0855 88.3934 25.0953 88.8565 24.2203C89.2963 23.3223 89.8983 22.6315 90.6854 22.1019C91.4726 21.5723 92.3524 21.319 93.3479 21.319C94.2045 21.319 94.9685 21.5262 95.6399 21.9407C96.3113 22.3552 96.8438 22.8848 97.2374 23.5295V16.8979H100.178V29.5854C100.178 29.8848 100.224 30.1151 100.34 30.2302C100.432 30.3683 100.618 30.4374 100.872 30.4605V32.8552C100.363 32.9473 99.9462 33.0164 99.622 33.0164C99.0895 33.0164 98.6497 32.8782 98.3024 32.6249C97.9551 32.3716 97.7699 32.0262 97.7236 31.5887L97.6773 30.8749C97.2605 31.5887 96.6818 32.1414 95.9409 32.5098C95.2232 32.8782 94.4592 33.0854 93.672 33.0854C92.8849 33.0854 92.144 32.9243 91.4726 32.6249C90.8012 32.3256 90.2224 31.9111 89.7362 31.3815C89.25 30.8519 88.8565 30.2302 88.6018 29.5164C88.324 28.8026 88.1851 28.0197 88.1851 27.1907ZM97.2142 28.7104V26.0394C97.0753 25.6249 96.8438 25.2565 96.5197 24.9341C96.1956 24.6118 95.8483 24.3354 95.4316 24.1512C95.038 23.967 94.6444 23.8519 94.2508 23.8519C93.7878 23.8519 93.3942 23.944 93.0238 24.1282C92.6534 24.3124 92.3292 24.5657 92.0745 24.8881C91.7967 25.2105 91.5884 25.5789 91.4494 25.9933C91.3105 26.4078 91.2411 26.8453 91.2411 27.3058C91.2411 27.7664 91.3105 28.2039 91.4726 28.5953C91.6347 29.0098 91.843 29.3552 92.144 29.6545C92.445 29.9539 92.7691 30.1841 93.1627 30.3683C93.5563 30.5295 93.973 30.6216 94.436 30.6216C94.7139 30.6216 94.9917 30.5756 95.2926 30.4835C95.5705 30.3914 95.8483 30.2532 96.103 30.092C96.3576 29.9308 96.5891 29.7236 96.7744 29.4933C96.9596 29.2401 97.1216 28.9868 97.2142 28.7104Z"
        fill={primaryColor}
      />
      <path d="m103.326 32.8782V17.3354H106.359V32.8782H103.326Z" fill={primaryColor} />
      <path
        d="m120.042 32.878H117.102V26.5457C117.102 25.6477 116.939 24.98 116.615 24.5655C116.291 24.151 115.851 23.9438 115.273 23.9438C114.879 23.9438 114.485 24.0359 114.069 24.2431C113.675 24.4504 113.305 24.7267 112.981 25.0721C112.656 25.4175 112.425 25.8319 112.286 26.2694V32.878H109.346V21.526H112.008V23.6444C112.309 23.1609 112.68 22.7464 113.119 22.401C113.582 22.0556 114.092 21.8023 114.694 21.6181C115.296 21.4339 115.921 21.3418 116.569 21.3418C117.287 21.3418 117.889 21.4569 118.329 21.7102C118.792 21.9635 119.139 22.3089 119.394 22.7234C119.648 23.1378 119.81 23.6214 119.903 24.151C119.996 24.6806 120.042 25.2332 120.042 25.7629V32.878Z"
        fill={primaryColor}
      />
      <path
        d="m132.15 21.4109C132.058 21.3188 131.849 21.1807 131.594 20.9965C131.317 20.8122 130.993 20.6511 130.599 20.4899C130.205 20.3287 129.789 20.1905 129.326 20.0524C128.863 19.9372 128.4 19.8682 127.936 19.8682C127.126 19.8682 126.501 20.0293 126.084 20.3287C125.668 20.628 125.459 21.0655 125.459 21.6182C125.459 22.0326 125.598 22.378 125.853 22.6083C126.107 22.8616 126.501 23.0688 127.034 23.23C127.566 23.4142 128.214 23.5984 129.001 23.8057C130.02 24.059 130.923 24.3583 131.664 24.7037C132.428 25.0491 133.007 25.5326 133.4 26.0853C133.794 26.6609 134.002 27.4208 134.002 28.3649C134.002 29.1938 133.84 29.9076 133.539 30.4833C133.238 31.082 132.798 31.5425 132.266 31.9109C131.733 32.2794 131.108 32.5326 130.414 32.7169C129.719 32.878 129.001 32.9701 128.214 32.9701C127.427 32.9701 126.663 32.9011 125.876 32.7399C125.089 32.5787 124.348 32.3484 123.63 32.0491C122.913 31.7497 122.241 31.3813 121.662 30.9668L123.005 28.3649C123.144 28.503 123.376 28.6642 123.7 28.8715C124.047 29.0787 124.441 29.2859 124.927 29.5162C125.413 29.7465 125.945 29.9076 126.501 30.0458C127.08 30.1839 127.659 30.2761 128.237 30.2761C129.048 30.2761 129.673 30.1379 130.113 29.8616C130.529 29.5853 130.761 29.1938 130.761 28.6872C130.761 28.2267 130.599 27.8583 130.252 27.605C129.904 27.3517 129.441 27.1215 128.839 26.9142C128.237 26.707 127.52 26.4997 126.709 26.2695C125.737 25.9932 124.904 25.6938 124.255 25.3484C123.607 25.003 123.098 24.5655 122.774 24.059C122.45 23.5293 122.287 22.8846 122.287 22.1017C122.287 21.0425 122.542 20.1445 123.051 19.4307C123.561 18.7168 124.232 18.1642 125.112 17.7958C125.969 17.4274 126.941 17.2432 127.983 17.2432C128.724 17.2432 129.418 17.3122 130.066 17.4734C130.715 17.6346 131.34 17.8418 131.895 18.1182C132.474 18.3715 132.984 18.6708 133.423 18.9701L132.15 21.4109Z"
        fill={primaryColor}
      />
      <path
        d="m142.893 33.0852C141.99 33.0852 141.203 32.878 140.531 32.4865C139.86 32.0951 139.327 31.5425 138.934 30.8747V37.5293H135.993V21.526H138.563V23.4832C139.003 22.8155 139.559 22.2859 140.23 21.9175C140.902 21.526 141.666 21.3418 142.545 21.3418C143.333 21.3418 144.027 21.503 144.698 21.8023C145.347 22.1017 145.926 22.5161 146.412 23.0688C146.898 23.5984 147.268 24.2201 147.546 24.9339C147.824 25.6247 147.963 26.3846 147.963 27.1905C147.963 28.2957 147.755 29.2859 147.315 30.1839C146.875 31.0819 146.296 31.7957 145.532 32.3254C144.722 32.8319 143.865 33.0852 142.893 33.0852ZM141.897 30.5984C142.36 30.5984 142.754 30.5063 143.124 30.3221C143.495 30.1378 143.819 29.8846 144.073 29.5622C144.351 29.2398 144.56 28.8714 144.698 28.4569C144.837 28.0425 144.907 27.628 144.907 27.1905C144.907 26.73 144.837 26.2925 144.675 25.878C144.513 25.4865 144.305 25.1181 144.004 24.8188C143.703 24.5194 143.379 24.2661 142.985 24.105C142.592 23.9438 142.175 23.8517 141.712 23.8517C141.434 23.8517 141.156 23.8977 140.855 23.9898C140.577 24.0819 140.3 24.2201 140.022 24.3813C139.767 24.5425 139.536 24.7497 139.327 24.98C139.119 25.2102 138.98 25.4635 138.887 25.7168V28.3879C139.073 28.8023 139.304 29.1707 139.605 29.5161C139.906 29.8615 140.253 30.1148 140.647 30.3221C141.087 30.5063 141.48 30.5984 141.897 30.5984Z"
        fill={primaryColor}
      />
      <path
        d="m155.233 33.0853C154.283 33.0853 153.45 32.9241 152.709 32.6248C151.968 32.3254 151.32 31.8879 150.811 31.3353C150.301 30.7827 149.884 30.1609 149.607 29.4471C149.329 28.7333 149.19 27.9965 149.19 27.2136C149.19 26.4307 149.329 25.6708 149.607 24.957C149.884 24.2432 150.278 23.6215 150.811 23.0688C151.32 22.5162 151.968 22.1017 152.709 21.7794C153.45 21.48 154.306 21.3188 155.233 21.3188C156.182 21.3188 157.015 21.48 157.756 21.7794C158.497 22.0787 159.122 22.5162 159.655 23.0688C160.187 23.6215 160.581 24.2432 160.858 24.957C161.136 25.6708 161.275 26.4077 161.275 27.2136C161.275 27.9965 161.136 28.7333 160.858 29.4471C160.581 30.1609 160.187 30.7827 159.678 31.3353C159.168 31.8879 158.52 32.3024 157.779 32.6248C157.015 32.9241 156.182 33.0853 155.233 33.0853ZM152.223 27.2136C152.223 27.8813 152.362 28.457 152.616 28.9636C152.871 29.4702 153.241 29.8616 153.705 30.1609C154.168 30.4373 154.677 30.5984 155.256 30.5984C155.811 30.5984 156.321 30.4603 156.784 30.1609C157.247 29.8616 157.594 29.4702 157.872 28.9636C158.15 28.457 158.289 27.8813 158.289 27.2136C158.289 26.5688 158.15 25.9932 157.872 25.4866C157.594 24.98 157.247 24.5656 156.784 24.2892C156.321 24.0129 155.834 23.8517 155.256 23.8517C154.677 23.8517 154.168 23.9899 153.705 24.2892C153.241 24.5886 152.894 24.98 152.616 25.4866C152.362 25.9932 152.223 26.5688 152.223 27.2136Z"
        fill={primaryColor}
      />
      <path
        d="m169.98 32.3025C169.725 32.3946 169.424 32.5327 169.077 32.6479C168.73 32.786 168.336 32.8781 167.943 32.9702C167.549 33.0393 167.132 33.0854 166.716 33.0854C166.183 33.0854 165.674 32.9933 165.211 32.8091C164.748 32.6248 164.401 32.3255 164.123 31.911C163.845 31.4966 163.706 30.967 163.706 30.2992V23.7827H162.178V21.5262H163.706V17.7959H166.669V21.5262H169.1V23.7827H166.669V29.3091C166.693 29.7005 166.785 29.9768 166.994 30.161C167.202 30.3452 167.457 30.4143 167.758 30.4143C168.059 30.4143 168.359 30.3683 168.66 30.2531C168.961 30.161 169.193 30.0689 169.355 29.9999L169.98 32.3025Z"
        fill={primaryColor}
      />
      <path
        d="m30.5139 14.6641L29.1711 15.8154C31.0001 18.3022 32.0883 21.3877 32.0883 24.7266C32.0883 28.0424 31.0001 31.1279 29.1711 33.6147L30.5139 34.743C32.5976 31.9338 33.8478 28.4799 33.8478 24.7266C33.8709 20.9502 32.6208 17.4733 30.5139 14.6641Z"
        fill={secondaryColor}
      />
      <path
        d="m26.8329 15.6776C24.4019 13.0526 20.9523 11.4177 17.1091 11.3486V16.4144C19.4475 16.4605 21.5311 17.4736 23.0128 19.0394L26.8329 15.6776Z"
        fill={primaryColor}
      />
      <path
        d="m17.1091 38.243C20.9986 38.174 24.4714 36.493 26.9023 33.845L23.0591 30.5522C21.5543 32.1411 19.4475 33.1542 17.1091 33.2003V38.243Z"
        fill={primaryColor}
      />
      <path
        d="m28.1757 34.8814C25.3975 37.9208 21.3922 39.809 16.9471 39.809C8.56615 39.809 1.78269 33.0623 1.78269 24.7268C1.78269 16.3912 8.56615 9.64453 16.9471 9.64453C21.3922 9.64453 25.3743 11.5327 28.1525 14.5722L29.4722 13.4208C26.3698 10.036 21.9016 7.89453 16.9239 7.89453C7.57062 7.89453 0 15.4241 0 24.7268C0 34.0294 7.57062 41.559 16.9239 41.559C21.9016 41.559 26.393 39.4176 29.4953 36.0097L28.1757 34.8814Z"
        fill={primaryColor}
      />
      <path
        d="m16.021 11.5098C15.0717 11.5328 14.2383 11.74 14.2383 11.74V37.8749C14.2383 37.8749 15.0717 38.1052 16.021 38.1052V11.5098Z"
        fill={primaryColor}
      />
      <path
        d="m12.8261 37.5294V16.0688H11.136V36.8846C11.6916 37.1379 12.2704 37.3452 12.8261 37.5294Z"
        fill={primaryColor}
      />
      <path
        d="m9.77016 36.1019V19.9604H8.08008V34.8585C8.61257 35.319 9.16821 35.7335 9.77016 36.1019Z"
        fill={primaryColor}
      />
      <path
        d="m5.00073 30.9207C5.46377 31.8187 6.01941 32.6477 6.69081 33.4306V24.1279H5.00073V30.9207Z"
        fill={primaryColor}
      />
    </svg>
  );
};

export default CisLogoIcon;
