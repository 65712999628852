import React from 'react';

import { TSortType } from '../../../utils/sortObjectArray/sortObjectArray.interface';
import IMAGES from '../../../assets';

interface SortIconProps {
  currentSortColumn: string;
  columnName: string;
  sortType: TSortType;
  setDataSort: React.Dispatch<
    React.SetStateAction<{
      sortType: TSortType;
      sortColumn: string;
    }>
  >;
}

const SortIcon: React.FC<SortIconProps> = ({
  currentSortColumn,
  columnName,
  sortType,
  setDataSort
}) => {
  const getSortIcon = () => {
    if (currentSortColumn === columnName) {
      return sortType === 'asc' ? IMAGES.sortAscIcon : IMAGES.sortDescIcon;
    }
    return IMAGES.sortIcon;
  };

  const handleSort = () => {
    setDataSort((prev) => ({
      sortType: prev.sortColumn === columnName && prev.sortType === 'asc' ? 'desc' : 'asc',
      sortColumn: columnName
    }));
  };

  return <img src={getSortIcon()} alt="sort" onClick={handleSort} className="sort-icon" />;
};

export default SortIcon;
