import { useEffect } from 'react';

import { IUseComponentMount } from './useComponentMount.interface';

const useComponentMount = ({ onMountFunc, onUnMountFunc }: IUseComponentMount) => {
  // Hook to call simple functions upon mounting of component

  useEffect(() => {
    onMountFunc();
    return () => {
      if (onUnMountFunc) {
        onUnMountFunc();
      }
    };
  }, []);
};

export default useComponentMount;
