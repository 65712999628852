import React, { useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { TEAM_MANAGEMENT_TEXT } from '../../../configs/teamManagement';
import { IUserPermissionsList } from '../../../store/teamManagement/teamManagement.interface';
import SubMenuModal from '../../common/subMenuModal/SubMenuModal';
import IMAGES from '../../../assets';
import { useAppDispatch } from '../../../hooks/redux/useRedux';
import { updateAccessControlTabType } from '../../../store/actions';
import useCalculateElementHeight from '../../../hooks/common/calculateElementHeight/useCalculateElementHeight';
import TooltipCustom from '../../common/molecules/tooltipCustom/TooltipCustom';
import { MY_PROFILE_TOOLTIP_MESSAGES } from '../../../configs/myProfile';
import useCheckUserPermissions from '../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import { routes } from '../../../constants/routes';
import { userEditAccessControlTabTypes } from '../../../enums/teamManagement';
import PaperCustom from '../../common/atoms/PaperCustom/PaperCustom';
import Typography from '../../common/typography/Typography';
import EditPenIcon from '../../common/icons/editPenIcon/EditPenIcon';

// Interfaces
interface IPermissionsProps {
  data: IUserPermissionsList[];
  teamUserProfile: boolean;
  onEditControlButtonClick?: () => void;
  openSelectModuleModal: boolean;
  setOpenSelectModuleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Permissions = ({
  data,
  teamUserProfile,
  openSelectModuleModal,
  setOpenSelectModuleModal
}: IPermissionsProps) => {
  const modules = useMemo(() => {
    return data.map((item) => item.module);
  }, [data.length]);

  const [selectedModule, setSelectedModule] = useState<string>(modules[0]);
  const userPermissionsScrollRef = useRef<HTMLDivElement>(null);

  const permissionsSelectedModule = useMemo(() => {
    return data.find((data) => data.module === selectedModule);
  }, [selectedModule]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // USER PERMISSIONS START
  const { canUserEditAnotherUserPermissions } = useCheckUserPermissions();
  // USER PERMISSIONS END

  const handleEdit = () => {
    dispatch(updateAccessControlTabType('permissions'));
    navigate(
      `${routes.TEAM_MANAGEMENT_USER_ACCESS_CONTROL}/${userEditAccessControlTabTypes.Permissions}`
    );
  };

  const { elementHeight } = useCalculateElementHeight({
    ref: userPermissionsScrollRef,
    triggers: [selectedModule],
    // gap between bottom of table and bottom of screen as per figma
    heightOffest: 70
  });

  return (
    <PaperCustom className="access-control__permissions access-control__tab-item" variant="medium">
      <div className="access-control__tab-item__items__heading">
        <Typography
          variant="body-2"
          size="medium"
          className="access-control__tab-item__items__heading__title"
        >
          {TEAM_MANAGEMENT_TEXT.PERMISSION_NAME}s
        </Typography>
        {teamUserProfile && (
          <TooltipCustom
            text={MY_PROFILE_TOOLTIP_MESSAGES.NO_EDIT_OTHER_USER_PROFILE_PERMISSIONS}
            conditionToDisplay={!canUserEditAnotherUserPermissions}
          >
            <button
              className="access-control__tab-item__items__heading__edit apply-loader"
              onClick={() => {
                if (canUserEditAnotherUserPermissions) {
                  handleEdit();
                }
              }}
              disabled={!canUserEditAnotherUserPermissions}
            >
              <EditPenIcon className="hide_button_icon" />
              <Typography variant="body-3" size="medium" as="span">
                {TEAM_MANAGEMENT_TEXT.EDIT_PERMISSIONS}
              </Typography>
            </button>
          </TooltipCustom>
        )}
      </div>
      {data.length > 0 && (
        <div className="access-control__permissions__head">
          <span className="access-control__permissions__head__title">
            {TEAM_MANAGEMENT_TEXT.MODULE}
          </span>
          <div className="access-control__permissions__head__select-module">
            <div
              className="access-control__permissions__head__select-module__current"
              onClick={(e) => {
                e.stopPropagation();
                setOpenSelectModuleModal((prev) => !prev);
              }}
            >
              <span>{selectedModule}</span>
              <img src={IMAGES.selectDownArrow} alt="dropdown icon" />
            </div>
            <SubMenuModal setOpenSubMenuModal={setOpenSelectModuleModal}>
              <div
                className={`access-control__permissions__head__select-module__dropdown ${
                  openSelectModuleModal
                    ? 'access-control__permissions__head__select-module__dropdown--is-open'
                    : ''
                }`}
              >
                {modules.map((item, index) => (
                  <Typography
                    variant="caption"
                    size="medium"
                    key={index}
                    onClick={() => setSelectedModule(item)}
                    className="module-item"
                    as="p"
                  >
                    {item}
                  </Typography>
                ))}
              </div>
            </SubMenuModal>
          </div>
        </div>
      )}
      <div className="access-control__permissions__items access-control__tab-item__items">
        <div
          className="access-control__permissions__items__permissions-container"
          ref={userPermissionsScrollRef}
          style={{ height: elementHeight ? `${elementHeight}px` : 'auto' }}
        >
          {permissionsSelectedModule && data.length > 0 ? (
            Object.keys(permissionsSelectedModule.permissions)
              .sort((permissionA: string, permissionB: string) =>
                permissionB.localeCompare(permissionA)
              )
              .map((item, index) => {
                const description = permissionsSelectedModule.permissions?.[item]?.description;
                return (
                  <Typography
                    variant="body-3"
                    size="medium"
                    key={index}
                    className="access-control__tab-item__items__item"
                  >
                    {item}
                    {description && (
                      <Typography
                        variant="body-3"
                        size="regular"
                        key={`desc-${index}`}
                        className="access-control__tab-item__items__item description"
                      >
                        {description}
                      </Typography>
                    )}
                  </Typography>
                );
              })
          ) : (
            <p key={1} className="access-control__tab-item__items__item">
              No permissions found
            </p>
          )}
        </div>
      </div>
    </PaperCustom>
  );
};

export default Permissions;
