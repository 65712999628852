import IMAGES from '../../../assets';

const LoadingScreen = () => {
  return (
    <div className="h-screen w-screen flex flex-col gap-4 items-center justify-center">
      <img
        src={IMAGES.Loading}
        alt="loading icon"
        className="animate-spin-anticlockwise h-16 w-16"
      />
      <h1 className="text-2xl">Please wait while we load...</h1>
    </div>
  );
};

export default LoadingScreen;
