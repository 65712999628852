import { SET_SIGN_UP_VALUES, UPDATE_SIGN_UP_EMAIL } from './userAuth.actionTypes';
import { IUserAuthAction, IUserAuthReducerInitialState } from './userAuth.interface';

import { ISignUp } from '../../pages/SignUp/SignUp.types';

export const signUpInitialValues: ISignUp = {
  email: '',
  password: '',
  confirmPassword: ''
};

const userAuthReducerInitialState: IUserAuthReducerInitialState = {
  signUpEmail: '',
  signUpValues: signUpInitialValues
};

const userAuthReducer = (state = userAuthReducerInitialState, action: IUserAuthAction) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SIGN_UP_EMAIL:
      return {
        ...state,
        signUpEmail: payload
      };

    case SET_SIGN_UP_VALUES:
      return {
        ...state,
        signUpValues: payload
      };
    default:
      return state;
  }
};

export default userAuthReducer;
