import { useNavigate } from 'react-router-dom';

import API_ENDPOINTS from '../../../constants/api_endpoints';
import { routes } from '../../../constants/routes';
import getUserData from '../../../helpers/getUserData';
import getWithAuth from '../../../services/getWithAuth';
import { setHeading } from '../../../store/actions';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import useComponentMount from '../../componentMount/useComponentMount';
import { useAppDispatch } from '../../redux/useRedux';

/**
 * This hook checks whether there are any active cloud accounts otherwise,
 * It would sent the user to access denied page.
 * @returns void;
 */
const useNoCloudAccountsAccessDenied = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { email } = getUserData();

  const checkActiveCloudAccounts = async () => {
    const myProfileResponse = await getWithAuth({
      url: API_ENDPOINTS.GET_USER,
      params: { email }
    });

    const myProfileData = myProfileResponse?.data?.data as IMyProfileState;

    const { activeCloudAccount } = myProfileData;

    if (!activeCloudAccount) {
      dispatch(setHeading(''));
      navigate(routes.ACCESS_DENIED);
    }
  };

  useComponentMount({
    onMountFunc: checkActiveCloudAccounts
  });
};

export default useNoCloudAccountsAccessDenied;
