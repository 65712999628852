import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { permissions } from '@cloudinspot/cis-common-constants';

import './addGroupPermissions.scss';

import Permissions from '../../permissions/Permissions';
import { BUTTON_TEXT } from '../../../../configs';
import { useAppDispatch } from '../../../../hooks/redux/useRedux';
import { addGroupName, setCurrentActiveTabForm } from '../../../../store/actions';
import { routes } from '../../../../constants/routes';
import { IPermissionsList } from '../../../../store/teamManagement/teamManagement.interface';
import { IAddGroupPermissions } from '../../AddUser/addUser.interface';
import ArrowShaftIcon from '../../../common/icons/arrowShaftIcon/ArrowShaftIcon';
import Typography from '../../../common/typography/Typography';
import HeadingText from '../../common/headingText/HeadingText';
import { TEAM_MANAGEMENT_TEXT } from '../../../../configs/teamManagement';
import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';
import useCalculateElementHeight from '../../../../hooks/common/calculateElementHeight/useCalculateElementHeight';
import CustomButton from '../../../common/buttons/CustomButton';

const AddGroupPermissions = ({
  addGroupSelectedPermissions,
  setAddGroupSelectedPermissions
}: IAddGroupPermissions) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const permissionsRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(0));
  };

  const goToTeamManagement = () => {
    // Take the user back to team management screen
    // wipe out data from redux store
    dispatch(addGroupName(''));
    navigate(routes.TEAM_MANAGEMENT_GROUPS_TABLE);
  };

  const handleNext = () => {
    dispatch(setCurrentActiveTabForm(2));
  };

  const { elementHeight } = useCalculateElementHeight({
    ref: permissionsRef,
    triggers: [],
    // gap between bottom of table and bottom of screen as per figma
    heightOffest: 70
  });

  return (
    <div
      className="add-group-permissions-container"
      ref={permissionsRef}
      style={{ height: elementHeight ? `${elementHeight}px` : 'auto' }}
    >
      <div className="add-group-permissions-container__go-back" onClick={handleGoBack}>
        <ArrowShaftIcon direction="left" />
        <Typography variant="caption" size="semiBold">
          {BUTTON_TEXT.BACK}
        </Typography>
      </div>
      <HeadingText text={TEAM_MANAGEMENT_TEXT.PERMISSIONS} color={BLACK_COLORS[900]} />
      <div className="add-group-permissions-container__permissions">
        <Permissions
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userPermissions={permissions as unknown as IPermissionsList[]}
          selectedPermissions={addGroupSelectedPermissions}
          setSelectedPermissions={setAddGroupSelectedPermissions}
          includeDefaultPermissions={false}
        />
      </div>
      <div className="add-group-permissions-container__navigation">
        <div className="add-group-permissions-container__navigation__backward">
          <CustomButton className="cancel" onClick={goToTeamManagement} text={BUTTON_TEXT.CANCEL} />
        </div>
        <div className="add-group-permissions-container__navigation__forward">
          <CustomButton className="next" onClick={handleNext} text={BUTTON_TEXT.NEXT} />
        </div>
      </div>
    </div>
  );
};

export default AddGroupPermissions;
