import React, { ReactNode } from 'react';

type ConditionalProps = {
  condition: boolean;
  whenTrue: ReactNode;
  whenFalse: ReactNode;
};

function Conditional({ condition, whenTrue, whenFalse }: ConditionalProps) {
  return condition ? whenTrue : whenFalse;
}

export default React.memo(Conditional);
