import { Pagination } from '@mui/material';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import CustomTableNoResults from './CustomTableNoResults';
import CustomTableSkeletonLoader from './CustomTableSkeletonLoader';
import { ICustomTable, TTableRowData } from './customTable.interface';
import './customTable.scss';

import { CUSTOM_TABLE } from '../../../configs';
import { selectDeslectCheckbox } from '../../../utils';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import Conditional from '../conditionalComponent/Conditional';
import ArrowIcon from '../icons/arrowIcon/ArrowIcon';
import SortIcon from '../sortIcon/SortIcon';
import SubMenuModal from '../subMenuModal/SubMenuModal';

const CustomTable = ({
  noData,
  tableColumns,
  tableData,
  totalItems,
  rowsPerPageOptions,
  rowsPerPage,
  setRowsPerPage,
  pageNumber,
  setPageNumber,
  selection = false,
  rowIdKey = 'id',
  selectedRows = [],
  setSelectedRows,
  onRowClick,
  rowPropsFunc,
  isLoading = false,
  noResultsContent,
  shouldShowPagination = true,
  sortState,
  shouldUpdatePageNumberOnItemChange = true,

  setSortState
}: ICustomTable) => {
  const [showSelectEntries, setShowSelectEntries] = useState<boolean>(false);

  const numberOfPages = Math.ceil(totalItems / rowsPerPage) || 1;

  const openSelectEntriesDropdown = () => {
    setShowSelectEntries(true);
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const onselection = (value: string) => {
    if (setSelectedRows) {
      const newArray = selectDeslectCheckbox({ arr: selectedRows, value }) as string[];
      setSelectedRows(() => [...newArray]);
    }
  };

  const selectAllRows = () => {
    const allRowIds = tableData.map((row) => row[rowIdKey]) as (string | number)[];

    if (setSelectedRows) {
      if (selectedRows.length === allRowIds.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(allRowIds);
      }
    }
  };

  const handleRowClick = ({ rowData }: { rowData: TTableRowData }) => {
    if (onRowClick) {
      onRowClick({ rowData });
    }
  };

  useEffect(() => {
    if (shouldUpdatePageNumberOnItemChange) {
      setPageNumber(1);
    }
  }, [rowsPerPage]);

  return (
    <>
      <PaperCustom className="common-table-paper" variant="high">
        <TableContainer className="common-table-container">
          <Conditional
            condition={isLoading}
            whenTrue={<CustomTableSkeletonLoader />}
            whenFalse={
              <Table
                aria-label="simple table"
                className="common-table-container__table"
                stickyHeader
              >
                <TableHead className="common-table-container__table__head">
                  <TableRow className="common-table-container__table__head__row">
                    {selection && (
                      <TableCell className="common-table-container__table__body__row__cell">
                        <input
                          type="checkbox"
                          checked={selectedRows.length === tableData.length}
                          className="checkbox"
                          onChange={selectAllRows}
                        />
                      </TableCell>
                    )}
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.key}
                        {...column.headerCellProps}
                        className={classNames(
                          'common-table-container__table__head__row__cell',
                          column?.headerCellProps?.className
                        )}
                        {...column.headerCellActions}
                      >
                        <div className="cell-inner">
                          {column.headElement()}
                          {column.sortable && sortState && setSortState && (
                            <SortIcon
                              currentSortColumn={sortState.sortColumn}
                              columnName={column.dataIndex}
                              sortType={sortState.sortType}
                              setDataSort={setSortState}
                            />
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="common-table-container__table__body">
                  {tableData && tableData.length ? (
                    tableData.map((dataObject, index) => {
                      const rowId = dataObject[rowIdKey] as string;
                      const rowProps = rowPropsFunc ? rowPropsFunc({ rowData: dataObject }) : {};
                      return (
                        <TableRow
                          key={index}
                          {...rowProps}
                          className={`common-table-container__table__body__row ${rowProps.className}`}
                          selected={selectedRows.includes(rowId)}
                          onClick={() => {
                            const rowData = dataObject as unknown as TTableRowData;
                            handleRowClick({ rowData });
                          }}
                        >
                          {selection && (
                            <TableCell
                              key={index}
                              className="common-table-container__table__body__row__cell"
                            >
                              <input
                                type="checkbox"
                                onChange={() => onselection(rowId)}
                                className="checkbox"
                                checked={selectedRows.includes(rowId)}
                              />
                            </TableCell>
                          )}
                          {tableColumns.map((column, index) => {
                            return (
                              <TableCell
                                key={column.key}
                                {...column.rowCellProps}
                                className={classNames(
                                  'common-table-container__table__body__row__cell',
                                  column?.rowCellProps?.className
                                )}
                              >
                                {column.bodyElement(
                                  dataObject[column.dataIndex],
                                  dataObject,
                                  index
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : noData ? (
                    noData
                  ) : (
                    <CustomTableNoResults
                      colSpan={tableColumns.length}
                      content={noResultsContent}
                    />
                  )}
                </TableBody>
              </Table>
            }
          />
        </TableContainer>
      </PaperCustom>

      <Conditional
        condition={shouldShowPagination}
        whenTrue={
          <div className="common-table__pagination">
            <div className="common-table__pagination__rows-per-page">
              <span className="apply-loader">{CUSTOM_TABLE.VIEW_ENTRIES}&#58;</span>
              <div className="common-table__pagination__rows-per-page__select apply-loader">
                <button
                  type="button"
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.stopPropagation();
                    openSelectEntriesDropdown();
                  }}
                >
                  <span>
                    {rowsPerPage}&nbsp;{CUSTOM_TABLE.ENTRIES}
                  </span>
                  <ArrowIcon />
                </button>
                {showSelectEntries && (
                  <SubMenuModal setOpenSubMenuModal={setShowSelectEntries}>
                    <div className="common-table__pagination__rows-per-page__select__items">
                      {rowsPerPageOptions.map((num) => (
                        <div
                          className="common-table__pagination__rows-per-page__select__items__item"
                          onClick={() => setRowsPerPage(num)}
                          key={num}
                        >
                          {num}
                        </div>
                      ))}
                    </div>
                  </SubMenuModal>
                )}
              </div>
            </div>
            <div className="common-table__pagination__page-numbers">
              <Stack spacing={2}>
                <Pagination
                  count={numberOfPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                  page={pageNumber}
                  siblingCount={0}
                  className="apply-loader"
                />
              </Stack>
            </div>
          </div>
        }
        whenFalse={<></>}
      />
      {/* Pagination starts */}

      {/* Pagination ends */}
    </>
  );
};

export default React.memo(CustomTable);
