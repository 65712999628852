import * as Yup from 'yup';

import { Countries, Currency, Timezone } from '../../../enums';

const organizationValidationSchema = Yup.object().shape({
  organizationName: Yup.string().required('Please enter your organization name'),
  organizationCountry: Yup.mixed<Countries>()
    .oneOf(Object.values(Countries), 'Please select your country from the available options')
    .required('Please select your Country'),
  organizationCity: Yup.string().required('Please select your City'),
  organizationCurrency: Yup.mixed<Currency>()
    .oneOf(Object.values(Currency), 'Please select your currency from the available options')
    .required('Please select your Currency'),
  organizationTimezone: Yup.mixed<Timezone>()
    .oneOf(Object.values(Timezone), 'Please select your timezone from the available options')
    .required('Please select your Timezone')
});

export default organizationValidationSchema;
