import { useState } from 'react';

const useConfirmPasswordVisibility = () => {
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible((prevVisible) => !prevVisible);
  };

  return {
    confirmPasswordVisible,
    toggleConfirmPasswordVisibility,
    confirmPasswordInputType: confirmPasswordVisible ? 'text' : 'password'
  };
};

export default useConfirmPasswordVisibility;
