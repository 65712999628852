import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './msteam.scss';

import IMAGES from '../../../../assets';
import { confirmSuccess } from '../../../../assets/images';
import { BUTTON_TEXT, CLOUD_ACCOUNTS_TEXT } from '../../../../configs';
import ENUMS from '../../../../enums';
import useCheckUserPermissions from '../../../../hooks/cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import { disconnectMsTeams } from '../../../../services/msTeams.service';
import {
  TDisconnectMsTeamsRequest,
  TDisconnectMsTeamsResponse,
  TMSTeamsMapping
} from '../../../../services/types/msTeams.endpoints';
import useOrganizationStore from '../../../../store/orgnization/useOrgnizationStore';
import { getDateFromString } from '../../../../utils/dateAndHourFormatting/dateAndHourFormatting';
import CloudInSpotName from '../../../cloudInSpotName/CloudInSpotName';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import RestoreArchiveUserModal, {
  TYPE_ALIGNMENT
} from '../../../common/restoreArchiveUserModal/RestoreArchiveUserModal';
import Typography from '../../../common/typography/Typography';

const { TableStatusTooltipText } = ENUMS.cloudAccounts;
function ConnectedMsTeamsView({
  viewMSTeamsDetails,
  msTeamsMapping,
  loading
}: {
  msTeamsMapping: TMSTeamsMapping | null;
  loading: boolean;
  viewMSTeamsDetails: () => Promise<void>;
  setMsTeamsMapping: Dispatch<SetStateAction<TMSTeamsMapping | null>>;
}) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const param = useParams();

  const [msTeamsDisconnectSuccess, setMsTeamsDisconnectSuccess] = useState<boolean>(false);
  const [isDisconnectMsTeamsOpen, setisDisconnectMsTeamsOpen] = useState<boolean>(false);
  const { canUserAddCloudAccount } = useCheckUserPermissions();
  const { organization } = useOrganizationStore();

  const handleReconfigMSTeams = () =>
    navigate(`/cloud-account/view/msteam/connect/${param?.id}`, {
      state: {
        reconfiguration: true
      }
    });

  const handleDisconnect = async () => {
    if (id) {
      try {
        const disconnectMsTeamsRequest: TDisconnectMsTeamsRequest = { accountId: id };
        const disconnectMSTeamResponse: TDisconnectMsTeamsResponse = await disconnectMsTeams(
          disconnectMsTeamsRequest
        );

        if (disconnectMSTeamResponse) {
          setMsTeamsDisconnectSuccess(true);
          setisDisconnectMsTeamsOpen(false);
        }
      } catch (error) {
        useErrorHandler({
          error,
          toastId: 'MSTeamsDisconnectFail',
          defaultMessage: 'Error in Ms Teams disconnection'
        });
      }
    }
  };

  return (
    <PaperCustom
      className={`msteam connectSteps view ${loading ? 'msteam_loading' : ''}`}
      variant="medium"
    >
      <div className="msteamContainer">
        <div className="msteamContainer__msteamHeaderView">
          <Typography
            variant="body-2"
            size="medium"
            className="msteamContainer__msteamHeaderView__header apply-loader"
          >
            MS Teams Integration
          </Typography>

          <div className="msteamButtons">
            <TooltipCustom
              text={'You do not have access to disconnect ms teams'}
              conditionToDisplay={!canUserAddCloudAccount}
            >
              <CustomButton
                text={CLOUD_ACCOUNTS_TEXT.DISCONNECT}
                variant={ButtonVariants.SECONDARY}
                image={IMAGES.disconnectBlueLeft}
                className="apply-loader disconnect-button"
                buttonWidth="149px"
                onClick={() => setisDisconnectMsTeamsOpen(true)}
                disabled={!canUserAddCloudAccount}
              />
            </TooltipCustom>
            <TooltipCustom
              text={'You do not have access to reconfigure  ms teams'}
              conditionToDisplay={!canUserAddCloudAccount}
            >
              <CustomButton
                text={CLOUD_ACCOUNTS_TEXT.RECONFIGURE}
                image={IMAGES.reconnectIcon}
                className="apply-loader recofig-button"
                buttonWidth="156px"
                onClick={handleReconfigMSTeams}
                disabled={!canUserAddCloudAccount}
              />
            </TooltipCustom>
          </div>
        </div>

        <div className="grid-container">
          <div className="grid-container__grid-item ">
            <Typography
              variant="body-3"
              size="medium"
              as="h4"
              className="grid-container__grid-item__key apply-loader"
            >
              Connected On
            </Typography>
            <Typography
              variant="body-3"
              size="regular"
              className="grid-container__grid-item__value apply-loader"
            >
              {getDateFromString(
                msTeamsMapping?.createdAt || '',
                organization.organizationAbbreviation,
                organization.organizationTimeZone
              )}
            </Typography>
          </div>
          <div className="grid-container__grid-item">
            <Typography
              variant="body-3"
              size="medium"
              as="h4"
              className="grid-container__grid-item__key apply-loader"
            >
              MS Teams Connection Status
            </Typography>
            <TooltipCustom text={TableStatusTooltipText.Connected}>
              <div className="ms_teams_status_container cursor-pointer apply-loader">
                <span className="apply-loader">{msTeamsMapping?.status}</span>
              </div>
            </TooltipCustom>
          </div>

          <div className="grid-container__grid-item">
            <Typography
              variant="body-3"
              size="medium"
              as="h4"
              className="apply-loader grid-container__grid-item__key apply-loader"
            >
              Connected By
            </Typography>
            <Typography
              className="grid-container__grid-item__value apply-loader"
              variant="body-3"
              size="regular"
            >
              {`${msTeamsMapping?.msTeamsInstalledBy.firstName} ${msTeamsMapping?.msTeamsInstalledBy.lastName}`}
            </Typography>
          </div>
        </div>

        {msTeamsDisconnectSuccess && (
          <ConfirmModal
            open={msTeamsDisconnectSuccess}
            setOpen={setMsTeamsDisconnectSuccess}
            title="Disconnected!"
            description={
              <>
                Disconnected MS Teams channel from <CloudInSpotName /> successfully.
              </>
            }
            descriptionWidth="88%"
            image={confirmSuccess}
            confirmButtonText={BUTTON_TEXT.OKAY}
            handleOkClick={viewMSTeamsDetails}
          />
        )}

        {isDisconnectMsTeamsOpen && (
          <RestoreArchiveUserModal
            type={TYPE_ALIGNMENT.LEFT}
            open={isDisconnectMsTeamsOpen}
            heading="Confirm Disconnection"
            actionButtonText="Yes, Disconnect"
            description="Are you sure you want to disconnect your MS Teams connection from cloud account? You’ll no longer receive notifications in your MS Teams."
            handlerFun={() => {
              handleDisconnect();
            }}
            handleClose={() => {
              setisDisconnectMsTeamsOpen(false);
            }}
          />
        )}
      </div>
    </PaperCustom>
  );
}

export default ConnectedMsTeamsView;
