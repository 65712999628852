// External imports
import classNames from 'classnames';
import React, { ReactNode } from 'react';

// Internal imports
import './summaryStats.scss';

import Typography from '../../../common/typography/Typography';
import PaperCustom from '../../atoms/PaperCustom/PaperCustom';

// Types
export type TSummaryStatItem = {
  label: string | ReactNode;
  icon: string | ReactNode;
  value: number | string;
  className?: string;
};

// Interfaces
interface ISummaryStatsProps {
  data: TSummaryStatItem[];
  className?: string;
}

const SummaryStats = ({ data, className }: ISummaryStatsProps) => {
  return (
    <div className={classNames('molecule-summary-stats', className)}>
      {' '}
      {/* Modify this line */}
      {data.map((item, index) => (
        <PaperCustom
          variant="light"
          key={index}
          className={classNames('molecule-summary-stats__item', item?.className)}
        >
          <div className="molecule-summary-stats__item__left">
            <div className="molecule-summary-stats__item__left__icon apply-loader">{item.icon}</div>
            <Typography
              variant="body-3"
              size="medium"
              as="p"
              className="molecule-summary-stats__item__left__label apply-loader"
            >
              {item.label}
            </Typography>
          </div>
          <div className="molecule-summary-stats__item__right apply-loader">
            <Typography
              variant="body-2"
              size="semiBold"
              as="p"
              className="molecule-summary-stats__item__right__value apply-loader"
            >
              {item.value}
            </Typography>
          </div>
        </PaperCustom>
      ))}
    </div>
  );
};

export default React.memo(SummaryStats);
