import { TGetTeamsRequest, TGetUsersRequest } from '../../services/types/teamManagement.endpoints';

export const getFetchUsersQueryKey = (getUsersRequest: TGetUsersRequest) => [
  'teamUsers',
  {
    ...getUsersRequest
  }
];

// ['teams', { pageNumber, rowsPerPage, filterGroup: searchString.value }]

export const getFetchTeamsQueryKey = (getTeamsRequest: TGetTeamsRequest) => [
  'teams',
  {
    ...getTeamsRequest
  }
];

export const getFetchArchivedUsersQueryKey = (getUsersRequest: TGetUsersRequest) => [
  'archivedUsers',
  {
    ...getUsersRequest
  }
];
