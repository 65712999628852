import { ITeamMangUser } from '../../../store/teamManagement/teamManagement.interface';
import getUserData from '../../../helpers/getUserData';

interface IDisableUserRowCalcProps {
  userData: ITeamMangUser;
  isloggedInUserOwner: boolean;
  isloggedInUserAdmin: boolean;
  canUserViewAnotherUser: boolean;
}

interface IDisableUserRowCalcReturn {
  disabled: boolean;
  tooltipText: string;
}

/**
 * Determines whether the row in the team management users table is clickable by the logged-in user.
 *
 * @param  userData container the data of user in row
 * @param  isloggedInUserOwner Whether the logged in user is Owner
 * @param  isloggedInUserAdmin Whether the logged in user is Admin
 * @param  canUserViewAnotherUser Whether the logged in user has view other user profile screen
 * @returns {disabled, tooltipText} true -> disable first name and last, tooltipText -> additional feedback to the user in the form of text.
 */
const disableUserRowCalc = ({
  userData,
  isloggedInUserOwner,
  isloggedInUserAdmin,
  canUserViewAnotherUser
}: IDisableUserRowCalcProps): IDisableUserRowCalcReturn => {
  // Requirements: Disable owner row, It'll be visible but not clickable.
  // An admin can delete another admin but an admin can't delete himself.
  // User is considered owner, if they have isOwner flag
  const isUserOwner: boolean = userData?.isOwner;

  const loggedInUserData = getUserData();

  if (isloggedInUserOwner) {
    return { disabled: false, tooltipText: '' };
  }
  if (isloggedInUserAdmin) {
    if (isUserOwner) {
      return { disabled: true, tooltipText: "You cannot view owner's profile" };
    }
    return { disabled: false, tooltipText: '' };
  }
  if (canUserViewAnotherUser) {
    if (isUserOwner) {
      return { disabled: true, tooltipText: "You cannot view owner's profile" };
    }
    return { disabled: false, tooltipText: '' };
  }
  if (!canUserViewAnotherUser) {
    if (loggedInUserData.email === userData.email) {
      // Self profile
      return {
        disabled: true,
        tooltipText:
          "You do not have permission to view other user's profile page. If this row belongs to you, please access your profile from the header"
      };
    }
    return {
      disabled: true,
      tooltipText: "You do not have permission to view other user's profile."
    };
  }
  return { disabled: true, tooltipText: '' };
};

export default disableUserRowCalc;
