import { INoDataScreenProps } from './noData.interface';
import './noData.scss';

import CustomButton, { ButtonVariants } from '../buttons/CustomButton';
import TooltipCustom from '../molecules/tooltipCustom/TooltipCustom';
import InfoIcon from '../icons/infoIcon/InfoIcon';
import PaperCustom from '../atoms/PaperCustom/PaperCustom';
import Typography from '../typography/Typography';
import IMAGES from '../../../assets';

const NoDataScreen = ({
  heading,
  line1,
  line2,
  onClick,
  buttonText,
  img,
  buttonVariant = ButtonVariants.PRIMARY,
  disabled = false,
  tooltiptext,
  buttonWidth
}: INoDataScreenProps) => {
  return (
    <div className="slackEmailEmptyView">
      <PaperCustom variant="medium" className="slackEmailEmptyView__card">
        <img src={img} alt="logo" className="slackEmailEmptyView__card__logo" />
        <Typography variant="body-2" size="semiBold" className="slackEmailEmptyView__card__heading">
          {heading}
        </Typography>
        <div className="slackEmailEmptyView__card__descView">
          <Typography variant="body-3" size="regular">
            {line1}
          </Typography>
          <Typography variant="body-3" size="regular">
            {line2}
          </Typography>
        </div>
        {/* TO-DO @rushikesh @jagadeesh please check this tooltip styles and fix it if required */}
        <div>
          {disabled && (
            <TooltipCustom text={tooltiptext} placement="top">
              <InfoIcon />
            </TooltipCustom>
          )}
          <CustomButton
            variant={buttonVariant}
            text={buttonText}
            image={IMAGES.Plus}
            buttonWidth={buttonWidth}
            imageStyles="slackEmailEmptyView__card__buttonIcon"
            disabled={disabled}
            onClick={onClick}
          />
        </div>
      </PaperCustom>
    </div>
  );
};

export default NoDataScreen;
