import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { AddUserToGroup } from './AddUserToGroup';
import './editGroup.scss';

import { confirmSuccess } from '../../../../assets/images';
import { BUTTON_TEXT } from '../../../../configs';
import { COMMON_TEXT } from '../../../../configs/common';
import { TEAM_MANAGEMENT_TEXT } from '../../../../configs/teamManagement';
import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';
import API_ENDPOINTS from '../../../../constants/api_endpoints';
import { offsetFirst, ROWS_PER_PAGE_FIRST_ITEM } from '../../../../constants/constants';
import { routes } from '../../../../constants/routes';
import useErrorHandler from '../../../../hooks/error-handler/useErrorHandler';
import useLoading from '../../../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import useGetRequest from '../../../../hooks/useGetRequest/useGetRequest';
import postWithAuth from '../../../../services/postWithAuth';
import { editGroupName, setCurrentActiveTabForm } from '../../../../store/actions';
import {
  IPermissionsList,
  ITeamManagementInitialValues,
  ITeamMangUser
} from '../../../../store/teamManagement/teamManagement.interface';
import { ITeamManagementGroupInitialState } from '../../../../store/teamManagement/teamManagementGroup/teamManagementGroup.interface';
import { textEllipsis } from '../../../../utils';
import { getFetchTeamsQueryKey } from '../../../../utils/pre-fetchers/prefetchTeamManagement';
import { trimPayload } from '../../../../utils/trimString/trimString';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import ConfirmModal from '../../../common/confirmModal/ConfirmModal';
import CloseIconBorder from '../../../common/icons/closeIconBorder/CloseIconBorder';
import Loader from '../../../common/loader/loader';
import TooltipCustom from '../../../common/molecules/tooltipCustom/TooltipCustom';
import NoDataFound from '../../../common/noDataFound/noDataFound';
import Typography from '../../../common/typography/Typography';
import GoBack from '../../common/goBack/GoBack';
import HeadingText from '../../common/headingText/HeadingText';

interface IAddUsersProps {
  selectedUsers: ITeamMangUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<ITeamMangUser[]>>;
  editGroupSelectedPermissions: IPermissionsList[];
}

const tooltipCharLimitValue: number = 15;

const AddUsers = ({
  selectedUsers,
  setSelectedUsers,
  editGroupSelectedPermissions
}: IAddUsersProps) => {
  const [searchUsersListCount, setSearchUsersListCount] = useState<number>(10);
  const [userSearchString, setuserSearchString] = useState({ value: '', isDirty: false });
  const [removeUsersFromGroupList, setRemoveUsersFromGroupList] = useState<string[]>([]);
  const [checkedUsers, setCheckedUsers] = useState<ITeamMangUser[]>([]);
  const boxRef = React.createRef<HTMLDivElement>();
  const [noDataFound, setNoDataFound] = useState(false);

  const { permissions: groupPermissions } = useAppSelector(
    (store) => store.teamManagementGroup
  ) as ITeamManagementGroupInitialState;

  const { editGroup1 } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { groupname } = useParams() as unknown as { groupname: string };

  const { data: currentUsersResponse } = useGetRequest({
    url: API_ENDPOINTS.TEAM_MGMT_GET_GROUP_USERS,
    params: {
      groupName: groupname,
      limit: 100,
      offset: 1
    }
  });

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const { loading, startLoading, stopLoading } = useLoading();

  const deselectUser = (user: ITeamMangUser) => {
    // removing from arrays
    setSelectedUsers(selectedUsers.filter((item) => item.email != user.email));

    setCheckedUsers((prev) => {
      const checkedIndex = checkedUsers.findIndex((fUser) => fUser.email === user.email);
      const newList = JSON.parse(JSON.stringify(prev));
      newList.splice(checkedIndex, 1);
      return newList;
    });
  };

  const addToSelectedUsers = (user: ITeamMangUser) => {
    if (selectedUsers.map((user) => user.email).includes(user.email)) {
      deselectUser(user);
      return;
    }
    setSelectedUsers((prev) => [...prev, user]);

    return;
  };

  const handleGoBack = () => {
    dispatch(setCurrentActiveTabForm(1));
  };

  const handleCancel = () => {
    dispatch(editGroupName(''));
    navigate(`${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${groupname.trim()}`);
  };

  const handleOkForModal = () => {
    dispatch(editGroupName(''));
    // User cannot be taken to group view now,
    // Because previously stored data (name, permissions) are now invalid.
    navigate(`${routes.TEAM_MANAGEMENT_VIEW_GROUP}/${editGroup1.groupName.trim()}`);
  };

  const { data: searchUsersResponse, setUseGetRequestCounter } = useGetRequest({
    url: API_ENDPOINTS.TEAM_MGMT_LIST_USERS,
    params: {
      limit: searchUsersListCount,
      offset: 1,
      ...(userSearchString.value && { filterUser: userSearchString.value })
    },
    delay: 500
  });

  const searchUserByName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUsersListCount(10);
    setuserSearchString((prev) => ({ ...prev, value: e.target.value }));
    setUseGetRequestCounter((prev) => prev + 1);
    setNoDataFound(false);
  };

  useEffect(() => {
    // This effect runs when searchUsersResponse changes
    if (searchUsersResponse?.data?.users && searchUsersResponse.data.users.length === 0) {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
    }
  }, [searchUsersResponse]);

  const viewMoreUsers = () => {
    // fetch 10 more users
    setSearchUsersListCount((prev) => prev + 10);
    setUseGetRequestCounter((prev) => prev + 1);
  };

  const removeUsersFromGroup = (user: string) => {
    setRemoveUsersFromGroupList((prev) => {
      return prev.concat(user);
    });

    setCheckedUsers((prev) => {
      const checkedIndex = checkedUsers.findIndex((fUser) => fUser.email === user);
      const newList = JSON.parse(JSON.stringify(prev));
      newList.splice(checkedIndex, 1);
      return newList;
    });
  };

  const allUsers = (searchUsersResponse?.data?.users || []) as ITeamMangUser[];
  const currentUsers = (currentUsersResponse?.data?.users || []) as ITeamMangUser[];
  const allUsersCount = searchUsersResponse?.data?.count as number;

  const existingColumnUsers = currentUsers.filter(
    (filterUser) => !removeUsersFromGroupList.includes(filterUser.email)
  );

  const checkPermissionChange = () => {
    return !_.isEqualWith(editGroupSelectedPermissions, groupPermissions);
  };

  const handleEditGroup = async () => {
    const checkedUsersEmails = checkedUsers.map((cUser) => cUser.email);
    const currentUsersEmails = currentUsers.map((cUser) => cUser.email);

    const usersToRemove = removeUsersFromGroupList.filter(
      (cUser) => !checkedUsersEmails.includes(cUser)
    );
    const usersToAdd = checkedUsersEmails
      .filter((cUser) => !removeUsersFromGroupList.includes(cUser))
      .filter((fUser) => !currentUsersEmails.includes(fUser));
    startLoading();
    try {
      const permissionChanged = checkPermissionChange();
      const payload = {
        groupName: groupname,
        newGroupName: editGroup1.groupName,
        permissionChanged,
        ...(editGroupSelectedPermissions.length > 0 && {
          permissions: editGroupSelectedPermissions
        }),
        ...(usersToAdd.length > 0 && { usersToAdd }),
        ...(usersToRemove.length > 0 && { usersToRemove })
      };

      const response = await postWithAuth({
        url: API_ENDPOINTS.TEAM_MGMT_EDIT_GROUP,
        payload: trimPayload(payload)
      });

      if (response) {
        setIsSuccessModalOpen(true);
        queryClient.resetQueries(
          getFetchTeamsQueryKey({
            limit: ROWS_PER_PAGE_FIRST_ITEM,
            offset: offsetFirst,
            filterGroup: ''
          })
        );
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'teamManagementEditGroupFail',
        defaultMessage:
          'Your request to edit group has failed due to unknown reasons! Please try again later'
      });
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    // Attach the scroll event listener to the box element
    const handleScroll = () => {
      const boxElement = boxRef.current;
      if (!boxElement) return; //return if api is called or don't have reference to the box element

      const boxHeight = boxElement.clientHeight; //height of the box
      const scrollPosition = boxElement.scrollTop; //position of the scroll
      const contentHeight = boxElement.scrollHeight; //height of the total content inside the box

      const threshold = 0.7; // 70% threshold to have a seamless UX

      if (
        boxHeight + scrollPosition > threshold * contentHeight && // scroll is crossed threshold
        allUsersCount - allUsers.length > 0
      ) {
        // You have reached the bottom of the box
        //update the limit and call API with updated limit value
        viewMoreUsers();
      }
    };

    const boxElement = boxRef.current;
    if (boxElement) {
      boxElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Detach the scroll event listener on cleanup
      if (boxElement) {
        boxElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [boxRef]);

  useEffect(() => {
    return () => {
      setSelectedUsers([]);
      setRemoveUsersFromGroupList([]);
      setCheckedUsers([]);
    };
  }, []);

  return (
    <div className="edit-group-add-user">
      <GoBack handleGoBack={handleGoBack} className="add-group-add-user__go-back" />
      <div className="search-selected">
        <PaperCustom variant="light" className="edit_users">
          <HeadingText text={TEAM_MANAGEMENT_TEXT.ADD_USERS} color={BLACK_COLORS[900]} />
          <div className="edit_users__search">
            <input
              type="search"
              className="search_input"
              placeholder="Search Name"
              onChange={searchUserByName}
            />
            <CustomButton
              variant={ButtonVariants.PRIMARY}
              text={COMMON_TEXT.SEARCH}
              onClick={() => {
                setUseGetRequestCounter((prev) => prev + 1);
              }}
            />
          </div>
          <div ref={boxRef} className="list-container">
            {noDataFound ? (
              <NoDataFound />
            ) : (
              allUsers.map((user, index) => (
                <AddUserToGroup
                  user={user}
                  key={index}
                  addToSelectedUsers={addToSelectedUsers}
                  index={index}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  currentUsers={existingColumnUsers}
                  removeUsersFromGroupList={removeUsersFromGroupList}
                  setRemoveUsersFromGroupList={setRemoveUsersFromGroupList}
                  checkedUsers={checkedUsers}
                  setCheckedUsers={setCheckedUsers}
                />
              ))
            )}
          </div>
        </PaperCustom>
        <PaperCustom variant="light" className="selected-users-container">
          <div className="selected-users-container__heading">
            <HeadingText text={TEAM_MANAGEMENT_TEXT.EXISTING_USERS} color={BLACK_COLORS[900]} />
            <Typography variant="body-3" size="regular" color={BLACK_COLORS[900]}>
              &#40;{existingColumnUsers.length}&nbsp;{TEAM_MANAGEMENT_TEXT.USER_SELECTED}
              &#41;
            </Typography>
          </div>
          <div className="selected-users-container_list">
            {existingColumnUsers.map((user, index) => {
              const nameToDisplay = `${user.firstName} ${user.lastName}`;
              return (
                <TooltipCustom
                  text={
                    <div className="user-tooltip">
                      <Typography variant="body-3" size="regular" as="p">
                        {nameToDisplay}
                      </Typography>
                      <Typography variant="body-3" size="regular" as="p">
                        {user.email}
                      </Typography>
                    </div>
                  }
                  key={index}
                  containerProps={{
                    className: 'tm-add-group-selected-user',
                    onClick: (e) => {
                      e.preventDefault();
                    }
                  }}
                  limitWidth={false}
                  conditionToDisplay={
                    nameToDisplay?.length >= tooltipCharLimitValue ||
                    user.email?.length >= tooltipCharLimitValue
                  }
                >
                  <div className="tm-add-group-selected-user__user">
                    <Typography
                      variant="body-3"
                      size="medium"
                      as="p"
                      className="tm-add-group-selected-user__user__name"
                    >
                      {textEllipsis({ text: nameToDisplay, charLimit: tooltipCharLimitValue })}
                    </Typography>{' '}
                    <Typography
                      variant="body-3"
                      size="regular"
                      as="p"
                      className="tm-add-group-selected-user__user__email"
                    >
                      {textEllipsis({ text: user.email, charLimit: tooltipCharLimitValue })}
                    </Typography>
                  </div>
                  <CloseIconBorder
                    color={BLACK_COLORS[700]}
                    containerProps={{
                      className: 'tm-add-group-selected-user__close-icon',
                      onClick: () => {
                        removeUsersFromGroup(user.email);
                      }
                    }}
                  />
                </TooltipCustom>
              );
            })}
          </div>
        </PaperCustom>
        <PaperCustom variant="light" className="selected-users-container">
          <div className="selected-users-container__heading">
            <HeadingText text={TEAM_MANAGEMENT_TEXT.SELECTED_USERS} color={BLACK_COLORS[900]} />
            <Typography variant="body-3" size="regular" color={BLACK_COLORS[900]}>
              &#40;{selectedUsers.length}&nbsp;{TEAM_MANAGEMENT_TEXT.USER_SELECTED}
              &#41;
            </Typography>
          </div>
          <div className="selected-users-container_list">
            {selectedUsers.map((user, index) => {
              const nameToDisplay = `${user.firstName} ${user.lastName}`;
              return (
                <TooltipCustom
                  text={
                    <div className="user-tooltip">
                      <Typography variant="body-3" size="regular" as="p">
                        {nameToDisplay}
                      </Typography>
                      <Typography variant="body-3" size="regular" as="p">
                        {user.email}
                      </Typography>
                    </div>
                  }
                  key={index}
                  containerProps={{
                    className: 'tm-add-group-selected-user',
                    onClick: (e) => {
                      e.preventDefault();
                    }
                  }}
                  conditionToDisplay={
                    nameToDisplay?.length >= tooltipCharLimitValue ||
                    user.email?.length >= tooltipCharLimitValue
                  }
                  limitWidth={false}
                >
                  <div className="tm-add-group-selected-user__user">
                    <Typography
                      variant="body-3"
                      size="medium"
                      as="p"
                      className="tm-add-group-selected-user__user__name"
                    >
                      {textEllipsis({ text: nameToDisplay, charLimit: tooltipCharLimitValue })}
                    </Typography>
                    <Typography
                      variant="body-3"
                      size="regular"
                      as="p"
                      className="tm-add-group-selected-user__user__email"
                    >
                      {textEllipsis({ text: user.email, charLimit: tooltipCharLimitValue })}
                    </Typography>
                  </div>{' '}
                  <CloseIconBorder
                    color={BLACK_COLORS[700]}
                    containerProps={{
                      className: 'tm-add-group-selected-user__close-icon',
                      onClick: () => {
                        deselectUser(user);
                      }
                    }}
                  />
                </TooltipCustom>
              );
            })}
          </div>
        </PaperCustom>
      </div>
      <div className="edit_user_buttons">
        <CustomButton
          variant={ButtonVariants.SECONDARY}
          type="button"
          text={BUTTON_TEXT.CANCEL}
          className="cancel"
          onClick={handleCancel}
        />

        <CustomButton
          variant={ButtonVariants.PRIMARY}
          text={loading ? <Loader /> : BUTTON_TEXT.SAVE_CHANGES}
          className="create_group_button"
          onClick={handleEditGroup}
        />
      </div>
      <ConfirmModal
        title={TEAM_MANAGEMENT_TEXT.GROUP_UPDATED}
        description={TEAM_MANAGEMENT_TEXT.GROUP_UPDATE_SUCCESS}
        image={confirmSuccess}
        setOpen={setIsSuccessModalOpen}
        open={isSuccessModalOpen}
        confirmButtonText="OKAY"
        handleOkClick={handleOkForModal}
      />
    </div>
  );
};

export default AddUsers;
