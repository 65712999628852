import axios from 'axios';
import { Dispatch } from 'react';

import { UPDATE_FAQS } from './faq.actionTypes';
import { IFaq, IFetchFaqsAction } from './faq.interface';

import API_ENDPOINTS from '../../constants/api_endpoints';
import useErrorHandler from '../../hooks/error-handler/useErrorHandler';

export const updateFaqs = (state: { faqs: IFaq[] }) => ({
  type: UPDATE_FAQS,
  payload: state
});

export const fetchFaqsRequest = ({ callbackFunc }: { callbackFunc: () => void }) => {
  return async (dispatch: Dispatch<IFetchFaqsAction>) => {
    try {
      const response = await axios.get(API_ENDPOINTS.FAQS, {
        //Prevent cache
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      });
      if (response) {
        dispatch(updateFaqs({ faqs: response?.data || [] }));
        callbackFunc();
      }
    } catch (error) {
      useErrorHandler({
        error,
        toastId: 'fetchFaqsFail',
        defaultMessage:
          'Your request to fetch FAQs has failed due to unknown reasons! Please try again later'
      });
    }
  };
};
