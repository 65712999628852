import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { debounce } from '@mui/material';
import classNames from 'classnames';

import './searchForm.scss';
import { type TSearchFormValues, type TSearchFormProps } from './searchForm.types';

import IMAGES from '../../../assets';
import { COMMON_TEXT } from '../../../configs/common';
import CustomButton, { ButtonVariants } from '../buttons/CustomButton';

const delay: number = 500;

const SearchForm = ({
  placeholder = COMMON_TEXT.SEARCH,
  className,
  shouldShowSearchButton = false,
  onSearch,
  debounceDelay = delay
}: TSearchFormProps) => {
  const initialValues: TSearchFormValues = {
    query: ''
  };

  const handleSubmit = (searchFormValues: TSearchFormValues) => {
    onSearch(searchFormValues);
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => onSearch({ query: value }), debounceDelay),
    [onSearch, debounceDelay]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue }) => {
        const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const value: string = e.target.value;
          setFieldValue('query', value);
          debouncedSearch(value);
        };

        return (
          <Form className={classNames('search-form', className, 'apply-loader')}>
            <div className={classNames('search-form__input-field', `${className}__input-field`)}>
              <img src={IMAGES.searchIcon} alt="Search Icon" />
              <input
                type="search"
                id="query"
                placeholder={placeholder}
                onChange={handleQueryChange}
                className="search-form__input"
              />
            </div>
            {shouldShowSearchButton && (
              <CustomButton
                variant={ButtonVariants.PRIMARY}
                text={COMMON_TEXT.SEARCH}
                type="submit"
                className="search-form__submit-button"
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchForm;
