import authHttpCloudService from './authHttpCloudAccount';

import { IPost } from '../service.interface';

const postWithCloudAuth = async ({ url, payload }: IPost) => {
  return authHttpCloudService
    .post(url, payload)
    .then((response) => {
      localStorage.setItem('retryCounter', '0');
      return response;
    })
    .catch((error: unknown) => {
      throw error;
    });
};
export default postWithCloudAuth;
