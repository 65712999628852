// Internal imports
import React from 'react';

import { ERRORS as ERRORS_COLORS, PRIMARY as PRIMARY_COLORS } from '../../../configs/v3.colors';
import useAccountSummaryStore from '../../../store/cloudAccounts/useAccountSummaryStore';
import ClockIcon from '../../common/icons/clockIcon/ClockIcon';
import CloseIconBorder from '../../common/icons/closeIconBorder/CloseIconBorder';
import CloudIcon from '../../common/icons/cloudIcon/CloudIcon';
// import CloudStruckIcon from '../../common/icons/cloudStruckIcon/CloudStruckIcon';
import SummaryStats, { TSummaryStatItem } from '../../common/molecules/summaryStats/SummaryStats';
// import Typography from '../../common/typography/Typography';

const CloudAccountsSummaryStats = () => {
  const {
    // accountConnectedThisMonth,
    // accountDisconnectedThisMonth,
    totalActiveAccounts,
    totalInprogressAccounts,
    totalFailedUnreachableAccounts
  } = useAccountSummaryStore();

  const data: TSummaryStatItem[] = [
    // {
    //   label: (
    //     <div className="summary_card_container">
    //       Accounts Connected{' '}
    //       <Typography variant="caption" size="regular" as="span">
    //         (This Month)
    //       </Typography>
    //     </div>
    //   ),
    //   icon: <CloudIcon color={PRIMARY_COLORS[600]} />,
    //   value: accountConnectedThisMonth
    // },
    // {
    //   label: (
    //     <div className="summary_card_container">
    //       Accounts Disconnected{' '}
    //       <Typography variant="caption" size="regular" as="span">
    //         (This Month)
    //       </Typography>
    //     </div>
    //   ),
    //   icon: <CloudStruckIcon color={PRIMARY_COLORS[600]} />,
    //   value: accountDisconnectedThisMonth
    // },
    // {
    //   label: (
    //     <div className="summary_card_container">
    //       Cloud Groups Created{' '}
    //       <Typography variant="caption" size="regular" as="span">
    //         (This Month)
    //       </Typography>
    //     </div>
    //   ),
    //   icon: <CloudIcon color={PRIMARY_COLORS[600]} />,
    //   value: '--'
    // },
    {
      label: <>Total Active Accounts</>,
      icon: <CloudIcon color={PRIMARY_COLORS[600]} />,
      value: totalActiveAccounts
    },
    {
      label: <>In-Progress Accounts</>,
      icon: <ClockIcon color={PRIMARY_COLORS[600]} />,
      value: totalInprogressAccounts
    },
    {
      label: <>Failed/Unreachable Accounts</>,
      icon: (
        <div className="close-icon">
          <CloseIconBorder color={ERRORS_COLORS[700]} />
        </div>
      ),
      value: totalFailedUnreachableAccounts,
      className: 'cloud-account-summary-red'
    }
  ];

  return <SummaryStats data={data} className="cloud-account-summary-stats" />;
};

export default React.memo(CloudAccountsSummaryStats);
