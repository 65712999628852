import {
  SET_NOTIFICATION_SETTINGS,
  SET_ORGANIZATION_SETTINGS,
  TOGGLE_SETTINGS_EDIT_MODE
} from './settings.actionTypes';
import { ISettingAction, ISettingsReducerInitialState } from './settings.interface';

import { getOrgData } from '../../helpers/getOrgData';
import {
  Frequency,
  NotificationSettings
} from '../../components/settings/notifications/notification.interface';

const { zone, orgName, organizationAbbreviation } = getOrgData();

export const notificationInitialState: NotificationSettings = {
  cost: {
    notification: {
      slackNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      teamsNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      mailNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      inAppNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      }
    },
    reports: {
      mailNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      slackNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      teamsNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      }
    }
  },
  saving: {
    notification: {
      slackNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      teamsNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      mailNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      inAppNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      }
    },
    reports: {
      mailNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      slackNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      teamsNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      }
    }
  },
  anomaly: {
    notification: {
      slackNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      teamsNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      mailNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      inAppNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      }
    },
    reports: {
      mailNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      slackNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      },
      teamsNotification: {
        frequency: Frequency.Empty,
        enabled: false,
        allowEdit: false,
        scheduledTime: ''
      }
    }
  }
};

const settingsReducerInitialState: ISettingsReducerInitialState = {
  organizationData: {
    name: orgName,
    country: '',
    state: '',
    currency: '',
    timezone: zone,
    abbreviation: organizationAbbreviation || '',
    gmtOffsetName: ''
  },
  isSettingsEditModeOn: false,
  notificationSettings: notificationInitialState
};

const settingsReducer = (
  state: ISettingsReducerInitialState = settingsReducerInitialState,
  action: ISettingAction
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ORGANIZATION_SETTINGS:
      return {
        ...state,
        organizationData: payload
      };

    case TOGGLE_SETTINGS_EDIT_MODE:
      return {
        ...state,
        isSettingsEditModeOn: payload
      };
    case SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: payload
      };

    default:
      return state;
  }
};

export default settingsReducer;
