import React, { useEffect, useState } from 'react';

import ENUMS, { PermissionModuleApiKeyEnums } from '../../../enums';
import { PermissionApiEndpointsEnums, PermissionNameEnums } from '../../../enums/common';
import useAllRoutes from '../../../routes/allRoutes';
import { IMyProfileState } from '../../../store/myProfile/myProfile.interface';
import { checkUserPermissions } from '../../cloudAccounts/checkUserPermissions/useCheckUserPermissions';
import { useAppSelector } from '../../redux/useRedux';

const { userGroups } = ENUMS.teamManagement;

interface IUseGetUnauthorizedRoutes {
  route: string;
  component: React.ReactNode;
  apiKey: PermissionModuleApiKeyEnums;
  permissionName: PermissionNameEnums;
  apiEndpoint: PermissionApiEndpointsEnums;
}

const useGetUnauthorizedRoutes = () => {
  const [unauthorizedRoutes, setUnauthorizedRoutes] = useState<IUseGetUnauthorizedRoutes[]>([]);

  const { isOwner, groups, permissions } = useAppSelector<IMyProfileState>(
    (store) => store.MyProfile
  );

  const allRoutes = useAllRoutes();

  const filterUnauthorizedRoutes = () => {
    const routes = allRoutes.filter((route) => {
      // Similar code is written at a diffent place, both will be merged.
      if (isOwner) {
        return false;
      } else if (groups.indexOf(userGroups.Admin) > -1) {
        return false;
      }
      return !checkUserPermissions({
        isOwner,
        groups,
        permissions,
        apiKey: route.apiKey,
        permissionName: route.permissionName,
        apiEndpoint: route.apiEndpoint
      });
    });

    setUnauthorizedRoutes(routes);
  };

  useEffect(() => {
    filterUnauthorizedRoutes();
  }, [isOwner, JSON.stringify(permissions), JSON.stringify(groups)]);

  return unauthorizedRoutes;
};

export default useGetUnauthorizedRoutes;
