import React from 'react';

import { PRIMARY as PRIMARY_COLORS } from '../../../../configs/v3.colors';

interface IChevronIcon {
  color?: string;
  containerProps?: React.HTMLAttributes<SVGElement>;
}

const ChevronIcon = ({ color = PRIMARY_COLORS[1100], containerProps = {} }: IChevronIcon) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...containerProps}
    >
      <path
        d="M12 6.5L8 10.5L4 6.5"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronIcon;
