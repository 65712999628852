import {
  CHECK_USER_DATA_IN_LOCAL_STORAGE,
  CLOSE_ALERT_MESSAGE,
  OPEN_ALERT_MESSAGE,
  TOGGLE_SUBMENU_CARD
} from './Header.actionTypes';
import { IHeaderAlertMessage } from './Header.interface';

export const toggleSubmenuCard = (state: boolean) => ({
  type: TOGGLE_SUBMENU_CARD,
  payload: state
});

export const checkUserDataInLocalStorage = (state: boolean) => ({
  type: CHECK_USER_DATA_IN_LOCAL_STORAGE,
  payload: state
});

export const openAlertMessage = (state: IHeaderAlertMessage) => ({
  type: OPEN_ALERT_MESSAGE,
  payload: state
});

export const closeAlertMessage = () => ({ type: CLOSE_ALERT_MESSAGE });
