import { permissions } from '@cloudinspot/cis-common-constants';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import './viewGroupPermissions.scss';

import { TEAM_MANAGEMENT_TEXT } from '../../../../configs/teamManagement';
import { BLACK as BLACK_COLORS } from '../../../../configs/v3.colors';
import { routes } from '../../../../constants/routes';
import useUpdateBreadcrumbs from '../../../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import { fetchTeamPermissions } from '../../../../services/teamManagement/teamManagement';
import { TGetGroupPermissionsResponse } from '../../../../services/types/teamManagement.endpoints';
import { IUserPermissionsList } from '../../../../store/teamManagement/teamManagement.interface';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import HeadingText from '../../common/headingText/HeadingText';
import Permissions from '../../permissions/Permissions';

const ViewGroupPermissions = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  const { groupname } = useParams() as unknown as { groupname: string };

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { text: TEAM_MANAGEMENT_TEXT.GROUPS, url: routes.TEAM_MANAGEMENT_GROUPS_TABLE },
      {
        text: TEAM_MANAGEMENT_TEXT.GROUP_VIEW,
        url: ''
      }
    ]
  });

  const { isLoading, data: permissionsResponse } = useQuery<TGetGroupPermissionsResponse>(
    ['viewTeamPermissions', { groupname: groupname }],
    () => fetchTeamPermissions(groupname),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const currentPermissions = permissionsResponse?.permissions || [];

  return (
    <PaperCustom variant="light" className="view-group-permissions">
      <HeadingText text={TEAM_MANAGEMENT_TEXT.PERMISSIONS} color={BLACK_COLORS[900]} />
      <Permissions
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        userPermissions={permissions as unknown as IUserPermissionsList[]}
        selectedPermissions={currentPermissions}
        disableCheckboxes={true}
        hideCheckBox={groupname === 'Admin'} //for admin group hide the checkboxes
        includeDefaultPermissions={false}
        isLoading={isLoading}
      />
    </PaperCustom>
  );
};

export default ViewGroupPermissions;
