import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { GroupNameValidation } from './addGroup.validations';

import TextInput from '../../../common/textInput/TextInput';
import { routes } from '../../../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux/useRedux';
import {
  IAddGroupName,
  ITeamManagementInitialValues
} from '../../../../store/teamManagement/teamManagement.interface';
import { addGroupName, setCurrentActiveTabForm } from '../../../../store/actions';
import CustomButton, { ButtonVariants } from '../../../common/buttons/CustomButton';
import { BUTTON_TEXT } from '../../../../configs';
import PaperCustom from '../../../common/atoms/PaperCustom/PaperCustom';
import Typography from '../../../common/typography/Typography';

const GroupNameInput = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { addGroup1 } = useAppSelector(
    (state) => state.teamManagement
  ) as ITeamManagementInitialValues;

  const addGroupNameInitialValues: IAddGroupName = {
    groupName: addGroup1.groupName
  };

  const handleCancel = () => {
    navigate(routes.TEAM_MANAGEMENT_GROUPS_TABLE);
    dispatch(addGroupName(''));
  };

  const handleNextClick = ({ groupName }: IAddGroupName) => {
    dispatch(addGroupName(groupName));
    dispatch(setCurrentActiveTabForm(1));
  };

  return (
    <Formik
      onSubmit={handleNextClick}
      initialValues={addGroupNameInitialValues}
      validationSchema={GroupNameValidation}
    >
      {({ isSubmitting, errors, touched }) => {
        return (
          <Form>
            <PaperCustom variant="light" className="addGroupContainer__addGroupName">
              <Typography
                variant="body-3"
                size="medium"
                className="addGroupContainer__addGroupName__heading"
              >
                Team Details
              </Typography>

              <TextInput
                disabled={isSubmitting}
                placeholder="Enter Team Name"
                label="Team Name"
                required={true}
                errors={errors}
                touched={touched}
                name="groupName"
                type="text"
                maxLength={200}
              />

              <div className="addGroupContainer__addGroupName__buttons">
                <CustomButton
                  type="button"
                  onClick={handleCancel}
                  text={BUTTON_TEXT.CANCEL}
                  variant={ButtonVariants.SECONDARY}
                  buttonWidth="200px"
                />
                <CustomButton
                  type="submit"
                  text={BUTTON_TEXT.NEXT}
                  variant={ButtonVariants.PRIMARY}
                  buttonWidth="200px"
                />
              </div>
            </PaperCustom>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GroupNameInput;
