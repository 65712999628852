import { useEffect } from 'react';
// eslint-disable-next-line
import { useParams } from 'react-router-dom';
import { routes } from '../../constants/routes';
import '../../components/cloudAccounts/accountView/slack/slack.scss';
import PaperCustom from '../../components/common/atoms/PaperCustom/PaperCustom';
import ConnectStep from '../../components/common/connectStep/ConnectStep';
import TextTooltipWithEllipsis from '../../components/common/TextTooltipWithEllipsis/TextTooltipWithEllipsis';
import Typography from '../../components/common/typography/Typography';
import {
  CONNECT_TO_SLACK_ADDITIONAL_STEPS,
  RECONFIGURE_TO_SLACK_ADDITIONAL_STEPS,
  SLACK_CONNECT_STEPS
} from '../../configs/cloudAccounts';
import { COMMON_TEXT } from '../../configs/common';
import useComponentMount from '../../hooks/componentMount/useComponentMount';
import useLoading from '../../hooks/loading/useLoading';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/useRedux';
import useUpdateBreadcrumbs from '../../hooks/updateBreadcrumbs/useUpdateBreadcrumbs';
import {
  fetchCloudAccountDetailsRequest,
  fetchSlackConnectionDetails,
  setHeading,
  updateBreadcrumbs,
  updateSkeletonLoader
} from '../../store/actions';
import { ICloudAccountsReducerInitialState } from '../../store/cloudAccounts/cloudAccounts.interface';
import LogoWithHeading from '../../components/common/logo-with-heading/logoWithHeading';
import IMAGES from '../../assets';

const ConnectSlackAdditionalSteps = () => {
  const dispatch = useAppDispatch();
  const param = useParams();
  const { accountDetails, slackConnectionDetails } = useAppSelector(
    (state) => state.cloudAccounts
  ) as ICloudAccountsReducerInitialState;

  const { startLoading, stopLoading } = useLoading();

  const fetchSlackAccountDetails = async () => {
    if (param && param?.id) {
      dispatch(fetchSlackConnectionDetails({ accountId: param.id, startLoading, stopLoading }));
    }
  };

  const SlackChannelMapped = slackConnectionDetails.status === 'Connected';

  useComponentMount({
    onMountFunc: () => {
      dispatch(setHeading(`Cloud Accounts`));
    }
  });

  useUpdateBreadcrumbs({
    breadcrumbs: [
      { url: routes.CLOUD_ACCOUNTS, text: 'Cloud Accounts' },
      { url: `/cloud-account/view/${param?.id}`, text: `Account View` },
      { text: `${accountDetails.accountName}`, url: `/cloud-account/view/${param.id}` },
      {
        text: SlackChannelMapped ? 'Reconfigure to Slack' : 'Slack Integration Steps',
        url: ''
      }
    ]
  });

  useEffect(() => {
    if (!accountDetails.accountName) {
      dispatch(
        fetchCloudAccountDetailsRequest({
          accountId: param?.id || '',
          startLoading,
          stopLoading: () => {
            stopLoading();
            dispatch(updateSkeletonLoader({ isLoading: false }));
          }
        })
      );
    }
  }, [param?.id]);

  const updateSlackBreadcrumbs = () => {
    dispatch(
      updateBreadcrumbs([
        { url: routes.CLOUD_ACCOUNTS, text: 'Cloud Accounts' },
        { url: `/cloud-account/view/${param?.id}`, text: `Account View` },
        {
          text: (
            <TextTooltipWithEllipsis
              limit={COMMON_TEXT.CLOUD_ACCOUNT_NAME_LIMIT}
              text={accountDetails.accountName}
            />
          ),
          url: `/cloud-account/view/${param.id}`
        },
        {
          text: SlackChannelMapped ? 'Reconfigure to Slack' : 'Slack Integration Steps',
          url: ''
        }
      ])
    );
  };

  useEffect(() => {
    fetchSlackAccountDetails();
    updateSlackBreadcrumbs();
  }, [accountDetails.accountName]);

  return (
    <PaperCustom className="slack connectSteps" variant="medium">
      <LogoWithHeading
        logoSrc={IMAGES.infoCircle}
        headingText={
          SlackChannelMapped
            ? SLACK_CONNECT_STEPS.STEPS_TO_RE_CONNECT_TO_SLACK
            : SLACK_CONNECT_STEPS.STEPS_TO_CONNECT_TO_SLACK
        }
        className="slack-steps-heading"
      />
      <div>
        <Typography variant="body-3" size="semiBold" className="connectSteps__stepTitle">
          Prerequisite
        </Typography>
        <div className="connectSteps__descDiv">
          <Typography variant="body-3" size="regular" as="p">
            Your cloud account should be connected with CloudInSpot.
          </Typography>
          <Typography variant="body-3" size="regular" as="p">
            Sign in to your slack workplace and create a channel to receive updates.
          </Typography>
        </div>
      </div>
      {/*Connect and Reconfigure To Slack Steps */}
      {SlackChannelMapped
        ? RECONFIGURE_TO_SLACK_ADDITIONAL_STEPS.map((item, index) => {
            const { stepName, description } = item;
            return (
              <ConnectStep
                key={item.stepName + index}
                stepName={stepName}
                description={description}
              />
            );
          })
        : CONNECT_TO_SLACK_ADDITIONAL_STEPS.map((item, index) => {
            const { stepName, description } = item;
            return (
              <ConnectStep
                key={item.stepName + index}
                stepName={stepName}
                description={description}
              />
            );
          })}
    </PaperCustom>
  );
};

export default ConnectSlackAdditionalSteps;
